import * as React from 'react';

import { Confirm, Common, Bankid } from './Chapters';

export default (key) => {
  switch (key) {
    case 'COMMON':
      return <Common />;
    case 'BANKID':
      return <Bankid/>;
    default:
      return <Confirm />;
  }
};
