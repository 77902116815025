import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import GroupList from "../../components/Account/GroupList";
import UserList from "../../components/Account/UserList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../components/Displays";
import { PrimaryButton, TextButton } from "../../components/Forms/Base/Buttons";
import * as SharedStyles from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useAllPermissionCheck, usePermissionCheck } from "../../store/base";
import { useSupervisorCheck } from "../../store/base/hooks/permissions";
import PageSlider from "../Layouts/PageSlider/PageSlider";
import PageTitle from "../Layouts/PageTitle/PageTitle";

const TABS = {
  USERS: "Användare",
  GROUPS: "Grupper",
};

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const query = useQuery();
  const tab = query.get("tab");

  const canAddGroup = useAllPermissionCheck([
    "view_can_permissions",
    "add_can_permissions",
  ]);
  const canViewGroups = usePermissionCheck("view_can_permissions");
  const canViewUsers = usePermissionCheck("view_can_user");
  const isSupervisor = useSupervisorCheck();

  const canAddUser = usePermissionCheck("add_can_user");

  const [selectedTab, setSelectedTab] = React.useState("USERS");
  const [showGroupGuide, setShowGroupGuide] = React.useState(false);

  const hasHiddenGuide = localStorage.getItem("hide_group_guide");

  React.useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }

    if (!hasHiddenGuide) {
      setShowGroupGuide(true);
    }
  }, [tab]);

  const goToAddGroup = () => {
    push("/account/groups-and-users/add-group");
  };

  const initiateAddNewUser = () => {
    push("/account/groups-and-users/user-setup/create-user");
  };

  const breadCrumbs = [
    {
      label: "Inställningar",
      url: "/account/user",
    },
    {
      label: "Användare & Grupper",
    },
  ];

  return (
    <SharedStyles.BareLayoutWrapper>
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle dark title="Användare & Grupper" breadCrumbs={breadCrumbs} />
        {(canViewGroups || isSupervisor) && (
          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => {
                setSelectedTab(tab);
                push(`/account/groups-and-users?tab=${tab}`);
              },
            }}
          />
        )}
      </SharedStyles.BareLayoutTitleWrapper>

      {selectedTab === "USERS" && (
        <SharedStyles.DetailPageBox>
          {canViewUsers ? (
            <>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Användare</OverviewTitle>
                  <OverviewSubtitle>
                    Tryck på en användare för att se detaljer och redigera
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
                {canAddUser && (
                  <>
                    <PrimaryButton
                      title="Lägg till användare"
                      newDesign
                      clicked={initiateAddNewUser}
                    />
                  </>
                )}
              </OverviewTitleWrapper>
              <UserList />
            </>
          ) : (
            <>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>
                    Behörighet att se användare saknas
                  </OverviewTitle>
                  <OverviewSubtitle>
                    {isSupervisor
                      ? "Du kan slå på behörighet själv att se användare via Grupper upp till höger."
                      : "För att få se lista över användare krävs rätt behörighet."}
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
            </>
          )}
        </SharedStyles.DetailPageBox>
      )}

      {selectedTab === "GROUPS" && (
        <>
          <SharedStyles.DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Skapade grupper</OverviewTitle>
                <OverviewSubtitle>
                  Tryck på en grupp för att se detaljer och redigera medlemmar
                  och behörigheter
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
              {(canAddGroup || isSupervisor) && (
                <PrimaryButton
                  newDesign
                  title="Lägg till grupp"
                  clicked={goToAddGroup}
                />
              )}
            </OverviewTitleWrapper>
            <GroupList />
          </SharedStyles.DetailPageBox>
        </>
      )}
    </SharedStyles.BareLayoutWrapper>
  );
};
