import { isEqual } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../store/overview";
import { setActiveFilteredRealEstates } from "../store/overview/store/actions";
import clearTableFilters from "../utils/clearTableFilters";

export default function useActiveFilters() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const filteredRealEstateIds = useSelector(
    (state) => state[storeName].filteredRealEstateIds
  );
  const filteredCompanyIds = useSelector(
    (state) => state[storeName].filteredCompanyIds
  );

  const filteredBrfCompanyIds = useSelector(
    (state) => state[storeName].filteredBrfCompanyIds
  );

  React.useEffect(() => {
    if (filteredRealEstateIds || filteredBrfCompanyIds || filteredCompanyIds) {
      localStorage.setItem(
        "active_filters",
        JSON.stringify({
          filteredCompanyIds,
          filteredBrfCompanyIds,
          filteredRealEstateIds,
        })
      );
    }
  }, [filteredRealEstateIds, filteredCompanyIds, filteredBrfCompanyIds]);

  React.useEffect(() => {
    const persistantFilters = JSON.parse(
      localStorage.getItem("active_filters")
    );

    if (!persistantFilters) return;

    if (
      !isEqual(persistantFilters, {
        filteredCompanyIds,
        filteredBrfCompanyIds,
        filteredRealEstateIds,
      })
    ) {
      dispatch(
        setActiveFilteredRealEstates({
          realEstateIds: persistantFilters.filteredRealEstateIds,
          companyIds: persistantFilters.filteredCompanyIds,
          brfCompanyIds: persistantFilters.filteredBrfCompanyIds,
        })
      );
    }
  }, []);

  const resetFilters = () => {
    clearTableFilters();
    localStorage.setItem(
      "active_filters",
      JSON.stringify({
        filteredCompanyIds: null,
        filteredBrfCompanyIds: null,
        filteredRealEstateIds: null,
      })
    );
    dispatch(
      setActiveFilteredRealEstates({
        realEstateIds: null,
        companyIds: null,
        brfCompanyIds: null,
      })
    );
  };

  return {
    filterActive:
      !!filteredCompanyIds ||
      !!filteredRealEstateIds ||
      !!filteredBrfCompanyIds,
    filteredRealEstates: filteredRealEstateIds?.filter((r) => !!r), // remove null values
    // not really filtered by companies or brfCompanies but by realestates under a company, this is for display only
    filteredCompanies: filteredCompanyIds,
    filteredBrfCompanies: filteredBrfCompanyIds,
    resetFilters,
  };
}
