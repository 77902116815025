import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TableSelect } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingDebtor";

import {
  useInvoicingCustomDimensionPagination,
  performFilter as dimensionPerformFilter,
  constructStrRep as dimensionConstructStrRep,
  constants as dimensionConstants,
} from "../../../../../store/invoicingCustomDimensions";
import dimensionListDefs from "../../../../Lists/InvoicingCustomDimensions/listDefs";

import {
  useInvoicingCustomDimensionEntriesPagination,
  performFilter as dimensionEntryPerformFilter,
  constructStrRep as dimensionEntryConstructStrRep,
  constants as dimensionEntryConstants,
} from "../../../../../store/invoicingCustomDimensionEntries";
import dimensionEntryListDefs from "../../../../Lists/InvoicingCustomDimensionEntries/listDefs";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const selectedDimension = useFormInstanceField({
    storeName,
    fieldKey: "_interal_dimension.id",
  });
  const selectedDimensionEntry = useFormInstanceField({
    storeName,
    fieldKey: "additional_dimension.dimension_entry.id",
  });

  const persistantQueryString = buildQueryString({
    dimension: selectedDimension,
  });

  const persistantFilterMethod = (obj) =>
    obj.dimension?.id == selectedDimension;

  const clearDimension = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          additional_dimension: null,
        },
      })
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Detaljerade bokföringsinställningar</OverviewTitle>
          <OverviewSubtitle>
            Inställning av specialanpassade bokföringsdimensioner
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      {!selectedDimensionEntry && (
        <TableSelect
          storeName={storeName}
          tableStoreName={dimensionConstants.STORE_NAME}
          method={method}
          performFilter={dimensionPerformFilter}
          fieldKey="_interal_dimension"
          paginationMethod={useInvoicingCustomDimensionPagination}
          listDefs={dimensionListDefs}
          method={method}
          fieldTitle="Anpassad dimension"
          title={"Välj anpassad dimension..."}
          constructStrRep={dimensionConstructStrRep}
          modalInModal
        />
      )}

      {(selectedDimension || selectedDimensionEntry) && (
        <TableSelect
          storeName={storeName}
          tableStoreName={dimensionEntryConstants.STORE_NAME}
          method={method}
          performFilter={dimensionEntryPerformFilter}
          fieldKey="additional_dimension.dimension_entry"
          paginationMethod={useInvoicingCustomDimensionEntriesPagination}
          listDefs={dimensionEntryListDefs}
          method={method}
          fieldTitle="Instans av dimension"
          title={"Välj instans av dimension..."}
          constructStrRep={dimensionEntryConstructStrRep}
          persistantFilterMethod={persistantFilterMethod}
          persistantQueryString={persistantQueryString}
          modalInModal
        />
      )}

      {selectedDimensionEntry && (
        <TextButton
          title="Ta bort den valda dimensionen"
          iconType="sync"
          iconPlacement="right"
          red
          clicked={clearDimension}
        />
      )}
    </>
  );
};
