import * as React from "react";
import { cloneDeep, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  getTemplate,
  usePrivateTemplates,
} from "../../../../store/editabledocs";

import { updateActiveFormInstance } from "../../../../store/base";

import { cleanEditableDocTemplate } from "./utils";

import { Pill, PillsWrapper } from "../../../sharedStyles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import {
  InputFieldTitle,
  InputSpacing,
} from "../../../Forms/Base/Fields/styles";

export const fastDocAtlasTemplateName = "publicAtlasFastDoc";
export const fastDocTemplateName = "fastDocTemplates";
export const privateTemplateName = "private";
export const privateFastDocTemplateName = "privateFastDoc";
export const publicAtlasTemplateName = "publicAtlas";

const OPTIONS = {
  PRIVATE: "Egna mallar",
};

export default React.memo(
  ({ storeName, dataPath, editableDocPath, openAuthModal, authed }) => {
    const dispatch = useDispatch();

    const [selectedTemplateType, setSelectedTemplateType] =
      React.useState(null);

    const [privateTemplates, isLoadingPrivateTemplates] = usePrivateTemplates();

    const [loading, setLoading] = React.useState(false);

    const chosenTemplate = useSelector((state) => {
      const d = state[storeName].formInstance?._chosenTemplate;
      if (d) {
        return d?.id || "";
      }
      return "";
    });

    const unauthedCallback = () => {
      openAuthModal();
    };

    const getTemplateTypeOptions = () => {
      let options = [OPTIONS.PRIVATE];

      return options;
    };

    const setChosenTemplate = ({ id, pay = false, type }) => {
      const data = {
        _chosenTemplate: {
          name: type,
          id,
          pay,
        },
      };

      dispatch(updateActiveFormInstance({ storeName, data }));
    };

    const getTemplateOptions = ({ type, templates }) => {
      switch (type) {
        case publicAtlasTemplateName: {
          return templates.map((value) => {
            return {
              value: value.id,
              label: value.title,
            };
          });
        }

        case privateTemplateName: {
          return templates.map((value) => {
            return {
              value: value.id,
              label: value.title,
            };
          });
        }

        default:
          break;
      }

      if (!templates?.length) {
        return null;
      }

      // private fastdok template or pigello fastdok
      return templates.map((t) => ({
        value: t.fastdoc_id,
        object: t,
        label: t.title,
      }));
    };

    const fetchInternalTemplate = ({ id, type }) => {
      let chosenOption = null;

      switch (type) {
        case privateTemplateName: {
          chosenOption = privateTemplates.find((t) => t.id === id);
          break;
        }

        default:
          break;
      }

      setLoading(false);

      if (!chosenOption) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde ej hämta mall",
            description: "Vänligen försök igen",
          })
        );

        setChosenTemplate("");
        return;
      }

      cleanEditableDocTemplate(cloneDeep(chosenOption)).then((cleaned) => {
        const data = editableDocPath ? { [editableDocPath]: cleaned } : cleaned;

        dispatch(updateActiveFormInstance({ storeName, data }));
        setChosenTemplate({
          id,
          pay: true,
          type,
        });
      });
    };

    // object for private and public fastdoc based
    const fetchTemplate = async ({ id, pay, type, object }) => {
      const { data, unauthed } = await getTemplate(id, pay);

      setLoading(false);

      if (unauthed) {
        setChosenTemplate("");
        unauthedCallback();
      } else if (data && object) {
        cleanEditableDocTemplate(cloneDeep(object)).then((cleaned) => {
          const editableDocData = editableDocPath
            ? { [editableDocPath]: cleaned }
            : cleaned;
          const docNamePath = `${dataPath.split(".").slice(0, -1).join(".")}`;
          dispatch(
            updateActiveFormInstance({ storeName, data: editableDocData })
          );
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: {
                [dataPath]: data,
                [`${docNamePath}${docNamePath.length ? ".doc" : "doc"}`]:
                  "contract.pdf",
              },
            })
          );
          setChosenTemplate({ id, type, pay });
        });
      } else if (data) {
        const docNamePath = `${dataPath.split(".").slice(0, -1).join(".")}`;
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              [dataPath]: data,
              [`${docNamePath}${docNamePath.length ? ".doc" : "doc"}`]:
                "contract.pdf",
            },
          })
        );
        setChosenTemplate({ id, type, pay });
      } else {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde ej hämta mall",
            description: "Vänligen försök igen",
          })
        );
        setChosenTemplate("");
      }
    };

    const choseTemplate = ({ id, type, object }) => {
      if (id === chosenTemplate || !id) {
        return;
      }

      setLoading(true);

      setChosenTemplate({ id, type });

      switch (type) {
        case fastDocTemplateName: {
          fetchTemplate({ id, pay: false, type });
          break;
        }

        case publicAtlasTemplateName: {
          fetchInternalTemplate({ id, type });
          break;
        }

        case fastDocAtlasTemplateName: {
          fetchTemplate({ id, pay: false, type, object });
        }

        case privateTemplateName: {
          fetchInternalTemplate({ id, type });
          break;
        }

        case privateFastDocTemplateName: {
          fetchTemplate({ id, pay: false, type, object });
        }

        default:
          return null;
      }
    };

    const selectedPrivateTemplate =
      selectedTemplateType === privateTemplateName
        ? {
            value: chosenTemplate,
            label: privateTemplates?.find((t) => t.Id === chosenTemplate)?.Name,
          }
        : null;

    return (
      <>
        {loading && <OverlaySpinner />}

        <PillsWrapper style={{ marginBottom: "24px" }}>
          {Object.keys(OPTIONS).map((key) => {
            const disabled = !getTemplateTypeOptions().includes(OPTIONS[key]);
            return (
              <Pill
                key={OPTIONS[key]}
                {...{
                  active: selectedTemplateType === OPTIONS[key],
                  disabled,
                }}
                onClick={
                  disabled ? null : () => setSelectedTemplateType(OPTIONS[key])
                }
              >
                {OPTIONS[key]}
              </Pill>
            );
          })}
        </PillsWrapper>

        {selectedTemplateType === OPTIONS.PRIVATE && (
          <InputSpacing>
            <InputFieldTitle>Egna mallar</InputFieldTitle>
            <Select
              menuPlacement="top"
              isDisabled={!privateTemplates.length}
              onChange={(o) =>
                choseTemplate({ id: o.value, type: privateTemplateName })
              }
              placeholder="Mall"
              selected={selectedPrivateTemplate}
              value={chosenTemplate}
              options={getTemplateOptions({
                type: privateTemplateName,
                templates: privateTemplates,
              })}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
            />
          </InputSpacing>
        )}
      </>
    );
  }
);
