import * as React from "react";
import { useAsyncDebounce } from "react-table";
import NonConnectedTextInput from "../../../../Forms/Base/Old/NonConnected/NonConnectedTextInput";

export default function SimpleTextFilter({
  column: { filterValue, setFilter },
  flatRows,
}) {
  const [value, setValue] = React.useState(filterValue);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 200);

  return (
    <NonConnectedTextInput
      noMargin
      rowSize
      value={value || ""}
      onChange={(value) => {
        setValue(value || undefined);
        onChange(value || undefined);
      }}
      placeholder={`Sök`}
    />
  );
}
