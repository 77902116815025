import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import Table from "../../../Lists/Base/CompleteList/Table";
import StandardModal from "../../../Modals/StandardModal";
import { TextButton } from "../Buttons";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

import {
  ClearSelectButton,
  ErrorMessage,
  InputFieldTitle,
  InputSpacing,
  M2mLabel,
  M2mLabelRemoveButton,
  SelectButton,
} from "./styles";

export default ({
  storeName,
  tableStoreName,
  method,
  fieldKey,
  listDefs,
  paginationMethod,
  persistantFilterMethod,
  persistantQueryString,
  extraColumnsDefsArgs = [],
  filterInstructions,
  performFilter,
  forceFrontendColumns,
  fieldTitle,
  title,
  description,
  onCreateClicked,
  onEditClicked,
  constructStrRep,
  extraStyles = {},

  disabled,

  // m2m field
  isMany,
  modalInModal,

  // set entire object in state instead of just id, non m2m
  setWholeObject,

  // string for display, comes from entire object, used in patch to display correct title
  patchDisplay,
  selectedCallback,
  noMargin,
  noTitle,
  overrideCloseFunction = false,
  closeModalCallBack,
  closeOnSelected,
}) => {
  const originalFieldKey = `_original_${fieldKey}`;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);

  const selected = useFormInstanceField({
    storeName,
    fieldKey,
  });
  // table data stored in redux for display purposes
  const originalSelected = useFormInstanceField({
    storeName,
    fieldKey: originalFieldKey,
  });

  const instructions = useFormField({
    storeName,
    method,
    fieldKey,
  });

  const currentError = useFormError({ storeName, fieldKey });

  const columns = React.useMemo(
    () => listDefs(...extraColumnsDefsArgs),
    [persistantFilterMethod, persistantQueryString, ...extraColumnsDefsArgs]
  );

  const fetchAllTreshold = 25;

  const checkRowHighlighted = (row) => {
    const { original } = row;

    let match = false;

    if (isMany) {
      match = selected?.find((s) => s.id === original.id);
    } else {
      if (setWholeObject) {
        match = selected?.id === original.id;
      } else {
        match = selected?.id === original.id;
      }
    }

    return match;
  };

  const onSelected = (row) => {
    const { original } = row;

    if (isMany) {
      let selectedClone = cloneDeep(selected);

      if (!selectedClone) {
        selectedClone = [original];
      } else if (selectedClone.find((s) => s.id === original.id)) {
        selectedClone = selectedClone.filter((s) => s.id !== original.id);
      } else {
        selectedClone.push(original);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: selectedClone },
        })
      );
      selectedCallback && selectedCallback(selectedClone);
    } else {
      if (setWholeObject) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: original, [originalFieldKey]: original },
          })
        );
        selectedCallback && selectedCallback(original);
      } else {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: original, [originalFieldKey]: original },
          })
        );
        selectedCallback && selectedCallback(original);
      }
    }

      if (closeOnSelected) {
      setModalOpen(false);
      closeModalCallBack && closeModalCallBack();
    }
    
    if (overrideCloseFunction) {
      setModalOpen(false);
    }
  
  };

  const onRemoveClicked = (item) => {
    if (isMany) {
      let selectedClone = cloneDeep(selected);

      selectedClone = selectedClone.filter((s) => {
        if (item._index) {
          return s._index !== item._index;
        } else {
          return s.id !== item.id;
        }
      });

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: selectedClone },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: undefined, [originalFieldKey]: undefined },
        })
      );
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [fieldKey]: undefined, [originalFieldKey]: undefined },
      })
    );
  };

  const getTitle = () => {
    if (!selected || isMany) return title;

    if (setWholeObject) {
      return selected?.str_representation || constructStrRep(selected);
    } else {
      return method === "PATCH"
        ? patchDisplay ||
            originalSelected?.str_representation ||
            selected?.str_representation
        : originalSelected?.str_representation ||
            patchDisplay ||
            selected?.str_representation;
    }
  };

  return (
    <>
      <InputSpacing {...{ noMargin, ...extraStyles }}>
        {!noTitle && (
          <InputFieldTitle
            style={{
              marginBottom: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {fieldTitle}
            {instructions?._required ? "*" : ""}
            {description && <DescriptionToolTip description={description} />}
          </InputFieldTitle>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "8px",
          }}
        >
          <SelectButton
            hasError={!!currentError}
            disabled={disabled}
            onClick={disabled ? null : () => setModalOpen(true)}
          >
            {getTitle()}

            {!isMany && !!selected && !instructions?._required && (
              <ClearSelectButton onClick={(e) => handleClear(e)} />
            )}
          </SelectButton>

          {onCreateClicked && (isMany || !selected) && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ margin: "0 6px", fontWeight: "500", fontSize: "14px" }}
              >
                eller
              </span>

              <TextButton
                extraStyle={{ margin: "6px 0" }}
                title="Skapa ny"
                iconType="add"
                iconPlacement="right"
                clicked={onCreateClicked}
              />
            </div>
          )}
        </div>

        {isMany && selected?.length > 0 && (
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {selected.map((item, index) => {
              let hasErr =
                currentError &&
                Array.isArray(currentError) &&
                currentError[item._index];
              if (hasErr) {
                const fieldErr = currentError[item._index];
                if (
                  fieldErr.constructor === Object &&
                  !Object.keys(fieldErr).length
                ) {
                  hasErr = false;
                }
              }

              return (
                <M2mLabel key={index}>
                  {item._index !== undefined ? (
                    <div
                      onClick={() => onEditClicked(item._index)}
                      style={hasErr ? { backgroundColor: "red" } : {}}
                    >
                      {item?.str_representation || constructStrRep(item)}
                    </div>
                  ) : (
                    item?.str_representation || constructStrRep(item)
                  )}
                  <M2mLabelRemoveButton onClick={() => onRemoveClicked(item)} />
                </M2mLabel>
              );
            })}
          </div>
        )}

        {!!currentError && !isMany && (
          <ErrorMessage>Detta fält innehåller fel</ErrorMessage>
        )}
      </InputSpacing>

      <StandardModal
        isOpen={modalOpen}
        closeFunction={() => {
          setModalOpen(false);
          closeModalCallBack && closeModalCallBack();
        }}
        title={title || "Lägg till"}
        actionBarCancelTitle="Klar"
        withActionBar
        modalInModal={modalInModal}
      >
        <Table
          storeName={tableStoreName}
          columns={columns}
          persistantQueryString={persistantQueryString}
          persistantFilterMethod={persistantFilterMethod}
          paginationMethod={paginationMethod}
          filterInstructions={filterInstructions}
          fetchAllTreshold={fetchAllTreshold}
          filterAction={performFilter}
          forceFrontendColumns={forceFrontendColumns}
          {...{
            hideExport: true,
            hideColumns: true,
            onRowClicked: onSelected,
            checkRowHighlighted,
          }}
        />
      </StandardModal>
    </>
  );
};
