import * as React from "react";
import { useSelector } from "react-redux";
import { constants } from "../../../store/billectaReports";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import NonConnectedTableSelect from "../../Forms/Base/Old/NonConnected/NonConnectedTableSelect";
import { FieldBox, Row } from "../Search/styles";
import BillingReportTable from "./BillingReportTable";

import {
  constants as projectConstants,
  performFilter as projectPerformFilter,
  constructStrRep as projectConstructStrRep,
  useInvoicingProjectPagination,
} from "../../../store/invoicingProjects";
import projectListDefs from "../../Lists/InvoicingProjects/listDefs";

export default ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  goToInvoice,
  selectedProjects,
  setSelectedProjects,
}) => {
  const billingReport = useSelector(
    (state) => state[constants.STORE_NAME].billingReport
  );

  return (
    <>
      <Row>
        <FieldBox>
          <OverviewTitle small style={{ marginBottom: "8px" }}>
            Datumintervall för rapport
          </OverviewTitle>
          <NonConnectedDatePicker
            value={fromDate}
            clearable={false}
            id="billing-report-from-date"
            label="Från"
            onChange={(val) => setFromDate(val)}
            noMargin
          />
          <NonConnectedDatePicker
            value={toDate}
            clearable={false}
            id="billing-report-to-date"
            label="Till"
            onChange={(val) => setToDate(val)}
            noMargin
          />
        </FieldBox>

        <FieldBox>
          <OverviewTitle small style={{ marginBottom: "8px" }}>
            Projekt
          </OverviewTitle>

          <NonConnectedTableSelect
            tableStoreName={projectConstants.STORE_NAME}
            selected={selectedProjects}
            listDefs={projectListDefs}
            paginationMethod={useInvoicingProjectPagination}
            performFilter={projectPerformFilter}
            constructStrRep={projectConstructStrRep}
            title="Välj projekt"
            handleChange={(d) => setSelectedProjects(d)}
            setWholeObject
            isMany
          />
        </FieldBox>
      </Row>

      {billingReport && (
        <BillingReportTable
          reportRows={billingReport}
          fromDate={fromDate}
          toDate={toDate}
          handleRowClicked={goToInvoice}
        />
      )}
    </>
  );
};
