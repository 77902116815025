import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { buildQueryString, useFrequentPermissions } from "../../../store/base";
import {
  useContractInvoice,
  useInvoicesFromContractInvoice,
  useSearchSpecificSingularInvoicesByDebtorsWithMapping,
} from "../../../store/billectaInvoicing/hooks/retrieve";
import {
  getContractInvoiceIdPreview,
  pauseContractInvoice,
  resumeContractInvoice,
  generateNextContractInvoice,
  moveNextContractInvoiceBySteps,
} from "../../../store/billectaInvoicing/store/actions";
import {
  detailUrl as companyDetailUrl,
  useCompany,
} from "../../../store/companies";

import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import { useCustomerInvoicing, update } from "../../../store/invoicingCustomer";
import {
  detailUrl as invoicingSettingDetailUrl,
  useInvoicingSetting,
  NON_INVOICING_STATES,
} from "../../../store/invoicingSettings";
import { detailUrl as debtSettingDetailUrl } from "../../../store/invoicingDebtSettings";
import {
  createCostsUrl,
  useManagementContract,
} from "../../../store/managementContracts";
import base64toBlob from "../../../utils/base64ToBlob";
import ContractViewInvoicesTable from "../../../components/Billecta/Invoices/ContractViewInvoicesTable";
import {
  PopupButton,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import InvoicingErrorTable from "../../../components/Lists/InvoicingErrors/FullTable";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { getIntervalStr } from "../../../components/Displays/InvoicingHelpers";
import moment from "moment";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { DateCell, InfoBox, LinkedObject } from "../../../components/Displays";
import { useCustomer } from "../../../store/customers";
import { useDebtorInvoicing } from "../../../store/invoicingDebtor";
import * as SC from "../../../components/Details/OverviewInfo/DetailInfo/styles";
import { useCompanyInvoicing } from "../../../store/invoicingCompany";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import InvoicingCustomerModalForm from "../../../components/Forms/InvoicingCustomer/ChapterForm/ModalForm";
import StandardModal from "../../../components/Modals/StandardModal";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";

export default function ManagementContractInvoicing() {
  const dispatch = useDispatch();
  const { managementContractId } = useParams();
  const { push } = useHistory();
  const [managementContract, managementContractLoading] =
    useManagementContract(managementContractId);
  const [invoicingObj, invoicingObjLoading] = useCustomerInvoicing(
    managementContract?.customer_invoicing?.id
  );
  const { hasBillectaFullPermission } = useFrequentPermissions();
  const [setting, settingLoading] = useInvoicingSetting(
    invoicingObj?.setting?.id
  );
  const [billingCompany] = useCompany(invoicingObj?.billing_company?.id);
  const [companyInvoiceConfig] = useCompanyInvoicing(
    billingCompany?.invoicing_details?.id
  );
  const [customer] = useCustomer(managementContract?.customer?.id);
  const [debtorInvoiceConfig] = useDebtorInvoicing(
    customer?.debtor_invoice_config?.id
  );

  const shouldBeInvoicing = !NON_INVOICING_STATES.includes(
    managementContract?.state
  );

  const autogiroAvailableForDebtor = debtorInvoiceConfig?.use_autogiro;
  const autogiroActivatedForContract = invoicingObj?.use_autogiro !== false;

  const [editOpen, setEditOpen] = React.useState(false);
  const [eventsOpen, setEventsOpen] = React.useState(false);
  const [previewLoading, setPreviewLoading] = React.useState(false);
  const [confirmFunction, setConfirmFunction] = React.useState(null);
  const [confirmTitle, setConfirmTitle] = React.useState("");

  const configMissing =
    !managementContractLoading && !managementContract?.customer_invoicing;

  // single invoices
  const [singleInvoices] =
    useSearchSpecificSingularInvoicesByDebtorsWithMapping({
      debtorInvoiceConfig,
      contractKind: "CUSTOMER",
      invoicingObj,
    });

  // contract invoice @ billecta
  const billectaCreditorId = billingCompany?.billecta_id;
  const billectaContractInvoiceId = invoicingObj?.billecta_ids?.find(
    (c) => c.creditor_id === billectaCreditorId
  )?.object_id;
  const [contractInvoice, contractInvoiceLoading] = useContractInvoice({
    contractInvoiceId: billectaContractInvoiceId,
    creditorId: billectaCreditorId,
  });

  const [invoicingErrorCount] = useInvoicingErrorPaginationCount({
    filters: {
      customerinvoicing: invoicingObj?.id || -1,
    },
  });

  const [invoicingHardErrorCount] = useInvoicingErrorPaginationCount({
    filters: {
      customerinvoicing: invoicingObj?.id || -1,
      "billecta_object_id!": billectaContractInvoiceId || undefined,
    },
  });
  console.log({ invoicingErrorCount, invoicingHardErrorCount });

  const invoicingErrorQ = buildQueryString({
    customerinvoicing: invoicingObj?.id,
  });
  const invoicingHardErrorQ = buildQueryString({
    customerinvoicing: invoicingObj?.id,
    "billecta_object_id!": billectaContractInvoiceId || undefined,
  });

  const invoicingHardErrorFilter = (obj) => {
    return (
      ((obj.initiator_content_type === "accounting.customerinvoicing" &&
        obj.initiator_object?.id ==
          managementContract?.customer_invoicing?.id) ||
        (obj?.error?.initiator_content_type ===
          "accounting.customerinvoicing" &&
          obj?.error?.initiator_object?.id ==
            managementContract?.customer_invoicing?.id)) &&
      obj?.billecta_object_id !== billectaContractInvoiceId
    );
  };

  const invoicingErrorFilter = (obj) => {
    return (
      (obj.initiator_content_type === "accounting.customerinvoicing" &&
        obj.initiator_object?.id ==
          managementContract?.customer_invoicing?.id) ||
      (obj?.error?.initiator_content_type === "accounting.customerinvoicing" &&
        obj?.error?.initiator_object?.id ==
          managementContract?.customer_invoicing?.id)
    );
  };

  const [generatedInvoices, generatedInvoicesLoading] =
    useInvoicesFromContractInvoice({
      contractInvoiceId: billectaContractInvoiceId,
    });

  const combinedInvoices = [
    ...(generatedInvoices || []),
    ...(singleInvoices?.filter((si) => {
      const double = generatedInvoices?.find(
        (gi) => gi.ActionPublicId === si.ActionPublicId
      );
      return !double;
    }) || []),
  ];

  const toggleAutoGiro = () => {
    const newUseAutogiro = !autogiroActivatedForContract;

    dispatch(
      update({
        id: managementContract?.customer_invoicing?.id,
        forceData: {
          use_autogiro: newUseAutogiro,
        },
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: `Autogiro ${
                newUseAutogiro ? "aktiverades" : "inaktiverades"
              }`,
            })
          );
        },
      })
    );
  };

  const generateContractInvoicePreview = () => {
    setPreviewLoading(true);
    dispatch(
      getContractInvoiceIdPreview({
        contractInvoiceId: billectaContractInvoiceId,
        successCallback: (previewData) => {
          const blob = base64toBlob(previewData);
          const blobUrl = URL.createObjectURL(blob);
          window.open(
            blobUrl,
            "",
            `height=${window.screen.height || window.innerHeight},width=${
              window.screen.width || window.innerWidth
            }`
          );
          setPreviewLoading(false);
        },
      })
    );
  };

  const handleInvoiceRowClicked = (row) => {
    const { original } = row;
    push(
      invoiceDetailUrl({
        invoiceId: original.ActionPublicId,
        creditorId: original.CreditorPublicId,
      })
    );
  };

  const handlePauseInvoicing = () => {
    dispatch(
      pauseContractInvoice({
        id: billectaContractInvoiceId,
        creditorId: billectaCreditorId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering pausad",
              description:
                "Tryck på Hantera > Aktivera avisering för att återaktivera aviseringen.",
            })
          );
        },
      })
    );
  };

  const handleActivateInvoicing = () => {
    dispatch(
      resumeContractInvoice({
        id: billectaContractInvoiceId,
        creditorId: billectaCreditorId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering aktiverad",
              description:
                "Tryck på Hantera > Inaktivera avisering för att inaktivera aviseringen igen.",
            })
          );
        },
      })
    );
  };

  const handleGenerateNextInvoice = () => {
    dispatch(
      generateNextContractInvoice({
        id: billectaContractInvoiceId,
        creditorId: billectaCreditorId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Nästa avi genererad",
            })
          );
        },
      })
    );
  };

  const handleMoveNextInvoiceForward = () => {
    dispatch(
      moveNextContractInvoiceBySteps({
        id: billectaContractInvoiceId,
        steps: "1",
        creditorId: billectaCreditorId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Nästa generering framflyttad 1 period",
            })
          );
        },
      })
    );
  };

  const handleMoveNextInvoiceBackward = () => {
    dispatch(
      moveNextContractInvoiceBySteps({
        id: billectaContractInvoiceId,
        steps: "-1",
        creditorId: billectaCreditorId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Nästa generering tillbakaflyttad 1 period",
            })
          );
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                message || "Perioden kan inte flyttas tillbaka längre",
            })
          );
        },
      })
    );
  };

  const popupActions = [];

  if (hasBillectaFullPermission) {
    popupActions.push({
      name: "Redigera inställningar",
      onClick: () => setEditOpen(true),
    });
  }

  if (hasBillectaFullPermission && contractInvoice) {
    if (!contractInvoice.State.IsPaused) {
      popupActions.push({
        name: "Pausa avisering",
        onClick: () => {
          setConfirmTitle(
            `Är du säker på att du vill pausa/inaktivera aviseringen för detta avtal?`
          );
          setConfirmFunction(() => handlePauseInvoicing);
        },
      });
    } else {
      popupActions.push({
        name: "Aktivera avisering",
        onClick: () => {
          setConfirmTitle(
            `Är du säker på att du vill återaktivera aviseringen för detta avtal?`
          );
          setConfirmFunction(() => handleActivateInvoicing);
        },
      });
    }

    if (contractInvoice.State?.NextRunDate != null) {
      popupActions.push({
        name: "Flytta fram nästa avi",
        onClick: () => {
          setConfirmTitle(
            `Är du säker på att du vill flytta fram nästa avisering för detta avtal en (1) period?`
          );
          setConfirmFunction(() => handleMoveNextInvoiceForward);
        },
      });

      popupActions.push({
        name: "Flytta tillbaka nästa avi",
        onClick: () => {
          setConfirmTitle(
            `Är du säker på att du vill flytta tillbaka nästa avisering för detta avtal en (1) period?`
          );
          setConfirmFunction(() => handleMoveNextInvoiceBackward);
        },
      });

      popupActions.push({
        name: "Generera nästa avi direkt",
        onClick: () => {
          setConfirmTitle(
            `Är du säker på att du vill generera nästa avi direkt?`
          );
          setConfirmFunction(() => handleGenerateNextInvoice);
        },
      });
    }
  }

  if (configMissing) {
    return (
      <DetailInnerWrapper>
        <DetailPageBox centerAll style={{ minHeight: 400 }}>
          <OverviewTitle small>Aviseringsinställning saknas</OverviewTitle>
          <PrimaryButton
            extraStyle={{ marginTop: 24 }}
            title="Ställ in avisering för detta avtal"
            clicked={() => push(createCostsUrl({ id: managementContractId }))}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    );
  }

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmFunction}
        closeFunction={() => setConfirmFunction(null)}
        acceptCallback={confirmFunction}
        title={confirmTitle}
      />

      <InvoicingCustomerModalForm
        method="PATCH"
        id={invoicingObj?.id}
        instance={invoicingObj}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <StandardModal
        withActionBar
        actionBarCancelTitle="Stäng"
        title="Händelser"
        isOpen={eventsOpen}
        closeFunction={() => setEventsOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitle small>Händelser</OverviewTitle>
        </OverviewTitleWrapper>

        {contractInvoice?.Events?.length > 0
          ? contractInvoice.Events.map((e) => (
              <InnerBox>
                <SC.ItemTitle>
                  {moment(e.EventDate).format("YYYY-MM-DD HH:mm")}
                </SC.ItemTitle>
                <SC.ItemTitle>
                  {e.Title}
                  {e.Content ? ` - ${e.Content}` : ""}
                </SC.ItemTitle>
              </InnerBox>
            ))
          : "Inga aviseringshändelser för detta avtal än"}
      </StandardModal>

      <DetailInnerWrapper>
        {invoicingHardErrorCount > 0 &&
          billectaContractInvoiceId &&
          billectaCreditorId &&
          managementContract?.billecta_ids?.length > 1 && (
            <DetailPageBox error style={{ backgroundColor: "#ea5050" }}>
              <OverviewTitleWrapper>
                <OverviewTitle small style={{ color: "white" }}>
                  Felmeddelanden för aviseringar som ska ha pausats eller
                  raderats (Allvarligt - kontakta Pigello)
                </OverviewTitle>
              </OverviewTitleWrapper>

              <InnerBox style={{ backgroundColor: "white" }}>
                <InvoicingErrorTable
                  hideTitle
                  hideExport
                  hideFilters
                  hideColumns
                  hideSearch
                  persistantQueryString={invoicingHardErrorQ}
                  persistantFilterMethod={invoicingHardErrorFilter}
                />
              </InnerBox>
            </DetailPageBox>
          )}
        {invoicingErrorCount > 0 && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle small>Felmeddelanden</OverviewTitle>
            </OverviewTitleWrapper>
            <InvoicingErrorTable
              hideTitle
              hideExport
              hideFilters
              hideColumns
              hideSearch
              persistantQueryString={invoicingErrorQ}
              persistantFilterMethod={invoicingErrorFilter}
            />
          </DetailPageBox>
        )}

        <DetailPageBox>
          {settingLoading && <OverlaySpinner />}
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Aviseringsinformation</OverviewTitle>
              {contractInvoice && contractInvoice?.State?.NextRunDate && (
                <OverviewSubtitle>
                  <TextButton
                    title={previewLoading ? "Laddar..." : "Visa exempelavi"}
                    disabled={previewLoading}
                    clicked={generateContractInvoicePreview}
                    iconType="download"
                    iconPlacement="right"
                  />
                </OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>

            <div style={{ display: "flex", alignItems: "center" }}>
              {contractInvoice && (
                <SecondaryButton
                  extraStyle={{ marginRight: 8 }}
                  title={`Händelser (${contractInvoice?.Events?.length || 0})`}
                  clicked={() => setEventsOpen(true)}
                />
              )}

              {popupActions?.length > 0 && (
                <PopupButton
                  leftAligned
                  title="Hantera"
                  actions={popupActions}
                />
              )}
            </div>
          </OverviewTitleWrapper>

          <>
            {contractInvoiceLoading && <OverlaySpinner />}

            {!setting && !settingLoading && (
              <InfoBox
                boxTheme="warning"
                title="Avisering ej inställt för förvaltningsavtalet"
                text="Detta kan bero på att modulen Fakturering ej har varit aktiverad tidigare eller att en användare som ej har rätt att sätta upp avisering skapade förvaltningsavtalet. Uppdatera inställningen för att aktivera avisering för avtalet."
                renderButton={
                  hasBillectaFullPermission
                    ? {
                        title: "Uppdatera inställning",
                        clicked: () => setEditOpen(true),
                        iconType: "edit",
                        iconPlacement: "right",
                      }
                    : null
                }
              />
            )}

            {setting &&
              !invoicingObjLoading &&
              !contractInvoiceLoading &&
              !contractInvoice &&
              shouldBeInvoicing && (
                <InfoBox
                  boxTheme="warning"
                  title="Lyckades inte skapa avisering"
                  text="Något kan ha gått fel i skapandet.
              Kontrollera eventuella felmeddelanden."
                />
              )}

            {!shouldBeInvoicing && (
              <InfoBox
                boxTheme="yellow"
                title="Avisering ej aktiverad"
                text="Statusen på avtalet gör att ingen avisering är aktiv. Detta kan bero på att avtalet ej är signerat eller att statusen är okänd."
              />
            )}

            {invoicingObj && setting && (
              <DetailInfo
                infoObj={getInfoObj({
                  invoicingObj,
                  billingCompany,
                  contractInvoice,
                  setting,
                  debtorInvoiceConfig,
                  companyInvoiceConfig,
                  autogiroActivatedForContract,
                  autogiroAvailableForDebtor,
                  toggleAutoGiro,
                })}
              />
            )}
          </>
        </DetailPageBox>

        {setting && invoicingObj && (
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>Genererade avier</OverviewTitle>
            </OverviewTitleWrapper>

            {generatedInvoicesLoading && <OverlaySpinner />}

            {combinedInvoices?.length ? (
              <ContractViewInvoicesTable
                invoices={combinedInvoices}
                handleRowClicked={handleInvoiceRowClicked}
              />
            ) : (
              <BodyText>Inga avier har genererats än för detta avtal</BodyText>
            )}
          </DetailPageBox>
        )}
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({
  invoicingObj,
  billingCompany,
  contractInvoice,
  setting,
  debtorInvoiceConfig,
  companyInvoiceConfig,
  autogiroActivatedForContract,
  autogiroAvailableForDebtor,
  toggleAutoGiro,
}) {
  const infoObj = {};

  if (contractInvoice) {
    infoObj["Aviseringsdetaljer"] = [
      {
        title: "Status",
        value: (
          <StatusLabel state={contractInvoice?.State?.IsPaused ? 3 : 1}>
            {contractInvoice?.State?.IsPaused ? "Pausad" : "Aktiv"}
          </StatusLabel>
        ),
      },
      {
        title: "Senaste avisering",
        value: contractInvoice?.State?.LastRunDate ? (
          <DateCell date={contractInvoice?.State?.LastRunDate} />
        ) : (
          "Ej aviserad än"
        ),
      },
      {
        title: "Nästa avisering",
        value: <DateCell date={contractInvoice?.State?.NextRunDate} />,
      },
      {
        title: "Slutdatum för avisering",
        value: contractInvoice?.RecurrenceDetails?.NoEndDate ? (
          "Inget slutdatum"
        ) : (
          <DateCell date={contractInvoice?.RecurrenceDetails?.End} />
        ),
      },
    ];
  }

  infoObj["Inställningar"] = [
    {
      title: "Aviseringsinställning",
      value: (
        <LinkedObject obj={setting} urlMethod={invoicingSettingDetailUrl} />
      ),
    },
    {
      title: "Fakturerande bolag",
      value: <LinkedObject obj={billingCompany} urlMethod={companyDetailUrl} />,
    },
    {
      title: "Betalvillkorsinställning",
      value: (
        <LinkedObject
          obj={setting?.debt_setting}
          urlMethod={debtSettingDetailUrl}
        />
      ),
    },

    {
      title: "Startdatum för avisering",
      value: <DateCell date={invoicingObj?.start_date} />,
    },
    {
      title: "Intervall",
      value: getIntervalStr(
        invoicingObj?.interval_setting || setting?.interval_setting
      ),
    },

    {
      title: "Period",
      value:
        invoicingObj?.interval_setting?.invoice_period_display ||
        setting?.interval_setting?.invoice_period_display,
    },

    {
      title: " Leveransmetod",
      value: invoicingObj?.delivery_method_display
        ? `${invoicingObj?.delivery_method_display} (från inställning)`
        : debtorInvoiceConfig?.delivery_method_display
        ? `${debtorInvoiceConfig?.delivery_method_display} (från kund)`
        : companyInvoiceConfig?.delivery_method_display
        ? `${companyInvoiceConfig?.delivery_method_display} (från bolag)`
        : "Saknas",
    },
    {
      title: "E-post för leverans",
      value: debtorInvoiceConfig?.email_invoice || "Ingen angiven",
    },
    {
      title: "Telefonnummer för leverans",
      value: debtorInvoiceConfig?.invoice_phone || "Ingen angiven",
    },
  ];

  if (autogiroAvailableForDebtor) {
    infoObj["Autogiro"] = [
      {
        title: `Använd autogiro`,
        value: (
          <StatusLabel state={autogiroActivatedForContract ? 1 : 5}>
            {autogiroActivatedForContract ? "Aktivt" : "Inaktivt"}
          </StatusLabel>
        ),
      },
      {
        title: (
          <TextButton
            title={`${
              autogiroActivatedForContract ? "Inaktivera" : "Aktivera"
            } autogiro`}
            extraStyle={{ marginTop: 12 }}
            iconPlacement="right"
            iconType={autogiroActivatedForContract ? "pause" : "done"}
            clicked={toggleAutoGiro}
          />
        ),
      },
    ];
  }

  return infoObj;
}
