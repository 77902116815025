import * as React from "react";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";
import Select from "react-select";

import * as SC from "../../../Forms/Base/Fields/styles";

import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";

export default React.memo(
  ({ storeName, currentPartyKey, activeFieldKey, updatedCallback }) => {
    const dispatch = useDispatch();
    const instancePartiesKey = currentPartyKey.replace(/\[[^\]]*\]$/g, "");

    const choices =
      useFormInstanceField({ storeName, fieldKey: instancePartiesKey }) || [];
    const value = useFormInstanceField({
      storeName,
      fieldKey: currentPartyKey,
    });
    const field = useFormInstanceField({ storeName, fieldKey: activeFieldKey });

    const onChange = (data) => {
      // party will always be required, we don't perform this action
      if (data === null) {
        return;
      }

      // take the activeField under the currentParty
      // and remove it from the currentParty, and instead place it
      // on the chosen party

      // first remove it from the currentParty
      const remainingFields = (value?.fields || []).filter(
        (f) => !isEqual(f, field)
      );
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [`${currentPartyKey}.fields`]: remainingFields },
        })
      );

      // then put it into the choosen instance
      const chosenIndex = choices.findIndex((c) => isEqual(c, data));
      let replacementFields = data?.fields || [];
      replacementFields.push(field);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            [`${instancePartiesKey}[${chosenIndex}].fields`]: replacementFields,
          },
        })
      );
      if (updatedCallback) {
        updatedCallback();
      }
    };

    const getFieldDataForLabel = (option) => {
      const firstNameField = option.fields?.find(
        (f) => f.kind === "first_name"
      );

      if (firstNameField?.value && firstNameField?.value.includes("Förnamn")) {
        // if template, cut off "Förnamn"
        // i.e "Förnamn part 1" -> "part 1"

        return firstNameField.value.substr(8, firstNameField.value.length);
      }

      return false;
    };

    const getOptionLabel = (option) => {
      const color = option?._color;
      let style = {};
      if (!color) {
        style = {
          backgroundColor: "#FFF",
          color: "#000",
          padding: "5px",
          borderRadius: "5px",
        };
      } else {
        style = {
          backgroundColor: color,
          color: "#FFF",
          padding: "5px",
          borderRadius: "5px",
        };
      }

      return (
        <div style={{ padding: "3px" }}>
          <span style={style}>
            {option.user?.str_representation ||
              option.str_representation ||
              getFieldDataForLabel(option) ||
              "Signerande Part"}
          </span>
        </div>
      );
    };

    return (
      <SC.InputSpacing noMargin style={{ flex: 1 }}>
        <SC.InputFieldTitle>Signerande part</SC.InputFieldTitle>

        <SC.SelectWrapper>
          <Select
            id={activeFieldKey}
            placeholder="Välj..."
            onChange={onChange}
            defaultValue={null}
            value={value}
            options={choices}
            getOptionValue={(option) => option}
            getOptionLabel={getOptionLabel}
            isClearable={false}
            isDisabled={false}
          />
        </SC.SelectWrapper>
      </SC.InputSpacing>
    );
  }
);
