import * as React from "react";
import { Redirect, useParams } from "react-router";
import { useDebtorInvoicing } from "../../../store/invoicingDebtor";

import { detailUrl } from "../../../store/customers";

export default function InvoicingDebtorRedirect() {
  const { id } = useParams();

  const [debtorInvoicing] = useDebtorInvoicing(id);

  const tenantId = debtorInvoicing?.tenant?.id;

  // wait for sufficient data to redirect
  if (!tenantId) return null;

  return <Redirect to={detailUrl({ id: tenantId })} />;
}
