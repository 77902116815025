import * as React from "react";
import * as SC from "./styles";
import { useTransition, config } from "react-spring";
import { TextButton } from "../Forms/Base/Buttons";
import { TOAST_COLOR_TYPES, TOAST_TYPES } from "../../store/toasts";

export default ({
  toast,
  handleLockToast,
  handleRemoveToast,
  handleUnlockToast,
}) => {
  const [actionSuccessObj, setActionSuccessObj] = React.useState(null);

  const modalTransition = useTransition(!!toast.open, {
    config: toast.open ? { ...config.stiff } : { ...config.default },
    from: { opacity: 0, transform: `translateX(120%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  const handleActionClicked = () => {
    try {
      toast.action.clicked();
      if (toast.action?.successObj) {
        setActionSuccessObj(toast.action.successObj);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return modalTransition((styles, open) => {
    return (
      open && (
        <SC.Toast
          style={{ ...styles }}
          type={actionSuccessObj ? TOAST_TYPES.SUCCESS : toast.type}
          onMouseOver={() => handleLockToast(toast.id)}
          onMouseLeave={() => handleUnlockToast(toast.id)}
        >
          <SC.SideColor
            background={
              actionSuccessObj
                ? TOAST_COLOR_TYPES.SUCCESS.background
                : toast?.style?.background
            }
          >
            <SC.SideIcon type={toast.type} />
          </SC.SideColor>
          <SC.ContentWrapper>
            <SC.UpperWrapper color={toast.style?.color}>
              <SC.Title>{actionSuccessObj?.title || toast?.title}</SC.Title>
              <SC.CloseSign onClick={() => handleRemoveToast(toast.id)} />
            </SC.UpperWrapper>
            {!actionSuccessObj && toast.description && (
              <SC.Description>{toast.description}</SC.Description>
            )}

            {!actionSuccessObj && toast.action && toast.action?.title && (
              <TextButton
                extraStyle={{ marginTop: 12 }}
                title={toast.action.title}
                clicked={handleActionClicked}
              />
            )}
          </SC.ContentWrapper>
        </SC.Toast>
      )
    );
  });
};
