import * as React from "react";

import * as SC from "../../Fields/styles";

import Suggestions from "./Suggestions";

export default React.memo(
  ({
    title,
    description,
    isValid,
    error,
    value,
    id,
    onChange,
    onBlur,
    onFocus,
    required,
    fieldLayoutRules,
    fullWidth,
    type,
    noMargin,
    renderDescription,
    renderSuggestions = false,
    suggestions = [],
    handleSuggestionClicked,
  }) => {
    let defaultValue = "";
    if ((value !== "null" && Boolean(value)) || value === 0) {
      defaultValue = value;
    }

    return (
      <SC.InputSpacing
        fullWidth={fullWidth}
        fieldLayoutRules={fieldLayoutRules}
        {...{ noMargin }}
      >
        <SC.InputFieldTitle>
          {title}
          {required ? "*" : ""}
        </SC.InputFieldTitle>
        {description && (
          <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
        )}
        {renderDescription ? renderDescription : null}
        <SC.InputFieldWrapper valid={isValid} error={!!error}>
          <SC.InputField
            value={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            id={id}
            required={required}
            type={type || "text"}
          />
          {renderSuggestions && suggestions.length > 0 && (
            <Suggestions
              suggestions={suggestions}
              handleSuggestionClicked={handleSuggestionClicked}
            />
          )}
        </SC.InputFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
