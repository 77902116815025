import * as React from "react";
import { useTransition } from "react-spring";

import * as SC from "./styles";

export default function Popup({
  open,
  closeFunction,
  children,
  positionRight,
}) {
  const popupRef = React.useRef();

  const popupTransition = useTransition(!!open, {
    config: { duration: 150 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const close = () => {
    closeFunction();
  };

  React.useEffect(() => {
    if (open) {
      window.addEventListener("click", close);
    }

    return () => window.removeEventListener("click", close);
  }, [open]);

  return popupTransition(
    (styles, open) =>
      open && (
        <SC.PopupContainer
          {...{ positionRight }}
          style={{ opacity: styles.opacity }}
          ref={popupRef}
        >
          {children}
        </SC.PopupContainer>
      )
  );
}
