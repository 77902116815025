import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { constants } from "../../../store/editabledocs";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { InfoBox } from "../../Displays";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "../../Forms/Base/Buttons";
import { DoubleFieldWrapper } from "../../Forms/Base/Chapters/styles";
import NonConnectedCheckbox from "../../Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import NonConnectedInputField from "../../Forms/Base/Old/NonConnected/NonConnectedInputField";
import NonConnectedRadioGroup from "../../Forms/Base/Old/NonConnected/NonConnectedRadioGroup";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import {
  FIELD_TYPES,
  CATEGORY_MAP,
  FIELD_KIND_CHOICES,
  getInitialPlacement,
  lightOrDark,
} from "../utils";
import FieldSuggestions from "./FieldSuggestions";
import * as SC from "./styles";

const STAGES = {
  PARTIES: "PARTIES",
  PARTY: "PARTY",
  ADD_FIELD: "ADD_FIELD",
};

function HandleAddField({ parties, isTemplate, closeFunction }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [currentStage, setCurrentStage] = React.useState(STAGES.PARTIES);
  const [currentSelectedParty, setCurrentSelectedParty] = React.useState(null);
  const [currentSelectedPartyIndex, setCurrentSelectedPartyIndex] =
    React.useState(0);
  const [newFieldName, setNewFieldName] = React.useState("");
  const [newFieldKind, setNewFieldKind] = React.useState("text");
  const [newFieldFilledBySender, setNewFieldFilledBySender] =
    React.useState(false);
  const [newFieldIsObligatory, setNewFieldIsObligatory] = React.useState(false);

  const [selectedFields, setSelectedFields] = React.useState([]);
  const [showProposedFields, setShowProposedFields] = React.useState(false);

  const editableDocPageNumber = useFormInstanceField({
    storeName,
    fieldKey: "_page",
  });

  const toggleFieldSelected = (field) => {
    let selectedClone = cloneDeep(selectedFields);
    if (selectedClone?.find((sf) => sf.name === field.name)) {
      selectedClone = selectedClone.filter((sf) => sf.name !== field.name);
    } else {
      selectedClone.push(field);
    }

    setSelectedFields(selectedClone);
  };

  const onAddFields = ({ partyIndex, addedFields }) => {
    closeFunction();

    let fields = cloneDeep(parties[partyIndex].fields || []);

    addedFields.forEach((curField, idx) => {
      let field;

      const matchingField = currentSelectedParty.fields?.find(
        (f) => f.name === curField.name
      );
      const matchingFieldIndex =
        currentSelectedParty?.fields?.indexOf(matchingField);

      if (matchingField) {
        const matchingFieldPlacements = matchingField.placements || [];
        field = {
          ...matchingField,
          placements: [
            ...matchingFieldPlacements,
            getInitialPlacement(
              editableDocPageNumber,
              16,
              idx * 50,
              matchingField.kind === FIELD_TYPES.CHECKBOX
            )[0],
          ],
        };
      } else {
        field = {
          ...curField,
          placements: getInitialPlacement(
            editableDocPageNumber,
            16,
            idx * 50,
            curField.kind === FIELD_TYPES.CHECKBOX
          ),
        };
      }

      if (matchingField) {
        fields[matchingFieldIndex] = field;
      } else {
        fields.push(field);
      }
    });

    const k = `parties[${partyIndex}].fields`;
    dispatch(updateActiveFormInstance({ storeName, data: { [k]: fields } }));
  };

  const getParties = () => {
    const partyFields = parties?.map((party, index) => {
      const firstNameIndex = party.fields.findIndex(
        (f) => f.kind === "first_name"
      );
      const lastNameIndex = party.fields.findIndex(
        (f) => f.kind === "last_name"
      );

      return (
        <SC.Field
          key={index}
          onClick={() => {
            setCurrentSelectedParty(party);
            setCurrentSelectedPartyIndex(index);
            setCurrentStage(STAGES.PARTY);
          }}
        >
          <SC.ColorIndicator style={{ marginRight: 8 }} color={party._color} />
          {firstNameIndex != null &&
          party.fields[firstNameIndex]?.value &&
          !isTemplate
            ? `${party.fields[firstNameIndex]?.value} ${party.fields[lastNameIndex]?.value}`
            : `Part ${index + 1} (${CATEGORY_MAP[party.category]})`}
        </SC.Field>
      );
    });
    return (
      <>
        {partyFields ||
          `Saknar parter. Gå till "Hantera parter" för att lägga till parter.`}
      </>
    );
  };

  const getPartyFields = () => {
    const partySignField = currentSelectedParty?.fields.find(
      (f) => f.kind === FIELD_TYPES.SIGNATURE
    );

    const partyFields = currentSelectedParty?.fields.map((field) => (
      <SC.Field
        {...{
          selected: !!selectedFields?.find((f) => f.name === field.name),
        }}
        key={field.name}
        style={{
          backgroundColor: currentSelectedParty?._color,
          color: currentSelectedParty?._color
            ? lightOrDark(currentSelectedParty?._color) === "light"
              ? "black"
              : "white"
            : "white",
        }}
        onClick={() => toggleFieldSelected(field)}
      >
        {field.name}
      </SC.Field>
    ));

    return (
      <div>
        {!showProposedFields && (
          <>
            <OverviewTitleWrapper>
              <OverviewTitle small>Existerande fält</OverviewTitle>
            </OverviewTitleWrapper>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {partyFields}
              {!partySignField && (
                <SC.Field
                  {...{
                    selected: !!selectedFields?.find(
                      (f) => f.kind === "signature"
                    ),
                  }}
                  onClick={() =>
                    toggleFieldSelected({
                      partyIndex: currentSelectedPartyIndex,
                      kind: "signature",
                      name: "Signatur",
                      is_obligatory: true,
                    })
                  }
                  style={{
                    backgroundColor: currentSelectedParty?._color,
                    color: currentSelectedParty?._color
                      ? lightOrDark(currentSelectedParty?._color) === "light"
                        ? "black"
                        : "white"
                      : "white",
                  }}
                >
                  Signatur
                </SC.Field>
              )}

              <SC.Field
                onClick={() => {
                  setCurrentStage(STAGES.ADD_FIELD);
                  setSelectedFields([]);
                }}
                style={{ backgroundColor: "#8ECAE6", color: "#023047" }}
              >
                Lägg till nytt fält
              </SC.Field>
            </div>
          </>
        )}

        {isTemplate && !showProposedFields && (
          <>
            <OverviewTitleWrapper style={{ marginTop: 24 }}>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Föreslagna fält</OverviewTitle>
                <OverviewSubtitle>
                  Föreslagna fält som kommer autofyllas av attribut på avtal om
                  det finns ett matchande värde
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <TextButton
              title="Gå till föreslagna fält"
              iconType="arrow"
              iconPlacement="arrow"
              clicked={() => setShowProposedFields(true)}
            />
          </>
        )}

        {isTemplate && showProposedFields && (
          <div>
            <TextButton
              title="Gå tillbaka till existerande fält"
              iconType="arrow-back"
              clicked={() => setShowProposedFields(false)}
            />

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <FieldSuggestions
                {...{
                  isLandlord: currentSelectedParty?.category === "landlord",
                  currentSelectedParty,
                  currentSelectedPartyIndex,
                  selectedFields,
                  onAddNewField: (newField) => toggleFieldSelected(newField),
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const addPartyField = () => {
    // block adding of duplicate field names, instead add placement to PDF - Scrive only accepts one field of each name
    const fieldWithMatchingName = currentSelectedParty.fields?.find(
      (f) => f.name === newFieldName
    );

    return (
      <div>
        {isTemplate && (
          <InfoBox
            boxTheme="yellow"
            text="OBS: Egna fält kommer ej att autofyllas ifrån avtal av Pigello. Välj istället de föreslagna fält som finns för t.ex. Orgnr, Total area osv."
          />
        )}
        <DoubleFieldWrapper
          style={{ marginBottom: "12px", alignItems: "flex-start" }}
        >
          <NonConnectedInputField
            title="Namn på fält"
            description="Namnet måste vara unikt"
            id="fieldName"
            type="text"
            noMargin
            value={newFieldName}
            onChange={({ target: { value } }) => {
              setNewFieldName(value);
            }}
          />

          <NonConnectedSelect
            extraStyles={{ marginLeft: "12px" }}
            id="fieldKind"
            allowNull={false}
            label="Typ av fält"
            helpText="Välj vilken typ av fält som ska skapas"
            noMargin={true}
            choices={FIELD_KIND_CHOICES}
            getOptionLabel={(o) => o.d}
            getOptionValue={(o) => o.v}
            value={newFieldKind}
            onUpdate={(val) => setNewFieldKind(val)}
            newLabelCallback={(label) => setNewFieldName(label)}
          />
        </DoubleFieldWrapper>

        <div>
          <NonConnectedCheckbox
            id="fieldObligatory"
            title="Kräver ifyllning"
            small
            noMargin
            value={newFieldIsObligatory}
            onChange={(checked) => setNewFieldIsObligatory(checked)}
          />
          {newFieldIsObligatory && (
            <NonConnectedRadioGroup
              id="fieldfilledbysender"
              value={newFieldFilledBySender}
              noMargin
              title="Ange vem som ska fylla i fältet"
              options={[
                {
                  label: "Ska fyllas i av avsändare",
                  value: true,
                },
                {
                  label: "Ska fyllas i av mottagare",
                  value: false,
                },
              ]}
              onChange={(data) => setNewFieldFilledBySender(data)}
            />
          )}
        </div>

        {fieldWithMatchingName && (
          <>
            <div style={{ color: "red" }}>
              Ett fält med detta namn finns redan.
            </div>
            <SecondaryButton
              title="Lägg till kopia av fält"
              extraStyle={{ marginTop: "12px" }}
              clicked={() =>
                onAddFields({
                  partyIndex: currentSelectedPartyIndex,
                  addedFields: [fieldWithMatchingName],
                })
              }
            />
          </>
        )}

        {!fieldWithMatchingName && (
          <PrimaryButton
            title="Lägg till fält"
            extraStyle={{ marginTop: "12px" }}
            disabled={newFieldName.length === 0 || fieldWithMatchingName}
            clicked={() =>
              onAddFields({
                partyIndex: currentSelectedPartyIndex,
                addedFields: [
                  {
                    kind: newFieldKind,
                    name: newFieldName,
                    value: newFieldName,
                    is_obligatory: newFieldIsObligatory,
                    should_be_filled_by_sender: newFieldFilledBySender,
                  },
                ],
              })
            }
          />
        )}
      </div>
    );
  };

  const getCurrentTitle = () => {
    switch (currentStage) {
      case STAGES.PARTIES:
        return "Välj part att lägga till ett fält ifrån";
      case STAGES.PARTY:
        return `Välj fält på ${
          currentSelectedParty?.str_representation ||
          currentSelectedParty?.user?.str_representation ||
          `part ${currentSelectedPartyIndex + 1}`
        }`;
      case STAGES.ADD_FIELD:
        return `Lägg till fält på ${
          currentSelectedParty?.str_representation ||
          currentSelectedParty?.user?.str_representation ||
          `part ${currentSelectedPartyIndex + 1}`
        }`;

      default:
        return "";
    }
  };

  const getFields = () => {
    switch (currentStage) {
      case STAGES.PARTIES:
        return getParties();

      case STAGES.PARTY:
        return getPartyFields();

      case STAGES.ADD_FIELD:
        return addPartyField();
      default:
        return null;
    }
  };

  return (
    <>
      <SC.TitleWrapper>
        {currentStage !== STAGES.PARTIES && (
          <SC.BackButton
            onClick={() =>
              setCurrentStage(
                currentStage === STAGES.ADD_FIELD
                  ? STAGES.PARTY
                  : STAGES.PARTIES
              )
            }
          />
        )}
        <SC.Title>
          {getCurrentTitle()}
          {currentStage === STAGES.PARTIES && (
            <div style={{ fontSize: ".7rem" }}>
              OBS: Tillagda fält som tillhör avtalet ska ligga på hyresvärden,
              t.ex. Hyra, Area osv. Detta för att motparten ej ska kunna
              redigera fälten i Scrive.
            </div>
          )}
        </SC.Title>
      </SC.TitleWrapper>

      <SC.FieldWrapper>{getFields()}</SC.FieldWrapper>

      {selectedFields?.length > 0 && (
        <PrimaryButton
          title="Lägg till valda fält på dokument"
          clicked={() =>
            onAddFields({
              partyIndex: currentSelectedPartyIndex,
              addedFields: selectedFields,
            })
          }
        />
      )}
    </>
  );
}

export default HandleAddField;
