import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingCustomer";
import { useDispatch } from "react-redux";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useParams } from "react-router";
import { useCompany } from "../../../../../store/companies";
import { useManagementContract } from "../../../../../store/managementContracts";
import { useCustomer } from "../../../../../store/customers";
import { useDebtorInvoicing } from "../../../../../store/invoicingDebtor";
import { useCompanyInvoicing } from "../../../../../store/invoicingCompany";
import { TextButton } from "../../../Base/Buttons";
import { RadioGroup, TextField, TextInput } from "../../../Base/Fields";
import InvoicingSettingDefault from "../../../../Summaries/InvoicingSettingDefault";
import { BodyText } from "../../../../sharedStyles";
import { cloneDeep } from "lodash";
import { useInvoicingSetting } from "../../../../../store/invoicingSettings";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { managementContractId } = useParams();

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const yourReference = useFormInstanceField({
    storeName,
    fieldKey: "your_reference",
  });

  const ourReference = useFormInstanceField({
    storeName,
    fieldKey: "our_reference",
  });

  const invoiceMessage = useFormInstanceField({
    storeName,
    fieldKey: "message",
  });

  const selectedBillingCompany = useFormInstanceField({
    storeName,
    fieldKey: "billing_company",
  });

  const [billingCompany] = useCompany(selectedBillingCompany?.id);
  const [managementContract] = useManagementContract(managementContractId);
  const [customer] = useCustomer(managementContract?.management_customer?.id);
  const [debtorInvoicingConfig] = useDebtorInvoicing(
    customer?.debtor_invoice_config?.id
  );
  const [companyInvoicingConfig] = useCompanyInvoicing(
    billingCompany?.invoicing_details?.id
  );
  const [existingSetting] = useInvoicingSetting(defaultSetting?.id);

  return (
    <>
      <FormAreaTitle>Information</FormAreaTitle>

      {ourReference != null || companyInvoicingConfig?.our_reference == null ? (
        <>
          {companyInvoicingConfig?.our_reference != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.our_reference = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: {
                      instanceClone,
                    },
                  })
                );
              }}
            />
          )}

          <TextInput
            title="Vår referens"
            description={`"Vår referens" på fakturor som genereras utifrån detta avtal.`}
            {...{ storeName, method, fieldKey: "our_reference" }}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Vår referens"
          sourceTitle="Från valt bolag"
          content={[
            {
              title: "Vår referens",
              content: companyInvoicingConfig?.our_reference,
            },
          ]}
          renderContent
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  our_reference: "",
                },
              })
            );
          }}
        />
      )}

      {yourReference != null ||
      debtorInvoicingConfig?.your_reference == null ? (
        <>
          {debtorInvoicingConfig?.your_reference != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                instanceClone.your_reference = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <TextInput
            title="Er referens"
            description={`"Er referens" på fakturor som genereras utifrån detta avtal.`}
            {...{ storeName, method, fieldKey: "your_reference" }}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Er referens"
          sourceTitle="Från kundens inställningar"
          content={[
            {
              title: "Er referens",
              content: debtorInvoicingConfig?.your_reference,
            },
          ]}
          renderContent
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  your_reference: "",
                },
              })
            );
          }}
        />
      )}

      <TextInput
        title="Referensmärkning på fakturor"
        description={`"Extern referens" på fakturor som genereras utifrån detta avtal.`}
        {...{ storeName, method, fieldKey: "external_reference" }}
      />

      <RadioGroup
        title="Använd automeddelande på fakturor"
        description="Om denna inställning är aktiv kommer meddelandet på fakturor som genereras för detta avtal att få ett auto-genererat meddelande som beskriver vilket/vilka hyresobjekt fakturan avser."
        {...{ storeName, method, fieldKey: "include_auto_message" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      {invoiceMessage != null || existingSetting?.message == null ? (
        <>
          {existingSetting?.message != null && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);

                instanceClone.message = null;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <TextField
            title="Meddelande på fakturor"
            {...{ storeName, method, fieldKey: "message" }}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Fakturameddelande"
          content={[
            {
              title: "Meddelande på fakturor",
              content: existingSetting?.message,
            },
          ]}
          renderContent
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  message: "",
                },
              })
            );
          }}
        />
      )}
      {debtorInvoicingConfig?.default_invoice_message && (
        <>
          <BodyText>OBS: Meddelandet som är inställt på kunden: </BodyText>
          <BodyText style={{ fontStyle: "italic", margin: "8px 0" }}>
            "{debtorInvoicingConfig.default_invoice_message}"
          </BodyText>
          <BodyText>
            kommer att läggas till efter detta meddelande på avierna.
          </BodyText>
        </>
      )}
    </>
  );
};
