import * as React from "react";
import { Redirect, useParams } from "react-router";

import { useCustomerInvoicing } from "../../../store/invoicingCustomer";
import { detailUrl } from "../../../store/invoicingCustomer";

export default function InvoicingCustomerRedirect() {
  const { id } = useParams();

  const [customerInvoicing] = useCustomerInvoicing(id);

  const customerContractId = customerInvoicing?.customer_contract?.id;

  // wait for sufficient data to redirect
  if (!customerContractId) return null;

  return <Redirect to={detailUrl({ id: customerContractId })} />;
}
