import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { groupBy } from "lodash";

export default ({ reportRows, fromDate, toDate }) => {
  const data = React.useMemo(() => {
    const grouped = groupBy(reportRows, (r) => r.BookKeepingAccount);
    if (!grouped) return [];
    const groupedArray = [];

    Object.keys(grouped).forEach((key) => {
      const currentArr = grouped[key];
      const group = {
        Amount: {
          ValueForView: currentArr.reduce((acc, cur) => {
            return acc + cur.Amount.ValueForView;
          }, 0),
        },
        Quantity: currentArr.reduce((acc, cur) => {
          return acc + cur.Quantity;
        }, 0),
        BookKeepingAccount: key,
      };

      groupedArray.push(group);
    });

    return groupedArray;
  }, [reportRows]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Bokföringskonto",
        accessor: "BookKeepingAccount",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
        TotalFooter: () => {
          return <Field>Total:</Field>;
        },
      },

      {
        Header: "Belopp (SEK)",
        accessor: "Amount.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.Amount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.Amount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        withExport
        exportName={`forsaljning_per_konto_${fromDate}-${toDate}`}
        withSummaryFooter
        withTotalSummaryFooter
        tableId="groupedaccountssalestable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
