import * as React from "react";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../../Forms/Base/Buttons";
import { activateScriveSigning } from "../../../../store/editabledocs/store/actions";
import ConfirmationModal from "../../../Modals/ConfirmationModal";

export default ({ editableDoc }) => {
  const dispatch = useDispatch();
  const [confirmSignOpen, setConfirmSignOpen] = React.useState(false);

  const onConfirmSign = () => {
    // confirm modal only open if editable doc exists
    dispatch(
      activateScriveSigning({
        id: editableDoc.id,
      })
    );
    setConfirmSignOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        isOpen={confirmSignOpen}
        closeFunction={() => setConfirmSignOpen(false)}
        acceptCallback={() => onConfirmSign()}
        newDesign
        title="Är du säker på att du vill starta signeringen för avtalet?"
        renderContent={() => (
          <>
            <span>Följande parter kommer vara delaktiga i signeringen:</span>
            <br></br>
            {(editableDoc?.parties || []).map((p) => {
              return (
                <>
                  <span>
                    {p.str_representation} <br />
                    Roll: {p.signatory_role_display}
                  </span>
                  <br />
                  <br />
                </>
              );
            })}
          </>
        )}
      />
      <div>
        <PrimaryButton
          extraStyle={{ marginRight: 8 }}
          pulse
          title="Starta signering"
          clicked={() => setConfirmSignOpen(true)}
        />
      </div>
    </>
  );
};
