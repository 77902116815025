import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { TextInput } from "../../../Base/Fields";
import GenericField from "../../../Base/FieldComponents/GenericField";

// store, state
import { constants } from "../../../../../store/basicDocs";
import DocumentUploader from "../../../Base/Fields/DocumentUploader";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Dokument</FormAreaTitle>
      <FormAreaDescription>
        Ange infromation om dokumentet du vill lägga till.
      </FormAreaDescription>

      <TextInput
        title="Namn på dokument"
        storeName={storeName}
        method={method}
        fieldKey="title"
      />

      <GenericField
        storeName={storeName}
        method={method}
        ctFieldKey="content_type"
        idFieldKey="object_id"
      />

      <DocumentUploader
        title="Dokument"
        method={method}
        storeName={storeName}
        fieldKey={"doc"}
        allowedFormats={[
          ".jpg",
          ".png",
          ".jpeg",
          ".pdf",
          ".docx",
          ".odf",
          ".txt",
          ".md",
        ]}
      />
    </>
  );
};
