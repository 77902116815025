import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import EditabledocTable from "../../../components/Lists/EditableDocs/FullTable";
import { buildQueryString } from "../../../store/base";

export default function Templates() {
  const persistantQueryString = buildQueryString({
    is_template: true,
  });
  const persistantFilterMethod = (obj) => {
    return obj.is_template;
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Mallar</OverviewTitle>
        </OverviewTitleWrapper>

        <EditabledocTable
          persistantFilterMethod={persistantFilterMethod}
          persistantQueryString={persistantQueryString}
          hideTitle
          withPersistantSortBy
          withPersistantGlobalFilter
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
