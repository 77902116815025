import * as React from "react";
import { useHistory, useParams } from "react-router";
import BottomBar from "../../../components/StepDisplay/BottomBar";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import CreateGroupForm from "../../../components/Forms/UserGroup/CreateGroupForm";
import { constants, create } from "../../../store/userGroups";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { BareLayoutWrapper, InnerBox } from "../../../components/sharedStyles";
import { useDispatch } from "react-redux";

export default () => {
  const { push, goBack } = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      create({
        successCallback: (_, { id }) => {
          setLoading(false);
          push(
            `/account/groups-and-users/user-setup/set-user-permissions/create-white-list-setting/${id}/${userId}`
          );
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <BareLayoutWrapper fillWidth style={{ paddingBottom: 120 }}>
      <StepDisplay
        steps={[
          "1. Skapa användare",
          "2. Ställ in behörigheter",
          "3. Bjud in användare",
        ]}
        activeIndex={1}
      />

      <InnerBox
        style={{
          maxWidth: 1200,
          padding: 48,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Skapa grupp för behörigheter (Steg 1/2)
            </OverviewTitle>
            <OverviewSubtitle>
              Extra behörigheter till specifika objekt kan läggas till i nästa
              steg.{" "}
              <strong>
                Enbart objekt där "Se" på grundbehörigheten är påslagen kan
                läggas till som "Extra behörighet" i nästa steg.
              </strong>
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <CreateGroupForm defaultUserId={userId} />
      </InnerBox>

      <BottomBar
        primaryActionTitle="Spara och gå vidare"
        disabled={false}
        primaryAction={onSubmit}
        cancelActionTitle="Tillbaka"
        cancelAction={() => goBack()}
      />
      {loading && <OverlaySpinner />}
    </BareLayoutWrapper>
  );
};
