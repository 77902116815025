import * as React from "react";

import { useFormInstanceField } from "../../../store/base";

import DraggableField from "./DraggableField";

export default React.memo(({ storeName, currentPage, method, disabled }) => {
  const partyBaseKey = "parties";
  const parties =
    useFormInstanceField({ storeName, fieldKey: partyBaseKey }) || [];

  const renderFields = () => {
    if (!parties?.length) {
      return null;
    }

    let result = [];

    parties.forEach((party, partyIndex) => {
      const fields = party.fields || [];
      if (!fields?.length) {
        return;
      }

      fields.forEach((field, fieldIndex) => {
        const positions = field.placements || [];
        const kind = field.kind;
        if (!positions.length) {
          return;
        }

        positions.forEach((position, positionIndex) => {
          if (position.page !== currentPage) {
            return;
          }

          const partyKey = `${partyBaseKey}[${partyIndex}]`;
          const fieldKey = `${partyKey}.fields[${fieldIndex}]`;
          const positionKey = `${fieldKey}.placements[${positionIndex}]`;
          result.push(
            <DraggableField
              disabled={disabled}
              storeName={storeName}
              partyKey={partyKey}
              fieldKey={fieldKey}
              kind={kind}
              positionKey={positionKey}
              position={position}
              method={method}
            />
          );
        });
      });
    });

    return result;
  };

  return <>{renderFields()}</>;
});
