import * as React from "react";

import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBoxFlexWrapper,
} from "../../components/sharedStyles";

import ErrorBoundary from "../../components/ErrorBoundary";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import { SecondaryButton } from "../../components/Forms/Base/Buttons";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import moment from "moment";
import TodoList from "../../components/Details/Dashboard/MyWorkday/TodoList";
import MyWorkdayCalendar from "../../components/Details/Dashboard/MyWorkday/Calendar";
import Favourites from "../../components/Details/Dashboard/MyWorkday/Favourites";

export default () => {
  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SecondaryButton
        title="FAQ"
        clicked={() =>
          window
            .open(
              "https://support.pigello.se/sv/collections/3293208-faq",
              "_blank"
            )
            .focus()
        }
      />
    </div>
  );
  const renderInfoPills = () => (
    <StatusLabel state={"neutral"}>{moment().format("LL")}</StatusLabel>
  );

  return (
    <ErrorBoundary>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          // isBeta
          title={`Min arbetsdag`}
          {...{ renderActions, renderInfoPills }}
        />

        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <div style={{ flex: 3 }}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginRight: 12 }}>
                  <Favourites />
                  <TodoList />
                </div>
                {/* <div style={{ flex: 1, marginRight: 12 }}>
                  <GetStarted />
                </div> */}
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <MyWorkdayCalendar />
            </div>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </ErrorBoundary>
  );
};
