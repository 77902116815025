import moment from "moment";
import constants from "./constants";

import * as services from "./services";
import {
  addToProcess,
  buildQueryString,
  clearFetched,
  get,
  getAllFetchProcessName,
  getFiltered,
  getPagination,
} from "../../base";
import { retrieve } from "../../base/store/services";
import { REPORT_EVENTS_CATEGORIES } from "../hooks/retrieve";
import { removeFromProgress } from "../../base/store/actions";

const NON_IMPORTANT_IMPORTANT_MESSAGES = [
  "ContractInvoiceInformations",
  "DebtorCorrections",
  "ContractInvoiceIssues",
]; // actually not important for our users
export const getImportantMessages = ({ inProcessName }) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, inProcessName || "important_messages");

      const { data } = await retrieve({
        url: constants.IMPORTANT_MESSAGES_URL,
      });

      const filteredData = data.filter(
        (d) => !NON_IMPORTANT_IMPORTANT_MESSAGES.includes(d.NotificationType)
      );

      dispatch({
        type: constants.SET_IMPORTANT_MESSAGES,
        payload: {
          messages: filteredData,
        },
      });

      removeFromProgress(
        dispatch,
        constants,
        inProcessName || "important_messages"
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteImportantMessage = ({
  creditorId,
  messageId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.deleteImportantMessage({ creditorId, messageId });

      dispatch({
        type: constants.DELETE_IMPORTANT_MESSAGE,
        payload: {
          deleteId: messageId,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};
// export const getSingle = (id) => {
//   const url = `${constants.GET_URL}${id}`;
//   return get({ url, constants, name: getSingleFetchProcessName(id) });
// };

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const markNotificationAsRead = ({ id }) => {
  return async (dispatch) => {
    try {
      const res = await services.markNotificationsAsRead([id]);

      dispatch(clearFetched(constants, true));
    } catch (e) {
      console.log(e);
    }
  };
};

export const markNotificationsAsRead = ({ ids }) => {
  return async (dispatch) => {
    try {
      const res = await services.markNotificationsAsRead(ids);

      dispatch(clearFetched(constants, true));
    } catch (e) {
      console.log(e);
    }
  };
};

export const clearAllPaymentsNotificationsForCreditor = ({ creditorId }) => {
  return async (dispatch) => {
    try {
      const q = buildQueryString({
        related_creditor_id: creditorId,
        read: false,
        categories: [
          REPORT_EVENTS_CATEGORIES.INVOICE_OVERPAYED,
          REPORT_EVENTS_CATEGORIES.INVOICE_UNMATCHED_PAYMENT,
        ],
      });

      const url = `${constants.LIST_URL}?${q}`;

      const allPaymentsNotifs = await retrieve({ url });

      if (!allPaymentsNotifs?.length) return;

      const ids = allPaymentsNotifs.map((notif) => notif.id);

      await services.markNotificationsAsRead(ids);
    } catch (e) {
      console.error(e);
    }
  };
};
