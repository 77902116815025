import moment from "moment";

export const initIntercom = ({ user, intercomData, isDev }) => {
  window.Intercom("boot", {
    app_id: isDev ? "vpmhskl3" : "hi0kt15h",
    name: user?.str_representation,
    phone: user?.phone,
    email: user?.email,
    user_id: user?.username,
    created_at: moment(intercomData?.user_created_date).unix(), // todo
    user_hash: intercomData?.user_hash_key, // todo,
    company: {
      company_id: intercomData?.customer?.id, // todo
      name: intercomData?.customer?.name, // todo
    },
  });
};
