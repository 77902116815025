import * as React from "react";
import Pikaday from "pikaday";
import moment from "moment";
import { uid } from "uid";
import "../../../../../../node_modules/pikaday/css/pikaday.css";

import * as SC from "../../Fields/styles";

export default function NonConnectedDatePicker({
  value,
  error,
  label,
  helpText,
  id,
  onChange,
  disabled,
  format,
  fillWidth,
  noMargin,
  extraStyle = {},
  inputExtraStyle = {},
  clearable = true,
  minDate,
  maxDate,
}) {
  const [dp, setDp] = React.useState(null);
  const usedFormat = format || "YYYY-MM-DD";

  const pickerRef = React.useRef();

  const handleChange = (data) => {
    const date = moment(data).format(usedFormat);
    if (date === value) return;

    onChange(date);
  };

  const clearDate = (e) => {
    e.stopPropagation();
    dp.setDate(null);

    onChange(undefined);
  };

  React.useEffect(() => {
    if (dp && value) {
      dp.setDate(moment(value).format("YYYY-MM-DD"));
    }
  }, [value]);

  React.useEffect(() => {
    // prevent double inits on hot reloads (dev env bug)
    if (dp) return;
    const datepicker = new Pikaday({
      field: pickerRef.current,
      format: usedFormat,
      defaultDate: value ? moment(value).toDate() : moment().toDate(),
      setDefaultDate: value ? true : false,
      onSelect: handleChange,
      minDate: minDate || undefined,
      maxDate: maxDate || undefined,
      firstDay: 1,
      i18n: {
        previousMonth: "Föregående månad",
        nextMonth: "Nästa månad",
        months: [
          "Januari",
          "Februari",
          "Mars",
          "April",
          "Maj",
          "Juni",
          "Juli",
          "Augusti",
          "September",
          "Oktober",
          "November",
          "December",
        ],
        weekdays: [
          "Söndag",
          "Måndag",
          "Tisdag",
          "Onsdag",
          "Torsdag",
          "Fredag",
          "Lördag",
        ],
        weekdaysShort: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
      },
    });
    setDp(datepicker);
  }, []);

  return (
    <SC.InputSpacing
      {...{ fillWidth, fullWidth: fillWidth, noMargin }}
      style={{ ...extraStyle }}
    >
      <SC.InputFieldTitle>{label}</SC.InputFieldTitle>
      {helpText && (
        <SC.InputFieldDescription>{helpText}</SC.InputFieldDescription>
      )}
      <div style={{ position: "relative", display: "inline-block" }}>
        <SC.DatePickerWrapper
          {...{ fillWidth, fullWidth: fillWidth }}
          style={{ ...inputExtraStyle }}
          type="text"
          autoComplete={uid(16)}
          ref={pickerRef}
          id={id}
          disabled={disabled}
          name={`${id}-${uid(16)}`}
        />
        {value && !disabled && clearable && (
          <SC.ClearDateButton onClick={clearDate} />
        )}
      </div>
      {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.InputSpacing>
  );
}
