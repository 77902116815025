import * as React from "react";
import { useEditabledocForm } from "../../../../store/editabledocs";
import EditableDocPDF from "../../../EditableDocBase/PdfRenderer/Pdf";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import { constants, destroyPatchForm } from "../../../../store/editabledocs";
import * as SC from "../styles";
import EditableDocBottomBar from "../../../EditableDocBase/BottomBar/BottomBar";
import { useFormInstanceField } from "../../../../store/base";
import EditableDocTopBar from "../../../EditableDocBase/TopBar/TopBar";
import useSetPartyColors from "../../../EditableDocBase/useSetPartyColors";
import { useDispatch } from "react-redux";

export default function EditableDocContractContainer({
  contract,
  method = "PATCH",
  onSubmit,
  detailUrl,
  onClearDocumentCallback,
  isCancelledDoc,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const formLoaded = Boolean(
    useEditabledocForm(
      method,
      isCancelledDoc ? contract?.cancelled_doc?.id : contract?.editabledoc?.id
    )
  );

  useSetPartyColors({ fieldKey: "parties", storeName });

  const editableDocFile = useFormInstanceField({
    storeName,
    fieldKey: "docData_",
  });
  const editableDocUrl = useFormInstanceField({
    storeName,
    fieldKey: "doc.get",
  });

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(true));
    };
  }, []);

  const hasDocument = editableDocFile || editableDocUrl;

  if (!formLoaded) return <FullPageSpinner />;

  return (
    <>
      {hasDocument && (
        <EditableDocTopBar
          {...{ detailUrl, contract, method, isCancelledDoc }}
        />
      )}
      {hasDocument && (
        <EditableDocBottomBar
          {...{ method, onSubmit, isCancelledDoc, onClearDocumentCallback }}
        />
      )}

      <SC.DocWrapper>
        <EditableDocPDF {...{ contract, method, isCancelledDoc }} />
      </SC.DocWrapper>
    </>
  );
}
