import * as React from "react";
import * as SC from "./styles";

export default function RequiredMissing({ requiredMissing, title }) {
  return (
    <SC.Wrapper>
      <SC.Title>{title}</SC.Title>

      {requiredMissing.map((r, idx) => (
        <SC.Item key={idx}>{r}</SC.Item>
      ))}
    </SC.Wrapper>
  );
}
