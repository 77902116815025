import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "../styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default React.memo(
  ({ storeName, fieldKey, method, instructionsKey }) => {
    const dispatch = useDispatch();

    const actualFieldKey = `${fieldKey}.is_checked`;
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || actualFieldKey,
    });

    const value = useFormInstanceField({ storeName, fieldKey: actualFieldKey });
    const error = useFormError({ storeName, fieldKey: actualFieldKey });

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [actualFieldKey]: data },
        })
      );
    };

    return (
      <>
        <SC.PDFCheckBox
          small
          id={actualFieldKey}
          checked={Boolean(value)}
          onChange={({ target: { checked } }) => onChange(checked)}
        />
        <label htmlFor={actualFieldKey}></label>
      </>
    );
  }
);
