import * as React from "react";
import * as SC from "./styles";
import { useDispatch } from "react-redux";

import Popup from "../Popup";

import { useHistory } from "react-router-dom";

import { redirectMethodFromKind } from "../../../store/utils";

import { markAsRead } from "../../../store/notificationsDisplay/store/actions";
import { TextButton } from "../../Forms/Base/Buttons";

export default React.memo(({ data, open, setOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [nothingToShow] = React.useState(true);
  const [hasOpened, setHasOpened] = React.useState(false);

  const onNotificationClicked = (notification) => {
    const redirectMethod = redirectMethodFromKind(notification?.content_type);
    if (typeof redirectMethod === "function") {
      history.push(redirectMethod({ id: notification?.object_id }));
    }
  };

  React.useEffect(() => {
    if (open) {
      setHasOpened(true);
    }
    if (!open && hasOpened) {
      const notificationIds = data.results.map((notification) => {
        return notification.id;
      });
      dispatch(markAsRead(notificationIds, hasOpened));
    }
  }, [open]);
  return (
    <Popup positionRight open={open} closeFunction={() => setOpen(false)}>
      <SC.ReportHeader>
        <SC.ReportHeaderTitle>Dina senaste notiser</SC.ReportHeaderTitle>
        <SC.ReportHeaderTitleInfo>Visar 10 senaste</SC.ReportHeaderTitleInfo>
      </SC.ReportHeader>
      {!data?.results?.length ? (
        <SC.ReportInfo>
          <SC.ReportInfoTitle>Det finns inget nytt att visa</SC.ReportInfoTitle>
        </SC.ReportInfo>
      ) : (
        data?.results?.map((notification) => {
          return (
            <SC.NotificationItem
              key={notification.id}
              onClick={() => {
                onNotificationClicked(notification);
              }}
            >
              {notification?.title}
            </SC.NotificationItem>
          );
        })
      )}

      <TextButton
        extraStyle={{ margin: "12px 8px 8px 8px" }}
        iconType="arrow"
        iconPlacement="right"
        title={nothingToShow ? "Gå till notiser" : "Visa alla"}
        clicked={() => history.push("/account/notifications")}
      />
    </Popup>
  );
});
