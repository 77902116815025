import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

// style, design
import * as SC from "../Base/Chapters/styles";
import { TextInput } from "../Base/Fields";
import { PrimaryButton } from "../Base/Buttons";

// store, state
import {
  constants,
  destroyPatchForm,
  destroyPostForm,
  create,
  update,
} from "../../../store/userGroups";
import { usePermissionCheck } from "../../../store/base";
import { useHistory } from "react-router-dom";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { useUserGroupForm } from "../../../store/userGroups";

export default ({ method, untouchedInstance, nextUrl }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { push } = useHistory();

  const formLoaded = Boolean(useUserGroupForm(method, untouchedInstance?.id));

  const hasChanged = useSelector((state) => {
    return !isEqual(untouchedInstance, state[storeName].formInstance);
  });

  const canSubmit = usePermissionCheck(
    `${method === "POST" ? "add" : "change"}_can_user`
  );

  const checkout = (success) => {
    setLoading(false);
    if (method === "POST") {
      dispatch(destroyPostForm(success));
      push(nextUrl || `/account/user`);
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
      push(nextUrl || `/account/user`);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id: untouchedInstance.id,
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  if (!formLoaded) {
    return <OverlaySpinner />;
  }

  return (
    <>
      <SC.FormArea>
        {loading && <OverlaySpinner />}
        <TextInput storeName={storeName} fieldKey={"name"} method={method} />

        <PrimaryButton
          newDesign
          clicked={onSubmit}
          title={method === "POST" ? "Lägg till" : "Uppdatera"}
          disabled={!canSubmit || !hasChanged}
        />
      </SC.FormArea>
    </>
  );
};
