import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "./styles";

// store, design
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    extraStyles,
    type,
    instructionsKey,
    noTitle,
    noMargin,
    rowSize,
    disabled,
    children,
    title,
    description,
    placeholder,
    noBorder,
    noResize,
    hideErrors,

    // explicitly specified rows
    rows,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    let error = useFormError({ storeName, fieldKey });
    if (hideErrors) error = null;

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {!noTitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.InputFieldTitle>
              {title || instructions._label}
              {instructions._required ? "*" : ""}
            </SC.InputFieldTitle>
            {(instructions._helpText || description) && (
              <DescriptionToolTip
                description={description || instructions._helpText}
              />
            )}
          </div>
        )}
        <SC.InputFieldWrapper {...{ noBorder }} error={!!error}>
          <SC.TextField
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            maxLength={instructions.max_length}
            placeholder={placeholder || ""}
            id={instructions._internalId}
            required={instructions._required}
            disabled={disabled}
            type={type || "text"}
            {...{ rowSize, placeholder, noResize, rows }}
          />
        </SC.InputFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        {children && children}
      </SC.InputSpacing>
    );
  }
);
