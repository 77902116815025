import * as React from "react";
import { useParams } from "react-router";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useCustomer } from "../../../store/customers";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import ManagementContractTable from "../../../components/Lists/ManagementContracts/FullTable";
import BasicDocTable from "../../../components/Lists/BasicDocs/FullTable";
import { buildQueryString } from "../../../store/base";

export default function CustomerContracts() {
  const { customerId } = useParams();
  const [customer] = useCustomer(customerId);

  const contractQuery = buildQueryString({ opponent: customerId });
  const contractFilter = (contract) => {
    return contract?.opponent?.id === parseInt(customerId);
  };

  const basicQuery = buildQueryString({
    customer: customerId,
  });
  const basicFilter = (basic) =>
    basic.content_type === "management.customer" &&
    basic.object_id === parseInt(customerId);

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Förvaltningsavtal</OverviewTitle>
        </OverviewTitleWrapper>
        <ManagementContractTable
          persistantQueryString={contractQuery}
          persistantFilterMethod={contractFilter}
          hideSearch
          hideExport
          hideFilters
          hideTitle
        />
      </DetailPageBox>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Dokument</OverviewTitle>
        </OverviewTitleWrapper>
        <BasicDocTable
          persistantQueryString={basicQuery}
          persistantFilterMethod={basicFilter}
          //   toggleFormCallback={canAddLease ? initiateLeaseCreation : undefined}
          hideSearch
          hideExport
          hideFilters
          hideTitle
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
