import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import { DetailInnerWrapper } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import InvoicingCompanyRedirect from "./redirects/Company";
import InvoicingCostCenterRedirect from "./redirects/CostCenter";
import InvoicingDebtorRedirect from "./redirects/Debtor";
import InvoicingLeaseRedirect from "./redirects/InvoicingLease";
import InvoicingProductRedirect from "./redirects/Product";
import InvoicingProjectRedirect from "./redirects/Project";

function ErrorRedirect() {
  const { path, url } = useRouteMatch();

  const query = useQuery();
  const contentType = query.get("contentType");
  const id = query.get("id");

  // list of possible redirects, used to match billecta out error object id to actual frontend page
  const SUB_PAGES = [
    {
      path: "/invoicinglease/:id",
      label: "lease_invoicing to lease_contract",
      component: InvoicingLeaseRedirect,
    },

    {
      path: "/invoicingcompany/:id",
      label: "company",
      component: InvoicingCompanyRedirect,
    },
    {
      path: "/invoicingproject/:id",
      label: "project",
      component: InvoicingProjectRedirect,
    },
    {
      path: "/invoicingproduct/:id",
      label: "product",
      component: InvoicingProductRedirect,
    },
    {
      path: "/invoicingcostcenter/:id",
      label: "costcenter",
      component: InvoicingCostCenterRedirect,
    },
    {
      path: "/invoicingdebtor/:id",
      label: "debtor",
      component: InvoicingDebtorRedirect,
    },
  ];

  return (
    <>
      <DetailInnerWrapper>
        <FullPageSpinner />

        <OverviewTitleWrapper>
          <OverviewTitle>Skickar vidare till rätt sida...</OverviewTitle>
        </OverviewTitleWrapper>
      </DetailInnerWrapper>
      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        <Redirect exact to={`${url}/${contentType}/${id}`} path={`${path}`} />
      </Switch>
    </>
  );
}

export default ErrorRedirect;
