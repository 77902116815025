import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  invoiceDetailUrl,
  getInvoiceIdPreview,
  attestInvoices,
} from "../../../store/billectaInvoicing";
import { useDispatch, useSelector } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import moment from "moment";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import {
  getAllInvoices,
  mergeInvoices,
  matchCreditToDebit,
  setInvoicesClosedFromDate,
} from "../../../store/billectaInvoicing/store/actions";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import CreateInvoiceController from "../../../components/Billecta/Invoices/CreateInvoice/Controller";
import ContainerSpinner from "../../../components/Loaders/ContainerSpinner";
import InvoicesTable from "../../../components/Billecta/Invoices/InvoicesTable";
import StandardModal from "../../../components/Modals/StandardModal";
import { InfoBox } from "../../../components/Displays";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";

export default function Invoices() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { creditorId } = useParams();

  const [createManualInvoiceOpen, setCreateManualInvoiceOpen] =
    React.useState(false);
  const [mergeInvoicesOptionsOpen, setMergeInvoicesOptionsOpen] =
    React.useState(false);
  const [mergeInvoicesInvoiceDate, setMergeInvoicesInvoiceDate] =
    React.useState(null);
  const [mergeInvoicesDueDate, setMergeInvoicesDueDate] = React.useState(null);
  const [mergeTenantsCount, setMergeTenantsCount] = React.useState(0);
  const [mergeInvoicesCount, setMergeInvoicesCount] = React.useState(0);
  const [mergeInvoicesLoading, setMergeInvoicesLoading] = React.useState(false);

  const [selectedInvoices, setSelectedInvoices] = React.useState([]);
  const [confirmFunction, setConfirmFunction] = React.useState(null);
  const [confirmTitle, setConfirmTitle] = React.useState("");

  const handleSelectedInvoicesUpdated = React.useCallback((invoices) => {
    setSelectedInvoices(invoices || []);
  }, []);

  const invoices = useSelector((state) => state.billectaInvoicing.invoices);
  const invoicesClosedFromDate = useSelector(
    (state) => state.billectaInvoicing.invoicesClosedFromDate
  );

  const closeCreateInvoice = () => {
    setCreateManualInvoiceOpen(false);
  };

  const handleDownloadInvoice = (invoice) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Hämtar faktura...",
      })
    );
    dispatch(
      getInvoiceIdPreview({
        invoiceId: invoice.ActionPublicId,
        download: true,
      })
    );
  };

  const handleConfirmAttestSelectedInvoices = () => {
    setConfirmTitle(
      `Är du säker på att du vill attestera ${selectedInvoices.length} faktur${
        selectedInvoices.length > 1 ? "or" : "a"
      }?`
    );
    setConfirmFunction(() => handleAttestSelectedInvoices);
  };

  const handleAttestSelectedInvoices = () => {
    dispatch(
      attestInvoices({
        idArray: selectedInvoices.map((i) => i.original.ActionPublicId),
        creditorId,
      })
    );
  };

  const handleConfirmMatchInvoices = () => {
    setConfirmTitle(`Är du säker på att du vill matcha kredit mot debet?`);
    setConfirmFunction(() => handleMatchSelectedInvoices);
  };

  const handleMatchSelectedInvoices = () => {
    const creditInvoiceActionId = selectedInvoices?.find(
      (c) => c.original.ActionType === "CreditInvoiceAction"
    )?.original?.ActionPublicId;

    const debitInvoiceActionId = selectedInvoices?.find(
      (c) => c.original.ActionType === "InvoiceAction"
    )?.original?.ActionPublicId;

    dispatch(
      matchCreditToDebit({
        creditInvoiceActionId,
        debitInvoiceActionId,
        creditorId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Kredit matchades mot debet",
            })
          );
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kunde ej matcha kredit mot debet ",
            })
          );
        },
      })
    );
  };

  const handleRowClicked = (row) => {
    push(
      invoiceDetailUrl({
        creditorId,
        invoiceId: row.original.ActionPublicId,
      })
    );
  };

  const handleMergeInitiated = () => {
    const selInvoices = selectedInvoices?.map((s) => s.original);

    const invoicesCount = selInvoices?.length;
    const uniqueTenantsCount = selInvoices?.reduce((acc, cur) => {
      if (acc.includes(cur.DebtorPublicId)) return acc;

      acc.push(cur.DebtorPublicId);

      return acc;
    }, [])?.length;

    const earliestInvoiceDate = selInvoices.reduce((acc, cur) => {
      const curDate = moment(cur.InvoiceDate);
      if (!acc) return curDate.format("YYYY-MM-DD");

      const accDate = moment(acc);

      if (accDate.isAfter(curDate)) return curDate.format("YYYY-MM-DD");

      return accDate.format("YYYY-MM-DD");
    }, null);

    const latestDueDate = selInvoices.reduce((acc, cur) => {
      const curDate = moment(cur.DueDate);
      if (!acc) return curDate.format("YYYY-MM-DD");

      const accDate = moment(acc);

      if (accDate.isBefore(curDate)) return curDate.format("YYYY-MM-DD");

      return accDate.format("YYYY-MM-DD");
    }, null);

    setMergeInvoicesInvoiceDate(earliestInvoiceDate);
    setMergeInvoicesDueDate(latestDueDate);
    setMergeTenantsCount(uniqueTenantsCount);
    setMergeInvoicesCount(invoicesCount);

    setMergeInvoicesOptionsOpen(true);
  };

  const handleMergeInvoices = () => {
    setMergeInvoicesLoading(true);

    dispatch(
      mergeInvoices({
        invoices: selectedInvoices?.map((s) => s.original),
        invoiceDate: mergeInvoicesInvoiceDate,
        dueDate: mergeInvoicesDueDate,
        successCallback: ({
          couldNotMergeCount,
          couldMergeCount,
          errorResults,
          validResults,
        }) => {
          setMergeInvoicesLoading(false);
          setMergeInvoicesOptionsOpen(false);

          if (couldMergeCount > 0 && validResults?.length > 0) {
            dispatch(
              addToast({
                type: TOAST_TYPES.SUCCESS,
                title: "Fakturorna sammanfördes",
              })
            );
          }

          if (couldNotMergeCount > 0) {
            dispatch(
              addToast({
                type: TOAST_TYPES.INFO,
                title: `${couldNotMergeCount} fakturor sammanfördes inte`,
                description:
                  "För att sammanföras krävs det minst två fakturor till samma motpart",
              })
            );
          }

          if (errorResults?.length > 0) {
            dispatch(
              addToast({
                type: TOAST_TYPES.ERROR,
                title: `Ett eller fler försök till sammanslagning misslyckades`,
                description: "Kontrollera datan och försök igen",
              })
            );
          }

          dispatch(getAllInvoices(creditorId));
        },
        errorCallback: (message) => {
          setMergeInvoicesLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message || "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const renderClosedDatePicker = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 8 }}>Visa fakturor som är stängda sedan</div>

        <NonConnectedDatePicker
          inputExtraStyle={{ maxWidth: 160 }}
          id="billecta_date_from"
          value={
            invoicesClosedFromDate ||
            moment().subtract(3, "months").format("YYYY-MM-DD")
          }
          onChange={(date) => {
            dispatch(
              setInvoicesClosedFromDate({
                date,
                creditorId,
              })
            );
          }}
          clearable={false}
          noMargin
          maxDate={moment().toDate()}
        />
      </div>
    );
  };

  const today = moment();
  const attestActionDisabled = selectedInvoices.some(
    (i) =>
      i.original.AttestedDate || moment(i.original.InvoiceDate).isAfter(today)
  );

  const mergeActionDisabled = selectedInvoices.some(
    (i) => i.original.AttestedDate
  );

  const oneCreditInvoiceSelected =
    selectedInvoices?.filter(
      (c) => c.original.ActionType === "CreditInvoiceAction"
    )?.length === 1;
  const nonCreditInvoice = selectedInvoices?.filter(
    (c) => c.original.ActionType === "InvoiceAction"
  );
  const nonCreditAttested =
    nonCreditInvoice?.length === 1 &&
    nonCreditInvoice.every((i) => i.original.AttestedDate != null);

  const nonCreditInvoiceDebtorId =
    nonCreditInvoice?.[0]?.original.DebtorPublicId;

  const isSameDebtor = selectedInvoices?.every(
    (i) => i.original.DebtorPublicId === nonCreditInvoiceDebtorId
  );

  const anyCompleted = selectedInvoices.some(
    (i) => i.original.Stage === "Completed"
  );

  const creditInvoiceEnabled =
    oneCreditInvoiceSelected &&
    nonCreditAttested &&
    isSameDebtor &&
    !anyCompleted;

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmFunction}
        closeFunction={() => setConfirmFunction(null)}
        acceptCallback={confirmFunction}
        title={confirmTitle}
      />

      <CreateInvoiceController
        {...{
          createManualInvoiceOpen,
          closeCreateInvoice,
        }}
      />

      <StandardModal
        title="Sammanför fakturor till samma kund"
        isOpen={mergeInvoicesOptionsOpen}
        closeFunction={() => setMergeInvoicesOptionsOpen(false)}
        withActionBar
        canAccept={!!mergeInvoicesDueDate && !!mergeInvoicesInvoiceDate}
        saveFunction={handleMergeInvoices}
        actionBarAcceptTitle={`Sammanför ${mergeInvoicesCount} fakturor`}
      >
        {mergeInvoicesLoading && <OverlaySpinner />}
        <InfoBox
          title="Sammanför fakturor till samma kund"
          text={`${mergeInvoicesCount} fakturor till ${mergeTenantsCount} ${
            mergeTenantsCount > 1 ? `olika kunder` : "kund"
          } kommer sammmanföras till ${mergeTenantsCount} ${
            mergeTenantsCount > 1 ? "nya fakturor" : "ny faktura"
          }. De existerande fakturorna kommer raderas. Välj fakturadatum samt förfallodatum för samlingsfakturorna. Det tidigaste fakturadatumet samt det senaste förfallodatumet har föreslagits automatiskt.`}
        />

        <NonConnectedDatePicker
          id="merge_from_date"
          value={mergeInvoicesInvoiceDate}
          onChange={(val) => setMergeInvoicesInvoiceDate(val)}
          label="Fakturadatum på samlingsfaktura"
        />

        <NonConnectedDatePicker
          id="merge_to_date"
          value={mergeInvoicesDueDate}
          onChange={(val) => setMergeInvoicesDueDate(val)}
          label="Förfallodatum på samlingsfaktura"
        />
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Fakturor</OverviewTitle>

            <PrimaryButton
              title="Skapa faktura"
              clicked={() => setCreateManualInvoiceOpen(true)}
            />
          </OverviewTitleWrapper>

          {!!invoices ? (
            <InvoicesTable
              invoices={invoices}
              handleDownloadInvoice={handleDownloadInvoice}
              handleSelectedInvoicesUpdated={handleSelectedInvoicesUpdated}
              selectedInvoices={selectedInvoices}
              handleAttestSelectedInvoices={handleConfirmAttestSelectedInvoices}
              handleMergeSelectedInvoices={handleMergeInitiated}
              attestSelectedDisabled={attestActionDisabled}
              mergeActionDisabled={mergeActionDisabled}
              handleRowClicked={handleRowClicked}
              renderBesideSearch={renderClosedDatePicker}
              handleMatchCreditToSelectedDebitInvoices={
                handleConfirmMatchInvoices
              }
              creditMatchActionDisabled={!creditInvoiceEnabled}
            />
          ) : (
            <ContainerSpinner />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
