import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { destroyForm } from "../../../store/base";

import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { PrimaryButton } from "../Base/Buttons";
import { DatePicker } from "../Base/Fields";

export default ({
  id,
  setCurrentMainTab,
  constants,
  updateFunction,
  canEdit,
  formLoaded,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;
  const method = "PATCH";

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    dataClone.is_signed = true;

    return dataClone;
  };

  const checkout = (success) => {
    dispatch(destroyForm({ constants, method, success }));
  };

  const onError = () => {
    setLoading(false);
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    if (setCurrentMainTab) {
      setCurrentMainTab("OVERVIEW");
    }
  };

  const onSubmit = (draft = false) => {
    setLoading(true);

    dispatch(
      updateFunction({
        id,
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess(data, draft),
      })
    );
  };

  if (!formLoaded || !canEdit) return null;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative",
      }}
    >
      {loading && <OverlaySpinner />}
      <DatePicker
        storeName={storeName}
        fieldKey="signed_date"
        method={method}
      />

      <div>
        <PrimaryButton title="Markera som signerat" clicked={onSubmit} />
      </div>
    </div>
  );
};
