export default function validateSave({ instance, isTemplate }) {
  const errors = [];

  if (!instance.title) {
    errors.push(
      `${
        isTemplate ? "Mallen" : "Dokumentet"
      } saknar en titel. Sätt en titel under "Övriga inställningar"`
    );
  }

  return [errors.length === 0, errors];
}
