import React from "react";
import * as SC from "../styles";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { constants } from "../../../../../store/userGroups";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { getModalInformation } from "./infoItems";
import Toggle from "../../../../Toggle/Toggle";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";

export default function SingleChapterRow({
  title,
  perms,
  showPermsInfo,
  group,
  disabled,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const pickedPerms = group?.permissions;

  const handlePermToggle = (p) => {
    let pickedPermsClone = cloneDeep(pickedPerms);

    const [kind, can, base, rest] = p.split("_");
    const isView = kind === "view";
    const viewPerm = `view_${can}_${base}${rest ? `_${rest}` : ""}`;

    if (pickedPermsClone.includes(p)) {
      if (isView) {
        if (rest) {
          let newBase = `${base}_${rest}`;
          pickedPermsClone = pickedPermsClone.filter(
            (i) => !i.includes(newBase)
          );
        } else {
          pickedPermsClone = pickedPermsClone.filter((i) => !i.includes(base));
        }
      } else {
        pickedPermsClone = pickedPermsClone.filter((i) => i !== p);
      }
    } else {
      pickedPermsClone.push(p);
      if (!isView && !pickedPerms?.includes(viewPerm)) {
        pickedPermsClone.push(viewPerm);
      }
    }

    //Extracheck to add baseobject perms if contract perms are toggled

    if (base === "contract") {
      if (
        pickedPermsClone.includes(`${kind}_can_baseobject`) &&
        !pickedPermsClone.includes(p)
      ) {
      } else {
        pickedPermsClone.push(`${kind}_can_baseobject`);
        if (!isView && !pickedPerms?.includes("view_can_baseobject")) {
          pickedPermsClone.push("view_can_baseobject");
        }
      }
    }

    if (base === "baseobject") {
      if (
        pickedPermsClone.includes(`${kind}_can_contract`) &&
        !pickedPermsClone.includes(p)
      ) {
        if (isView) {
          pickedPermsClone = pickedPermsClone.filter(
            (i) => !i.includes("contract")
          );
        } else {
          pickedPermsClone = pickedPermsClone.filter(
            (i) => i !== `${kind}_can_contract`
          );
        }
      }
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          permissions: pickedPermsClone,
        },
      })
    );
  };

  const renderPermToggles = () => {
    const toggles = perms?.map((p) => {
      const isActive = pickedPerms?.includes(p);
      return (
        <Toggle
          disabled={disabled}
          key={p}
          isRedGreen
          extraStyle={{ margin: "4px 0" }}
          value={isActive}
          onToggle={() => {
            handlePermToggle(p);
          }}
          title=""
          extraStyle={{ flex: 1, justifyContent: "flex-start" }}
        />
      );
    });

    return toggles;
  };

  return (
    <>
      <SC.ChapterRow>
        <SC.HeaderTitle
          icon
          style={{ flex: 2 }}
          onClick={() => showPermsInfo(getModalInformation(title))}
        >
          {title}
        </SC.HeaderTitle>
        {perms ? renderPermToggles() : <OverlaySpinner />}

        <SC.TableFiller />
      </SC.ChapterRow>
    </>
  );
}
