import * as React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Pikaday from "pikaday";
import { uid } from "uid";

import "../../../../../node_modules/pikaday/css/pikaday.css";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

const PICKER_i18n = {
  previousMonth: "Föregående månad",
  nextMonth: "Nästa månad",
  months: [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ],
  weekdays: [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
  ],
  weekdaysShort: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
};

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    instructionsKey,
    format,
    noTitle,
    noMargin,
    fillWidth = false,
    extraStyle = {},
    placeholder,
    disabled,
    children,
    title,
    subtitle,
    description,
    defaultNow = false,
    forceRequired,
    minDate,
    maxDate,
  }) => {
    const dispatch = useDispatch();
    const [dp, setDp] = React.useState(null);
    const usedFormat = format || "YYYY-MM-DD";

    const pickerRef = React.useRef(null);

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });
    const didDefault = React.useRef(null);

    React.useEffect(() => {
      if (didDefault.current || value || !defaultNow) {
        return;
      }
      const today = moment().format(usedFormat);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: today },
        })
      );
      didDefault.current = true;
    }, [defaultNow, value]);

    React.useEffect(() => {
      if (value && typeof value === "string" && value.includes("+")) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [fieldKey]: moment(value).format(usedFormat) },
          })
        );
      }
    }, [value]);

    const onChange = React.useCallback(
      (data) => {
        const date = moment(data).format(usedFormat);
        dispatch(
          updateActiveFormInstance({ storeName, data: { [fieldKey]: date } })
        );
      },
      [dispatch, fieldKey, storeName, usedFormat]
    );

    const clearDate = (e) => {
      e.stopPropagation();
      dp.setDate(null);
      const formattedData = instructions._allowNull ? null : undefined;
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
    };

    React.useEffect(() => {
      if (dp || !pickerRef.current) return;

      const datepicker = new Pikaday({
        field: pickerRef.current,
        format: usedFormat,
        defaultDate: value ? moment(value).toDate() : moment().toDate(),
        setDefaultDate: value ? true : false,
        onSelect: onChange,
        i18n: PICKER_i18n,
        firstDay: 1,
        minDate,
        maxDate,
      });
      setDp(datepicker);
    }, [dp, onChange, usedFormat, value, instructions]);

    React.useEffect(() => {
      if (!dp) return;
      if (!moment(dp.getMoment()).isSame(moment(value))) {
        dp.setDate(value);
      }
    }, [value]);

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing
        style={{ ...extraStyle, display: "flex", flexDirection: "column" }}
        {...{ noMargin }}
      >
        {!noTitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.InputFieldTitle>
              {title}
              {instructions._required || forceRequired ? "*" : ""}
            </SC.InputFieldTitle>
            {description && <DescriptionToolTip description={description} />}
          </div>
        )}

        {subtitle && (
          <SC.InputFieldDescription>{subtitle}</SC.InputFieldDescription>
        )}

        <div
          style={{
            position: "relative",
            display: "inline-block",
            marginRight: "auto",
          }}
        >
          <SC.DatePickerWrapper
            {...{ fillWidth }}
            type="text"
            autoComplete={uid(16)}
            placeholder={placeholder || "Välj datum"}
            ref={pickerRef}
            id={instructions._internalId}
            name={uid(16)} // prevents chrome ignoring turning autocomplete off by simply always presenting the field as something it has never seen before
            disabled={disabled}
          />
          {value && !instructions._required && !forceRequired && !disabled && (
            <SC.ClearDateButton onClick={clearDate} />
          )}
        </div>

        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        <div>{children && children}</div>
      </SC.InputSpacing>
    );
  }
);
