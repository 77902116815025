import * as React from "react";
import { useFormInstanceField } from "../../../store/base";
import * as SC from "./styles";
import { constants, overviewUrl } from "../../../store/editabledocs";
import HandleParties from "./HandleParties";
import HandleAddField from "./HandleAddField";
import HandleSettings from "./HandleSettings";
import HandleAttachments from "./HandleAttachments";
import { TextButton } from "../../Forms/Base/Buttons";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import { useHistory } from "react-router";

const OPTIONS = {
  HANDLE_PARTIES: 0,
  ADD_FIELD: 1,
  ATTACHMENTS: 2,
  SETTINGS: 3,
};

function EditableDocTopBar({
  detailUrl,
  method,
  contract,
  isTemplate,
  isCancelledDoc,
}) {
  const storeName = constants.STORE_NAME;
  const [activeOption, setActiveOption] = React.useState(null);
  const [confirmGoBackTitle, setConfirmGoBackTitle] = React.useState(null);
  const [confirmGoBackMethod, setConfirmGoBackMethod] = React.useState(null);
  const { push } = useHistory();

  const menuContainerRef = React.useRef();
  const partiesOptionRef = React.useRef();
  const fieldsOptionRef = React.useRef();
  const attachmentOptionRef = React.useRef();
  const settingsOptionRef = React.useRef();

  const parties = useFormInstanceField({
    storeName,
    fieldKey: "parties",
  });

  const docName = useFormInstanceField({
    storeName,
    fieldKey: "title",
  });

  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (
      menuContainerRef?.current?.contains(e.target) ||
      partiesOptionRef?.current?.contains(e.target) ||
      fieldsOptionRef?.current?.contains(e.target) ||
      attachmentOptionRef?.current?.contains(e.target) ||
      settingsOptionRef?.current?.contains(e.target)
    ) {
      // inside click
      return;
    }
    // outside click
    setActiveOption(null);
  };

  const renderMenuContent = () => {
    switch (activeOption) {
      case OPTIONS.HANDLE_PARTIES:
        return <HandleParties {...{ isTemplate, method, parties }} />;
      case OPTIONS.ADD_FIELD:
        return (
          <HandleAddField
            {...{ isTemplate, parties }}
            closeFunction={() => setActiveOption(null)}
          />
        );
      case OPTIONS.SETTINGS:
        return <HandleSettings method={method} {...{ isTemplate }} />;

      case OPTIONS.ATTACHMENTS:
        return <HandleAttachments method={method} />;
      default:
        return null;
    }
  };

  const handleGoToContract = () => {
    push(detailUrl({ id: contract.id }));
  };

  const handleGoToTemplateOverview = () => {
    push(overviewUrl());
  };

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmGoBackTitle}
        title={confirmGoBackTitle}
        renderContent={() => (
          <div>
            Alla ändringar som gjorts kommer att gå förlorade. Detta går ej att
            ångra.
          </div>
        )}
        closeFunction={() => {
          setConfirmGoBackMethod(null);
          setConfirmGoBackTitle(null);
        }}
        acceptCallback={confirmGoBackMethod}
        acceptTitle={"Gå vidare"}
      />

      <SC.TopBarContainer>
        <SC.TitleContainer>
          <TextButton
            extraStyle={{ flex: 1 }}
            title={`Avbryt och gå till ${
              isTemplate ? "mallöversikt" : "avtal"
            }`}
            iconType="arrow-back"
            clicked={() => {
              if (isTemplate) {
                setConfirmGoBackTitle(
                  `Avbryt ${
                    method === "POST" ? "skapande" : "redigering"
                  } av mall`
                );
                setConfirmGoBackMethod(() => handleGoToTemplateOverview);
              } else {
                setConfirmGoBackTitle(
                  `Avbryt redigering av signeringsdokument`
                );
                setConfirmGoBackMethod(() => handleGoToContract);
              }
            }}
          />

          <div
            style={{
              flex: 1,
              textAlign: "center",
              fontWeight: 500,
              color: "#444A55",
            }}
          >
            {method === "POST" ? "Skapar " : "Redigerar "}
            {isTemplate ? "mall" : "signeringsdokument"}
          </div>

          <TextButton
            extraStyle={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
            red={!docName}
            title={
              !docName
                ? `${isTemplate ? "Mallen" : "Dokumentet"} saknar titel. ${
                    activeOption !== OPTIONS.SETTINGS
                      ? "Tryck för att ställa in"
                      : ""
                  }`
                : `Namn på ${isTemplate ? "mall" : "dokument"}: ${docName}`
            }
            iconType="edit"
            clicked={() => setActiveOption(OPTIONS.SETTINGS)}
          />
        </SC.TitleContainer>
        <hr style={{ width: "98%", margin: 0 }} />
        <SC.InnerContainer>
          {activeOption != null && (
            <SC.MenuContainer ref={menuContainerRef}>
              {renderMenuContent()}
            </SC.MenuContainer>
          )}

          <SC.Option
            ref={partiesOptionRef}
            onClick={() =>
              setActiveOption(
                activeOption === OPTIONS.HANDLE_PARTIES
                  ? null
                  : OPTIONS.HANDLE_PARTIES
              )
            }
            active={activeOption === OPTIONS.HANDLE_PARTIES}
          >
            Hantera parter
          </SC.Option>

          <SC.Option
            ref={fieldsOptionRef}
            onClick={() =>
              setActiveOption(
                activeOption === OPTIONS.ADD_FIELD ? null : OPTIONS.ADD_FIELD
              )
            }
            active={activeOption === OPTIONS.ADD_FIELD}
          >
            Lägg till fält
          </SC.Option>

          <SC.Option
            ref={attachmentOptionRef}
            onClick={() =>
              setActiveOption(
                activeOption === OPTIONS.ATTACHMENTS
                  ? null
                  : OPTIONS.ATTACHMENTS
              )
            }
            active={activeOption === OPTIONS.ATTACHMENTS}
          >
            Bilagor
          </SC.Option>

          {/* Only render settings if template or contract is to be e-signed */}
          {(isTemplate ||
            (isCancelledDoc
              ? contract?.uses_cancellation_e_signing
              : contract?.uses_e_signing)) && (
            <SC.Option
              ref={settingsOptionRef}
              onClick={() =>
                setActiveOption(
                  activeOption === OPTIONS.SETTINGS ? null : OPTIONS.SETTINGS
                )
              }
              active={activeOption === OPTIONS.SETTINGS}
            >
              Övriga inställningar
            </SC.Option>
          )}
        </SC.InnerContainer>
      </SC.TopBarContainer>
    </>
  );
}

export default EditableDocTopBar;
