import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "../styles";

// store, design
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    type,
    instructionsKey,
    fontSize,
    autocomplete,
    name,
    borderColor,
    displayOnlyMode,
  }) => {
    const dispatch = useDispatch();

    const [internalValue, setInternalValue] = React.useState("");

    const actualFieldKey = `${fieldKey}.value`;
    const placeholderFieldKey = `${fieldKey}._placeholder`;
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || actualFieldKey,
    });

    const kind = useFormInstanceField({
      storeName,
      fieldKey: `${fieldKey}.kind`,
    });

    const value = useFormInstanceField({ storeName, fieldKey: actualFieldKey });
    const placeholder = useFormInstanceField({
      storeName,
      fieldKey: placeholderFieldKey,
    });
    const error = useFormError({ storeName, fieldKey: actualFieldKey });

    React.useEffect(() => {
      const stateYearValue = value?.split(" ")[0] || "00";
      const stateMonthValue = value?.split(" ")[1] || "00";

      const formattedValue = `${stateYearValue} år, ${stateMonthValue} månader`;
      setInternalValue(formattedValue);
    }, [value]);

    const onChange = (data) => {
      const yearValue = data.split(" ")[0];
      const monthValue = data.split(" ")[2];

      const stateUpdateValue = `${yearValue} ${monthValue} 00 00`;

      const newInternalValue = `${yearValue} år, ${monthValue} månader`;

      setInternalValue(newInternalValue);

      const isValidShouldUpdate =
        yearValue.length === 2 && monthValue.length === 2;

      if (!isValidShouldUpdate) return;

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [actualFieldKey]: stateUpdateValue },
        })
      );
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <div style={{ display: "flex" }}>
        <SC.PDFInputField
          {...{ displayOnlyMode }}
          value={internalValue}
          name={name || instructions._internalId}
          autocomplete={autocomplete === false ? "false" : ""}
          onChange={({ target: { value } }) => onChange(value)}
          maxLength={instructions.max_length}
          id={instructions._internalId}
          required={instructions._required}
          type={type || "text"}
          style={fontSize ? { fontSize } : null}
          borderColor={borderColor}
          placeholder={placeholder}
        />
      </div>
    );
  }
);
