import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBookKeepingForm } from "../../../store/billectaBookKeeping/hooks/form";
import { usePaymentMeansForm } from "../../../store/billectaPaymentMeans/hooks/form";
import {
  getBookKeepingInstance,
  constants as bookKeepingConstants,
  getPeriodLockPeriod,
  lockPeriod,
  deleteLockPeriod,
} from "../../../store/billectaBookKeeping";
import {
  getPaymentMeansInstance,
  constants as paymentMeanConstants,
} from "../../../store/billectaPaymentMeans";
import { PrimaryButton, TextButton } from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { InvoiceChapter } from "../Invoices/InvoiceComponents";

import * as SC from "../Invoices/styles";
import { Checkbox, Select, TextInput } from "../../Forms/Base/Fields";
import { InfoBox } from "../../Displays";
import { updateBookKeepingSettings } from "../../../store/billectaBookKeeping/store/actions";
import { useParams } from "react-router";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useCompany } from "../../../store/companies";
import { BodyText, InnerBox } from "../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import StandardModal from "../../Modals/StandardModal";
import moment from "moment";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import ConfirmationModal from "../../Modals/ConfirmationModal";

export default function BookKeepingContainer({ forceCompanyId }) {
  const storeName = bookKeepingConstants.STORE_NAME;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  const [lockPeriodOpen, setLockPeriodOpen] = React.useState(false);
  const [lockPeriodConfirmOpen, setLockPeriodConfirmOpen] =
    React.useState(false);
  const [lockPeriodDate, setLockPeriodDate] = React.useState(
    moment().subtract({ month: 1 }).endOf("month").format("YYYY-MM-DD")
  );
  const [lockPeriodLoading, setLockPeriodLoading] = React.useState(false);
  const [confirmRemoveLockPeriodOpen, setConfirmRemoveLockPeriodOpen] =
    React.useState(false);

  const { companyId } = useParams();
  const [company] = useCompany(forceCompanyId || companyId);
  const creditorId = company?.billecta_id;

  const bookKeepingInstance = useSelector(
    (state) => state.billectaBookKeeping.formInstance
  );
  const paymentMeansInstance = useSelector(
    (state) => state.billectaPaymentMeans.formInstance
  );
  const periodLockPeriod = useSelector(
    (state) => state.billectaBookKeeping[`periodLockPeriod_${creditorId}`]
  );

  const restingVatIsEnabled = useFormInstanceField({
    storeName,
    fieldKey: "RestingVatIsEnabled",
  });

  const accrualIsEnabled = useFormInstanceField({
    storeName,
    fieldKey: "AccrualIsEnabled",
  });

  useBookKeepingForm("PUT");
  usePaymentMeansForm("PUT");

  React.useEffect(() => {
    if (!Object.keys(bookKeepingInstance).length && creditorId) {
      dispatch(
        getBookKeepingInstance({
          creditorId,
        })
      );
    }
  }, [creditorId]);

  React.useEffect(() => {
    if (!Object.keys(paymentMeansInstance).length && creditorId) {
      dispatch(
        getPaymentMeansInstance({
          creditorId,
        })
      );
    }
  }, [creditorId]);

  React.useEffect(() => {
    if (creditorId && !periodLockPeriod) {
      dispatch(getPeriodLockPeriod({ creditorId }));
    }
  }, [periodLockPeriod, creditorId]);

  React.useEffect(() => {
    if (accrualIsEnabled && restingVatIsEnabled) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            AccrualIsEnabled: false,
          },
        })
      );
    }
  }, [accrualIsEnabled, restingVatIsEnabled]);

  React.useEffect(() => {
    if (
      Object.keys(paymentMeansInstance).length &&
      Object.keys(bookKeepingInstance).length
    ) {
      setLoading(false);
    }
  }, [paymentMeansInstance, bookKeepingInstance]);

  React.useEffect(() => {
    return () => {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: paymentMeanConstants.STORE_NAME,
          data: {},
        })
      );
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      updateBookKeepingSettings({
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              title: "Uppdateringen lyckades",
              type: TOAST_TYPES.SUCCESS,
            })
          );
        },
        errorCallback: (message) => {
          setLoading(false);
          dispatch(
            addToast({
              title: "Inställningarna kunde inte uppdateras",
              description: message ? message : "Något gick fel. Försök igen.",
              type: TOAST_TYPES.ERROR,
            })
          );
        },
        creditorId,
      })
    );
  };

  return (
    <>
      <ConfirmationModal
        isOpen={confirmRemoveLockPeriodOpen}
        closeFunction={() => setConfirmRemoveLockPeriodOpen(false)}
        title="Bekräfta borttagande av låsning"
        renderContent={() => (
          <InfoBox
            title="Borttagande av låsning"
            text="Detta innebär att den tidigare låsta bokföringsperioden återigen blir öppen för händelser som leder till ändringar i bokföringen."
          />
        )}
        acceptCallback={() => {
          setLockPeriodLoading(true);
          dispatch(
            deleteLockPeriod({
              creditorId,
              successCallback: () => {
                setLockPeriodLoading(false);
                dispatch(
                  addToast({
                    type: TOAST_TYPES.SUCCESS,
                    title: `Bokföringslåsningen togs bort`,
                  })
                );
              },
              errorCallback: (message) => {
                setLockPeriodLoading(false);
                dispatch(
                  addToast({
                    type: TOAST_TYPES.ERROR,
                    title: "Något gick fel",
                    description: message || "Låsningen kunde inte tas bort",
                  })
                );
              },
            })
          );
        }}
      />

      <ConfirmationModal
        isOpen={lockPeriodConfirmOpen}
        title="Bekräfta låsning av period"
        renderContent={() => (
          <InfoBox
            boxTheme="warning"
            title="OBS"
            text="Att låsa perioden innebär att inga fler händelser som påverkar bokföringen kan ske fram till det låsta datumet. Exempelvis kan inte fakturor och kreditfakturor skapas upp med ett fakturadatum som ligger innan det låsta datumet. Vänligen bekräfta att detta stämmer."
          />
        )}
        closeFunction={() => setLockPeriodConfirmOpen(false)}
        acceptCallback={() => {
          setLockPeriodLoading(true);
          dispatch(
            lockPeriod({
              creditorId,
              date: lockPeriodDate,
              successCallback: () => {
                setLockPeriodLoading(false);
                dispatch(
                  addToast({
                    type: TOAST_TYPES.SUCCESS,
                    title: `Bokföringen låstes fram till ${lockPeriodDate}`,
                  })
                );
              },
              errorCallback: (message) => {
                setLockPeriodLoading(false);
                dispatch(
                  addToast({
                    type: TOAST_TYPES.ERROR,
                    title: "Något gick fel",
                    description: message || "Perioden kunde inte låsas",
                  })
                );
              },
            })
          );
        }}
        acceptTitle="Bekräfta"
      />

      <StandardModal
        isOpen={lockPeriodOpen}
        closeFunction={() => setLockPeriodOpen(false)}
        title="Lås bokföring fram till ett visst datum"
        withActionBar
        small
        saveFunction={() => {
          setLockPeriodConfirmOpen(true);
          setLockPeriodOpen(false);
        }}
        actionBarAcceptTitle="Lås bokföring"
      >
        <InnerBox>
          <NonConnectedDatePicker
            value={lockPeriodDate}
            label="Lås bokföring till och med"
            helpText=""
            id="lock_period"
            onChange={(date) => setLockPeriodDate(date)}
            clearable={false}
            maxDate={moment().subtract({ days: 1 }).toDate()}
          />
        </InnerBox>
      </StandardModal>

      {loading && <OverlaySpinner />}
      <InfoBox
        boxTheme="info"
        title="Information om bokföringsinställningar"
        text="Uppgifterna ligger som underlag till exporten av
        bokföringsunderlag. Är du osäker vad du ska ange bör du kontakta din
        bokföringskonsult."
      />

      <InnerBox style={{ marginBottom: 24 }}>
        {lockPeriodLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Låsning av bokföringsperioder</OverviewTitle>
            <OverviewSubtitle>
              Genom att låsa bokföringen fram till ett visst datum förhindras
              nya bokföringshändelser fram till det låsta datumet. Detta innebär
              t.ex. att inga fakturor med ett fakturadatum till och med det
              låsta datumet kan attesteras.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {periodLockPeriod ? (
          <BodyText>
            <strong>
              Bokföringen är låst fram till och med{" "}
              {moment(periodLockPeriod).format("YYYY-MM-DD")}
            </strong>
          </BodyText>
        ) : (
          <>
            <BodyText>
              <strong>Bokföringen är ej låst fram till ett visst datum.</strong>{" "}
              Tryck på "Lås bokföring fram till ett visst datum" för att låsa
              bokföringen.
            </BodyText>
          </>
        )}

        <TextButton
          extraStyle={{ marginTop: 24 }}
          title="Lås bokföring fram till ett visst datum"
          iconType="lock"
          iconPlacement="right"
          clicked={() => setLockPeriodOpen(true)}
        />

        {periodLockPeriod && (
          <TextButton
            extraStyle={{ marginTop: 12 }}
            red
            title="Ta bort låsning av bokföring"
            iconType="close"
            iconPlacement="right"
            clicked={() => setConfirmRemoveLockPeriodOpen(true)}
          />
        )}
      </InnerBox>

      <InvoiceChapter
        hideToggle
        title="Generella inställningar"
        forceOpen={true}
        description="Inställingar för vilka standarder exporten av bokföringsunderlag ska ta hänsyn till"
      >
        <SC.InvoicedBoxContent>
          <SC.InvoicedBoxRow>
            <SC.InvoicedBoxRowTitle>Verifikationsserie</SC.InvoicedBoxRowTitle>
            <SC.InvoicedBoxRowValue>
              <TextInput
                noMargin
                noTitle
                storeName={bookKeepingConstants.STORE_NAME}
                method={"PUT"}
                fieldKey="VoucherSeries"
              />
            </SC.InvoicedBoxRowValue>
          </SC.InvoicedBoxRow>
          <SC.InvoicedBoxRow>
            <SC.InvoicedBoxRowTitle>Räkenskapsår</SC.InvoicedBoxRowTitle>
            <SC.InvoicedBoxRowValue>
              <Select
                noMargin
                noTitle
                storeName={bookKeepingConstants.STORE_NAME}
                method={"PUT"}
                fieldKey="FiscalYear"
              />
            </SC.InvoicedBoxRowValue>
          </SC.InvoicedBoxRow>

          <SC.InvoicedBoxRow>
            <SC.InvoicedBoxRowTitle>Bokföringsmetod</SC.InvoicedBoxRowTitle>
            <SC.InvoicedBoxRowValue>
              <Select
                noMargin
                noTitle
                storeName={bookKeepingConstants.STORE_NAME}
                method={"PUT"}
                fieldKey="BookKeepingMethod"
              />
            </SC.InvoicedBoxRowValue>
          </SC.InvoicedBoxRow>

          <SC.InvoicedBoxRow>
            <SC.InvoicedBoxRowTitle>
              Periodisering{" "}
              <strong style={{ textDecoration: "underline" }}>utan</strong>{" "}
              vilande moms
            </SC.InvoicedBoxRowTitle>
            <SC.InvoicedBoxRowValue>
              <Checkbox
                noMargin
                noTitle
                title={accrualIsEnabled ? "Aktiv" : "Inaktiv"}
                storeName={bookKeepingConstants.STORE_NAME}
                method={"PUT"}
                fieldKey="AccrualIsEnabled"
              />
            </SC.InvoicedBoxRowValue>
          </SC.InvoicedBoxRow>

          <SC.InvoicedBoxRow>
            <SC.InvoicedBoxRowTitle>
              Periodisering{" "}
              <strong style={{ textDecoration: "underline" }}>med</strong>{" "}
              vilande moms
            </SC.InvoicedBoxRowTitle>
            <SC.InvoicedBoxRowValue>
              <Checkbox
                noMargin
                noTitle
                title={restingVatIsEnabled ? "Aktiv" : "Inaktiv"}
                storeName={bookKeepingConstants.STORE_NAME}
                method={"PUT"}
                fieldKey="RestingVatIsEnabled"
              />
            </SC.InvoicedBoxRowValue>
          </SC.InvoicedBoxRow>
        </SC.InvoicedBoxContent>
      </InvoiceChapter>

      <InvoiceChapter
        title="Bokföringskonton"
        description="Inställningar för vilka konton olika typer av transaktioner ska bokas på"
      >
        <SC.InvoicedBoxContent>
          {bookKeepingInstance?.Accounts?.map((account, index) => (
            <SC.InvoicedBoxRow>
              <SC.InvoicedBoxRowTitle>
                {ACCOUNT_MAPPER[`${account.AccountType}${account.VAT || ""}`]}
              </SC.InvoicedBoxRowTitle>
              <SC.InvoicedBoxRowValue>
                <TextInput
                  noMargin
                  noTitle
                  storeName={bookKeepingConstants.STORE_NAME}
                  method={"PUT"}
                  fieldKey={`Accounts[${index}].Account`}
                  instructionsKey="Accounts.Account"
                />
              </SC.InvoicedBoxRowValue>
            </SC.InvoicedBoxRow>
          ))}
        </SC.InvoicedBoxContent>
      </InvoiceChapter>

      <InvoiceChapter
        title="Betalsätt"
        description="Inställningar för betalsätt och vilka konton de ska bokas på"
      >
        <SC.InvoicedBoxContent>
          {Object.keys(paymentMeansInstance)?.map((key) => (
            <>
              <SC.InvoicedBoxRow>
                <SC.InvoicedBoxRowTitle>
                  {paymentMeansInstance[key].PaymentCode}
                </SC.InvoicedBoxRowTitle>
                <SC.InvoicedBoxRowValue style={{ margin: "0 4px" }}>
                  <TextInput
                    noMargin
                    noTitle
                    storeName={paymentMeanConstants.STORE_NAME}
                    method={"PUT"}
                    fieldKey={`${key}.Description`}
                    instructionsKey="Description"
                  />
                </SC.InvoicedBoxRowValue>
                <SC.InvoicedBoxRowValue>
                  <TextInput
                    noMargin
                    noTitle
                    storeName={paymentMeanConstants.STORE_NAME}
                    method={"PUT"}
                    fieldKey={`${key}.BookkeepingAccount`}
                    instructionsKey="BookkeepingAccount"
                  />
                </SC.InvoicedBoxRowValue>
              </SC.InvoicedBoxRow>
            </>
          ))}
        </SC.InvoicedBoxContent>
      </InvoiceChapter>

      <div style={{ display: "flex" }}>
        <PrimaryButton title="Spara uppdateringar" clicked={handleSubmit} />
      </div>
    </>
  );
}

const ACCOUNT_MAPPER = {
  TradeDebtsAccount: "Kundfordringar",
  TradeDebtsTaxReductionAccount: "Kundfordran (ROT/RUT)",
  AccountPayablesAccount: "Leverantörsskulder",
  ShortTermDebtsAccount: "Kortfristiga skulder",
  AccrualAccount: "Periodiseringskonto",
  FinancialInstituteDebtAccount: "Skulder till finansiella institut",
  CustomerAdvancedPaymentsAccount: "Förskott från kund",
  UnmatchedPaymentsAccount: "Omatchade betalningar",
  VATAccount6: "Moms 6%",
  VATAccount12: "Moms 12%",
  VATAccount25: "Moms 25%",
  IncomingVATAccount: "Ingående moms",
  RestingVATAccount: "Vilande moms",
  FreightCostAccount: "Fraktavgifter 0%",
  FreightCostAccount6: "Fraktavgifter 6%",
  FreightCostAccount12: "Fraktavgifter 12%",
  FreightCostAccount25: "Fraktavgifter 25%",
  AdministrationCostAccount: "Administrationsavgifter 0%",
  AdministrationCostAccount6: "Administrationsavgifter 6%",
  AdministrationCostAccount12: "Administrationsavgifter 12%",
  AdministrationCostAccount25: "Administrationsavgifter 25%",
  SalesWithReverseVATAccount: "Försälj. Omvänd moms",
  SalesWithRotRutAccount: "Försälj. Arbete ROT/RUT",
  RoundingAccount: "Öresavrundning",
  OtherOperatingIncomeAccount: "Övriga rörelseintäkter",
  WriteOffAccount: "Bortskrivningar/Kundförluster",
  RevenueCorrectionAccount: "Intäktskorrigeringar",
  CurrencyDifferenceLossesAccount: "Valutakursförluster",
  CurrencyDifferenceGainsAccount: "Valutakursvinster",
  InterestAccount: "Ränteintäkter",
  FinancialCostsAccount: "Finansiella kostnader",
};
