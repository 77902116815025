export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CUSTOMER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CUSTOMER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CUSTOMER",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CUSTOMER",
  DESTROY_FORM: "DESTROY_FORM_CUSTOMER",
  SET_FORM_ERROR: "SET_FORM_ERROR_CUSTOMER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CUSTOMER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CUSTOMER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CUSTOMER",
  RESET_STATE: "RESET_STATE_CUSTOMER",
  CLEAR_FETCHED: "CLEAR_FETCHED_CUSTOMERS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CUSTOMER",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_CUSTOMER",

  LIST_URL: "/management/customer/list/",
  POST_URL: "/management/customer/",
  PATCH_URL: "/management/customer/",
  GET_URL: "/management/customer/",

  AUTHENTICATE_AS_CUSTOMER_URL: "/accounts/auth/management_auth/",

  STORE_NAME: "customers",
};
