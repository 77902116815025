import * as React from "react";

import SelectFilter from "../../Base/CompleteList/Filters/SelectFilter";

export default ({ column }) => {
  const choices = [
    { value: 1, display: "Aktiv" },
    { value: 2, display: "Tillsvidare" },
    { value: 3, display: "Utgående" },
    { value: 4, display: "Kommande" },
    { value: 5, display: "Ej signerat" },
    { value: 6, display: "Uppsagt för utflytt" },
    { value: 7, display: "Uppsagt för förnyelse" },
    { value: 8, display: "Ointressant" },
    { value: 9, display: "Tidigare avtal" },
    { value: 10, display: "Okänt" },
  ];

  return <SelectFilter choices={choices} column={column} />;
};
