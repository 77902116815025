import * as React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import GlobalSearchBox from "./GlobalSearchBox";
import * as SC from "./styles";
import { CheckBox } from "../../Forms/Base/Fields/styles";
import Pagination from "./Pagination";
import { ExportExcelButton } from "../../Forms/Base/Buttons";
import { performTableToBookExcelExport } from "../../Lists/Base/utils";
import { useDispatch, useSelector } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

function formatCellToSearchable(value) {
  if (value instanceof Object) {
    return (
      value?.display?.toString()?.toLowerCase() || value?.str_representation
    );
  }

  return value.toString().toLowerCase();
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, row, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          width: "26px",
          height: "26px",
        }}
      >
        <CheckBox
          id={row?.id || "all"}
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
        <label
          style={{ height: "26px", width: "26px" }}
          htmlFor={row?.id || "all"}
        ></label>
      </div>
    );
  }
);

export default function Table({
  tableId,
  columns,
  data,
  onRowClicked,
  selectedRow,
  hideSearch = false,
  withSummaryFooter = false,
  withTotalSummaryFooter = false,
  withSelectableRows,
  handleSelectedUpdated,
  pageUpdatedCallback,
  // children in this component is a custom footer
  children,
  handleSelectedHeader,
  renderBesideSearch, // renders beside search
  externalFilters: ExternalFilterComponent,
  //custom export handling
  onExport,
  forceInitialPageSize,

  //default export handling
  withExport,
  exportName,

  // function to determine if show error state
  checkRowError,

  // table persistance
  withPersistantGlobalFilter,
  withPersistantSortBy,
  hasPersistantExternalFilter,
  clearExternalFilters,
  extraStyles = {},
}) {
  const dispatch = useDispatch();
  const PERSISTANT_SORT_BY_KEY = `persistant_sort_by_${tableId}`;
  const PERSISTANT_PAGE_KEY = `persistant_page_${tableId}`;
  const PERSISTANT_PAGE_SIZE_KEY = `persistant_page_size_${tableId}`;
  const PERSISTANT_FILTERS_KEY = `persistant_filters_${tableId}`;
  const PERSISTANT_GLOBAL_FILTER_KEY = `persistant_global_filter_${tableId}`;

  const getInitialGlobalFilter = () => {
    try {
      const persistantGlobalFilter = localStorage.getItem(
        PERSISTANT_GLOBAL_FILTER_KEY
      );
      const parsed = persistantGlobalFilter
        ? JSON.parse(persistantGlobalFilter)
        : "";

      return parsed;
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const getInitialSortBy = () => {
    try {
      const persistantSortBy = localStorage.getItem(PERSISTANT_SORT_BY_KEY);
      const parsed = JSON.parse(persistantSortBy) || [];
      return parsed;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getInitialPage = () => {
    try {
      const persistantPage = localStorage.getItem(PERSISTANT_PAGE_KEY);
      const parsed = JSON.parse(persistantPage) || 0;
      return parsed;
    } catch (e) {
      return 0;
    }
  };

  const getInitialFilters = () => {
    try {
      const persitantFilters = localStorage.getItem(PERSISTANT_FILTERS_KEY);
      const parsed = JSON.parse(persitantFilters) || [];
      return parsed;
    } catch (e) {
      return [];
    }
  };

  const getInitialPageSize = () => {
    try {
      const persistantPageSize = localStorage.getItem(PERSISTANT_PAGE_SIZE_KEY);
      const parsed =
        JSON.parse(persistantPageSize) || forceInitialPageSize || 10;
      return parsed;
    } catch (e) {
      return forceInitialPageSize || 10;
    }
  };

  const [initialState, setInitialState] = React.useState({
    pageSize: withPersistantSortBy
      ? getInitialPageSize()
      : forceInitialPageSize || 10,
    pageIndex: withPersistantSortBy ? getInitialPage() : 0,
    sortBy: withPersistantSortBy ? getInitialSortBy() : [],
    globalFilter: withPersistantGlobalFilter ? getInitialGlobalFilter() : "",
    filters: withPersistantSortBy ? getInitialFilters() : [],
  });

  const user = useSelector((state) => state.app?.user?.str_representation);

  const tableRef = React.useRef();

  const filterTypes = React.useMemo(
    () => ({
      // override the default text filter

      text: (rows, columns, filterValue) => {
        return rows.filter((row) => {
          let matches = false;

          columns.forEach((columnKey) => {
            const cellValue = row.values[columnKey];
            if (!cellValue) return;

            const formattedCellValue = formatCellToSearchable(cellValue);

            if (
              formattedCellValue?.includes(filterValue) ||
              filterValue?.includes(formattedCellValue)
            ) {
              matches = true;
              return matches;
            }
          });

          return matches;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: {
      filters,
      sortBy,
      pageIndex,
      pageSize,
      globalFilter,
      selectedRowIds,
    },
    preGlobalFilteredRows,
    setGlobalFilter,
    // setAllFilters,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (withSelectableRows) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...{ row }}
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  // persistant sort between remounts
  React.useEffect(() => {
    if (withPersistantSortBy) {
      localStorage.setItem(PERSISTANT_SORT_BY_KEY, JSON.stringify(sortBy));
    }
  }, [sortBy]);
  React.useEffect(() => {
    if (withPersistantSortBy) {
      localStorage.setItem(PERSISTANT_PAGE_KEY, JSON.stringify(pageIndex));
    }
  }, [pageIndex]);
  React.useEffect(() => {
    if (withPersistantSortBy) {
      localStorage.setItem(PERSISTANT_PAGE_SIZE_KEY, JSON.stringify(pageSize));
    }
  }, [pageSize]);

  // persistant filters (not the same as the prop, this is for user filtered options persisted from last session)
  React.useEffect(() => {
    if (withPersistantSortBy) {
      localStorage.setItem(PERSISTANT_FILTERS_KEY, JSON.stringify(filters));
    }
  }, [filters]);

  // persistant global filter between remounts
  React.useEffect(() => {
    if (withPersistantGlobalFilter) {
      localStorage.setItem(
        PERSISTANT_GLOBAL_FILTER_KEY,
        globalFilter ? JSON.stringify(globalFilter) : ""
      );
    }
  }, [globalFilter]);

  // Inform about persistant sortBy / globalfilter
  React.useEffect(() => {
    if (withPersistantGlobalFilter || withPersistantSortBy) {
      const initalSortBy = getInitialSortBy();
      let initialFilters = getInitialFilters();
      const initialGlobalFilter = getInitialGlobalFilter();

      //special check for filters, some values are automatic but doesn't actually filter anything
      if (initialFilters?.length) {
        const startDateFilter = initialFilters.find(
          (f) => f.id === "start_date"
        );
        const endDateFilter = initialFilters.find((f) => f.id === "end_date");

        // empty start date filter
        if (startDateFilter && !startDateFilter.value.some((v) => v)) {
          initialFilters = initialFilters.filter((i) => i.id !== "start_date");
        }

        // empty end date filter
        if (endDateFilter && !endDateFilter.value.some((v) => v)) {
          initialFilters = initialFilters.filter((i) => i.id !== "end_date");
        }
      }

      if (
        withPersistantGlobalFilter &&
        withPersistantSortBy &&
        initalSortBy?.length &&
        initialGlobalFilter?.length &&
        (initialFilters?.length || hasPersistantExternalFilter)
      ) {
        // inform about all
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Sortering, filtrering och sökning aktiv",
            description:
              "Den sparade filtreringen, sorteringen och sökningen för tabellen används",
            action: {
              title: "Återställ filter, sortering och sökning",
              clicked: () => {
                setSortBy([]);
                setGlobalFilter("");
                // setAllFilters([]);
                clearExternalFilters && clearExternalFilters();
              },
              successObj: {
                title: "Filter, sortering och sökning återställdes",
              },
            },
          })
        );
      } else if (
        withPersistantGlobalFilter &&
        withPersistantSortBy &&
        initalSortBy?.length &&
        initialGlobalFilter?.length
      ) {
        // inform about both sort and search
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Sortering och sökning aktiv",
            description:
              "Den sparade sorteringen och sökningen för tabellen används",
            action: {
              title: "Återställ sortering och sökning",
              clicked: () => {
                setSortBy([]);
                setGlobalFilter(null);
              },
              successObj: {
                title: "Sortering och sökning återställdes",
              },
            },
          })
        );
      } else if (
        withPersistantSortBy &&
        initalSortBy?.length &&
        (initialFilters?.length || hasPersistantExternalFilter)
      ) {
        // inform about persistant sort by and filters
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Sortering och filtrering aktiv",
            description:
              "Den sparade sorteringen och filtreringen för tabellen används",
            action: {
              title: "Återställ sortering och filter",
              clicked: () => {
                setSortBy([]);
                // setAllFilters([]);
                clearExternalFilters && clearExternalFilters();
              },
              successObj: {
                title: "Sortering och filter återställdes",
              },
            },
          })
        );
      } else if (withPersistantSortBy && initalSortBy?.length) {
        // inform about persistant sort by
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Sortering aktiv",
            description: "Den sparade sorteringen för tabellen används",
            action: {
              title: "Återställ sortering",
              clicked: () => {
                setSortBy([]);
              },
              successObj: {
                title: "Sortering återställdes",
              },
            },
          })
        );
      } else if (
        withPersistantSortBy &&
        (initialFilters?.length || hasPersistantExternalFilter)
      ) {
        // inform about persistant filters
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Filtrering aktiv",
            description: "Den sparade filtreringen för tabellen används",
            action: {
              title: "Återställ filter",
              clicked: () => {
                // setAllFilters([]);
                clearExternalFilters && clearExternalFilters();
              },
              successObj: {
                title: "Filter återställdes",
              },
            },
          })
        );
      } else if (withPersistantGlobalFilter && initialGlobalFilter?.length) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Sökning aktiv",
            description: "Den sparade sökningen för tabellen används",
            action: {
              title: "Återställ sökning",
              clicked: () => {
                setGlobalFilter("");
              },
              successObj: {
                title: "Sökningen rensades",
              },
            },
          })
        );
      }
    }
  }, []);

  const handleExport = () => {
    performTableToBookExcelExport({
      element: tableRef.current,
      fileName: exportName || "Pigello_Fastighetssystem_Export",
      userStr: user,
    });
  };

  React.useEffect(() => {
    if (handleSelectedUpdated) handleSelectedUpdated(selectedFlatRows);
  }, [selectedFlatRows, handleSelectedUpdated]);

  React.useEffect(() => {
    pageUpdatedCallback && pageUpdatedCallback(page);
  }, [page]);

  return (
    <>
      {!hideSearch && (
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <GlobalSearchBox
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={(val) => {
              setGlobalFilter(val);
            }}
          />
          {renderBesideSearch && renderBesideSearch()}
          {onExport && (
            <ExportExcelButton onClick={onExport} title="Exportera" />
          )}
          {withExport && !onExport && (
            <ExportExcelButton onClick={handleExport} title="Exportera" />
          )}
        </div>
      )}

      {ExternalFilterComponent ? <ExternalFilterComponent /> : null}

      <SC.TableWrapper style={{ ...extraStyles }}>
        {handleSelectedHeader && handleSelectedHeader()}
        <SC.InnerTableWrapper>
          <SC.Table ref={tableRef} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {/** Sorting */}
                        {column.canSort ? (
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        ) : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={onRowClicked ? () => onRowClicked(row) : undefined}
                    className={[
                      selectedRow === row.id ? "active-row" : "",
                      onRowClicked ? "clickable-row" : "",
                      checkRowError && checkRowError(row) ? "error-row" : "",
                    ].join(" ")}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>

            {withTotalSummaryFooter && (
              <tfoot
                style={{
                  marginTop: "24px",
                  borderTop: "2px solid #ddd",
                  backgroundColor: "#eceff1",
                  color: "#69707D",
                }}
              >
                {footerGroups.map((group) => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <td {...column.getFooterProps()}>
                        {column.TotalFooter && column.render("TotalFooter")}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
            {withSummaryFooter && pageCount > 1 && (
              <tfoot>
                {footerGroups.map((group) => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <td {...column.getFooterProps()}>
                        {column.Footer && column.render("Footer")}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </SC.Table>
        </SC.InnerTableWrapper>

        {children ? children : null}
      </SC.TableWrapper>

      {pageCount > 1 && (
        <Pagination
          {...{
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            pageIndex,
            pageSize,
          }}
        />
      )}
    </>
  );
}
