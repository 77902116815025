import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

import CustomerTable from "../../../components/Lists/Customers/FullTable";
import { useAllPermissionCheck } from "../../../store/base";

import ModalForm from "../../../components/Forms/Customer/ChapterForm/ModalForm";

export default function Customers() {
  const canAdd = useAllPermissionCheck(["add_can_management"]);

  const [createTenantActive, setCreateTenantActive] = React.useState(null);

  const renderActions = () => (
    <div style={{ alignItems: "center" }}>
      {canAdd && (
        <PrimaryButton
          newDesign
          title="Lägg till förvaltningsuppdrag"
          clicked={() => setCreateTenantActive(true)}
        />
      )}
    </div>
  );

  return (
    <>
      {canAdd && (
        <ModalForm
          method="POST"
          kind={createTenantActive}
          isOpen={createTenantActive}
          onCheckout={() => setCreateTenantActive(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>
            {renderActions()}
          </OverviewTitleWrapper>

          <CustomerTable
            hideTitle
            withPersistantSortBy
            withPersistantGlobalFilter
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
