import moment from "moment";
import * as React from "react";
import * as ISC from "../../../Details/InfoTable/styles";

import { BooleanLabel, DurationCell } from "../../../Displays";

export default ({ instance }) => {
  return (
    <ISC.InfoTable>
      <ISC.InfoTableTitleWrapper>
        <ISC.InfoTableTitle>
          <ISC.InfoTableTitle>
            {instance?.title ? instance.title : "Titel saknas"}
          </ISC.InfoTableTitle>
          <ISC.InfoTableSubtitle>Titel</ISC.InfoTableSubtitle>
        </ISC.InfoTableTitle>
      </ISC.InfoTableTitleWrapper>

      <ISC.InfoTableContent>
        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Påminn innan</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.remind_before ? (
              <DurationCell durationStamp={instance.remind_before} />
            ) : (
              "Tid saknas"
            )}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>

        <ISC.InfoTableRow>
          <ISC.InfoTableRowTitle>Visa som upptagen</ISC.InfoTableRowTitle>
          <ISC.InfoTableRowValue>
            {instance?.display_as_occupied != null ? (
              <BooleanLabel
                value={instance.display_as_occupied}
                onLabel={"Ja"}
                offLabel={"Nej"}
              />
            ) : (
              "Värde saknas"
            )}
          </ISC.InfoTableRowValue>
        </ISC.InfoTableRow>
      </ISC.InfoTableContent>
    </ISC.InfoTable>
  );
};
