import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import { InfoBox } from "../../Displays";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { FieldBox } from "../Search/styles";
import { constants } from "../../../store/billectaReports";
import FutureInvoicesTable from "./FutureInvoicesTable";

export default ({ fromDate, toDate, setToDate, setFromDate }) => {
  const futureInvoices = useSelector(
    (state) => state[constants.STORE_NAME].futureInvoices
  );

  const renderInvoicesTable = () => {
    if (!futureInvoices)
      return <div>Generera en rapport för att se framtida fakturor</div>;

    if (futureInvoices && futureInvoices?.length === 0)
      return <div>Inga framtida fakturor förväntas dessa datum</div>;

    return (
      <FutureInvoicesTable
        reportRows={futureInvoices}
        fromDate={fromDate}
        toDate={toDate}
      />
    );
  };
  return (
    <>
      <FieldBox>
        <OverviewTitle small style={{ marginBottom: "8px" }}>
          Datumintervall för rapport
        </OverviewTitle>
        <NonConnectedDatePicker
          value={fromDate}
          clearable={false}
          id="future-from-date"
          label="Från"
          onChange={(val) => setFromDate(val)}
          minDate={moment().toDate()}
          noMargin
        />
        <NonConnectedDatePicker
          value={toDate}
          clearable={false}
          id="future-to-date"
          label="Till"
          minDate={moment().add(30, "days").toDate()}
          onChange={(val) => setToDate(val)}
          noMargin
        />
      </FieldBox>
      <InfoBox
        title="Framtida fakturor"
        text="Framtida fakturor räknas ut genom att titta på de avtalsaviseringar som är uppsatta. Med hjälp av datumen, intervallen och fakturaraderna på avtalsaviseringen så föreslås förväntade framtida fakturor. Observera att ändringar på avtalsaviseringarna även ändrar förväntade framtida fakturor, och därmed är det presenterade resultatet en ögonblicksbild över hur det kommer se ut om inget förändras på de existerande avtalsaviseringarna."
      />

      {renderInvoicesTable()}
    </>
  );
};
