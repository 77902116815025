import { cloneDeep } from "lodash";
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

export const overviewUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}`;

// TODO REMOVE DUPLICATE FUNCTION

export const creditorDetailUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}`;

export const creditorInvoiceDetailUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/invoices`;

export const creditorPaymentsDetailUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/payments`;

export const createManyInvoiceUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/create-multiple-invoices`;

export const createTripleInvoiceUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/create-triple-invoices`;

export const createManyContractInvoiceUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/create-multiple-contract-invoices`;

export const invoiceDetailUrl = ({ invoiceId, creditorId }) =>
  `/rental-notifications/company/${creditorId}/invoice/${invoiceId}`;

export const billectaStateMap = {
  None: "Okänd",
  Created: "Ej attesterad",
  ReminderInvoiceSent: "Påminnelse",
  Manual: "Förfallen (Ej hanterad)",
  Completed: "Slutbetald",
  Cancelled: "Avbruten",
  InvoiceSent: "Skickad",
  Attested: "Attesterad",
  CreditInvoice: "Kreditfaktura",
  SentToDebtCollection: "Inkasso",
  SalesRequested: "SalesRequested",
  SaleRequestAccepted: "SaleRequestAccepted",
  SalesRequestedCancelled: "SalesRequestedCancelled",
  SalesRequestedDenied: "SalesRequestedDenied",
};

export const BILLECTA_ACTIONS = {
  ACTION_UPDATED: 0,
  ACTION_DEBIT_INTERVAL: 1,
  ACTION_CREATED: 2,
  ACTION_SIGNED: 3,
  ACTION_CANCELLED: 4,
};

export const INTERVAL_MAPPER = {
  Monthly: "Månadsvis",
  Yearly: "Årsvis",
  Quarterly: "Kvartalsvis",
};

export const MONTH_MAPPER = {
  1: "Januari",
  2: "Februari",
  3: "Mars",
  4: "April",
  5: "Maj",
  6: "Juni",
  7: "Juli",
  8: "Augusti",
  9: "September",
  10: "Oktober",
  11: "November",
  12: "December",
};

export const DELIVERY_METHOD_MAPPER = {
  Email: "Email (0 kr/st)",
  Kivra: "Kivra (4 kr/st)",
  EInvoice: "E-faktura (2.50 kr/st exkl. bankkostnader)",
  Mail: "Post (6.50 kr/st)",
  Manually: "Skicka ej",
  SMS: "SMS (1.50 kr/st)",
};

export const DELIVERY_METHOD_MAPPER_EXCL_PRICES = {
  Email: "Email",
  Kivra: "Kivra",
  EInvoice: "E-faktura",
  Mail: "Post",
  Manually: "Skicka ej",
  SMS: "SMS",
};

export const INVOICE_TYPES = {
  LEASE: "LEASE",
  PARKING: "PARKING",
  OTHER: "OTHER",
};

export const billingDatesProposalFromPeriod = ({ periodStart, periodEnd }) => {
  const startM = moment(periodStart);
  const today = moment();

  // calculate proposed duedate and invoice dates
  let billStart = today.add(1, "days");

  // bill start is tomorrow, if period has passed, or we're in period
  // but if period is upcoming, the billstart should be the 27th just before the bill start
  if (startM.isAfter(today)) {
    billStart = startM.clone();
    if (billStart.day() > 27) {
      // same month as start is OK, just set date
      billStart.set("date", 27);
    } else {
      // must use previous month, just set date
      billStart.subtract(1, "months");
      billStart.set("date", 27);
    }
  }

  const billEnd = billStart.clone();
  billEnd.add(30, "days");

  const InvoiceDate = billStart.format("YYYY-MM-DD");
  const DueDate = billEnd.format("YYYY-MM-DD");

  return {
    InvoiceDate,
    DueDate,
  };
};

export const setProducts = ({
  invoiceInstance,
  products,
  setVatToZero = false,
  setPeriod = false,
  periodStart,
  periodEnd,
}) => {
  const data = cloneDeep(invoiceInstance);

  if (!data?.Records) return data;

  let RecordsCopy = cloneDeep(data.Records);

  const otherProduct = products?.find((p) => p.Description === "Annan");

  RecordsCopy = RecordsCopy.map((r) => {
    const matchedProduct = products?.find(
      (p) =>
        p.Description.toLowerCase() === r.ArticleDescription.toLowerCase() ||
        r.ArticleDescription.toLowerCase()?.includes(
          p.Description.toLowerCase()
        ) ||
        p.Description.toLowerCase().includes(r.ArticleDescription.toLowerCase())
    );

    if (matchedProduct) {
      return {
        ...r,
        ProductPublicId: matchedProduct.ProductPublicId,
        VAT: r.VAT != null ? r.VAT : setVatToZero ? 0 : 25,
        PeriodStart: setPeriod ? periodStart : undefined,
        PeriodEnd: setPeriod ? periodEnd : undefined,
      };
    } else if (otherProduct) {
      return {
        ...r,
        ProductPublicId: otherProduct.ProductPublicId,
        VAT: r.VAT != null ? r.VAT : setVatToZero ? 0 : 25,
        PeriodStart: setPeriod ? periodStart : undefined,
        PeriodEnd: setPeriod ? periodEnd : undefined,
      };
    }

    return r;
  });

  data.Records = RecordsCopy;

  return data;
};

export const handlePeriodCosts = (data) => {
  const _periodStart = data._periodStart;
  const _periodEnd = data._periodEnd;

  if (!_periodStart || !_periodEnd) return data;

  const dataClone = cloneDeep(data);

  const startM = moment(_periodStart);
  const endM = moment(_periodEnd);

  if (startM.isValid() && endM.isValid()) {
    const yearDiff = endM.year() - startM.year();
    const monthDiff = endM.month() - startM.month();

    // refactor and filter out the records, single invoice -> backend normalizes costs
    // to be for one month
    let monthDuration = yearDiff * 12 + monthDiff + 1;
    if (monthDuration <= 0) {
      monthDuration = 1;
    }

    let newRecords = [];
    (dataClone.Records || []).forEach((r) => {
      const rInvoiceStart = moment(r?.InvoicedFrom);
      const rInvoiceEnd = moment(r?.InvoicedTo);

      if (r?.InvoicedFrom && rInvoiceStart.isValid()) {
        if (rInvoiceStart.isAfter(endM)) {
          return;
        }
      }
      if (r?.InvoicedTo && rInvoiceEnd.isValid()) {
        if (rInvoiceEnd.isBefore(startM)) {
          return;
        }
      }

      const clone = cloneDeep(r);

      const price = clone?.UnitPrice?.Value;
      if (price) {
        clone.UnitPrice.Value *= monthDuration;
      }
      newRecords.push(clone);
    });

    // set periods
    newRecords.forEach((r) => {
      r.PeriodStart = _periodStart;
      r.PeriodEnd = _periodEnd;
    });

    dataClone.Records = newRecords;
  }

  return dataClone;
};

export const CONTRACT_INVOICE_DIFF_TYPES = {
  RECORD: "RECORD",
  INTERVALTYPE: "INTERVALTYPE",
  INTERVAL_DETAILS: "INTERVAL_DETAILS",
  DATE: "DATE",
};
