import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { exportEditableDoc } from "../../../components/EditableDocBase/export";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import Pdf from "../../../components/Pdf/Pdf";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { useEditabledoc, editUrl } from "../../../store/editabledocs";

export default function Template() {
  const { editableDocId } = useParams();
  const [docData, setDocData] = React.useState(null);
  const { push } = useHistory();

  const [editableDoc] = useEditabledoc(editableDocId);

  const printDocument = async ({ editableDoc }) => {
    const data = await exportEditableDoc({ editableDoc, forShow: true });
    setDocData(data);
  };

  React.useEffect(() => {
    if (editableDoc && !docData) {
      printDocument({ editableDoc });
    }
  }, [editableDoc]);

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Förhandsgranskning</OverviewTitle>
          <PrimaryButton
            title="Redigera mall"
            clicked={() => push(editUrl({ id: editableDocId }))}
          />
        </OverviewTitleWrapper>

        <InnerBox>
          {docData ? (
            <Pdf uint8={docData} />
          ) : editableDoc?.fastdoc_id ? (
            "Kan inte förhandsvisa FastDok-mall. Tryck på Redigera mall för att autentisera mot FastDok och se mallen."
          ) : (
            "Laddar in mall..."
          )}
        </InnerBox>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
