import * as React from "react";
import * as SC from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getKivraSettings,
  getVismaSettings,
  getFortnoxSettings,
  getPEAccountingSettings,
  getAutogiroSettings,
  clearIntegrationData,
} from "../../../store/billectaIntegrations";

import { useFilteredFortnoxCredentials } from "../../../store/fortnoxCredential";

import kivraIcon from "../../../assets/img/kivra.svg";
import peIcon from "../../../assets/img/pe-logo.png";
import fortnoxIcon from "../../../assets/img/fortnox-logo.svg";
import vismaIcon from "../../../assets/img/visma-logo.jpg";
import autogiroIcon from "../../../assets/img/bankgirot-logo.png";
import KivraIntegrationForm from "./KivraIntegrationForm";
import PEIntegrationForm from "./PEIntegrationForm";
import FortnoxIntegrationForm from "./FortnoxIntegrationForm";
import VismaIntegrationForm from "./VismaIntegrationForm";
import { PrimaryButton, TextButton } from "../../Forms/Base/Buttons";
import AutogiroIntegrationForm from "./AutogiroIntegrationForm";
import { useHistory, useParams } from "react-router";
import { activateFortnoxUrl, useCompany } from "../../../store/companies";
import { uid } from "uid";
import FortnoxCredentialForm from "./FortnoxCredentialForm";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";

export default function IntegrationsContainer({ forceCompanyId }) {
  const [kivraOpen, setKivraOpen] = React.useState(false);
  const [peOpen, setPEOpen] = React.useState(false);
  const [fortnoxOpen, setFortnoxOpen] = React.useState(false);
  const [fortnoxCredentialOpen, setFortnoxCredentialOpen] =
    React.useState(false);
  const [vismaOpen, setVismaOpen] = React.useState(false);
  const [autogiroOpen, setAutogiroOpen] = React.useState(false);

  const dispatch = useDispatch();

  const { companyId } = useParams();
  const { push } = useHistory();

  const [company] = useCompany(forceCompanyId || companyId);
  const creditorId = company?.billecta_id;

  //Fortnox credentials, not part of billecta
  const canEditFortnoxCred = useAllPermissionCheck([
    "allow_fortnox",
    "change_can_fortnox",
  ]);
  const query = buildQueryString({ company__id: forceCompanyId || companyId });
  const [fortnoxCred] = useFilteredFortnoxCredentials(query);
  const fortnoxCredential = fortnoxCred?.[0];
  const fortnoxCredId = fortnoxCred?.[0]?.id;

  const kivraSettings = useSelector(
    (state) => state.billectaIntegrations.kivraSettings
  );
  const vismaSettings = useSelector(
    (state) => state.billectaIntegrations.vismaSettings
  );
  const peSettings = useSelector(
    (state) => state.billectaIntegrations.peSettings
  );
  const fortnoxSettings = useSelector(
    (state) => state.billectaIntegrations.fortnoxSettings
  );
  const autogiroSettings = useSelector(
    (state) => state.billectaIntegrations.autogiroSettings
  );

  React.useEffect(() => {
    if (!creditorId) return;

    if (!kivraSettings) dispatch(getKivraSettings(creditorId));
    if (!vismaSettings) dispatch(getVismaSettings(creditorId));
    if (!peSettings) dispatch(getPEAccountingSettings(creditorId));
    if (!fortnoxSettings) dispatch(getFortnoxSettings(creditorId));
    if (!autogiroSettings) dispatch(getAutogiroSettings(creditorId));
  }, [creditorId]);

  React.useEffect(() => {
    return () => {
      dispatch(clearIntegrationData());
    };
  }, []);

  const handleRenewFortNoxConnection = () => {
    const state = uid();

    const returnUrl = `https://${window.location.hostname}/${
      companyId ? "companies" : "brf-companies"
    }/detail/${companyId}/activate-fortnox-complete`;

    const voucherSeries = fortnoxSettings?.VoucherSeries;
    const startDate = fortnoxSettings?.StartDate;

    localStorage.setItem("fnx_state", state);
    localStorage.setItem("fnx_voucher_series", voucherSeries);
    localStorage.setItem("fnx_start_date", startDate);
    localStorage.setItem("fnx_is_update", true);

    window.location.href = `https://apps.fortnox.se/oauth-v1/auth?client_id=32hsGLkR78Av&scope=settings%20project%20price%20bookkeeping%20costcenter&state=${state}&access_type=offline&response_type=code&redirect_uri=${returnUrl}`;
  };

  const enabled = [];
  const disabled = [];

  if (kivraSettings?.IsEnabled) {
    enabled.push(
      <SC.Integration key={"kivra"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={kivraIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>Kivra</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              Kivra är en digital brevlåda där privatpersoner och företag kan ta
              emot sin post.
              <br />
              <strong>Pris: 29kr /mån</strong>
            </SC.IntegrationText>
          </div>
        </div>
        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Hantera"
          clicked={() => setKivraOpen(true)}
        />
      </SC.Integration>
    );
  } else {
    disabled.push(
      <SC.Integration key={"kivra"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={kivraIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>Kivra</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              Kivra är en digital brevlåda där privatpersoner och företag kan ta
              emot sin post.
            </SC.IntegrationText>
          </div>
        </div>
        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Hantera"
          clicked={() => setKivraOpen(true)}
        />
      </SC.Integration>
    );
  }

  if (!!fortnoxSettings) {
    enabled.push(
      <SC.Integration key={"fortnox"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={fortnoxIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>Fortnox</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              Fortnox är en digital bokföringstjänst.
            </SC.IntegrationText>
          </div>
        </div>

        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Hantera"
          clicked={() => setFortnoxOpen(true)}
        />

        <TextButton
          extraStyle={{ margin: "12px auto 0 auto" }}
          iconType={"sync"}
          iconPlacement="right"
          title="Förnya koppling"
          clicked={handleRenewFortNoxConnection}
        />
      </SC.Integration>
    );
  } else {
    disabled.push(
      <SC.Integration key={"fortnox"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={fortnoxIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>Fortnox</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              Fortnox är en digital bokföringstjänst.
            </SC.IntegrationText>
          </div>
        </div>

        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Aktivera"
          clicked={() =>
            push(
              activateFortnoxUrl({
                id: companyId,
              })
            )
          }
        />
      </SC.Integration>
    );
  }

  if (!!peSettings) {
    enabled.push(
      <SC.Integration key={"pe"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={peIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>PE Accounting</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              PE är en digital bokföringstjänst.
            </SC.IntegrationText>
          </div>
        </div>
        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Hantera"
          clicked={() => setPEOpen(true)}
        />
      </SC.Integration>
    );
  } else {
    disabled.push(
      <SC.Integration key={"pe"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={peIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>PE Accounting</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              PE är en digital bokföringstjänst.
            </SC.IntegrationText>
          </div>
        </div>
        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Hantera"
          clicked={() => setPEOpen(true)}
        />
      </SC.Integration>
    );
  }

  if (!!autogiroSettings) {
    enabled.push(
      <SC.Integration key={"autogiro"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={autogiroIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>Autogiro</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              Autogirot är en tjänst från Bankgirot som tillhandahåller
              automatiska betalningar.
            </SC.IntegrationText>
          </div>
        </div>
        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Hantera"
          clicked={() => setAutogiroOpen(true)}
        />
      </SC.Integration>
    );
  } else {
    disabled.push(
      <SC.Integration key={"autogiro"}>
        <div style={{ display: "flex", maxWidth: "340px" }}>
          <SC.IntegrationImage src={autogiroIcon}></SC.IntegrationImage>

          <div style={{ marginLeft: "8px" }}>
            <SC.IntegrationTextTitle>Autogiro</SC.IntegrationTextTitle>

            <SC.IntegrationText>
              Autogirot är en tjänst från Bankgirot som tillhandahåller
              automatiska betalningar.
            </SC.IntegrationText>
          </div>
        </div>
        <PrimaryButton
          extraStyle={{ width: "100%" }}
          title="Hantera"
          clicked={() => setAutogiroOpen(true)}
        />
      </SC.Integration>
    );
  }
  if (canEditFortnoxCred) {
    if (!!fortnoxCredential) {
      enabled.push(
        <SC.Integration key={"fortnoxCredential"}>
          <div style={{ display: "flex", maxWidth: "340px" }}>
            <SC.IntegrationImage src={fortnoxIcon}></SC.IntegrationImage>

            <div style={{ marginLeft: "8px" }}>
              <SC.IntegrationTextTitle>
                Fortnox-leverantörsfaktura
              </SC.IntegrationTextTitle>

              <SC.IntegrationText>
                Integration för att hantera leverantörsfaktura.
              </SC.IntegrationText>
            </div>
          </div>

          <PrimaryButton
            extraStyle={{ width: "100%" }}
            title="Hantera"
            clicked={() => setFortnoxCredentialOpen(true)}
          />
        </SC.Integration>
      );
    } else {
      disabled.push(
        <SC.Integration key={"fortnoxCredential"}>
          <div style={{ display: "flex", maxWidth: "340px" }}>
            <SC.IntegrationImage src={fortnoxIcon}></SC.IntegrationImage>

            <div style={{ marginLeft: "8px" }}>
              <SC.IntegrationTextTitle>
                Fortnox-leverantörsfaktura
              </SC.IntegrationTextTitle>

              <SC.IntegrationText>
                Integration för att hantera leverantörsfaktura.
              </SC.IntegrationText>
            </div>
          </div>

          <PrimaryButton
            extraStyle={{ width: "100%" }}
            title="Hantera"
            clicked={() => setFortnoxCredentialOpen(true)}
          />
        </SC.Integration>
      );
    }
  }

  return (
    <>
      <KivraIntegrationForm
        open={kivraOpen}
        closeFunction={() => setKivraOpen(false)}
        creditorId={creditorId}
      />

      <PEIntegrationForm
        creditorId={creditorId}
        open={peOpen}
        closeFunction={() => setPEOpen(false)}
      />

      <FortnoxIntegrationForm
        creditorId={creditorId}
        open={fortnoxOpen}
        closeFunction={() => setFortnoxOpen(false)}
      />

      <FortnoxCredentialForm
        fortnoxCredId={fortnoxCredId}
        fortnoxCredential={fortnoxCredential}
        companyId={forceCompanyId || companyId}
        open={fortnoxCredentialOpen}
        closeFunction={() => setFortnoxCredentialOpen(false)}
      />

      <VismaIntegrationForm
        creditorId={creditorId}
        open={vismaOpen}
        closeFunction={() => setVismaOpen(false)}
      />

      <AutogiroIntegrationForm
        creditorId={creditorId}
        open={autogiroOpen}
        closeFunction={() => setAutogiroOpen(false)}
      />

      {/*  Active integrations  */}
      <SC.IntegrationsTitle>Aktiva integrationer</SC.IntegrationsTitle>
      <SC.IntegrationsBox>{enabled.map((c) => c)}</SC.IntegrationsBox>

      {/* Inactive integrations */}
      <SC.IntegrationsTitle>Inaktiva integrationer</SC.IntegrationsTitle>
      <SC.IntegrationsBox>
        {disabled.map((c) => c)}

        <SC.Integration key={"visma"}>
          <div style={{ display: "flex", maxWidth: "340px" }}>
            <SC.IntegrationImage
              style={{ border: "thin solid rgba(0,0,0,0.07)" }}
              src={vismaIcon}
            ></SC.IntegrationImage>

            <div style={{ marginLeft: "8px" }}>
              <SC.IntegrationTextTitle>Visma</SC.IntegrationTextTitle>

              <SC.IntegrationText>
                Visma är en digital bokföringstjänst.
              </SC.IntegrationText>
            </div>
          </div>
          <PrimaryButton
            extraStyle={{ width: "100%" }}
            title="Hantera"
            clicked={() => setVismaOpen(true)}
          />
        </SC.Integration>
      </SC.IntegrationsBox>
    </>
  );
}
