import * as React from "react";
import { useDispatch } from "react-redux";
import Draggable from "react-draggable";

import EditableField from "./EditableField";

import { updateActiveFormInstance } from "../../../store/base";
import {
  FIELD_TYPES,
  toExternalCoordinates,
  toInternalCoordinates,
} from "../utils";

// since position is passed, this will be re-rendered upon position change
export default React.memo(
  ({
    storeName,
    method,
    kind,
    position,
    partyKey,
    fieldKey,
    positionKey,
    disabled,
  }) => {
    const dispatch = useDispatch();
    const [canClick, setCanClick] = React.useState(true);

    const { x, y } = toInternalCoordinates({
      x: position.xrel,
      y: position.yrel,
    });

    // update the position of the field placement
    const onStop = (event) => {
      const { x, y } = toExternalCoordinates(event);
      if (x < 0 || y < 0 || x > 1 || y > 1) {
        return;
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [`${positionKey}.xrel`]: x, [`${positionKey}.yrel`]: y },
        })
      );

      setTimeout(() => setCanClick(true), 200);
    };

    return (
      <Draggable
        axis="both"
        handle=".handle"
        bounds="parent"
        defaultPosition={{ x, y }}
        position={{ x, y }}
        grid={[1, 1]}
        scale={1}
        onStop={onStop}
        onDrag={() => setCanClick(false)}
        cancel=".no-move"
      >
        {/* top and left to better match actual field placements */}
        <div
          style={{
            position: "absolute",
            top: kind === FIELD_TYPES.CHECKBOX ? -3 : -10,
            left: -6,
            zIndex: 2,
          }}
        >
          <EditableField
            canClick={canClick}
            disabled={disabled}
            storeName={storeName}
            position={position}
            partyKey={partyKey}
            fieldKey={fieldKey}
            positionKey={positionKey}
            method={method}
          />
        </div>
      </Draggable>
    );
  }
);
