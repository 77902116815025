import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { NumberInput, Select, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingCompany";

import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { ErrorMessage } from "../../../Base/Fields/styles";
import { useFormError } from "../../../../../store/base";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const contactError = useFormError({
    storeName,
    fieldKey: "contact_person",
  });
  return (
    <>
      <FormAreaTitle>Leverans av faktura</FormAreaTitle>

      {method === "POST" && (
        <NumberInput
          title="Börja fakturanummer på..."
          {...{
            storeName,
            method,
            fieldKey: `next_invoice_number`,
          }}
        />
      )}

      <Select
        title="Standard leveransmetod"
        {...{
          storeName,
          method,
          fieldKey: `delivery_method`,
        }}
      />

      <TextInput
        title="Bolagets referens"
        {...{
          storeName,
          method,
          fieldKey: `our_reference`,
        }}
      />

      <TextInput
        title="E-postadress på avier"
        {...{
          storeName,
          method,
          fieldKey: `invoice_email`,
        }}
      />

      <TextInput
        title="Telefonnummer på avier"
        {...{
          storeName,
          method,
          fieldKey: `invoice_phone`,
        }}
      />

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Kontaktperson på avier</OverviewTitle>
        </OverviewTitleWrapper>

        {contactError && <ErrorMessage>{contactError}</ErrorMessage>}

        <TextInput
          title="Titel"
          {...{
            storeName,
            method,
            fieldKey: `contact_person.title`,
          }}
        />

        <TextInput
          title="Namn"
          {...{
            storeName,
            method,
            fieldKey: `contact_person.name`,
          }}
        />

        <TextInput
          title="Telefon"
          {...{
            storeName,
            method,
            fieldKey: `contact_person.phone`,
          }}
        />

        <TextInput
          title="E-post"
          {...{
            storeName,
            method,
            fieldKey: `contact_person.email`,
          }}
        />
      </InnerBox>

      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>Kontaktperson för krav</OverviewTitle>
        </OverviewTitleWrapper>

        <TextInput
          title="Titel"
          {...{
            storeName,
            method,
            fieldKey: `claims_contact.title`,
          }}
        />

        <TextInput
          title="Namn"
          {...{
            storeName,
            method,
            fieldKey: `claims_contact.name`,
          }}
        />

        <TextInput
          title="Telefon"
          {...{
            storeName,
            method,
            fieldKey: `claims_contact.phone`,
          }}
        />

        <TextInput
          title="E-post"
          {...{
            storeName,
            method,
            fieldKey: `claims_contact.email`,
          }}
        />
      </InnerBox>
    </>
  );
};
