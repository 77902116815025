import moment from "moment";
import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import CustomerContractDocumentTabs from "./Detail/Documents";
import CustomerContractInvoicing from "./Detail/Invoicing";
import CustomerContractOverview from "./Detail/Overview";
import Costs from "../../components/Details/OverviewInfo/Costs/Costs";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { toMoneyString } from "../../components/utils/stringUtils";
import {
  setActiveFormInstance,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../store/base";
import { useInvoicingErrorPaginationCount } from "../../store/invoicingErrors/hooks/retrieve";

import {
  cancelUrl,
  constants,
  useManagementContract,
} from "../../store/managementContracts";
import { constants as editableDocConstants } from "../../store/editabledocs";
import { constants as invoicingConstants } from "../../store/invoicingCustomer";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import { ESignOrManual } from "../../components/Forms/Pickers";
import { useCanUseScrive } from "../../store/editabledocs/hooks/retrieve";
import { totalCostBetweenDates } from "../../components/Lists/ManagementContracts/listDefs";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

export default function ManagementContractDetail() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  const { managementContractId } = useParams();

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [cancelTypeOpen, setCancelTypeOpen] = React.useState(false);
  const [managementContract, managementContractLoading] =
    useManagementContract(managementContractId);
  const { hasBillectaViewPermission } = useFrequentPermissions();
  usePermissionRedirect(["view_can_contract"]);

  const canDelete = usePermissionCheck("delete_can_contract");
  const canEdit = usePermissionCheck("change_can_contract");
  const [canUseScrive] = useCanUseScrive();

  const isCancelled =
    managementContract?.closed_date || managementContract?.closed_renew_date;
  const isSigned = managementContract?.is_signed;

  // clear form states
  React.useEffect(() => {
    return () => {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {},
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: invoicingConstants.STORE_NAME,
          data: {},
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: editableDocConstants.STORE_NAME,
          data: {},
        })
      );
    };
  }, []);

  const [invoicingErrorCount] = useInvoicingErrorPaginationCount({
    filters: {
      customerinvoicing: managementContract?.customer_invoicing?.id || -1,
    },
  });

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Avtal",
      component: CustomerContractOverview,
    },
    {
      path: "/costs",
      label: "Kostnader",
      component: Costs,
    },
    {
      path: "/docs",
      label: "Dokument",
      component: CustomerContractDocumentTabs,
    },
  ];

  if (hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Avisering",
      hasError: invoicingErrorCount,
      component: CustomerContractInvoicing,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleCancelClicked = () => {
    if (canUseScrive) {
      setCancelTypeOpen(true);
    } else {
      push(cancelUrl({ id: managementContractId, isManualSign: true }));
    }
  };

  const onCancelTypeSelected = (cancelType) => {
    push(
      cancelUrl({
        id: managementContractId,
        isManualSign: cancelType !== "digital",
      })
    );
  };

  const handleActions = [];

  if (canEdit && !isCancelled && isSigned) {
    handleActions.push({
      name: "Säg upp",
      onClick: handleCancelClicked,
    });
  }

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
    });
  }

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={managementContract?.state}>
          {managementContract?.state_display}
        </StatusLabel>
        {isSigned && !isCancelled && (
          <StatusLabel style={{ marginLeft: 12 }} state={1}>
            Signerat
          </StatusLabel>
        )}

        <StatusLabel style={{ marginLeft: 12 }} state={4}>
          Årsintäkt ({moment().format("YYYY")}):{" "}
          {toMoneyString(
            totalCostBetweenDates({
              costs: managementContract?.costs,
              start: moment({ year: moment().year(), month: 0, day: 1 }),
              end: moment({ year: moment().year(), month: 0, day: 1 }).add({
                year: 1,
              }),
              contract: managementContract,
            }),
            true
          )}
        </StatusLabel>
      </>
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        constants={constants}
        instance={managementContract}
        deletedCallback={() => push("/management-contracts")}
      />

      <ESignOrManual
        isOpen={cancelTypeOpen}
        onClose={() => setCancelTypeOpen(false)}
        onSelect={onCancelTypeSelected}
        description="Bekräfta uppsägning"
        digitalTitle="Signera digitalt"
        manualTitle="Bekräfta manuellt"
        infoText="Välj om uppsägningen ska signeras digitalt eller enbart bekräftas"
      />

      <DetailLayoutWrapper>
        {managementContractLoading && <OverlaySpinner />}
        <DetailPageHeaderMenu
          title={`Förvaltningsavtal ${
            managementContract?.id_number || "Laddar..."
          }`}
          breadCrumbs={[
            { url: "/management-contracts", label: "Förvaltningsavtal" },
            { label: managementContract?.id_number || "Laddar..." },
          ]}
          eventContentType="management.customercontract"
          eventObjectId={managementContractId}
          eventIdQueryStr="customercontract"
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
