import * as React from "react";
import { durationParse } from "../../../components/Displays/utils";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";

import CustomerTable from "../../../components/Lists/Customers/FullTable";
import CompanyTable from "../../../components/Lists/Companies/FullTable";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useFilteredNotes } from "../../../store/notes";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useParams } from "react-router";
import { useManagementContract } from "../../../store/managementContracts";
import { DateCell } from "../../../components/Displays";
import UpdateManagementContractModalForm from "../../../components/Forms/ManagementContract/EditContractForm/ModalForm";
import UpdateManagementContractCustomerModalForm from "../../../components/Forms/ManagementContract/EditContractCustomerForm/ModalForm";
import { useEditabledoc } from "../../../store/editabledocs";
import ManagementContractManualSign from "../../../components/Details/ManagementContract/Signing/ManualSign";
import ManagementContractDigitalSign from "../../../components/Details/ManagementContract/Signing/DigitalSign";
import ManagementContractManualCancellation from "../../../components/Details/ManagementContract/Cancellation/ManualSign";
import ManagementContractDigitalCancelSign from "../../../components/Details/ManagementContract/Cancellation/DigitalSign";
import UpdateManagementContractLandlordModalForm from "../../../components/Forms/ManagementContract/EditContractCompanyForm/ModalForm";
import {
  getCostBetweenMonthDates,
  getIndexBetweenMonths,
  getMonthsForCost,
  getSpecifiedPeriodDates,
  PRODUCT_CATEGORY_MAPPING,
} from "../../../components/Lists/ManagementContracts/listDefs";
import moment from "moment";

export default function ManagementContractOverview() {
  const { managementContractId } = useParams();
  const [contract] = useManagementContract(managementContractId);
  const [editableDoc] = useEditabledoc(contract?.editabledoc?.id);
  const [cancelledDoc] = useEditabledoc(contract?.cancelled_doc?.id);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editCustomerOpen, setEditCustomerOpen] = React.useState(false);
  const [editCompanyOpen, setEditCompanyOpen] = React.useState(false);

  const canEdit = usePermissionCheck("change_can_management");

  const showSigningDetails = !contract?.is_signed;
  const isDigitalSign = contract?.uses_e_signing;

  const isCancelled = contract?.status === 6 || contract?.status === 7;
  const isDigitalCancelSign = contract?.uses_cancellation_e_signing;
  const isDraft = contract?.draft;

  const notesQ = buildQueryString({
    id__in: contract?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const customerQuery = buildQueryString({
    id__in: contract?.opponent?.id,
  });
  const customerFilter = (obj) => {
    return contract?.opponent?.id == obj.id;
  };

  const companyQuery = buildQueryString({
    id__in: contract?.company?.id || [],
  });
  const companyFilter = (obj) => {
    return obj.id === contract?.company?.id;
  };

  return (
    <>
      <UpdateManagementContractModalForm
        instance={contract}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        id={managementContractId}
      />

      <UpdateManagementContractCustomerModalForm
        instance={contract}
        isOpen={editCustomerOpen}
        onCheckout={() => setEditCustomerOpen(false)}
        id={managementContractId}
      />

      <UpdateManagementContractLandlordModalForm
        instance={contract}
        isOpen={editCompanyOpen}
        onCheckout={() => setEditCompanyOpen(false)}
        id={managementContractId}
      />

      <DetailInnerWrapper>
        {contract && showSigningDetails && !isDraft && (
          <>
            {isDigitalSign ? (
              <ManagementContractDigitalSign {...{ contract, editableDoc }} />
            ) : (
              <ManagementContractManualSign {...{ contract }} />
            )}
          </>
        )}

        {contract && isCancelled && (
          <>
            {isDigitalCancelSign ? (
              <ManagementContractDigitalCancelSign
                contract={contract}
                cancelledDoc={cancelledDoc}
              />
            ) : (
              <ManagementContractManualCancellation />
            )}
          </>
        )}

        <DetailPageBoxFlexWrapper>
          <div style={{ width: "65%", marginRight: 12 }}>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>
                    Översikt över {contract?.id_number}
                  </OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera avtalsdetaljer"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {contract && <DetailInfo infoObj={getInfoObj({ contract })} />}
            </DetailPageBox>
          </div>

          <div style={{ width: "35%" }}>
            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Förvaltningsuppdrag</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera förvaltningsuppdrag"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditCustomerOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <CustomerTable
                persistantFilterMethod={customerFilter}
                persistantQueryString={customerQuery}
                hideTitle
                hideSearch
                hideExport
                hideFilters
                hideColumns
              />
            </DetailPageBox>

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Förvaltningsbolag</OverviewTitle>
                  {canEdit && (
                    <OverviewSubtitle>
                      <TextButton
                        title="Redigera förvaltningsbolag"
                        iconType="edit"
                        iconPlacement="right"
                        clicked={() => setEditCompanyOpen(true)}
                      />
                    </OverviewSubtitle>
                  )}
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <CompanyTable
                persistantFilterMethod={companyFilter}
                persistantQueryString={companyQuery}
                hideTitle
                hideSearch
                hideExport
                hideFilters
                hideColumns
                hideSelect
              />
            </DetailPageBox>

            <DetailPageBox style={{ alignSelf: "flex-start" }}>
              <Notes
                notes={notes}
                contentType="management.customercontract"
                objectId={managementContractId}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

export function getOverviewPageCostsDetail(contract, costsData) {
  const startOfYear = moment().set({
    month: 0,
    date: 1,
    hour: 0,
  });
  const endOfYear = startOfYear.clone().add({ year: 1 });
  const indexEndOfYear = startOfYear
    .clone()
    .add({ year: 1 })
    .subtract({ days: 1 });

  const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
    start: startOfYear.format("YYYY-MM-DD"),
    end: endOfYear.format("YYYY-MM-DD"),
    contract,
  });

  const { specifiedEndDate: specifiedIndexEndDate } = getSpecifiedPeriodDates({
    start: startOfYear.format("YYYY-MM-DD"),
    end: indexEndOfYear.format("YYYY-MM-DD"),
    contract,
  });

  const costs = getCostBetweenMonthDates(
    costsData,
    specifiedStartDate,
    specifiedEndDate
  );

  const indexValueForPeriod = getIndexBetweenMonths(
    !!contract?.indexation,
    contract?.indexations,
    specifiedStartDate,
    specifiedIndexEndDate
  );

  costs.sort((a, b) => {
    const bRate =
      b.end_date && b.start_date ? 3 : b.end_date ? 2 : b.start_date ? 1 : 0;
    const aRate =
      a.end_date && a.start_date ? 3 : a.end_date ? 2 : a.start_date ? 1 : 0;

    if (bRate === aRate) {
      return (a.product_category ?? -1) - (b.product_category ?? -1);
    }
    return aRate - bRate;
  });

  let costInfo = [];

  let totalCost =
    typeof indexValueForPeriod === "number" ? indexValueForPeriod : 0;

  costs.forEach((c) => {
    const productKey = PRODUCT_CATEGORY_MAPPING[c.product_category] ?? "Övriga";

    const start = moment(c.start_date);
    const end = moment(c.end_date);

    let dtString = "";
    if (start._isValid && end._isValid) {
      dtString = ` (${start.format("YYYY-MM-DD")} - ${end.format(
        "YYYY-MM-DD"
      )})`;
    } else if (start._isValid) {
      dtString = ` (f.o.m ${start.format("YYYY-MM-DD")})`;
    } else if (end._isValid) {
      dtString = ` (t.o.m ${end.format("YYYY-MM-DD")})`;
    }

    const months = getMonthsForCost(c, specifiedStartDate, specifiedEndDate);
    const totVal = c.value * c.unit_amount * months;
    costInfo.push({
      title: `${c.title} (${productKey})`,
      value: `${toMoneyString(totVal)}${dtString}`,
    });

    totalCost += totVal;
  });

  if (indexValueForPeriod && indexValueForPeriod > 0) {
    costInfo.push({
      title: "Indexuppräkning",
      value: toMoneyString(indexValueForPeriod),
    });
  }

  if (costInfo.length) {
    costInfo.push({
      title: <b>Totalt</b>,
      value: <b>{toMoneyString(totalCost)}</b>,
    });
  } else {
    costInfo.push({
      title: "Kostnader Saknas",
      value: "",
    });
  }

  return costInfo;
}

function getInfoObj({ contract }) {
  const infoObj = {
    Avtalsdetaljer: [
      {
        title: "Avtalsstatus",
        value: (
          <StatusLabel state={contract?.state}>
            {contract?.state_display}
          </StatusLabel>
        ),
      },
      { title: "Avtals-ID", value: contract?.id_number },

      { title: "Startdatum", value: <DateCell date={contract?.start_date} /> },
      { title: "Slutdatum", value: <DateCell date={contract?.end_date} /> },
      {
        title: "Förlängt till",
        value: <DateCell date={contract?.renewed_to} />,
        hidden: !contract?.renewed_to,
      },
      {
        title: "Uppsägningstid",
        value: contract?.notify_interval
          ? durationParse(contract?.notify_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Förlängningstid",
        value: contract?.renew_interval
          ? durationParse(contract?.renew_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Varningstid",
        value: contract?.warning_interval
          ? durationParse(contract?.warning_interval, true)
          : "Ej specificerad",
      },
      {
        title: "Signerades",
        value: <DateCell date={contract?.signed_date} />,
      },
    ],
  };

  if (contract?.indexation) {
    infoObj["Indexuppräkning"] = [
      {
        title: "Andel av kostnaden som räknas upp (%)",
        value:
          contract?.indexation?.index_amount != null
            ? `${contract?.indexation?.index_amount}%`
            : "-",
      },
      {
        title: "Indexuppräkning",
        value: contract?.indexation?.index_quota
          ? `${contract?.indexation?.index_quota}%`
          : "Enligt KPI(1980)",
      },
      {
        title: "Använd minimal indexkvot",
        value: contract?.indexation?.index_min_none ? "Ja" : "Nej",
      },
      {
        title: "Minimal indexkvot",
        value: contract?.indexation?.index_min_val || "-",
      },
      {
        title: "Maximal indexkvot",
        value: contract?.indexation?.index_max_val || "-",
      },
      {
        title: "Basbelopp för index",
        value: contract?.indexation?.index_rent || "Använder baskostnad",
      },
      {
        title: "Startdatum för uppräkning",
        value: contract?.indexation?.index_base_date ? (
          <DateCell date={contract?.indexation?.index_base_date} />
        ) : (
          "Automatiskt"
        ),
      },
    ];
  }
  const costTitle = `Intäkter (${moment()
    .set({
      month: 0,
      date: 1,
      hour: 0,
    })
    .format("YYYY-MM-DD")} - ${moment()
    .set({ month: 11, date: 31, hour: 23 })
    .format("YYYY-MM-DD")}) i SEK`;

  infoObj[costTitle] = contract
    ? getOverviewPageCostsDetail(contract, contract?.costs)
    : [];

  return infoObj;
}
