import * as React from "react";
import { Pill, PillsWrapper } from "../../sharedStyles";

export default ({ OPTIONS, selectedOption, onSelect }) => {
  return (
    <PillsWrapper>
      {Object.keys(OPTIONS).map((key) => (
        <Pill
          {...{ active: selectedOption === key }}
          onClick={() => onSelect(key)}
        >
          {OPTIONS[key]}
        </Pill>
      ))}
    </PillsWrapper>
  );
};
