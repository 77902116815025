import styled from "styled-components";

export const DocumentSelectorWrapper = styled.div`
  width: 90%;
  margin: 40px auto;
  background-color: white;
  min-height: 600px;
  border-radius: 8px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
`;
