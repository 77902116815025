import * as React from "react";
import * as SC from "./styles";
import { CATEGORY_MAP } from "../../../utils";

export default ({ party, color, displayOnlyMode, fontSize }) => {
  return (
    <SC.Wrapper style={{ fontSize }} {...{ color, displayOnlyMode }}>
      {party?.user?.str_representation ||
        party?.str_representation ||
        `Part ${CATEGORY_MAP[party.category]}`}{" "}
      signerar här
    </SC.Wrapper>
  );
};
