import * as React from "react";
import { CirclePicker, BlockPicker } from "react-color";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { lightOrDark } from "../../../EditableDocBase/utils";

import DescriptionToolTip from "../Layout/DescriptionToolTip";
import * as SC from "./styles";

export default ({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  title,
  description,
  noTitle,
  blockPicker,
}) => {
  const dispatch = useDispatch();
  const [pickerOpen, setPickerOpen] = React.useState(false);

  const ref = React.useRef();

  const instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });
  let value = useFormInstanceField({ storeName, fieldKey });
  if (blockPicker) {
    value = `#${value}`;
  }
  const error = useFormError({ storeName, fieldKey });

  const colorChanged = (data) => {
    if (!data) return;
    const { hex } = data;
    if (!hex) return;

    if (blockPicker) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: hex.split("#")[1] },
        })
      );
    } else {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: hex },
        })
      );
    }
  };

  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!ref.current) return;

    if (ref.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setPickerOpen(false);
  };

  if (!instructions) {
    return null;
  }
  if (instructions._readOnly) {
    return null;
  }

  return (
    <SC.InputSpacing>
      {!noTitle && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <SC.InputFieldTitle>
            {title || instructions._label}
            {instructions._required ? "*" : ""}
          </SC.InputFieldTitle>
          {(instructions._helpText || description) && (
            <DescriptionToolTip
              description={description || instructions._helpText}
            />
          )}
        </div>
      )}

      <div
        onClick={() => setPickerOpen(true)}
        style={{
          backgroundColor: `${value || "#5165FB"}`,
          color:
            lightOrDark(value || "#5165FB") === "light" ? "#444A55" : "#fff",
          border: value ? "none" : "thin solid #ddd",
          position: "relative",
          zIndex: "500",
          padding: "10px 24px",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "30px",
          display: "inline-flex",
          cursor: "pointer",
        }}
      >
        {value ? value : "Tryck för att välja färgtema"}
        {pickerOpen && (
          <div
            ref={ref}
            style={{
              position: "absolute",
              top: "0",
              boxShadow: "2px 2px 6px 6px rgba(0,0,0,0.09)",
              left: 0,
              backgroundColor: "white",
              padding: "6px",
              borderRadius: "5px",
            }}
          >
            {blockPicker ? (
              <BlockPicker
                color={value ?? {}}
                onChangeComplete={(data) => colorChanged(data)}
              />
            ) : (
              <CirclePicker
                color={value ?? {}}
                onChangeComplete={(data) => colorChanged(data)}
              />
            )}
          </div>
        )}
      </div>

      {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.InputSpacing>
  );
};
