import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteObject, setActiveFormInstance } from "../../../../store/base";
import {
  useNoteForm,
  constants,
  update,
  reverseCreateNote,
} from "../../../../store/notes";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { InfoBox } from "../../../Displays";
import { TextButton } from "../../../Forms/Base/Buttons";
import MenuButton from "../../../Forms/Base/Buttons/MenuButton";
import { TextField } from "../../../Forms/Base/Fields";
import NonConnectedTextField from "../../../Forms/Base/Old/NonConnected/NonConnectedTextField";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import StandardModal from "../../../Modals/StandardModal";
import { BodyText } from "../../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../styles";
import * as SC from "./styles";

function Notes({
  notes,
  contentType,
  objectId,
  title = "Anteckningar",
  fieldTitle = "Anteckning",
  attrName = "notes",
  green = false,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [showAll, setShowAll] = React.useState(false);
  const [editID, setEditID] = React.useState(false);
  const [sumbitEditLoading, setSubmitEditLoading] = React.useState(false);
  const [sumbitCreateLoading, setSubmitCreateLoading] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [createValue, setCreateValue] = React.useState("");
  const currentUserId = useSelector((state) => state.app?.user?.id);
  const currentUserType = useSelector((state) => state.app?.user?.user_type);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [awaitDeleteId, setAwaitDeleteId] = React.useState(null);

  const patchLoaded = Boolean(useNoteForm("PATCH", editID));

  React.useEffect(() => {
    if (notes?.length && notes?.length < 2) {
      setShowAll(true);
    }
  }, [notes]);

  const reversedNotes = cloneDeep(notes || []).reverse();

  const displayedNotes = showAll ? reversedNotes : reversedNotes.slice(0, 2);

  React.useEffect(() => {
    dispatch(
      setActiveFormInstance({
        storeName,
        data: notes?.find((n) => n.id === editID),
      })
    );
  }, [editID]);

  React.useEffect(() => {
    if (!createOpen) {
      setCreateValue("");
    }
  }, [createOpen]);

  const handleEditSuccess = () => {
    setSubmitEditLoading(false);
    setEditID(false);
  };

  const handleCreateSuccess = () => {
    setSubmitCreateLoading(false);

    setCreateOpen(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Anteckningen sparades",
      })
    );
  };

  const editPreProcess = (data) => {
    const dataClone = cloneDeep(data);

    // set editiing user to owner
    dataClone.owner = { id: currentUserId };

    return dataClone;
  };

  const submitDeleteNote = () => {
    const instance = notes.find((n) => n.id === awaitDeleteId);

    dispatch(
      deleteObject({
        instance,
        constants,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Anteckningen togs bort",
            })
          );
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Anteckningen kunde ej raderas",
            })
          );
        },
      })
    );
  };

  const submitEditNote = () => {
    setSubmitEditLoading(true);

    dispatch(
      update({
        id: editID,
        successCallback: handleEditSuccess,
        preProcess: editPreProcess,
        errorCallback: () => setSubmitEditLoading(false),
      })
    );
  };

  const getActions = (note) => {
    const actions = [];

    if (note?.owner?.id === currentUserId || currentUserType === 0) {
      actions.push({
        name: "Redigera",
        onClick: () => setEditID(note.id),
      });
    }
    if (note?.owner?.id === currentUserId || currentUserType === 0) {
      actions.push({
        name: "Radera",
        onClick: () => {
          setAwaitDeleteId(note.id);
          setConfirmDeleteOpen(true);
        },
      });
    }

    return actions;
  };

  const submitCreateNote = () => {
    if (!createValue || !contentType || !objectId || !attrName) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Information saknas",
          description:
            "Anteckningen kunde inte sparas. Kontakta Pigello om detta fel kvarstår.",
        })
      );
    }

    setSubmitCreateLoading(true);
    const noteObj = {
      content: createValue,
      owner: { id: currentUserId },
      content_type: contentType,
      object_id: objectId,
      attr_name: attrName,
    };

    dispatch(
      reverseCreateNote({
        noteObj,
        successCallback: handleCreateSuccess,
        errorCallback: (error) => {
          setSubmitCreateLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Anteckningen kunde inte sparas",
              description: error,
            })
          );
        },
      })
    );
  };
  return (
    <>
      <ConfirmationModal
        title={`Bekräfta radering av ${fieldTitle?.toLowerCase()}`}
        renderContent={() => <div>Denna åtgärd kan inte ångras</div>}
        isOpen={confirmDeleteOpen}
        closeFunction={() => setConfirmDeleteOpen(false)}
        acceptCallback={submitDeleteNote}
        acceptTitle="Radera"
      />

      <StandardModal
        isOpen={editID}
        withActionBar
        saveFunction={submitEditNote}
        closeFunction={() => setEditID(false)}
        title={`Redigera ${fieldTitle?.toLowerCase()}`}
        small
      >
        {(!patchLoaded || sumbitEditLoading) && <OverlaySpinner />}

        <TextField
          {...{
            rows: "10",
            storeName,
            method: "PATCH",
            fieldKey: "content",
            title: fieldTitle,
          }}
        />
      </StandardModal>

      <StandardModal
        isOpen={createOpen}
        withActionBar
        actionBarAcceptTitle={`Spara ${fieldTitle.toLowerCase()}`}
        canAccept={createValue?.length > 0}
        saveFunction={submitCreateNote}
        closeFunction={() => setCreateOpen(false)}
        small
        title={`Ny ${fieldTitle.toLowerCase()}`}
      >
        {sumbitCreateLoading && <OverlaySpinner />}

        <NonConnectedTextField
          id="create-new-note"
          value={createValue}
          handleChange={(val) => setCreateValue(val)}
          title={fieldTitle}
          required
          rows="10"
        />
      </StandardModal>

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>{title}</OverviewTitle>
          {contentType && objectId && (
            <OverviewSubtitle>
              <TextButton
                title="Lägg till ny"
                clicked={() => setCreateOpen(true)}
                iconType="add"
                iconPlacement="right"
              />
            </OverviewSubtitle>
          )}
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <SC.Wrapper>
        {displayedNotes?.map((n) => (
          <SC.NoteContainer key={n.id} {...{ green }}>
            <SC.NoteHeader>
              <SC.NoteTitle>
                {n.owner?.str_representation}
                <SC.NoteDate>{n.created_at}</SC.NoteDate>
              </SC.NoteTitle>
              {getActions(n)?.length > 0 && (
                <MenuButton leftAligned actions={getActions(n)} />
              )}
            </SC.NoteHeader>

            <SC.NoteContent>
              {n.content || `Tom ${fieldTitle?.toLowerCase()}`}
            </SC.NoteContent>
          </SC.NoteContainer>
        ))}

        {!showAll && notes?.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 24,
            }}
          >
            <BodyText>Visar 2 av {notes?.length}</BodyText>
            <TextButton
              title={`Visa alla`}
              extraStyle={{ marginRight: 0 }}
              iconType="add"
              iconPlacement="right"
              clicked={() => setShowAll(true)}
            />
          </div>
        )}
      </SC.Wrapper>
    </>
  );
}

export default Notes;
