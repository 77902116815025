import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import useActiveFilters from "../../hooks/useActiveFilters";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { usePermissionCheck } from "../../store/base";
import ModalForm from "../../components/Forms/BasicDoc/ChapterForm/ModalForm";
import { useBasicDocPaginationCount } from "../../store/basicDocs/hooks/retrieve";
import BasicDocs from "./overview/BasicDocs";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

export default function MainDocumentsOverview() {
  const { path, url } = useRouteMatch();
  const [createBasicActive, setCreateBasicActive] = React.useState(false);
  usePermissionRedirect(["view_can_baseobject"]);

  const canAddBasic = usePermissionCheck("add_can_baseobject");

  const [activeCount] = useBasicDocPaginationCount({});

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: BasicDocs,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={4}>
          Antal dokument: {activeCount || "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <>
      {canAddBasic ? (
        <PrimaryButton
          title="Lägg till dokument"
          newDesign
          clicked={() => setCreateBasicActive(true)}
        />
      ) : null}
    </>
  );

  return (
    <>
      <ModalForm
        isOpen={createBasicActive}
        onCheckout={() => setCreateBasicActive(false)}
        method="POST"
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Dokument`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
