import * as React from "react";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./billectaListDefs";

// store, state
import {
  useCompanyPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/companies";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  handleRowClicked,
  withPersistantSortBy,
  withPersistantGlobalFilter,
  billectaEvents,
}) => {
  const storeName = constants.STORE_NAME;

  const filterInstructions = {
    name: { operator: "icontains" },
    orgnr: { operator: "icontains" },
  };

  const columns = React.useMemo(
    () => columnDefs(billectaEvents),
    [persistantFilterMethod, persistantQueryString, billectaEvents]
  );
  const fetchAllTreshold = 25;

  return (
    <>
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useCompanyPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        onRowClicked={handleRowClicked}
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Bolag"}
        detailUrlMethod={(original) =>
          detailUrl({ id: original?.id, showInvoicingSettingPrompt: true })
        }
        exportSheetController={exportSheetController}
        {...{
          hideFilters,
          hideSearch,
          hideExport,
          withPersistantSortBy,
          hideColumns,
          withPersistantGlobalFilter,
        }}
      />
    </>
  );
};
