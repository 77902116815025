import * as React from "react";

// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import {
  TextInput
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/editabledocs";

export default () => {
  const storeName = constants.STORE_NAME;
  const method = "POST";
  return (
    <>
      <FormAreaTitle>Generell information</FormAreaTitle>

      <TextInput 
      storeName={storeName} 
      fieldKey="name"
      method={method}
      />
      <TextInput 
      storeName={storeName} 
      fieldKey="scrive_name"
      method={method}
      />
      <TextInput 
      storeName={storeName} 
      fieldKey="orgnr"
      method={method}
      />
      <TextInput 
      storeName={storeName} 
      fieldKey="email"
      method={method}
      type="email"
      />
      <TextInput 
      storeName={storeName} 
      fieldKey="sms_sender_name"
      method={method}
      />
    </>
  );
};
