import * as React from "react";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedInputField from "../../Forms/Base/Old/NonConnected/NonConnectedInputField";
import { InnerBox } from "../../sharedStyles";
import * as SC from "./styles";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import NonConnectedTableSelect from "../../Forms/Base/Old/NonConnected/NonConnectedTableSelect";

import {
  constants as costCenterConstants,
  performFilter as costCenterPerformFilter,
  constructStrRep as costCenterConstructStrRep,
  useInvoicingCostCenterPagination,
} from "../../../store/invoicingCostCenters";
import {
  constants as projectConstants,
  performFilter as projectPerformFilter,
  constructStrRep as projectConstructStrRep,
  useInvoicingProjectPagination,
} from "../../../store/invoicingProjects";
import {
  constants as productConstants,
  performFilter as productPerformFilter,
  constructStrRep as productConstructStrRep,
  useInvoicingProductPagination,
} from "../../../store/invoicingProducts";
import costCenterListDefs from "../../Lists/InvoicingCostCenters/listDefs";
import projectListDefs from "../../Lists/InvoicingProjects/listDefs";
import productListDefs from "../../Lists/InvoicingProducts/listDefs";
const STATUS_CHOICES = [
  { value: "Open", label: "Öppna fakturor" },
  { value: "Closed", label: "Stängda fakturor" },
  { value: "WithInvoiceReminder", label: "Med påminnelse" },
  { value: "WithoutInvoiceReminder", label: "Utan påminnelse" },
];

export default ({
  invoiceNumber,
  setInvoiceNumber,
  invoiceDateFrom,
  setInvoiceDateFrom,
  invoiceDateTo,
  setInvoiceDateTo,
  dueDateFrom,
  setDueDateFrom,
  dueDateTo,
  setDueDateTo,
  periodDateFrom,
  setPeriodDateFrom,
  periodDateTo,
  setPeriodDateTo,
  createdDateFrom,
  setCreatedDateFrom,
  createdDateTo,
  setCreatedDateTo,
  attestedDateFrom,
  setAttestedDateFrom,
  attestedDateTo,
  setAttestedDateTo,
  closedDateFrom,
  setClosedDateFrom,
  closedDateTo,
  setClosedDateTo,
  selectedCostCenter,
  setSelectedCostCenter,
  selectedProject,
  setSelectedProject,
  selectedProducts,
  setSelectedProducts,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  return (
    <SC.FieldsWrapper>
      <InnerBox>
        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Fakturanummer/OCR
            </OverviewTitle>
            <NonConnectedInputField
              value={invoiceNumber}
              onChange={({ target: { value } }) => setInvoiceNumber(value)}
              id="invoice-number"
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Status
            </OverviewTitle>

            <NonConnectedSelect
              choices={STATUS_CHOICES}
              value={selectedStatuses?.map((s) =>
                STATUS_CHOICES.find((v) => v.value === s)
              )}
              id="status"
              isMulti
              onUpdate={(val) => setSelectedStatuses(val)}
              getOptionValue={(choice) => choice.value}
              getOptionLabel={(choice) => choice.label}
              bodyPortaled
            />
          </SC.FieldBox>
        </SC.Row>

        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Fakturadatum
            </OverviewTitle>
            <NonConnectedDatePicker
              noMargin
              label="Från"
              value={invoiceDateFrom}
              onChange={(val) => setInvoiceDateFrom(val)}
              id="invoice-date-from"
            />
            <NonConnectedDatePicker
              noMargin
              label="Till"
              value={invoiceDateTo}
              onChange={(val) => setInvoiceDateTo(val)}
              id="invoice-date-to"
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Förfallodatum
            </OverviewTitle>
            <NonConnectedDatePicker
              noMargin
              label="Från"
              value={dueDateFrom}
              onChange={(val) => setDueDateFrom(val)}
              id="due-date-from"
            />
            <NonConnectedDatePicker
              noMargin
              label="Till"
              value={dueDateTo}
              onChange={(val) => setDueDateTo(val)}
              id="due-date-to"
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Period
            </OverviewTitle>
            <NonConnectedDatePicker
              noMargin
              label="Från"
              value={periodDateFrom}
              onChange={(val) => setPeriodDateFrom(val)}
              id="period-date-from"
            />
            <NonConnectedDatePicker
              noMargin
              label="Till"
              value={periodDateTo}
              onChange={(val) => setPeriodDateTo(val)}
              id="period-date-to"
            />
          </SC.FieldBox>
        </SC.Row>

        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Skapades
            </OverviewTitle>
            <NonConnectedDatePicker
              noMargin
              label="Från"
              value={createdDateFrom}
              onChange={(val) => setCreatedDateFrom(val)}
              id="created-date-from"
            />
            <NonConnectedDatePicker
              noMargin
              label="Till"
              value={createdDateTo}
              onChange={(val) => setCreatedDateTo(val)}
              id="created-date-to"
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Attesterades
            </OverviewTitle>
            <NonConnectedDatePicker
              noMargin
              label="Från"
              value={attestedDateFrom}
              onChange={(val) => setAttestedDateFrom(val)}
              id="attested-date-from"
            />
            <NonConnectedDatePicker
              noMargin
              label="Till"
              value={attestedDateTo}
              onChange={(val) => setAttestedDateTo(val)}
              id="attested-date-to"
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Avslutades
            </OverviewTitle>
            <NonConnectedDatePicker
              noMargin
              label="Från"
              value={closedDateFrom}
              onChange={(val) => setClosedDateFrom(val)}
              id="closed-date-from"
            />
            <NonConnectedDatePicker
              noMargin
              label="Till"
              value={closedDateTo}
              onChange={(val) => setClosedDateTo(val)}
              id="closed-date-to"
            />
          </SC.FieldBox>
        </SC.Row>

        <SC.Row>
          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Kostnadsställe
            </OverviewTitle>

            <NonConnectedTableSelect
              tableStoreName={costCenterConstants.STORE_NAME}
              selected={selectedCostCenter}
              listDefs={costCenterListDefs}
              paginationMethod={useInvoicingCostCenterPagination}
              performFilter={costCenterPerformFilter}
              constructStrRep={costCenterConstructStrRep}
              title="Välj kostnadsställe"
              handleChange={(d) => setSelectedCostCenter(d)}
              setWholeObject
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Projekt
            </OverviewTitle>
            <NonConnectedTableSelect
              tableStoreName={projectConstants.STORE_NAME}
              selected={selectedProject}
              listDefs={projectListDefs}
              paginationMethod={useInvoicingProjectPagination}
              performFilter={projectPerformFilter}
              constructStrRep={projectConstructStrRep}
              title="Välj projekt"
              handleChange={(d) => setSelectedProject(d)}
              setWholeObject
            />
          </SC.FieldBox>

          <SC.FieldBox>
            <OverviewTitle small style={{ marginBottom: "8px" }}>
              Produkter
            </OverviewTitle>
            <NonConnectedTableSelect
              tableStoreName={productConstants.STORE_NAME}
              selected={selectedProducts}
              isMany
              listDefs={productListDefs}
              paginationMethod={useInvoicingProductPagination}
              performFilter={productPerformFilter}
              constructStrRep={productConstructStrRep}
              title="Välj produkter"
              handleChange={(d) => setSelectedProducts(d)}
              setWholeObject
            />
          </SC.FieldBox>
        </SC.Row>
      </InnerBox>
    </SC.FieldsWrapper>
  );
};
