export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_MANAGEMENT_CONTRACT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_MANAGEMENT_CONTRACT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_MANAGEMENT_CONTRACT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_MANAGEMENT_CONTRACT",
  DESTROY_FORM: "DESTROY_FORM_MANAGEMENT_CONTRACT",
  SET_FORM_ERROR: "SET_FORM_ERROR_MANAGEMENT_CONTRACT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_MANAGEMENT_CONTRACT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_MANAGEMENT_CONTRACT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_MANAGEMENT_CONTRACT",
  RESET_STATE: "RESET_STATE_MANAGEMENT_CONTRACT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_MANAGEMENT_CONTRACT",
  REMOVE_OBJECT: "REMOVE_OBJECT_MANAGEMENT_CONTRACT",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_MANAGEMENT_CONTRACT",
  CLEAR_FETCHED: "CLEAR_FETCHED_MANAGEMENT_CONTRACT",

  LIST_URL: "/management/customercontract/list/",
  POST_URL: "/management/customercontract/",
  PATCH_URL: "/management/customercontract/",
  GET_URL: "/management/customercontract/",

  STORE_NAME: "managementContracts",
};
