export default [
  {
    name: "Pigello - Förvaltare grundbehörighet",
    id: 5,
    isBase: true,
    permissions: [
      "view_can_management",
      "add_can_management",
      "change_can_management",
      "view_can_company",
      "view_can_contract",
      "add_can_contract",
      "change_can_contract",
      "view_can_fastdoc",
      "add_can_fastdoc",
      "change_can_fastdoc",
      "delete_can_fastdoc",
    ],
    users: [],
  },
  {
    name: "Pigello - Förvaltare administratör",
    id: 6,
    isBase: true,
    permissions: [
      "view_can_management",
      "add_can_management",
      "change_can_management",
      "delete_can_management",
      "view_can_company",
      "add_can_company",
      "change_can_company",
      "delete_can_company",
      "view_can_contract",
      "add_can_contract",
      "change_can_contract",
      "delete_can_contract",
      "view_can_user",
      "add_can_user",
      "change_can_user",
      "delete_can_user",
      "view_can_permissions",
      "add_can_permissions",
      "change_can_permissions",
      "delete_can_permissions",
      "view_can_billing",
      "add_can_billing",
      "change_can_billing",
      "delete_can_billing",
      "view_can_e_signing",
      "add_can_e_signing",
      "change_can_e_signing",
      "delete_can_e_signing",
      "view_can_fastdoc",
      "add_can_fastdoc",
      "change_can_fastdoc",
      "delete_can_fastdoc",
    ],
    users: [],
  },
];
