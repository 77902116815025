import { uploadBase64ToS3 } from "../../../utils/s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    const docPath = responseData?.doc?.put;
    let base64file = formInstance?.docData_;

    const prefixSplit = base64file?.split(";")[0];
    const mimeType = prefixSplit.split(":")[1];

    const b64Prefix = `data:${mimeType ? mimeType : "application/pdf"};base64,`;

    if (base64file && !base64file.includes(b64Prefix)) {
      base64file = b64Prefix + base64file;
    }

    if (!docPath || !base64file) {
      return;
    }

    await uploadBase64ToS3({
      signedUrl: docPath,
      base64: base64file,
    });

    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
