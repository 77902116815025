import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import { DoubleFieldWrapper } from "../../../components/Forms/Base/Chapters/styles";
import {
  Checkbox,
  NumberInput,
  Select,
  TextField,
  TextInput,
} from "../../../components/Forms/Base/Fields";
import TableFileM2m from "../../../components/Forms/Base/Fields/TableFileM2m";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  updateActiveFormInstance,
  useFormInstanceField,
  usePermissionCheck,
} from "../../../store/base";
import {
  useEditabledoc,
  useEditabledocForm,
  constants,
  update,
  destroyPatchForm,
} from "../../../store/editabledocs";

export default function Settings() {
  const dispatch = useDispatch();
  const { editableDocId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;
  const method = "PATCH";

  const [editableDoc] = useEditabledoc(editableDocId);
  useEditabledocForm("PATCH", editableDocId);
  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const canChange = usePermissionCheck("change_can_contract");

  React.useEffect(() => {
    if (editableDoc) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: editableDoc,
        })
      );
    }
  }, [editableDoc]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  const saveUpdate = () => {
    setLoading(true);
    const cleanData = {
      title: instance.title,
      category: instance.category,
      days_to_sign: instance.days_to_sign,
      days_to_remind: instance.days_to_remind,
      invitation_message: instance.invitation_message,
      confirmation_message: instance.confirmation_message,
    };

    dispatch(
      update({
        id: editableDocId,
        forceData: cleanData,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitle>Inställningar för mall</OverviewTitle>
        </OverviewTitleWrapper>

        <InnerBox style={{ maxWidth: 670 }}>
          <TextInput
            title="Namn på mall"
            storeName={storeName}
            fieldKey="title"
            method={method}
          />

          <DoubleFieldWrapper>
            <NumberInput
              title="Antal dagar att signera"
              description="Ange hur många dagar parterna har på sig att signera avtalet innan signeringen misslyckas"
              storeName={storeName}
              fieldKey={"days_to_sign"}
              placeholder="90 dagar är standard om inget anges"
              method={method}
              extraStyles={{ minWidth: 310, marginRight: 12 }}
            />
            <NumberInput
              title="Påminnelse skickas efter X dagar"
              storeName={storeName}
              fieldKey={"days_to_remind"}
              description="Om inte parten har signerat kommer en påminnelse automatiskt att skickas ut efter X antal dagar"
              placeholder="Ingen påminnelse skickas om inget anges"
              method={method}
              extraStyles={{ minWidth: 310 }}
            />
          </DoubleFieldWrapper>

          <DoubleFieldWrapper>
            <TextField
              title="Inbjudningsmeddelande"
              description="Detta meddelande kommer att skickas i E-postmeddelandet när parten bjuds in till att signera om E-post är valt som leveranssätt för parten"
              storeName={storeName}
              fieldKey={"invitation_message"}
              method={method}
              extraStyles={{ minWidth: 310, maxWidth: 310, marginRight: 12 }}
            />
            <TextField
              title="Bekräftelsemeddelande"
              description="Detta meddelande kommer att skickas i E-postmeddelandet när parten informeras om att signeringen är avslutad om E-post är valt som leveranssätt för parten"
              storeName={storeName}
              fieldKey={"confirmation_message"}
              method={method}
              extraStyles={{ minWidth: 310, maxWidth: 310 }}
            />
          </DoubleFieldWrapper>

          <Checkbox
            title="Detta är en publik mall"
            storeName={storeName}
            fieldKey={"is_public"}
            method={method}
          />

          {canChange && (
            <div
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <PrimaryButton title="Spara uppdateringar" clicked={saveUpdate} />
            </div>
          )}
        </InnerBox>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
