import * as React from "react";
import * as SC from "./styles";

import settingsIcon from "../../../assets/svg/material/settings_black.svg";
import notifIcon from "../../../assets/svg/material/notifications_black.svg";
import helpIcon from "../../../assets/svg/material/help_outline_black.svg";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import NotificationsPopup from "../../Popups/NotificationsPopup/NotificationsPopup";
import Logo from "../Sidebar/Logo";

export default () => {
  const { push } = useHistory();
  const user = useSelector((state) => state.app.user);

  const [notifsOpen, setNotifsOpen] = React.useState(false);

  const userImg = user?.image?.get;
  const userName = `${user?.first_name ? `${user?.first_name}+` : ""}${
    user?.last_name ? `${user?.last_name}` : ""
  }`;

  const goToSupport = () => {
    window.open("https://pigello.se/support/guider/", "_blank").focus();
  };

  const goToSettings = () => {
    push("/account/groups-and-users");
  };

  const goToProfile = () => {
    push("/account/user");
  };

  return (
    <SC.Wrapper formPadding>
      <Logo noBorder borderRight />

      <SC.TopBarItemsContainer>
        <SC.IconButton
          onClick={goToSupport}
          style={{ backgroundPosition: "50% 60%" }}
          icon={helpIcon}
        />
        <SC.IconButton onClick={goToSettings} icon={settingsIcon} />
        <SC.IconButton onClick={() => setNotifsOpen(true)} icon={notifIcon}>
          <NotificationsPopup open={notifsOpen} setOpen={setNotifsOpen} />
        </SC.IconButton>
        <SC.ProfileBadge
          onClick={goToProfile}
          name={userName}
          image={userImg}
        />
      </SC.TopBarItemsContainer>
    </SC.Wrapper>
  );
};
