const getReadableDate = (date) => {
  const strDate = date.toString();

  const lastChar = strDate.substr(strDate.length - 1);

  if (lastChar === "1" || lastChar === "2") {
    return `${date}:a`;
  } else {
    return `${date}:e`;
  }
};

const getReadableOcurrance = (ocurrance, type) => {
  if (ocurrance === 1) return "varje";
  if (ocurrance === 2)
    return type === "quarter" || type === "year" ? "vartannat" : "varannan";

  return `var ${ocurrance}:e`;
};

/**
 * Gets interval str from invoice setting
 */
export const getIntervalStr = (intervalSetting) => {
  if (!intervalSetting) return "Saknar intervall";
  // Monthly
  if (intervalSetting.mode === 0) {
    return `Den ${getReadableDate(
      intervalSetting.day_in_month
    )} ${getReadableOcurrance(intervalSetting.interval, "month")} månad`;
  }

  if (intervalSetting.mode === 1) {
    return `Den ${getReadableDate(
      intervalSetting.day_in_month
    )} i den ${getReadableDate(
      intervalSetting.on_month
    )} månaden ${getReadableOcurrance(
      intervalSetting?.interval,
      "quarter"
    )} kvartal`;
  }

  if (intervalSetting.mode === 2) {
    return `Den ${getReadableDate(
      intervalSetting.day_in_month
    )} i den  i den ${getReadableDate(
      intervalSetting.on_month
    )} månaden ${getReadableOcurrance(intervalSetting.interval, "year")} år`;
  }

  if (intervalSetting.mode === 3) {
    return `Var ${getReadableDate(intervalSetting.interval)} dag`;
  }
};

export const getReadableDebtCollectionLevel = (level) => {
  switch (level) {
    case "LatePaymentFee":
      return "Förseningsavgift";
    case "Reminders":
      return "Påminnelse";
    case "DebtCollection":
      return "Inkasso";

    default:
      return level;
  }
};

export const getReadableInterestType = (interestType) => {
  switch (interestType) {
    case "AboveEffectiveReference":
      return "Över gällande referensränta";
    case "Fixed":
      return "Fast ränta";
    case "NoInterest":
      return "Ingen dröjsmålsränta";

    default:
      return "";
  }
};

export const getReadableInvoicePeriod = (period) => {
  switch (period) {
    case "Current":
      return "Innevarande";
    case "Next":
      return "Nästkommande";
    case "Previous":
      return "Föregående";

    default:
      return "";
  }
};
