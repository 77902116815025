export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_COSTS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_COSTS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_COSTS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_COSTS",
  DESTROY_FORM: "DESTROY_FORM_COSTS",
  SET_FORM_ERROR: "SET_FORM_ERROR_COSTS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_COSTS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_COSTS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_COSTS",
  RESET_STATE: "RESET_STATE_COSTS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_COSTS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_COSTS",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_COSTS",
  CLEAR_FETCHED: "CLEAR_FETCHED_COSTS",

  LIST_URL: "/accounting/costs/cost/list/",
  POST_URL: "/accounting/costs/cost/",
  PATCH_URL: "/accounting/costs/cost/",
  GET_URL: "/accounting/costs/cost/",

  STORE_NAME: "costs",
};
