import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";
import { PrimaryButton } from "../Forms/Base/Buttons";
import { StatusLabel } from "../Lists/Base/CompleteList/styles";
import * as SC from "./styles";

/**
 * Expects entire debtsetting,
 */
export default ({ setting, onCopy }) => {
  if (!setting) return null;

  const getDueDateStr = () => {
    if (setting.contract_pay_last_day_month) return "Den sista i månaden";

    return `Efter ${setting.contract_payment_term_days} dagar`;
  };

  return (
    <SC.Container>
      <SC.PrimaryCard>
        <OverviewTitleWrapper style={{ padding: "0 12px" }}>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Betalningsvillkor</OverviewTitle>
            <OverviewSubtitle>{setting.str_representation}</OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <SC.DataRow>
          <SC.DataTitle>Fakturor förfaller</SC.DataTitle>
          <SC.DataContent>{getDueDateStr()}</SC.DataContent>
        </SC.DataRow>
      </SC.PrimaryCard>

      <SC.PrimaryCard>
        <OverviewTitleWrapper style={{ padding: "0 12px" }}>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Dröjsmålsränta</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <SC.DataRow>
          <SC.DataTitle>Typ av ränta</SC.DataTitle>
          <SC.DataContent>{setting.interest_type_display}</SC.DataContent>
        </SC.DataRow>

        <SC.DataRow>
          <SC.DataTitle>Ränta</SC.DataTitle>
          <SC.DataContent>{setting.interest_percentage}%</SC.DataContent>
        </SC.DataRow>

        <SC.DataRow>
          <SC.DataTitle>Börjar räknas</SC.DataTitle>
          <SC.DataContent>
            {setting.interest_start_due_date} dagar efter förfallodatum
          </SC.DataContent>
        </SC.DataRow>

        {setting.reason_higher_interest && (
          <SC.DataRow>
            <SC.DataTitle>Anledning till hög ränta</SC.DataTitle>
            <SC.DataContent>{setting.reason_higher_interest}</SC.DataContent>
          </SC.DataRow>
        )}
      </SC.PrimaryCard>

      <SC.PrimaryCard>
        <OverviewTitleWrapper style={{ padding: "0 12px" }}>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Påminnelser</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <SC.DataRow>
          <SC.DataTitle>Skicka påminnelser</SC.DataTitle>
          <SC.DataContent>
            {setting.use_reminders ? (
              <StatusLabel state={0}>Aktiv</StatusLabel>
            ) : (
              <StatusLabel state={6}>Inaktiv</StatusLabel>
            )}
          </SC.DataContent>
        </SC.DataRow>

        {setting.use_reminders && (
          <>
            {" "}
            <SC.DataRow>
              <SC.DataTitle>Skickas</SC.DataTitle>
              <SC.DataContent>
                {setting.send_reminder_after_days} dagar efter förfallodatum
              </SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Betalvillkor</SC.DataTitle>
              <SC.DataContent>
                {setting.reminder_term_days} dagar
              </SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Påminnelseavgift</SC.DataTitle>
              <SC.DataContent>{setting.reminder_fee} SEK</SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Nådedagar</SC.DataTitle>
              <SC.DataContent>
                {setting.reminder_grace_days === 0
                  ? "Inga"
                  : `${setting.reminder_grace_days} dagar`}
              </SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Minsta belopp för påminnelse</SC.DataTitle>
              <SC.DataContent>
                {setting.reminder_grace_amount === 0
                  ? "Skickas alltid"
                  : `${setting.reminder_grace_amount} SEK`}
              </SC.DataContent>
            </SC.DataRow>
          </>
        )}
      </SC.PrimaryCard>

      <SC.PrimaryCard>
        <OverviewTitleWrapper style={{ padding: "0 12px" }}>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Automatisk kravhantering</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <SC.DataRow>
          <SC.DataTitle>Använd kravhantering</SC.DataTitle>
          <SC.DataContent>
            {setting.use_debt_collection ? (
              <StatusLabel state={0}>Aktiv</StatusLabel>
            ) : (
              <StatusLabel state={6}>Inaktiv</StatusLabel>
            )}
          </SC.DataContent>
        </SC.DataRow>

        {setting.use_debt_collection && (
          <>
            <SC.DataRow>
              <SC.DataTitle>Inleds</SC.DataTitle>
              <SC.DataContent>
                {setting.send_to_debt_after_days} dagar efter förfallodatum
              </SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Antal påminnelser innan inkassokrav</SC.DataTitle>
              <SC.DataContent>{setting.number_of_reminders}</SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Krav inleds med</SC.DataTitle>
              <SC.DataContent>{setting.debt_start_mode_display}</SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Krav avslutas med</SC.DataTitle>
              <SC.DataContent>{setting.debt_end_mode_display}</SC.DataContent>
            </SC.DataRow>
            <SC.DataRow>
              <SC.DataTitle>Betalvillkor</SC.DataTitle>
              <SC.DataContent>{setting.debt_term_days} dagar</SC.DataContent>
            </SC.DataRow>
          </>
        )}
      </SC.PrimaryCard>

      {onCopy && (
        <SC.ActionBanner>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle style={{ color: "white" }} small>
                Vill du göra ändringar?
              </OverviewTitle>
              <OverviewSubtitle style={{ color: "white" }}>
                Inställningar för betalningsvillkor kan inte redigeras från
                denna vy då det kan påverka andra avtal som använder sig av
                denna inställning. Kopiera denna inställning och skapa ny för
                att redigera inställningarna.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <PrimaryButton
              inverted
              title="Skapa ny inställning utifrån den valda"
              clicked={onCopy}
            />
          </div>
        </SC.ActionBanner>
      )}
    </SC.Container>
  );
};
