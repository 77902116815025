import * as React from "react";

// style, design
import { DoubleFieldWrapper } from "../../../Base/Chapters/styles";
import { AddressField, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/customers";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Förvaltningsuppdrag</OverviewTitle>
          <OverviewSubtitle>Ange detaljer om kunden</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        title="Bolagsnamn"
        fieldKey={"corporate_name"}
        method={method}
      />

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          title={"Kontaktperson förnamn"}
          fieldKey={"first_name"}
          method={method}
        />
        <TextInput
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          title={"Kontaktperson efternamn"}
          fieldKey={"last_name"}
          method={method}
        />
      </DoubleFieldWrapper>

      <TextInput
        storeName={storeName}
        fieldKey={"email"}
        title="E-post"
        method={method}
      />

      <TextInput
        storeName={storeName}
        fieldKey={"legal_id"}
        title={"Organisationsnummer"}
        method={method}
      />

      <AddressField
        storeName={storeName}
        fieldKey={"address"}
        title={"Adress"}
        method={method}
      />
    </>
  );
};
