import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import * as SharedStyles from "../../components/sharedStyles";
import PageTitle from "../Layouts/PageTitle/PageTitle";
import { useNotificationSetting, constants } from "../../store/notifications";
import PlainForm from "../../components/Forms/Notification/Settings/PlainForm";
import { updateActiveFormInstance } from "../../store/base";

export default () => {
  const dispatch = useDispatch();

  const [notifSetting] = useNotificationSetting();
  React.useEffect(() => {
    if (!notifSetting) {
      return;
    }
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: notifSetting,
      })
    );
  }, [notifSetting]);

  const breadCrumbs = [
    {
      label: "Inställningar",
      url: "/account/user",
    },
    {
      label: "Notifikationer",
      url: "/account/notifications",
    },
    { label: "Inställningar" },
  ];

  return (
    <SharedStyles.BareLayoutWrapper>
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle dark title="Notisinställningar" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Notisinställningar</OverviewTitle>
        </OverviewTitleWrapper>

        <PlainForm untouchedInstance={notifSetting} />
      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
