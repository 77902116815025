import * as React from "react";
import { useParams } from "react-router-dom";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import CompaniesTable from "../../../components/Lists/Companies/FullTable";
import InvoicingLeaseTable from "../../../components/Lists/InvoicingCustomer/FullTable";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

export const TABS = {
  COMPANY: "Bolag",
  LEASE: "Förvaltningsavtal",
};

function InvoicingSettingUsedBy() {
  const { settingId } = useParams();
  const [selectedTab, setSelectedTab] = React.useState("COMPANY");

  const persistantQueryString = buildQueryString({
    setting: settingId,
  });

  const persistantFilterMethod = (obj) => obj.setting?.id == settingId;

  const companyPersistantQueryString = buildQueryString({
    invoicing_config: settingId,
  });

  const companyPersistantFilterMethod = (obj) =>
    obj.invoicing_config?.id == settingId;

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Används av</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        {selectedTab === "COMPANY" && (
          <CompaniesTable
            hideTitle
            hideColumns
            {...{
              persistantQueryString: companyPersistantQueryString,
              persistantFilterMethod: companyPersistantFilterMethod,
            }}
          />
        )}

        {selectedTab === "LEASE" && (
          <InvoicingLeaseTable
            hideTitle
            hideColumns
            {...{ persistantQueryString, persistantFilterMethod }}
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default InvoicingSettingUsedBy;
