export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INVOICING_SETTINGS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INVOICING_SETTINGS",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INVOICING_SETTINGS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INVOICING_SETTINGS",
  DESTROY_FORM: "DESTROY_FORM_INVOICING_SETTINGS",
  SET_FORM_ERROR: "SET_FORM_ERROR_INVOICING_SETTINGS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INVOICING_SETTINGS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INVOICING_SETTINGS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INVOICING_SETTINGS",
  RESET_STATE: "RESET_STATE_INVOICING_SETTINGS",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INVOICING_SETTINGS",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_INVOICING_SETTINGS",
  CLEAR_FETCHED: "CLEAR_FETCHED_INVOICING_SETTINGS",

  LIST_URL: "/accounting/configs/invoicingsetting/list/",
  POST_URL: "/accounting/configs/invoicingsetting/",
  PATCH_URL: "/accounting/configs/invoicingsetting/",
  GET_URL: "/accounting/configs/invoicingsetting/",

  ASK_UPDATE_SHARED_SETTING_URL_CUSTOMER_INVOICING:
    "/accounting/contract/customer_invoice/setting_changes/",

  STORE_NAME: "invoicingSettings",
};
