import * as React from "react";

import { Confirm, Delivery, Info, Payment, Accounting } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "INFO":
      return <Info {...{ method }} />;
    case "DELIVERY":
      return <Delivery {...{ method }} />;
    case "PAYMENT":
      return <Payment {...{ method }} />;
    case "ACCOUNTING":
      return <Accounting {...{ method }} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
