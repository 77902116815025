import * as React from "react";
import {
  constants,
  markNotificationsAsRead,
  performFilter,
  useBillectaEventsPagination,
} from "../../../store/billectaEvents";
import listDefs from "./notificationsListDefs";

import BaseTable from "../../Lists/Base/CompleteList/Table";
import { buildRangeFilter } from "../../Lists/Base/utils";
import { useHistory, useParams } from "react-router-dom";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import { creditorDetailUrl } from "../../../store/billectaInvoicing/utils";
import { useDispatch } from "react-redux";
import { markNotificationAsRead } from "../../../store/billectaEvents";
import { PrimaryButton } from "../../Forms/Base/Buttons";

export default function Notifications({
  persistantQueryString,
  persistantFilterMethod,
  title,
  hideExport,
  hideFilters,
  hideSearch,
  hideColumns,
  invoices = [],
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { push } = useHistory();

  const [selected, setSelected] = React.useState([]);

  const { creditorId } = useParams();

  const filterInstructions = {
    added_time: { handler: (filterObject) => buildRangeFilter(filterObject) },
    related_revenue: {
      handler: (filterObject) => buildRangeFilter(filterObject),
    },
  };

  const handleSelectedUpdated = (sel) => {
    setSelected(sel);
  };

  const columns = React.useMemo(
    () => listDefs(invoices),
    [persistantFilterMethod, persistantQueryString]
  );
  const fetchAllTreshold = 25;

  const handleRowClicked = (obj) => {
    const notifId = obj.id;

    dispatch(markNotificationAsRead({ id: notifId }));

    let creditor = obj.related_creditor_id || creditorId;
    if (obj.related_id) {
      push(
        invoiceDetailUrl({ invoiceId: obj.related_id, creditorId: creditor })
      );
    } else if (creditor) {
      push(creditorDetailUrl({ creditorId: creditor }));
    }
  };

  const markSelectedAsRead = () => {
    dispatch(
      markNotificationsAsRead({ ids: selected.map((s) => s.original.id) })
    );
  };

  return (
    <>
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        intitialSortBy={[{ id: "added_time", desc: true }]}
        paginationMethod={useBillectaEventsPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        onRowClicked={({ original }) => {
          handleRowClicked(original);
        }}
        withSelectableRows
        handleSelectedUpdated={handleSelectedUpdated}
        title={title}
        hideExport={hideExport}
        hideFilters={hideFilters}
        hideSearch={hideSearch}
        hideColumns={hideColumns}
      />

      {selected?.length > 0 && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 42 }}
        >
          <PrimaryButton
            newDesign
            extraStyle={{ minWidth: "300px" }}
            clicked={markSelectedAsRead}
            title={`Markera ${selected.length} som lästa`}
          />
        </div>
      )}
    </>
  );
}
