import * as React from "react";
import { Route } from "react-router-dom";

// style, design
import SettingsSidebar from "./Accounts/SettingsSidebar";
import TopArea from "./TopArea/TopArea";

export default ({ component: RouteComponent, path, exact }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => (
        <div style={{ display: "flex" }}>
          <SettingsSidebar />
          <div
            style={{
              maxWidth: "calc(100vw - 250px)",
              width: "calc(100vw - 250px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TopArea />
            <RouteComponent {...matchProps} />
          </div>
        </div>
      )}
    />
  );
};
