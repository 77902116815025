import * as React from "react";
import { constants } from "../../../../../store/managementContracts";
import {
  constructStrRep,
  useCustomerPagination,
  performFilter,
  constants as customerConstants,
} from "../../../../../store/customers";

import {
  constructStrRep as constructCompanyStrRep,
  useCompanyPagination,
  performFilter as performCompanyFilter,
  constants as companyConstants,
} from "../../../../../store/companies";
import companyListDefs from "../../../../Lists/Companies/listDefs";

import listDefs from "../../../../Lists/Customers/selectListDefs";
// style, design

import { TableSelect } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <TableSelect
        storeName={storeName}
        method={method}
        fieldKey="opponent"
        constructStrRep={constructStrRep}
        tableStoreName={customerConstants.STORE_NAME}
        listDefs={listDefs}
        paginationMethod={useCustomerPagination}
        performFilter={performFilter}
        fieldTitle={"Uppdragsgivare"}
        title="Välj förvaltningsuppdrag..."
      />

      <TableSelect
        storeName={storeName}
        method={method}
        fieldKey="company"
        constructStrRep={constructCompanyStrRep}
        tableStoreName={companyConstants.STORE_NAME}
        listDefs={companyListDefs}
        paginationMethod={useCompanyPagination}
        extraColumnsDefsArgs={[]}
        performFilter={performCompanyFilter}
        fieldTitle={"Förvaltande bolag"}
        title="Välj förvaltande bolag..."
      />
    </>
  );
};
