export default {
  START_LOGIN: "START_LOGIN",
  LOGIN_SUCCESSFUL: "LOGIN_SUCCESSFUL",
  LOGIN_FAILED: "LOGIN_FAILED",
  START_LOCAL_LOGIN: "START_LOCAL_LOGIN",
  LOCAL_LOGIN_SUCCESSFUL: "LOCAL_LOGIN_SUCCESSFUL",
  LOCAL_LOGIN_FAILED: "LOCAL_LOGIN_FAILED",
  GET_USER: "GET_USER",
  LOG_OUT: "LOG_OUT",
  SET_BARE_MODE: "SET_BARE_MODE",
  SET_SCREEN_SIZE: "SET_SCREEN_SIZE",
  SET_LOADING_PERMS: "SET_LOADING_PERMS",
  SET_PERMS: "SET_PERMS",
  MFA_SETTING: "MFA_SETTING",
  MFA_PENDING: "MFA_PENDING",
};
