import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "../../../../store/billectaInvoicing";
import { buildQueryString } from "../../../../store/base";

import * as SC from "../styles";
import {
  Select,
  DatePicker,
  PositiveNumber,
  TextInput,
  TextField,
  Checkbox,
} from "../../../Forms/Base/Fields";
import { InvoiceChapter } from "../InvoiceComponents";

import checkValidations from "../InvoiceComponents/checkValidations";
import InvoiceRecords from "../InvoiceComponents/InvoiceRecords";
import {
  getAutogiroSettings,
  getEInvoiceSettings,
  getKivraSettings,
} from "../../../../store/billectaIntegrations";
import checkRequired from "../InvoiceComponents/checkRequired";
import RequiredMissing from "../InvoiceComponents/RequiredMissing/RequiredMissing";
import { useParams } from "react-router";
import { useFilteredDebtorInvoicings } from "../../../../store/invoicingDebtor";
import { useCustomer } from "../../../../store/customers";

export default function CreditInvoiceForm({
  allDisabled,
  onValidationError,
  onRequiredMissing,
}) {
  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = React.useState({});
  const [requiredMissing, setRequiredMissing] = React.useState([]);

  const invoiceData = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );
  const { creditorId } = useParams();

  const kivraSettings = useSelector(
    (state) => state.billectaIntegrations.kivraSettings
  );
  const eInvoiceSettings = useSelector(
    (state) => state.billectaIntegrations.eInvoiceSettings
  );

  const autogiroSettings = useSelector(
    (state) => state.billectaIntegrations.autogiroSettings
  );
  if (!autogiroSettings) dispatch(getAutogiroSettings(creditorId));

  const debtorQuery = buildQueryString({
    billecta_object_ids: invoiceData?.Debtor?.DebtorPublicId || [],
  });

  const [debtors] = useFilteredDebtorInvoicings(debtorQuery);
  const selectedDebtorConfig = debtors?.[0];

  const [customer] = useCustomer(selectedDebtorConfig?.management_customer?.id);

  const customerHasAutogiro = selectedDebtorConfig?.use_autogiro;
  const creditorHasAutogiro = !!autogiroSettings;

  React.useEffect(() => {
    if (!kivraSettings) {
      dispatch(getKivraSettings(creditorId));
    }
    if (!eInvoiceSettings) {
      dispatch(getEInvoiceSettings(creditorId));
    }
  }, []);

  const setValidationErrorsCallback = React.useCallback(
    (errors) => {
      setValidationErrors(errors);
      onValidationError(errors);
    },
    [validationErrors]
  );

  const setRequiredCallback = React.useCallback(
    (required) => {
      setRequiredMissing(required);
      onRequiredMissing(required);
    },
    [requiredMissing]
  );

  React.useEffect(() => {
    // check validation
    if (invoiceData) {
      checkValidations({
        formInstance: invoiceData,
        debtorInstance: selectedDebtorConfig,
        customer,
        kivraEnabled: kivraSettings?.IsEnabled,
        eInvoiceEnabled: !!eInvoiceSettings?.FUI,
        isCreditInvoice: true,
        setErrors: setValidationErrorsCallback,
        type: "INVOICE",
      });

      checkRequired({
        formInstance: invoiceData,
        type: "INVOICE",
        setRequired: setRequiredCallback,
      });
    }
  }, [invoiceData]);

  const invoicingChapter = () => (
    <InvoiceChapter
      title="Faktura"
      forceOpen
      hideToggle
      description="Inställningar för fakturan"
    >
      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Vår referens:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <TextInput
            disabled={allDisabled}
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`OurReference`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>
          Krediteringsdatum (samma som faktura som standard):
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <DatePicker
            disabled={allDisabled}
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`InvoiceDate`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors["DueDate"] ||
            !!validationErrors["DueDate_before_today"],
        }}
      >
        <SC.InvoicedBoxRowTitle>Förfallodatum:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <DatePicker
            disabled={allDisabled}
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`DueDate`}
          />
          {!!validationErrors["DueDate"] && (
            <SC.InvalidRowWarning>
              Förfallodatumet är innan fakturadatumet. Fakturan kommer ej kunna
              attesteras.
            </SC.InvalidRowWarning>
          )}

          {!!validationErrors["DueDate_before_today"] && (
            <SC.InvalidRowWarning>
              Förfallodatumet är innan dagens datum. Fakturan kommer ej kunna
              attesteras.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Använd autogiro:</SC.InvoicedBoxRowTitle>
        <SC.InvoicedBoxRowValue>
          <Checkbox
            disabled={!customerHasAutogiro || !creditorHasAutogiro}
            noMargin
            title={
              !creditorHasAutogiro
                ? "Autogiro ej aktiverat för bolaget"
                : !customerHasAutogiro
                ? "Autogiro ej aktiverat för kunden"
                : "Använd autogiro"
            }
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`Autogiro.AutogiroWithdrawalEnabled`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors["DeliveryMethod_Email"] ||
            !!validationErrors["DeliveryMethod_Mail"] ||
            !!validationErrors["DeliveryMethod_Kivra_Disabled"] ||
            !!validationErrors["DeliveryMethod_SMS"] ||
            !!validationErrors["DeliveryMethod_EInvoice_Disabled"],
        }}
      >
        <SC.InvoicedBoxRowTitle>Leveransmetod:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <Select
            disabled={allDisabled}
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`DeliveryMethod`}
          />
          {!!validationErrors["DeliveryMethod_Email"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar e-postadress för leverans. Lägg till
              en e-postadress på kunden eller välj en annan leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Mail"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar fysisk adress för leverans. Lägg
              till en address på kunden eller välj en annan leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Kivra_Disabled"] && (
            <SC.InvalidRowWarning>
              Kivra-integrationen är inte konfigurerad för detta bolag. Aktivera
              integrationen för att kunna nyttja Kivra som leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_SMS"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar telefonnummer för leverans. Lägg
              till ett telefonnummer på kunden eller välj en annan
              leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_EInvoice_Disabled"] && (
            <SC.InvalidRowWarning>
              E-fakturering har inte aktiverats. Kontakta Pigello för att
              aktivera detta.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      {invoiceData.DeliveryMethod === "Email" && (
        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>
            Skicka postalt om ej e-post är öppnad efter antal dagar:
          </SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            <PositiveNumber
              disabled={allDisabled}
              rowSize
              noMargin
              noTitle
              storeName={constants.STORE_NAME}
              method={"PUT"}
              fieldKey={`SendByMailIfEmailNotViewedInDays`}
            />
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>
      )}

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Meddelande på fakturan:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <TextField
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`Message`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>
    </InvoiceChapter>
  );

  return (
    <>
      {invoicingChapter()}

      <InvoiceRecords
        disabled={allDisabled}
        constants={constants}
        method="PUT"
        instance={invoiceData}
        matchRecords={false}
        creditorId={creditorId}
        isCreditInvoice
      />

      {!!validationErrors["Credit_invoice_records_positivevalue"] && (
        <div style={{ color: "red", fontWeight: "600" }}>
          Värden på kreditfakturor måste vara negativa
        </div>
      )}

      {requiredMissing.length > 0 && (
        <RequiredMissing
          requiredMissing={requiredMissing}
          title="Dessa uppgifter saknas för att kunna skapa eller förhandsgranska fakturan:"
        />
      )}
    </>
  );
}
