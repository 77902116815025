import * as React from "react";
import * as SC from "../styles";
import { Checkbox, RadioGroup, Select } from "../../../Forms/Base/Fields";
import PartySelect from "./PartySelect";
import RadioPositionValue from "./RadioPositionValue";
import FontSizeInput from "./FontSizeInput";

import { CloseButton } from "../../TopBar/styles";
import {
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../../store/base";
import { TextButton } from "../../../Forms/Base/Buttons";
import { unPackArrayErrors } from "../../../Forms/Base/utils";
import { FIELD_TYPES, getFieldType } from "../../utils";
import { get, isEqual } from "lodash";
import { useDispatch } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import ConfirmationModal from "../../../Modals/ConfirmationModal";

const LOCKED_KINDS = [
  "first_name",
  "last_name",
  "company",
  "company_number",
  "personal_number",
  "email",
  "phone",
  "corporate_name",
  "full_name",
];

export default React.memo(function FieldDetailCard({
  storeName,
  partyKey,
  fieldKey,
  fieldInstructionKey,
  method,
  position,
  positionKey,
  closeFunction,
  pageAmount,
  onPageSelected,
}) {
  const dispatch = useDispatch();
  const [pageChoices, setPageChoices] = React.useState(null);
  const [showRemoveConfirmation, setShowRemoveConfirmation] =
    React.useState(false);

  const party = useFormInstanceField({
    storeName,
    fieldKey: partyKey,
  });

  const field = useFormInstanceField({
    storeName,
    fieldKey,
  });

  const kind = field?.kind;

  const fieldType = getFieldType(kind);

  const fieldError = useFormError({ storeName, fieldKey: fieldKey });
  const hasErr =
    fieldError &&
    (Array.isArray(fieldError)
      ? fieldError.length
      : fieldError.constructor === Object
      ? Object.keys(fieldError).length
      : true);

  React.useEffect(() => {
    let pages = [];
    for (let i = 0; i < pageAmount; i++) {
      pages.push({ d: i + 1, v: i + 1 });
    }
    setPageChoices(pages);
  }, [pageAmount]);

  const handlePageUpdated = (data) => {
    const page = data?.v;

    if (onPageSelected) {
      onPageSelected(page);
    }
  };

  const removeField = () => {
    const fields = party?.fields || [];

    if (!fields.length) {
      return;
    }

    if (!field) {
      return;
    }

    const newFields = fields.filter((f) => !isEqual(f, field));

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`${partyKey}.fields`]: newFields },
      })
    );
  };

  const removePosition = () => {
    const positions = get(field, `placements`, []);
    if (!positions.length) {
      return;
    }

    const newPositions = positions.filter((p) => !isEqual(p, position));

    // delete the position
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`${fieldKey}.placements`]: newPositions },
      })
    );
  };

  const handleRemoveField = () => {
    removeField();
    closeFunction();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={showRemoveConfirmation}
        title="Bekräfta borttagande av fält"
        renderContent={() => (
          <div>
            Detta innebär att fältet tas bort från parten och alla placeringar
            försvinner.
          </div>
        )}
        closeFunction={() => setShowRemoveConfirmation(false)}
        acceptCallback={handleRemoveField}
        acceptTitle="Ta bort fält"
      />

      <SC.CardWrapper className={"no-move"}>
        <SC.Card>
          {hasErr && Array.isArray(fieldError) ? (
            <span>{unPackArrayErrors(fieldError)}</span>
          ) : null}

          <OverviewTitleWrapper style={{ marginBottom: 0 }}>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Hantera fält {field?.name}</OverviewTitle>
              <OverviewSubtitle>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextButton
                    iconType="close"
                    iconPlacement="right"
                    extraStyle={{ marginRight: 12 }}
                    clicked={() => {
                      removePosition();
                      closeFunction();
                    }}
                    title="Ta bort denna placering"
                  />

                  {!LOCKED_KINDS.includes(field.kind) && (
                    <TextButton
                      iconType="close"
                      iconPlacement="right"
                      red
                      clicked={() => {
                        setShowRemoveConfirmation(true);
                      }}
                      title="Ta bort fält från dokument"
                    />
                  )}
                </div>
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            <CloseButton onClick={closeFunction} />
          </OverviewTitleWrapper>
          <hr style={{ margin: "12px 0" }} />
          <SC.AttributeBox>
            {/** The party of the field */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <div style={{ minWidth: 270 }}>
                <PartySelect
                  storeName={storeName}
                  currentPartyKey={partyKey}
                  activeFieldKey={fieldKey}
                  updatedCallback={closeFunction}
                />
              </div>

              {fieldType === FIELD_TYPES.TEXT ? (
                <div style={{ minWidth: 270 }}>
                  <FontSizeInput
                    noMargin
                    storeName={storeName}
                    fieldKey={`${positionKey}.fsrel`}
                    method={method}
                    instructionsKey={`${fieldInstructionKey}.placements.fsrel`}
                  />
                </div>
              ) : null}

              <div style={{ minWidth: 270 }}>
                <Select
                  title="Sida"
                  noMargin
                  menuPlacement="top"
                  forceChoices={pageChoices}
                  storeName={storeName}
                  fieldKey={`${positionKey}.page`}
                  method={method}
                  changeCallback={handlePageUpdated}
                  instructionsKey={`${fieldInstructionKey}.placements.page`}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",

                marginBottom: "12px",
              }}
            >
              {fieldType === FIELD_TYPES.RADIO ? (
                <RadioPositionValue
                  storeName={storeName}
                  fieldKey={`${positionKey}.value`}
                  instructionsKey={`${fieldInstructionKey}.placements.value`}
                  method={method}
                  parentValueKey={`${fieldKey}.value`}
                />
              ) : null}

              {/** Details about the field */}
              {/** is_obligatory option */}

              <Checkbox
                title="Fältet kräver ifyllning"
                storeName={storeName}
                noMargin
                extraStyles={{
                  marginLeft: fieldType === FIELD_TYPES.RADIO ? 12 : 0,
                }}
                fieldKey={`${fieldKey}.is_obligatory`}
                method={method}
                instructionsKey={`${fieldInstructionKey}.is_obligatory`}
              />

              {field.is_obligatory && (
                <RadioGroup
                  title={"Ange vem som ska fylla i fältet"}
                  options={[
                    {
                      label: "Ska fyllas i av avsändare",
                      value: true,
                    },
                    {
                      label: "Ska fyllas i av mottagare",
                      value: false,
                    },
                  ]}
                  defaultValue={true}
                  extraStyles={{ marginLeft: 12 }}
                  noMargin
                  storeName={storeName}
                  fieldKey={`${fieldKey}.should_be_filled_by_sender`}
                  method={method}
                  instructionsKey={`${fieldInstructionKey}.should_be_filled_by_sender`}
                />
              )}
            </div>
          </SC.AttributeBox>
        </SC.Card>
      </SC.CardWrapper>
    </>
  );
});
