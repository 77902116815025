import { isEqual } from "lodash";

import { updateActiveFormInstance } from "../../store/base";

export const pdfDimensions = () => {
  //react-pdf__Page__canvas
  const pdf = document.getElementsByClassName("react-pdf__Page__canvas")[0];
  if (!pdf) {
    return { w: 0, h: 0 };
  }

  return {
    w: pdf.width ? pdf.width / window.devicePixelRatio : 0,
    h: pdf.height ? pdf.height / window.devicePixelRatio : 0,
  };
};

export const toInternalCoordinates = ({ x, y }) => {
  const { w, h } = pdfDimensions();
  return { x: x * w, y: y * h };
};

export const toExternalCoordinates = (event) => {
  const transform = event.target.closest(".react-draggable")?.style?.transform;
  if (!transform) {
    return { x: 0, y: 0 };
  }

  const split = transform.split(",");
  const x = parseInt(split[0].split("translate(")[1].split("px")[0]);
  const y = parseInt(split[1].split("px")[0]);

  const { w, h } = pdfDimensions();

  return { x: x / w, y: y / h };
};

export const toExternalSize = (size) => {
  const { w, h } = pdfDimensions();

  return {
    w: w === 0 ? 0 : (size.width || 0) / w,
    h: h === 0 ? 0 : (size.height || 0) / h,
  };
};

export const toInternalSize = ({ w, h }) => {
  const dims = pdfDimensions();

  return { w: w * dims.w, h: h * dims.h };
};

export const toInternalFontSize = ({ f }) => {
  const { w } = pdfDimensions();
  const result = Math.round(f * w);

  // if (result < 0 || result > 20) {
  //   return { f: 18 };
  // }
  return { f: result };
};

export const toExternalFontSize = ({ f }) => {
  const { w } = pdfDimensions();

  return { f: w ? f / w : 0 };
};

export const getInitialPlacement = (
  pageNumber,
  fontSize,
  extraTopOffset = 0,
  isCheckbox
) => {
  const size = toExternalSize(
    isCheckbox ? { width: 20, height: 20 } : { width: 64, height: 36 }
  );
  const fsrel = toExternalFontSize({ f: fontSize || 16 });

  const scrollTop = window.scrollY + extraTopOffset;
  const { h } = pdfDimensions();

  let yRel = scrollTop / h;

  if (yRel > 1) {
    yRel = 0.9;
  } else if (yRel < 0.05) {
    yRel += 0.07;
  }

  return [
    {
      xrel: 0.4,
      yrel: yRel,
      wrel: size.w,
      hrel: size.h,
      fsrel: fsrel.f,
      page: pageNumber || 1,
    },
  ];
};

export const canCollapseFieldOnName = ({ relevantFields, name }) => {
  return relevantFields.findIndex((c) => c.name === name) >= 0;
};

export const collapseFieldOnName = ({
  dispatch,
  storeName,
  allFieldsKey,
  relevantFields,
  currentField,
  name,
}) => {
  const existingIndex = relevantFields.findIndex((c) => c.name === name);

  if (existingIndex >= 0) {
    // alright, we found one, now take our pos and ad it to existing pos
    let newFields = [];
    relevantFields.forEach((c, index) => {
      if (isEqual(c, currentField)) {
        return;
      }

      if (index === existingIndex) {
        newFields.push({
          ...c,
          placements: [
            ...(c.placements || []),
            ...(currentField.placements || []),
          ],
        });
      } else {
        newFields.push(c);
      }
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [allFieldsKey]: newFields },
      })
    );

    return true;
  }

  return false;
};

export const FIELD_TYPES = {
  CHECKBOX: "checkbox",
  TEXT: "text",
  RADIO: "radio",
  INTERVAL: "interval",
  SIGNATURE: "signature",
};
export const CATEGORY_MAP = {
  tenant: "Hyresgäst",
  bi_tenant: "Övrig hyresgäst",
  landlord: "Hyresvärd",
  other: "Övrig",
};

export const SIGN_ROLE_MAP = {
  approver: "Godkänner dokumentet",
  signing_party: "Signerar dokumentet",
  viewer: "Visar dokumentet",
};

export const getFieldType = (kind) => {
  if (kind === "signature") return FIELD_TYPES.SIGNATURE;

  if (["notify_interval", "renew_interval"].includes(kind)) {
    return FIELD_TYPES.INTERVAL;
  }
  if (["checkbox", "subletted", "has_furniture"].includes(kind)) {
    return FIELD_TYPES.CHECKBOX;
  }
  if (["radiogroup"].includes(kind)) {
    return FIELD_TYPES.RADIO;
  }

  return FIELD_TYPES.TEXT;
};

export const SIGN_TYPES = {
  DIGITAL: "digital-sign",
  MANUAL: "manual-sign",
};

export const FIELD_KIND_CHOICES = [
  // {
  //   d: "Signatur",
  //   v: "signature",
  // },
  {
    d: "Text",
    v: "text",
  },
  {
    d: "Radiogrupp",
    v: "radiogroup",
  },
  {
    d: "Checkbox",
    v: "checkbox",
  },
  // {
  //   d: "Förnamn",
  //   v: "fist_name",
  // },
  // {
  //   d: "Efternamn",
  //   v: "last_name",
  // },
  // {
  //   d: "Mobil",
  //   v: "mobile",
  // },
  // {
  //   d: "Bolagsnamn",
  //   v: "company",
  // },
  // {
  //   d: "E-post",
  //   v: "email",
  // },
  // {
  //   d: "Bolag Orgnr.",
  //   v: "company_number",
  // },
  // {
  //   d: "Personnummer",
  //   v: "personal_number",
  // },
];
export const lightOrDark = (color) => {
  if (!color) return "light";
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
};

// calculates total contract value for a leasecontract
// breaks down every cost to the monthly cost, and then uses debit interval to calculate the debited amount
export const getTotalValueLeaseContract = ({ instance, area }) => {
  let value = 0;
  if (!instance) return value;

  if (instance.rent) value += instance.rent;

  if (instance.electricity_cost) {
    if (instance.electricity_is_m2) {
      const electricityTotal = (area || 0) * instance.electricity_cost;
      value += electricityTotal;
    } else {
      value += instance.electricity_cost;
    }
  }

  if (instance.heat_cost) {
    if (instance.heat_is_m2) {
      const heatTotal = (area || 0) * instance.heat_cost;
      value += heatTotal;
    } else {
      value += instance.heat_cost;
    }
  }

  if (instance.water_cost) {
    if (instance.water_is_m2) {
      const waterTotal = (area || 0) * instance.water_cost;
      value += waterTotal;
    } else {
      value += instance.water_cost;
    }
  }

  if (instance.cooling_cost) {
    if (instance.cooling_is_m2) {
      const coolingTotal = (area || 0) * instance.cooling_cost;
      value += coolingTotal;
    } else {
      value += instance.cooling_cost;
    }
  }

  if (instance.cost_is_yearly) {
    value = value / 12;
  }

  if (instance.other_costs) {
    let otherCostsValue = 0;

    // handle costs
    instance.other_costs.forEach((oc) => {
      if (oc.start_date || oc.end_date || !oc.value) return;
      let val = oc.value;

      if (oc.value_is_m2) {
        val = val * (area || 0);
      }

      if (oc.cost_is_yearly) {
        val = val / 12;
      }

      otherCostsValue += val;
    });

    value += otherCostsValue;
  }

  value = (value * (instance.invoice_month_interval || 1)).toFixed(0);

  return value;
};
