import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import { performFilter, constants, detailUrl } from "../../../store/userGroups";
import { useUserGroupPagination } from "../../../store/userGroups";

export default ({
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  persistantQueryString,
  persistantFilterMethod,
  overrideRowClick,
  checkRowHighlighted,
}) => {
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;

  const columns = React.useMemo(() => columnDefs(), []);
  const fetchAllTreshold = 25;

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantFilterMethod={persistantFilterMethod}
      persistantQueryString={persistantQueryString}
      paginationMethod={useUserGroupPagination}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      onRowClicked={(row) => {
        if (overrideRowClick) {
          overrideRowClick(row);
          return;
        }
        push(`/account/groups-and-users/group/${row.original.id}`);
      }} // prevent default behaviour
      detailUrlMethod={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      title={hideTitle ? undefined : "Grupper"}
      exportSheetController={exportSheetController}
      {...{
        hideFilters,
        hideExport,
        hideSearch,
        hideColumns,
        checkRowHighlighted,
      }}
    />
  );
};
