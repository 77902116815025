import * as React from "react";

import { buildQueryString } from "../../store/base";

import FullTable from "../Lists/Users/FullTable";

export default ({ groupId }) => {
  const persistantQueryString = buildQueryString({
    usergroup__id: groupId,
  });

  const filterMethod = (userObject) => {
    const match = userObject.usergroup_set.find(
      (u) => u.id === parseInt(groupId)
    );
    return match;
  };

  return (
    <>
      <FullTable
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={filterMethod}
        hideTitle
        hideFilters
        hideColumns
        hideExport
      />
    </>
  );
};
