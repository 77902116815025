import * as React from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

// style, design
import * as SharedStyles from "../../../components/sharedStyles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";

// store, state

import { buildQueryString } from "../../../store/base";
import { useAccountsReceivableForDate } from "../../../store/billectaAccountsReceivable/hooks/retrieve";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import AccountsReceivableTable from "../../../components/Billecta/AccountsReceivable/AccountsReceivableTable";
import { useFilteredCustomers } from "../../../store/customers";
import { cloneDeep } from "lodash";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import NonConnectedCheckbox from "../../../components/Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useFilteredDebtorInvoicings } from "../../../store/invoicingDebtor";

const DATE_FORMAT = "YYYY-MM-DD";

export default function AccountsReceivable() {
  const { creditorId } = useParams();
  const { push } = useHistory();

  const [date, setDate] = React.useState(moment().format(DATE_FORMAT));
  const [
    excludeInvoicesWithPeriodOutsideDate,
    setExcludeInvoicesWithPeriodOutsideDate,
  ] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [invoices, invoicesLoading] = useAccountsReceivableForDate({
    date,
    creditorId,
    excludeInvoicesWithPeriodOutsideDate,
  });

  // get unique debtor ids for fetching customers
  const debtorPublicIds = [
    ...new Set(invoices ? invoices?.map((r) => r.Debtor.DebtorPublicId) : []),
  ];

  const debtorQuery = buildQueryString({
    billecta_object_ids: debtorPublicIds,
  });

  const [debtors] = useFilteredDebtorInvoicings(debtorQuery);
  const customerQuery = buildQueryString({
    id__in: debtors?.map((d) => d.customer?.id),
  });
  const [customers] = useFilteredCustomers(customerQuery);

  const mappedCustomers = customers?.map((t) => ({
    ...t,
    debtor_invoice_config: debtors?.find(
      (d) => d.management_customer.id === t.id
    ),
  }));

  const rowClicked = (row) => {
    const invoiceId = row.original?.ActionPublicId;
    if (!invoiceId) return;

    push(invoiceDetailUrl({ invoiceId, creditorId }));
  };

  const getCombinedData = () => {
    if (!invoices || !customers) return null;
    let combined = cloneDeep(invoices);
    combined = combined.map((r) => {
      const matchingCustomer = mappedCustomers.find((t) => {
        return !!t.debtor_invoice_config?.billecta_ids?.find(
          (object) => object.object_id === r.Debtor.DebtorPublicId
        );
      });

      r.customer = matchingCustomer;
      r.PaidAmount =
        r.InvoicedAmountIncludingTax.ValueForView -
        r.RemainingAmountIncludingTax.ValueForView;

      return r;
    });

    return combined;
  };

  const combinedData = getCombinedData();

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitle>Kundreskontra</OverviewTitle>
        </OverviewTitleWrapper>
        <div style={{ display: "flex" }}>
          <NonConnectedDatePicker
            value={date}
            label={"Datum"}
            id={"accountsreceivabledate"}
            onChange={(val) => setDate(val)}
            format={"YYYY-MM-DD"}
            clearable={false}
          />
        </div>
        <NonConnectedCheckbox
          id="setExcludeInvoicesWithPeriodOutsideDate"
          value={excludeInvoicesWithPeriodOutsideDate}
          onChange={(checked) => {
            setExcludeInvoicesWithPeriodOutsideDate(checked);
          }}
          title="Visa enbart fakturor där datumet infaller i periodisering"
          noMargin
        />

        {!invoicesLoading && invoices?.length > 0 && (
          <AccountsReceivableTable
            invoices={combinedData}
            handleRowClicked={rowClicked}
            date={date}
          />
        )}

        {!invoicesLoading && invoices?.length === 0 && (
          <SharedStyles.InnerBox
            style={{
              position: "relative",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Inga fakturor matchar sökningen
          </SharedStyles.InnerBox>
        )}

        {invoicesLoading && (
          <SharedStyles.InnerBox
            style={{ position: "relative", height: "300px" }}
          >
            <OverlaySpinner />
          </SharedStyles.InnerBox>
        )}
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
