import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  margin: 20px 0;
`;

export const Title = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  font-size: ${(p) => p.theme.fontSizes.headerLarge};
  color: ${(p) => p.theme.colors.gray1};
  letter-spacing: ${(p) => p.theme.kerning.headerLarge};
  display: flex;
  align-items: center;

  ${(p) =>
    p.dark &&
    css`
      color: ${(p) => p.theme.colors.gray4};
    `}
`;

export const Subtitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
`;

export const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const BreadCrumb = styled(Link)`
  margin-right: 16px;
  position: relative;
  color: ${(p) => p.theme.colors.gray3};
  background-color: ${(p) => p.theme.colors.gray1};
  border: ${(p) => p.theme.borders.standard};
  border-radius: 3px;
  padding: 1px 4px;
  font-size: 13px;

  &:hover {
    color: ${(p) => p.theme.colors.gray3};
  }

  ${(p) =>
    !p.to &&
    css`
      cursor: default !important;
    `}

  &:not(:last-of-type) {
    color: ${(p) => p.theme.colors.greenDark};
    border-color: ${(p) => p.theme.colors.greenDark};

    background-color: ${(p) => p.theme.colors.greenLight};
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${(p) => p.theme.colors.greenLight};

      background-color: ${(p) => p.theme.colors.greenDark};
    }

    &::after {
      height: ${(p) => p.theme.fontSizes.headerXSmall};
      width: ${(p) => p.theme.fontSizes.headerXSmall};
      color: ${(p) => p.theme.colors.gray2};
      content: ">";
      position: absolute;
      right: -18px;
      top: calc(50% - 10px);
    }
  }
`;

export const BetaBox = styled.div`
  position: absolute;
  top: -10%;
  left: 85%;
  background-color: #fff;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border-radius: 8px;
  padding: 12px;
  width: 300px;
  z-index: 999;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;
