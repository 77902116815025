import * as React from "react";
import * as SC from "./styles";

export default function ContractInvoiceTableFilters({
  selectedFilter,
  updateFilter,
  filterCounts,
}) {
  return (
    <SC.FilterWrapper>
      <SC.ActiveFilterButton
        selected={selectedFilter === "Active"}
        onClick={() => updateFilter("Active")}
      >
        Aktiva ({filterCounts?.Active || 0})
      </SC.ActiveFilterButton>

      <SC.PausedFilterButton
        selected={selectedFilter === "Paused"}
        onClick={() => updateFilter("Paused")}
      >
        Pausade ({filterCounts?.Paused || 0})
      </SC.PausedFilterButton>
    </SC.FilterWrapper>
  );
}
