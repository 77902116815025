import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ Component, ...rest }) => {
  const loggedOut = useSelector((state) => state.app.loggedOut);
  const authenticated = useSelector((state) => !!state.app.authorization);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedOut || !authenticated) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
