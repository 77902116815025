import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../../components/Displays";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import InvoicingSettingModalForm from "../../../components/Forms/InvoicingSetting/ChapterForm/ModalForm";
import StandardModal from "../../../components/Modals/StandardModal";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import InvoicingSettingSummary from "../../../components/Summaries/InvoicingSettingSummary";
import { useInvoicingSetting } from "../../../store/invoicingSettings";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import InvoicingLeaseTable from "../../../components/Lists/InvoicingCustomer/FullTable";

import { TABS as USED_BY_TABS } from "./UsedBy";
import { buildQueryString } from "../../../store/base";
function InvoicingSettingOverview() {
  const { settingId } = useParams();
  const [setting] = useInvoicingSetting(settingId);
  const [editOpen, setEditOpen] = React.useState(false);
  const [warnEditOpen, setWarnEditOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("LEASE");

  const persistantQueryString = buildQueryString({
    setting: settingId,
  });

  const persistantFilterMethod = (obj) => obj.setting?.id == settingId;

  return (
    <>
      <StandardModal
        isOpen={warnEditOpen}
        title="Information om redigering"
        withActionBar
        saveFunction={() => {
          setEditOpen(true);
          setWarnEditOpen(false);
        }}
        closeFunction={() => setWarnEditOpen(false)}
        actionBarAcceptIsWarning
        actionBarAcceptTitle="Redigera ändå"
      >
        <InfoBox
          title="OBS"
          text={
            "Ändringar av denna inställning kommer att påverka aviseringsinställningarna för avtal och objekt som använder sig av den. Kontrollera att detta är önskvärt."
          }
          boxTheme="warning"
        />

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Används av</OverviewTitle>
          </OverviewTitleWrapper>

          <PageSlider
            {...{
              TABS: USED_BY_TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />

          {selectedTab === "LEASE" && (
            <InvoicingLeaseTable
              hideTitle
              hideColumns
              {...{ persistantQueryString, persistantFilterMethod }}
            />
          )}
        </DetailPageBox>
      </StandardModal>

      <InvoicingSettingModalForm
        method="PATCH"
        id={settingId}
        instance={setting}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>

            <PrimaryButton
              title="Redigera"
              clicked={() => setWarnEditOpen(true)}
            />
          </OverviewTitleWrapper>

          <InvoicingSettingSummary
            {...{ setting, linkedDebtSetting: true, linkedIMDSetting: true }}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export default InvoicingSettingOverview;
