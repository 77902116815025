import * as React from "react";
import { useDispatch } from "react-redux";
import { cloneDeep, isEqual } from "lodash";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";

let colors = [
  "#E9C46A",
  "#9CE37D",
  "#355691",
  "#ED217C",
  "#2A9D8F",
  "#E76F51",
  "#355691",
  "#F4A261",
  "#264653",
  "#FFFD82",
  "#FF9B71",
  "#DBDFAC",
  "#ECBA82",
  "#297045",
  "#204E4A",
  "#BCABAE",
  "#716969",
  "#D9D0DE",
  "#BC8DA0",
  "#AB4967",
];

const useSetPartyColors = ({ fieldKey, storeName }) => {
  const dispatch = useDispatch();
  const partiesRef = React.useRef();

  const parties = useFormInstanceField({
    storeName,
    fieldKey,
  });

  React.useEffect(() => {
    if (isEqual(partiesRef?.current, parties)) return;

    if (!parties?.length) return;

    const partiesClone = cloneDeep(parties);

    // temp arr for multiple inits
    let taken = [];

    partiesClone.forEach((party, idx) => {
      if (Object.keys(party)?.length === 0) return;

      let availableColors = colors.filter(
        (c) => !partiesClone.find((p) => p._color === c)
      );

      let color = party._color;
      if (!color) {
        color = availableColors[taken.length];

        taken.push(color);

        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              [`${fieldKey}[${idx}]._color`]: color,
              [`${fieldKey}[${idx}]._internalId`]: idx,
            },
          })
        );
      }

      partiesRef.current = parties;
    });
  }, [parties]);
};

export default useSetPartyColors;
