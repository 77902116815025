import * as React from "react";
import { constants } from "../../../store/editabledocs";

import { PrimaryButton } from "../../Forms/Base/Buttons";
import { DoubleFieldWrapper } from "../../Forms/Base/Chapters/styles";
import { Checkbox, Select, TextInput } from "../../Forms/Base/Fields";
import * as SC from "./styles";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

export default function EditParty({
  parties,
  editParty,
  editIndex,
  setEditIndex,
  method,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const selectedUser = useFormInstanceField({
    storeName,
    fieldKey: `parties[${editIndex}].user`,
  });

  const editPartyFields = editParty?.fields;
  const user = editParty?.user;

  const firstNameIndex = editPartyFields?.findIndex(
    (f) => f.kind === "first_name"
  );
  const lastNameIndex = editPartyFields?.findIndex(
    (f) => f.kind === "last_name"
  );
  const corporateNameIndex = editPartyFields?.findIndex(
    (f) => f.kind === "company"
  );
  const corporateLegalIdIndex = editPartyFields?.findIndex(
    (f) => f.kind === "company_number"
  );
  const emailIndex = editPartyFields?.findIndex((f) => f.kind === "email");
  const phoneIndex = editPartyFields?.findIndex((f) => f.kind === "phone");
  const legalIdIndex = editPartyFields?.findIndex(
    (f) => f.kind === "personal_number"
  );

  const partyFields = editParty?.fields;
  const firstNamePartyField = partyFields?.[firstNameIndex];
  const lastNamePartyField = partyFields?.[lastNameIndex];
  const emailPartyField = partyFields?.[emailIndex];
  const phonePartyField = partyFields?.[phoneIndex];
  const companyPartyField = partyFields?.[corporateNameIndex];
  const legalIdField = partyFields?.[legalIdIndex];

  const editPartySignMethod = editParty?.authentication_method_to_sign;
  const editPartyDeliveryMethod = editParty?.delivery_method;

  const hasUserPartyDifferences =
    (selectedUser?.first_name || null) !=
      (firstNamePartyField?.value || null) ||
    (selectedUser?.legal_id || null) != (legalIdField?.value || null) ||
    (selectedUser?.last_name || null) != (lastNamePartyField?.value || null) ||
    (selectedUser?.phone || null) != (phonePartyField?.value || null) ||
    (selectedUser?.email || null) != (emailPartyField?.value || null) ||
    (selectedUser?.corporate_name || null) !=
      (companyPartyField?.value || null);

  const handleSyncParty = () => {
    const partiesClone = cloneDeep(parties);

    partiesClone[editIndex].fields[firstNameIndex].value =
      selectedUser.first_name;
    partiesClone[editIndex].fields[lastNameIndex].value =
      selectedUser.last_name;
    partiesClone[editIndex].fields[emailIndex].value = selectedUser.email;
    partiesClone[editIndex].fields[phoneIndex].value = selectedUser.phone;
    partiesClone[editIndex].fields[legalIdIndex].value = selectedUser.legal_id;

    if (corporateNameIndex > 0) {
      partiesClone[editIndex].fields[corporateNameIndex].value =
        selectedUser.corporate_name;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setEmailObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!partiesClone[editIndex].fields[emailIndex]) return;

    partiesClone[editIndex].fields[emailIndex].is_obligatory = true;
    partiesClone[editIndex].fields[
      emailIndex
    ].should_be_filled_by_sender = true;

    if (
      editPartySignMethod !== "sms_pin" &&
      !!partiesClone[editIndex]?.fields[phoneIndex]
    ) {
      partiesClone[editIndex].fields[phoneIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setPhoneObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!partiesClone[editIndex]?.fields[phoneIndex]) return;

    partiesClone[editIndex].fields[phoneIndex].is_obligatory = true;
    partiesClone[editIndex].fields[
      phoneIndex
    ].should_be_filled_by_sender = true;

    if (
      editPartyDeliveryMethod !== "email" &&
      !!partiesClone[editIndex]?.fields?.[emailIndex]
    ) {
      partiesClone[editIndex].fields[emailIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setEmailAndPhoneObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!!partiesClone[editIndex].fields[phoneIndex]) {
      partiesClone[editIndex].fields[phoneIndex].is_obligatory = true;
      partiesClone[editIndex].fields[
        phoneIndex
      ].should_be_filled_by_sender = true;
    }

    if (!!partiesClone[editIndex].fields[emailIndex]) {
      partiesClone[editIndex].fields[emailIndex].is_obligatory = true;
      partiesClone[editIndex].fields[
        emailIndex
      ].should_be_filled_by_sender = true;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const setPersonalNumberObligatory = () => {
    const partiesClone = cloneDeep(parties);

    if (!!partiesClone[editIndex].fields[legalIdIndex]) {
      partiesClone[editIndex].fields[legalIdIndex].is_obligatory = true;
      partiesClone[editIndex].fields[
        legalIdIndex
      ].should_be_filled_by_sender = true;
    }

    if (
      editPartyDeliveryMethod !== "mobile" &&
      !!partiesClone[editIndex].fields[phoneIndex]
    ) {
      partiesClone[editIndex].fields[phoneIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  const clearPossibleObligatories = () => {
    const partiesClone = cloneDeep(parties);

    if (
      editPartyDeliveryMethod !== "email" &&
      !!partiesClone[editIndex].fields[emailIndex]
    ) {
      partiesClone[editIndex].fields[emailIndex].is_obligatory = false;
    }

    if (
      editPartyDeliveryMethod !== "mobile" &&
      editPartySignMethod !== "sms_pin" &&
      !!partiesClone[editIndex].fields[phoneIndex]
    ) {
      partiesClone[editIndex].fields[phoneIndex].is_obligatory = false;
    }

    if (
      editPartySignMethod !== "se_bankid" &&
      !!partiesClone[editIndex].fields[legalIdIndex]
    ) {
      partiesClone[editIndex].fields[legalIdIndex].is_obligatory = false;
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { parties: partiesClone },
      })
    );
  };

  React.useEffect(() => {
    if (editPartyDeliveryMethod === "email") {
      if (
        !emailPartyField?.should_be_filled_by_sender ||
        !emailPartyField?.is_obligatory
      ) {
        setEmailObligatory();
      }
    } else if (editPartyDeliveryMethod === "mobile") {
      if (
        !phonePartyField?.should_be_filled_by_sender ||
        !phonePartyField?.is_obligatory
      ) {
        setPhoneObligatory();
      }
    } else if (editPartyDeliveryMethod === "email_mobile") {
      if (
        !phonePartyField?.should_be_filled_by_sender ||
        !phonePartyField?.is_obligatory ||
        !emailPartyField?.should_be_filled_by_sender ||
        !emailPartyField?.is_obligatory
      ) {
        setEmailAndPhoneObligatory();
      }
    } else {
      clearPossibleObligatories();
    }
  }, [editPartyDeliveryMethod]);
  // om leveranssätta är e-post - > kräv e-post av avsändar och visa rött annars
  // om leveranssätt är e-post/SMS _> kräv e-post och sms av avsändare och visa rött
  // om vleef aekf ls ätr sms -> räv telefon och visa rött

  React.useEffect(() => {
    if (editPartySignMethod === "standard") {
      clearPossibleObligatories();
    } else if (editPartySignMethod === "sms_pin") {
      setPhoneObligatory();
    } else if (editPartySignMethod === "se_bankid") {
      setPersonalNumberObligatory();
    }
  }, [editPartySignMethod]);
  // om signering är bankid -> sätt personnummer till krav
  // om signering är sms-pinkod -> sätt telefonnummer till krav

  return (
    <>
      <SC.TitleWrapper
        style={{ cursor: "pointer" }}
        onClick={() => setEditIndex(null)}
        style={{ marginBottom: 12 }}
      >
        <SC.BackButton />
        <SC.Title style={{ cursor: "pointer" }}>Gå tillbaka</SC.Title>
      </SC.TitleWrapper>

      <DoubleFieldWrapper style={{ width: "100%" }}>
        <Select
          rowSize
          noMargin
          extraStyles={{
            minWidth: "30%",
            marginRight: "5%",
            marginBottom: 12,
          }}
          forceChoices={[
            {
              v: "tenant",
              d: "Kund",
            },
            {
              v: "landlord",
              d: "Förvaltningsbolag",
            },
          ]}
          title="Typ av part"
          {...{
            method,
            storeName,
            fieldKey: `parties[${editIndex}].category`,
            instructionsKey: "parties.category",
          }}
        />
        <TextInput
          rowSize
          noMargin
          extraStyles={{
            minWidth: "30%",
            marginRight: "5%",
            marginBottom: 12,
          }}
          {...{
            storeName,
            method,
            fieldKey: `parties[${editIndex}].fields[${firstNameIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
          title="Förnamn"
        />
        <TextInput
          rowSize
          noMargin
          extraStyles={{ minWidth: "30%", marginBottom: 12 }}
          {...{
            storeName,
            method,
            fieldKey: `parties[${editIndex}].fields[${lastNameIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
          title="Efternamn"
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper style={{ width: "100%" }}>
        <TextInput
          rowSize
          noMargin
          overrideRequired={
            legalIdField?.should_be_filled_by_sender &&
            legalIdField?.is_obligatory
          }
          extraStyles={{
            minWidth: "30%",
            marginRight: "5%",
            marginBottom: 12,
          }}
          {...{
            storeName,
            method,
            fieldKey: `parties[${editIndex}].fields[${legalIdIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
          title={"Personnummer"}
        />
        <TextInput
          rowSize
          noMargin
          overrideRequired={
            emailPartyField?.should_be_filled_by_sender &&
            emailPartyField?.is_obligatory
          }
          extraStyles={{
            minWidth: "30%",
            marginRight: "5%",
            marginBottom: 12,
          }}
          {...{
            storeName,
            method,
            fieldKey: `parties[${editIndex}].fields[${emailIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
          title="E-post"
        />
        <TextInput
          rowSize
          noMargin
          overrideRequired={
            phonePartyField?.should_be_filled_by_sender &&
            phonePartyField?.is_obligatory
          }
          subtitleHelp="Anges +467XXXXXXXX"
          extraStyles={{ minWidth: "30%", marginBottom: 12 }}
          {...{
            storeName,
            method,
            fieldKey: `parties[${editIndex}].fields[${phoneIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
          title="Telefon"
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          rowSize
          noMargin
          extraStyles={{
            minWidth: "30%",
            marginBottom: 12,
            marginRight: "5%",
          }}
          {...{
            storeName,
            method,
            fieldKey: `parties[${editIndex}].fields[${corporateNameIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
          title="Bolag"
        />

        <TextInput
          rowSize
          noMargin
          extraStyles={{
            minWidth: "30%",
            marginBottom: 12,
          }}
          {...{
            storeName,
            method,
            fieldKey: `parties[${editIndex}].fields[${corporateLegalIdIndex}].value`,
            instructionsKey: "parties.fields.value",
          }}
          title="Orgnr"
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper style={{ width: "100%" }}>
        <Select
          rowSize
          noMargin
          menuPlacement="top"
          extraStyles={{
            minWidth: "30%",
            marginRight: "5%",
            marginBottom: 12,
          }}
          filterChoices={(c) => {
            if (["approver"].includes(c.v)) {
              return false;
            }
            return true;
          }}
          title="Roll"
          {...{
            method,
            storeName,
            fieldKey: `parties[${editIndex}].signatory_role`,
            instructionsKey: "parties.signatory_role",
          }}
        />
        <Select
          rowSize
          noMargin
          menuPlacement="top"
          extraStyles={{
            minWidth: "30%",
            marginRight: "5%",
            marginBottom: 12,
          }}
          filterChoices={(c) => {
            if (["pad"].includes(c.v)) {
              return false;
            }
            return true;
          }}
          title="Leveranssätt"
          {...{
            method,
            storeName,
            fieldKey: `parties[${editIndex}].delivery_method`,
            instructionsKey: "parties.delivery_method",
          }}
        />
        <Select
          rowSize
          noMargin
          filterChoices={(c) => {
            if (["standard", "sms_pin", "se_bankid"].includes(c.v)) {
              return true;
            }
            return false;
          }}
          menuPlacement="top"
          extraStyles={{
            minWidth: "30%",
            marginRight: "5%",
            marginBottom: 12,
          }}
          title="Signeringsmetod"
          {...{
            method,
            storeName,
            fieldKey: `parties[${editIndex}].authentication_method_to_sign`,
            instructionsKey: "parties.authentication_method_to_sign",
          }}
        />
      </DoubleFieldWrapper>

      <Checkbox
        rowSize
        noMargin
        extraStyles={{ marginBottom: 12 }}
        {...{
          storeName,
          method,
          fieldKey: `parties[${editIndex}].hide_personal_number`,
          instructionsKey: "parties.hide_personal_number",
        }}
        title="Dölj personnummer för andra parter i Scrive"
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PrimaryButton title="Klar" clicked={() => setEditIndex(null)} />
      </div>
    </>
  );
}
