import * as React from "react";
import { usePermissionCheck } from "../../../../store/base";
import { DetailPageBox } from "../../../sharedStyles";
import { OverviewTitle, OverviewTitleWrapper } from "../../OverviewInfo/styles";
import {
  constants,
  update,
  useManagementContractForm,
} from "../../../../store/managementContracts";
import ManualSignForm from "../../../Forms/ManagementContract/ManualSignForm";

export default function ManagementContractManualSign({ contract }) {
  const canEdit = usePermissionCheck("change_can_contract");

  const formLoaded = Boolean(useManagementContractForm("PATCH", contract?.id));

  return (
    <DetailPageBox>
      <OverviewTitleWrapper>
        <OverviewTitle>Manuell signering</OverviewTitle>
      </OverviewTitleWrapper>

      <ManualSignForm
        id={contract?.id}
        canEdit={canEdit}
        {...{ constants, updateFunction: update, formLoaded }}
      />
    </DetailPageBox>
  );
}
