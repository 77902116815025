import styled, { css } from "styled-components";
import searchIcon from "../../../assets/svg/material/search_black.svg";
import dropDownIcon from "../../../assets/svg/material/arrow_drop_down_circle_black.svg";
import closeIcon from "../../../assets/svg/material/close-circle-white.svg";
import arrowIcon from "../../../assets/svg/material/arrow-right-white.svg";
import arrowIconBlack from "../../../assets/svg/material/arrow-right-black.svg";
import supportIconBlack from "../../../assets/svg/material/support_black.svg";
import managementBadgeGreen from "../../../assets/svg/material/management_key_green.svg";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => (p.formPadding ? "0 24px 0 0" : "12px 24px")};
  color: ${(p) => p.theme.colors.primaryText};
  font-family: "Inter", sans-serif;
  border-bottom: 2px solid ${(p) => p.theme.colors.graphite};
  background-color: ${(p) => p.theme.colors.gray5};
  min-height: 73px;

  ${(p) =>
    p.formPadding &&
    css`
      max-height: 73px;
    `}
`;

export const ManagementPortalLabel = styled.div`
  position: relative;
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  color: ${(p) => p.theme.colors.green};
  padding-right: 24px;

  &:after {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    right: -4px;
    top: calc(50% - 12px);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${managementBadgeGreen});
  }
`;

export const SearchBarContainer = styled.div`
  background-color: #fff;
  border-radius: 30px;
  flex: 2;
  margin-right: 80px;
  max-width: 700px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  position: relative;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${searchIcon});
`;

export const HistoryIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${dropDownIcon});
`;

export const SupportLink = styled.div`
  position: relative;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px 0 12px;
  margin: 0 4px;
  border-radius: 50px;
  cursor: pointer;
  background-color: #fff;
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};

  &:after {
    position: absolute;
    content: "";
    right: 6px;
    height: 22px;
    width: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    background-image: url(${supportIconBlack});
  }
`;

export const IconButton = styled.div`
  position: relative;
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: #fff;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  background-image: url(${(p) => p.icon});
  margin: 0 4px;

  ${(p) =>
    p.notReadCount > 0 &&
    css`
      &:after {
        position: absolute;
        content: "";
        top: -3px;
        right: -3px;
        height: 10px;
        width: 10px;
        box-shadow: 0px 0px 12px 3px ${(p) => p.theme.colors.blue};
        border-radius: 50%;
        background-color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}
`;

export const ProfileBadge = styled.div`
  cursor: pointer;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background-color: #fff;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(p) =>
    p.userImage
      ? p.userImage
      : `https://eu.ui-avatars.com/api/?name=${
          p.name || ""
        }&background=253237&color=ffffff`});
  margin-left: 8px;
`;

export const SearchBar = styled.input.attrs({
  type: "text",
  placeholder: "Sök i systemet",
})`
  padding: 12px 12px 12px 42px;
  border: none;
  width: 93%;
  border-radius: 30px;
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const TopBarItemsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SpinningCircleContainer = styled.svg`
  position: absolute;
  right: 40px;
  top: calc(50% - 12px);
  height: 24px;
  width: 24px;
  animation: rotate 2s linear infinite;
`;

export const SpinningCircle = styled.circle`
  animation: dash-searchbar 1s ease-in-out infinite;
  stroke-dasharray: 90, 150; // ie11 fallback
  stroke-dashoffset: -35; // ie11 fallback
  stroke-linecap: round;
  stroke: ${(p) => p.theme.colors.primaryBlue};
`;

export const HistoryContainer = styled.div`
  color: #fff;
  background-color: ${(p) => p.theme.colors.graphite};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  padding: 24px;
  top: 130%;
  left: 0;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 20px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  z-index: 999;
`;

export const HistoryArrow = styled.div`
  height: 32px;
  width: 32px;
  background-position: center;
  background-image: url(${arrowIcon});
  background-size: contain;
  background-repeat: no-repeat;
`;
export const HistoryItem = styled.div`
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
    color: ${(p) => p.theme.colors.graphite};

    & > ${HistoryArrow} {
      background-image: url(${arrowIconBlack});
    }
  }
`;

export const HistoryTitle = styled.div`
  display: flex;
  flex-direction: column;

  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const ResultsContainer = styled.div`
  color: #fff;
  background-color: ${(p) => p.theme.colors.graphite};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 85vw;
  padding: 24px;
  max-width: 1100px;
  min-height: 500px;
  top: 130%;
  left: ${(p) => (p.sideBarOpen ? "-200px" : "-60px")};
  border: ${(p) => p.theme.borders.standard};
  border-radius: 20px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  z-index: 999;
`;

export const ResultsTitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  margin-bottom: 24px;
`;

export const ResultsTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultsTitle = styled.div`
  line-height: 100%;
`;
export const ResultsSubTitle = styled.div`
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  color: #f1f1f1;
`;

export const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Result = styled.div`
  background-color: ${(p) => p.theme.colors.gray4};
  border-radius: 5px;
  padding: 6px 12px;
  min-width: 200px;
  margin: 12px;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
`;

export const ResultTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  margin-bottom: 12px;
`;

export const ResultLink = styled.div`
  cursor: pointer;
  color: ${(p) => p.theme.colors.blue};

  &:hover {
    color: ${(p) => p.theme.colors.blueLight};
  }
`;

export const HighlightWrapper = styled.div`
  font-size: 10px;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};

  & > div > em {
    background-color: ${(p) => p.theme.colors.yellow};
    color: ${(p) => p.theme.colors.graphite};
    font-weight: bold;
    padding: 1px;
    border-radius: 2px;
  }
`;

export const ClearButton = styled.div`
  cursor: pointer;
  height: 32px;
  width: 32px;
  background-position: center;
  background-image: url(${closeIcon});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ResultsOverlay = styled.div`
  height: calc(100vh - 73px);
  width: 100vw;
  position: fixed;
  top: 73px;
  left: 0;
  z-index: 998;
  background-color: rgba(256, 256, 256, 0.4);
`;
