import { cloneDeep, set } from "lodash";
import moment from "moment";
import baseReducer from "../../billecta/reducer";
import constants from "./constants";

const INITIAL_STATE = {
  connectedPaymentFromDate: moment()
    .subtract({ months: 1 })
    .format("YYYY-MM-DD"),
  connectedPaymentToDate: moment().format("YYYY-MM-DD"),
  all: {},
  allCopy: {},
  inProgress: [],
  paginations: {},
  paginationsCopy: {},
  filtered: {},
  filteredCopy: {},
  backgroundTasks: {},
  hasFetchedAll: false,

  forms: {},
  formInstance: {},
  formErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.RESET_STATE: {
      return {
        ...newState,
        invoices: undefined,
        payments: undefined,
      };
    }

    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_CONTRACT_INVOICES: {
      return { ...newState, contractInvoices: payload.invoices || [] };
    }

    case constants.SET_FILTERED_CONTRACT_INVOICES: {
      return handleSetFilteredContractInvoices(state, payload);
    }

    case constants.SET_ALL_INVOICES: {
      return { ...newState, invoices: payload.invoices };
    }

    case constants.SET_PAYMENTS: {
      return { ...newState, payments: payload.payments };
    }

    case constants.SET_CURRENT_INVOICE: {
      return {
        ...newState,
        currentInvoice: payload.invoice,
        currentInvoiceType: payload.invoiceType,
      };
    }

    case constants.SET_CURRENT_REMINDER_INVOICE: {
      return {
        ...newState,
        currentReminderInvoice: payload.reminderInvoice,
      };
    }

    case constants.SET_CURRENT_INVOICE_PREVIEW_PDF: {
      return {
        ...newState,
        currentInvoicePreview: {
          id: payload.id,
          pdf: payload.pdf,
        },
      };
    }

    case constants.SET_CURRENT_CONTRACT_INVOICE: {
      return {
        ...newState,
        currentContractInvoice: payload.invoice,
        currentContractInvoiceType: payload.invoiceType,
      };
    }

    case constants.SET_CONNECTED_CONTRACT_INVOICES: {
      return {
        ...newState,
        [payload.key]: payload.invoices,
      };
    }

    case constants.CLEAR_CONNECTED_INVOICES: {
      return {
        ...newState,
        [payload.key]: undefined,
      };
    }

    case constants.SET_CONNECTED_TENANT_INVOICES: {
      return {
        ...newState,
        [payload.debtorId]: {
          invoices: payload.invoices,
          contractInvoices: payload.contractInvoices,
        },
      };
    }

    case constants.SET_ATTEST_INVOICES_LOADING: {
      return {
        ...newState,
        attestInvoicesLoading: payload.loading,
      };
    }

    case constants.SET_DELETE_INVOICES_LOADING: {
      return {
        ...newState,
        deleteInvoicesLoading: payload.loading,
      };
    }

    case constants.REMOVE_MANY_INVOICE: {
      return {
        ...newState,
        formInstance: {
          ...newState.formInstance,
          invoices: payload.updatedInvoices,
        },
      };
    }

    case constants.REMOVE_MANY_CONTRACT_INVOICE: {
      return {
        ...newState,
        formInstance: {
          ...newState.formInstance,
          contractInvoices: payload.updatedInvoices,
        },
      };
    }

    case constants.SET_CLOSED_FROM_DATE: {
      return {
        ...newState,
        invoicesClosedFromDate: payload.date,
      };
    }

    case constants.SET_INVOICES_GENERATED_BY_CONTRACT_INVOICE: {
      return {
        ...newState,
        [payload.name]: payload.invoices,
      };
    }

    case constants.SET_DEBTOR_INVOICE_SEARCH_RESULT: {
      return {
        ...newState,
        [payload.identifier]: payload.invoices,
      };
    }

    case constants.SET_CONNECTED_PAYMENT_DATE_FROM: {
      return {
        ...newState,
        connectedPaymentFromDate: payload.date,
      };
    }

    default:
      return newState;
  }
};

const handleSetFilteredContractInvoices = (state, payload) => {
  const { contractInvoices, errors } = payload;

  const newState = cloneDeep(state);

  contractInvoices.forEach((c) => {
    if (newState.filteredContractInvoices) {
      newState.filteredContractInvoices[c.ActionPublicId] = c;
    } else {
      newState.filteredContractInvoices = {
        [c.ActionPublicId]: c,
      };
    }
  });

  if (errors?.length) {
    errors.forEach((errorId) => {
      if (newState.filteredContractInvoicesErrors) {
        newState.filteredContractInvoicesErrors[errorId] = errorId;
      } else {
        newState.filteredContractInvoicesErrors = {
          [errorId]: errorId,
        };
      }
    });
  }

  return newState;
};
