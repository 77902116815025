import * as React from "react";
import moment from "moment";
import * as SharedStyles from "../../../components/sharedStyles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { buildQueryString } from "../../../store/base";
import { useFilteredCompanies } from "../../../store/companies";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import ReportOptions from "../../../components/Billecta/Reports/ReportOptions";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  getBillingReport,
  getFutureInvoicesReport,
  getPaymentsReport,
  getPeriodicProductSalesReport,
  getProductSalesReport,
  getVATReport,
} from "../../../store/billectaReports/store/actions";
import BillingReport from "../../../components/Billecta/Reports/BillingReport";
import ProductSalesReport from "../../../components/Billecta/Reports/ProductSalesReport";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import PeriodicProductSalesReport from "../../../components/Billecta/Reports/PeriodicProductSalesReport";
import PaymentsReport from "../../../components/Billecta/Reports/PaymentsReport";
import VATReport from "../../../components/Billecta/Reports/VATReport";
import GroupedProductSalesReport from "../../../components/Billecta/Reports/GroupedProductSalesReport";
import GroupedAccountSalesReport from "../../../components/Billecta/Reports/GroupedAccountSalesReport";
import GroupedCostCenterSalesReport from "../../../components/Billecta/Reports/GroupedCostCenterSalesReport";
import GroupedProjectSalesReport from "../../../components/Billecta/Reports/GroupedProjectSalesReport";
import FutureInvoices from "../../../components/Billecta/Reports/FutureInvoices";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import GeneralInvoiceReport from "../../../components/Billecta/Reports/GeneralInvoiceReport";
import { axiosInstance } from "../../../store/base/store/axios";
import { convertPigelloProjectsToBillecta } from "../../../store/billectaInvoicing/store/services";

const DATE_FORMAT = "YYYY-MM-DD";

const OPTIONS = {
  INVOICING_REPORT: "Faktureringsrapport",
  FUTURE_INVOICES: "Framtida fakturor",
  PRODUCT_SALES: "Försäljning per produkt",
  GROUPED_PRODUCT_SALES: "Grupperad försäljning per produkt",
  PERIODIC_PRODUCT_SALES: "Periodiserad försäljning per produkt",
  ACCOUNT_SALES: "Försäljning per bokföringskonto",
  COST_CENTER_SALES: "Försäljning per kostnadsställe",
  PROJECT_SALES: "Försäljning per projekt",
  PAYMENTS: "Inbetalningsrapport",
  VAT: "Momsrapport",

  GENERAL_REPORT: "Aviseringsrapport",
  DETAILED_GENERAL_REPORT: "Detaljerad aviseringsrapport (Excel)",
  GENERAL_INVOICE_REPORT: "Aviseringsjournal",
};

export default () => {
  const dispatch = useDispatch();
  const { creditorId } = useParams();
  const { push } = useHistory();
  const [selectedOption, setSelectedOption] =
    React.useState("INVOICING_REPORT");

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const [companies, isLoadingCompanies] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const [selectedProjects, setSelectedProjects] = React.useState([]);

  const [billingReportFromDate, setBillingReportFromDate] = React.useState(
    moment().subtract(30, "days").format(DATE_FORMAT)
  );
  const [billingReportToDate, setBillingReportToDate] = React.useState(
    moment().format(DATE_FORMAT)
  );

  const [futurebillingReportFromDate, setFutureBillingReportFromDate] =
    React.useState(moment().format(DATE_FORMAT));
  const [futurebillingReportToDate, setFutureBillingReportToDate] =
    React.useState(moment().add(3, "months").format(DATE_FORMAT));

  const [productSalesReportFromDate, setProductSalesReportFromDate] =
    React.useState(moment().subtract(30, "days").format(DATE_FORMAT));
  const [productSalesReportToDate, setProductSalesReportToDate] =
    React.useState(moment().format(DATE_FORMAT));

  const [
    periodicProductSalesReportFromDate,
    setPeriodicProductSalesReportFromDate,
  ] = React.useState(moment().subtract(30, "days").format(DATE_FORMAT));
  const [
    periodicProductSalesReportToDate,
    setPeriodicProductSalesReportToDate,
  ] = React.useState(moment().format(DATE_FORMAT));
  const [paymentsReportFromDate, setPaymentsReportFromDate] = React.useState(
    moment().subtract(30, "days").format(DATE_FORMAT)
  );
  const [paymentsReportToDate, setPaymentsReportToDate] = React.useState(
    moment().format(DATE_FORMAT)
  );
  const [VATReportFromDate, setVATReportFromDate] = React.useState(
    moment().subtract(30, "days").format(DATE_FORMAT)
  );
  const [VATReportToDate, setVATReportToDate] = React.useState(
    moment().format(DATE_FORMAT)
  );

  const [generalEmailsValue, setGeneralEmailsValue] = React.useState(undefined);
  const [generalFromDate, setGeneralFromDate] = React.useState(undefined);
  const [generalToDate, setGeneralToDate] = React.useState(undefined);

  const [loadingReport, setLoadingReport] = React.useState(false);

  const handleGetReport = () => {
    switch (selectedOption) {
      case "INVOICING_REPORT": {
        handleGetBillingReport();
        break;
      }

      case "FUTURE_INVOICES": {
        handleGetFutureInvoices();
      }

      case "PRODUCT_SALES": {
        handleGetProductSalesReport();
        break;
      }

      case "GROUPED_PRODUCT_SALES": {
        handleGetProductSalesReport();
        break;
      }

      case "ACCOUNT_SALES": {
        handleGetProductSalesReport();
        break;
      }

      case "COST_CENTER_SALES": {
        handleGetProductSalesReport();
        break;
      }

      case "PROJECT_SALES": {
        handleGetProductSalesReport();
        break;
      }

      case "PERIODIC_PRODUCT_SALES": {
        handleGetPeriodicProductSalesReport();
        break;
      }

      case "PAYMENTS": {
        handleGetPaymentsReport();
        break;
      }

      case "VAT": {
        handleGetVATReport();
        break;
      }

      case "GENERAL_REPORT": {
        handleGetGeneralReport(0);
        break;
      }

      case "DETAILED_GENERAL_REPORT": {
        handleGetGeneralReport(2);
        break;
      }

      case "GENERAL_INVOICE_REPORT": {
        handleGetGeneralReport(1);
        break;
      }

      default:
        return null;
    }
  };

  const handleGetGeneralReport = async (type) => {
    setLoadingReport(true);

    // validate the data first
    let emailErr = false;
    let startErr = false;
    let endErr = false;

    let data = {};

    if (!generalEmailsValue || !typeof generalEmailsValue === "string") {
      emailErr = true;
    } else {
      const splitted = generalEmailsValue.split(",");
      let emails = [];
      splitted.forEach((v) => {
        const res = v.replaceAll(" ", "");
        if (!res.length) {
          return;
        }
        emails.push(res);
      });
      if (!emails.length) {
        emailErr = true;
      } else {
        data["emails"] = emails;
      }
    }

    if (emailErr) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Fyll i giltliga email-adresser",
          description: "Separera email-adresserna med kommatecken",
        })
      );
      setLoadingReport(false);
      return;
    }

    let dtStart;
    if (!generalFromDate) {
      startErr = true;
    } else {
      dtStart = moment(generalFromDate);
      if (!dtStart._isValid) {
        startErr = true;
      } else {
        data["start_date"] = dtStart.format("YYYY-MM-DD");
      }
    }

    if (startErr) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Fyll ett giltligt startdatum",
        })
      );
      setLoadingReport(false);
      return;
    }

    let dtEnd;
    if (!generalToDate) {
      endErr = true;
    } else {
      dtEnd = moment(generalToDate);
      if (!dtEnd._isValid) {
        endErr = true;
      } else {
        data["end_date"] = dtEnd.format("YYYY-MM-DD");
      }
    }

    if (endErr) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Fyll ett giltligt slutdatum",
        })
      );
      setLoadingReport(false);
      return;
    }

    const diff = dtEnd.diff(dtStart, "seconds");
    if (diff < 0) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Slutdatumet måste vara senare än startdatumet",
        })
      );
      setLoadingReport(false);
      return;
    } else if (diff > 60 * 60 * 24 * 365) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title:
            "Intervallet mellan start och slut måste vara mindre än eller lika med 365 dagar",
        })
      );
      setLoadingReport(false);
      return;
    }

    try {
      const url =
        type === 1
          ? `pdfgenerator/accounting/bill_summary/invoice_summary/${company.id}/`
          : type === 2
          ? `pdfgenerator/accounting/bill_summary/bill_summary_excel/${company.id}/`
          : `pdfgenerator/accounting/bill_summary/bill_summary/${company.id}/`;
      await axiosInstance.post(url, data);
      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Rapporten genereras och skickas till ifyllda email-adresser",
          description: data["emails"].join(", "),
          toastTimeOut: 10000,
        })
      );
      setGeneralEmailsValue(undefined);
    } catch (err) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Lyckades inte generera rapporten",
        })
      );
    }
    setLoadingReport(false);
  };

  const handleGetPaymentsReport = () => {
    setLoadingReport(true);

    dispatch(
      getPaymentsReport({
        creditorId,
        fromDate: paymentsReportFromDate,
        toDate: paymentsReportToDate,
        successCallback: () => {
          setLoadingReport(false);
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta rapport",
              description: "Kontrollera datan och försök igen",
            })
          );

          setLoadingReport(false);
        },
      })
    );
  };

  const handleGetVATReport = () => {
    setLoadingReport(true);

    const diff = -moment(VATReportFromDate).diff(moment(VATReportToDate));

    const prevPeriodFromDate = moment(VATReportFromDate)
      .subtract(diff)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    const prevPeriodToDate = moment(prevPeriodFromDate)
      .add(diff)
      .add(1, "days")
      .format("YYYY-MM-DD");

    dispatch(
      getVATReport({
        creditorId,
        fromDate: VATReportFromDate,
        toDate: VATReportToDate,
        prevPeriodFromDate,
        prevPeriodToDate,
        successCallback: () => {
          setLoadingReport(false);
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta rapport",
              description: "Kontrollera datan och försök igen",
            })
          );

          setLoadingReport(false);
        },
      })
    );
  };

  const handleGetBillingReport = async () => {
    let mappedProjects;

    if (selectedProjects?.length) {
      mappedProjects = await convertPigelloProjectsToBillecta({
        projects: selectedProjects,
        companyId: company?.id,
      });
    }

    if (selectedProjects?.length && !mappedProjects?.length) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej filtrera på projekt",
          description: "Rapporten genereras utan denna filtrering",
        })
      );
    }

    setLoadingReport(true);
    dispatch(
      getBillingReport({
        creditorId,
        fromDate: billingReportFromDate,
        toDate: billingReportToDate,
        selectedProjects: mappedProjects || [],
        successCallback: () => {
          setLoadingReport(false);
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta rapport",
              description: "Kontrollera datan och försök igen",
            })
          );

          setLoadingReport(false);
        },
      })
    );
  };

  const handleGetFutureInvoices = () => {
    setLoadingReport(true);

    dispatch(
      getFutureInvoicesReport({
        creditorId,
        fromDate: futurebillingReportFromDate,
        toDate: futurebillingReportToDate,
        successCallback: () => {
          setLoadingReport(false);
        },
        errorCallback: () => {
          setLoadingReport(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta rapport",
              description: "Kontrollera datan och försök igen",
            })
          );
        },
      })
    );
  };

  const handleGetProductSalesReport = () => {
    setLoadingReport(true);
    dispatch(
      getProductSalesReport({
        creditorId,
        fromDate: productSalesReportFromDate,
        toDate: productSalesReportToDate,
        successCallback: () => {
          setLoadingReport(false);
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta rapport",
              description: "Kontrollera datan och försök igen",
            })
          );

          setLoadingReport(false);
        },
      })
    );
  };

  const handleGetPeriodicProductSalesReport = () => {
    setLoadingReport(true);
    dispatch(
      getPeriodicProductSalesReport({
        creditorId,
        fromDate: periodicProductSalesReportFromDate,
        toDate: periodicProductSalesReportToDate,
        successCallback: () => {
          setLoadingReport(false);
        },
        errorCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej hämta rapport",
              description: "Kontrollera datan och försök igen",
            })
          );

          setLoadingReport(false);
        },
      })
    );
  };

  const goToInvoice = (row) => {
    const invoiceId = row?.original?.ActionPublicId;

    push(invoiceDetailUrl({ creditorId, invoiceId }));
  };

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        {loadingReport && <OverlaySpinner />}
        <ReportOptions
          OPTIONS={OPTIONS}
          selectedOption={selectedOption}
          onSelect={(key) => setSelectedOption(key)}
        />
        <OverviewTitleWrapper style={{ marginTop: "24px" }}>
          <OverviewTitle>{OPTIONS[selectedOption]}</OverviewTitle>

          <PrimaryButton
            title="Generera rapport"
            clicked={handleGetReport}
            newDesign
          />
        </OverviewTitleWrapper>

        {selectedOption === "GENERAL_REPORT" && (
          <GeneralInvoiceReport
            emails={generalEmailsValue}
            fromDate={generalFromDate}
            toDate={generalToDate}
            setEmails={setGeneralEmailsValue}
            setFromDate={setGeneralFromDate}
            setToDate={setGeneralToDate}
          />
        )}
        {selectedOption === "DETAILED_GENERAL_REPORT" && (
          <GeneralInvoiceReport
            emails={generalEmailsValue}
            fromDate={generalFromDate}
            toDate={generalToDate}
            setEmails={setGeneralEmailsValue}
            setFromDate={setGeneralFromDate}
            setToDate={setGeneralToDate}
          />
        )}
        {selectedOption === "GENERAL_INVOICE_REPORT" && (
          <GeneralInvoiceReport
            emails={generalEmailsValue}
            fromDate={generalFromDate}
            toDate={generalToDate}
            setEmails={setGeneralEmailsValue}
            setFromDate={setGeneralFromDate}
            setToDate={setGeneralToDate}
            isJournal
          />
        )}

        {selectedOption === "INVOICING_REPORT" && (
          <BillingReport
            fromDate={billingReportFromDate}
            toDate={billingReportToDate}
            setToDate={setBillingReportToDate}
            setFromDate={setBillingReportFromDate}
            goToInvoice={goToInvoice}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
          />
        )}

        {selectedOption === "PRODUCT_SALES" && (
          <ProductSalesReport
            fromDate={productSalesReportFromDate}
            toDate={productSalesReportToDate}
            setToDate={setProductSalesReportToDate}
            setFromDate={setProductSalesReportFromDate}
          />
        )}

        {selectedOption === "FUTURE_INVOICES" && (
          <FutureInvoices
            company={company}
            fromDate={futurebillingReportFromDate}
            toDate={futurebillingReportToDate}
            setFromDate={setFutureBillingReportFromDate}
            setToDate={setFutureBillingReportToDate}
          />
        )}

        {selectedOption === "GROUPED_PRODUCT_SALES" && (
          <GroupedProductSalesReport
            fromDate={productSalesReportFromDate}
            toDate={productSalesReportToDate}
            setToDate={setProductSalesReportToDate}
            setFromDate={setProductSalesReportFromDate}
          />
        )}

        {selectedOption === "ACCOUNT_SALES" && (
          <GroupedAccountSalesReport
            fromDate={productSalesReportFromDate}
            toDate={productSalesReportToDate}
            setToDate={setProductSalesReportToDate}
            setFromDate={setProductSalesReportFromDate}
          />
        )}

        {selectedOption === "COST_CENTER_SALES" && (
          <GroupedCostCenterSalesReport
            fromDate={productSalesReportFromDate}
            toDate={productSalesReportToDate}
            setToDate={setProductSalesReportToDate}
            setFromDate={setProductSalesReportFromDate}
          />
        )}

        {selectedOption === "PROJECT_SALES" && (
          <GroupedProjectSalesReport
            fromDate={productSalesReportFromDate}
            toDate={productSalesReportToDate}
            setToDate={setProductSalesReportToDate}
            setFromDate={setProductSalesReportFromDate}
          />
        )}

        {selectedOption === "PERIODIC_PRODUCT_SALES" && (
          <PeriodicProductSalesReport
            fromDate={periodicProductSalesReportFromDate}
            toDate={periodicProductSalesReportToDate}
            setToDate={setPeriodicProductSalesReportToDate}
            setFromDate={setPeriodicProductSalesReportFromDate}
          />
        )}

        {selectedOption === "PAYMENTS" && (
          <PaymentsReport
            fromDate={paymentsReportFromDate}
            toDate={paymentsReportToDate}
            setFromDate={setPaymentsReportFromDate}
            setToDate={setPaymentsReportToDate}
            goToInvoice={goToInvoice}
          />
        )}

        {selectedOption === "VAT" && (
          <VATReport
            fromDate={VATReportFromDate}
            toDate={VATReportToDate}
            setFromDate={setVATReportFromDate}
            setToDate={setVATReportToDate}
            goToInvoice={goToInvoice}
          />
        )}
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
};
