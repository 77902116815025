import React from "react";
import * as SC from "../styles";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { constants } from "../../../../../store/userGroups";

import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { getModalInformation } from "./infoItems";
import Toggle from "../../../../Toggle/Toggle";

const INTEGRATION_TITLES = {
  integration_accounting: "Bokföringsintegration",
  integration_billecta: "Billectaintegration",
  integration_scrive: "E-signering via Scrive",
  integration_fastdoc: "Fastighetsägarna dokument",
  integration_insights: "Pigello Insights",
};

const GDPR_TITLES = {
  handle_gdpr: "Hantera GDPR",
};

export default function SingleToggleRow({
  type,
  perm,
  showPermsInfo,
  group,
  disabled,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const pickedPerms = group?.permissions;

  const handlePermToggle = (p) => {
    let pickedPermsClone = cloneDeep(pickedPerms);

    if (pickedPermsClone.includes(p)) {
      pickedPermsClone = pickedPermsClone.filter((i) => i !== p);
    } else {
      pickedPermsClone.push(p);
    }
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          permissions: pickedPermsClone,
        },
      })
    );
  };

  const toggleRowType = () => {
    switch (type) {
      case "integration":
        return INTEGRATION_TITLES;
      case "gdpr":
        return GDPR_TITLES;
      default:
        break;
    }
  };

  return (
    <>
      <SC.SingleToggleRow>
        <SC.HeaderTitle
          icon
          style={{ flex: 1 }}
          onClick={() => showPermsInfo(getModalInformation(perm))}
        >
          {toggleRowType()[perm]}
        </SC.HeaderTitle>
        <Toggle
          disabled={disabled}
          isRedGreen
          extraStyle={{ margin: "4px 0" }}
          value={pickedPerms?.includes(perm)}
          onToggle={() => {
            handlePermToggle(perm);
          }}
          title=""
          extraStyle={{ flex: 1, justifyContent: "flex-end" }}
        />
        <SC.TableFiller />
      </SC.SingleToggleRow>
    </>
  );
}
