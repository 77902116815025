import styled, { css, keyframes } from "styled-components";

export const LayoutWrapper = styled.div`
  position: relative;
  margin: 12px 24px;
  box-shadow: ${(p) => p.theme.boxShadows.discrete};
  border: 1px solid ${(p) => p.theme.colors.gray};
  background-color: white;
  border-radius: 8px;
  width: calc(100% - 48px);
  min-height: ${(p) => p.height};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${(p) =>
    p.withPadding &&
    css`
      padding: 24px;
    `}
`;

export const BareLayoutWrapper = styled.div`
  position: relative;
  flex: 1;
  padding: 0 24px 12px 24px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0);
  width: ${(p) => (p.fillWidth ? "100%" : " calc(100% - 48px)")};
  min-height: ${(p) => p.height};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const DetailLayoutWrapper = styled.div`
  position: relative;
  flex: 1;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  width: ${(p) => (p.fillWidth ? "100%" : " calc(100% - 48px)")};
  min-height: ${(p) => p.height};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const DetailInnerWrapper = styled.div`
  position: relative;
  flex: 1;
  padding: 12px 24px 12px 24px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  min-height: ${(p) => p.height || "200px"};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const BareLayoutTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LayoutTitle = styled.h1`
  color: #1a1c21;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 300;
  letter-spacing: -0.04em;
`;

export const StartTableWrapper = styled.div`
  padding: 6px;
`;

export const DetailPageBox = styled.div`
  position: relative;
  background-color: white;
  padding: 24px;
  border: ${(p) =>
    p.error ? p.theme.borders.standardRed : p.theme.borders.standard};
  border-radius: 30px;
  box-shadow: ${(p) =>
    p.error ? p.theme.boxShadows.standardRed : p.theme.boxShadows.default};
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  ${(p) =>
    p.flexStart &&
    css`
      align-self: flex-start;
    `}

  ${(p) =>
    p.centerAll &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export const BodyText = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.secondaryText};

  ${(p) =>
    p.largerFont &&
    css`
      font-size: ${(p) => p.theme.fontSizes.data};
      color: ${(p) => p.theme.colors.primaryText};
    `}

  ${(p) =>
    p.row &&
    css`
      display: flex;
      align-items: center;
      margin-top: 6px;
    `}
`;

export const pulseYellow = keyframes`

    0% {
      box-shadow: 0 0 0 0 rgba(255,186,51, 0.6);
    }

    70% {
      box-shadow: 0 0 0 5px rgba(255,186,51, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255,186,51, 0);
    }
  
`;

export const pulseRed = keyframes`

    0% {
      box-shadow: 0 0 0 0 rgb(234, 80, 80,.6);
    }

    70% {
      box-shadow: 0 0 0 5px rgba(255,186,51, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255,186,51, 0);
    }
  
`;

export const pulseBlue = keyframes`

    0% {
      box-shadow: 0 0 0 0 rgba(73,157,255, 0.6);
    }

    70% {
      box-shadow: 0 0 0 8px rgba(73,157,255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(73,157,255, 0);
    }
  
`;

export const pulseGreen = keyframes`

    0% {
      box-shadow: 0 0 0 0 #2ec177;
    }

    70% {
      box-shadow: 0 0 0 8px rgba(73,157,255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(73,157,255, 0);
    }
  
`;

export const InnerBox = styled.div`
  background-color: white;
  width: 100%;
  padding: 12px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-family: "Inter", sans-serif;
  position: relative;
`;

export const DetailPageBoxFlexWrapper = styled.div`
  display: flex;
  max-width: 100%;

  & > ${DetailPageBox} {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const CardTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  font-size: ${(p) => p.theme.fontSizes.headerMedium};
  color: ${(p) => p.theme.colors.primaryText};
  display: flex;
  align-items: center;
  line-height: 100%;

  ${(p) =>
    p.small &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerSmall};
      font-size: ${(p) => p.theme.fontSizes.headerSmall};
    `}
`;

export const CardSubtitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.secondaryText};
  margin-top: 4px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PillsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Pill = styled.div`
  cursor: pointer;
  padding: 8px 12px;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 24px;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => (p.active ? "white" : p.theme.colors.primaryText)};
  background-color: ${(p) =>
    p.active ? p.theme.colors.primaryBlue : p.theme.colors.gray1};

  transition: all 0.2s ease-in-out;

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;
