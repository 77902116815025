import * as React from "react";
import constants from "../store/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getHandleContractInvoice,
  getHandleInvoice,
  getInvoiceIdPreview,
} from "..";
import { buildQueryString, useInProgress } from "../../base";
import {
  getContractInvoiceIdPreview,
  getSingularInvoicesByDebtorConfig,
  getInvoicesGeneratedByContractInvoice,
  getReminderInvoice,
  getAllContractInvoices,
} from "../store/actions";
import { useFilteredSingleInvoiceMappings } from "../../invoicingSingleMapping";

export const useInvoice = ({ invoiceId, creditorId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [notFound, setNotFound] = React.useState(false);

  if (!invoiceId || !creditorId)
    throw Error("Invoice id or creditor id missing");

  const currentInvoice = useSelector(
    (state) => state[storeName].currentInvoice
  );

  const inProgress = useInProgress({ storeName, name: invoiceId });

  if (notFound) return [undefined, false, true];

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (!currentInvoice || currentInvoice?.ActionPublicId !== invoiceId) {
    dispatch(
      getHandleInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        notFoundCallback: () => setNotFound(true),
      })
    );
    return [undefined, true];
  }

  return [currentInvoice, false];
};

// reminder invoice id is really only used to determine if there is a reminder invoice or not
export const useReminderInvoice = ({ reminderInvoiceId, invoiceId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const currentReminderInvoice = useSelector(
    (state) => state[storeName].currentReminderInvoice
  );

  const inProgress = useInProgress({
    storeName,
    name: `reminder_${reminderInvoiceId}`,
  });
  if (!reminderInvoiceId) return [undefined, false];

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (
    !currentReminderInvoice ||
    currentReminderInvoice?.SourceActionPublicId !== invoiceId
  ) {
    dispatch(getReminderInvoice({ reminderInvoiceId, invoiceId }));
    return [undefined, true];
  }

  return [currentReminderInvoice, false];
};

export const useContractInvoice = ({ contractInvoiceId, creditorId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const currentContractInvoice = useSelector(
    (state) => state[storeName].currentContractInvoice
  );

  const inProgress = useInProgress({ storeName, name: contractInvoiceId });

  if (!contractInvoiceId || !creditorId) return [undefined, false];
  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (
    !currentContractInvoice ||
    currentContractInvoice?.ActionPublicId !== contractInvoiceId
  ) {
    dispatch(
      getHandleContractInvoice({
        creditorId,
        contractInvoiceActionId: contractInvoiceId,
      })
    );
    return [undefined, true];
  }

  return [currentContractInvoice, false];
};

export const useInvoicePreview = ({ invoiceId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  if (!invoiceId) throw Error("Invoice id missing");

  const currentPreview = useSelector(
    (state) => state[storeName].currentInvoicePreview
  );

  const inProgress = useInProgress({ storeName, name: `preview_${invoiceId}` });

  if (inProgress) return [undefined, true];

  if (!currentPreview || currentPreview?.id !== invoiceId) {
    dispatch(getInvoiceIdPreview({ invoiceId }));
    return [undefined, true];
  }

  return [currentPreview, false];
};

export const useContractInvoicePreview = ({ contractInvoiceId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  if (!contractInvoiceId) throw Error("contract InvoiceId id missing");

  const currentPreview = useSelector(
    (state) => state[storeName].currentInvoicePreview
  );

  const inProgress = useInProgress({
    storeName,
    name: `preview_${contractInvoiceId}`,
  });

  if (inProgress) return [undefined, true];

  if (!currentPreview || currentPreview?.id !== contractInvoiceId) {
    dispatch(getContractInvoiceIdPreview({ contractInvoiceId }));
    return [undefined, true];
  }

  return [currentPreview, false];
};

export const useInvoicesFromContractInvoice = ({ contractInvoiceId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const name = `${contractInvoiceId}_related`;

  const currentInvoices = useSelector((state) => state[storeName][name]);

  const inProgress = useInProgress({ storeName, name });

  if (!contractInvoiceId) return [undefined, false];
  if (inProgress) return [undefined, true];

  if (!currentInvoices) {
    dispatch(
      getInvoicesGeneratedByContractInvoice({
        contractInvoiceId,
        name,
      })
    );
    return [undefined, true];
  }

  return [currentInvoices, false];
};

// returns single invoices (not connected to a contract invoice) for a specific debtor invoice config
export const useSearchSpecificSingularInvoicesByDebtorsWithMapping = ({
  debtorInvoiceConfig,
  contractKind,
  invoicingObj,
}) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const identifier = `search_invoices_for_${contractKind}_${invoicingObj?.id}`;

  const singleInvoiceIds = invoicingObj?.billecta_single_invoice_ids?.map(
    (bsi) => bsi.object_id
  );
  const singleInvoiceQuery = buildQueryString({
    billecta_object_ids: singleInvoiceIds || [],
  });
  const [singleInvoiceMappings] =
    useFilteredSingleInvoiceMappings(singleInvoiceQuery);

  const currentInvoices = useSelector((state) => state[storeName][identifier]);
  const inProgress = useInProgress({ storeName, name: identifier });

  if (
    !singleInvoiceMappings?.length ||
    !debtorInvoiceConfig?.billecta_ids?.length
  )
    return [undefined, false];
  if (inProgress) return [undefined, true];

  if (!currentInvoices) {
    dispatch(
      getSingularInvoicesByDebtorConfig({
        debtorInvoiceConfig,
        identifier,
      })
    );
    return [undefined, true];
  }

  const mapped = currentInvoices?.map((i) => {
    const id = i.ActionPublicId;
    const matchingMapping = singleInvoiceMappings.find(
      (m) => m.billecta_id.object_id === id
    );

    return {
      ...i,
      pigelloInvoiceType: matchingMapping?.category_display || "Okänd",
    };
  });

  return [mapped, false];
};

export const useActiveContractInvoices = ({ creditorId }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const currentContractInvoices = useSelector(
    (state) => state[storeName].contractInvoices
  );

  const inProgress = useInProgress({
    storeName,
    name: `all_active_contractinvoices_${creditorId}`,
  });

  if (!creditorId) return [undefined, false];

  if (inProgress) return [undefined, true];

  // check if current invoices is the ones we want, if not, we fetch it
  if (
    !currentContractInvoices ||
    currentContractInvoices?.[0]?.CreditorPublicId !== creditorId
  ) {
    dispatch(getAllContractInvoices(creditorId));
    return [undefined, true];
  }

  return [currentContractInvoices, false];
};
