import * as React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import InternalCustomerTable from "../../../components/Lists/InternalCustomers/FullTable";
import Toggle from "../../../components/Toggle/Toggle";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import { useDispatch } from "react-redux";
import { runForceInvoicing } from "../../../store/internalCustomers";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";

const STEPS = {
  SELECT_CUSTOMER: 1,
  SELECT_JOBS: 2,
  CONFIRM: 3,
};

const AVAILABLE_JOBS = ["invoicing", "vacancyinvoicing"];

export default function ForceInvoicing() {
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(STEPS.SELECT_CUSTOMER);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [jobsToRun, setJobsToRun] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { goBack } = useHistory();

  const steps = ["1. Välj kund", "2. Välj jobb", "3. Bekräfta körning av jobb"];

  const handleSelectedCustomer = ({ original: customer }) => {
    setSelectedCustomer(customer);

    setStep(STEPS.SELECT_JOBS);
  };

  const toggleJob = (job) => {
    let jobsCopy = [...jobsToRun];

    if (jobsCopy.includes(job)) {
      jobsCopy = jobsCopy.filter((j) => j != job);
    } else {
      jobsCopy.push(job);
    }

    setJobsToRun(jobsCopy);
  };

  const handleRunJobs = () => {
    setLoading(true);

    dispatch(
      runForceInvoicing({
        jobsToRun,
        selectedCustomer,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Jobb startades",
              description:
                "Observera att det kan ta en liten stund att utföra jobben",
            })
          );

          goBack();
        },
        errorCallback: (e, status) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: e || status,
            })
          );
        },
      })
    );
  };

  const selectedCustomerStep = () => (
    <DetailPageBox>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Välj kund</OverviewTitle>
          <OverviewSubtitle>
            Klicka på en kund för att gå till nästa steg
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InternalCustomerTable
        hideColumns
        hideExport
        hideTitle
        onRowClicked={handleSelectedCustomer}
      />
    </DetailPageBox>
  );

  const selectJobsStep = () => (
    <DetailPageBox>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Välj vilka jobb som ska köras</OverviewTitle>
          <OverviewSubtitle>
            Välj vilka typer av genererings-jobb som ska köras
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InnerBox style={{ marginBottom: 24 }}>
        <Toggle
          extraStyle={{ marginBottom: 8 }}
          isRedGreen
          value={jobsToRun.includes("invoicing")}
          title={`Avier som ska genereras idag (${moment().format(
            "YYYY-MM-DD"
          )})`}
          onToggle={() => toggleJob("invoicing")}
        />

        <Toggle
          extraStyle={{ marginBottom: 8 }}
          isRedGreen
          value={jobsToRun.includes("vacancyinvoicing")}
          title={`Vakansbokningar som ska genereras idag (${moment().format(
            "YYYY-MM-DD"
          )})`}
          onToggle={() => toggleJob("vacancyinvoicing")}
        />
      </InnerBox>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PrimaryButton
          disabled={jobsToRun?.length === 0}
          clicked={() => {
            setStep(STEPS.CONFIRM);
          }}
          title={"Gå vidare"}
        />
      </div>
    </DetailPageBox>
  );

  const confirmStep = () => (
    <DetailPageBox>
      {loading && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Bekräfta körning av jobb</OverviewTitle>
          <OverviewSubtitle>
            Kontrollera datan och bekräfta för att starta jobben
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <DetailInfo infoObj={getInfoObj({ selectedCustomer, jobsToRun })} />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PrimaryButton
          disabled={jobsToRun?.length === 0}
          clicked={() => {
            handleRunJobs();
          }}
          title={"Kör jobb"}
        />
      </div>
    </DetailPageBox>
  );

  return (
    <DetailInnerWrapper>
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Avbryt & gå tillbaka "
        iconType="arrow-back"
        clicked={() => goBack()}
      />
      <StepDisplay steps={steps} activeIndex={step - 1} />

      {step === STEPS.SELECT_CUSTOMER && selectedCustomerStep()}
      {step === STEPS.SELECT_JOBS && selectJobsStep()}
      {step === STEPS.CONFIRM && confirmStep()}
    </DetailInnerWrapper>
  );
}

function getInfoObj({ selectedCustomer, jobsToRun }) {
  return {
    "Körning av jobb": [
      {
        title: "Vald kund",
        value: selectedCustomer?.str_representation,
      },
      {
        title: "Kör avisingsjobb",
        value: (
          <StatusLabel state={jobsToRun.includes("invoicing") ? 0 : 6}>
            {jobsToRun.includes("invoicing") ? "Kör jobb" : "Kör EJ jobb"}
          </StatusLabel>
        ),
      },
      {
        title: "Kör vakansbokningsjobb",
        value: (
          <StatusLabel state={jobsToRun.includes("vacancyinvoicing") ? 0 : 6}>
            {jobsToRun.includes("vacancyinvoicing")
              ? "Kör jobb"
              : "Kör EJ jobb"}
          </StatusLabel>
        ),
      },
    ],
  };
}
