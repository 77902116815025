export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INTERNAL_CUSTOMER",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INTERNAL_CUSTOMER",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_INTERNAL_CUSTOMER",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INTERNAL_CUSTOMER",
  DESTROY_FORM: "DESTROY_FORM_INTERNAL_CUSTOMER",
  SET_FORM_ERROR: "SET_FORM_ERROR_INTERNAL_CUSTOMER",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INTERNAL_CUSTOMER",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INTERNAL_CUSTOMER",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INTERNAL_CUSTOMER",
  RESET_STATE: "RESET_STATE_INTERNAL_CUSTOMER",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INTERNAL_CUSTOMER",
  REMOVE_OBJECT: "REMOVE_OBJECT_INTERNAL_CUSTOMER",
  SET_EXPECTED_INVOICE_STAT: "SET_EXPECTED_INVOICE_STAT_INTERNAL_CUSTOMER",
  CLEAR_FETCHED: "CLEAR_FETCHED_INTERNAL_CUSTOMER",

  LIST_URL: "/sales/internalcustomer/list/",
  POST_URL: "/sales/internalcustomer/",
  PATCH_URL: "/sales/internalcustomer/",
  GET_URL: "/sales/internalcustomer/",

  RUN_INVOICING_GENERATING: "/accounting/contract/run_invoice_generating/",
  RUN_VACANCY_GENERATING:
    "/accounting/contract/run_vacancy_invoice_generating/",

  STORE_NAME: "internalCustomers",
};
