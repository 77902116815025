import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    instructionsKey,
    noMargin,
    extraStyles = {},
    noHelp,
    small,
    alwaysShowHelp,
    title,
    disabled,
    description,
    // top, bottom
    forceVerticalToolTipPlacement,
    //left ,right
    forceHorizontalToolTipPlacement,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );
    };

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ small, noMargin }}>
        <SC.CheckBoxWrapper {...{ noMargin, small }} key={fieldKey}>
          <SC.CheckBox
            {...{ small, disabled }}
            id={fieldKey}
            checked={Boolean(value)}
            onChange={({ target: { checked } }) => onChange(checked)}
          />
          <label htmlFor={fieldKey}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {title}
              {instructions._required ? "*" : ""}
              {description && !alwaysShowHelp && !noHelp && (
                <DescriptionToolTip
                  forceHorizontalPlacement={forceHorizontalToolTipPlacement}
                  forceVerticalPlacement={forceVerticalToolTipPlacement}
                  description={description}
                />
              )}
            </div>
          </label>
        </SC.CheckBoxWrapper>
        {alwaysShowHelp && <div>{description}</div>}
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
