import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Granska och slutför</OverviewTitle>
          <OverviewSubtitle>
            Kontrollera att uppgifterna stämmer. Tryck på "
            {method === "POST" ? "Skapa" : "Uppdatera"} faktureringsinställning"
            för att slutföra.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />
    </>
  );
};
