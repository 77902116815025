export default [
  {
    title: "Förvaltningsuppdrag",
    key: "CUSTOMER",
    visited: true,
    hasError: false,
    fieldKeys: ["customer"],
  },
  {
    title: "Uppdatera",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
