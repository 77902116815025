import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { usePermissionCheck } from "../../store/base";
import {
  useEditabledoc,
  constants,
  overviewUrl,
} from "../../store/editabledocs";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import Attachments from "./detail/Attachments";
import Settings from "./detail/Settings";
import Template from "./detail/Template";

export default function EditableDocMainDetail() {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const { editableDocId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();

  const [editableDoc] = useEditabledoc(editableDocId);
  usePermissionRedirect(["view_can_contract"]);

  const canDelete = usePermissionCheck("delete_can_contract");

  const SUB_PAGES = [
    {
      path: "/template",
      label: "Mall",
      component: Template,
    },
    {
      path: "/attachments",
      label: "Bilagor",
      component: Attachments,
    },
    {
      path: "/settings",
      label: "Inställningar",
      component: Settings,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({ name: "Radera", onClick: () => setDeleteOpen(true) });
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={editableDoc}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Mall ${editableDoc?.str_representation || "laddar..."}`}
          breadCrumbs={[
            { url: overviewUrl(), label: "Mallar" },
            { label: editableDoc?.str_representation || "Laddar..." },
          ]}
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
