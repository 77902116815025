import { cloneDeep } from "lodash";
import moment from "moment";

const TYPES = {
  CONTRACT_INVOICE: "CONTRACT_INVOICE",
  INVOICE: "INVOICE",
};

export default function checkValidations({
  setErrors,
  formInstance,
  debtorInstance,
  tenant,
  kivraEnabled,
  eInvoiceEnabled,
  type,
  isCreditInvoice = false,
}) {
  const instance = cloneDeep(formInstance);

  const debtorIsCompany = !!tenant?.user?.corporate_name;

  let errors = {};

  // delivery method validation
  if (instance.DeliveryMethod && debtorInstance) {
    if (instance.DeliveryMethod === "Email" && !debtorInstance.email_invoice) {
      errors["DeliveryMethod_Email"] = true;
    }
    if (
      instance.DeliveryMethod === "Mail" &&
      !debtorInstance.invoice_address?.base
    ) {
      errors["DeliveryMethod_Mail"] = true;
    }
    if (instance.DeliveryMethod === "Kivra" && !kivraEnabled) {
      errors["DeliveryMethod_Kivra_Disabled"] = true;
    }
    if (instance.DeliveryMethod === "SMS" && !tenant?.user?.phone) {
      errors["DeliveryMethod_SMS"] = true;
    }
    if (
      instance.DeliveryMethod === "EInvoice" &&
      !debtorIsCompany &&
      !eInvoiceEnabled
    ) {
      errors["DeliveryMethod_EInvoice_Disabled"] = true;
    }
  }

  // Date validation
  if (type === TYPES.INVOICE && instance.InvoiceDate && instance.DueDate) {
    if (moment(instance.InvoiceDate).isAfter(moment(instance.DueDate))) {
      errors["DueDate"] = true;
    }

    if (moment(instance.DueDate).isBefore(moment())) {
      errors["DueDate_before_today"] = true;
    }
  }

  // credit invoice validation
  if (isCreditInvoice && instance.Records?.length > 0) {
    const { Records } = instance;
    Records.forEach((r) => {
      const isMessageRow = r.RecordType === "Message";
      if (!isMessageRow && r.UnitPrice?.Value > 0) {
        errors["Credit_invoice_records_positivevalue"] = true;
      }
    });
  }

  if (
    type === TYPES.CONTRACT_INVOICE &&
    instance.RecurrenceDetails?.Start &&
    instance.RecurrenceDetails?.End
  ) {
    if (
      moment(instance.RecurrenceDetails?.End).isBefore(moment()) &&
      !instance.RecurrenceDetails?.NoEndDate
    ) {
      errors["End_before_today"] = true;
    }
  }

  if (instance.DebtCollectionDetails?.SendToDebtCollection === true) {
    // Debtcollection validations
    const { DebtCollectionDetails } = instance;

    // days delay after payment day at least 1
    if (DebtCollectionDetails.DaysDelayAfterDueDate < 1) {
      errors["DebtCollectionDetails.DaysDelayAfterDueDate"] = true;
    }

    // days delay after due date + reminder invoice date
    if (instance.ReminderInvoiceDetails?.SendReminderInvoice) {
      if (
        instance.ReminderInvoiceDetails.DaysDelayAfterDueDate >=
        DebtCollectionDetails.DaysDelayAfterDueDate
      ) {
        errors["DebtCollectionDetails.DaysDelayAfterDueDate_Reminder"] = true;
      }
    }

    // minimum payment days
    if (DebtCollectionDetails.PaymentTermsInDays < 10) {
      errors["DebtCollectionDetails.PaymentTermsInDays"] = true;
    }

    // reason for higher interest
    if (instance.InterestPercentage > 8 && !instance.ReasonForHigherInterest) {
      errors["ReasonForHigherInterest"] = true;
    }

    if (instance.InterestStartInDaysAfterDueDate < 8) {
      errors["InterestStartInDaysAfterDueDate"] = true;
    }

    // late payment fee needs to be setup in order to send to private customers

    if (
      DebtCollectionDetails.StartDebtCollectionActionLevel ===
        "LatePaymentFee" &&
      !debtorIsCompany
    ) {
      errors["StartDebtCollectionActionLevelLatePaymentFeeNotCompany"] = true;
    }
  }

  setErrors(errors);
}
