import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { DateCell } from "../../Displays";

export default ({ reportRows, fromDate, toDate, handleRowClicked }) => {
  const data = React.useMemo(() => {
    return reportRows;
  }, [reportRows]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Avtalsnummer",
        accessor: "contractNumber",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
        Footer: () => {
          return <Field style={{ fontWeight: 600 }}>Total sida:</Field>;
        },
        TotalFooter: () => {
          return <Field style={{ fontWeight: 600 }}>Total:</Field>;
        },
      },
      {
        Header: "Kundens referens",
        accessor: "yourReference",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Fakturadatum",
        accessor: "invoiceDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "dueDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Fakturabelopp (SEK ink. Moms)",
        accessor: "sumIncVAT",
        Cell: (props) => {
          return <Field>{props?.value?.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.sumIncVAT;
            return (acc += val);
          }, 0);

          return <Field>{summarized?.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.sumIncVAT;
            return (acc += val);
          }, 0);

          return <Field>{summarized?.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Fakturabelopp belopp (SEK exkl. Moms)",
        accessor: "sumExclVAT",
        Cell: (props) => {
          return <Field>{props.value?.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.sumExclVAT;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.sumExclVAT;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Moms total (SEK)",
        accessor: "VAT",
        Cell: (props) => {
          return <Field>{props.value?.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.VAT;
            return (acc += val);
          }, 0);

          return <Field>{summarized?.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.VAT;
            return (acc += val);
          }, 0);

          return <Field>{summarized?.toLocaleString("sv")}</Field>;
        },
      },
    ],
    [data]
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        withExport
        exportName={`framtida_fakturor_${fromDate}-${toDate}`}
        onRowClicked={handleRowClicked}
        withSummaryFooter
        withTotalSummaryFooter
        tableId="futureinvoicestable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
