import * as React from "react";
import { useDispatch } from "react-redux";

import * as SC from "./styles";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { useFilePicker } from "../../../../hooks/useFilePicker";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import { TextButton } from "../Buttons";

const MAX_FILE_SIZE = 100;
const ALLOWED_FORMATS = [".jpg", ".png", ".svg"];

export default function ImageUploader({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  title,
  description,
  allowedFormats = null,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const tempDataFieldKey = `${fieldKey}_temp_data`;

  const instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });
  const value = useFormInstanceField({ storeName, fieldKey });
  const tempValue = useFormInstanceField({
    storeName,
    fieldKey: tempDataFieldKey,
  });
  const error = useFormError({ storeName, fieldKey });

  const { files, onClick, onClear, errors, HiddenFileInput } = useFilePicker({
    maxFileSize: MAX_FILE_SIZE,
  });

  React.useEffect(() => {
    handleChange();
  }, [files]);

  const handleClear = () => {
    dispatch(
      updateActiveFormInstance({ storeName, data: { [fieldKey]: null } })
    );
  };

  const handleChange = async () => {
    if (files.length === 0) {
      return;
    } else {
      setLoading(true);

      // clear old image state to trigger rerender if base64 -> base64
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: null } })
      );

      const file = await toBase64(files[0]);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: file.file_name },
        })
      );
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [tempDataFieldKey]: file },
        })
      );
      setLoading(false);
    }
  };

  const formats = allowedFormats || ALLOWED_FORMATS;

  const toBase64 = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileData = {
          file_name: file.name,
          file_size: file.size,
          data: reader.result.toString(),
        };

        resolve(fileData);
      };
    });
  };

  if (!instructions) {
    return null;
  }
  if (instructions._readOnly) {
    return null;
  }

  return (
    <SC.InputSpacing>
      <SC.InputFieldTitle>
        {title}
        {instructions._required ? "*" : ""}
      </SC.InputFieldTitle>
      {description && (
        <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
      )}
      <SC.InputFieldDescription>
        Tillåtna filformat: {formats.join(", ")}. Maximal storlek:{" "}
        {MAX_FILE_SIZE}mb.
      </SC.InputFieldDescription>
      <SC.FileUploaderWrapper onClick={onClick}>
        {loading && <OverlaySpinner />}
        <SC.UploadedImagesWrapper>
          {!!value ? (
            <SC.UploadedImage src={tempValue ? tempValue.data : value?.get} />
          ) : (
            <SC.NoImage />
          )}
        </SC.UploadedImagesWrapper>

        <SC.FileUploaderLabel>
          {loading ? "Laddar..." : value?.get ? "Byt ut bild" : "Välj en bild"}
        </SC.FileUploaderLabel>
        {value && (
          <TextButton
            red
            extraStyle={{ marginTop: 12 }}
            title="Ta bort bild"
            clicked={() => {
              // filepicker
              onClear();
              // internal
              handleClear();
            }}
          />
        )}
        <HiddenFileInput accept={formats.join(", ")} multiple={false} />
      </SC.FileUploaderWrapper>
      {errors?.hasInvalidFileSize && (
        <SC.ErrorMessage>
          Filen är för stor (får vara max {MAX_FILE_SIZE}mb)
        </SC.ErrorMessage>
      )}
      {errors?.hasInvalidImage && (
        <SC.ErrorMessage>
          Filen är inte tillåten. Välj en ny i ett tillåtet format och storlek.
        </SC.ErrorMessage>
      )}
      {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.InputSpacing>
  );
}
