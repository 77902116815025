import * as React from "react";
import { useHistory } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import * as SharedStyles from "../../components/sharedStyles";
import PageTitle from "../Layouts/PageTitle/PageTitle";
import Notifications from "../NotificationsCenter/Notifications";

export default () => {
  const { push } = useHistory();

  const goToEdit = () => {
    push(`/account/notifications/settings`);
  };

  const breadCrumbs = [
    {
      label: "Inställningar",
      url: "/account/user",
    },
    {
      label: "Notifikationer",
    },
  ];

  return (
    <SharedStyles.BareLayoutWrapper>
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle dark title="Notifikationer" breadCrumbs={breadCrumbs} />
      </SharedStyles.BareLayoutTitleWrapper>

      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Notifikationer</OverviewTitle>

          <PrimaryButton
            newDesign
            title="Notisinställningar"
            clicked={goToEdit}
          />
        </OverviewTitleWrapper>

        <Notifications />
      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
