import * as React from "react";
import { useSelector } from "react-redux";
import { constants } from "../../../../../store/managementContracts";

// style, design
import { FormAreaDescription } from "../../../Base/Chapters/styles";
import { FileManyToMany } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const isSigned = useSelector((state) => {
    const instance = state[storeName].formInstance;
    const manualSign = instance?.is_signed;
    if (manualSign) {
      return true;
    }

    const status = instance?.editabledoc?.status || "preparation";
    return status !== "preparation";
  });

  const usesESigning = useSelector(
    (state) => state[storeName].formInstance.uses_e_signing
  );

  return (
    <>
      {isSigned && usesESigning ? (
        <FormAreaDescription>
          Dokumentet är signerat, eller håller på att signeras.
          <br></br>
          Härav kan du ej redigera dessa detaljer just nu.
        </FormAreaDescription>
      ) : (
        <FileManyToMany
          title="Bilagor"
          description="Ladda upp eventuella bilagor till avtalet"
          storeName={storeName}
          method={method}
          fieldKey={"editabledoc.attachments"}
        />
      )}
    </>
  );
};
