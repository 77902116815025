import * as React from "react";
import { TableSelect } from "../../../Base/Fields";

import { constants } from "../../../../../store/invoicingCustomer";
import {
  constants as invoicingSettingConstants,
  useInvoicingSettingPagination,
  performFilter,
  constructStrRep,
} from "../../../../../store/invoicingSettings";
import listDefs from "../../../../Lists/InvoicingSettings/listDefs";
import { TextButton } from "../../../Base/Buttons";
import { useFormInstanceField } from "../../../../../store/base";
import InvoicingSettingSummary from "../../../../Summaries/InvoicingSettingSummary";

import {
  constants as companyConstants,
  useCompanyPagination,
  performFilter as companyPerformFilter,
  constructStrRep as companyConstructStrRep,
} from "../../../../../store/companies";
import companyListDefs from "../../../../Lists/Companies/listDefs";
import { InfoBox } from "../../../../Displays";

export default ({
  method,
  onGoToCreateNew,
  onCopyAndCreateNew,
  skipInvoicingSetting,
}) => {
  const storeName = constants.STORE_NAME;

  const selectedSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  return (
    <>
      <InfoBox
        boxTheme="info"
        title="Vill du sätta upp avisering senare?"
        text={`Vill du inte avisera för detta avtal eller sätta upp aviseringen senare? Tryck på "Gå direkt till kostnader" för att hoppa över aviseringsinställningen.`}
        renderButton={{
          title: "Gå direkt till kostnader",
          iconType: "arrow",
          iconPlacement: "right",
          clicked: skipInvoicingSetting,
        }}
      />

      <hr />

      <TableSelect
        storeName={storeName}
        tableStoreName={companyConstants.STORE_NAME}
        method={method}
        fieldKey={"billing_company"}
        listDefs={companyListDefs}
        paginationMethod={useCompanyPagination}
        performFilter={companyPerformFilter}
        title="Välj fakturerande bolag..."
        fieldTitle="Fakturerande bolag"
        description={
          "Välj vilket bolag detta avtal ska aviseras ifrån. Om inget bolag väljs kommer bolaget som hyresobjektet tillhör att användas"
        }
        constructStrRep={companyConstructStrRep}
      />

      <TableSelect
        storeName={storeName}
        tableStoreName={invoicingSettingConstants.STORE_NAME}
        method={method}
        fieldKey={"setting"}
        listDefs={listDefs}
        paginationMethod={useInvoicingSettingPagination}
        performFilter={performFilter}
        title="Välj aviseringsinställning..."
        fieldTitle="Aviseringsinställning"
        description={
          "Välj vilken basinställning för avisering som ska användas för avtalet"
        }
        constructStrRep={constructStrRep}
      />

      {!selectedSetting && (
        <TextButton
          title="Eller tryck här för att skapa en ny inställning"
          iconType="add"
          iconPlacement="right"
          clicked={() => onGoToCreateNew()}
        />
      )}

      {selectedSetting && (
        <InvoicingSettingSummary
          onCopy={onCopyAndCreateNew}
          setting={selectedSetting}
        />
      )}
    </>
  );
};
