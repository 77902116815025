import * as React from "react";
import { InfoBox } from "../../../Displays";

import NonConnectedDatePicker from "../../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { FormAreaDescription } from "../styles";

export default function SelectPeriod({ period, setPeriod, error }) {
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);

  React.useEffect(() => {
    setPeriod({
      start_date: startDate,
      end_date: endDate,
    });
  }, [startDate, endDate]);

  return (
    <>
      <FormAreaDescription>
        Välj period som fakturan ska avse. Kostnader för perioden kommer att
        genereras automatiskt utifrån detaljerna på avtalet.
      </FormAreaDescription>

      {error && (
        <InfoBox boxTheme="warning" title="Ogiltiga datum" text={error} />
      )}

      <NonConnectedDatePicker
        value={period.start_date}
        label="Fakturerad period start"
        id="startdate"
        onChange={(date) => setStartDate(date)}
      />
      <NonConnectedDatePicker
        value={period.end_date}
        label="Fakturerad period slut"
        id="enddate"
        onChange={(date) => setEndDate(date)}
      />
    </>
  );
}
