import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import FullChapter from "../../Base/Chapters/FullChapter";
import renderChapter from "./renderChapter";
import chapterDefs from "./chapterDefs";

// store, state
import {
  destroyScriveSignupForm,
  setSignupProposal,
  submitScriveSignup,
  useScriveSignupForm,
  constants,
} from "../../../../store/editabledocs";
import { useHasSignedUpScrive } from "../../../../store/editabledocs/hooks/retrieve";

export default () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();

  const storeName = constants.STORE_NAME;

  const formLoaded = Boolean(useScriveSignupForm());
  const [hasSignedUp, isLoadingHasSignedUp] = useHasSignedUpScrive();

  React.useEffect(() => {
    if (formLoaded) {
      dispatch(setSignupProposal());
    }
  }, [formLoaded, dispatch]);

  const checkout = (success) => {
    dispatch(destroyScriveSignupForm(success));
  };

  const onSuccess = () => {
    checkout(true);
    goBack();
  };

  const onSubmit = () => {
    dispatch(submitScriveSignup({ successCallback: onSuccess }));
  };

  const onDone = () => {
    checkout(false);
  };

  // let false, undefined and null do the same thing
  // (null => error on checking if signed up, undefined haven't checkd, false hasn't signed up)
  if (hasSignedUp) {
    return <span>You've already signed up</span>;
  }

  return (
    <FullChapter
      loading={!formLoaded}
      storeName={storeName}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key)}
      submitCallback={onSubmit}
      title={"Spara"}
      onDone={onDone}
    />
  );
};
