import styled, { css } from "styled-components";
import addIcon from "../../../../assets/svg/add_border.svg";
import trashIcon from "../../../../assets/svg/delete.svg";
import editIcon from "../../../../assets/svg/edit.svg";
import excelIcon from "../../../../assets/svg/excel.svg";
import outlookIcon from "../../../../assets/svg/outlook-icon.svg";
import closeCircle from "../../../../assets/svg/close-circle.svg";
import downloadIcon from "../../../../assets/svg/download_link.svg";
import materialAddIcon from "../../../../assets/svg/material/add-blue.svg";
import materialDoneIcon from "../../../../assets/svg/material/done_blue.svg";
import materialArrowIcon from "../../../../assets/svg/material/arrow-right-blue.svg";
import materialWhiteArrowIcon from "../../../../assets/svg/material/arrow-right-white.svg";
import materialEdit from "../../../../assets/svg/material/edit-blue.svg";
import infoBlue from "../../../../assets/svg/material/info_blue.svg";
import closeWhite from "../../../../assets/svg/material/close-circle-white.svg";
import closeIcon from "../../../../assets/svg/material/close-circle-blue.svg";
import boltIcon from "../../../../assets/svg/bolt_purple.svg";
import moreIcon from "../../../../assets/svg/material/more_vertical_black.svg";
import moreIconWhite from "../../../../assets/svg/material/more_vertical_white.svg";
import notificationsIcon from "../../../../assets/svg/material/notifications_blue.svg";
import pauseIcon from "../../../../assets/svg/material/pause_blue.svg";
import copyIcon from "../../../../assets/svg/material/copy_blue.svg";
import syncIcon from "../../../../assets/svg/material/sync_blue.svg";
import eventRepeatIcon from "../../../../assets/svg/material/event_repeat_blue.svg";
import eventIcon from "../../../../assets/svg/material/calendar_blue.svg";
import launchIcon from "../../../../assets/svg/material/fullscreen_blue.svg";
import launchIconWhite from "../../../../assets/svg/material/fullscreen_white.svg";
import lockIconBlue from "../../../../assets/svg/material/lock_blue.svg";
import { pulseBlue } from "../../../sharedStyles";

export const PrimaryButton = styled.button`
  position:relative;
  -webkit-appearance: none;
  white-space:nowrap;
  border: none;
  box-sizing: border-box;
  color: ${(p) => (p.inverted ? p.theme.colors.primaryBlue : "#fff")};
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  background-color: ${(p) => (p.inverted ? "#fff" : p.theme.colors.greenDark)};
  border-radius: 30px;

  ${(p) =>
    p.pulse &&
    css`
      animation: ${pulseBlue} 1.2s linear infinite;
    `}


  &:hover {
    background-color: ${(p) =>
      p.inverted ? p.theme.colors.gray1 : p.theme.colors.primaryBlueFaded};   
  }
  
  &:active {
    outline: none;
  }

  ${
    "" /* &.full-width {
    width: 100%;
  }
  &.warn {
    background-color: red;
  } */
  }
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }

   ${(p) =>
     p.fillWidth &&
     css`
       width: 100%;
     `}
}
`;

export const SecondaryButton = styled.button`
background-color: #fff;
white-space:nowrap;
  -webkit-appearance: none;
  border: 1px solid ${(p) => p.theme.colors.greenDark};
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};

  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  border-radius: 30px;
  color: ${(p) => p.theme.colors.greenDark};
  
  &:hover {
    color: ${(p) => p.theme.colors.green};
  }

  &:active {
    outline: none;
  }

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }


  ${(p) =>
    p.fillWidth &&
    css`
      width: 100%;
    `}
}

  ${(p) =>
    p.pulse &&
    css`
      animation: ${pulseBlue} 1.2s linear infinite;
    `}

`;

export const LinkButton = styled.button`
background-color: rgba(0,0,0,0);
color:  ${(p) => p.theme.colors.blueDark};
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};

  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
      font-family: "Inter", sans-serif;
      font-size: ${(p) => p.theme.fontSizes.headerXSmall};
      font-weight: ${(p) => p.theme.fontWeights.data};
      color: ${(p) => p.theme.colors.primaryBlue};


   &:hover {
      color: ${(p) => p.theme.colors.primaryBlueFaded};
  }
  
  &:active {
    outline: none;
  }

  ${
    "" /* &.full-width {
    width: 100%;
  }
  &.warn {
    background-color: red;
  } */
  }
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }
}
`;

export const CreateNewButton = styled.button`
background-color: rgba(0,0,0,0);
color:  ${(p) => p.theme.colors.blueDark};
  -webkit-appearance: none;
  box-sizing: border-box;
    cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};

  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  outline: none;
  padding: 6px 0 6px 22px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  position:relative;

  &:hover {
    opacity: ${(p) => (p.disabled ? 0.7 : 0.8)};
  }
  &:active {
    outline: none;
  }

  &::after {
    position:absolute;
    content:"";
    background-position:center;
    background-repeat:no-repeat;
    background-image: url(${addIcon});
    background-size:contain;
    height: 18px;
    width: 18px;
    top: calc(50% - 10px);
    left:0;
  }

  ${
    "" /* &.full-width {
    width: 100%;
  }
  &.warn {
    background-color: red;
  } */
  }
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }
}
`;

export const RemoveButton = styled.button`
background-color: rgba(0,0,0,0);
color:  ${(p) => p.theme.colors.red};
  -webkit-appearance: none;
  box-sizing: border-box;
   cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};

  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  outline: none;
  padding: 6px 0 6px 22px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  position:relative;

  &:hover {
   opacity: ${(p) => (p.disabled ? 0.7 : 0.8)};
  }
  &:active {
    outline: none;
  }

  &::after {
    position:absolute;
    content:"";
    background-position:center;
    background-repeat:no-repeat;
    background-image: url(${(p) => (p.closeIcon ? closeCircle : trashIcon)});
    background-size:contain;
    height: 18px;
    width: 18px;
    top: calc(50% - 10px);
    left:0;
  }

  ${
    "" /* &.full-width {
    width: 100%;
  }
  &.warn {
    background-color: red;
  } */
  }
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }
}
`;

export const EditButton = styled(RemoveButton)`
  color: ${(p) => p.theme.colors.primary};

  &::after {
    position: absolute;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${editIcon});
    background-size: contain;
    height: 16px;
    width: 16px;
    top: calc(50% - 8px);
    left: 0;
  }
`;

export const ExportExcelButton = styled(PrimaryButton)`
  background-color: rgb(52, 106, 68);
  position: relative;
  color: #fff;
  padding-left: 50px;

  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  border-radius: 30px;

  &::after {
    position: absolute;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${excelIcon});
    background-size: contain;
    height: 18px;
    width: 18px;
    top: calc(50% - 9px);
    left: 20px;
  }
`;

export const OutlookButton = styled(PrimaryButton)`
  background-color: #0072c6;
  position: relative;
  color: #fff;
  padding-left: 50px;

  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  border-radius: 30px;

  &::after {
    position: absolute;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${outlookIcon});
    background-size: contain;
    height: 18px;
    width: 18px;
    top: calc(50% - 9px);
    left: 20px;
  }
`;

export const TableLinkButton = styled.button`
  display: inline-block;
  outline: none;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: start;
  font-weight: 600;
  box-sizing: border-box;
  color: ${(p) => p.theme.colors.blue};
  -webkit-appearance: none;

  &:active {
    outline: none;
  }
`;

export const InsightsButton = styled(PrimaryButton)`
  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  border: thin solid ${(p) => p.theme.colors.purple};
  border-radius: 30px;
  background-color: ${(p) => p.theme.colors.purpleLight};
  color: ${(p) => p.theme.colors.purple};
  padding-left: 40px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${boltIcon});
    background-size: contain;
    height: 18px;
    width: 18px;
    top: calc(50% - 9px);
    left: 20px;
  }
`;

export const TextButton = styled.button`
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};
  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  color: ${(p) =>
    p.disabled
      ? p.theme.colors.gray2
      : p.inverted
      ? "#fff"
      : p.theme.colors.greenDark};
  display: inline-flex;
  outline: none;
  text-align: center;
  transition: all 0.2s ease-in-out;
  position: relative;
  align-items: center;

  ${(p) =>
    p.iconPlacement === "right" &&
    css`
      flex-direction: row-reverse;
      margin-right: auto;
    `}

  ${(p) =>
    p.underlined &&
    css`
      text-decoration: underline;
    `}


  ${(p) =>
    p.red &&
    css`
      color: ${(p) => p.theme.colors.red};
    `}

  &:hover {
    color: ${(p) => p.theme.colors.primaryBlueFaded};
  }
`;

export const TextButtonIcon = styled.div`
  height: ${(p) => p.theme.fontSizes.headerXSmall};
  width: ${(p) => p.theme.fontSizes.headerXSmall};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 3px 0 0;

  ${(p) =>
    p.iconPlacement === "right" &&
    css`
      margin: 0 0 0 3px;
    `}

  ${"" /* standard icon types, string passed down  */}
  ${(p) =>
    p.iconType === "download" &&
    css`
      background-image: url(${downloadIcon});
    `}
    ${(p) =>
    p.iconType === "add" &&
    css`
      background-image: url(${materialAddIcon});
    `}
    ${(p) =>
    p.iconType === "arrow" &&
    css`
      background-image: url(${p.inverted
        ? materialWhiteArrowIcon
        : materialArrowIcon});
    `}

 ${(p) =>
    p.iconType === "arrow-back" &&
    css`
      background-image: url(${materialArrowIcon});
      transform: rotate(180deg);
    `}

     ${(p) =>
    p.iconType === "edit" &&
    css`
      background-image: url(${materialEdit});
    `}

     ${(p) =>
    p.iconType === "close" &&
    css`
      background-image: url(${p.inverted ? closeWhite : closeIcon});
    `}

     ${(p) =>
    p.iconType === "done" &&
    css`
      background-image: url(${materialDoneIcon});
    `}

     ${(p) =>
    p.iconType === "info" &&
    css`
      background-image: url(${infoBlue});
    `}
      
    ${(p) =>
    p.iconType === "notification" &&
    css`
      background-image: url(${notificationsIcon});
    `}

       
    ${(p) =>
    p.iconType === "pause" &&
    css`
      background-image: url(${pauseIcon});
    `}

      ${(p) =>
    p.iconType === "copy" &&
    css`
      background-image: url(${copyIcon});
    `}

        ${(p) =>
    p.iconType === "sync" &&
    css`
      background-image: url(${syncIcon});
    `}

         ${(p) =>
    p.iconType === "eventrepeat" &&
    css`
      background-image: url(${eventRepeatIcon});
    `}

          ${(p) =>
    p.iconType === "event" &&
    css`
      background-image: url(${eventIcon});
    `}

        ${(p) =>
    p.iconType === "launch" &&
    !p.inverted &&
    css`
      background-image: url(${launchIcon});
    `}

       ${(p) =>
    p.iconType === "launch" &&
    p.inverted &&
    css`
      background-image: url(${launchIconWhite});
    `}

${(p) =>
    p.iconType === "lock" &&
    css`
      background-image: url(${lockIconBlue});
    `}


${"" /* icon passed down */}
${(p) =>
    p.icon &&
    css`
      background-image: url(${p.icon});
    `}
`;

export const MenuButton = styled.div`
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 24px;
  min-width: 24px;
  background-image: url(${(p) => (p.light ? moreIconWhite : moreIcon)});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
