import constants from "./constants";
import { store } from "../../store";
import * as services from "./services";
import { cloneDeep } from "lodash";

export const getKivraSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getKivraSettings(creditorId);

    dispatch({
      type: constants.SET_KIVRA_SETTINGS,
      payload: {
        kivraSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const updateKivraSettings = ({
  creditorId,
  enable,
  successCallback,
}) => {
  return async (dispatch) => {
    const data = {
      CreditorPublicId: creditorId,

      IsEnabled: enable,
    };

    await services.updateKivraSettings(data);

    // update integration status
    dispatch(getKivraSettings(creditorId));

    successCallback && successCallback();
  };
};

export const getVismaSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getVismaSettings(creditorId);

    dispatch({
      type: constants.SET_VISMA_SETTINGS,
      payload: {
        vismaSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const getPEAccountingSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getPEAccountingSettings(creditorId);

    dispatch({
      type: constants.SET_PE_SETTINGS,
      payload: {
        peSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const updatePEAccountingSettings = ({ creditorId, successCallback }) => {
  return async (dispatch) => {
    const state = store.getState();

    const data = cloneDeep(state.billectaIntegrations.formInstance);
    data.CreditorPublicId = creditorId;

    await services.updatePEAccountingSettings(data);

    // update integration status
    dispatch(getPEAccountingSettings(creditorId));

    successCallback && successCallback();
  };
};

export const getFortnoxSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getFortnoxSettings(creditorId);

    dispatch({
      type: constants.SET_FORTNOX_SETTINGS,
      payload: {
        fortnoxSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const updateFortnoxSettings = ({
  creditorId,
  successCallback,
  errorCallback,
  forceData,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const data =
        forceData || cloneDeep(state.billectaIntegrations.formInstance);
      data.CreditorPublicId = creditorId;

      await services.updateFortnoxSettings(data);

      // update integration status
      dispatch(getFortnoxSettings(creditorId));

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e);
    }
  };
};

export const getAutogiroSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getAutogiroSettings(creditorId);

    dispatch({
      type: constants.SET_AUTOGIRO_SETTINGS,
      payload: {
        autogiroSettings: settings,
      },
    });
  };
};

export const getEInvoiceSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getEInvoiceSettings(creditorId);

    dispatch({
      type: constants.SET_EINVOICE_SETTINGS,
      payload: {
        eInvoiceSettings: settings,
      },
    });
  };
};

export const updateAutogiroSettings = ({
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const data = cloneDeep(state.billectaIntegrations.formInstance);
      data.CreditorPublicId = creditorId;

      await services.updateAutogiroSettings(data);

      // update integration status
      dispatch(getAutogiroSettings(creditorId));

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const clearIntegrationData = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_INTEGRATION_STATE,
    });
  };
};
