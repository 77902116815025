import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { constants } from "../../../store/userGroups";

import BottomBar from "../../../components/StepDisplay/BottomBar";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import PickGroupForm from "../../../components/Forms/UserGroup/PickGroupForm";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { BareLayoutWrapper, InnerBox } from "../../../components/sharedStyles";
import { setActiveFormInstance } from "../../../store/base";

export default () => {
  const [loading, setLoading] = React.useState(false);
  const { push, goBack } = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const pickedGroup = useSelector(
    (state) => state[constants.STORE_NAME]?.formInstance
  );
  const { id } = pickedGroup;

  const onSubmit = () => {
    setLoading(true);
    push(
      `/account/groups-and-users/user-setup/invite-user/${id}/${userId}` //groupId, userId
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(
        setActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {},
        })
      );
    };
  }, []);

  return (
    <BareLayoutWrapper fillWidth style={{ paddingBottom: 120 }}>
      <StepDisplay
        steps={[
          "1. Skapa användare",
          "2. Ställ in behörigheter",
          "3. Bjud in användare",
        ]}
        activeIndex={1}
      />

      <InnerBox
        style={{
          maxWidth: 1200,
          padding: 48,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Välj grupp för behörigheter</OverviewTitle>
            <OverviewSubtitle>
              Observera att gruppen inte kan redigeras i denna vy, då det
              riskerar att påverkar andra användare som ligger i samma
              behörighetsgrupp.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <PickGroupForm />
      </InnerBox>

      <BottomBar
        primaryActionTitle="Spara och gå vidare"
        disabled={!id}
        primaryAction={onSubmit}
        cancelActionTitle="Tillbaka"
        cancelAction={() => goBack()}
      />
      {loading && <OverlaySpinner />}
    </BareLayoutWrapper>
  );
};
