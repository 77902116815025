import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary};
  text-align: center;

  ${(p) =>
    !p.displayOnlyMode &&
    css`
      background-color: rgba(256, 256, 256, 0.7);
      border: 4px solid ${(p) => p.color};
      border-radius: 5px;
    `}
`;
