import * as React from "react";
import * as SC from "./styles";
import { useTransition } from "react-spring";

export default ({
  chapterCount,
  chapterIndex,
  onNavigateNext,
  onNavigatePrev,
  isVisibile,
}) => {
  const displayTransition = useTransition(!!isVisibile, {
    config: { duration: 300 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return displayTransition(
    (styles, visible) =>
      visible && (
        <SC.FlowFormChapterNavigator style={{ ...styles }}>
          {chapterIndex > 0 && <SC.NavigatePrev onClick={onNavigatePrev} />}
          {chapterIndex !== chapterCount - 1 && (
            <SC.NavigateNext onClick={onNavigateNext} />
          )}
        </SC.FlowFormChapterNavigator>
      )
  );
};
