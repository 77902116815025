import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import SettingsTable from "../../../components/Lists/InvoicingSettings/FullTable";
import DebtSettingsTable from "../../../components/Lists/InvoicingDebtSettings/FullTable";
import InvoicingSettingModalForm from "../../../components/Forms/InvoicingSetting/ChapterForm/ModalForm";
import InvoicingDebtSettingModalForm from "../../../components/Forms/InvoicingDebtSetting/ChapterForm/ModalForm";

const TABS = {
  INVOICING_SETTINGS: "Avisering",
  DEBT_SETTINGS: "Betalningsvillkor",
};

export default function InvoicingSettings() {
  const [selectedTab, setSelectedTab] = React.useState("INVOICING_SETTINGS");
  const [createInvoicingSettingOpen, setCreateInvoicingSettingOpen] =
    React.useState(false);
  const [createDebtHandlingSettingOpen, setCreateDebtHandlingSettingOpen] =
    React.useState(false);

  const ACTIONS = {
    INVOICING_SETTINGS: (
      <TextButton
        title="Lägg till ny inställning för avisering"
        iconType="add"
        iconPlacement="right"
        clicked={() => setCreateInvoicingSettingOpen(true)}
      />
    ),
    DEBT_SETTINGS: (
      <TextButton
        title="Lägg till ny inställning för betalningsvillkor"
        iconType="add"
        iconPlacement="right"
        clicked={() => setCreateDebtHandlingSettingOpen(true)}
      />
    ),
  };

  return (
    <>
      <InvoicingSettingModalForm
        method="POST"
        isOpen={createInvoicingSettingOpen}
        onCheckout={() => setCreateInvoicingSettingOpen(false)}
      />

      <InvoicingDebtSettingModalForm
        method="POST"
        isOpen={createDebtHandlingSettingOpen}
        onCheckout={() => setCreateDebtHandlingSettingOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>{TABS[selectedTab]}</OverviewTitle>
              <OverviewSubtitle>{ACTIONS[selectedTab]}</OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            <PageSlider
              {...{
                TABS,
                selectedTab,
                onTabSelected: (tab) => setSelectedTab(tab),
              }}
            />
          </OverviewTitleWrapper>

          {selectedTab === "INVOICING_SETTINGS" && (
            <SettingsTable hideColumns hideTitle />
          )}
          {selectedTab === "DEBT_SETTINGS" && (
            <DebtSettingsTable hideColumns hideTitle />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
