import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/invoicingCustomer";
import { useFormInstanceField } from "../../../../../store/base";

import {
  constants as invoicingSettingConstants,
  useInvoicingSettingPagination,
  performFilter,
  constructStrRep,
  useInvoicingSetting,
} from "../../../../../store/invoicingSettings";
import listDefs from "../../../../Lists/InvoicingSettings/listDefs";

import InvoicingSettingSummary from "../../../../Summaries/InvoicingSettingSummary";
import { TableSelect } from "../../../Base/Fields";

import {
  constants as companyConstants,
  useCompanyPagination,
  performFilter as companyPerformFilter,
  constructStrRep as companyConstructStrRep,
} from "../../../../../store/companies";
import companyListDefs from "../../../../Lists/Companies/listDefs";
import { useDispatch } from "react-redux";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const [existingSetting] = useInvoicingSetting(defaultSetting?.id);

  return (
    <>
      <FormAreaTitle>Aviseringsinställning</FormAreaTitle>

      <TableSelect
        storeName={storeName}
        tableStoreName={companyConstants.STORE_NAME}
        method={method}
        fieldKey={"billing_company"}
        listDefs={companyListDefs}
        paginationMethod={useCompanyPagination}
        performFilter={companyPerformFilter}
        title="Välj fakturerande bolag..."
        fieldTitle="Fakturerande bolag"
        description={
          "Välj vilket bolag detta avtal ska aviseras ifrån. Om inget bolag väljs kommer bolaget som hyresobjektet tillhör att användas"
        }
        constructStrRep={companyConstructStrRep}
        modalInModal
      />

      <TableSelect
        storeName={storeName}
        tableStoreName={invoicingSettingConstants.STORE_NAME}
        method={method}
        fieldKey={"setting"}
        listDefs={listDefs}
        paginationMethod={useInvoicingSettingPagination}
        performFilter={performFilter}
        title="Välj aviseringsinställning..."
        fieldTitle="Aviseringsinställning"
        description={
          "Välj vilken basinställning för avisering som ska användas för avtalet"
        }
        constructStrRep={constructStrRep}
        modalInModal
      />

      {defaultSetting?.id && (
        <>
          {existingSetting ? (
            <InvoicingSettingSummary setting={existingSetting} />
          ) : null}
        </>
      )}
    </>
  );
};
