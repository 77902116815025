import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import { durationParse } from "../../Displays/utils";

import {
  useNotificationsPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/notificationsDisplay";

import { markAsRead } from "../../../store/notificationsDisplay";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
}) => {
  const dispatch = useDispatch();

  const storeName = constants.STORE_NAME;

  const exportParser = {
    state: (data) => data.state_display,
    category: (data) => data.category_display,
    notify_interval: (data) => durationParse(data.notify_interval),
    renew_interval: (data) => durationParse(data.renew_interval),
  };

  const onNotificationClicked = (row) => {
    const readByMe = row?.original?.read_by_me;
    if (readByMe === false) {
      dispatch(markAsRead([row.original.id]));
    }
  };

  const columns = React.useMemo(() => columnDefs, []);
  const fetchAllTreshold = 25;

  return (
    <>
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useNotificationsPagination}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        detailUrlMethod={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Notiser"}
        exportParser={exportParser}
        exportSheetController={exportSheetController}
        hideSearch
        hideExport
        hideCreate
        hideColumns
        hideFilters
        isNotificationsTable
        onRowClicked={(row) => {
          onNotificationClicked(row);
        }}
      />
    </>
  );
};
