export default [
  {
    title: 'Generell information',
    key: 'COMMON',
    visited: true,
    hasError: false,
    fieldKeys: [
      'name', 
      'scrive_name', 
      'orgnr',
      'email',
      'sms_sender_name'
    ],
  },
  {
    title: 'BankId detaljer',
    key: 'BANKID',
    visited: false,
    hasError: false,
    fieldKeys: [
      'swedish_bank_id',
      'description',
      'revenue',
      'owners_personal_number',
      'employee_amount',
      'bankid_terms',
      'authentications_month',
      'sign_month',
      'bank_id_name',
      'document_types',
    ],
  },
  {
    title: 'Skapa',
    key: 'CONFIRM',
    visited: false,
    hasError: false,
  },
];
