import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";

import InternalCustomerTable from "../../../components/Lists/InternalCustomers/FullTable";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { axiosInstance } from "../../../store/base/store/axios";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useHistory } from "react-router-dom";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import NonConnectedInputField from "../../../components/Forms/Base/Old/NonConnected/NonConnectedInputField";

const STEPS = {
  SELECT_CUSTOMER: 1,
  CONNECT: 2,
  CONFIRM: 3,
};

export default function ScriveCredentials() {
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();
  const [step, setStep] = React.useState(STEPS.SELECT_CUSTOMER);
  const [loading, setLoading] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [existingCredential, setExistingCredential] = React.useState("");
  const [editExisting, setEditExisting] = React.useState(false);

  const handleSelectedCustomer = ({ original: customer }) => {
    setSelectedCustomer(customer);
    setStep(STEPS.CONNECT);
  };

  const getCurrentCredentials = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/external/scrive/credentials/${selectedCustomer?.id}/`
      );

      const hasCredential = data?.length > 0;

      if (hasCredential) {
        setExistingCredential(data[0]);
        setEmail(data[0].email);
        setPassword(data[0].password);
      } else {
        setExistingCredential(false);
      }
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta nuvarande credentials",
        })
      );
    }
  };

  const editExistingCredentials = async () => {
    setLoading(true);

    try {
      await axiosInstance.patch(
        `/external/scrive/credential/${selectedCustomer.id}/${existingCredential.id}/`,
        {
          email,
          password,
        }
      );
      setLoading(false);
      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Scrive-credential uppdaterad",
          description: `Scrive är nu kopplat för ${selectedCustomer?.name}`,
        })
      );
      push("/admin");
    } catch (e) {
      setLoading(false);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej uppdatera",
          description: JSON.stringify(e?.response),
        })
      );
    }
  };

  const submitNewCredential = async () => {
    setLoading(true);

    try {
      await axiosInstance.post(
        `/external/scrive/credential/${selectedCustomer.id}/`,
        {
          email,
          password,
        }
      );
      setLoading(false);
      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Scrive-credential sparad",
          description: `Scrive är nu kopplat för ${selectedCustomer?.name}`,
        })
      );
      push("/admin");
    } catch (e) {
      setLoading(false);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej spara",
          description: JSON.stringify(e?.response),
        })
      );
    }
  };

  React.useEffect(() => {
    if (selectedCustomer) {
      getCurrentCredentials();
    }
  }, [selectedCustomer]);

  const steps = ["1. Välj kund", "2. Hantera Scrive-credential"];

  const onSubmit = () => {
    if (existingCredential) {
      editExistingCredentials();
    } else {
      submitNewCredential();
    }
  };

  const selectStep = () => (
    <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Välj kund</OverviewTitle>
          <OverviewSubtitle>
            Klicka på en kund för att gå till nästa steg
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InternalCustomerTable
        hideColumns
        hideExport
        hideTitle
        onRowClicked={handleSelectedCustomer}
      />
    </DetailPageBox>
  );

  const connectStep = () => (
    <>
      <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
        <TextButton
          extraStyle={{ marginBottom: 8 }}
          title="Gå tillbaka"
          iconType="arrow-back"
          clicked={() => {
            setSelectedCustomer(null);
            setExistingCredential(false);
            setEditExisting(false);
            setEmail("");
            setPassword("");
            setStep(STEPS.SELECT_CUSTOMER);
          }}
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Hantera Scrive-credential för{" "}
              {selectedCustomer?.str_representation}
            </OverviewTitle>
            <OverviewSubtitle>
              Lägg till eller redigera Scrive-kontot som är kopplat till denna
              kund
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {existingCredential && !editExisting ? (
          <>
            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Scrive redan uppsatt</OverviewTitle>
              </OverviewTitleWrapper>

              <TextButton
                title="Redigera Scrive-credentials"
                iconType="edit"
                iconPlacement="right"
                clicked={() => setEditExisting(true)}
              />
            </InnerBox>
          </>
        ) : (
          <>
            <InnerBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Ange Scrive-credentials</OverviewTitle>
              </OverviewTitleWrapper>

              <NonConnectedInputField
                id="scrive-credential-email"
                title="E-post"
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
              />

              <NonConnectedInputField
                id="scrive-credential-pass"
                title="Lösenord"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
              />
            </InnerBox>
          </>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            minWidth: 200,
            marginTop: 12,
          }}
        >
          <PrimaryButton
            title="Spara"
            disabled={!email || !password}
            clicked={onSubmit}
          />
        </div>
      </DetailPageBox>
    </>
  );

  return (
    <DetailInnerWrapper>
      {loading && <OverlaySpinner />}
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Avbryt koppling "
        iconType="arrow-back"
        clicked={() => goBack()}
      />
      <StepDisplay steps={steps} activeIndex={step - 1} />

      {step === STEPS.SELECT_CUSTOMER && <>{selectStep()}</>}
      {step === STEPS.CONNECT && <>{connectStep()}</>}
    </DetailInnerWrapper>
  );
}
