export default [
  {
    title: "Dokument",
    key: "DOCUMENT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "category",
      "content_type",
      "object_id",
      "doc",
      "docData_",
      "realestate",
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
