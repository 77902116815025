import * as React from "react";
import { useParams, useHistory } from "react-router";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { EmailTag, LinkedObject, PhoneTag } from "../../../components/Displays";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { buildQueryString, setActiveFormInstance } from "../../../store/base";
import { useDebtorInvoicing } from "../../../store/invoicingDebtor";
import DebtorInvoiceForm from "../../../components/Forms/InvoicingDebtor/ChapterForm/ModalForm";
import { useCustomer } from "../../../store/customers";
import { useDispatch } from "react-redux";
import { constants as invoicingDebtorConstants } from "../../../store/invoicingDebtor";
import InvoicingErrorTable from "../../../components/Lists/InvoicingErrors/FullTable";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import useQuery from "../../../components/utils/useQuery";
import { detailUrl } from "../../../store/invoicingCustomDimensionEntries";
import { useInvoicesFromMultipleSearchResults } from "../../../store/billectaSearch";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ContractViewInvoicesTable from "../../../components/Billecta/Invoices/ContractViewInvoicesTable";
import { invoiceDetailUrl } from "../../../store/billectaInvoicing";
import DebtorAccountBalance from "./AccountBalance";

export default function CustomerInvoicing() {
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const { customerId } = useParams();
  const { push } = useHistory();

  const query = useQuery();
  const openSettings = query.get("opensettings") == "true";

  const [customer] = useCustomer(customerId);

  const [debtorInvoiceConfig, debtorInvoiceConfigLoading] = useDebtorInvoicing(
    customer?.debtor_invoice_config?.id
  );

  const customerBillectaIds = debtorInvoiceConfig?.billecta_ids?.map((bid) => ({
    creditorId: bid.creditor_id,
    debtorId: bid.object_id,
  }));

  const searchInstances = customerBillectaIds?.map((tbi) => {
    const params = {
      CreditorPublicId: tbi.creditorId,
      DebtorPublicIds: [tbi.debtorId],
    };

    return params;
  });

  const [invoices, invoicesLoading] = useInvoicesFromMultipleSearchResults({
    searchInstances,
    searchId: customer?.debtor_invoice_config?.id,
    typeConstants: invoicingDebtorConstants,
    errorCallback: () => {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde inte hämta avier för kund",
          description: "Kontakta Pigello om problemet kvarstår",
        })
      );
    },
  });

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      debtorinvoiceconfig: customer?.debtor_invoice_config?.id || "",
    },
  });

  const errorQuery = buildQueryString({
    debtorinvoiceconfig: customer?.debtor_invoice_config?.id,
  });

  const errorFilter = (obj) => {
    return (
      (obj.initiator_content_type === "accounting.debtorinvoiceconfig" &&
        obj.initiator_object?.id == customer?.debtor_invoice_config?.id) ||
      (obj?.error?.initiator_content_type ===
        "accounting.debtorinvoiceconfig" &&
        obj?.error?.initiator_object?.id == customer?.debtor_invoice_config?.id)
    );
  };

  const openCreateDebtorInvoiceForm = () => {
    const initialData = {
      customer: { id: customerId },
      email_invoice: customer?.email,
      your_reference: customer?.corporate_name
        ? customer.corporate_name
        : `${customer?.first_name || ""} ${customer?.last_name || ""}`,
    };

    dispatch(
      setActiveFormInstance({
        storeName: invoicingDebtorConstants.STORE_NAME,
        data: initialData,
      })
    );

    setCreateOpen(true);
  };

  React.useEffect(() => {
    if (openSettings) {
      openCreateDebtorInvoiceForm();
    }
  }, [openSettings]);

  const handleInvoiceRowClicked = (row) => {
    const { original } = row;
    push(
      invoiceDetailUrl({
        invoiceId: original.ActionPublicId,
        creditorId: original.CreditorPublicId,
      })
    );
  };

  return (
    <>
      <DebtorInvoiceForm
        method="PATCH"
        id={debtorInvoiceConfig?.id}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        instance={debtorInvoiceConfig}
      />

      <DebtorInvoiceForm
        method="POST"
        isOpen={createOpen}
        onCheckout={() => setCreateOpen(false)}
      />

      <DetailInnerWrapper>
        {errorCount > 0 && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle small>Felmeddelanden</OverviewTitle>
            </OverviewTitleWrapper>

            <InvoicingErrorTable
              hideTitle
              hideColumns
              hideFilters
              hideSearch
              hideExport
              persistantFilterMethod={errorFilter}
              persistantQueryString={errorQuery}
            />
          </DetailPageBox>
        )}

        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{
              flex: 3,
              alignSelf: "flex-start",
              maxWidth:
                debtorInvoiceConfig?.billecta_ids?.length > 0 ? "70%" : "100%",
            }}
          >
            {debtorInvoiceConfigLoading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitle>Aviseringsinställning</OverviewTitle>

              {debtorInvoiceConfig && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            {!debtorInvoiceConfig ? (
              <div
                style={{
                  width: "100%",
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PrimaryButton
                  title="Sätt upp aviseringsinställning"
                  clicked={openCreateDebtorInvoiceForm}
                />
              </div>
            ) : (
              <>
                <DetailInfo
                  infoObj={getInfoObj({
                    debtor: debtorInvoiceConfig,
                  })}
                />
              </>
            )}
          </DetailPageBox>

          {debtorInvoiceConfig?.billecta_ids?.length > 0 && (
            <div
              style={{
                flex: 1,
                minWidth: "25%",
                alignSelf: "flex-start",
                flexDirection: "column",
                display: "flex",
              }}
            >
              {debtorInvoiceConfig?.billecta_ids?.map((billectaObj) => (
                <DebtorAccountBalance {...{ billectaObj, customer }} />
              ))}
            </div>
          )}
        </DetailPageBoxFlexWrapper>

        {customerBillectaIds?.length > 0 && (
          <DetailPageBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>Fakturor till kund</OverviewTitle>
            </OverviewTitleWrapper>

            {invoicesLoading && <OverlaySpinner />}

            {invoices?.length ? (
              <ContractViewInvoicesTable
                invoices={invoices}
                handleRowClicked={handleInvoiceRowClicked}
              />
            ) : (
              <BodyText>
                {invoicesLoading
                  ? "Laddar avier..."
                  : "Inga avier har genererats än för denna kund"}
              </BodyText>
            )}
          </DetailPageBox>
        )}
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ debtor }) {
  const infoObj = {
    Faktureringsinfo: [
      {
        title: "Fakturaadress",
        value: debtor.invoice_address?.base?.split(",")?.[0] || "-",
      },
      {
        title: "Fakturaadress postnr.",
        value: debtor.invoice_address?.postal_code || "-",
      },
      {
        title: "Fakturaadress stad",
        value: debtor.invoice_address?.city || "-",
      },
      {
        title: "Fakturaadress land",
        value: debtor.invoice_address?.country || "-",
      },
      {
        title: "c/o",
        value: debtor.care_of || "-",
      },
    ],
    "Leverans av faktura": [
      {
        title: "Leveransmetod",
        value: debtor.delivery_method_display || "Ej specificerad",
      },
      {
        title: "Kundens referens",
        value: debtor.your_reference || "-",
      },
      { title: "E-post", value: <EmailTag mail={debtor?.email_invoice} /> },
      {
        title: "E-post CC",
        value: <EmailTag mail={debtor?.email_invoice_cc} />,
      },
      {
        title: "E-post BCC",
        value: <EmailTag mail={debtor?.email_invoice_bcc} />,
      },
      { title: "Telefon", value: <PhoneTag phone={debtor?.invoice_phone} /> },
    ],

    Betalmetoder: [
      {
        title: "Autogiro clearing",
        value: debtor.autogiro_clearing || "Ej uppsatt",
      },
      {
        title: "Autogironummer",
        value: debtor.autogiro_number || "Ej uppsatt",
      },
      {
        title: "Autogiroleverantör",
        value: debtor.autogiro_payment_service || "Ej uppsatt",
      },
    ],

    Bokföring: [
      {
        title: "Anpassad dimension",
        value: (
          <LinkedObject
            obj={debtor?.additional_dimension}
            urlMethod={detailUrl}
          />
        ),
      },
    ],
  };

  infoObj["Faktureringsinfo"].push({
    title: "VAT-nummer",
    value: debtor.vat || "-",
  });

  infoObj["Betalmetoder"].push({
    title: "Intermediator",
    value: debtor.intermediator_display || "Ej uppsatt",
  });

  infoObj["Betalmetoder"].push({
    title: "GLN",
    value: debtor.gln || "Ej uppsatt",
  });

  return infoObj;
}
