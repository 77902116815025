import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { usePermissionCheck } from "../../store/base";
import { useBasicDoc, constants } from "../../store/basicDocs";
import Document from "./detail/Document";

export default function MainDocumentDetail() {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const { path, url } = useRouteMatch();
  const { replace } = useHistory();
  const { basicDocId } = useParams();

  const [basicDoc] = useBasicDoc(basicDocId);
  const canDelete = usePermissionCheck("delete_can_baseobject");

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Dokument",
      component: Document,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
    });
  }

  return (
    <>
      {canDelete && (
        <DeleteModal
          isOpen={deleteModalOpen}
          instance={basicDoc}
          closeFunction={() => setDeleteModalOpen(false)}
          constants={constants}
          deletedCallback={() => replace("/documents")}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Dokument ${basicDoc?.str_representation || "Laddar..."}`}
          breadCrumbs={[
            { url: "/documents", label: "Dokument" },
            { label: basicDoc?.str_representation || "Laddar..." },
          ]}
          eventContentType="standard.basicdoc"
          eventObjectId={basicDocId}
          eventIdQueryStr="basicdoc"
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
