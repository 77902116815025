import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import { buildQueryString } from "../../../../store/base";
import { useFilteredCustomers } from "../../../../store/customers";
import {
  constants,
  createManualInvoice,
  getManualInvoiceProposal,
  getAllInvoices,
} from "../../../../store/billectaInvoicing";
import { useManualInvoiceForm } from "../../../../store/billectaInvoicing/hooks/form";
import { getInvoicePreview } from "../../../../store/billectaInvoicing/store/actions";

import CreateManualInvoiceForm from "./CreateManualInvoiceForm";
import StandardModal from "../../../Modals/StandardModal";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import TenantsTable from "../TenantsTable";
import SelectPeriod from "./SelectPeriod";
import moment from "moment";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { useDebtorInvoicing } from "../../../../store/invoicingDebtor";
import { useFilteredCompanies } from "../../../../store/companies";
import { useCompanyInvoicing } from "../../../../store/invoicingCompany";
import { InfoBox } from "../../../Displays";

const STEPS = {
  SELECT_CUSTOMER: "SELECT_CUSTOMER",
  SELECT_PERIOD: "SELECT_PERIOD",
  REVIEW_INVOICE: "REVIEW_INVOICES",
};

export default function CreateManualInvoice({ open, closeFunction }) {
  const dispatch = useDispatch();
  const { creditorId } = useParams();

  const [selectedCustomerId, setSelectedCustomerId] = React.useState(null);
  const [step, setStep] = React.useState(STEPS.SELECT_CUSTOMER);
  const [loading, setLoading] = React.useState(false);

  const [period, setPeriod] = React.useState({});
  const [periodError, setPeriodError] = React.useState(null);
  const [hasValidationError, setHasValidationError] = React.useState(false);
  const [hasRequiredMissing, setHasRequiredMissing] = React.useState(false);

  const invoice = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });
  const [companies] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];
  const [companyInvoiceConfig] = useCompanyInvoicing(
    company?.invoicing_details?.id
  );

  const [customers] = useFilteredCustomers("");

  const manualInvoiceProposal = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );

  const selectedCustomer =
    selectedCustomerId && customers.find((t) => t.id === selectedCustomerId);

  const [selectedDebtorConfig] = useDebtorInvoicing(
    selectedCustomer?.debtor_invoice_config?.id
  );

  React.useEffect(() => {
    if (!open) {
      setStep(STEPS.SELECT_CUSTOMER);
      setSelectedCustomerId(null);
      setLoading(false);
      setPeriod({});

      dispatch({
        type: constants.DESTROY_FORM,
        payload: {
          // don't reset paginations
          success: false,
        },
      });
    }
  }, [open]);

  const form = Boolean(useManualInvoiceForm("POST", selectedCustomer));

  React.useEffect(() => {
    if (form && Boolean(Object.keys(manualInvoiceProposal || {}).length)) {
      setLoading(false);
    }
  }, [form, manualInvoiceProposal]);

  React.useEffect(() => {
    if (
      Object.keys(period).length &&
      period["start_date"] !== undefined &&
      period["end_date"] !== undefined
    ) {
      const startDate = moment(period.start_date);
      const endDate = moment(period.end_date);

      if (startDate.isBefore(endDate)) {
        setPeriodError(null);
      } else {
        setPeriodError("Startdatumet måste vara innan slutdatumet");
      }
    }
  }, [period]);

  const handleValidationError = React.useCallback((validationError) => {
    if (Object.keys(validationError).length) {
      setHasValidationError(true);
    } else {
      setHasValidationError(false);
    }
  }, []);

  const handleRequiredMissing = React.useCallback((requiredMissing) => {
    if (requiredMissing.length) {
      setHasRequiredMissing(true);
    } else {
      setHasRequiredMissing(false);
    }
  }, []);

  const invoicesCreatedCallback = () => {
    dispatch(getAllInvoices(creditorId));
    closeFunction();
  };

  const handleCreatedErrorCallback = (_, returnedData) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Något gick fel",
        description: returnedData?.Message || "Fakturan kunde inte skapas",
      })
    );

    setLoading(false);
  };

  const previewInvoice = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Hämtar förhandsgranskning...",
      })
    );
    dispatch(getInvoicePreview({ postObj: cloneDeep(invoice), company }));
  };

  const handleSaveClicked = () => {
    if (step === STEPS.SELECT_CUSTOMER) {
      setLoading(true);
      setStep(STEPS.SELECT_PERIOD);
      dispatch(
        getManualInvoiceProposal({
          creditorId,
          selectedDebtorConfig,
          companyInvoiceConfig,
        })
      );
    } else if (step === STEPS.SELECT_PERIOD) {
      setStep(STEPS.REVIEW_INVOICE);
    } else {
      setLoading(true);
      // attempt to create invoice
      dispatch(
        createManualInvoice({
          successCallback: invoicesCreatedCallback,
          errorCallback: handleCreatedErrorCallback,
          company,
        })
      );
    }
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomerId(customer.id);
  };

  const renderContent = () => {
    if (step === STEPS.SELECT_CUSTOMER) {
      return (
        <>
          <InfoBox
            boxTheme="yellow"
            title="OBS"
            text={`Endast kunder som är konfigurerade för fakturering visas här. Om kunden som ska faktureras ej hittas i listan, leta upp den under "Förvaltningskunder" och sätt upp faktureringsinställningar för kunden först.`}
          />

          <TenantsTable
            tenants={customers?.filter(
              (t) => t.debtor_invoice_config?.id != null
            )}
            selectTenant={handleSelectCustomer}
          />
        </>
      );
    } else if (step === STEPS.SELECT_PERIOD) {
      return (
        <SelectPeriod
          error={periodError}
          period={period}
          setPeriod={setPeriod}
        />
      );
    } else {
      return (
        <CreateManualInvoiceForm
          selectedTenant={selectedCustomer}
          selectedDebtorConfig={selectedDebtorConfig}
          companyInvoiceConfig={companyInvoiceConfig}
          company={company}
          onValidationError={handleValidationError}
          onRequiredMissing={handleRequiredMissing}
          period={period}
        />
      );
    }
  };

  const acceptTitle =
    step === STEPS.SELECT_CUSTOMER
      ? "Gå vidare"
      : step === STEPS.SELECT_PERIOD
      ? "Välj period"
      : "Skapa faktura";

  const canAccept = () => {
    if (step === STEPS.SELECT_CUSTOMER) {
      return !!selectedCustomer;
    }

    if (step === STEPS.SELECT_PERIOD) {
      // if we have a date set, check so that the start date is before the end date
      if (periodError || !period.start_date || !period.end_date) {
        return false;
      } else {
        return true;
      }
    }

    if (step === STEPS.REVIEW_INVOICE) {
      return !!invoice && !hasValidationError && !hasRequiredMissing;
    }
  };

  const nextButtonEnabled = canAccept();

  return (
    <StandardModal
      isOpen={open}
      title={
        step === STEPS.SELECT_CUSTOMER
          ? "Välj kund som ska faktureras"
          : step === STEPS.SELECT_PERIOD
          ? "Välj period"
          : "Skapa faktura"
      }
      closeFunction={closeFunction}
      saveFunction={handleSaveClicked}
      large
      actionBarAcceptTitle={acceptTitle}
      withActionBar={true}
      canAccept={nextButtonEnabled}
      headerAction={
        step === STEPS.SELECT_CUSTOMER || step === STEPS.SELECT_PERIOD
          ? null
          : nextButtonEnabled
          ? previewInvoice
          : null
      }
      headerActionTitle={
        step === STEPS.SELECT_CUSTOMER
          ? null
          : nextButtonEnabled
          ? "Förhandsgranska faktura"
          : null
      }
    >
      {!loading && renderContent()}
      {loading && <OverlaySpinner />}
    </StandardModal>
  );
}
