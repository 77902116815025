import styled, { css } from "styled-components";
import arrowIcon from "../../../assets/svg/play.svg";
import moveIcon from "../../../assets/svg/move.svg";
import editIcon from "../../../assets/svg/edit.svg";
import checkMark from "../../../assets/svg/check_white.svg";
import fastDocLogo from "../../../assets/svg/fastdoc.svg";

export const DocumentWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
`;

export const PagePickerWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #f9fbfd;
  border-top: thin solid rgba(0, 0, 0, 0.2);
  padding: 8px 80px;

  justify-content: space-between;
  align-items: center;
`;

export const DocumentButtonsWrapper = styled.div`
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
  padding: 8px;
  display: flex;
  width: 100%;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
`;

export const PageButton = styled.button`
  border: 0;
  position: relative;
  color: ${(p) => p.theme.colors.primary};
  background-color: rgba(0, 0, 0, 0);
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0;
  position: relative;
  text-decoration: 0;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: no-wrap;

  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${arrowIcon});

  ${(p) =>
    p.isPrevious &&
    css`
      transform: rotate(180deg);
    `}

  &:disabled {
    visibility: hidden;
  }
`;

export const PageIndicator = styled.div`
  color: ${(p) => p.theme.colors.primary};
  font-weight: 600;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const CardWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
`;

export const Card = styled.div`
  padding: 20px 24px 50px 24px;
  background-color: rgba(256, 256, 256, 0.95);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
  border: thin solid ${(p) => p.theme.colors.gray1};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  max-width: 1100px;
`;

export const Title = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.primary};
`;

export const AttributeBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 400;
`;

export const PDFInputField = styled.input`
  background-color: rgba(255, 255, 255, 0.2);
  border: 3px solid black;
  border-radius: 3px;
  padding: 2px;
  position: relative;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-appearance: textfield;
  &:required {
    box-shadow: none;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;

  ${(p) =>
    p.borderColor &&
    css`
      border-color: ${p.borderColor};
    `}

  ${(p) =>
    p.displayOnlyMode &&
    css`
      background-color: rgba(0, 0, 0, 0);
      border: none;
    `}
`;

export const PDFInputFieldWrapperWithCrossHair = styled.div`
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  min-height: ${(p) => (p.lockedMinSize ? "22px" : "30px")};
  max-height: ${(p) => (p.noMaxHeight ? "" : "38px")};
  min-width: ${(p) => (p.lockedMinSize ? "22px" : "100px")};
  max-width: ${(p) => (p.lockedMinSize ? "22px" : "300px")};
  position: relative;
  padding: 0 2px 2px 0;
  overflow: ${(p) => (p.lockedMinSize ? "none" : "auto")};

  ${(p) =>
    p.hasErr &&
    css`
      color: red;
      background-color: red;
      border: 1px solid red;
    `}
`;

export const Move = styled.div`
  cursor: grab;
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${moveIcon});
  position: absolute;

  z-index: 99;

  ${(p) =>
    p.moveIsLeftMounted
      ? css`
          left: -12px;
        `
      : css`
          right: -12px;
        `}

  ${(p) =>
    p.moveIsTopMounted
      ? css`
          top: -12px;
        `
      : css`
          bottom: -12px;
        `}
`;

export const Edit = styled.div`
  cursor: pointer;
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${editIcon});
  position: absolute;

  z-index: 99;

  ${(p) =>
    p.moveIsLeftMounted
      ? css`
          left: -12px;
        `
      : css`
          right: -12px;
        `}

  ${(p) =>
    p.moveIsTopMounted
      ? css`
          bottom: -12px;
        `
      : css`
          top: -12px;
        `}
`;

export const CrossHair = styled.div`
  width: 3px;
  height: 3px;
  background-color: #000;
  position: absolute;
  top: calc(50% - 2px);
  left: -1px;

  &:after {
    position: absolute;
    content: "";
    height: 200vh;
    width: 1px;
    background-color: #000;
    bottom: -100vh;
    right: 100%;
  }

  &::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 200vw;
    background-color: #000;
    bottom: 100%;
    right: -100vw;
  }
`;

export const PDFCheckBox = styled.input.attrs({
  type: "checkbox",
})`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: ${(p) => (p.small ? "22px" : "32px")};
    cursor: pointer;
    line-height: ${(p) => (p.small ? "18px" : "26px")};
    display: inline-block;
    color: ${(p) => p.theme.colors.black};

    ${(p) =>
      p.small &&
      css`
        font-size: 0.8rem;
      `}
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: -16px;
    width: ${(p) => (p.small ? "18px" : "26px")};
    height: ${(p) => (p.small ? "18px" : "26px")};
    border: 1px solid #ddd;
    border-radius: 3px;
    background: #fff;
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: ${(p) => (p.small ? "18px" : "26px")};
    height: ${(p) => (p.small ? "18px" : "26px")};
    background-color: ${(p) => p.theme.colors.blueDark};
    position: absolute;
    top: -16px;
    left: 0px;
    border-radius: 3px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  &:checked + label:after {
    background-image: url(${checkMark});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

export const PDFRadio = styled.input.attrs({
  type: "radio",
})`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: ${(p) => (p.small ? "22px" : "32px")};
    cursor: pointer;
    line-height: ${(p) => (p.small ? "18px" : "26px")};
    display: inline-block;
    color: ${(p) => p.theme.colors.black};

    ${(p) =>
      p.small &&
      css`
        font-size: 0.8rem;
      `}
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: -16px;
    width: ${(p) => (p.small ? "18px" : "26px")};
    height: ${(p) => (p.small ? "18px" : "26px")};
    border: 1px solid #ddd;
    border-radius: 50%;
    background: #fff;
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: ${(p) => (p.small ? "18px" : "26px")};
    height: ${(p) => (p.small ? "18px" : "26px")};
    background-color: ${(p) => p.theme.colors.blueDark};
    position: absolute;
    top: -16px;
    left: 0px;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  &:checked + label:after {
    background-image: url(${checkMark});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

export const FastDocLogo = styled.div`
  height: 120px;
  width: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${fastDocLogo});
`;

export const DisplayName = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  font-weight: 600;
  font-size: 0.8rem;
  color: ${(p) => p.theme.colors.primary};
`;
