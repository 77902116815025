import * as React from "react";

import { Tenant, Confirm } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "MANAGEMENT_CUSTOMER":
      return <Tenant method={method} />;
    default:
      return <Confirm {...{ method }} />;
  }
};
