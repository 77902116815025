import * as React from 'react';
// style, design
import { FormAreaTitle } from '../../../Base/Chapters/styles';
import {
  TextInput,
  PositiveNumber,
  Checkbox,
  Select,
} from '../../../Base/Fields';

// store, state
import { constants } from '../../../../../store/editabledocs';
import { useFormInstanceField } from '../../../../../store/base';

export default () => {
  const storeName = constants.STORE_NAME;
  const method = "POST";

  const bankIdChecked = useFormInstanceField({storeName, fieldKey:"swedish_bank_id"})

  return (
    <>
      <FormAreaTitle>BankID relaterad information</FormAreaTitle>
      <>
      <Checkbox
      storeName={storeName}
      method={method}
      fieldKey={"swedish_bank_id"}
      />
      {bankIdChecked ? 
      <>
      <TextInput
      storeName={storeName}
      method={method}
      fieldKey={"description"}
      />
      <PositiveNumber
      storeName={storeName}
      method={method}
      fieldKey={"revenue"}
      />
      <TextInput
      storeName={storeName}
      method={method}
      fieldKey={"owners_personal_number"}
      />
      <PositiveNumber
      storeName={storeName}
      method={method}
      fieldKey={"employee_amount"}
      />
      <Checkbox
      storeName={storeName}
      method={method}
      fieldKey={"bankid_terms"}
      />
      <Select
      storeName={storeName}
      method={method}
      fieldKey={"authentications_month"}
      />
      <Select
      storeName={storeName}
      method={method}
      fieldKey={"sign_month"}
      />
      <TextInput
      storeName={storeName}
      method={method}
      fieldKey={"bank_id_name"}
      />
      <TextInput
      storeName={storeName}
      method={method}
      fieldKey={"document_types"}
      />
      </>
      : null}
      </>
    </>
  );
};
