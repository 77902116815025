import * as React from "react";
import { useHistory } from "react-router";
import * as SC from "./styles";

export default ({ noBorder, borderRight }) => {
  const { push } = useHistory();
  const goHome = () => {
    push("/");
  };

  return (
    <SC.MiniLogoWrapper onClick={goHome} {...{ noBorder, borderRight }}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 279.0844 279.0844"
      >
        <g id="Logo">
          <circle
            cx="139.5422"
            cy="139.5422"
            r="139.5422"
            style={{ fill: "#fff" }}
          />
          <g id="P">
            <g id="LINE">
              <polygon
                points="215.49 111.23 122.752 164.773 122.752 184.16 215.49 130.618 215.49 111.23"
                style={{ fill: "#202225" }}
              />
            </g>
            <g id="LINE-2" data-name="LINE">
              <path
                d="M156.332,198.9274,198.7,174.4661a33.58,33.58,0,0,0,16.79-29.0808l-92.7378,53.5421A33.5794,33.5794,0,0,0,156.332,198.9274Z"
                style={{ fill: "#202225" }}
              />
            </g>
            <g id="LINE-3" data-name="LINE">
              <path
                d="M215.49,96.4627A33.58,33.58,0,0,0,198.7,67.3818L156.332,42.9205a33.58,33.58,0,0,0-33.58,0Z"
                style={{ fill: "#202225" }}
              />
            </g>
            <g id="LINE-4" data-name="LINE">
              <polygon
                points="109.963 50.304 93.173 59.998 185.911 113.54 202.701 103.847 109.963 50.304"
                style={{ fill: "#202225" }}
              />
            </g>
            <g id="LINE-5" data-name="LINE">
              <polygon
                points="109.963 252.545 93.173 242.852 93.173 74.766 109.963 84.459 109.963 252.545"
                style={{ fill: "#202225" }}
              />
            </g>
            <g id="LINE-6" data-name="LINE">
              <path
                d="M80.3842,67.3818a33.58,33.58,0,0,0-16.79,29.0809V206.387a33.58,33.58,0,0,0,16.79,29.0809Z"
                style={{ fill: "#202225" }}
              />
            </g>
          </g>
        </g>
      </svg>
    </SC.MiniLogoWrapper>
  );
};
