import * as React from "react";
// style, design
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Select, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingProducts";

export const OK_CATEGORIES = [13, 21, 23, 24, 25, 27, 28, 29];

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Produkt</FormAreaTitle>

      <TextInput
        title="Titel"
        {...{
          storeName,
          method,
          fieldKey: `title`,
        }}
      />

      <DoubleFieldWrapper>
        <Select
          extraStyles={{ minWidth: "290px", marginRight: 20 }}
          title="Kategori"
          description="Typ av kostnad"
          filterChoices={(option) => {
            return OK_CATEGORIES.includes(option.v);
          }}
          {...{
            storeName,
            method,
            fieldKey: `category`,
          }}
        />

        <Select
          extraStyles={{ minWidth: "290px" }}
          title="Standard momssats"
          description="Standardmoms för denna produkt"
          {...{
            storeName,
            method,
            fieldKey: `vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Intäktskonto"
          description="3000 kontot är standardkontot för intäkter, om du vill ha ett specifikt konto för vissa produkter kan du välja det här"
          {...{
            storeName,
            method,
            fieldKey: `account_no`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="Utgående moms"
          description="Ange momskonto om du vill att försäljningen/kostnaden ska bokföras på annat momskonto än vad som angetts i bokföringsinställningar. Lämna blankt om du vill använda momskontot som anges i bokföringsinställningar."
          {...{
            storeName,
            method,
            fieldKey: `account_no_vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Konto försäljning EU"
          {...{
            storeName,
            method,
            fieldKey: `account_no_eu`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="Konto försäljning EU Moms"
          {...{
            storeName,
            method,
            fieldKey: `account_no_eu_vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Konto köp"
          {...{
            storeName,
            method,
            fieldKey: `account_no_purchase`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="Konto köp Moms"
          {...{
            storeName,
            method,
            fieldKey: `account_no_purchase_vat`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          title="Konto försäljning icke-EU"
          {...{
            storeName,
            method,
            fieldKey: `account_no_non_eu`,
          }}
        />
      </DoubleFieldWrapper>
    </>
  );
};
