import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { destroyForm, updateActiveFormInstance } from "../../../store/base";
import { constants } from "../../../store/billectaIntegrations";
import { usePEForm } from "../../../store/billectaIntegrations/hooks/form";
import { updatePEAccountingSettings } from "../../../store/billectaIntegrations/store/actions";
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import { DatePicker, TextInput } from "../../Forms/Base/Fields";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";

export default function PEIntegrationForm({ open, closeFunction, creditorId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const peSettings = useSelector(
    (state) => state.billectaIntegrations.peSettings
  );

  usePEForm("PUT", open);

  React.useEffect(() => {
    if (open) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: peSettings,
        })
      );
    } else {
      dispatch(
        destroyForm({
          constants,
          method: "PUT",
          success: false,
        })
      );
    }
  }, [open]);

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      updatePEAccountingSettings({
        creditorId,
        successCallback: () => {
          closeFunction();
          setLoading(false);
        },
      })
    );
  };

  return (
    <StandardModal
      isOpen={open}
      closeFunction={closeFunction}
      withActionBar
      actionBarAcceptTitle="Aktivera integration"
      title="Integration - PE Accounting"
      saveFunction={handleSubmit}
    >
      {loading && <OverlaySpinner />}

      <FormAreaTitle>PE Accounting</FormAreaTitle>
      <FormAreaDescription>
        Integrationen PE Accounting synkar bokföringsuppgifter baserat på avier
        skickat genom Pigello mot PE Accounting
      </FormAreaDescription>

      <TextInput
        storeName={constants.STORE_NAME}
        fieldKey={"CompanyId"}
        method={"PUT"}
      />

      <TextInput
        storeName={constants.STORE_NAME}
        fieldKey={"AccessToken"}
        method={"PUT"}
      />

      <DatePicker
        storeName={constants.STORE_NAME}
        fieldKey={"StartDate"}
        method={"PUT"}
      />

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={constants.STORE_NAME}
          fieldKey={"PaymentVoucherSeries"}
          method={"PUT"}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          storeName={constants.STORE_NAME}
          fieldKey={"ProjectDimensionId"}
          method={"PUT"}
        />
      </DoubleFieldWrapper>
    </StandardModal>
  );
}
