import * as React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { insertIntoAll, usePermissionCheck } from "../../../../store/base";
import {
  performScriveAction,
  SCRIVE_ACTIONS,
} from "../../../../store/editabledocs/store/actions";
import PopupButton from "../../../Forms/Base/Buttons/PopupButton";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import { isCancelSigning } from "../../../../store/managementContracts";
import { useCanUseScrive } from "../../../../store/editabledocs/hooks/retrieve";
import NonConnectedTextInput from "../../../Forms/Base/Old/NonConnected/NonConnectedTextInput";

export default ({ contract, cancelledDoc, constants }) => {
  const dispatch = useDispatch();

  const scrivePermission = usePermissionCheck("allow_scrive");

  const [confirmSignRestartOpen, setConfirmSignRestartOpen] =
    React.useState(false);
  const [confirmSignCancelOpen, setConfirmSignCancelOpen] =
    React.useState(false);
  const [confirmProlongOpen, setConfirmProlongOpen] = React.useState(false);
  const [confirmRemindOpen, setConfirmRemindOpen] = React.useState(false);
  const [confirmAutoRemindOpen, setConfirmAutoRemindOpen] =
    React.useState(false);
  const [prolongDays, setProlongDays] = React.useState(1);
  const [autoRemindDays, setAutoRemindDays] = React.useState(1);

  const canPerformCancelledDocActions =
    !contract.closed_signed && isCancelSigning(contract);

  const [canUseScrive, isLoadingCanUseScrive] = useCanUseScrive();
  const hasDoc = cancelledDoc?.doc;
  const isPending = cancelledDoc?.status === "pending";
  const timedOut = cancelledDoc?.status === "timedout";
  const isDigitalSign = contract.uses_cancellation_e_signing;

  const onScriveActionPerformed = (_, returned) => {
    // update the contract's editabledock
    const data = { ...contract, cancelled_doc: returned };
    dispatch(insertIntoAll(constants, data));
  };

  const onConfirmCancel = () => {
    dispatch(
      performScriveAction({
        id: cancelledDoc.id,
        action: SCRIVE_ACTIONS.CANCEL,
        successCallback: onScriveActionPerformed,
      })
    );
    setConfirmSignCancelOpen(false);
  };

  const onConfirmRestart = () => {
    dispatch(
      performScriveAction({
        id: cancelledDoc.id,
        action: SCRIVE_ACTIONS.RESTART,
        successCallback: onScriveActionPerformed,
      })
    );
    setConfirmSignRestartOpen(false);
  };
  const onConfirmProlong = () => {
    dispatch(
      performScriveAction({
        id: cancelledDoc.id,
        data: { days: prolongDays },
        action: SCRIVE_ACTIONS.PROLONG,
        successCallback: onScriveActionPerformed,
      })
    );
    setConfirmProlongOpen(false);
  };
  const onConfirmAutoRemind = () => {
    dispatch(
      performScriveAction({
        id: cancelledDoc.id,
        data: { days: autoRemindDays },
        action: SCRIVE_ACTIONS.SET_AUTO_REMINDER,
        successCallback: onScriveActionPerformed,
      })
    );
    setConfirmAutoRemindOpen(false);
  };

  const onConfirmRemind = () => {
    // doesn't alter data, no succescallback
    dispatch(
      performScriveAction({
        id: cancelledDoc.id,
        action: SCRIVE_ACTIONS.REMIND,
      })
    );
    setConfirmRemindOpen(false);
  };

  const canCancel =
    canUseScrive && isDigitalSign && canPerformCancelledDocActions && isPending;

  const canRestart =
    canUseScrive &&
    isDigitalSign &&
    canPerformCancelledDocActions &&
    !["pending", "closed", "preparation"].includes(cancelledDoc?.status);

  const canProlong =
    canUseScrive && isDigitalSign && canPerformCancelledDocActions && timedOut;

  const canRemind =
    canUseScrive && isDigitalSign && canPerformCancelledDocActions && isPending;

  const canSetAutomaticReminder =
    canUseScrive && isDigitalSign && canPerformCancelledDocActions && isPending;

  let signActions = [];

  if (canRestart)
    signActions.push({
      name: "Starta om process",
      onClick: () => setConfirmSignRestartOpen(true),
    });
  if (canProlong)
    signActions.push({
      name: "Förläng signering",
      onClick: () => setConfirmProlongOpen(true),
    });
  if (canRemind)
    signActions.push({
      name: "Skicka påminnelse",
      onClick: () => setConfirmRemindOpen(true),
    });
  if (canSetAutomaticReminder)
    signActions.push({
      name: "Ställ in påminnelse",
      onClick: () => setConfirmAutoRemindOpen(true),
    });

  if (canCancel)
    signActions.push({
      name: "Avbryt signering",
      onClick: () => setConfirmSignCancelOpen(true),
    });

  if (!hasDoc || !scrivePermission) return null;

  return (
    <>
      <ConfirmationModal
        isOpen={confirmSignCancelOpen}
        closeFunction={() => setConfirmSignCancelOpen(false)}
        acceptCallback={() => onConfirmCancel()}
        withActionBar
        newDesign
        title=" Är du säker på att du vill avbryta signeringsprocessen för avtalet?"
      />

      <ConfirmationModal
        isOpen={confirmSignRestartOpen}
        closeFunction={() => setConfirmSignRestartOpen(false)}
        acceptCallback={() => onConfirmRestart()}
        withActionBar
        newDesign
        title="Är du säker på att du vill starta om signeringsprocessen för
          avtalet?"
      />

      <ConfirmationModal
        isOpen={confirmRemindOpen}
        closeFunction={() => setConfirmRemindOpen(false)}
        acceptCallback={() => onConfirmRemind()}
        withActionBar
        newDesign
        title="Är du säker på att du vill skicka ut signeringspåmminnelser?"
        renderContent={() => (
          <>
            <span>Följande parter är delaktiga i signeringen:</span>
            <br></br>
            {(cancelledDoc?.parties || []).map((p) => {
              return (
                <>
                  <span>
                    {p.str_representation} (Roll: {p.signatory_role_display})
                  </span>
                  <br></br>
                </>
              );
            })}
          </>
        )}
      />

      <ConfirmationModal
        isOpen={confirmProlongOpen}
        closeFunction={() => setConfirmProlongOpen(false)}
        acceptCallback={() => onConfirmProlong()}
        canAccept={prolongDays >= 1 && prolongDays <= 90}
        withActionBar
        newDesign
        title="Hur många dagar vill du förlänga signeringen med?"
        renderContent={() => (
          <>
            <span>Signeringen kan fölängas med mellan 1 och 90 dagar</span>
            <NonConnectedTextInput
              value={prolongDays}
              onChange={(val) => setProlongDays(val)}
            />
          </>
        )}
      />

      <ConfirmationModal
        isOpen={confirmAutoRemindOpen}
        closeFunction={() => setConfirmAutoRemindOpen(false)}
        acceptCallback={() => onConfirmAutoRemind()}
        newDesign
        canAccept={
          autoRemindDays > 0 &&
          cancelledDoc?.timeout_time &&
          moment().add(autoRemindDays, "days") <
            moment(cancelledDoc?.timeout_time)
        }
        title="Om hur många dagar vill du påminna parterna?"
        renderContent={() => (
          <>
            <NonConnectedTextInput
              value={autoRemindDays}
              onChange={(val) => setAutoRemindDays(val)}
            />
          </>
        )}
        withActionBar
      />

      <PopupButton leftAligned title="Hantera" actions={signActions} />
    </>
  );
};
