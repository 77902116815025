import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import InvoiceTableFilters from "../ExternalFilters/InvoiceTableFilters";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { DateCell } from "../../Displays";

export default ({ invoices, handleRowClicked }) => {
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [filterCounts, setFilterCounts] = React.useState(null);

  React.useEffect(() => {
    let newFilterCounts = {};

    invoices.forEach((i) => {
      if (newFilterCounts[i.Stage]) {
        newFilterCounts[i.Stage] += 1;
      } else {
        newFilterCounts[i.Stage] = 1;
      }

      setFilterCounts(newFilterCounts);
    });
  }, [invoices]);

  const updateFilter = React.useCallback(
    (filter) => {
      if (selectedFilter === filter) {
        setSelectedFilter(null);
      } else {
        setSelectedFilter(filter);
      }
    },
    [selectedFilter]
  );

  const data = React.useMemo(() => {
    let displayedInvoices = [];

    // map extra data
    displayedInvoices = invoices.map((i) => ({
      ...i,
      total: `${(i.InvoicedAmount.Value / 100)?.toLocaleString("sv")}`,
      totalLeft: `${(i.CurrentAmount.Value / 100)?.toLocaleString("sv")} `,
    }));

    // filter external filters
    if (selectedFilter) {
      displayedInvoices = displayedInvoices.filter((i) => {
        return i.Stage === selectedFilter;
      });
    }

    return displayedInvoices;
  }, [invoices, selectedFilter]);

  const billectaStateMap = {
    None: "Okänd",
    Created: "Ej attesterad",
    ReminderInvoiceSent: "Påminnelse",
    Manual: "Förfallen (Ej hanterad)",
    Completed: "Slutbetald",
    Cancelled: "Avbruten",
    InvoiceSent: "Skickad",
    Attested: "Attesterad",
    CreditInvoice: "Kreditfaktura",
    SentToDebtCollection: "Inkasso",
    SalesRequested: "SalesRequested",
    SaleRequestAccepted: "SaleRequestAccepted",
    SalesRequestedCancelled: "SalesRequestedCancelled",
    SalesRequestedDenied: "SalesRequestedDenied",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "Stage",
        Cell: (props) => {
          if (props.row?.original?.ActionType === "CreditInvoiceAction") {
            return (
              <StatusLabel state={"Kreditfaktura"}>Kreditfaktura</StatusLabel>
            );
          }
          return (
            <StatusLabel state={billectaStateMap[props.value]}>
              {billectaStateMap[props.value]}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Fakturanummer",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Genererad av",
        accessor: "pigelloInvoiceType",
        Cell: (props) => {
          return <Field>{props.value || "Avtal"}</Field>;
        },
      },
      {
        Header: "Belopp (SEK)",
        accessor: "total",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Kvarvarande belopp (SEK)",
        accessor: "totalLeft",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Attesterad",
        accessor: "AttestedDate",
        Cell: (props) => {
          return <Field>{props.value ? "Ja" : "Nej"}</Field>;
        },
      },
      {
        Header: "Fakturadatum",
        accessor: "InvoiceDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "DueDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Skapad",
        accessor: "Created",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        onRowClicked={handleRowClicked}
        externalFilters={() => (
          <InvoiceTableFilters
            updateFilter={updateFilter}
            selectedFilter={selectedFilter}
            filterCounts={filterCounts}
          />
        )}
        tableId="contractviewinvoicestable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
