import { useDispatch, useSelector } from "react-redux";
import { getSignupFormProcessName } from "../utils";

import { useForm } from "../../base";
import { getPatchForm, getPostForm, constants, getScriveSignupForm } from ".."

export function useScriveSignupForm() {
    const dispatch = useDispatch();
    const storeName = constants.STORE_NAME;
    
    const processName = getSignupFormProcessName();
    
    const existing = useSelector((state) => state[storeName].forms["POST"]);
    const isFetching = useSelector((state) => state[storeName].inProgress.includes(processName));
    
    // first check if it exists in store
    if (existing) {return existing;}

    // if it doesn't exist check if it currently fetching
    // if so, we wait for result
    if (isFetching) { return undefined; }

    // we're not waiting, so we should initiate a retrival
    dispatch(getScriveSignupForm())
    
    return undefined;
}

export function useEditabledocForm(method, id) {
    let params = {
        storeName:constants.STORE_NAME,
        method:method,
        id:id
    }
    
    if (method === "POST") {params.fetchMethod = getPostForm}
    else {params.fetchMethod = getPatchForm}

    return useForm(params)
}