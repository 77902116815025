import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";

import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { buildQueryString } from "../../store/base";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { useFilteredCompanies } from "../../store/companies";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllInvoices,
  getAllPayments,
  constants,
} from "../../store/billectaInvoicing";
import PaymentsContainer from "../../components/Billecta/Payments/PaymentsContainer";
import Search from "./detail/Search";
import Reports from "./detail/Reports";
import AccountsReceivable from "./detail/AccountsReceivable";
import InvoicingContracts from "./detail/Contracts";
import Invoices from "./detail/Invoices";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import BillectaKyc from "./detail/Kyc";
import { clearIntegrationData } from "../../store/billectaIntegrations";
import Notifications from "./detail/Notifications";

function BillectaDetail() {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { push } = useHistory();

  const { creditorId } = useParams();

  React.useEffect(() => {
    dispatch(getAllInvoices(creditorId));
    dispatch(getAllPayments(creditorId));
  }, [creditorId]);

  React.useEffect(() => {
    return () => {
      dispatch(clearIntegrationData());
    };
  }, []);

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: Notifications,
    },
    {
      path: "/invoices",
      label: "Fakturor",
      component: Invoices,
    },
    {
      path: "/contract-invoices",
      label: "Avtal",
      component: InvoicingContracts,
    },
    {
      path: "/payments",
      label: "Betalningar",
      component: PaymentsContainer,
    },
    {
      path: "/reports",
      label: "Rapporter",
      component: Reports,
    },
    {
      path: "/search",
      label: "Sök",
      component: Search,
    },
    {
      path: "/accounts-receivable",
      label: "Kundreskontra",
      component: AccountsReceivable,
    },
  ];

  if (company && !company?.kyc_report?.id) {
    SUB_PAGES.push({
      path: "/kyc",
      hidden: true,
      component: BillectaKyc,
    });
  }

  const subPageLinks = SUB_PAGES.filter((s) => !s.hidden).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderActions = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {company && !company?.kyc_report?.id && (
          <PrimaryButton
            title="Registrera KYC"
            clicked={() => push(`${url}/kyc`)}
            pulse
          />
        )}
      </div>
    );
  };

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={
            company?.str_representation
              ? `Fakturering för ${company?.str_representation}`
              : "Laddar..."
          }
          {...{
            subPages: subPageLinks,
            breadCrumbs: [
              {
                label: "Fakturering",
                url: "/rental-notifications",
              },
              { label: company?.str_representation || "Laddar..." },
            ],
            renderActions,
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default BillectaDetail;
