const defs = [
  {
    title: "Parter",
    key: "PARTIES",
    visited: true,
    hasError: false,
    fieldKeys: ["opponent", "company"],
  },
  {
    title: "Dokument",
    key: "EXISTINGDOC",
    visited: false,
    hasError: false,
    fieldKeys: ["editabledoc.doc"],
  },
  {
    title: "Bilagor",
    key: "ATTACHMENTS",
    visited: false,
    hasError: false,
    fieldKeys: ["attachments"],
  },
  {
    title: "Avtal",
    key: "CONTRACT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "id_number",
      "status",
      "start_date",
      "end_date",
      "renew_interval",
      "warning_interval",
      "notify_interval",
      "invoice_month_interval",
      "first_billing_date",
      "renew_indefinetely",
    ],
  },
  {
    title: "Index",
    key: "INDEXATION",
    visited: false,
    hasError: false,
    fieldKeys: ["indexation"],
  },
  {
    title: "Signeringsstatus",
    key: "SIGN_STATUS",
    visited: false,
    hasError: false,
    fieldKeys: ["is_signed", "signed_date"],
  },
  {
    title: "Slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default ({ contractType, isManualSign }) => {
  const exclude = [];

  // only include signing if existing contract, new contracts uses SCRIVE_SETTINGS
  if (contractType === "new") {
    exclude.push("EXISTINGDOC");
    exclude.push("ATTACHMENTS"); // uploaded with editable doc

    if (!isManualSign) {
      exclude.push("SIGN_STATUS");
    }
  }

  return defs.filter((d) => !exclude.includes(d.key));
};
