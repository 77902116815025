import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, set } from "lodash";

// styles, design
import ChapterForm from "../ScreenForms/ChapterForm";
import { StepsButtonsWrapper } from "./styles";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// store, state
import reducer, { ACTIONS } from "./uiReducer";
import {
  useChapterErrors,
  setActiveFormInstance,
} from "../../../../store/base";
import useScreenSize from "../../../../hooks/useScreenSize";

export default ({
  storeName,
  chapterDefs,
  onRenderChapter,
  submitCallback,
  title,
  onDone,
  loading,
  pdf,
  canSaveAsDraft,
  isPatch,
  askSaveWork,
  getSteps,
  forceChapter,
  setForceChapter,

  // custom chapter rendering, optional
  renderChapters,
}) => {
  const dispatch = useDispatch();

  const initialState = {
    activeChapter: chapterDefs[0],
    chapters: chapterDefs,
    isFirstStep: true,
    isLastStep: false,
  };

  const [state, localDispatch] = React.useReducer(reducer, { ...initialState });
  const [showPdf, setShowPdf] = React.useState(true);
  const [showButtons, setShowButtons] = React.useState(true);
  const chapterErrors = useChapterErrors({ storeName, chapterDefs });
  const chapterRef = React.useRef(chapterErrors);
  const { isMobile } = useScreenSize();

  const formInstance = useSelector((state) => state[storeName].formInstance);
  const cleanedInstanceRef = React.useRef(formInstance);

  // listen for changes in total error status of chapter and update status
  React.useEffect(() => {
    if (JSON.stringify(chapterRef.current) !== JSON.stringify(chapterErrors)) {
      localDispatch({
        type: ACTIONS.SET_CHAPTER_ERRORS,
        payload: {
          keys: chapterErrors,
        },
      });
      chapterRef.current = chapterErrors;
    }
  }, [chapterErrors]);

  const cleanInstance = () => {
    let cleanedInstance = {};
    chapterDefs.forEach((def) => {
      (def.fieldKeys || []).forEach((k) => {
        const value = get(formInstance, k, undefined);

        // if we set undefined values to a parent,
        // the parent will be an empty dict, which backend doesn allow
        // e.g editabledoc.parties = undefined -> {editabledoc:{}}
        if (value === undefined) {
          return;
        }

        set(cleanedInstance, k, value);
      });
    });

    dispatch(setActiveFormInstance({ storeName, data: cleanedInstance }));
  };

  const getChapters = () => {
    if (!renderChapters) return chapters;

    return renderChapters(chapters);
  };

  const onSubmit = () => {
    cleanInstance();
    submitCallback();
  };

  const { activeChapter, chapters } = state;

  const goToPreviousStep = () => {
    localDispatch({
      type: ACTIONS.GO_TO_PREVIOUS_STEP,
    });
  };

  const goToNextStep = () => {
    localDispatch({
      type: ACTIONS.GO_TO_NEXT_STEP,
    });
  };

  const onChapterClicked = (key) => {
    localDispatch({
      type: ACTIONS.SET_ACTIVE_CHAPTER,
      payload: {
        key,
      },
    });
  };

  React.useEffect(() => {
    if (forceChapter && forceChapter !== activeChapter.key) {
      localDispatch({
        type: ACTIONS.SET_ACTIVE_CHAPTER,
        payload: {
          key: forceChapter,
        },
      });

      setForceChapter(null);
    }
  }, [forceChapter]);

  React.useEffect(() => {
    const { key } = activeChapter;

    if (
      key === "PLACE_FIELDS" ||
      key === "CONNECTED_CONTRACTS" ||
      key === "INVOICING"
    ) {
      setShowPdf(false);
      setShowButtons(false);
    } else if (key === "FAULTS") {
      setShowPdf(true);
      setShowButtons(false);
    } else {
      setShowPdf(true);
      setShowButtons(true);
    }
  }, [state.activeChapter]);

  const onRenderChapterButtons = () => {
    if (getSteps) {
      const result = getSteps({
        state,
        chapterKey: activeChapter?.key,
        cleanInstance,
        goToNextStep,
        goToPreviousStep,
        onSubmit,
      });
      if (result) {
        return result;
      }
    }

    let confirmTitle = "Bekräfta";
    if (typeof title === "string") {
      confirmTitle = title;
    }

    return (
      <>
        {!state.isFirstStep && (
          <SecondaryButton
            fillWidth={!!isMobile}
            clicked={goToPreviousStep}
            title="Tillbaka"
          />
        )}
        {!state.isLastStep && (
          <PrimaryButton
            fillWidth={!!isMobile}
            clicked={goToNextStep}
            title="Gå vidare"
          />
        )}
        {state.isLastStep && (
          <PrimaryButton
            fillWidth={!!isMobile}
            clicked={onSubmit}
            title={confirmTitle}
          />
        )}
      </>
    );
  };

  return (
    <ChapterForm
      loading={loading}
      title={title}
      chapters={getChapters()}
      activeChapter={activeChapter}
      chapterClicked={onChapterClicked}
      onDone={onDone}
      pdf={showPdf && pdf}
      askSaveWork={askSaveWork}
      noPadding={!showPdf}
    >
      {onRenderChapter(activeChapter.key)}

      {showButtons && (
        <>
          <StepsButtonsWrapper>{onRenderChapterButtons()}</StepsButtonsWrapper>
          {canSaveAsDraft && state.isLastStep && (
            <StepsButtonsWrapper>
              <SecondaryButton
                clicked={askSaveWork}
                fillWidth={!!isMobile}
                title={isPatch ? "Uppdatera utkast" : "Spara som utkast"}
              />
            </StepsButtonsWrapper>
          )}
        </>
      )}
    </ChapterForm>
  );
};
