import * as React from "react";
import { InfoBox } from "../../../components/Displays";
import * as SharedStyles from "../../../components/sharedStyles";

import KycForm from "../../../components/Forms/Kyc/PlainForm";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../store/base";
import { constants, useKYC } from "../../../store/billectaKyc";
import { useParams } from "react-router";
import { useFilteredCompanies } from "../../../store/companies";

export default function BillectaKyc() {
  const dispatch = useDispatch();
  const { creditorId } = useParams();

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];
  const kycReportId = company?.kyc_report?.id;

  const [existingKyc] = useKYC(kycReportId);

  React.useEffect(() => {
    if (kycReportId && existingKyc) {
      dispatch(
        setActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: existingKyc,
        })
      );
    } else if (company) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {
            company: { id: company?.id },
            us_persons: [],
            majority_holders: [],
            benifitial_owners: [],
          },
        })
      );
    }
  }, [company, existingKyc]);

  return (
    <SharedStyles.LayoutWrapper style={{ padding: "20px" }}>
      <InfoBox
        title="Kundkännedom"
        text="För att få erbjuda finansiella tjänster kräver Finansinspektionen att en
        blankett för kundkännedom fylls i och skickas in. Fyll i uppgifterna
        nedan för att komma igång med fakturering."
      />
      <SharedStyles.LayoutTitle>
        Blankett för kundkännedom för {company?.str_representation}
      </SharedStyles.LayoutTitle>

      <KycForm existingKyc={existingKyc} company={company} />
    </SharedStyles.LayoutWrapper>
  );
}
