import * as React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { matchPaymentsToInvoice } from "../../../store/billectaInvoicing";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import MatchInvoicesTable from "./MatchInvoicesTable";
import MatchPaymentsTable from "./MatchPaymentsTable";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

const today = moment().format("YYYY-MM-DD");

export default function MatchPaymentsModal({
  isOpen,
  selectedPayments,
  possibleInvoices = [],
  closeFunction,
  resetSelected,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [paymentDate, setPaymentDate] = React.useState(today);
  const payments = selectedPayments.map((sp) => ({ ...sp.original }));

  const { creditorId } = useParams();

  const matchSelected = () => {
    setLoading(true);
    const invoiceId = selectedInvoice?.original?.ActionPublicId;
    const paymentIds = payments.map((p) => p.UnhandledPaymentPublicId);
    if (!invoiceId || !paymentIds?.length) {
      setLoading(false);
      return;
    }
    dispatch(
      matchPaymentsToInvoice({
        paymentIds,
        invoiceId,
        creditorId,
        paymentDate,
        successCallback: () => {
          setLoading(false);
          resetSelected();
          closeFunction();
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Betalningar matchades",
              description: "Saldor på de berörda fakturorna uppdaterades",
            })
          );
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message ? message : "",
            })
          );

          setLoading(false);
        },
        alternativeErrorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: message ? message : "",
            })
          );

          resetSelected();
          closeFunction();

          setLoading(false);
        },
      })
    );
  };

  return (
    <StandardModal
      isOpen={isOpen}
      title={`Välj faktura att matcha betalning${
        selectedPayments.length > 1 ? "ar" : ""
      } mot`}
      withActionBar
      actionBarAcceptTitle="Matcha betalningar"
      saveFunction={matchSelected}
      closeFunction={closeFunction}
      canAccept={!!selectedInvoice && !loading}
    >
      {loading && <OverlaySpinner />}
      <div style={{ fontWeight: 600, fontSize: "1.2rem" }}>{`Betalning${
        selectedPayments.length > 1 ? "ar" : ""
      }`}</div>
      {selectedPayments.length > 0 && (
        <MatchPaymentsTable payments={payments} />
      )}

      <NonConnectedDatePicker
        value={paymentDate}
        label="Betaldatum"
        helpText="Välj vilket datum betalningen ska bokföras på. Om inget datum väljs kommer dagens datum automatiskt att bokföras som betaldatum."
        onChange={(val) => setPaymentDate(val)}
        maxDate={moment().toDate()}
      />

      <div style={{ fontWeight: 600, fontSize: "1.2rem", marginTop: "40px" }}>
        Möjliga fakturor
      </div>
      <MatchInvoicesTable
        handleRowClicked={(row) => setSelectedInvoice(row)}
        invoices={possibleInvoices}
        selectedRow={selectedInvoice}
      />
    </StandardModal>
  );
}
