import * as React from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

// style, design
import * as SC from "./styles";
import { CreateNewButton } from "../Buttons";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
  updateFormErrors,
} from "../../../../store/base";

export default React.memo(
  ({
    storeName,
    method,
    fieldKey,
    fetchMethod,
    constructStrRep,
    onEditClicked,
    onCreateClicked,
    instructionsKey,
    getDisplayColor,
    placeholder,
    title,

    // allows for react select to anchor the select list to the body.
    // Excellent for overflow problems but MUST NOT be used in modals
    bodyPortaled,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const [choices, choicesLoading] = fetchMethod();

    const updateErrors = (index) => {
      // check if we have an error at the index
      // if so, clear it

      // we assume that error is an array, and that index exists
      // otherwice this method should not be used

      const newErrors = [...error];
      const res = newErrors.splice(index, 1);
      if (res) {
        dispatch(
          updateFormErrors({ storeName, data: { [fieldKey]: newErrors } })
        );
      }
    };

    const onChange = (data) => {
      let formattedData = [];
      if (data !== null) {
        formattedData = data.map((d, index) => {
          if (d.id) {
            return { id: d.id, str_representation: d.str_representation };
          } else {
            return { ...d, _index: index };
          }
        });
      }

      if (data === null) {
        // clear all errors, because all objects were removed
        if (error && Array.isArray(error) && error.length) {
          dispatch(
            updateFormErrors({ storeName, data: { [fieldKey]: undefined } })
          );
        }
      } else {
        // removed value -> remove it's error
        // iter through old values (value), if not found in new values (data)
        // take out the index and remove the error
        if (value && error && Array.isArray(error) && data) {
          const index = value.findIndex(
            (v) =>
              !data.some((f) => {
                if ((f.id && !v.id) || (v.id && !f.id)) {
                  return false;
                }
                return f.id === v.id || f._index === v._index;
              })
          );
          if (index >= 0) {
            updateErrors(index);
          }
        }
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
    };

    const getDisplayValue = (option) => {
      const isEditable = option._index !== undefined;
      const strRep = option.str_representation || constructStrRep(option);
      if (isEditable) {
        if (error && Array.isArray(error)) {
          let err = error[option._index];
          if (err.constructor === Object && !Object.keys(err).length) {
            err = undefined;
          }

          if (err) {
            return (
              <p
                onClick={() => onEditClicked(option._index)}
                style={{ backgroundColor: "red" }}
              >
                {strRep}
              </p>
            );
          }
        }
        return (
          <p
            onClick={() => onEditClicked(option._index)}
            style={
              getDisplayColor
                ? { backgroundColor: getDisplayColor(option) }
                : {}
            }
          >
            {strRep}
          </p>
        );
      }

      return strRep;
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing>
        <SC.InputFieldTitle>
          {title || instructions._label}
          {instructions._required ? "*" : ""}
        </SC.InputFieldTitle>
        {instructions._helpText && (
          <SC.InputFieldDescription>
            {instructions._helpText}
          </SC.InputFieldDescription>
        )}
        {choices ? (
          <SC.SelectWrapper>
            <Select
              id={instructions._internalId}
              placeholder={placeholder || "Välj..."}
              onChange={onChange}
              defaultValue={[]}
              isMulti={true}
              value={value}
              options={choices}
              getOptionValue={(option) => option.id || option._index}
              getOptionLabel={getDisplayValue}
              {...{
                menuPortalTarget: bodyPortaled
                  ? document.querySelector("body")
                  : null,
              }}
            />
          </SC.SelectWrapper>
        ) : (
          <SC.SelectLoader>Laddar alternativ...</SC.SelectLoader>
        )}

        {!!onCreateClicked && (
          <CreateNewButton title="Skapa ny" clicked={onCreateClicked} />
        )}
        {!!error && (
          <SC.ErrorMessage>Några av objekten innehåller fel</SC.ErrorMessage>
        )}
      </SC.InputSpacing>
    );
  }
);
