import * as React from "react";

// style, design
import { ToolTipCell, DateCell, BooleanLabel } from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "../Base/CompleteList/Filters/DateFilter";

export default [
  {
    Header: "Läst",
    accessor: "read_by_me",
    Cell: ({ value }) => <BooleanLabel value={value} onLabel={"Läst"} offLabel={"Ej läst"} />,
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  },
  {
    Header: "Beskrivning",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Datum",
    accessor: "created_at",
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "between",
  },
];
