import * as React from "react";
import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => {
  const cols = [
    {
      Header: "Namn på dimension",
      accessor: "title",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "SIE-ID",
      accessor: "dimension_id",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
