import * as React from "react";

import CreateManualInvoice from "./CreateManualInvoice";

export default function CreateInvoiceController({
  createManualInvoiceOpen,
  closeCreateInvoice,
}) {
  return (
    <>
      <CreateManualInvoice
        open={createManualInvoiceOpen}
        closeFunction={closeCreateInvoice}
      />
    </>
  );
}
