import * as React from "react";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { constants } from "../../../store/billectaAccountingReports";
import { CLEANED_GENERATE_FORM } from "../../../store/billectaAccountingReports/hooks/instructions";
import {
  generateAccountingReport,
  generateDynamicAccountingReport,
} from "../../../store/billectaAccountingReports/store/actions";
import { useFilteredCompanies } from "../../../store/companies";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { PrimaryButton, SecondaryButton } from "../../Forms/Base/Buttons";
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import { Checkbox, DatePicker, Select } from "../../Forms/Base/Fields";
import AccountingEventsPicker from "../../Forms/Base/Fields/CustomFields/AccountingEventsPicker";
import DescriptionToolTip from "../../Forms/Base/Layout/DescriptionToolTip";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { BodyText } from "../../sharedStyles";

export default function AccountingReportsForm({ creditorId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [dynamicOpen, setDynamicOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const [companies] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const fromDate = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "From",
  });
  const toDate = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "To",
  });

  React.useEffect(() => {
    if (!fromDate || !toDate || fromDate !== toDate) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {
            remove_already_exported: false,
          },
        })
      );
    }
  }, [fromDate, toDate]);

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          BookKeepingTypesFilter:
            CLEANED_GENERATE_FORM.BookKeepingTypesFilter._choices.map(
              (c) => c.v
            ),
        },
      })
    );
  }, [creditorId]);

  const submitForm = () => {
    setLoading(true);

    dispatch(
      generateAccountingReport({
        creditorId,
        successCallback: () => {
          dispatch(
            addToast({
              title: "Bokföringsfilen genererades",
              description: "Den kan nu hämtas från listan över rapporter",
              type: TOAST_TYPES.SUCCESS,
            })
          );

          setOpen(false);
          setLoading(false);
        },
        errorCallback: (error) => {
          dispatch(
            addToast({
              title: "Något gick fel",
              description: JSON.stringify(error?.response?.data?.Message),
              type: TOAST_TYPES.ERROR,
            })
          );

          setLoading(false);
        },
      })
    );
  };

  const submitDynamicForm = () => {
    setLoading(true);

    dispatch(
      generateDynamicAccountingReport({
        creditorId,
        successCallback: ({ data: file }) => {
          dispatch(
            addToast({
              title: "Bokföringsfilen genererades",
              description: "Hämtningen påbörjas direkt",
              type: TOAST_TYPES.SUCCESS,
            })
          );

          const href = `data:application/octet-stream;base64,${file}`;

          const a = document.createElement("a");
          a.href = href;
          a.download = `${
            company?.str_representation || "Export"
          }_${fromDate}-${toDate}.si`;
          a.click();

          setDynamicOpen(false);
          setLoading(false);
        },
        errorCallback: (error) => {
          dispatch(
            addToast({
              title: "Något gick fel",
              description: JSON.stringify(error?.response?.data?.Message),
              type: TOAST_TYPES.ERROR,
            })
          );

          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      <div style={{ display: "inline-flex", alignItems: "flex-end" }}>
        <PrimaryButton
          extraStyle={{ marginTop: "12px" }}
          title="Generera export"
          clicked={() => setOpen(true)}
        />

        <BodyText style={{ margin: "12px" }}>eller</BodyText>

        <SecondaryButton
          extraStyle={{ marginTop: "12px" }}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              Hämta utökad export{" "}
              <DescriptionToolTip
                description={`Utökad export innebär att exporten tar hänsyn till de "Anpassade dimensioner" som är inställda i systemet. Observera att denna export hämtas direkt vid generering till skillnad från den vanliga exporten.`}
              />
            </div>
          }
          clicked={() => setDynamicOpen(true)}
        />
      </div>

      <StandardModal
        title="Generera export"
        isOpen={open}
        closeFunction={() => setOpen(false)}
        withActionBar
        saveFunction={submitForm}
        canAccept={!!fromDate && !!toDate}
        actionBarAcceptTitle="Generera"
      >
        {loading && <OverlaySpinner />}
        <FormAreaTitle>Generera en bokföringsfil</FormAreaTitle>
        <FormAreaDescription>
          Specificera hur rapporten ska genereras. När den är genererad kan den
          hämtas från listan
        </FormAreaDescription>

        <DoubleFieldWrapper>
          <DatePicker
            title="Från"
            storeName={constants.STORE_NAME}
            method={"POST"}
            forceRequired
            fieldKey={`From`}
          />
          <DatePicker
            title="Till"
            extraStyle={{ marginLeft: "12px" }}
            storeName={constants.STORE_NAME}
            method={"POST"}
            forceRequired
            fieldKey={`To`}
          />
        </DoubleFieldWrapper>

        <Select
          storeName={constants.STORE_NAME}
          method={"POST"}
          fieldKey={`DateSelectionType`}
        />

        <AccountingEventsPicker
          storeName={constants.STORE_NAME}
          method={"POST"}
          fieldKey={`BookKeepingTypesFilter`}
        />
      </StandardModal>

      <StandardModal
        title="Hämta utökad export"
        isOpen={dynamicOpen}
        closeFunction={() => setDynamicOpen(false)}
        withActionBar
        saveFunction={submitDynamicForm}
        canAccept={!!fromDate && !!toDate}
        actionBarAcceptTitle="Generera och hämta"
      >
        {loading && <OverlaySpinner />}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Generera en utökad bokföringsfil</OverviewTitle>
            <OverviewSubtitle>
              Specificera inställningar för export. När den är genererad hämtas
              den direkt från webbläsaren. <br />
              <strong>
                OBS: för att hämta en export som exkluderar redan exporterade
                verifikat så måste samma datum anges som Från och Till.
              </strong>
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <DoubleFieldWrapper>
          <DatePicker
            storeName={constants.STORE_NAME}
            method={"POST"}
            title="Från"
            forceRequired
            fieldKey={`From`}
          />
          <DatePicker
            extraStyle={{ marginLeft: "12px" }}
            storeName={constants.STORE_NAME}
            method={"POST"}
            title="Till"
            forceRequired
            fieldKey={`To`}
          />
        </DoubleFieldWrapper>

        {fromDate && toDate && fromDate === toDate && (
          <Checkbox
            {...{
              method: "POST",
              storeName: constants.STORE_NAME,
              fieldKey: "remove_already_exported",
              title: "Exkludera redan exporterade verifikationer",
              description:
                "Verifikat som redan exporterats för den angivna perioden och baserat på samma typ av händelse som här angivet, kommer att exkluderas. För verifikat som innehåller utökade dimensioner, så kommer dem inte att exkluderas även om dem har exporterats innan.",
            }}
          />
        )}

        <Checkbox
          {...{
            method: "POST",
            storeName: constants.STORE_NAME,
            fieldKey: "fallback_voucher_index",
            title: "Autogenerera verifikationsnummer om det saknas",
            description:
              "Det autogenererade verifikationsnumret baseras på verfifikatets ordning i denna export. Därmed är verifikationsnumret inte nödvändigtvis unikt jämtemot tidigare exporter.",
          }}
        />

        <Select
          storeName={constants.STORE_NAME}
          method={"POST"}
          fieldKey={`DateSelectionType`}
        />

        <AccountingEventsPicker
          storeName={constants.STORE_NAME}
          method={"POST"}
          fieldKey={`BookKeepingTypesFilter`}
        />
      </StandardModal>
    </>
  );
}
