import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useAnyPermissionCheck,
  useFormError,
  useFormInstanceField,
  useFrequentPermissions,
  usePermissionCheck,
} from "../../../../store/base";
import * as SC from "./styles";

import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import Table from "../../../Billecta/Table/BasicTable";
import { PrimaryButton } from "../../../Forms/Base/Buttons";
import { Select } from "../../../Forms/Base/Fields";
import { StatusLabel } from "../../../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../sharedStyles";
import { toMoneyString } from "../../../utils/stringUtils";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../styles";

import { InfoBox } from "../../../Displays";

import { useInvoicingCostCenter } from "../../../../store/invoicingCostCenters";
import { useInvoicingProject } from "../../../../store/invoicingProjects";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { useInvoicingSetting } from "../../../../store/invoicingSettings";

import {
  createCostsUrl as createManagementCostsUrl,
  useManagementContract,
} from "../../../../store/managementContracts";
import {
  constants as customerInvoicingConstants,
  useCustomerInvoicingForm,
  useCustomerInvoicing,
  update as updateCustomerInvoicing,
  destroyPatchForm as customerDestroyPatchForm,
} from "../../../../store/invoicingCustomer";
import HandleContractCost from "./HandleCost";

const getContractHook = (url) => {
  if (url.includes("management-contracts")) return useManagementContract;

  return null;
};

const getCreateCostsUrlMethod = (url) => {
  if (url.includes("management-contracts")) return createManagementCostsUrl;

  return null;
};

const getInvoicingObjHook = (url) => {
  if (url.includes("management-contracts")) return useCustomerInvoicing;

  return null;
};
const getConstants = (url) => {
  if (url.includes("management-contracts")) return customerInvoicingConstants;

  return null;
};

const getUpdateMethod = (url) => {
  if (url.includes("management-contracts")) return updateCustomerInvoicing;

  return null;
};

const getFormHook = (url) => {
  if (url.includes("management-contracts")) return useCustomerInvoicingForm;

  return null;
};

const getAttrName = (url) => {
  if (url.includes("management-contracts")) return "customer_invoicing";

  return null;
};

function Costs() {
  const method = "PATCH";
  const dispatch = useDispatch();
  const [handleCostIndex, setHandleCostIndex] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const canAddCost = useAnyPermissionCheck([
    "add_can_billing",
    "add_can_contract",
  ]);
  const canChangeVat = usePermissionCheck("change_can_contract");

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const { url } = useRouteMatch();
  const { push } = useHistory();
  const { managementContractId } = useParams();

  const contractId = managementContractId;

  const useContractHook = getContractHook(url);
  const createCostUrlMethod = getCreateCostsUrlMethod(url);
  const useInvoicingObjHook = getInvoicingObjHook(url);
  const invoicingConstants = getConstants(url);
  const formHook = getFormHook(url);
  const attrName = getAttrName(url);
  const updateMethod = getUpdateMethod(url);
  const storeName = invoicingConstants.STORE_NAME;

  const [contract, contractLoading] = useContractHook(contractId);
  const [invoicingObj, invoicingObjLoading] = useInvoicingObjHook(
    contract?.[attrName]?.id
  );
  const [invoicingSetting, invoicingSettingLoading] = useInvoicingSetting(
    invoicingObj?.setting?.id
  );

  const [defaultProject] = useInvoicingProject(invoicingSetting?.project?.id);
  const [defaultCostCenter] = useInvoicingCostCenter(
    invoicingSetting?.cost_center?.id
  );

  const [formDirty, setFormDirty] = React.useState(false);

  const hasIndexSetting = !!contract?.indexation;
  const indexations = contract?.indexations;

  const configMissing =
    !contractLoading && !invoicingObjLoading && !invoicingObj; // no cost and invoicing object setup

  formHook("PATCH", invoicingObj?.id);

  const formCosts = useFormInstanceField({
    storeName,
    fieldKey: "cost_set",
  });

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const baseVAT = useFormInstanceField({
    storeName,
    fieldKey: "vat",
  });

  const memberFeeTitle = useFormInstanceField({
    storeName,
    fieldKey: "member_fee_title",
  });

  const costErrors = useFormError({
    storeName,
    fieldKey: "cost_set",
  });

  const onUpdateSuccess = (_, returnedData) => {
    setLoading(false);

    dispatch(
      setActiveFormInstance({
        storeName,
        data: returnedData,
      })
    );
  };
  const onUpdateError = () => {
    setLoading(false);
  };

  const updateCosts = () => {
    setLoading(true);
    dispatch(
      updateMethod({
        id: invoicingObj?.id,
        successCallback: onUpdateSuccess,
        errorCallback: onUpdateError,
      })
    );
  };

  const canUpdate = checkCanUpdate({
    formCosts,
    invoicingObj,
    baseVAT,
    memberFeeTitle,
  });

  React.useEffect(() => {
    // not fetched costs yet
    if (formDirty || !invoicingObj) {
      return;
    }

    const invoicingObjWithCostsOrdered = cloneDeep(invoicingObj);

    // default sort order by backend, needs to be taken into account if sort order is missing
    // legacy - remove when safe
    const TEMP_SORT_ORDER = {
      1: 1, // BASE_RENT
      11: 2, // RENT_INCREASE
      0: 3, // INDX
      12: 4, // MEMBER_FEE
      9: 5, // PARKING
      10: 6, // DEPOSIT
      2: 7, // PROP_TAX
      3: 8, // ELECTRIC
      4: 9, // WATER_HOT
      5: 10, // WATER_COLD
      8: 11, // WATER
      15: 12, // GAS
      6: 13, // HEAT
      7: 14, // COOLING
      16: 15, // DISTRICT_COOLING
      17: 16, // DISTRICT_HEAT
      14: 17, // IMD
      13: 18, // ERRAND_ARTICLE
    };

    if (invoicingObjWithCostsOrdered.cost_set?.length) {
      // temp sorting to prevent dynamic sortings
      invoicingObjWithCostsOrdered.cost_set.sort((a, b) => {
        const aProductCategoryPoint =
          TEMP_SORT_ORDER[a.product?.category] || 999;
        const bProductCategoryPoint =
          TEMP_SORT_ORDER[b.product?.category] || 999;
        return aProductCategoryPoint - bProductCategoryPoint;
      });

      invoicingObjWithCostsOrdered.cost_set.forEach((c) => {
        if (c.order) return;
        const allOrders = invoicingObjWithCostsOrdered.cost_set.map(
          (c) => c.order || 0
        );
        const maxOrder = Math.max.apply(null, allOrders);
        c.order = maxOrder + 1;
      });

      invoicingObjWithCostsOrdered.cost_set.sort((a, b) => {
        return a.order - b.order;
      });
    }

    dispatch(
      setActiveFormInstance({
        storeName,
        data: invoicingObjWithCostsOrdered,
      })
    );

    setFormDirty(true);
  }, [invoicingObj, contract]);

  const handleRowDown = (event, row) => {
    event.stopPropagation();

    const instanceClone = cloneDeep(instance);

    const rowOrder = row.order;
    const newOrder = rowOrder + 1;

    const rowThatChangedOrder = instanceClone.cost_set.find(
      (r) => r.order === rowOrder
    );
    const rowThatPreviuoslyHadOrder = instanceClone.cost_set.find(
      (r) => r.order === newOrder
    );

    rowThatChangedOrder.order = newOrder;
    rowThatPreviuoslyHadOrder.order = rowOrder;

    instanceClone.cost_set.sort((a, b) => {
      return a.order - b.order;
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: instanceClone,
      })
    );
  };

  const handleRowUp = (event, row) => {
    event.stopPropagation();

    const instanceClone = cloneDeep(instance);

    const rowOrder = row.order;
    const newOrder = rowOrder - 1;

    const rowThatChangedOrder = instanceClone.cost_set.find(
      (r) => r.order === rowOrder
    );
    const rowThatPreviuoslyHadOrder = instanceClone.cost_set.find(
      (r) => r.order === newOrder
    );

    rowThatChangedOrder.order = newOrder;
    rowThatPreviuoslyHadOrder.order = rowOrder;

    instanceClone.cost_set.sort((a, b) => {
      return a.order - b.order;
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: instanceClone,
      })
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(customerDestroyPatchForm());
    };
  }, []);

  const tableCosts = React.useMemo(() => {
    return formCosts;
  }, [formCosts, baseVAT]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: " ",
        id: "orderOnInvoice",
        Cell: ({ row }) => {
          const rowOrder = row.original.order;
          const allOrders = formCosts.map((fc) => fc.order);
          const maxUp = Math.max.apply(null, allOrders);
          const maxDown = Math.min.apply(null, allOrders);
          const canDown = rowOrder < maxUp;
          const canUp = rowOrder > maxDown;

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {canUp && (
                <SC.OrderUp onClick={(e) => handleRowUp(e, row.original)} />
              )}
              {canDown && (
                <SC.OrderDown onClick={(e) => handleRowDown(e, row.original)} />
              )}
            </div>
          );
        },
      },
      {
        Header: "Typ",
        accessor: "_",
        Cell: ({ row }) => {
          const isDiscount = row.original.value < 0;
          return (
            <StatusLabel state={isDiscount ? 3 : 1}>
              {isDiscount ? "Rabatt" : "Kostnad"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Produkt",
        accessor: "product",
        Cell: ({ value }) => {
          const title = value?.str_representation || value?.title || "";

          return <div>{title}</div>;
        },
      },
      {
        Header: "SEK/månad",
        accessor: "value",
        Cell: ({ value }) => {
          return <div>{toMoneyString(value || 0)}</div>;
        },
      },
      {
        Header: "Momssats (%)",
        accessor: "vat",
        Cell: ({ value, row }) => {
          const productVat = row.original.product?.vat;
          const val =
            value != null
              ? `${value}`
              : productVat != null
              ? `${productVat} (från produkt)`
              : baseVAT != null
              ? `${baseVAT} (från grundmomssats)`
              : "-";
          return <div>{val}</div>;
        },
      },
      {
        Header: "Börjar aviseras för",
        accessor: "start_date",
        Cell: ({ value }) => {
          if (!value) return <div>Med avtalet</div>;
          return <div>{value}</div>;
        },
      },
      {
        Header: "Slutar aviseras för",
        accessor: "end_date",
        Cell: ({ value }) => {
          if (!value) return <div>Med avtalet</div>;

          return <div>{value}</div>;
        },
      },
    ];

    if (hasBillectaViewPermission) {
      cols.splice(1, 0, {
        Header: "Godkänd för avisering",
        accessor: "accepted",
        Cell: ({ value }) => {
          return (
            <StatusLabel state={value ? 1 : 6}>
              {value ? "Godkänd" : "Ej godkänd"}
            </StatusLabel>
          );
        },
      });

      cols.push({
        Header: "Kostnadsställe",
        accessor: "cost_center",
        Cell: ({ value }) => {
          const title = value?.title
            ? value.title
            : value?.str_representation
            ? value.str_representation
            : defaultCostCenter
            ? `${defaultCostCenter.str_representation} (från inställning)`
            : "-";

          return <div>{title}</div>;
        },
      });

      cols.push({
        Header: "Projekt",
        accessor: "project",
        Cell: ({ value }) => {
          const title = value?.title
            ? value.title
            : value?.str_representation
            ? value.str_representation
            : defaultProject
            ? `${defaultProject.str_representation} (från inställning)`
            : "-";

          return <div>{title}</div>;
        },
      });
    }

    cols.push({
      Header: "Indexuppräkning",
      accessor: "indexate",
      Cell: ({ value }) => {
        const label = value ? (
          <StatusLabel state={1}>Aktiv</StatusLabel>
        ) : (
          <StatusLabel state={3}>Inaktiv</StatusLabel>
        );

        return label;
      },
    });

    return cols;
  }, [hasBillectaViewPermission, baseVAT, formCosts]);

  const handleEditCost = (row) => {
    setHandleCostIndex(row.index);
  };

  const handleAddCost = () => {
    const costsCopy = [...(formCosts || [])];
    const newIndex = costsCopy.length;

    costsCopy.push({ order: newIndex + 1 });
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: costsCopy,
        },
      })
    );

    setHandleCostIndex(newIndex);
  };

  const handleRemoveCost = (index) => {
    const costsCopy = cloneDeep(formCosts);

    const removedRowOrder = costsCopy[index].order;
    costsCopy.splice(index, 1);

    // fix orders
    if (costsCopy.length) {
      costsCopy.forEach((c) => {
        if (c.order > removedRowOrder) {
          c.order = c.order - 1;
        }
      });
    }

    setHandleCostIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          cost_set: costsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );
  };

  const handleEditCostDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleCostIndex(null);
  };

  const renderCostErrors = () => {
    return (
      <InfoBox
        title="En eller flera kostnadsrader innehåller fel"
        text="Kontrollera de rödmarkerade raderna. Klicka på raden för att se detaljer kring felet"
        boxTheme="warning"
      />
    );
  };

  const checkRowError = (row) => {
    const errorIndexes =
      costErrors
        ?.map((r, idx) => {
          if (Object.keys(r).length) {
            return idx;
          }
          return null;
        })
        ?.filter((e) => e != null) || [];
    return errorIndexes.includes(row.index);
  };

  if (configMissing) {
    return (
      <DetailInnerWrapper>
        <DetailPageBox centerAll style={{ minHeight: 400 }}>
          <OverviewTitle small>Kostnadsinställning saknas</OverviewTitle>
          <PrimaryButton
            extraStyle={{ marginTop: 24 }}
            title={"Ställ in kostnader för detta avtal"}
            clicked={() => push(createCostUrlMethod({ id: contract?.id }))}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    );
  }

  return (
    <>
      <HandleContractCost
        costs={formCosts}
        handleCostIndex={handleCostIndex}
        closeFunction={() => setHandleCostIndex(null)}
        onUpdate={handleEditCostDone}
        method={method}
        storeName={storeName}
        contract={contract}
        onRemove={() => handleRemoveCost(handleCostIndex)}
      />
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{
              minHeight: 400,
              width: hasIndexSetting ? "70%" : "100%",
              maxWidth: hasIndexSetting ? "70%" : "100%",
            }}
          >
            {(!invoicingObj || loading) && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Kostnader och Rabatter</OverviewTitle>
                <OverviewSubtitle>
                  Tryck på en rad för att redigera den. För att spara ändringar,
                  tryck på "Uppdatera" längst ner
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
              {canAddCost && (
                <PrimaryButton title="Lägg till rad" clicked={handleAddCost} />
              )}
            </OverviewTitleWrapper>

            {canUpdate && (
              <InfoBox
                title="Uppdateringar ej sparade"
                text={`Tryck på "Uppdatera" nere till höger för att spara ändringarna i kostnadsraderna.`}
              />
            )}

            {costErrors?.length && renderCostErrors()}

            {canChangeVat && (
              <Select
                title="Grundmomssats (%)"
                description="Momssats som ska ligga till grund för alla rader som EJ har en momssats specificerad. Om en rad har en momssats specificerad gäller den."
                {...{ storeName, method: "PATCH", fieldKey: "vat" }}
              />
            )}

            {tableCosts?.length > 0 ? (
              <>
                <Table
                  onRowClicked={handleEditCost}
                  columns={tableColumns}
                  data={tableCosts}
                  checkRowError={checkRowError}
                  hideSearch
                />
              </>
            ) : (
              <InnerBox
                style={{
                  minHeight: 200,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Inga kostnader eller rabatter är tillagda än
                <div style={{ marginTop: 24 }}>
                  {canAddCost && (
                    <PrimaryButton
                      title="Lägg till rad"
                      clicked={handleAddCost}
                    />
                  )}
                </div>
              </InnerBox>
            )}

            {canUpdate && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <PrimaryButton pulse title="Uppdatera" clicked={updateCosts} />
              </div>
            )}
          </DetailPageBox>

          {hasIndexSetting && (
            <DetailPageBox style={{ width: "30%", alignSelf: "flex-start" }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Indexuppräkning</OverviewTitle>
                  <OverviewSubtitle>
                    Index räknas fram utefter angivna inställningar. Nedan visas
                    den aktuella och historiska uppräkningar.
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {Object.keys(indexations || {}).length === 0 && (
                <InnerBox>
                  Inga indexvärden kan visas för detta avtal för tillfället.
                </InnerBox>
              )}

              {Object.keys(indexations || {}).map((year) => (
                <InnerBox style={{ marginBottom: 8 }} key={year}>
                  <strong>{year.split("-")?.[0] || year}: </strong>
                  {toMoneyString((indexations[year] || 0) / 12, true)}/månad
                </InnerBox>
              ))}
            </DetailPageBox>
          )}
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

export default Costs;

function checkCanUpdate({ formCosts, invoicingObj, baseVAT, memberFeeTitle }) {
  const baseVATChanged = invoicingObj?.vat !== baseVAT;
  const memberFeeTitleChanged =
    invoicingObj?.member_fee_title !== memberFeeTitle;
  const existingCosts = invoicingObj?.cost_set;

  let hasChange = false;

  if (existingCosts?.length !== formCosts?.length) return true;
  if (!existingCosts?.length && !formCosts?.length) return false;

  existingCosts.forEach((cost) => {
    const match = formCosts.find((fCost) => {
      return fCost.id === cost.id;
    });

    if (!match) {
      hasChange = true;
      return;
    }

    if (
      match.title !== cost.title ||
      match.value !== cost.value ||
      match.order !== cost.order ||
      match.indexate !== cost.indexate ||
      match.start_date !== cost.start_date ||
      match.end_date !== cost.end_date ||
      match.vat !== cost.vat ||
      match.unit_amount !== cost.unit_amount ||
      match.product?.id !== cost.product?.id ||
      match.cost_center?.id !== cost.cost_center?.id ||
      match.project?.id !== cost.project?.id
    ) {
      hasChange = true;
    }
  });

  return hasChange || baseVATChanged || memberFeeTitleChanged;
}
