import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  handleContractEditableDocUrl,
} from "../../../../store/editabledocs";
import { useCanUseScrive } from "../../../../store/editabledocs/hooks/retrieve";
import {
  SCRIVE_DESCRIPTION_MAP,
  SCRIVE_STATE_MAP,
} from "../../../../store/editabledocs/utils";
import {
  isCancelSigning,
  constants,
  update,
} from "../../../../store/managementContracts";
import { InfoBox } from "../../../Displays";
import { PrimaryButton, SecondaryButton } from "../../../Forms/Base/Buttons";
import DescriptionToolTip from "../../../Forms/Base/Layout/DescriptionToolTip";
import { StatusLabel } from "../../../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import { DetailPageBox, InnerBox } from "../../../sharedStyles";
import Parties from "../../OverviewInfo/Signing/Parties";
import ScriveEvents from "../../OverviewInfo/Signing/ScriveEvents";
import SignActions from "../../OverviewInfo/Signing/SignActions";
import StartSigning from "../../OverviewInfo/Signing/StartSigning";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../OverviewInfo/styles";

export default function ManagementContractDigitalSign({
  contract,
  editableDoc,
}) {
  const dispatch = useDispatch();
  const [canUseScrive] = useCanUseScrive();
  const [open, setOpen] = React.useState(false);
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const hasScrive = editableDoc?.has_scrive;
  const isDraft = contract.draft;
  const isDigitalSign = contract.uses_e_signing;
  const isPreparation = editableDoc?.status === "preparation";
  const hasParties = editableDoc?.parties?.length > 0;
  const canPerformEditableDocActions = !isCancelSigning(contract);

  const canStartScriveSign =
    canUseScrive &&
    !isDraft &&
    isDigitalSign &&
    canPerformEditableDocActions &&
    hasParties &&
    isPreparation;

  const showScriveActions =
    isDigitalSign &&
    canUseScrive &&
    hasParties &&
    !canStartScriveSign &&
    !isDraft &&
    canPerformEditableDocActions;

  const handleCreateEditableDoc = () => {
    setLoading(true);
    dispatch(
      update({
        id: contract.id,
        forceData: {
          editabledoc: {
            title: "doc",
          },
        },
        successCallback: () => {
          setLoading(false);
          push(
            handleContractEditableDocUrl({
              contractType: EDITABLE_DOC_CONTRACT_TYPES.CUSTOMER_CONTRACT,
              id: contract?.id,
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <DetailPageBox
      style={{
        border: "2px solid #e4f1ff",
        boxShadow: "0px 2px 11px 5px rgba(73, 157, 255, 0.2)",
      }}
    >
      {loading && <OverlaySpinner />}

      {editableDoc?.status === "canceled" && (
        <InfoBox
          boxTheme="yellow"
          title="Signering avbruten"
          text={`Signeringsprocessen är avbruten. För att kunna göra ändrignar i dokument och starta om signeringsprocessen, tryck på "Hantera -> Starta om process". Detta kommer att sätta statusen på signeringen till "Förberedelse" igen. Dokumentet kommer EJ skickas ut till parterna på dokumentet innan signeringen startas igen.`}
        />
      )}
      <OverviewTitleWrapper style={open ? {} : { marginBottom: 0 }}>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            Digital signering{" "}
            {SCRIVE_DESCRIPTION_MAP[editableDoc?.status] && (
              <DescriptionToolTip
                description={SCRIVE_DESCRIPTION_MAP[editableDoc?.status]}
              />
            )}
          </OverviewTitle>
          <OverviewSubtitle style={{ display: "flex", alignItems: "center" }}>
            Status:{" "}
            <StatusLabel state={SCRIVE_STATE_MAP[editableDoc?.status] || 9}>
              {" "}
              {isDigitalSign
                ? contract?.editabledoc
                  ? editableDoc?.status_display
                  : "Signeringsdokument saknas"
                : contract.is_signed
                ? "Signerat"
                : "Ej signerat"}
            </StatusLabel>
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>

        <div style={{ display: "flex" }}>
          {!contract?.editabledoc && (
            <PrimaryButton
              title="Skapa dokument för signering"
              clicked={handleCreateEditableDoc}
            />
          )}

          {editableDoc && (
            <SecondaryButton
              extraStyle={
                showScriveActions || canStartScriveSign || !hasParties
                  ? { marginRight: 8 }
                  : {}
              }
              title={open ? "Dölj detaljer" : "Visa detaljer"}
              clicked={() => setOpen(!open)}
            />
          )}
          {!hasParties && editableDoc && (
            <PrimaryButton
              extraStyle={
                showScriveActions || canStartScriveSign
                  ? { marginRight: 8 }
                  : {}
              }
              title="Hantera dokument"
              clicked={() =>
                push(
                  handleContractEditableDocUrl({
                    contractType: EDITABLE_DOC_CONTRACT_TYPES.CUSTOMER_CONTRACT,
                    id: contract?.id,
                  })
                )
              }
            />
          )}

          {canStartScriveSign && (
            <StartSigning
              contract={contract}
              editableDoc={editableDoc}
              constants={constants}
            />
          )}
          {showScriveActions && (
            <SignActions
              contract={contract}
              editableDoc={editableDoc}
              constants={constants}
            />
          )}
        </div>
      </OverviewTitleWrapper>

      {open && (
        <div style={{ display: "flex" }}>
          {hasScrive && isDigitalSign && (
            <InnerBox>
              <ScriveEvents editableDoc={editableDoc} />
            </InnerBox>
          )}

          <InnerBox style={{ marginLeft: 12 }}>
            <OverviewTitleWrapper>
              <OverviewTitle small>Parter</OverviewTitle>
            </OverviewTitleWrapper>
            <Parties editabledoc={editableDoc} />
          </InnerBox>
        </div>
      )}
    </DetailPageBox>
  );
}
