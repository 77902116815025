export const ALL_VIEW_PERM_MAP = {
  view_can_company: "Visa företag",
  view_can_baseobject: "Visa objekt",
  view_can_customer: "Visa förvaltningsuppdrag",
  view_can_contract: "Visa kontrakt",
  view_can_user: "Visa användare",
  view_can_permissions: "Visa behörigheter",
  view_can_billing: "Visa avisering/fakturering",
  view_can_e_signing: "Visa e-signering",
  view_can_fastdoc: "Visa fastighetsägarna dokument",
  view_can_fortnox: "Visa driftnetto (Fortnox)",
};

export const ALL_CUSTOMER_PERM_MAP = {
  allow_billecta: "Billecta",
  allow_scrive: "Scrive (E-signering)",
  allow_fastdoc: "Fastighetsägarna dokument",
  allow_tenantportal: "Mina sidor",
  allow_fortnox: "Driftnetto (Fortnox)",
};
