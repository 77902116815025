import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { useFrequentPermissions, usePermissionCheck } from "../../store/base";
import { useDebtorInvoicing } from "../../store/invoicingDebtor";
import {
  authenticateAsCustomer,
  constants,
  overviewUrl,
  useCustomer,
} from "../../store/customers";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import TenantContracts from "./detail/Contracts";
import TenantInvoicing from "./detail/Invoicing";
import CustomerOverview from "./detail/Overview";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import { useDispatch } from "react-redux";

export default function CustomerDetail() {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const [customer] = useCustomer(customerId);
  const [invoiceSettingPromptOpen, setInvoicingSettingPromtOpen] =
    React.useState(false);
  const { push } = useHistory();
  const query = useQuery();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  usePermissionRedirect(["view_can_management"]);

  const canDeleteCustomer = usePermissionCheck("delete_can_management");
  const { hasBillectaViewPermission, hasBillectaFullPermission } =
    useFrequentPermissions();

  const showInvoicingSettingPrompt = query.get("showInvoicingSettingPrompt");

  const [debtorInvoiceConfig] = useDebtorInvoicing(
    customer?.debtor_invoice_config?.id
  );

  React.useEffect(() => {
    if (hasBillectaFullPermission && showInvoicingSettingPrompt == "true") {
      setInvoicingSettingPromtOpen(true);
    }
  }, [showInvoicingSettingPrompt, hasBillectaFullPermission]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Förvaltningsuppdrag",
      component: CustomerOverview,
    },
    {
      path: "/contracts",
      label: "Avtal",
      component: TenantContracts,
    },
  ];

  if (hasBillectaViewPermission) {
    SUB_PAGES.push({
      path: "/invoicing",
      label: "Fakturering",
      component: TenantInvoicing,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const handleActions = [];

  if (canDeleteCustomer) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
    });
  }

  const goToInvoicing = () => {
    push(`/customers/detail/${customerId}/invoicing?opensettings=true`);
  };

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={debtorInvoiceConfig ? 1 : 6}>
          {debtorInvoiceConfig
            ? "Uppsatt för fakturering"
            : "Ej uppsatt för fakturering"}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => {
    const connected = !customer?.is_not_connected;

    if (!connected) return <></>;

    return (
      <PrimaryButton
        title="Logga in som förvaltare"
        clicked={() => dispatch(authenticateAsCustomer(customerId))}
      />
    );
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={customer}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <ConfirmationModal
        isOpen={invoiceSettingPromptOpen}
        closeFunction={() => setInvoicingSettingPromtOpen(false)}
        title="Vill du konfigurera faktureringsinställningar för detta förvaltningsuppdrag direkt?"
        acceptTitle="Konfigurera nu"
        acceptCallback={goToInvoicing}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Förvaltningsuppdrag ${
            customer?.str_representation || "laddar..."
          }`}
          breadCrumbs={[
            { url: "/customers", label: "Förvaltningsuppdrag" },
            { label: customer?.str_representation || "Laddar..." },
          ]}
          eventContentType="management.customer"
          eventObjectId={customerId}
          eventIdQueryStr="customer"
          {...{
            subPages: subPageLinks,
            handleActions,
            renderInfoPills,
            renderActions,
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
