import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import {
  TimePicker,
  TextField,
  AsyncSelect,
  AddressField,
  TableSelect,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/calendarEvents";
import { useFilteredCalendars } from "../../../../../store/calendar";

import useActiveFilters from "../../../../../hooks/useActiveFilters";
import { TextInput } from "../../../Base/Fields";
import { buildQueryString } from "../../../../../store/base";
import { useSelector } from "react-redux";

import {
  constants as userConstants,
  useUserPagination,
  performFilter as userFilter,
  constructStrRep as userConstructStrRep,
} from "../../../../../store/users";
import userListDefs from "../../../../Lists/Users/listDefs";
export default ({ method, calendars }) => {
  const storeName = constants.STORE_NAME;

  const userId = useSelector((state) => state.app.user?.id);

  const userPersistantQuery = buildQueryString({
    user_type__in: [0, 1, 2],
    "user_id!": userId,
  });
  const userPersistantFilter = (obj) =>
    [0, 1, 2].includes(obj.user_type) && obj?.id != userId;

  return (
    <>
      <FormAreaTitle>Händelse</FormAreaTitle>
      <FormAreaDescription>Ange information om händelsen</FormAreaDescription>
      <AsyncSelect
        storeName={storeName}
        method={method}
        fieldKey={"calendar"}
        fetchMethod={() =>
          useFilteredCalendars(
            buildQueryString({
              outlook_calendar_id__isnull: true,
              owner: userId,
            })
          )
        }
        title={"Kalender"}
      />

      <TextInput
        storeName={storeName}
        title={"Titel på händelse"}
        method={method}
        fieldKey={"title"}
      />

      <TextField
        storeName={storeName}
        title={"Beskrivning"}
        method={method}
        fieldKey={"description"}
      />
      <DoubleFieldWrapper>
        <TimePicker
          storeName={storeName}
          title={"Start"}
          method={method}
          fieldKey={"start"}
          extraStyle={{ marginRight: 12 }}
        />
        <TimePicker
          storeName={storeName}
          title={"Slut"}
          method={method}
          fieldKey={"end"}
        />
      </DoubleFieldWrapper>

      <TableSelect
        storeName={storeName}
        tableStoreName={userConstants.STORE_NAME}
        method={method}
        fieldKey="participants"
        listDefs={userListDefs}
        paginationMethod={useUserPagination}
        persistantFilterMethod={userPersistantFilter}
        persistantQueryString={userPersistantQuery}
        performFilter={userFilter}
        fieldTitle="Deltagare"
        title="Välj deltagare..."
        description="Inbjudna deltagare kommer att se denna händelse i sina kalendrar och få notiser om händelsen."
        constructStrRep={userConstructStrRep}
        // m2m field
        isMany
        modalInModal
      />

      <AddressField
        storeName={storeName}
        method={method}
        fieldKey={"place"}
        title={"Plats"}
      />
    </>
  );
};
