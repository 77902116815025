import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as SC from "../styles";
import { cloneDeep } from "lodash";
import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../../store/base";
import { NumberInput } from "../../../Forms/Base/Fields";
import { CreateNewButton } from "../../../Forms/Base/Buttons";
import NonConnectedTextInput from "../../../Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { useFilteredCompanies } from "../../../../store/companies";
import InvoiceRecordsRow from "./InvoiceRecordsRow";

export default function InvoiceRecords({
  instance,
  constants,
  creditorId,
  method,
  matchRecords,
  disabled,
  isCreditInvoice,
  tenantHasEInvoiceActivated,
  setHasFinishedProductMatching,
  setVatToZero = false,
  periodStart,
  periodEnd,
}) {
  const dispatch = useDispatch();
  const [hasMatched, setHasMatched] = React.useState(false);

  const products = useSelector((state) => state.billectaInvoicing.products);

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const invoicePeriodStart = instance?.Records?.[0]?.PeriodStart;
  const invoicePeriodEnd = instance?.Records?.[0]?.PeriodEnd;

  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const usesCentRounding = company?.use_cent_rounding;

  const handleMatchProductsToDescription = () => {
    if (!instance?.Records) return;

    let RecordsCopy = cloneDeep(instance.Records);

    const otherProduct = products?.find((p) => p.Description === "Annan");

    RecordsCopy = RecordsCopy.map((r) => {
      const matchedProduct = products?.find(
        (p) =>
          p.Description.toLowerCase() === r.ArticleDescription.toLowerCase() ||
          r.ArticleDescription.toLowerCase()?.includes(
            p.Description.toLowerCase()
          ) ||
          p.Description.toLowerCase().includes(
            r.ArticleDescription.toLowerCase()
          )
      );

      if (matchedProduct) {
        return {
          ...r,
          ProductPublicId: matchedProduct.ProductPublicId,
          VAT: r.VAT != null ? r.VAT : setVatToZero ? 0 : 25,
        };
      } else if (otherProduct) {
        return {
          ...r,
          ProductPublicId: otherProduct.ProductPublicId,
          VAT: r.VAT != null ? r.VAT : setVatToZero ? 0 : 25,
        };
      }

      return r;
    });

    setHasMatched(true);

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: { Records: [] },
      })
    );

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: { Records: RecordsCopy },
      })
    );

    if (setHasFinishedProductMatching) {
      setHasFinishedProductMatching(true);
    }
  };

  const shouldMatch =
    matchRecords &&
    !hasMatched &&
    method === "POST" &&
    instance &&
    Object.keys(instance).length;

  React.useEffect(() => {
    if (shouldMatch) {
      handleMatchProductsToDescription();
    }
  }, [instance, products]);

  const onRemoveRecordClicked = (index) => {
    let copy = cloneDeep(instance.Records);
    copy.splice(index, 1);

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: { Records: copy },
      })
    );
  };

  const onAddRecordClicked = async () => {
    let copy = cloneDeep(instance.Records || []);
    copy.push({
      UnitPrice: {
        Value: 0,
        CurrencyCode: "SEK",
      },
      Units: "st",
      ArticleDescription: "",
      Quantity: 1,
      VAT: 25,
      VatIsIncluded: false,
      ProductPublicId: null,
      PeriodStart: periodStart
        ? periodStart
        : invoicePeriodStart
        ? invoicePeriodStart
        : undefined,
      PeriodEnd: periodEnd
        ? periodEnd
        : invoicePeriodEnd
        ? invoicePeriodEnd
        : undefined,
      RecordType: "Standard",
    });

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: { Records: copy },
      })
    );
  };

  const onAddMessageRowClicked = (headRowIndex) => {
    let copy = cloneDeep(instance.Records);
    copy.splice(headRowIndex + 1, 0, {
      ArticleDescription: "",
      RecordType: "Message",
    });

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: { Records: copy },
      })
    );
  };

  const getMaxVAT = () => {
    let maxVAT = instance?.Records?.reduce((acc, cur) => {
      const rowVAT = cur?.VAT;
      if (rowVAT > acc) return rowVAT;
      return acc;
    }, 0);

    if (!maxVAT) maxVAT = 0;

    return maxVAT;
  };

  const renderRecordsTitleRow = () => (
    <SC.InovicedBoxRecordsTitlesRow>
      <SC.InvoicedBoxRecordRowTitle percentageWidth="25">
        Beskrivning
      </SC.InvoicedBoxRecordRowTitle>

      <SC.InvoicedBoxRecordRowTitle percentageWidth="25">
        Produkt
      </SC.InvoicedBoxRecordRowTitle>

      <SC.InvoicedBoxRecordRowTitle percentageWidth="10">
        Kvantitet
      </SC.InvoicedBoxRecordRowTitle>
      <SC.InvoicedBoxRecordRowTitle percentageWidth="5">
        Enhet
      </SC.InvoicedBoxRecordRowTitle>
      <SC.InvoicedBoxRecordRowTitle percentageWidth="10">
        Á-pris
      </SC.InvoicedBoxRecordRowTitle>
      <SC.InvoicedBoxRecordRowTitle percentageWidth="10">
        Moms(%)
      </SC.InvoicedBoxRecordRowTitle>
      <SC.InvoicedBoxRecordRowTitle flexEnd percentageWidth="10">
        Totalt (ex. Moms)
      </SC.InvoicedBoxRecordRowTitle>
      <SC.InvoicedBoxRecordRowTitle percentageWidth="5"></SC.InvoicedBoxRecordRowTitle>
    </SC.InovicedBoxRecordsTitlesRow>
  );

  const renderRecordsBottomRow = () => {
    let totalNet = instance?.Records?.reduce((acc, cur) => {
      if (!cur?.UnitPrice?.Value) return acc;
      return acc + cur?.UnitPrice?.Value * (cur?.Quantity || 0);
    }, 0);

    // net portion of admin fee
    const adminFeeNet = instance?.InvoiceFee?.Value;
    // vat portion of admon fee
    const adminFeeVat =
      (instance?.InvoiceFee?.Value || 0) * (1 + getMaxVAT() / 100) -
      (instance?.InvoiceFee?.Value || 0);

    let totalVAT =
      instance?.Records?.reduce((acc, cur) => {
        if (!cur?.UnitPrice?.Value) return acc;

        return (
          acc +
          ((cur?.UnitPrice?.Value || 0) *
            (cur?.Quantity || 0) *
            (cur?.VAT || 0)) /
            100
        );
      }, 0) || 0;

    if (adminFeeVat) {
      totalVAT = totalVAT + adminFeeVat;
    }
    if (adminFeeNet) {
      totalNet = totalNet + adminFeeNet;
    }
    let totalWithVAT = totalNet + totalVAT;

    let centRounding = 0;

    if (usesCentRounding) {
      const totalRounded = Math.round(totalWithVAT);
      const diff = totalWithVAT - totalRounded;

      centRounding = diff * -1;
    }

    const totalPaid = totalWithVAT - centRounding * -1;

    return (
      <SC.InvoicedBoxRecordRowBottomRow>
        <SC.InvoicedBoxRecordRowContent percentageWidth="85" flexEnd>
          <div>
            <strong>Netto:</strong>{" "}
          </div>
          <div>
            <strong>Moms: </strong>
          </div>
          <div>
            <strong>Total ink. moms:</strong>
          </div>
          {usesCentRounding && (
            <div>
              <strong>Öresavrundning:</strong>
            </div>
          )}
          <div>
            <strong>{isCreditInvoice ? "Krediteras:" : "Att betala:"}</strong>
          </div>
        </SC.InvoicedBoxRecordRowContent>
        <SC.InvoicedBoxRecordRowContent percentageWidth="10" flexEnd>
          <div>{totalNet?.toFixed(2).toLocaleString("sv") || 0}</div>
          <div> {totalVAT?.toFixed(2).toLocaleString("sv") || 0}</div>
          <div>
            {" "}
            {(totalWithVAT || 0)?.toFixed(2).toLocaleString("sv") || 0}
          </div>
          {usesCentRounding && (
            <div>{(centRounding || 0)?.toFixed(2).toLocaleString("sv")}</div>
          )}
          <div>
            <span style={{ fontSize: ".6rem" }}>SEK&nbsp;</span>
            {(totalPaid || 0)?.toFixed(2).toLocaleString("sv")}
          </div>
        </SC.InvoicedBoxRecordRowContent>
      </SC.InvoicedBoxRecordRowBottomRow>
    );
  };

  const invoiceFeeTotal = instance?.InvoiceFee?.Value || 0;

  return (
    <SC.InvoicedBox>
      <SC.InvoicedBoxTitleWrapper>
        <SC.InvoicedBoxTitleTextWrapper>
          <SC.InvoicedBoxTitle>Fakturarader</SC.InvoicedBoxTitle>
          <SC.InvoicedBoxSubtitle>
            Detaljer kring vad som{" "}
            {isCreditInvoice ? "krediteras" : "faktureras"}. Tryck på{" "}
            <strong>"Detaljer"</strong> på en rad för att välja kostnadsställe
            och projekt.
          </SC.InvoicedBoxSubtitle>
        </SC.InvoicedBoxTitleTextWrapper>

        {!disabled && (
          <CreateNewButton clicked={onAddRecordClicked} title="Lägg till rad" />
        )}
      </SC.InvoicedBoxTitleWrapper>

      {renderRecordsTitleRow()}

      {((shouldMatch && hasMatched) || !shouldMatch) &&
        instance?.Records?.map((record, index) => (
          <InvoiceRecordsRow
            {...{
              record,
              index,
              instance,
              disabled,
              onRemoveRecordClicked,
              method,
              constants,
              creditorId,
              addDetailRow: onAddMessageRowClicked,
            }}
          />
        ))}

      {!tenantHasEInvoiceActivated && (
        <SC.InvoicedBoxRow key="adminfee">
          <SC.InvoicedBoxRecordRowContent percentageWidth="25">
            <NonConnectedTextInput
              noMargin
              noTitle
              rowSize
              value="Administrationsavgift"
              disabled
            />
          </SC.InvoicedBoxRecordRowContent>
          <SC.InvoicedBoxRecordRowContent percentageWidth="40"></SC.InvoicedBoxRecordRowContent>

          <SC.InvoicedBoxRecordRowContent percentageWidth="10">
            <NumberInput
              noTitle
              noMargin
              rowSize
              storeName={constants.STORE_NAME}
              method={method}
              fieldKey={"InvoiceFee.Value"}
              disabled={disabled}
            />
          </SC.InvoicedBoxRecordRowContent>
          <SC.InvoicedBoxRecordRowContent percentageWidth="10">
            <NonConnectedTextInput
              noMargin
              noTitle
              rowSize
              value={`${getMaxVAT()}%`}
              disabled
            />
          </SC.InvoicedBoxRecordRowContent>
          <SC.InvoicedBoxRecordRowContent flexEnd percentageWidth="10">
            {invoiceFeeTotal}
          </SC.InvoicedBoxRecordRowContent>
        </SC.InvoicedBoxRow>
      )}

      {renderRecordsBottomRow()}
    </SC.InvoicedBox>
  );
}
