import * as React from "react";
import { detailUrl } from "../../../store/companies";
import { MultiEntryLinks } from "../../Displays";
import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => {
  const cols = [
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kategori",
      accessor: "category_display",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Bolag som använder produkten",
      accessor: (row) => {
        return row.companies?.map((c) => c.str_representation)?.join(", ");
      },
      Cell: ({ row }) => {
        const companies = row.original.companies || [];
        let mapping = [];
        let restLength = 0;

        companies.forEach((c) => {
          mapping.push({ obj: c, urlMethod: detailUrl });
        });

        if (mapping.length > 2) {
          restLength = mapping.length - 2;
          mapping = mapping.slice(0, 2);
        }

        return <MultiEntryLinks mappings={mapping} restLength={restLength} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Intäktskonto",
      accessor: "account_no",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Utgående moms",
      accessor: "account_no_vat",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Intäktskonto EU",
      accessor: "account_no_eu",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Konto försäljning EU moms",
      accessor: "account_no_eu_vat",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Konto köp",
      accessor: "account_no_purchase",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Konto köp moms",
      accessor: "account_no_purchase_vat",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Konto försäljning icke-EU",
      accessor: "account_no_non_eu",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
