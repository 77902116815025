import outlookLogo from "../../assets/svg/outlook-icon-blue.svg";
import unit4Logo from "../../assets/img/unit4.svg";
import bankgiroLogo from "../../assets/img/bankgirot-logo.png";
import kivraLogo from "../../assets/img/kivra.svg";
import fortnoxLogo from "../../assets/img/fortnox-logo.svg";
import peLogo from "../../assets/img/pe-logo.png";
import vismaLogo from "../../assets/img/visma-logo.jpg";
import scriveLogo from "../../assets/img/scrive_logo.svg";
import fastdocLogo from "../../assets/svg/fastdoc.svg";

export default [
  {
    title: "Scrive",
    subtitle: "Digital signering",
    image: scriveLogo,
    description:
      "Integrationen mot Scrive tillåter Pigello-användare att skicka ut dokument och avtal för digital signering direkt via Pigello.",
    path: "/integrations/scrive",
    visitUrl: "https://www.scrive.com/sv",
  },
  {
    title: "Bankgirots Autogiro",
    subtitle: "Bankkoppling",
    image: bankgiroLogo,
    description:
      "Med Autogiro kopplat mot Pigellos faktureringsmodul kan fakturor automatiskt debiteras från mottagarens bankkonto.",
    path: "/integrations/autogiro",
    visitUrl: "https://www.bankgirot.se/autogiro",
  },
  {
    title: "Kivra",
    subtitle: "Digital brevlåda",
    image: kivraLogo,
    description:
      "Integrationen Kivra tillåter Pigellos faktureringsmodul att skicka fakturor direkt till Kivra",
    path: "/integrations/kivra",

    visitUrl: "https://kivra.se/sv/foretag",
  },
  {
    title: "Fortnox bokföring",
    subtitle: "Bokföringssystem",
    image: fortnoxLogo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos faktureringsmodul med integrationen mot Fortnox.",
    path: "/integrations/fortnox-accounting",
    visitUrl: "https://www.fortnox.se/",
  },
  {
    title: "PE Accounting",
    subtitle: "Bokföringssystem",
    image: peLogo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos faktureringsmodul med integrationen mot PE Accounting.",
    path: "/integrations/pe-accounting",
    visitUrl: "https://www.accounting.pe/sv/",
  },
  {
    title: "Visma E-ekonomi",
    subtitle: "Bokföringssystem",
    image: vismaLogo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos faktureringsmodul med integrationen mot Visma E-ekonomi.",
    path: "/integrations/visma-accounting",
    visitUrl: "https://vismaspcs.se/produkter/bokforingsprogram/visma-eekonomi",
  },
  {
    title: "Unit4 ERP",
    subtitle: "Bokföringssystem",
    image: unit4Logo,
    description:
      "Synka och bokför automatiskt transaktioner från Pigellos faktureringsmodul med integrationen mot Unit4 ERP.",
    path: "/integrations/unit-4-erp",
    visitUrl: "https://www.unit4.com/sv",
  },

  {
    title: "Outlook",
    subtitle: "Microsofts tjänst för E-post",
    image: outlookLogo,
    description: `Integrationen mot Outlook tillåter användare att se sina kalenderhändelser från Outlook direkt i Pigellos "Min arbetsdag" och kalendern.`,
    path: "/integrations/outlook",
    visitUrl: "https://outlook.live.com/owa/",
  },
];
