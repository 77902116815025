import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { axiosInstance } from "../../../store/base/store/axios";
import { getAllPayments } from "../../../store/billectaInvoicing";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import NonConnectedInputField from "../../Forms/Base/Old/NonConnected/NonConnectedInputField";

export default function DevCreateUnmatchedPayment() {
  const dispatch = useDispatch();
  const [reference, setReference] = React.useState("");
  const [date, setDate] = React.useState("");
  const [val, setVal] = React.useState("");

  const { creditorId } = useParams();

  const createPayment = async () => {
    await axiosInstance.post(`/accounting/gateways/dev/unhandled_payment/`, {
      CreditorPublicId: creditorId,
      Amount: { Value: parseInt(val, 10) * 100, CurrencyCode: "SEK" },
      PaymentDate: date,
      Reference: reference,
      UnhandledPaymentType: "UnmatchedPayment",
    });

    dispatch(getAllPayments(creditorId));
  };

  return (
    <>
      <div style={{ fontWeight: "600", fontSize: "1.2rem" }}>
        DEV skapa ickematchad betalning
      </div>

      <NonConnectedInputField
        title="Referens"
        value={reference}
        onChange={({ target: { value } }) => setReference(value)}
      />
      <NonConnectedInputField
        title="Datum (YYYY-MM-DD)"
        value={date}
        onChange={({ target: { value } }) => setDate(value)}
      />
      <NonConnectedInputField
        title="Summa"
        value={val}
        onChange={({ target: { value } }) => setVal(value)}
      />

      <PrimaryButton
        clicked={createPayment}
        title="Skapa ickematchad betalning (DEV)"
      />
    </>
  );
}
