import * as React from "react";
import { cloneDeep, set } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  useManagementContractForm,
  constants,
  create,
  destroyPostForm,
  createCostsUrl,
} from "../../../../store/managementContracts";
import useQuery from "../../../utils/useQuery";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import StepDisplay from "../../../StepDisplay/StepDisplay";

export default ({ method = "POST" }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const formLoaded = Boolean(useManagementContractForm(method));
  const { premisesKind, contractType } = useParams();
  const { replace } = useHistory();

  const query = useQuery();
  const isManualSign = query.get("manual_sign") === "true";

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(createCostsUrl({ id: returned.id }));
  };

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(false));
    };
  }, []);

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data, contractType, isManualSign }),
      })
    );
  };

  const renderSteps = () => {
    const steps = ["1. Avtal", "2. Kostnader & Avisering"];

    if (contractType !== "existing") {
      steps.push("3. Dokument för signering");
    }

    return <StepDisplay steps={steps} activeIndex={0} />;
  };

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      {renderSteps()}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs({
            premisesKind,
            contractType,
            isManualSign,
          }),
          chapters,
          descriptions,
          method,
          onSubmit,
          displayDocumentFieldKey: "editabledoc.docData_",
          title:
            method === "POST"
              ? "Lägg till förvaltningsavtal"
              : "Uppdatera förvaltningsavtal",
        }}
      />
    </>
  );
};

const preProcess = ({ data, contractType, isManualSign }) => {
  const copy = cloneDeep(data);

  copy.pre_existing = contractType === "existing";
  copy.uses_e_signing = !isManualSign;

  if (copy.renew_indefinetely === "no_renewal") {
    copy.renew_indefinetely = false;
    copy.renew_interval = null;
  }

  // always create an editable doc
  if (!copy.editabledoc?.title) {
    set(copy, "editabledoc.title", copy.id_number || "Dokument");
  }

  if (copy.indexation) {
    if (!copy.indexation.index_min_val) {
      copy.indexation.index_min_val = 0;
    }

    if (!copy.indexation.index_amount) {
      copy.indexation = null;
    }
  }

  // can't send null to backend
  if (copy.renew_indefinetely === null) {
    delete copy.renew_indefinetely;
  }

  return copy;
};
