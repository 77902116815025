import { getBase64 } from "../../components/EditableDocBase/Templates/utils";

export const constructStrRep = (obj) => {
  if (obj.str_representation) return obj.str_representation;
  if (obj.id_number) return obj.id_number;
  return "Förvaltningsavtal";
};

export const createUrl = ({ existing, digitalSign }) => {
  return `/management-contracts/create/${
    existing ? "existing" : "new"
  }?manual_sign=${!digitalSign}`;
};
export const createCostsUrl = ({ id }) => {
  return `/management-contracts/create/costs/${id}`;
};

export const detailUrl = ({ id }) => {
  return `/management-contracts/detail/${id}`;
};
export const overviewUrl = () => {
  return `/management-contracts`;
};
export const cancelUrl = ({ id, isManualSign }) => {
  return `/management-contracts/cancel/${id}${
    isManualSign ? "?manual_sign=true" : ""
  }`;
};

export const handleManyInvoicingUrl = () => {
  return "/management-contracts/handle-invoicing-statuses";
};

export const isCancelSigning = (contract) => {
  if (!contract) {
    return false;
  }
  const possibleInitial = contract?.is_signed === false;
  const possibleClosing =
    [6, 7].includes(contract?.state) &&
    !contract?.closed_signed &&
    !possibleInitial;

  return possibleClosing;
};

const CLONE_COPY_KEYS = [
  "bi_tenants",
  "editabledoc",
  "end_date",
  "invoice_month_interval",
  "landlord",
  "notify_interval",
  "notify_time",
  "pre_existing",
  "realestate",
  "renew_indefinetely",
  "renew_interval",
  "start_date",
  "uses_e_signing",
  "warning_interval",
  "warning_time",
];

const CLONE_OTHER_CONTRACT_KEYS = [
  "cost_is_yearly",
  "end_date",
  "start_date",
  "title",
  "value",
  "vat",
];

const CLONE_EDITABLEDOC_KEYS = [
  "attachments",
  "category",
  "confirmation_message",
  "days_to_remind",
  "days_to_sign",
  "doc",
  "fastdoc_id",
  "has_scrive",
  "invitation_message",
  "is_public",
  "is_template",
  "parties",
  "sms_confirmation_message",
  "sms_invitation_message",
  "title",
];

const CLONE_PARTIES_KEYS = [
  "authentication_method_to_sign",
  "authentication_method_to_view",
  "category",
  "confirmation_delivery_method",
  "delivery_method",
  "fields",
  "hide_personal_number",
  "sign_order",
  "signatory_role",
];

const CLONE_FIELDS_KEYS = [
  "is_checked",
  "is_obligatory",
  "kind",
  "name",
  "placements",
  "should_be_filled_by_sender",
  "value",
];

const cleanFields = (fields) => {
  const source = [];

  fields.forEach((field) => {
    const f = {};
    Object.keys(field).forEach((key) => {
      if (CLONE_FIELDS_KEYS.includes(key)) {
        f[key] = field[key];
      }
    });

    source.push(f);
  });

  return source;
};

const cleanParties = (parties) => {
  const source = [];

  parties.forEach((party) => {
    const p = {};
    Object.keys(party).forEach((key) => {
      if (CLONE_PARTIES_KEYS.includes(key)) {
        p[key] = party[key];
      }
    });

    if (p["fields"]) {
      p["fields"] = cleanFields(p["fields"]);
    }

    source.push(p);
  });

  return source;
};

const cleanEditableDoc = async (editableDoc) => {
  const source = {};

  Object.keys(editableDoc).forEach((key) => {
    if (CLONE_EDITABLEDOC_KEYS.includes(key)) {
      source[key] = editableDoc[key];
    }
  });

  const doc = source["doc"];
  if (doc) {
    const isFastdoc = !!source.fastdoc_id;

    if (isFastdoc) {
      source.doc = "doc.pdf";
    } else {
      if (doc?.get) {
        const b64 = await getBase64(doc.get);
        if (b64) {
          source.docData_ = b64;
          source.doc = `${source?.title || "doc"}.pdf`;
        }
      }
    }
  }

  if (source["parties"]) {
    source["parties"] = cleanParties(source["parties"]);
  }

  return source;
};

export const cleanClone = async (leaseContract) => {
  const source = {};

  Object.keys(leaseContract).forEach((key) => {
    if (CLONE_COPY_KEYS.includes(key)) {
      source[key] = leaseContract[key];
    }
  });

  if (source["editabledoc"]) {
    source["editabledoc"] = await cleanEditableDoc(source["editabledoc"]);
  }

  if (source.editabledoc?.fastdoc_id) {
    source._chosenTemplate = {
      id: source.editabledoc?.fastdoc_id,
      pay: false,
    };
  }

  return source;
};
