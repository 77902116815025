import * as React from "react";

// style, design
import * as SharedStyles from "../../../components/sharedStyles";

// store, state

import IntegrationsContainer from "../../../components/Billecta/Integrations/IntegrationsContainer";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

function CompanyIntegrations() {
  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Integrationer</OverviewTitle>
        </OverviewTitleWrapper>

        <IntegrationsContainer />
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}

export default CompanyIntegrations;
