import * as React from "react";
// style, design
import { DoubleFieldWrapper } from "../../../Base/Chapters/styles";
import { RadioGroup, Select, TextInput } from "../../../Base/Fields";

// store, states
import { constants } from "../../../../../store/invoicingDebtor";
import { useFormInstanceField } from "../../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const useAutoGiro = useFormInstanceField({
    storeName,
    fieldKey: "use_autogiro",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Betalmetoder</OverviewTitle>
          <OverviewSubtitle>
            Ställ in eventuell betalmetod som ska användas för automatiskt
            debitering av kund
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <Select
        title="Intermediator"
        description="Välj av en de som finns i listan. Om ni är osäker på vilken intermediatör er kund har så behöver ni kontakta er kund."
        {...{
          storeName,
          method,
          fieldKey: `intermediator`,
        }}
      />

      <TextInput
        title="GLN"
        description="Denna uppgift är frivillig men om ni anger detta så minskar det risken för att er kund inte hittas i e-fakturanätverket."
        {...{
          storeName,
          method,
          fieldKey: `gln`,
        }}
      />

      <RadioGroup
        title="Autogiro"
        options={[
          { label: "Använd autogiro", value: true },
          { label: "Använd ej autogiro", value: false },
        ]}
        defaultValue={false}
        {...{
          storeName,
          method,
          fieldKey: `use_autogiro`,
        }}
      />

      {useAutoGiro && (
        <>
          {" "}
          <TextInput
            title="Autogiroleverantör"
            description="Namnet på autogiroleverantören, t.ex. SHB"
            {...{
              storeName,
              method,
              fieldKey: `autogiro_payment_service`,
            }}
          />
          <TextInput
            title="Autogiro betalarnummer"
            extraStyles={{ minWidth: "150px", marginRight: "20px" }}
            {...{
              storeName,
              method,
              fieldKey: `autogiro_payer_number`,
            }}
          />
          <DoubleFieldWrapper>
            <TextInput
              title="Autogiro clearing"
              extraStyles={{ minWidth: "150px", marginRight: "20px" }}
              {...{
                storeName,
                method,
                fieldKey: `autogiro_clearing`,
              }}
            />

            <TextInput
              title="Autogironummer"
              extraStyles={{ minWidth: "290px" }}
              {...{
                storeName,
                method,
                fieldKey: `autogiro_number`,
              }}
            />
          </DoubleFieldWrapper>
          <TextInput
            title="Externt referens-ID"
            extraStyles={{ minWidth: "150px", marginRight: "20px" }}
            {...{
              storeName,
              method,
              fieldKey: `external_id`,
            }}
          />{" "}
        </>
      )}
    </>
  );
};
