export default [
  {
    title: "Aviseringsinställning",
    key: "INVOICING_SETTING",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "message",
      "require_cost_accept",
      "auto_merge",
      "auto_attest",
    ],
  },
  {
    title: "Intervall",
    key: "INTERVAL",
    hasError: false,
    fieldKeys: ["interval_setting"],
  },
  {
    title: "Bokföring",
    key: "ACCOUNTING",
    hasError: false,
    fieldKeys: ["project", "cost_center"],
  },
  {
    title: "Betalningsvillkor",
    key: "DEBT_SETTING",
    hasError: false,
    fieldKeys: ["debt_setting"],
  },

  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["id"],
  },
];
