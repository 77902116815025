import styled, { css } from "styled-components";

export const VATReportWrapper = styled.div`
  width: 100%;
  border: thin solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
`;

export const VATReportHeader = styled.div`
  background-color: #eceff1;
  padding: 12px;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  color: ${(p) => p.theme.colors.textPrimary};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VATReportRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;

  &:nth-child(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }
`;

export const VATReportTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  color: ${(p) => p.theme.colors.textPrimary};
  display: flex;
  flex-direction: column;

  ${(p) =>
    p.bold &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerSmall};
    `}
`;

export const VATReportValue = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  color: ${(p) => p.theme.colors.textPrimary};

  ${(p) =>
    p.bold &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerSmall};
    `}
`;
