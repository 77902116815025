import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import InternalCustomerTable from "../../../components/Lists/InternalCustomers/FullTable";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { axiosInstance } from "../../../store/base/store/axios";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useHistory } from "react-router-dom";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import StandardModal from "../../../components/Modals/StandardModal";
import { cloneDeep, conforms } from "lodash";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { clearFetched } from "../../../store/base";
import { constants as customerConstants } from "../../../store/customers";

const STEPS = {
  SELECT_CUSTOMER: 1,
  CONNECT: 2,
  CONFIRM: 3,
};

/* 

FORMAT FOR CONNECTION 

{
  customerId: internalCustomerId
}

customer id is the management client, management.customer
internalCustomerId is the Pigello database client 
*/

export default function ConnectManagementAccounts() {
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();
  const [step, setStep] = React.useState(STEPS.SELECT_CUSTOMER);
  const [loading, setLoading] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [editConnection, setEditConnection] = React.useState(null);
  const [
    currentInternalCustomerConnectionData,
    setCurrentInternalCustomerConnectionData,
  ] = React.useState(null);
  const [customerConnections, setCustomerConnections] = React.useState({});

  React.useEffect(() => {
    setCurrentInternalCustomerConnectionData(null);
    setCustomerConnections({});
  }, [selectedCustomer]);

  const getManagementConnections = async (customerId) => {
    try {
      const res = await axiosInstance.get(
        `/sales/internalcustomer/${customerId}/management_connect/`
      );

      setCurrentInternalCustomerConnectionData(res.data);
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Data kunde inte hämtas",
          description:
            'Detta beror förmodligen på att denna kund saknar rättigheter till "Pigello Förvaltning"',
        })
      );
    }
  };

  React.useEffect(() => {
    if (selectedCustomer && selectedCustomer?.id) {
      getManagementConnections(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  const handleSelectedCustomer = ({ original: customer }) => {
    setSelectedCustomer(customer);
    setStep(STEPS.CONNECT);
  };

  const handleConnect = (internalCustomerId, customerId) => {
    const connectionClone = cloneDeep(customerConnections);

    connectionClone[customerId] = internalCustomerId;

    setCustomerConnections(connectionClone);

    setEditConnection(null);
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const res = await axiosInstance.patch(
        `/sales/internalcustomer/${selectedCustomer.id}/management_connect/`,
        customerConnections
      );

      console.log({ res });

      clearFetched(customerConstants, true);

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Kopplingarna sparades",
        })
      );

      push("/admin");
    } catch (e) {
      setLoading(false);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel",
          description: JSON.stringify(e),
        })
      );
    }
  };

  const steps = ["1. Välj kund", "2. Koppla konton", "3. Granska ändringar"];

  const selectStep = () => (
    <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Välj kund</OverviewTitle>
          <OverviewSubtitle>
            Klicka på en kund för att gå till nästa steg
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InternalCustomerTable
        hideColumns
        hideExport
        hideTitle
        onRowClicked={handleSelectedCustomer}
      />
    </DetailPageBox>
  );

  const connectStep = () => (
    <>
      <StandardModal
        title="Koppla förvaltningsuppdrag mot kund"
        isOpen={editConnection != null}
        closeFunction={() => setEditConnection(null)}
        withActionBar
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Välj kund att koppla mot</OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <PremisesTable
          headers={["Kund"]}
          rows={currentInternalCustomerConnectionData?.internal_customers
            ?.filter((ic) => {
              return !Object.values(customerConnections || {}).includes(ic.id);
            })
            .map((c) => [c.str_representation])}
          onClickRowMapping={currentInternalCustomerConnectionData?.internal_customers?.map(
            (c) => c.id
          )}
          onRowClicked={(id) => {
            handleConnect(id, editConnection);
          }}
        />
      </StandardModal>

      <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
        <TextButton
          extraStyle={{ marginBottom: 8 }}
          title="Gå tillbaka"
          iconType="arrow-back"
          clicked={() => {
            setSelectedCustomer(null);
            setStep(STEPS.SELECT_CUSTOMER);
          }}
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Koppla förvaltningskonton för{" "}
              {selectedCustomer?.str_representation}
            </OverviewTitle>
            <OverviewSubtitle>
              Välj vilket Förvaltningsuppdrag du vill koppla. Kopplas ett
              förvaltningsuppdrag som redan har en koppling så skrivs den äldre
              över. Varje kundbolag (databas) kan enbart ha ett förvaltande
              bolag.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {currentInternalCustomerConnectionData ? (
          <PremisesTable
            onRowClicked={(id) => {
              setEditConnection(id);
            }}
            onClickRowMapping={currentInternalCustomerConnectionData?.customers?.map(
              (c) => c.id
            )}
            headers={["Förvaltningsuppdrag", "Koppling"]}
            rows={currentInternalCustomerConnectionData?.customers?.map((c) => {
              return [
                c.str_representation,
                c.already_has_connection ? (
                  <StatusLabel state={4}>Existerande</StatusLabel>
                ) : customerConnections[c.id] ? (
                  <StatusLabel state={0}>
                    Ny koppling (
                    {currentInternalCustomerConnectionData?.internal_customers?.find(
                      (ic) => {
                        return ic.id === customerConnections[c.id];
                      }
                    )?.str_representation || ""}
                    )
                  </StatusLabel>
                ) : (
                  <StatusLabel state={3}>Ingen</StatusLabel>
                ),
              ];
            })}
          />
        ) : (
          "Laddar..."
        )}

        <div
          style={{ display: "flex", justifyContent: "flex-end", minWidth: 200 }}
        >
          <PrimaryButton
            disabled={!Object.keys(customerConnections)?.length > 0}
            title="Fortsätt"
            clicked={() => setStep(STEPS.CONFIRM)}
          />
        </div>
      </DetailPageBox>
    </>
  );

  const confirmStep = () => (
    <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Gå tillbaka"
        iconType="arrow-back"
        clicked={() => {
          setSelectedCustomer(null);
          setStep(STEPS.CONNECT);
        }}
      />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>
            Granska och godkänn de nya kopplingarna för{" "}
            {selectedCustomer?.str_representation}
          </OverviewTitle>
          <OverviewSubtitle>
            Tryck på "Spara kopplingar" för att spara kopplingarna
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <PremisesTable
        headers={["Förvaltningsuppdrag", "Nykopplad kund"]}
        rows={Object.keys(customerConnections || {}).map((customerId) => {
          const internalCustomerId = customerConnections[customerId];

          const customer =
            currentInternalCustomerConnectionData?.customers?.find(
              (c) => c.id == customerId
            );
          const internalCustomer =
            currentInternalCustomerConnectionData?.internal_customers?.find(
              (c) => c.id == internalCustomerId
            );

          return [
            customer?.str_representation,
            internalCustomer?.str_representation,
          ];
        })}
      />

      <div>
        <PrimaryButton title="Spara kopplingar" clicked={onSubmit} />
      </div>
    </DetailPageBox>
  );

  return (
    <DetailInnerWrapper>
      {loading && <OverlaySpinner />}
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Avbryt koppling "
        iconType="arrow-back"
        clicked={() => goBack()}
      />
      <StepDisplay steps={steps} activeIndex={step - 1} />

      {step === STEPS.SELECT_CUSTOMER && <>{selectStep()}</>}
      {step === STEPS.CONNECT && <>{connectStep()}</>}
      {step === STEPS.CONFIRM && <>{confirmStep()}</>}
    </DetailInnerWrapper>
  );
}
