import * as React from "react";
import { useHistory } from "react-router";
import { useInvoicingCostCenter } from "../../store/invoicingCostCenters";
import {
  useInvoicingDebtSetting,
  detailUrl as debtDetailUrl,
} from "../../store/invoicingDebtSettings";

import { useInvoicingProject } from "../../store/invoicingProjects";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";
import { LinkedObject } from "../Displays";
import { PrimaryButton, TextButton } from "../Forms/Base/Buttons";
import { StatusLabel } from "../Lists/Base/CompleteList/styles";
import * as SC from "./styles";
import { detailUrl as projectUrl } from "../../store/invoicingProjects";
import { detailUrl as costCenterUrl } from "../../store/invoicingCostCenters";
import { getIntervalStr } from "../Displays/InvoicingHelpers";

/**
 * Expects entire invoicingSetting, with debtSetting nestled
 * Can also receive an invoicing obj, which overwrites default
 * settings from the main setting
 * LinkedDebtSetting indicates that the debt setting should be
 * linked to instead of displayed directly
 */
export default ({ setting, linkedDebtSetting, onCopy }) => {
  const [debtSetting] = useInvoicingDebtSetting(setting?.debt_setting?.id);
  const { push } = useHistory();

  const [defaultProject] = useInvoicingProject(setting?.project?.id);
  const [defaultCostCenter] = useInvoicingCostCenter(setting?.cost_center?.id);

  if (!setting) return null;

  const getDueDateStr = () => {
    const { debt_setting } = setting;

    if (debt_setting?.contract_pay_last_day_month) return "Den sista i månaden";

    return `Efter ${debt_setting?.contract_payment_term_days} dagar`;
  };

  return (
    <>
      <SC.Container>
        <SC.PrimaryCard>
          <OverviewTitleWrapper style={{ padding: "0 12px" }}>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Aviseringsinställningar</OverviewTitle>
              <OverviewSubtitle>{setting?.str_representation}</OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <SC.DataRow>
            <SC.DataTitle>Kräv godkännande av kostnader</SC.DataTitle>
            <SC.DataContent>
              {setting?.require_cost_accept ? (
                <StatusLabel state={0}>Aktiv</StatusLabel>
              ) : (
                <StatusLabel state={6}>Inaktiv</StatusLabel>
              )}
            </SC.DataContent>
          </SC.DataRow>

          <SC.DataRow>
            <SC.DataTitle>Sammanför automatiskt fakturor</SC.DataTitle>
            <SC.DataContent>
              {setting?.auto_merge ? (
                <StatusLabel state={0}>Aktiv</StatusLabel>
              ) : (
                <StatusLabel state={6}>Inaktiv</StatusLabel>
              )}
            </SC.DataContent>
          </SC.DataRow>

          <SC.DataRow>
            <SC.DataTitle>Automatisk attestering</SC.DataTitle>
            <SC.DataContent>
              {setting?.auto_attest ? (
                <StatusLabel state={0}>Aktiv</StatusLabel>
              ) : (
                <StatusLabel state={6}>Inaktiv</StatusLabel>
              )}
            </SC.DataContent>
          </SC.DataRow>

          <SC.DataRow>
            <SC.DataTitle>Standardprojekt</SC.DataTitle>
            <SC.DataContent>
              {defaultProject?.str_representation ? (
                <LinkedObject
                  openInNewTab
                  obj={defaultProject}
                  urlMethod={projectUrl}
                />
              ) : (
                "Inget valt"
              )}
            </SC.DataContent>
          </SC.DataRow>

          <SC.DataRow>
            <SC.DataTitle>Standard kostnadsställe</SC.DataTitle>
            <SC.DataContent>
              {defaultCostCenter?.str_representation ? (
                <LinkedObject
                  openInNewTab
                  obj={defaultCostCenter}
                  urlMethod={costCenterUrl}
                />
              ) : (
                "Inget valt"
              )}
            </SC.DataContent>
          </SC.DataRow>

          <SC.DataRow>
            <SC.DataTitle>Intervall</SC.DataTitle>
            <SC.DataContent>
              {getIntervalStr(setting?.interval_setting)}
            </SC.DataContent>
          </SC.DataRow>

          <SC.DataRow>
            <SC.DataTitle>Faktureringsperiod</SC.DataTitle>
            <SC.DataContent>
              {setting?.interval_setting?.invoice_period_display}
            </SC.DataContent>
          </SC.DataRow>

          <SC.DataRow>
            <SC.DataTitle>Fakturor förfaller</SC.DataTitle>
            <SC.DataContent>{getDueDateStr()}</SC.DataContent>
          </SC.DataRow>
        </SC.PrimaryCard>

        {!linkedDebtSetting && debtSetting && (
          <>
            <SC.PrimaryCard>
              <OverviewTitleWrapper style={{ padding: "0 12px" }}>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Dröjsmålsränta</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <SC.DataRow>
                <SC.DataTitle>Typ av ränta</SC.DataTitle>
                <SC.DataContent>
                  {debtSetting?.interest_type_display}
                </SC.DataContent>
              </SC.DataRow>

              <SC.DataRow>
                <SC.DataTitle>Ränta</SC.DataTitle>
                <SC.DataContent>
                  {debtSetting?.interest_percentage}%
                </SC.DataContent>
              </SC.DataRow>

              <SC.DataRow>
                <SC.DataTitle>Börjar räknas</SC.DataTitle>
                <SC.DataContent>
                  {debtSetting?.interest_start_due_date} dagar efter
                  förfallodatum
                </SC.DataContent>
              </SC.DataRow>

              {debtSetting?.reason_higher_interest && (
                <SC.DataRow>
                  <SC.DataTitle>Anledning till hög ränta</SC.DataTitle>
                  <SC.DataContent>
                    {debtSetting?.reason_higher_interest}
                  </SC.DataContent>
                </SC.DataRow>
              )}
            </SC.PrimaryCard>

            <SC.PrimaryCard>
              <OverviewTitleWrapper style={{ padding: "0 12px" }}>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Påminnelser</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <SC.DataRow>
                <SC.DataTitle>Skicka påminnelser</SC.DataTitle>
                <SC.DataContent>
                  {debtSetting.use_reminders ? (
                    <StatusLabel state={0}>Aktiv</StatusLabel>
                  ) : (
                    <StatusLabel state={6}>Inaktiv</StatusLabel>
                  )}
                </SC.DataContent>
              </SC.DataRow>

              {debtSetting.use_reminders && (
                <>
                  {" "}
                  <SC.DataRow>
                    <SC.DataTitle>Antal</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.number_of_reminders}
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Skickas</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.send_reminder_after_days} dagar efter
                      förfallodatum
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Betalvillkor</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.reminder_term_days} dagar
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Påminnelseavgift</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.reminder_fee} SEK
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Nådedagar</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.reminder_grace_days === 0
                        ? "Inga"
                        : `${debtSetting?.reminder_grace_days} dagar`}
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Minsta belopp för påminnelse</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.reminder_grace_amount === 0
                        ? "Skickas alltid"
                        : `${debtSetting?.reminder_grace_amount} SEK`}
                    </SC.DataContent>
                  </SC.DataRow>
                </>
              )}
            </SC.PrimaryCard>

            <SC.PrimaryCard>
              <OverviewTitleWrapper style={{ padding: "0 12px" }}>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Automatisk kravhantering</OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <SC.DataRow>
                <SC.DataTitle>Använd kravhantering</SC.DataTitle>
                <SC.DataContent>
                  {debtSetting?.use_debt_collection ? (
                    <StatusLabel state={0}>Aktiv</StatusLabel>
                  ) : (
                    <StatusLabel state={6}>Inaktiv</StatusLabel>
                  )}
                </SC.DataContent>
              </SC.DataRow>
              {debtSetting?.use_debt_collection && (
                <>
                  <SC.DataRow>
                    <SC.DataTitle>Inleds</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.send_to_debt_after_days} dagar efter
                      förfallodatum
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Krav inleds med</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.debt_start_mode_display}
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Krav avslutas med</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.debt_end_mode_display}
                    </SC.DataContent>
                  </SC.DataRow>
                  <SC.DataRow>
                    <SC.DataTitle>Betalvillkor</SC.DataTitle>
                    <SC.DataContent>
                      {debtSetting?.debt_term_days} dagar
                    </SC.DataContent>
                  </SC.DataRow>
                </>
              )}
            </SC.PrimaryCard>
          </>
        )}

        {onCopy && (
          <SC.ActionBanner>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle style={{ color: "white" }} small>
                  Vill du göra ändringar?
                </OverviewTitle>
                <OverviewSubtitle style={{ color: "white" }}>
                  Aviseringsinställningar kan inte redigeras från denna vy då
                  det kan påverka andra avtal som använder sig av denna
                  inställning. Kopiera denna inställning och skapa ny för att
                  redigera inställningarna.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <PrimaryButton
                inverted
                title="Skapa ny inställning utifrån den valda"
                clicked={onCopy}
              />
            </div>
          </SC.ActionBanner>
        )}
      </SC.Container>

      {linkedDebtSetting && debtSetting && (
        <TextButton
          extraStyle={{ marginTop: 24 }}
          title={`Använder betalningsvillkorsinställning ${debtSetting?.str_representation}`}
          iconType="arrow"
          iconPlacement="right"
          clicked={() => push(debtDetailUrl({ id: debtSetting?.id }))}
        />
      )}
    </>
  );
};
