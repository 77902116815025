import { cloneDeep } from "lodash";
import * as React from "react";

import Table from "../../../../Lists/Base/CompleteList/Table";
import StandardModal from "../../../../Modals/StandardModal";
import DescriptionToolTip from "../../Layout/DescriptionToolTip";
import {
  ClearSelectButton,
  InputFieldTitle,
  InputSpacing,
  M2mLabel,
  M2mLabelRemoveButton,
  SelectButton,
} from "../../Fields/styles";

export default ({
  selected,
  required,
  tableStoreName,
  listDefs,
  paginationMethod,
  persistantFilterMethod,
  persistantQueryString,
  extraColumnsDefsArgs = [],
  filterInstructions,
  performFilter,
  forceFrontendColumns,
  fieldTitle,
  title,
  description,
  onEditClicked,
  constructStrRep,
  handleChange,
  disabled,
  noMargin,
  noTitle,
  extraStyles = {},

  // m2m field
  isMany,
  modalInModal,

  // set entire object in state instead of just id, non m2m
  setWholeObject,

  // string for display, comes from entire object, used in patch to display correct title
  patchDisplay,
  overrideOpen,
  overrideCloseFunction = false,
  closeCallback,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const columns = React.useMemo(
    () => listDefs(...extraColumnsDefsArgs),
    [persistantFilterMethod, persistantQueryString, ...extraColumnsDefsArgs]
  );

  const fetchAllTreshold = 25;

  const checkRowHighlighted = (row) => {
    const { original } = row;

    let match = false;

    if (isMany) {
      match = selected?.find((s) => s.id === original.id);
    } else {
      if (setWholeObject) {
        match = selected?.id === original.id;
      } else {
        match = selected === original.id;
      }
    }

    return match;
  };

  const onSelected = (row) => {
    const { original } = row;

    if (isMany) {
      let selectedClone = cloneDeep(selected);

      if (!selectedClone) {
        selectedClone = [original];
      } else if (selectedClone.find((s) => s.id === original.id)) {
        selectedClone = selectedClone.filter((s) => s.id !== original.id);
      } else {
        selectedClone.push(original);
      }

      handleChange(selectedClone);
    } else {
      handleChange(row.original);
    }
    if (overrideCloseFunction) {
      setModalOpen(false);
    }
  };

  const onRemoveClicked = (item) => {
    if (isMany) {
      let selectedClone = cloneDeep(selected);
      selectedClone = selectedClone.filter((s) => {
        if (item._index) {
          return s._index !== item._index;
        } else {
          return s.id !== item.id;
        }
      });
      handleChange(selectedClone);
    } else {
      handleChange(null);
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();

    handleChange(null);
  };

  const getTitle = () => {
    if (selected && !isMany) return constructStrRep(selected);
    if (!selected?.length || isMany) return title;

    return selected[0].str_representation;
  };

  return (
    <>
      <InputSpacing {...{ noMargin }} style={{ ...extraStyles }}>
        {!noTitle && (
          <InputFieldTitle
            style={{
              marginBottom: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {fieldTitle}
            {required ? "*" : ""}
            {description && <DescriptionToolTip description={description} />}
          </InputFieldTitle>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "8px",
          }}
        >
          <SelectButton
            disabled={disabled}
            onClick={disabled ? null : () => setModalOpen(true)}
          >
            {getTitle()}

            {!isMany && !!selected && !required && (
              <ClearSelectButton onClick={(e) => handleClear(e)} />
            )}
          </SelectButton>
        </div>

        {isMany && selected?.length > 0 && (
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {selected.map((item, index) => {
              return (
                <M2mLabel key={index}>
                  {item?.str_representation || constructStrRep(item)}

                  <M2mLabelRemoveButton onClick={() => onRemoveClicked(item)} />
                </M2mLabel>
              );
            })}
          </div>
        )}
      </InputSpacing>

      <StandardModal
        isOpen={overrideOpen || modalOpen}
        closeFunction={() => {
          setModalOpen(false);
          closeCallback && closeCallback();
        }}
        title={title || "Lägg till"}
        actionBarCancelTitle="Klar"
        withActionBar
        modalInModal={modalInModal}
      >
        <Table
          storeName={tableStoreName}
          columns={columns}
          persistantQueryString={persistantQueryString}
          persistantFilterMethod={persistantFilterMethod}
          paginationMethod={paginationMethod}
          filterInstructions={filterInstructions}
          fetchAllTreshold={fetchAllTreshold}
          filterAction={performFilter}
          forceFrontendColumns={forceFrontendColumns}
          {...{
            hideExport: true,
            hideColumns: true,
            onRowClicked: onSelected,
            checkRowHighlighted,
          }}
        />
      </StandardModal>
    </>
  );
};
