import * as React from "react";
import { useDispatch } from "react-redux";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { toExternalFontSize, toInternalFontSize } from "../../utils";
import NonConnectedSelect from "../../../Forms/Base/Old/NonConnected/NonConnectedSelect";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    validation,
    instructionsKey,
    extraStyles,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const onChange = (data) => {
      let formattedData = parseFloat(data);
      const isInvalid =
        isNaN(data) ||
        data < 0 ||
        data > 20 ||
        (validation && !validation(formattedData));
      if (isInvalid) {
        formattedData = instructions._allowNull
          ? null
          : instructions._required
          ? 0
          : "";
      } else {
        formattedData = toExternalFontSize({ f: formattedData }).f;
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <NonConnectedSelect
        {...{ extraStyles }}
        noMargin
        label={"Fontstorlek"}
        value={
          value && !isNaN(parseFloat(value))
            ? toInternalFontSize({ f: value }).f
            : 18
        }
        choices={[
          { value: 16, label: "Liten" },
          { value: 18, label: "Normal" },
          { value: 20, label: "Stor" },
        ]}
        getOptionValue={(o) => o.value}
        getOptionLabel={(o) => o.label}
        menuPlacement={"top"}
        onUpdate={onChange}
        error={error}
      />
    );
  }
);
