import * as React from "react";
import { TextButton } from "../../../Forms/Base/Buttons";
import * as SC from "./styles";

/**
 * Expects mapped info in the form of
 * {
 *    "Header": [{title:"Title", value:"Value", hidden:false}]
 * }
 */

function DetailInfo({
  infoObj,
  paginateAmount = null,
  includeCounter = false,
  extraChapterStyles = {},
  darkThemed = false,
}) {
  const [pages, setPages] = React.useState(null);

  React.useState(() => {
    if (!paginateAmount) {
      return;
    }

    const def = Object.keys(infoObj).reduce(
      (acc, curr) => ({ ...acc, [curr]: 0 }),
      {}
    );
    setPages(def);
  }, [
    paginateAmount,
    JSON.stringify(Object.values(infoObj).map((v) => v?.length)),
  ]);

  const hasNext = (key) => {
    if (!paginateAmount || !pages) {
      return false;
    }

    const length = infoObj[key]?.length;
    const currentPage = pages[key];
    // page 0 -> 0 to paginateAmount = (0 + 1)*paginateAmount in count
    // page 1 -> 0 to paginateAmount*2 = (1 + 1)*paginateAmount in count
    return length > (currentPage + 1) * paginateAmount;
  };

  const hasPrevious = (key) => {
    if (!paginateAmount || !pages) {
      return false;
    }

    return pages[key] > 0;
  };

  const skipPages = (key, amount) => {
    const newPages = { ...pages, [key]: pages[key] + amount };
    setPages(newPages);
  };

  const nextPage = (key) => skipPages(key, 1);
  const previousPage = (key) => skipPages(key, -1);

  const paginatedItems = (key) => {
    const normal = infoObj[key];
    if (!paginateAmount || !pages) {
      return normal;
    }

    const page = pages[key];
    const start = page * paginateAmount;
    return [...(normal || [])]?.slice(start, start + paginateAmount);
  };

  return (
    <SC.Wrapper>
      {Object.keys(infoObj).map((key, idx) => {
        const items = paginatedItems(key);
        const next = hasNext(key);
        const prev = hasPrevious(key);

        const filtered = items?.filter((i) => !i.hidden);

        return (
          <SC.Chapter
            style={{ marginRight: 36, marginBottom: 36, ...extraChapterStyles }}
            key={key}
            {...{ darkThemed }}
          >
            <SC.ChapterTitle>
              {key}
              {!!includeCounter ? ` ${infoObj[key]?.length}` : null}
            </SC.ChapterTitle>
            {filtered?.map((i) => (
              <SC.Item>
                <SC.ItemTitle>{i.title}</SC.ItemTitle>
                <SC.ItemValue>{i.value}</SC.ItemValue>
              </SC.Item>
            ))}

            {(prev || next) && (
              <div style={{ marginTop: "8px" }}>
                {prev && (
                  <TextButton
                    title={"Föregående"}
                    icon={"arrow-back"}
                    iconPlacement={"left"}
                    clicked={() => previousPage(key)}
                  />
                )}
                {next && (
                  <TextButton
                    title={"Nästa"}
                    icon={"arrow"}
                    iconPlacement={"right"}
                    clicked={() => nextPage(key)}
                    extraStyle={prev ? { marginLeft: "8px" } : undefined}
                  />
                )}
              </div>
            )}
          </SC.Chapter>
        );
      })}
    </SC.Wrapper>
  );
}

export default DetailInfo;
