import { axiosInstance } from "../../base/store/axios";

async function searchInvoices({ fromDate, toDate, creditorId }) {
  const searchParams = {
    CreditorPublicId: creditorId,
    Status: ["Open", "WithInvoiceReminder", "WithoutInvoiceReminder"],
    InvoiceDateFrom: fromDate,
    InvoiceDateTo: toDate,
  };

  const { data: response } = await axiosInstance.post(
    "accounting/gateways/search/",
    searchParams
  );

  return response;
}

async function getAccountsReceivableForDate({
  date,
  creditorId,
  excludeInvoicesWithPeriodOutsideDate,
}) {
  const data = await axiosInstance.get(
    `/accounting/gateways/account_receivables/${creditorId}/?date=${date}&showOnlyFinancedInvoices=false&excludeInvoicesWithPeriodOutsideDate=${
      excludeInvoicesWithPeriodOutsideDate || false
    }`
  );

  return data;
}

export { searchInvoices, getAccountsReceivableForDate };
