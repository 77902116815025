//#permisisonchoices

export const COMPANY_PERMS = [
  "view_can_company",
  "add_can_company",
  "change_can_company",
  "delete_can_company",
];

export const CUSTOMER_PERMS = [
  "view_can_management",
  "add_can_management",
  "change_can_management",
  "delete_can_management",
];

export const BASEOBJECT_PERMS = [
  "view_can_baseobject",
  "add_can_baseobject",
  "change_can_baseobject",
  "delete_can_baseobject",
];

export const CONTRACTS_PERMS = [
  "view_can_contract",
  "add_can_contract",
  "change_can_contract",
  "delete_can_contract",
];

export const USER_PERMS = [
  "view_can_user",
  "add_can_user",
  "change_can_user",
  "delete_can_user",
];

export const PERMISSION_PERMS = [
  "view_can_permissions",
  "add_can_permissions",
  "change_can_permissions",
  "delete_can_permissions",
];

export const BILLING_PERMS = [
  "view_can_billing",
  "add_can_billing",
  "change_can_billing",
  "delete_can_billing",
];

export const E_SIGNING_PERMS = [
  "view_can_e_signing",
  "add_can_e_signing",
  "change_can_e_signing",
  "delete_can_e_signing",
];

export const FASTDOC_PERMS = [
  "view_can_fastdoc",
  "add_can_fastdoc",
  "change_can_fastdoc",
  "delete_can_fastdoc",
];
