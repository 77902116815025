import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  handleContractCancelledDocUrl,
} from "../../../../store/editabledocs";
import { useCanUseScrive } from "../../../../store/editabledocs/hooks/retrieve";
import {
  SCRIVE_DESCRIPTION_MAP,
  SCRIVE_STATE_MAP,
} from "../../../../store/editabledocs/utils";
import {
  isCancelSigning,
  constants,
  update,
} from "../../../../store/managementContracts";
import { InfoBox } from "../../../Displays";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "../../../Forms/Base/Buttons";
import DescriptionToolTip from "../../../Forms/Base/Layout/DescriptionToolTip";
import { StatusLabel } from "../../../Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import { DetailPageBox, InnerBox } from "../../../sharedStyles";
import CancelSignActions from "../../OverviewInfo/Signing/CancelSignActions";
import Parties from "../../OverviewInfo/Signing/Parties";
import ScriveEvents from "../../OverviewInfo/Signing/ScriveEvents";
import StartSigning from "../../OverviewInfo/Signing/StartSigning";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../OverviewInfo/styles";

export default function ManagementContractDigitalCancelSign({
  contract,
  cancelledDoc,
}) {
  const dispatch = useDispatch();
  const [canUseScrive] = useCanUseScrive();
  const [open, setOpen] = React.useState(false);
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const hasScrive = cancelledDoc?.has_scrive;
  const isDigitalSign = contract.uses_cancellation_e_signing;
  const isPreparation = cancelledDoc?.status === "preparation";
  const hasParties = cancelledDoc?.parties?.length > 0;
  const canPerformCancelledDocActions = isCancelSigning(contract);

  const [confirmCancelCancellationOpen, setConfirmCancelCancellationOpen] =
    React.useState(false);

  const canStartScriveSign =
    canUseScrive &&
    isDigitalSign &&
    canPerformCancelledDocActions &&
    hasParties &&
    isPreparation;

  const showScriveActions =
    isDigitalSign &&
    canUseScrive &&
    hasParties &&
    !canStartScriveSign &&
    canPerformCancelledDocActions;

  const cancelCancellation = () => {
    setLoading(true);
    const data = {
      closed_date: null,
      closed_renew_date: null,
      closed_on: null,
      status: 0,
      closed_cause: null,
      closed_signed: false,
      closed_confirmed: false,
      landlord_cancelled: null,
      cancelled_doc: null,
    };

    dispatch(
      update({
        id: contract?.id,
        forceData: data,
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  const handleCreateCancelledDoc = () => {
    setLoading(true);
    dispatch(
      update({
        id: contract.id,
        forceData: {
          cancelled_doc: {
            title: "canceldoc",
          },
        },
        successCallback: () => {
          setLoading(false);
          push(
            handleContractCancelledDocUrl({
              contractType: EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT,
              id: contract?.id,
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      <ConfirmationModal
        isOpen={confirmCancelCancellationOpen}
        title={"Är du säker på att du vill ångra uppsägningen?"}
        renderContent={() => (
          <div>
            Det digitala dokumentet kommer att tas bort. Detta kan inte ångras.
          </div>
        )}
        closeFunction={() => setConfirmCancelCancellationOpen(false)}
        acceptCallback={cancelCancellation}
        acceptTitle="Ångra uppsägning"
      />

      <DetailPageBox
        style={{
          border: "2px solid #e4f1ff",
          boxShadow: "0px 2px 11px 5px rgba(73, 157, 255, 0.2)",
        }}
      >
        {loading && <OverlaySpinner />}

        {cancelledDoc?.status === "canceled" && (
          <InfoBox
            boxTheme="yellow"
            title="Signering avbruten"
            text={`Signeringsprocessen är avbruten. För att kunna göra ändrignar i dokument och starta om signeringsprocessen, tryck på "Hantera -> Starta om process". Detta kommer att sätta statusen på signeringen till "Förberedelse" igen. Dokumentet kommer EJ skickas ut till parterna på dokumentet innan signeringen startas igen.`}
          />
        )}

        <OverviewTitleWrapper style={open ? {} : { marginBottom: 0 }}>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              Digital signering av uppsägning{" "}
              {SCRIVE_DESCRIPTION_MAP[cancelledDoc?.status] && (
                <DescriptionToolTip
                  description={SCRIVE_DESCRIPTION_MAP[cancelledDoc?.status]}
                />
              )}
            </OverviewTitle>

            <OverviewSubtitle style={{ display: "flex", alignItems: "center" }}>
              Status:{" "}
              <StatusLabel state={SCRIVE_STATE_MAP[cancelledDoc?.status] || 9}>
                {" "}
                {isDigitalSign
                  ? contract?.cancelled_doc
                    ? cancelledDoc?.status_display
                    : "Signeringsdokument saknas"
                  : contract.closed_signed
                  ? "Signerat"
                  : "Ej signerat"}
              </StatusLabel>
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <div style={{ display: "flex" }}>
            {!contract?.cancelled_doc && (
              <PrimaryButton
                title="Skapa dokument för signering"
                clicked={handleCreateCancelledDoc}
              />
            )}

            {cancelledDoc && (
              <SecondaryButton
                extraStyle={
                  showScriveActions || canStartScriveSign || !hasParties
                    ? { marginRight: 8 }
                    : {}
                }
                title={open ? "Dölj detaljer" : "Visa detaljer"}
                clicked={() => setOpen(!open)}
              />
            )}
            {!hasParties && cancelledDoc && (
              <PrimaryButton
                extraStyle={
                  showScriveActions || canStartScriveSign
                    ? { marginRight: 8 }
                    : {}
                }
                title="Hantera dokument"
                clicked={() =>
                  push(
                    handleContractCancelledDocUrl({
                      contractType: EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT,
                      id: contract?.id,
                    })
                  )
                }
              />
            )}

            {canStartScriveSign && (
              <StartSigning
                contract={contract}
                editableDoc={cancelledDoc}
                constants={constants}
              />
            )}
            {showScriveActions && (
              <CancelSignActions
                contract={contract}
                cancelledDoc={cancelledDoc}
                constants={constants}
              />
            )}
          </div>
        </OverviewTitleWrapper>

        {open && (
          <>
            <div style={{ display: "flex" }}>
              {hasScrive && isDigitalSign && (
                <InnerBox>
                  <ScriveEvents editableDoc={cancelledDoc} />
                </InnerBox>
              )}

              <InnerBox style={{ marginLeft: 12 }}>
                <OverviewTitleWrapper>
                  <OverviewTitle small>Parter</OverviewTitle>
                </OverviewTitleWrapper>
                <Parties editabledoc={cancelledDoc} />
              </InnerBox>
            </div>

            {canStartScriveSign && (
              <TextButton
                extraStyle={{ marginTop: 24 }}
                title="Avbryt uppsägning"
                iconType="close"
                iconPlacement="right"
                red
                clicked={() => setConfirmCancelCancellationOpen(true)}
              />
            )}
          </>
        )}
      </DetailPageBox>
    </>
  );
}
