import * as React from "react";

import {
  TimePicker,
  TextField,
  TextInput,
  TableSelect,
} from "../../../Base/Fields";

import { constants } from "../../../../../store/calendarEvents";

import useActiveFilters from "../../../../../hooks/useActiveFilters";
import GenericField from "../../../Base/FieldComponents/GenericField";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { buildQueryString } from "../../../../../store/base";

import {
  constants as userConstants,
  useUserPagination,
  performFilter as userFilter,
  constructStrRep as userConstructStrRep,
} from "../../../../../store/users";
import userListDefs from "../../../../Lists/Users/listDefs";
import { useSelector } from "react-redux";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const userId = useSelector((state) => state.app.user?.id);

  const userPersistantQuery = buildQueryString({
    user_type__in: [0, 1, 2],
    "user_id!": userId,
  });
  const userPersistantFilter = (obj) =>
    [0, 1, 2].includes(obj.user_type) && obj?.id != userId;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Att göra</OverviewTitle>
          <OverviewSubtitle>Ange information om uppgiften</OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <TextInput
        storeName={storeName}
        title={"Titel"}
        method={method}
        fieldKey={"title"}
      />

      <TextField
        storeName={storeName}
        title={"Uppgift"}
        method={method}
        fieldKey={"description"}
      />

      <TimePicker
        storeName={storeName}
        title={"Ska vara gjord senast"}
        method={method}
        fieldKey={"end"}
      />

      <TableSelect
        storeName={storeName}
        tableStoreName={userConstants.STORE_NAME}
        method={method}
        fieldKey="participants"
        listDefs={userListDefs}
        paginationMethod={useUserPagination}
        persistantFilterMethod={userPersistantFilter}
        persistantQueryString={userPersistantQuery}
        performFilter={userFilter}
        fieldTitle="Ansvariga för uppgift"
        title="Välj ansvariga..."
        description="Inbjudna deltagare kommer att se denna händelse i sina kalendrar och få notiser om händelsen."
        constructStrRep={userConstructStrRep}
        // m2m field
        isMany
        modalInModal
      />
    </>
  );
};
