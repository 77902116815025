import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { fastDocTemplateName } from "../../components/EditableDocBase/DocumentSelector/Templates/Container";
import EditableDocCreateContainer from "../../components/Forms/EditableDoc/TemplateForm/Container";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import { updateActiveFormInstance } from "../../store/base";
import {
  update,
  useEditabledoc,
  constants,
  detailUrl,
  destroyPatchForm,
} from "../../store/editabledocs";

export default function EditTemplate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { editableDocId } = useParams();

  const [editableDoc] = useEditabledoc(editableDocId);
  const { push } = useHistory();

  React.useEffect(() => {
    if (editableDoc) {
      // to default and show which template that has been chosen
      if (editableDoc.fastdoc_id) {
        editableDoc._chosenTemplate = {
          id: editableDoc.fastdoc_id,
          pay: false,
          name: fastDocTemplateName,
        };
      }

      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: editableDoc,
        })
      );
    }
  }, [editableDoc]);

  const successCallback = (_, returned) => {
    setLoading(false);

    dispatch(destroyPatchForm(true));
    push(detailUrl({ id: returned.id }));
  };

  const errorCallback = () => {
    setLoading(false);
  };

  const preProcess = (data) => {
    const clone = cloneDeep(data);

    return clone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id: editableDocId,
        preProcess,
        successCallback,
        errorCallback,
      })
    );
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      <EditableDocCreateContainer
        {...{
          method: "PATCH",
          onSubmit,
          editableDocId,
        }}
      />
    </>
  );
}
