import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TableSelect } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingSettings";

import {
  constants as projectConstants,
  performFilter as projectPerformFilter,
  constructStrRep as constructStrRepProject,
  useInvoicingProjectPagination,
} from "../../../../../store/invoicingProjects";
import {
  constants as costCenterConstants,
  performFilter as costCenterPerformFilter,
  constructStrRep as constructStrRepCostCenter,
  useInvoicingCostCenterPagination,
} from "../../../../../store/invoicingCostCenters";
import projectListDefs from "../../../../Lists/InvoicingProjects/listDefs";
import costCenterListDefs from "../../../../Lists/InvoicingCostCenters/listDefs";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Bokföringsinställningar</FormAreaTitle>

      <TableSelect
        storeName={storeName}
        tableStoreName={projectConstants.STORE_NAME}
        performFilter={projectPerformFilter}
        listDefs={projectListDefs}
        fieldKey="project"
        method={method}
        paginationMethod={useInvoicingProjectPagination}
        fieldTitle="Standardprojekt för kostnader på avier under bolaget"
        title="Välj standardprojekt"
        description="Om inget annat projekt specifikt väljs på en kostnad så kommer kostnaden automatiskt bokföras under detta projekt"
        modalInModal
        setWholeObject
        constructStrRep={constructStrRepProject}
      />

      <TableSelect
        storeName={storeName}
        tableStoreName={costCenterConstants.STORE_NAME}
        performFilter={costCenterPerformFilter}
        listDefs={costCenterListDefs}
        fieldKey="cost_center"
        method={method}
        paginationMethod={useInvoicingCostCenterPagination}
        fieldTitle="Standard kostnadsställe för kostnader på avier under bolaget"
        title="Välj standard kostnadsställe"
        description="Om inget annat kostnadsställe specifikt väljs på en kostnad så kommer kostnaden automatiskt bokföras under detta kostnadsställe"
        modalInModal
        setWholeObject
        constructStrRep={constructStrRepCostCenter}
      />
    </>
  );
};
