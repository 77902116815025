import * as React from "react";
import { constants } from "../../../store/editabledocs";
import TableFileM2m from "../../Forms/Base/Fields/TableFileM2m";

function HandleAttachments({ method }) {
  const storeName = constants.STORE_NAME;

  return (
    <TableFileM2m
      extraStyles={{ maxWidth: "100%" }}
      storeName={storeName}
      method={method}
      fieldKey={"attachments"}
      title="Bilagor"
    />
  );
}

export default HandleAttachments;
