export default () => [
  {
    title: "Förvaltningsuppdrag",
    key: "MANAGEMENT_CUSTOMER",
    visited: true,
    hasError: false,
    fieldKeys: [
      "corporate_name",
      "first_name",
      "last_name",
      "email",
      "legal_id",
      "address",
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
