import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "../../../Forms/Base/Fields/styles";

// store, design
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    extraStyles,
    type,
    instructionsKey,
    noTitle,
    noMargin,
    rowSize,
    parentValueKey,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const parentValue = useFormInstanceField({
      storeName,
      fieldKey: parentValueKey,
    });
    const error = useFormError({ storeName, fieldKey });

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );

      if (parentValue === value) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { [parentValueKey]: data },
          })
        );
      }
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing style={{ ...extraStyles }} noMargin>
        {!noTitle && (
          <SC.InputFieldTitle>
            {instructions._label}
            {instructions._required ? "*" : ""}
          </SC.InputFieldTitle>
        )}
        {instructions._helpText && (
          <SC.InputFieldDescription>
            {instructions._helpText}
          </SC.InputFieldDescription>
        )}
        <SC.InputFieldWrapper error={!!error}>
          <SC.InputField
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            maxLength={instructions.max_length}
            id={instructions._internalId}
            required={instructions._required}
            type={type || "text"}
            {...{ rowSize }}
          />
        </SC.InputFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
