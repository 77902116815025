import * as React from "react";
import { NotificationDot } from "../../Dashboard/styles";

// style, design
import { ToolTipCell } from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "../Base/CompleteList/styles";
export default (billectaEvents = []) => [
  {
    Header: "",
    id: "events",
    Cell: ({ row }) => {
      const matches = billectaEvents.filter(
        (be) => be.related_creditor_id === row.original.billecta_id
      );

      const count = matches?.length;

      return count > 0 ? (
        <NotificationDot>{count}</NotificationDot>
      ) : (
        <div></div>
      );
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Bolag",
    accessor: "name",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Orgnr",
    accessor: "orgnr",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "KYC inlämnad",
    accessor: "kyc_report",
    Cell: ({ value }) => (
      <StatusLabel state={value ? 1 : 3}>{value ? "Ja" : "Nej"}</StatusLabel>
    ),
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
];
