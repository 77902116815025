import * as React from "react";

import { Confirm, Customer } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "CUSTOMER":
      return <Customer method={method} />;
    default:
      return <Confirm />;
  }
};
