import * as React from "react";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import { InfoBox } from "../../Displays";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedTextInput from "../../Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { FieldBox } from "../Search/styles";

export default ({
  emails,
  fromDate,
  toDate,
  setEmails,
  setFromDate,
  setToDate,
  isJournal,
}) => {
  return (
    <>
      {isJournal ? (
        <InfoBox
          boxTheme="info"
          title="Aviseringsjournal"
          text="Denna journal visar underlag för den avisering/fakturering som har skett utifrån valt tidsintervall. Journalen utgår ifrån den faktiska bokföringen som har genererats."
        />
      ) : (
        <InfoBox
          boxTheme="info"
          title="Aviseringsrapport"
          text="Denna rapport visar preliminär debiteringar utifrån valt tidsintervall. Rapporten utgår ifrån de avtal som är konfigurerade för avisering"
        />
      )}
      <FieldBox>
        <OverviewTitle small style={{ marginBottom: "8px" }}>
          Datumintervall för rapport
        </OverviewTitle>
        <NonConnectedDatePicker
          value={fromDate}
          clearable={false}
          id="grouped-product-sales-report-from-date"
          label="Från"
          onChange={(val) => setFromDate(val)}
          noMargin
        />
        <NonConnectedDatePicker
          value={toDate}
          clearable={false}
          id="grouped-product-sales-report-to-date"
          label="Till"
          onChange={(val) => setToDate(val)}
          noMargin
        />
        <NonConnectedTextInput
          extraStyles={{ marginTop: "8px" }}
          value={emails}
          onChange={(val) => setEmails(val)}
          label={"Skicka till email adresser"}
          helpText={
            "Fyll i flera adresser genom att separera dem med kommatecken"
          }
        />
      </FieldBox>
    </>
  );
};
