import * as React from "react";
import { useDispatch } from "react-redux";
import { PrimaryButton, TextButton } from "../../Forms/Base/Buttons";
import PremisesTable from "../../PremisesTable/PremisesTable";
import * as SC from "./styles";
import { constants } from "../../../store/editabledocs";
import { cloneDeep } from "lodash";
import { updateActiveFormInstance } from "../../../store/base";

import { CATEGORY_MAP, SIGN_ROLE_MAP } from "../utils";
import { InfoBox } from "../../Displays";

import EditParty from "./EditParty";

function HandleParties({ parties, method, isTemplate }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [editIndex, setEditIndex] = React.useState(null);

  const removeParty = (idx) => {
    const partiesClone = cloneDeep(parties);

    partiesClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          parties: partiesClone,
        },
      })
    );
  };

  const handleAddParty = () => {
    const hasTenant = !!parties?.find((p) => p.category === "tenant");
    const hasLandlord = !!parties?.find((p) => p.category === "landlord");
    const initialData = {
      category:
        hasTenant && hasLandlord
          ? "bi_tenant"
          : hasTenant
          ? "landlord"
          : "tenant",
      fields: [
        {
          kind: "first_name",
          name: "Förnamn",
          value: isTemplate
            ? `Förnamn part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "last_name",
          name: "Efternamn",
          value: isTemplate
            ? `Efternamn part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "personal_number",
          name: "Personnummer",
          value: isTemplate
            ? `Personnummer part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "corporate_name",
          name: "Företagsnamn",
          value: isTemplate
            ? `Företagsnamn part ${(parties || [])?.length + 1}`
            : "",
        },
        {
          kind: "company_number",
          name: "Organisationnummer",
          value: isTemplate ? `Orgnr part ${(parties || [])?.length + 1}` : "",
        },
        {
          kind: "email",
          name: "Email",
          is_obligatory: true,
          value: isTemplate ? `E-post part ${(parties || [])?.length + 1}` : "",
        },
        {
          kind: "phone",
          name: "Telefonnummer",
          value: isTemplate
            ? `Telefonnummer part ${(parties || [])?.length + 1}`
            : "",
        },
      ],
      // settings defaults
      signatory_role: "signing_party",
      delivery_method: "email",
      confirmation_delivery_method: "email",
      authentication_method_to_sign: "standard",
      authentication_method_to_view: "standard",
    };

    const partiesCopy = cloneDeep(parties || []);
    partiesCopy.push(initialData);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          parties: partiesCopy,
        },
      })
    );

    setEditIndex(partiesCopy.length - 1);
  };

  const handleEditParty = (_, idx) => {
    setEditIndex(idx);
  };

  if (editIndex != null) {
    return (
      <EditParty
        {...{
          parties,
          editParty: parties?.[editIndex],
          editIndex,
          setEditIndex,
          isTemplate,
          method,
        }}
      />
    );
  }

  const hasMultipleTenants =
    parties?.filter((p) => p.category === "tenant")?.length > 1;
  const hasMultipleLandlords =
    parties?.filter((p) => p.category === "landlord")?.length > 1;

  return (
    <>
      {hasMultipleTenants && (
        <InfoBox
          boxTheme="warning"
          title="Endast en huvudsaklig kund tillåten"
          text={`Endast en part får ha kategorin "Kund". De övriga kunderna ska ha kategorin "Övrig kund".`}
        />
      )}
      {hasMultipleLandlords && (
        <InfoBox
          boxTheme="warning"
          title="Endast en hyresvärd tillåten"
          text={`Endast en part får ha kategorin "Hyresvärd". De övriga parterna som ej är hyresgäster ska ha kategorin "Annan".`}
        />
      )}

      <PremisesTable
        onRowClicked={handleEditParty}
        headers={["Färg", "Namn", "E-post", "Roll", "Ta bort"]}
        rows={parties?.map((p, idx) => {
          const partyFields = p.fields;
          const firstNameIndex = partyFields.findIndex(
            (f) => f.kind === "first_name"
          );
          const lastNameIndex = partyFields.findIndex(
            (f) => f.kind === "last_name"
          );

          const emailIndex = partyFields.findIndex((f) => f.kind === "email");

          return [
            <SC.ColorIndicator color={p._color} />,
            `${
              isTemplate
                ? `Part ${idx + 1} (${CATEGORY_MAP[p.category] || "-"})`
                : `${p.fields[firstNameIndex]?.value} ${p.fields[lastNameIndex]?.value}`
            }`,
            p.fields[emailIndex].value,
            SIGN_ROLE_MAP[p.signatory_role],
            <TextButton title="Ta bort" clicked={() => removeParty(idx)} />,
          ];
        })}
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PrimaryButton title="Lägg till part" clicked={handleAddParty} />
      </div>
    </>
  );
}

export default HandleParties;
