import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useAllPermissionCheck } from "../store/base";

export default function usePermissionRedirect(permissions = []) {
  const user = useSelector((state) => state.app.user);
  const { replace } = useHistory();

  const hasPermissions = useAllPermissionCheck(permissions);

  if (!user?.permissions || permissions?.length === 0) return;

  if (!hasPermissions) {
    replace(`/permission-denied?perms=${permissions.join(",")}`);
  }

  return true;
}
