import * as React from "react";

// style, design
import { ToolTipCell } from "../../Displays";
import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => {
  const defs = [
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Beskrivning",
      accessor: "description",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return defs;
};
