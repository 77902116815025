import * as React from "react";

import { ToolTipCell, EmailTag, PhoneTag } from "../../Displays";
import AnonymousCell from "../../Displays/AnonymousCell";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => {
  const cols = [
    {
      Header: "Kund",
      accessor: "str_representation",
      Cell: ({ value, row }) =>
        !!row?.original?.user?.anonymization ? (
          <AnonymousCell />
        ) : (
          <ToolTipCell text={value} />
        ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Org-nr",
      accessor: "orgnr",
      Cell: ({ value, row }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kontaktperson",
      accessor: "contact_name",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Email",
      accessor: "contact_email",
      Cell: ({ value }) => <EmailTag mail={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Telefon",
      accessor: "contact_phone",
      Cell: ({ value }) => <PhoneTag phone={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Faktureringsadress",
      accessor: "invoice_address",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
