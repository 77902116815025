import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { NumberInput, Select } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingSettings";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useDispatch } from "react-redux";
import { InfoBox } from "../../../../Displays";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [invoiceMonthDisabled, setInvoiceMonthDisabled] = React.useState(false);

  const intervalMode = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.mode",
  });

  const invoicePeriod = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.invoice_period",
  });

  const intervalMonth = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.on_month",
  });

  React.useEffect(() => {
    // cant be previous on quarterly and yearly mode
    if ((intervalMode === 1 || intervalMode === 2) && invoicePeriod === -1) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ["interval_setting.invoice_period"]: 0,
          },
        })
      );

      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Faktureringsperioden sattes till innevarande",
          description:
            "Faktureringsperioden kan ej vara föregående vid årsvis eller kvartalsvis avisering",
        })
      );
    }

    // if invoice period is next, intervalmode is quarter, intervalmonth must be 3
    if (invoicePeriod === 1 && intervalMode === 1) {
      setInvoiceMonthDisabled(true);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ["interval_setting.on_month"]: 3,
          },
        })
      );

      if (intervalMonth !== 3) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Månad i kvartal för aviseringsintervallet sattes till 3",
            description:
              "Vid nästkommande faktureringsperiod och kvartalsvis avisering måste månaden var den sista i kvartalet",
          })
        );
      }

      // if invoice period is next, intervalmode is year, intervalmonth must be 12
    } else if (invoicePeriod === 1 && intervalMode === 2) {
      setInvoiceMonthDisabled(true);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ["interval_setting.on_month"]: 12,
          },
        })
      );

      if (intervalMonth !== 12) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Månad i år för aviseringsintervallet sattes till 12",
            description:
              "Vid nästkommande faktureringsperiod och årsvis avisering måste månaden var den sista i året",
          })
        );
      }
    } else {
      setInvoiceMonthDisabled(false);
    }
  }, [invoicePeriod, intervalMode, intervalMonth]);

  return (
    <>
      <FormAreaTitle>Faktureringsintervall </FormAreaTitle>
      <Select
        title="Typ av faktureringsintervall"
        description={`Typ av intervall för när fakturor ska genereras och skickas. Vid månadsvis fakturering väljs t.ex. "Per månad".`}
        {...{ storeName, method, fieldKey: "interval_setting.mode" }}
      />

      {/* Per month */}
      {intervalMode === 0 && (
        <>
          <NumberInput
            title="Månadsintervall"
            description={`Ange i vilket månadsintervall fakturor ska genereras. Ska en faktura genereras varje månad anges 1, varannan månad 2 osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i varje månad som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      {/* Quarterly */}
      {intervalMode === 1 && (
        <>
          <NumberInput
            title="Kvartalsintervall"
            description={`Ange i vilket kvartalsintervall fakturor ska genereras. Ska en faktura genereras varje kvartal anges "1", varannat kvartal "2" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          {invoiceMonthDisabled && (
            <div style={{ maxWidth: 600 }}>
              <InfoBox
                boxTheme="info"
                text="Vid nästkommande period och kvartalsintervall måste månaden vara 3"
              />
            </div>
          )}
          <NumberInput
            disabled={invoiceMonthDisabled}
            title="Månad i kvartal som faktura ska genereras"
            description={`Ange vilken månaden i kvartalet som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas 1:a månaden kvartalet så anges "1".`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.on_month",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      {/* yearly */}
      {intervalMode === 2 && (
        <>
          <NumberInput
            title="Årsintervall"
            description={`Ange i vilket årsintervall fakturor ska genereras. Ska en faktura genereras varje år anges "1", varannat år "2" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.interval",
            }}
          />

          {invoiceMonthDisabled && (
            <div style={{ maxWidth: 600 }}>
              <InfoBox
                boxTheme="info"
                text="Vid nästkommande period och årsintervall måste månaden vara 12"
              />
            </div>
          )}
          <NumberInput
            disabled={invoiceMonthDisabled}
            title="Månad som faktura ska genereras"
            description={`Ange vilken månaden på året som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas i Januari så anges "1", Juli så anges "7" osv.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.on_month",
            }}
          />

          <NumberInput
            title="Dag i månad som faktura ska genereras"
            description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
            {...{
              storeName,
              method,
              fieldKey: "interval_setting.day_in_month",
            }}
          />
        </>
      )}

      {(intervalMode === 1 || intervalMode === 2) && (
        <div style={{ maxWidth: 600 }}>
          <InfoBox
            boxTheme="info"
            text="Faktureringsperioden kan ej vara föregående vid kvartalsvis eller årsvis avisering"
          />
        </div>
      )}
      <Select
        title="Faktureringsperiod"
        filterChoices={(c) => {
          if ([1, 2].includes(intervalMode)) {
            return c.v === -1 ? false : true;
          }
          return true;
        }}
        {...{ storeName, method, fieldKey: "interval_setting.invoice_period" }}
      />
    </>
  );
};
