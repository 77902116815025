import * as React from "react";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import {
  useCustomerPagination,
  performFilter,
  constants,
  detailUrl,
  authenticateAsCustomer,
} from "../../../store/customers";
import { useFrequentPermissions } from "../../../store/base";
import { useDispatch } from "react-redux";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  hideSearch,
  hideFilters,
  hideExport,
  hideColumns,
  exportSheetController,
  isMembers,
  withPersistantSortBy,
  withPersistantGlobalFilter,
}) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const handleLogin = (customerId) => {
    dispatch(authenticateAsCustomer(customerId));
  };
  const columns = React.useMemo(
    () => columnDefs(hasBillectaViewPermission, handleLogin),
    [persistantFilterMethod, persistantQueryString, hasBillectaViewPermission]
  );
  const fetchAllTreshold = 25;

  // not filterable on backend
  const forceFrontendColumns = ["state"];

  const localStorageHiddenColumnId = "customer_full_table_hidden";

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantQueryString={persistantQueryString}
      persistantFilterMethod={persistantFilterMethod}
      paginationMethod={useCustomerPagination}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      detailUrlMethod={(obj) =>
        detailUrl({
          id: obj.id,
          isMember: isMembers,
        })
      }
      toggleFormCallback={toggleFormCallback}
      title={hideTitle ? undefined : "Förvaltningsuppdrag"}
      forceFrontendColumns={forceFrontendColumns}
      exportSheetController={exportSheetController}
      {...{
        localStorageHiddenColumnId,
        withPersistantSortBy,
        withPersistantGlobalFilter,
        hideSearch,
        hideFilters,
        hideExport,
        hideColumns,
      }}
    />
  );
};
