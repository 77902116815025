import * as React from "react";

import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import { EmailTag, PhoneTag } from "../../Displays";
import moment from "moment";
import DetailInfo from "../OverviewInfo/DetailInfo/DetailInfo";

export default ({ user, group }) => {
  return (
    <>
      <DetailInfo infoObj={getInfoObj(user, group)} />
    </>
  );
};

function getInfoObj(user, group) {
  const isActive = user?.is_active;
  const invited = user?.invite_pending;
  const infoObj = {
    Information: [
      {
        title: "Användartyp",
        value: user?.user_type_display || "-",
      },
      {
        title: "Namn",
        value:
          user?.str_representation ||
          `${user?.first_name} ${user?.last_name} ${
            user?.corporate_name ? `(${user?.corporate_name})` : ""
          }`,
      },
      {
        title: "Status",
        value: (
          <StatusLabel state={isActive ? 0 : invited ? 4 : 3}>
            {isActive ? "Aktiv" : invited ? "Inbjuden" : "Inaktiv"}
          </StatusLabel>
        ),
      },
      { title: "E-post", value: <EmailTag mail={user?.email} /> },
      { title: "Telefon", value: <PhoneTag phone={user?.phone} /> },
      {
        title: user?.corporate_name ? "Orgnr" : "Personnummer",
        value: user?.legal_id || "-",
      },
      {
        title: "Behörighetsgrupp",
        value: group ? (group?.name ? group?.name : "Hittade inte") : "-",
      },
      {
        title: "Aktiv till och med",
        value: user?.active_to
          ? moment(user?.active_to).format("YYYY-MM-DD HH:mm")
          : "Tillsvidare",
      },
    ],
  };

  return infoObj;
}
