import * as React from "react";
// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import { buildRangeFilter } from "../Base/utils";

import {
  useInvoicingProductPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/invoicingProducts";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  onRowClicked,
  checkRowHighlighted,
  forceInitialPageSize,
  hidePagination,
  externalFilters,
  withPersistantSortBy,
  withPersistantGlobalFilter,
  persistantCategories,
  clearCategories,
}) => {
  const storeName = constants.STORE_NAME;

  const filterInstructions = {};

  const columns = React.useMemo(
    () => columnDefs(),
    [persistantFilterMethod, persistantQueryString]
  );
  const fetchAllTreshold = 25;

  const localStorageHiddenColumnId = "product_invoicing_full_table_hidden";

  // not filterable on backend, but we want to enable filtering on it
  const forceFrontendColumns = [];

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantQueryString={persistantQueryString}
      persistantFilterMethod={persistantFilterMethod}
      paginationMethod={useInvoicingProductPagination}
      filterInstructions={filterInstructions}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      localStorageHiddenColumnId={localStorageHiddenColumnId}
      detailUrlMethod={(obj) => detailUrl({ id: obj.id })}
      toggleFormCallback={toggleFormCallback}
      title={hideTitle ? undefined : "Produkter"}
      forceFrontendColumns={forceFrontendColumns}
      forceInitialPageSize={forceInitialPageSize}
      hidePagination={hidePagination}
      exportSheetController={exportSheetController}
      {...{
        hideSearch,
        hideFilters,
        hideExport,
        hideColumns,
        onRowClicked,
        checkRowHighlighted,
        externalFilters,
        withPersistantSortBy,
        withPersistantGlobalFilter,
        persistantCategories,
        clearCategories,
      }}
    />
  );
};
