import * as React from "react";
import { constants } from "../../../../store/whitelistPerms";
import Table from "../../../Lists/Base/CompleteList/Table";
import StandardModal from "../../../Modals/StandardModal";

import {
  useCompanyPagination,
  constants as companyConstants,
  performFilter as companyPerformFilter,
} from "../../../../store/companies";
import { companyColumnDefs } from "../../../Lists/Companies/listDefs";

import {
  useManagementContractPagination,
  constants as managementcontractConstants,
  performFilter as managementcontractPerformFilter,
} from "../../../../store/managementContracts";
import leasecontractColumnDefs from "../../../Lists/ManagementContracts/listDefs";

import {
  useEditabledocPagination,
  constants as editabledocConstants,
  performFilter as editabledocPerformFilter,
} from "../../../../store/editabledocs";
import { editableDocsColumnDefs } from "../../../Lists/EditableDocs/listDefs";

import {
  useBasicDocPagination,
  constants as basicdocConstants,
  performFilter as basicdocPerformFilter,
} from "../../../../store/basicDocs";
import basicdocColumnDefs from "../../../Lists/BasicDocs/listDefs";

import { useDispatch, useSelector } from "react-redux";
import {
  addWhitelistObject,
  removeWhiteListObject,
} from "../../../../store/whitelistPerms/store/actions";

const CONTENT_TYPE_MAP = {
  company: {
    columnDefs: companyColumnDefs,
    storeName: companyConstants.STORE_NAME,
    performFilter: companyPerformFilter,
    paginationMethod: useCompanyPagination,
  },
  leasecontract: {
    columnDefs: leasecontractColumnDefs,
    storeName: managementcontractConstants.STORE_NAME,
    performFilter: managementcontractPerformFilter,
    paginationMethod: useManagementContractPagination,
  },

  editabledoc: {
    columnDefs: editableDocsColumnDefs,
    storeName: editabledocConstants.STORE_NAME,
    performFilter: editabledocPerformFilter,
    paginationMethod: useEditabledocPagination,
  },
  basicdoc: {
    columnDefs: basicdocColumnDefs,
    storeName: basicdocConstants.STORE_NAME,
    performFilter: basicdocPerformFilter,
    paginationMethod: useBasicDocPagination,
  },
};

export default ({ contentType, isOpen, closeFunction }) => {
  const dispatch = useDispatch();
  const currentSetting = CONTENT_TYPE_MAP[contentType];
  const fetchAllTreshold = 25;
  const filterInstructions = {
    name: { operator: "icontains" },
  };

  const current = useSelector((state) => state[constants.STORE_NAME].current);

  const onSelected = (row) => {
    const { original } = row;
    if (current[contentType]?.find((r) => r.object_id === original.id)) {
      dispatch(removeWhiteListObject({ object: original, contentType }));
    } else {
      dispatch(addWhitelistObject({ object: original, contentType }));
    }
  };

  const checkRowHighlighted = (row) => {
    const { original } = row;

    let match = false;

    match = current[contentType]?.find((s) => s.object_id === original.id);

    return match;
  };

  const columns = React.useMemo(
    () => currentSetting.columnDefs(null, null),
    [contentType]
  );

  return (
    <StandardModal
      isOpen={isOpen}
      closeFunction={() => closeFunction(false)}
      title={getReadablePermissionType(contentType)}
      withActionBar
      actionBarCancelTitle="Klar"
      modalInModal
    >
      <Table
        storeName={currentSetting.storeName}
        columns={columns}
        paginationMethod={currentSetting.paginationMethod}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={currentSetting.performFilter}
        // forceFrontendColumns={forceFrontendColumns}
        {...{
          hideExport: true,
          hideColumns: true,
          onRowClicked: onSelected,
          checkRowHighlighted,
        }}
      />
    </StandardModal>
  );
};

const getReadablePermissionType = (permissionType) => {
  switch (permissionType) {
    case "customer":
      return "Förvaltningsuppdrag";
    case "user":
      return "Användare";
    case "message":
      return "Meddelanden";
    case "notification":
      return "Notiser";
    case "permissiondetail":
      return "Behörigheter - detaljer";
    case "permissionrule":
      return "Behörigheter - regler";
    case "basicdoc":
      return "Dokument";
    case "company":
      return "Företag";
    case "editabledoc":
      return "Mallar & Avtalsdokument";
    case "managementcontract":
      return "Förvaltningsavtal";

    default:
      return permissionType;
  }
};
