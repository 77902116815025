import * as React from "react";

import { buildQueryString } from "../../store/base";
import { useFilteredUserGroups } from "../../store/userGroups";

import FullTable from "../Lists/Users/FullTable";

export default () => {
  const persistantQueryString = buildQueryString({
    "user_type__in!": [3, 5],
  });
  const [groups] = useFilteredUserGroups("");

  const filterMethod = (userObject) => ![3, 5].includes(userObject.user_type);

  return (
    <>
      <FullTable
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={filterMethod}
        hideTitle
        hideFilters
        hideColumns
        hideExport
        groups={groups}
      />
    </>
  );
};
