import * as React from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiEmptyPrompt,
  EuiText,
} from "@elastic/eui";
import { FaFilePdf } from "react-icons/fa";

export default () => {
  return (
    <>
      <EuiPanel paddingSize={"l"} grow={true}>
        <EuiText>
          <h3>Dokument</h3>
        </EuiText>

        <EuiFlexGroup direction={"column"} style={{ height: "100%" }}>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false}>
            <EuiEmptyPrompt
              iconType={FaFilePdf}
              title={<h2>Inget dokument</h2>}
              body={
                <>
                  <p>Det finns inget kopplat dokument</p>
                </>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
        </EuiFlexGroup>
      </EuiPanel>
    </>
  );
};
