import * as React from "react";

export default () => {
  return (
    <div>
      Välj de parter som ska stå med på avtalet.
      <br />
      <br />
    </div>
  );
};
