import * as React from "react";
import { constants } from "../../../../../store/managementContracts";
import {
  constructStrRep,
  useCustomerPagination,
  performFilter,
  constants as customerConstants,
} from "../../../../../store/customers";

import listDefs from "../../../../Lists/Customers/listDefs";
// style, design

import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TableSelect } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Förvaltningsuppdrag på avtal</FormAreaTitle>

      <TableSelect
        storeName={storeName}
        method={method}
        fieldKey="opponent"
        constructStrRep={constructStrRep}
        modalInModal
        tableStoreName={customerConstants.STORE_NAME}
        listDefs={listDefs}
        paginationMethod={useCustomerPagination}
        performFilter={performFilter}
        fieldTitle={"Förvaltningsuppdrag"}
        title="Välj uppdrag..."
      />
    </>
  );
};
