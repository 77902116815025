import { durationParse } from "../../components/Displays/utils";
import { toMoneyString } from "../../components/utils/stringUtils";
import { checkTrailingUrlSlash } from "../base";
import { upload } from "../base/store/services";
import { PRODUCT_CATEGORIES } from "../invoicingProducts/utils";
import constants from "./store/constants";

const getObject = async (body) => {
  const url = constants.FASTDOC_HANDLE_URL;

  try {
    const result = await upload({
      url: checkTrailingUrlSlash(url),
      data: body,
    });
    const pdf = result.data?.data;
    if (!pdf) {
      return { data: null, unauthed: false };
    }

    return { data: pdf, unauthed: false };
  } catch (error) {
    let status = 500;
    if (error.response) {
      status = error.response.status;
    }
    if (status === 403) {
      return { data: null, unauthed: true };
    }

    return { data: null, unauthed: false };
  }
};

export const getTemplate = (id, pay) => {
  const data = {
    template_id: id,
    perform_payment: pay,
  };
  return getObject(data);
};

export const getInstance = (id, pay) => {
  const data = {
    instance_id: id,
    perform_payment: pay,
  };
  return getObject(data);
};

export const authenticate = async (username, password) => {
  try {
    await upload({
      url: constants.AUTH_URL,
      data: { username: username, password: password },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getHistoryProcessName = (id) => {
  return `${id}_SCRIVE_HISTORY`;
};

export const getSignupFormProcessName = () => {
  return "SCRIVE_SIGNUP_FORM";
};

export const getHasSignedUpProcessName = () => {
  return "SCRIVE_HAS_SIGNED_UP";
};

export const getSignupUrl = () => {
  return "/e-signing/sign-up";
};

export const overviewUrl = () => {
  return `/templates`;
};
export const detailUrl = ({ id }) => {
  return `/templates/detail/${id}`;
};
export const createUrl = () => {
  return `/editable-doc/create`;
};
export const editUrl = ({ id }) => {
  return `/editable-doc/edit/${id}`;
};

export const EDITABLE_DOC_CONTRACT_TYPES = {
  CUSTOMER_CONTRACT: "management-contract",
};

export const PARTY_KIND = {
  TENANT: "tenant",
  LANDLORD: "landlord",
  OTHER: "other",
  BITENANTS: "bi_tenant",
};

export const SCRIVE_STATE_MAP = {
  preparation: 9,
  pending: 4,
  closed: 1,
  canceled: 3,
  timedout: 6,
  rejected: 6,
  document_error: 6,
};

export const SCRIVE_DESCRIPTION_MAP = {
  preparation: "Dokumentet förbereds för utskick till de signerande parterna",
  pending: "Dokumentet inväntar signering",
  closed: "Dokumentet är signerat",
  canceled: "Signeringsprocessen är avbruten",
  timedout: "Signeringsprocessen är avbruten då tidsgränsen passerades",
  rejected:
    "Signeringsprocessen är avbruten då signering av dokumentet nekades",
  document_error:
    "Dokumentet innehåller fel, signeringsprocessen kan därav ej fortsätta",
};

export const handleContractEditableDocUrl = ({ contractType, id }) => {
  return `/editable-doc/handle/${contractType}/${id}`;
};

export const handleContractCancelledDocUrl = ({ contractType, id }) => {
  return `/cancelled-doc/handle/${contractType}/${id}`;
};

export const matchContractAttributesToPartyFields = ({
  editableDoc,
  contract,
  customer,
  company,
  companyUser,
  billingSettingCompany,
}) => {
  const companyParty = editableDoc.parties?.find(
    (party) => party.category === PARTY_KIND.LANDLORD
  );

  // set all fields values to placeholders
  if (editableDoc?.parties?.length) {
    editableDoc.parties.forEach((p) => {
      if (p.fields?.length) {
        p.fields.forEach((f) => {
          f._placeholder = f.value;
          f.value = "";
        });
      }
    });
  }

  // match contract, apartment, industrialpremoses, landlord, costs and invoicing data to landlord template fields
  if (companyParty) {
    if (contract) {
      const totalMonthlyBaseRent = contract?.costs
        ?.filter((c) => c.product_category === PRODUCT_CATEGORIES.BASE_RENT)
        ?.reduce((acc, cur) => acc + (cur.value || 0), 0);

      companyParty.fields.forEach((field) => {
        switch (field.kind) {
          case "contract_notify_interval":
            field.value = contract.notify_interval
              ? durationParse(contract?.notify_interval)?.presentation
              : "";
            break;
          case "contract_renew_interval":
            field.value = contract.renew_interval
              ? durationParse(contract?.renew_interval)?.presentation
              : "";
            break;
          case "contract_end_date":
            field.value = contract.end_date ? contract.end_date : "Tillsvidare";
            break;
          case "contract_start_date":
            field.value = contract.start_date || "";
            break;
          case "contract_id":
            field.value = contract.id_number || "";
            break;
          case "contract_total_rent_month":
            field.value = toMoneyString(totalMonthlyBaseRent);
            break;
          case "contract_total_rent_year":
            field.value = toMoneyString(totalMonthlyBaseRent * 12);
            break;
          default:
            break;
        }

        if (companyUser) {
          companyParty.user = companyUser;

          switch (field.kind) {
            case "full_name":
              field.value = `${companyUser.first_name || ""} ${
                companyUser.last_name || ""
              }`;
            case "first_name":
              field.value = companyUser.first_name || "";
              break;
            case "last_name":
              field.value = companyUser.last_name || "";
              break;
            case "personal_number":
              field.value = companyUser.legal_id || "";
              break;
            case "email":
              field.value = companyUser.email || "";
              break;
            case "phone":
              field.value = companyUser.phone || "";
              break;

            default:
              break;
          }
        }

        if (company) {
          switch (field.kind) {
            case "corporate_name":
              field.value = company.name || "";
              break;
            case "company_number":
              field.value = company.orgnr || "";
              break;
            default:
              break;
          }
        }

        if (billingSettingCompany) {
          switch (field.kind) {
            case "autogiro":
              field.value = billingSettingCompany.autogiro || "";
              break;
            case "bankgiro":
              field.value = billingSettingCompany.bankgiro || "";
              break;
            default:
              break;
          }
        }
      });
    }
  }

  // match main tenant fields to main tenant
  if (customer) {
    const matchingParty = editableDoc.parties.find((party) => {
      return party.category === PARTY_KIND.TENANT;
    });

    if (matchingParty) {
      matchingParty.fields.forEach((field) => {
        switch (field.kind) {
          case "full_name":
            field.value = `${customer.first_name || ""} ${
              customer.last_name || ""
            }`;
          case "first_name":
            field.value = customer.first_name || "";
            break;
          case "last_name":
            field.value = customer.last_name || "";
            break;
          case "personal_number":
            field.value = customer.legal_id || "";
            break;
          case "email":
            field.value = customer.email || "";
            break;
          case "company":
            field.value = customer.corporate_name || "";
            break;
          default:
            break;
        }
      });
    }
  }

  return editableDoc;
};

const geCustomerPartyFields = (customer) => {
  const fields = [
    {
      kind: "first_name",
      name: "Förnamn",
      value: customer?.first_name || "",
    },
    {
      kind: "last_name",
      name: "Efternamn",
      value: customer?.last_name || "",
    },
    {
      kind: "personal_number",
      name: "Personnummer",
      value: "",
    },
    {
      kind: "company_number",
      name: "Orgnr",
      value: customer?.legal_id || "",
    },
    {
      kind: "email",
      name: "Email",
      is_obligatory: true,
      value: customer?.email || "",
    },
    {
      kind: "phone",
      name: "Telefonnummer",
      value: customer?.phone || "",
    },
    {
      kind: "company",
      name: "Företagsnamn",
      value: customer?.corporate_name || "",
    },
  ];

  return fields;
};

const getCompanyPartyFields = ({ companyUser, company }) => {
  const fields = [
    {
      kind: "first_name",
      name: "Förnamn",
      value: companyUser?.first_name || "",
    },
    {
      kind: "last_name",
      name: "Efternamn",
      value: companyUser?.last_name || "",
    },
    {
      kind: "personal_number",
      name: "Personnummer",
      value: companyUser?.legal_id || "",
    },
    {
      kind: "company_number",
      name: "Organisationnummer",
      value: company?.orgnr || "",
    },
    {
      kind: "email",
      name: "Email",
      is_obligatory: true,
      value: companyUser?.email || "",
    },
    {
      kind: "phone",
      name: "Telefonnummer",
      value: companyUser?.phone || "",
    },
    {
      kind: "company",
      name: "Företagsnamn",
      value: company?.name || "",
    },
  ];

  return fields;
};

export const setInitialParties = ({
  editableDoc,
  customer,
  companyUser,
  company,
}) => {
  if (editableDoc && !editableDoc.parties) {
    editableDoc.parties = [];
  }

  if (customer) {
    const fields = geCustomerPartyFields(customer);

    editableDoc.parties.push({
      user: null,
      category: PARTY_KIND.TENANT,
      fields,
      signatory_role: "signing_party",
      delivery_method: "email",
      confirmation_delivery_method: "email",
      authentication_method_to_sign: "standard",
      authentication_method_to_view: "standard",
    });
  }

  if (companyUser || company) {
    const fields = getCompanyPartyFields({ companyUser, company });

    editableDoc.parties.push({
      category: PARTY_KIND.LANDLORD,
      fields,
      signatory_role: "signing_party",
      delivery_method: "email",
      confirmation_delivery_method: "email",
      authentication_method_to_sign: "standard",
      authentication_method_to_view: "standard",
    });
  }

  return editableDoc;
};
