import * as React from "react";
import { useTransition } from "react-spring";

// style, design
import * as SC from "./styles";
import newContractIcon from "../../../assets/svg/add_contract.svg";
import existingContractIcon from "../../../assets/svg/existing_contract.svg";

export default function ESignOrManual({
  isOpen,
  onClose,
  onSelect,
  description,
  digitalTitle,
  manualTitle,
  infoText,
}) {
  const modalTransition = useTransition(!!isOpen, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateY(100%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.BackDrop onClick={onClose} style={{ opacity: styles.opacity }}>
          <SC.Modal
            onClick={(e) => e.stopPropagation()}
            style={{ ...styles, opacity: 1 }}
          >
            <SC.ModalContent>
              <SC.ModalTitle style={{ marginBottom: "12px" }}>
                {description ||
                  "Välj om det avtalet ska signeras digitalt eller manuellt"}
              </SC.ModalTitle>

              <SC.ModalDescription>
                {infoText ||
                  "Vid manuell signering måste avtalet laddas ned och signeras mauellt, för att sedan markeras som signerat i Pigello"}
              </SC.ModalDescription>

              <SC.OptionsWrapper>
                <SC.Option onClick={() => onSelect("digital")}>
                  <SC.OptionIcon imgSrc={existingContractIcon} />
                  <SC.OptionLabel>
                    {digitalTitle || "Digital signering"}
                  </SC.OptionLabel>
                </SC.Option>

                <SC.Option onClick={() => onSelect("manual")}>
                  <SC.OptionIcon imgSrc={newContractIcon} />
                  <SC.OptionLabel>
                    {manualTitle || "Manuell signering"}
                  </SC.OptionLabel>
                </SC.Option>
              </SC.OptionsWrapper>
            </SC.ModalContent>
          </SC.Modal>
        </SC.BackDrop>
      )
  );
}
