import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { constants, create, useUserGroupForm } from "../../../store/userGroups";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { PrimaryButton } from "../Base/Buttons";
import { TextInput } from "../Base/Fields";
import RenderGroup from "./Permissions/RenderGroup";
import defaultGroups from "./Permissions/defaultGroups";
import NonConnectedSelect from "../Base/Old/NonConnected/NonConnectedSelect";
import { useHistory } from "react-router";

export default ({ defaultUserId, addGroupForm }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedBaseGroup, setSelectedBaseGroup] = React.useState(null);
  useUserGroupForm("POST");

  const group = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const handlePickedGroup = (groupId) => {
    let name = group?.name;
    const defaultGroup = defaultGroups.find((g) => g.id === groupId);
    const defaultNames = defaultGroups.map((g) => g.name);

    if (name === "" || defaultNames.find((defName) => defName === name)) {
      name = defaultGroup.name;
    } else {
      name = group?.name;
    }

    setSelectedBaseGroup(defaultGroup?.id);
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          name: name,
          permissions: defaultGroup?.permissions,
        },
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          name: "",
          permissions: [],
          users: [],
        },
      })
    );
  }, []);

  const onSuccess = (_, returnedData) => {
    setIsLoading(false);
    push(`/account/groups-and-users/group/${returnedData?.id}?tab=DETAILS`);
    //push detail when created
  };

  const onError = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const onSubmit = () => {
    setIsLoading(true);
    dispatch(create({ successCallback: onSuccess, errorCallback: onError }));
  };

  return (
    <>
      {isLoading && <OverlaySpinner />}
      <TextInput
        storeName={storeName}
        method="POST"
        title="Namn på grupp"
        fieldKey="name"
        placeholder="Ange namnet på den nya gruppen"
      />

      <NonConnectedSelect
        choices={defaultGroups}
        value={selectedBaseGroup || null}
        id="groups"
        placeholder="Välj grupp för att se behörigheter..."
        onUpdate={(group) => handlePickedGroup(group)}
        getOptionValue={(choice) => choice?.id}
        getOptionLabel={(choice) => choice?.name}
        label="Pigello standardgrupper"
      />

      {!!group && <RenderGroup group={group} />}
      {addGroupForm && (
        <div style={{ margin: "32px 0 " }}>
          <PrimaryButton
            title="Skapa grupp"
            clicked={onSubmit}
            disabled={!group?.name}
          />
        </div>
      )}
    </>
  );
};
