import styled, { css } from "styled-components";
import backIcon from "../../../assets/svg/back.svg";
import closeIcon from "../../../assets/svg/close-circle.svg";

export const TopBarContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 70px;
  box-shadow: ${(p) => p.theme.boxShadows.standardUnder};
  z-index: 99;
`;

export const InnerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  height: 100%;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: 4px 0px;
`;

export const Option = styled.div`
  cursor: pointer;
  width: 170px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};

  ${(p) =>
    p.active &&
    css`
      border-bottom: 2px solid ${(p) => p.theme.colors.primaryBlue};
    `}

  ${(p) =>
    !p.active &&
    css`
      &:hover {
        border-bottom: 2px solid ${(p) => p.theme.colors.gray2};
      }
    `}
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 2.5%;
  width: 95%;
  padding: 20px;
  background-color: white;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standardUnder};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  max-height: 600px;
  overflow-y: auto;
`;

export const ColorIndicator = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  min-width: 22px;
  max-width: 22px;
  background-color: ${(p) => p.color};
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: rgba(256, 256, 256, 0.95);
  z-index: 100;
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const BackButton = styled.div`
  height: 16px;
  width: 18px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${backIcon});
  cursor: pointer;
  margin-right: 12px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  height: 26px;
  width: 26px;
  margin-left: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeIcon});
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  color: ${(p) => p.theme.colors.primary};
  display: flex;
  flex-direction: column;
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Field = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 5px;
  margin-right: 6px;
  margin-bottom: 6px;
  font-size: 0.8rem;
  font-weight: 600;
  border: ${(p) => p.theme.borders.standard};
  transition: all 0.2s ease-in-out;

  ${(p) =>
    p.selected &&
    css`
      border: 2px solid ${(p) => p.theme.blue};
    `}

  ${(p) =>
    p.clickableCategory &&
    css`
      &:hover {
        background-color: ${p.theme.colors.blue};
        color: white;
      }
    `}
`;
