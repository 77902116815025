import { axiosInstance } from "../../base/store/axios";
import { buildQueryString, checkTrailingUrlSlash } from "../../base";
import moment from "moment";
import { cloneDeep } from "lodash";
import constants from "./constants";
import { constants as productConstants } from "../../invoicingProducts";
import { constants as costCenterConstants } from "../../invoicingCostCenters";
import { constants as projectConstants } from "../../invoicingProjects";

export const CONTRACT_TYPES = {
  LEASE: "LEASE",
  PARKING: "PARKING",
  OTHER: "OTHER",
  BRF_PREMIS: "BRF_PREMIS",
};

async function generateContractInvoiceIdPreview(id) {
  const {
    data: { data: preview },
  } = await axiosInstance.get(
    `/accounting/gateways/contract_invoice-utils/${id}/?action=preview`
  );

  return preview;
}

async function generateInvoicePreview({ postObj }) {
  const {
    data: { data: preview },
  } = await axiosInstance.post(
    `/accounting/gateways/invoice-utils/?action=preview`,
    postObj
  );

  return preview;
}

async function downloadBillectaFile(id) {
  const {
    data: { data: preview },
  } = await axiosInstance.get(`/accounting/gateways/files/${id}/?action=file`);

  return preview;
}

async function generateInvoiceIdPreview(id) {
  const {
    data: { data: preview },
  } = await axiosInstance.get(
    `/accounting/gateways/invoice-utils/${id}/?action=preview`
  );

  return preview;
}

async function sendInvoice(invoiceActionId, method) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=sendinvoice&method=${method}`
  );

  return data;
}

async function cancelReminders(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=cancelreminder`
  );

  return data;
}

async function disputeInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=dispute`
  );

  return data;
}

async function undisputeInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=canceldispute`
  );

  return data;
}

async function getInvoicesGeneratedByContractInvoice({ contractInvoiceId }) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/contract_invoice/${contractInvoiceId}/invoices/`
  );

  return data;
}

async function getAllInvoices(creditorId, closedFromDate) {
  const toDate = moment().add(1, "day").format("YYYY-MM-DD");
  const fromDate =
    closedFromDate || moment().subtract(3, "months").format("YYYY-MM-DD");

  const promises = [
    axiosInstance.get(`/accounting/gateways/invoices/open/${creditorId}/`),
    axiosInstance.get(
      `/accounting/gateways/invoices/closed/${creditorId}/?from=${fromDate}&to=${toDate}`
    ),
  ];
  const [{ data: openData }, { data: closedData }] = await Promise.all(
    promises
  );

  return [...openData, ...closedData];
}

async function getAllContractInvoices(creditorId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/contract_invoices/${creditorId}/`
  );

  return data;
}

async function getAllConnectedPayments({
  creditorId,
  connectedFromDate,
  connectedToDate,
}) {
  const { data } = await axiosInstance.put(
    "/accounting/gateways/payments/connected/",
    {
      CreditorPublicId: creditorId,
      From: connectedFromDate,
      To: connectedToDate,
    }
  );

  return data;
}

async function getAllUnconnectedPayments(creditorId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/payments/unconnected/${creditorId}/`
  );

  return data;
}

async function getSingleInvoice(invoiceActionId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/invoice/${invoiceActionId}/`
  );

  return data;
}

async function getReminderInvoice(invoiceActionId) {
  const { data } = await axiosInstance.get(
    `/accounting/gateways/invoice/reminder_invoice/${invoiceActionId}/`
  );

  return data;
}

async function getContractInvoice(contractInvoiceActionId) {
  try {
    const { data } = await axiosInstance.get(
      `/accounting/gateways/contract_invoice/${contractInvoiceActionId}/`
    );

    return data;
  } catch (e) {
    return new Error(e);
  }
}

async function attestInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=attest`
  );

  return data;
}

async function mergeInvoices({ invoiceIds, invoiceDate, dueDate }) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/?action=merge&invoicedate=${invoiceDate}&duedate=${dueDate}&${invoiceIds
      .map((i) => `actionpublicid=${i}`)
      .join("&")}`
  );

  return data;
}

async function deleteInvoice(invoiceActionId) {
  const { data } = await axiosInstance.delete(
    `/accounting/gateways/invoice/${invoiceActionId}/`
  );

  return data;
}

async function pauseInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=pause`
  );

  return data;
}

async function pauseContractInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/contract_invoice-utils/${invoiceActionId}/?action=pause`
  );

  return data;
}

async function resumeContractInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/contract_invoice-utils/${invoiceActionId}/?action=resume&nextdate=${moment().format(
      "YYYY-MM-DD"
    )}`
  );

  return data;
}

async function generateNextContractInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/contract_invoice-utils/${invoiceActionId}/?action=createinvoice`
  );

  return data;
}

async function moveNextContractInvoiceBySteps(invoiceActionId, steps) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/contract_invoice-utils/${invoiceActionId}/?action=movenextrun&steps=${steps}`
  );

  return data;
}
// createinvoice - generera nu
// pause - pausa
// resume - resuma - speca tidigast start
// movenextrun ?query eller data - kolla dok billecta

async function resumeInvoice(invoiceActionId) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=resume`
  );

  return data;
}

async function creditInvoice({ creditInvoiceActionId, debitInvoiceActionId }) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/${debitInvoiceActionId}/?action=creditaction&creditinvoiceactionpublicid=${creditInvoiceActionId}`
  );

  return data;
}

async function createCreditInvoice(postObj) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/invoice/`,
    postObj
  );

  return data;
}

async function registerInvoicePayment({ postObj, invoiceActionId }) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=registerpayment`,
    postObj
  );

  return data;
}

async function matchPaymentsToInvoice({
  paymentIds,
  invoiceId,
  paymentDate,
  creditorId,
}) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/payments/match/${creditorId}/`,
    paymentIds.map((pid) => ({
      UnhandledPaymentPublicId: pid,
      ActionPublicId: invoiceId,
      PaymentDate: paymentDate,
    }))
  );

  return data;
}

async function deletePayment({
  paymentId,
  creditorId,
  bookKeepingAccount,
  transactionDate,
}) {
  const res = await axiosInstance.delete(
    `/accounting/gateways/payments/unconnected/${creditorId}?paymentpublicid=${paymentId}${
      !!bookKeepingAccount ? `&bookkeepingaccount=${bookKeepingAccount}` : ""
    }${!!transactionDate ? `&transactiondate=${transactionDate}` : ""}`
  );

  return res;
}

async function sendManualReminderInvoice({ body }) {
  const { data } = await axiosInstance.patch(
    `/accounting/gateways/invoice-utils/?action=sendreminderinvoice`,
    body
  );

  return data;
}

async function activateAutogiroForInvoice(invoiceActionId) {
  const { data } = await axiosInstance.post(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=autogiro`
  );

  return data;
}

async function inactivateAutogiroForInvoice(invoiceActionId) {
  const { data } = await axiosInstance.delete(
    `/accounting/gateways/invoice-utils/${invoiceActionId}/?action=autogiro`
  );

  return data;
}

async function convertBillectaInvoiceToPigello({ invoice, creditorId }) {
  try {
    const invoiceClone = cloneDeep(invoice);

    const billectaProductIds = invoiceClone.Records?.map(
      (r) => r.ProductPublicId
    );
    const billectaCostCenterIds = invoiceClone.Records?.map(
      (r) => r.CostCenter
    );
    const billectaProjectIds = invoiceClone.Records?.map((r) => r.Project);

    const hasProducts = billectaProductIds?.length;
    const hasCostCenters = billectaCostCenterIds?.length;
    const hasProjects = billectaProjectIds?.length;

    const calls = [];

    if (hasProducts) {
      const productQuery = buildQueryString({
        billecta_object_ids: billectaProductIds,
      });

      calls.push(
        axiosInstance.get(`${productConstants.LIST_URL}?${productQuery}`)
      );
    }

    if (hasCostCenters) {
      const costCenterQuery = buildQueryString({
        billecta_object_ids: billectaCostCenterIds,
      });

      calls.push(
        axiosInstance.get(`${costCenterConstants.LIST_URL}?${costCenterQuery}`)
      );
    }

    if (hasProjects) {
      const projectQuery = buildQueryString({
        billecta_object_ids: billectaProjectIds,
      });

      calls.push(
        axiosInstance.get(`${projectConstants.LIST_URL}?${projectQuery}`)
      );
    }

    const responses = await Promise.all(calls.map((r) => r.catch((e) => e)));
    const validResponses = responses
      .filter((resp) => !(resp instanceof Error))
      ?.map((r) => r.data);
    const errors = responses.filter((resp) => resp instanceof Error);

    if (errors?.length) {
      throw Error("Något gick fel");
    }

    let products;
    let costCenters;
    let projects;

    if (hasProducts) {
      products = validResponses[0];
    }

    if (hasCostCenters) {
      if (hasProducts) {
        costCenters = validResponses[1];
      } else {
        costCenters = validResponses[0];
      }
    }

    if (hasProjects) {
      if (hasProducts && hasCostCenters) {
        projects = validResponses[2];
      } else if (hasProducts || hasCostCenters) {
        projects = validResponses[1];
      } else {
        projects = validResponses[0];
      }
    }

    if (invoiceClone.Records) {
      invoiceClone.Records.forEach((r) => {
        if (r.ProductPublicId && products) {
          r.ProductPublicId = products.find((p) => {
            return p.billecta_ids?.find((pbi) => {
              return (
                pbi.creditor_id === creditorId &&
                pbi.object_id === r.ProductPublicId
              );
            });
          });
        }

        if (r.CostCenter && costCenters) {
          r.CostCenter = costCenters.find((cc) => {
            return cc.billecta_ids?.find((ccbi) => {
              return (
                ccbi.creditor_id === creditorId &&
                ccbi.object_id === r.CostCenter
              );
            });
          });
        }

        if (r.Project && projects) {
          r.Project = projects.find((p) => {
            return p.billecta_ids?.find((pbi) => {
              return (
                pbi.creditor_id === creditorId && pbi.object_id === r.Project
              );
            });
          });
        }
      });
    }

    return invoiceClone;
  } catch (e) {
    console.log(e);
    return invoice;
  }
}

async function convertPigelloProductsToBillecta({ products, companyId }) {
  try {
    const productIds = products?.map((p) => p.id);

    const { data: res } = await axiosInstance.patch(
      checkTrailingUrlSlash(`${constants.ENSURE_PRODUCT_IDS_URL}${companyId}`),
      {
        ids: productIds,
      }
    );

    const mapped = productIds.map((id) => res[id]);

    return mapped;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function convertPigelloCostCentersToBillecta({ costCenters, companyId }) {
  try {
    const costCenterIds = costCenters?.map((p) => p.id);

    const { data: res } = await axiosInstance.patch(
      checkTrailingUrlSlash(
        `${constants.ENSURE_COSTCENTER_IDS_URL}${companyId}`
      ),
      {
        ids: costCenterIds,
      }
    );

    const mapped = costCenterIds.map((id) => res[id]);

    return mapped;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function convertPigelloProjectsToBillecta({ projects, companyId }) {
  try {
    const projectIds = projects?.map((p) => p.id);

    const { data: res } = await axiosInstance.patch(
      checkTrailingUrlSlash(`${constants.ENSURE_PROJECT_IDS_URL}${companyId}`),
      {
        ids: projectIds,
      }
    );

    const mapped = projectIds.map((id) => res[id]);

    return mapped;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function convertPigelloInvoiceToBillecta({
  invoice,
  companyId,
  isUpdate,
}) {
  try {
    const invoiceClone = cloneDeep(invoice);

    const debtorId = invoiceClone.DebtorPublicId;

    // get ids for products and so on
    const products = invoiceClone?.Records?.map((r) => r.ProductPublicId)
      ?.filter((pr) => pr)
      ?.map((p) => p.id);
    const costCenters = invoiceClone?.Records?.map((r) => r.CostCenter)
      ?.filter((cc) => cc)
      ?.map((c) => c.id);
    const projects = invoiceClone?.Records?.map((r) => r.Project)
      ?.filter((pr) => pr)
      ?.map((p) => p.id);

    const hasProducts = products?.length;
    const hasProjects = projects?.length;
    const hasCostCenters = costCenters?.length;

    // map up calls
    let calls = [];

    // get debtor id if not is update
    if (!isUpdate) {
      calls.push(
        axiosInstance.patch(
          checkTrailingUrlSlash(
            `${constants.ENSURE_DEBTOR_IDS_URL}${companyId}`
          ),
          {
            ids: [debtorId],
          }
        )
      );
    }

    if (hasProducts) {
      calls = [
        ...calls,
        axiosInstance.patch(
          checkTrailingUrlSlash(
            `${constants.ENSURE_PRODUCT_IDS_URL}${companyId}`
          ),
          {
            ids: products,
          }
        ),
      ];
    }

    if (hasCostCenters) {
      calls = [
        ...calls,
        axiosInstance.patch(
          checkTrailingUrlSlash(
            `${constants.ENSURE_COSTCENTER_IDS_URL}${companyId}`
          ),
          {
            ids: costCenters,
          }
        ),
      ];
    }

    if (hasProjects) {
      calls = [
        ...calls,
        axiosInstance.patch(
          checkTrailingUrlSlash(
            `${constants.ENSURE_PROJECT_IDS_URL}${companyId}`
          ),
          {
            ids: projects,
          }
        ),
      ];
    }

    // get mapped data from backend
    const responses = await Promise.all(calls.map((r) => r.catch((e) => e)));

    const validResponses = responses
      .filter((resp) => !(resp instanceof Error))
      ?.map((r) => r.data);
    const errors = responses.filter((resp) => resp instanceof Error);

    if (errors?.length) {
      throw Error("Något gick fel");
    }

    // map backend data to post obj
    let debtorResponse;
    let productsResponse;
    let costCentersResponse;
    let projectsResponse;

    if (!isUpdate) {
      debtorResponse = validResponses[0];
    }

    if (hasProducts) {
      if (isUpdate) {
        // we have no debtor - index 0
        productsResponse = validResponses[0];
      } else {
        // we have a debtor - index 1
        productsResponse = validResponses[1];
      }
    }

    if (hasCostCenters) {
      if (!isUpdate && hasProducts) {
        // we have debtor and products - index 2
        costCentersResponse = validResponses[2];
      } else if (isUpdate && hasProducts) {
        // we have no debtor but products - index 1
        costCentersResponse = validResponses[1];
      } else if (isUpdate) {
        // we have no debtor and no products - index 0
        costCentersResponse = validResponses[0];
      } else if (!isUpdate) {
        // we have a debtor and no products - index 1
        costCentersResponse = validResponses[1];
      }
    }

    if (hasProjects) {
      if (!isUpdate && hasCostCenters && hasProducts) {
        // we have debtor and cost centers and products - index 3
        projectsResponse = validResponses[3];
      } else if (isUpdate && hasCostCenters && hasProducts) {
        // we have no debtor but cost centers AND products - index 2
        projectsResponse = validResponses[2];
      } else if (isUpdate && (hasCostCenters || hasProducts)) {
        // we have no debtor but cost centers OR products - index 1
        projectsResponse = validResponses[1];
      } else if (!isUpdate && (hasCostCenters || hasProducts)) {
        // we have a debtor and cost centers OR products - index 2
        projectsResponse = validResponses[2];
      } else if (isUpdate) {
        // we have no debtor or cost centers or products - index 0
        projectsResponse = validResponses[0];
      } else if (!isUpdate) {
        // we have a debtor but no cost centers or products - index 1
        projectsResponse = validResponses[1];
      }
    }

    if (!isUpdate) {
      invoiceClone.DebtorPublicId = debtorResponse[debtorId];
    }

    if (invoiceClone.Records) {
      invoiceClone.Records.forEach((r) => {
        if (hasProducts && r.ProductPublicId) {
          r.ProductPublicId = productsResponse[r.ProductPublicId?.id];
        }

        if (hasCostCenters && r.CostCenter) {
          r.CostCenter = costCentersResponse[r.CostCenter?.id];
        }

        if (hasProjects && r.Project) {
          r.Project = projectsResponse[r.Project?.id];
        }
      });
    }

    return invoiceClone;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export {
  generateContractInvoiceIdPreview,
  generateInvoicePreview,
  downloadBillectaFile,
  getAllInvoices,
  getAllContractInvoices,
  generateInvoiceIdPreview,
  sendInvoice,
  cancelReminders,
  disputeInvoice,
  undisputeInvoice,
  getAllConnectedPayments,
  getAllUnconnectedPayments,
  getSingleInvoice,
  getContractInvoice,
  attestInvoice,
  mergeInvoices,
  deleteInvoice,
  pauseInvoice,
  pauseContractInvoice,
  resumeInvoice,
  resumeContractInvoice,
  generateNextContractInvoice,
  moveNextContractInvoiceBySteps,
  creditInvoice,
  createCreditInvoice,
  registerInvoicePayment,
  matchPaymentsToInvoice,
  sendManualReminderInvoice,
  deletePayment,
  getInvoicesGeneratedByContractInvoice,
  convertPigelloInvoiceToBillecta,
  convertBillectaInvoiceToPigello,
  getReminderInvoice,
  convertPigelloProductsToBillecta,
  convertPigelloProjectsToBillecta,
  convertPigelloCostCentersToBillecta,
  activateAutogiroForInvoice,
  inactivateAutogiroForInvoice,
};
