import * as React from "react";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import {
  performFilter,
  constants,
  detailUrl,
  useCalendarEventPagination,
} from "../../../store/calendarEvents";

export default ({
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  persistantQueryString,
  persistantFilterMethod,
  handleRowClicked,
  checkRowHighlighted,
}) => {
  const storeName = constants.STORE_NAME;

  const columns = React.useMemo(() => columnDefs(), []);
  const fetchAllTreshold = 25;

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantFilterMethod={persistantFilterMethod}
      persistantQueryString={persistantQueryString}
      paginationMethod={useCalendarEventPagination}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      onRowClicked={
        handleRowClicked ? (row) => handleRowClicked(row) : () => {}
      } // prevent default behaviour
      title={hideTitle ? undefined : "Att göra"}
      exportSheetController={exportSheetController}
      {...{
        hideFilters,
        hideExport,
        hideSearch,
        hideColumns,
        checkRowHighlighted,
      }}
    />
  );
};
