import { cloneDeep } from "lodash";

import {
  addToProcess,
  getAllFetchProcessName,
  getFiltered,
  getSingleFetchProcessName,
  checkTrailingUrlSlash,
  cleanOptions,
  destroyForm,
  getPagination,
  options,
  patch,
  post,
  updateActiveFormInstance,
  get,
} from "../../base";
import {
  instructions,
  retrieve,
  update as serviceUpdate,
  upload,
} from "../../base/store/services";
import { store } from "../../store";
import { addToast, TOAST_TYPES } from "../../toasts";
import {
  getHasSignedUpProcessName,
  getHistoryProcessName,
  getSignupFormProcessName,
} from "../utils";

import constants from "./constants";
import { createUploadHandler } from "./uploadHandler";

export const getPrivateTemplates = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring });
};

export const getAtlasTemplates = (querystring) => {
  const url = `${constants.PUBLIC_TEMPLATE_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring });
};

export const getAtlasFastdocTemplates = (querystring) => {
  const url = `${constants.PUBLIC_TEMPLATE_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring });
};

/**
 * Querystring is just for differentiationg instances from templates
 * in state
 */

const preProcessFastdocResponse = (data) => {
  let res = data?.Data || [];
  res.forEach((r) => (r.id = `_FASTDOC_${r.Id}`));

  return res;
};

export const getFastdocInstances = (querystring) => {
  const url = constants.FASTDOC_HANDLE_URL;
  return getFiltered({
    url,
    constants,
    querystring,
    preProcess: preProcessFastdocResponse,
  });
};

export const getFastdocTemplates = (querystring) => {
  const url = constants.FASTDOC_TEMPLATE_URL;
  return getFiltered({
    url,
    constants,
    querystring,
    preProcess: preProcessFastdocResponse,
  });
};

const getHistory = ({ id }) => {
  const url = `${constants.SCRIVE_UTIL_URL}${id}/history/`;
  const name = getHistoryProcessName(id);
  return async (dispatch) => {
    addToProcess(dispatch, constants, name);

    const result = await retrieve({ url });
    let data = result.data;

    dispatch({
      type: constants.INSERT_INTO_HISTORY,
      payload: { result: data, name: name, id: id },
    });
  };
};

export const getScriveHistory = (id) => {
  return getHistory({ id });
};

export const activateScriveSigning = ({
  id,
  successCallback,
  errorCallback,
}) => {
  const url = `${constants.SCRIVE_SIGN_URL}${id}/`;

  return async (dispatch) => {
    try {
      const result = await serviceUpdate({ url, data: {} });
      let returnedData = result.data;

      if (successCallback) {
        successCallback({}, returnedData);
      }

      // insert into state
      dispatch({
        type: constants.INSERT_INTO_ALL,
        payload: { result: returnedData },
      });

      // refresh history
      dispatch(getScriveHistory(id));

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Signering har startats",
          description: "Du ser händelser på detaljsidan för avtalet",
        })
      );
    } catch (error) {
      let returnedData = error.response.data;

      // check if callback
      if (errorCallback) {
        errorCallback({}, returnedData);
      }

      if (error.response.status === 403) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Rättighet saknas",
            description:
              "Ditt konto har ej satts upp för att använda e-signering ännu.",
          })
        );
      }

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Signeringsaktiverandet misslyckades",
          description: "Vänligen försök igen",
        })
      );
    }
  };
};

export const SCRIVE_ACTIONS = {
  CANCEL: "cancel",
  RESTART: "restart",
  PROLONG: "prolong",
  REMIND: "remind",
  SET_AUTO_REMINDER: "setautoreminder",
};

export const performScriveAction = ({
  id,
  action,
  data,
  successCallback,
  errorCallback,
}) => {
  let url;
  if (
    [SCRIVE_ACTIONS.PROLONG, SCRIVE_ACTIONS.SET_AUTO_REMINDER].includes(action)
  ) {
    url = `${constants.SCRIVE_EXTRAS_URL}${id}/${action}/`;
  } else {
    url = `${constants.SCRIVE_UTIL_URL}${id}/${action}/`;
  }

  return async (dispatch) => {
    try {
      const result = await serviceUpdate({ url, data: data || {} });
      let returnedData = result.data;

      if (successCallback) {
        successCallback({}, returnedData);
      }

      // insert into state
      dispatch({
        type: constants.INSERT_INTO_ALL,
        payload: { result: returnedData },
      });

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Åtgärden lyckades",
          description: "Du ser händelser på detaljsidan för avtalet",
        })
      );
    } catch (error) {
      let returnedData = error.response.data;

      // check if callback
      if (errorCallback) {
        errorCallback({}, returnedData);
      }

      if (error.response.status === 403) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Rättighet saknas",
            description:
              "Ditt konto har ej satts upp för att använda e-signering ännu.",
          })
        );
      }

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Åtgärden misslyckades",
          description: "Vänligen försök igen",
        })
      );
    }
  };
};

export const getScriveSignupForm = () => {
  const name = getSignupFormProcessName();

  return async (dispatch) => {
    addToProcess(dispatch, constants, name);

    const result = await instructions({
      url: checkTrailingUrlSlash(constants.SCRIVE_SIGNUP_URL),
    });

    const cleaned = cleanOptions(result.data.actions["POST"]);

    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: { result: cleaned, name: name, method: "POST" },
    });
  };
};

export const checkHasSignedUp = () => {
  const name = getHasSignedUpProcessName();
  const url = constants.SCRIVE_SIGNUP_URL;

  return async (dispatch) => {
    // block request loop
    const hasAttempted = store.getState()?.editabledocs?.hasAttempted;

    if (hasAttempted) return;

    addToProcess(dispatch, constants, name);
    try {
      const result = await retrieve({ url });
      let returnedData = result.data;

      dispatch({
        type: constants.SET_HAS_SIGNED_UP,
        payload: {
          hasSignedUp: returnedData["has_signed_up"],
          canUse: returnedData["has_credential"],
        },
      });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: constants.SET_HAS_SIGNED_UP,
        payload: { result: false },
      });
    }
  };
};

export const setSignupProposal = () => {
  const url = `${constants.SCRIVE_SIGNUP_URL}?propose=true`;

  return async (dispatch) => {
    try {
      const result = await retrieve({ url });
      let returnedData = result.data;

      // we use the common formInstance here
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: returnedData,
        })
      );
    } catch (error) {}
  };
};

export const destroyScriveSignupForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const submitScriveSignup = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  let data = store.getState()[constants.STORE_NAME].formInstance;
  return async (dispatch) => {
    try {
      const result = await upload({
        url: checkTrailingUrlSlash(constants.SCRIVE_SIGNUP_URL),
        data,
      });
      let returnedData = result.data;

      // destroy form
      dispatch(destroyForm({ constants, method: "POST", success: true }));

      // check if postProcess
      if (processSuccess) {
        returnedData = processSuccess(returnedData);
      }

      dispatch({
        type: constants.SET_HAS_SIGNED_UP,
        payload: { result: true },
      });

      // check if callback
      if (successCallback) {
        successCallback(data, returnedData);
      }

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Åtgärden lyckades",
          description: "Vi meddelar dig när e-signeringen är redo att användas",
        })
      );
    } catch (error) {
      let returnedData = error.response.data;
      if (processError) {
        returnedData = processError(returnedData);
      }

      dispatch({
        type: constants.SET_FORM_ERROR,
        payload: { result: returnedData },
      });

      // check if callback
      if (errorCallback) {
        errorCallback(data, returnedData);
      }

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Åtgärden misslyckades",
          description: "Vänligen se över felmeddelandena",
        })
      );
    }
  };
};

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (querystring, callback, taskToken) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  // fix scrive phone validation
  if (cloned.parties?.length) {
    cloned.parties.forEach((party) => {
      //remove when field added
      party.confirmation_delivery_method = party.delivery_method;

      if (party.fields?.length) {
        party.fields.forEach((field) => {
          if (field.kind === "phone") {
            if (field.value?.substr(0, 2) === "00") {
              // 0046723070119 -> +46723070119
              field.value = `+${field.value.substr(2, field.length)}`;
            } else if (field.value?.substr(0, 2) === "07") {
              // 0723070119 -> +46723070119
              field.value = `+46${field.value.substr(1, field.length)}`;
            } else if (field.value?.substr(0, 2) === "46") {
              // 0723070119 -> +46723070119
              field.value = `+${field.value}`;
            }
          }
        });
      }
    });
  }

  // the main file
  if (cloned?.docData_) {
    // file has been edited, editabledoc.doc has been set
    // so delete docData (on the clone, we have the reference on formInstance)
    delete cloned?.docData_;
  } else {
    // file has not been edited, don't send anything
    delete cloned?.doc;
  }

  // the attachments
  (cloned?.attachments || []).forEach((a) => {
    // will never be sent to backend
    delete a._tempData;
    delete a._referenceId;

    if (a.id) {
      // file has not been edited, so we can just use the id
      delete a.pdf;
    }
  });

  return cloned;
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
    uploadCallback: uploadCallback,
    forceData,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
    uploadCallback: uploadCallback,
    forceData,
  });
};
