import * as React from "react";

import {
  Confirm,
  InvoicingSetting,
  InvoicingDebtSetting,
  Interval,
  Accounting,
} from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "INVOICING_SETTING":
      return <InvoicingSetting {...{ method }} />;
    case "DEBT_SETTING":
      return <InvoicingDebtSetting {...{ method }} />;
    case "INTERVAL":
      return <Interval {...{ method }} />;
    case "ACCOUNTING":
      return <Accounting {...{ method }} />;

    default:
      return <Confirm {...{ method }} />;
  }
};
