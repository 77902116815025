import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import LeaseList from "../../../components/Lists/ManagementContracts/FullTable";
import { useManagementContractPaginationCount } from "../../../store/managementContracts/hooks/retrieve";
import { buildQueryString } from "../../../store/base";
import {
  FilterButton,
  FilterWrapper,
} from "../../../components/Filters/styles";
import { cloneDeep } from "lodash";

export const PERSISTANT_CATEGORY_KEY = "persistant_category_managementcontract";

export default function ManagementContracts() {
  const [readyToRenderTable, setReadyToRenderTable] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [persistantCategories, setPersistantCategories] = React.useState(null);

  const [activeCount] = useManagementContractPaginationCount({
    statuses: [0, 1, 2],
  });

  const [warningCount] = useManagementContractPaginationCount({
    statuses: [3],
  });

  const [upcomingCount] = useManagementContractPaginationCount({
    statuses: [4],
  });

  const [notSignedCount] = useManagementContractPaginationCount({
    statuses: [5],
  });

  const [closedCount] = useManagementContractPaginationCount({
    statuses: [6, 7],
  });

  const [earlierCount] = useManagementContractPaginationCount({
    statuses: [9],
  });

  const persistantQueryString = buildQueryString({
    state__in: selectedFilters?.length ? selectedFilters : undefined,
  });
  const persistantFilterMethod = (obj) => {
    // no filters, no filtered realestates
    if (!selectedFilters?.length) return obj;

    // filters, no filtered realestates

    return selectedFilters.includes(obj.state);
  };

  React.useEffect(() => {
    const persistantCategories = localStorage.getItem(PERSISTANT_CATEGORY_KEY);
    const parsed = persistantCategories ? JSON.parse(persistantCategories) : [];

    setSelectedFilters(parsed);

    if (parsed?.length) {
      setPersistantCategories(parsed);
    }
    setReadyToRenderTable(true);
  }, []);

  const handleClearPersistantCategories = () => {
    setSelectedFilters([]);
    setPersistantCategories(null);
    localStorage.removeItem(PERSISTANT_CATEGORY_KEY);
  };

  const toggleSelected = (statuses) => {
    let filterCopy = cloneDeep(selectedFilters);

    statuses.forEach((status) => {
      if (filterCopy.includes(status)) {
        filterCopy = filterCopy.filter((s) => s !== status);
      } else {
        filterCopy.push(status);
      }
    });

    setSelectedFilters(filterCopy);
    localStorage.setItem(PERSISTANT_CATEGORY_KEY, JSON.stringify(filterCopy));
  };

  const getExternalFilters = () => {
    return (
      <FilterWrapper>
        <FilterButton
          style={{
            color: "#2ec177",
            borderColor: "#2ec177",
            backgroundColor: "#e4fbef",
          }}
          selected={
            selectedFilters.includes(0) ||
            selectedFilters.includes(1) ||
            selectedFilters.includes(2)
          }
          onClick={() => {
            toggleSelected([0, 1, 2]);
          }}
        >
          Aktiva ({activeCount})
        </FilterButton>

        <FilterButton
          style={{
            color: "#fdb738",
            borderColor: "#fdb738",
            backgroundColor: "#fff8ec",
          }}
          selected={selectedFilters.includes(3)}
          onClick={() => {
            toggleSelected([3]);
          }}
        >
          Utgående ({warningCount})
        </FilterButton>

        <FilterButton
          style={{
            color: "#6883fb",
            borderColor: "#6883fb",
            backgroundColor: "#e4f1ff",
          }}
          selected={selectedFilters.includes(4)}
          onClick={() => {
            toggleSelected([4]);
          }}
        >
          Kommande ({upcomingCount})
        </FilterButton>

        <FilterButton
          style={{
            color: "#cc68fb",
            borderColor: "#cc68fb",
            backgroundColor: "#f2ebff",
          }}
          selected={selectedFilters.includes(5)}
          onClick={() => {
            toggleSelected([5]);
          }}
        >
          Ej signerade ({notSignedCount})
        </FilterButton>

        <FilterButton
          style={{
            color: "#fa3d56",
            borderColor: "#fa3d56",
            backgroundColor: "#ffecf2",
          }}
          selected={selectedFilters.includes(6) || selectedFilters.includes(7)}
          onClick={() => {
            toggleSelected([6, 7]);
          }}
        >
          Uppsagda ({closedCount})
        </FilterButton>

        <FilterButton
          style={{
            color: "#5C667B",
            borderColor: "#5C667B",
            backgroundColor: "#F3F4F6",
          }}
          selected={selectedFilters.includes(9)}
          onClick={() => {
            toggleSelected([9]);
          }}
        >
          Tidigare ({earlierCount})
        </FilterButton>
      </FilterWrapper>
    );
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Förvaltningsavtal</OverviewTitle>
          </OverviewTitleWrapper>

          {readyToRenderTable && (
            <LeaseList
              persistantQueryString={persistantQueryString}
              persistantFilterMethod={persistantFilterMethod}
              hideTitle
              externalFilters={getExternalFilters()}
              withPersistantSortBy
              withPersistantGlobalFilter
              persistantCategories={persistantCategories}
              clearCategories={handleClearPersistantCategories}
              withCosts
              withFullScreen
            />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
