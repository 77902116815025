import * as React from "react";
import InvoiceChapter from "./InvoiceChapter";
import * as SC from "../styles";
import {
  Checkbox,
  PositiveNumber,
  Select,
  TextInput,
} from "../../../Forms/Base/Fields";
import { useHistory } from "react-router-dom";

export default function DebtCollectionChapter({
  constants,
  validationErrors,
  instance,
  automatedDebtCollectionRemoved,
  automatedDebtCollectionAdded,
  method,
  fieldKey,
  instructionsKey,
  company,
}) {
  const { push } = useHistory();

  return (
    <InvoiceChapter
      title="Automatisk kravhantering"
      description="Inställningar för automatiserad kravhantering"
      openCallback={automatedDebtCollectionAdded}
      closeCallback={automatedDebtCollectionRemoved}
      isAddRemove={true}
      forceOpen={!!instance.DebtCollectionDetails}
    >
      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors[
              ("DebtCollectionDetails.DaysDelayAfterDueDate",
              "DebtCollectionDetails.DaysDelayAfterDueDate_Reminder")
            ],
        }}
      >
        <SC.InvoicedBoxRowTitle>
          Antal dagar efter förfallodatum som fakturan skickas till
          kravhantering:
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <PositiveNumber
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={method || "POST"}
            fieldKey={
              fieldKey
                ? `${fieldKey}.DebtCollectionDetails.DaysDelayAfterDueDate`
                : `DebtCollectionDetails.DaysDelayAfterDueDate`
            }
            {...{
              instructionsKey: instructionsKey
                ? `${instructionsKey}.DebtCollectionDetails.DaysDelayAfterDueDate`
                : undefined,
            }}
          />
          {!!validationErrors[
            "DebtCollectionDetails.DaysDelayAfterDueDate"
          ] && (
            <SC.InvalidRowWarning>
              Minst 1 dag är god kravsed. Fakturan kommer ej kunna attesteras.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors[
            "DebtCollectionDetails.DaysDelayAfterDueDate_Reminder"
          ] && (
            <SC.InvalidRowWarning>
              Datumet för att skicka fakturan till kravhantering måste vara
              minst 1 dag efter påminnelsefakturans utskicksdatum.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors[
              "StartDebtCollectionActionLevelLatePaymentFeeNotCompany"
            ],
        }}
      >
        <SC.InvoicedBoxRowTitle>
          Påbörja kravhantering vid nivå:
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <Select
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={method || "POST"}
            fieldKey={
              fieldKey
                ? `${fieldKey}.DebtCollectionDetails.StartDebtCollectionActionLevel`
                : `DebtCollectionDetails.StartDebtCollectionActionLevel`
            }
            {...{
              instructionsKey: instructionsKey
                ? `${instructionsKey}.DebtCollectionDetails.StartDebtCollectionActionLevel`
                : undefined,
            }}
          />
          {}
          {!!validationErrors[
            "StartDebtCollectionActionLevelLatePaymentFeeNotCompany"
          ] && (
            <SC.InvalidRowWarning>
              Fakturan/fakturorna kommer inte att kunna attesteras.
              Förseningsavgift (företag) kan inte användas på kravhantering mot
              privatpersoner.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors["DebtCollectionDetails.PaymentTermsInDays"],
        }}
      >
        <SC.InvoicedBoxRowTitle>
          Betalningvillkor dagar för påminnelse:
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <PositiveNumber
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={method || "POST"}
            fieldKey={
              fieldKey
                ? `${fieldKey}.DebtCollectionDetails.PaymentTermsInDays`
                : `DebtCollectionDetails.PaymentTermsInDays`
            }
            {...{
              instructionsKey: instructionsKey
                ? `${instructionsKey}.DebtCollectionDetails.PaymentTermsInDays`
                : undefined,
            }}
          />
          {!!validationErrors["DebtCollectionDetails.PaymentTermsInDays"] && (
            <SC.InvalidRowWarning>
              Minst 10 dagar är god kravsed. Fakturan kommer ej kunna
              attesteras.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>
          Typ av ränta vid utebliven betalning:
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <Select
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={method || "POST"}
            fieldKey={fieldKey ? `${fieldKey}.InterestType` : `InterestType`}
            {...{
              instructionsKey: instructionsKey
                ? `${instructionsKey}.InterestType`
                : undefined,
            }}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>
          Avtalad ränta vid utebliven betalning i %:
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <PositiveNumber
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={method || "POST"}
            fieldKey={
              fieldKey ? `${fieldKey}.InterestPercentage` : `InterestPercentage`
            }
            {...{
              instructionsKey: instructionsKey
                ? `${instructionsKey}.InterestPercentage`
                : undefined,
            }}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      {instance?.InterestPercentage > 8 && (
        <SC.InvoicedBoxRow
          {...{
            invalid: !!validationErrors["ReasonForHigherInterest"],
          }}
        >
          <SC.InvoicedBoxRowTitle>
            Anledning till hög ränta:
          </SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            <TextInput
              rowSize
              noMargin
              noTitle
              storeName={constants.STORE_NAME}
              method={method || "POST"}
              fieldKey={
                fieldKey
                  ? `${fieldKey}.ReasonForHigherInterest`
                  : `ReasonForHigherInterest`
              }
              {...{
                instructionsKey: instructionsKey
                  ? `${instructionsKey}.ReasonForHigherInterest`
                  : undefined,
              }}
            />
            {!!validationErrors["ReasonForHigherInterest"] && (
              <SC.InvalidRowWarning>
                Anledning till hög ränta måste anges om räntan är över 8%
              </SC.InvalidRowWarning>
            )}
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>
      )}

      <SC.InvoicedBoxRow
        {...{
          invalid: !!validationErrors["InterestStartInDaysAfterDueDate"],
        }}
      >
        <SC.InvoicedBoxRowTitle>
          Antal dagar efter förfallodatum som ränta börjar räknas:
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <PositiveNumber
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={method || "POST"}
            fieldKey={
              fieldKey
                ? `${fieldKey}.InterestStartInDaysAfterDueDate`
                : `InterestStartInDaysAfterDueDate`
            }
            {...{
              instructionsKey: instructionsKey
                ? `${instructionsKey}.InterestStartInDaysAfterDueDate`
                : undefined,
            }}
          />
          {!!validationErrors["InterestStartInDaysAfterDueDate"] && (
            <SC.InvalidRowWarning>
              Minst 8 dagar är god kravsed. Fakturan kommer ej kunna attesteras.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Antal påminnelser:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <Select
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={method || "POST"}
            fieldKey={
              fieldKey
                ? `${fieldKey}.DebtCollectionDetails.NumberOfReminders`
                : `DebtCollectionDetails.NumberOfReminders`
            }
            {...{
              instructionsKey: instructionsKey
                ? `${instructionsKey}.DebtCollectionDetails.NumberOfReminders`
                : undefined,
            }}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>
    </InvoiceChapter>
  );
}
