import * as React from "react";

// style, design
import { LinkedObject, DateCell } from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "../Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";
import { StatusLabel } from "../Base/CompleteList/styles";
import { detailUrl as managementContractDetailUrl } from "../../../store/managementContracts";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: "is_active",
      Cell: ({ value }) => {
        const label = value ? "Aktiv" : "Ej aktiv";
        const state = value ? 1 : 3;

        return <StatusLabel state={state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Förvaltningsavtal",
      accessor: "str_representation",
      Cell: ({ row, value }) => (
        <LinkedObject
          obj={{
            str_representation: value,
            id: row.original.customer_contract?.id,
          }}
          urlMethod={managementContractDetailUrl}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Startdatum för avisering",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
  ];

  return cols;
};
