import { cloneDeep } from "lodash";
import moment from "moment";
import {
  post,
  put,
  destroyForm,
  addToProcess,
  setActiveFormInstance,
} from "../../base";

import { getFormInstanceStateType } from "../../base/utils";

import { cleanPutInvoice, invoiceToCreditInvoice } from "../../billecta/utils";

import constants from "./constants";
import { store } from "../../store";
import { CONTRACT_TYPES } from "./services";
import * as services from "./services";
import * as searchServices from "../../billectaSearch/store/services";
import {
  checkTrailingUrlSlash,
  removeFromProgress,
} from "../../base/store/actions";
import { retrieve } from "../../base/store/services";
import { axiosInstance } from "../../base/store/axios";
import {
  billingDatesProposalFromPeriod,
  handlePeriodCosts,
  setProducts,
} from "../utils";
import { addToast, TOAST_TYPES } from "../../toasts";

export const getInvoicesGeneratedByContractInvoice = ({
  contractInvoiceId,
  name,
}) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, name);

    const invoices = await services.getInvoicesGeneratedByContractInvoice({
      contractInvoiceId,
    });

    const sorted = invoices.sort((a, b) => {
      const aIsBefore = moment(a.Created).isBefore(moment(b.Created));

      return aIsBefore ? 1 : -1;
    });

    dispatch({
      type: constants.SET_INVOICES_GENERATED_BY_CONTRACT_INVOICE,
      payload: {
        invoices: sorted,
        name,
      },
    });

    removeFromProgress(dispatch, constants, name);
  };
};

export const getAllInvoices = (creditorId) => {
  return async (dispatch) => {
    const state = store.getState();
    const closedFromDate = state[constants.STORE_NAME].invoicesClosedFromDate;
    const invoices = await services.getAllInvoices(creditorId, closedFromDate);

    const sorted = invoices.sort((a, b) => {
      const aIsBefore = moment(a.Created).isBefore(moment(b.Created));

      return aIsBefore ? 1 : -1;
    });

    invoices.forEach((i) => {
      if (i.Stage === "Manual" && !!i.ReminderInvoiceActionPublicId) {
        i.Stage = "ReminderInvoiceSent";
      }
    });

    dispatch({
      type: constants.SET_ALL_INVOICES,
      payload: {
        invoices: sorted,
      },
    });
  };
};

export const getAllContractInvoices = (creditorId) => {
  return async (dispatch) => {
    addToProcess(
      dispatch,
      constants,
      `all_active_contractinvoices_${creditorId}`
    );

    const invoices = await services.getAllContractInvoices(creditorId);

    const sorted = invoices?.sort((a, b) => {
      const aIsBefore = moment(a.Created).isBefore(moment(b.Created));

      return aIsBefore ? 1 : -1;
    });

    dispatch({
      type: constants.SET_CONTRACT_INVOICES,
      payload: {
        invoices: sorted,
      },
    });
    removeFromProgress(
      dispatch,
      constants,
      `all_active_contractinvoices_${creditorId}`
    );
  };
};

export const setInvoicesClosedFromDate = ({ date, creditorId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CLOSED_FROM_DATE,
      payload: {
        date,
      },
    });

    dispatch(getAllInvoices(creditorId));
  };
};

export const setShowConnectedPaymentFromDate = ({ date, creditorId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CONNECTED_PAYMENT_DATE_FROM,
      payload: {
        date,
      },
    });

    dispatch(getAllPayments(creditorId));
  };
};

export const getAllPayments = (creditorId) => {
  return async (dispatch) => {
    const state = store.getState();

    const calls = [
      services.getAllUnconnectedPayments(creditorId),
      services.getAllConnectedPayments({
        creditorId,
        connectedFromDate: state[constants.STORE_NAME].connectedPaymentFromDate,
        connectedToDate: state[constants.STORE_NAME].connectedPaymentToDate,
      }),
    ];

    const [unconnectedPayments, connectedPayments] = await Promise.all(calls);

    dispatch({
      type: constants.SET_PAYMENTS,
      payload: {
        payments: [...unconnectedPayments, ...connectedPayments],
      },
    });
  };
};

export const removeManyInvoice = ({
  removedInvoiceInternalId,
  successCallback,
}) => {
  return async (dispatch) => {
    const state = store.getState();
    const storeName = constants.STORE_NAME;

    const invoices = cloneDeep(state[storeName].formInstance.invoices);

    const updatedInvoices = invoices.filter((i) => {
      return i._internalId.toString() !== removedInvoiceInternalId.toString();
    });

    dispatch({
      type: constants.REMOVE_MANY_INVOICE,
      payload: { updatedInvoices },
    });

    successCallback && successCallback();
  };
};

export const getManyInvoiceProposal = ({
  contract,
  tenant,
  privateTenant,
  creditorId,
  companyId,
  products,
  type,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const formInstance = state[constants.STORE_NAME].formInstance;
      const tenantHasEInvoiceActivated = !!tenant?.e_invoice_bank;
      const nextId = formInstance?.invoices?.length || 0;
      let url;

      switch (type) {
        case CONTRACT_TYPES.LEASE:
          url = ``;
          break;

        case CONTRACT_TYPES.OTHER:
          url = ``;
          break;

        case CONTRACT_TYPES.PARKING:
          url = ``;
          break;
        default:
          throw Error("Type not found");
      }

      const name = "invoice";
      const inProgress = store
        .getState()
        [constants.STORE_NAME].inProgress.includes(name);
      if (inProgress) {
        return undefined;
      }
      addToProcess(dispatch, constants, name);

      let DebtorPublicId;

      // data needed for creation, _ is internal for correct post url
      const preFillData = {
        CreditorPublicId: creditorId,
        DebtorPublicId,
        _type: type,
        _contractId: contract.id,
        _contract: contract,
        _tenant: tenant,
        _internalId: nextId,
        _periodStart: formInstance?._periodStart,
        _periodEnd: formInstance?._periodEnd,
      };

      const result = await retrieve({
        url: `${checkTrailingUrlSlash(url)}${
          companyId ? `?company_id=${companyId}` : ""
        }`,
      });

      // clone current instance
      const currentInstance = cloneDeep(formInstance);

      // get settings
      const settings = cloneDeep(currentInstance);
      settings.InvoiceFee = {
        Value: settings.InvoiceFee?.Value || 0,
        Currency: "SEK",
      };

      // if tenant has e invoice, no invoicefee is allowed and e invoice is required
      if (tenantHasEInvoiceActivated) {
        settings.InvoiceFee.Value = 0;
        settings.DeliveryMethod = "EInvoice";
      }

      // remove invoices from settings
      delete settings.invoices;

      // build new invoice and handle products
      const newInvoice = handlePeriodCosts(
        setProducts({
          invoiceInstance: { ...result.data, ...settings, ...preFillData },
          products,
          setVatToZero: !!privateTenant,
        })
      );

      // add to instance
      if (currentInstance.invoices) {
        currentInstance.invoices.push(newInvoice);
      } else {
        currentInstance.invoices = [newInvoice];
      }

      // update instance
      dispatch({
        type: getFormInstanceStateType(constants.STORE_NAME),
        payload: { result: currentInstance, clean: true },
      });

      dispatch({
        type: constants.REMOVE_TO_IN_PROGRESS,
        payload: { name: name },
      });

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const getTripleInvoiceProposal = ({
  contract,
  tenant,
  privateTenant,
  creditorId,
  companyId,
  products,
  type,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const formInstance = state[constants.STORE_NAME].formInstance;
      const tenantHasEInvoiceActivated = !!tenant?.e_invoice_bank;

      let url;

      switch (type) {
        case CONTRACT_TYPES.LEASE:
          url = ``;
          break;

        case CONTRACT_TYPES.OTHER:
          url = ``;
          break;

        case CONTRACT_TYPES.PARKING:
          url = ``;
          break;
        default:
          throw Error("Type not found");
      }

      const name = "invoice";
      const inProgress = store
        .getState()
        [constants.STORE_NAME].inProgress.includes(name);
      if (inProgress) {
        return undefined;
      }
      addToProcess(dispatch, constants, name);

      let DebtorPublicId;
      // const tenantBillectaId = tenant?.billecta_ids?.find(
      //   (bid) => bid.creditor_id === creditorId
      // );

      // if (tenantBillectaId) {
      //   DebtorPublicId = tenantBillectaId.object_id;
      // } else {
      //   DebtorPublicId = await createBillectaDebtor({
      //     tenant,
      //     dispatch,
      //     creditorId,
      //   });
      // }

      // get proposal
      const result = await retrieve({
        url: `${checkTrailingUrlSlash(url)}${
          companyId ? `?company_id=${companyId}` : ""
        }`,
      });

      // clone current instance
      const currentInstance = cloneDeep(formInstance);

      // get settings
      const settings = cloneDeep(currentInstance);
      settings.InvoiceFee = {
        Value: settings.InvoiceFee?.Value || 0,
        Currency: "SEK",
      };

      // if tenant has e invoice, no invoicefee is allowed and e invoice is required
      if (tenantHasEInvoiceActivated) {
        settings.InvoiceFee.Value = 0;
        settings.DeliveryMethod = "EInvoice";
      }

      // remove invoices from settings
      delete settings.invoices;

      // add three invoices
      for (let i = 0; i < 3; i++) {
        let periodStart = currentInstance?.[`_period${i + 1}Start`];
        let periodEnd = currentInstance?.[`_period${i + 1}End`];

        // same invoice date for all invoices
        let firstPeriodStart = currentInstance?.[`_period1Start`];
        let firstPeriodEnd = currentInstance?.[`_period1End`];
        let { InvoiceDate } = billingDatesProposalFromPeriod({
          periodStart: firstPeriodStart,
          periodEnd: firstPeriodEnd,
        });

        // data needed for creation, _ is internal for correct post url
        let preFillData = {
          CreditorPublicId: creditorId,
          DebtorPublicId,
          _type: type,
          _contractId: contract.id,
          _contract: contract,
          _tenant: tenant,
          _internalId: i,
          _periodStart: periodStart,
          _periodEnd: periodEnd,
          // get due date
          ...billingDatesProposalFromPeriod({ periodStart, periodEnd }),
          InvoiceDate,
        };

        // build new invoice and handle products
        const newInvoice = handlePeriodCosts(
          setProducts({
            invoiceInstance: { ...result.data, ...settings, ...preFillData },
            products,
            setVatToZero: !!privateTenant,
            setPeriod: true,
            periodStart,
            periodEnd,
          })
        );

        // add to instance
        if (currentInstance.invoices) {
          currentInstance.invoices.push(newInvoice);
        } else {
          currentInstance.invoices = [newInvoice];
        }
      }

      // update instance
      dispatch({
        type: getFormInstanceStateType(constants.STORE_NAME),
        payload: { result: currentInstance, clean: true },
      });

      dispatch({
        type: constants.REMOVE_TO_IN_PROGRESS,
        payload: { name: name },
      });

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const getManualInvoiceProposal = ({
  creditorId,
  errorCallback,
  selectedDebtorConfig,
  companyInvoiceConfig,
}) => {
  return async (dispatch) => {
    try {
      const name = "manual_invoice";
      const inProgress = store
        .getState()
        [constants.STORE_NAME].inProgress.includes(name);

      if (inProgress) {
        return undefined;
      }

      addToProcess(dispatch, constants, name);

      const InvoiceDate = moment().format("YYYY-MM-DD");
      const DueDate = moment().add({ months: 1 }).format("YYYY-MM-DD");

      dispatch({
        type: getFormInstanceStateType(constants.STORE_NAME),
        payload: {
          result: {
            InvoiceDate,
            DueDate,
            Message: selectedDebtorConfig?.default_invoice_message,
            DeliveryMethod: companyInvoiceConfig?.delivery_method,
            OurReference: companyInvoiceConfig?.our_reference,
            YourReference: selectedDebtorConfig?.your_reference,
            DebtorPublicId: selectedDebtorConfig?.id, // converted to DebtorPublicId in creation
            CreditorPublicId: creditorId,
            InvoiceFee: {
              CurrencyCode: "SEK",
              Value: companyInvoiceConfig?.admin_fee || 0,
            },
            ReminderInvoiceDetails: {
              DaysDelayAfterDueDate: 3,
            },
          },
        },
      });
    } catch (e) {
      const message = e?.response?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const getHandleInvoice = ({
  creditorId,
  invoiceActionId,
  notFoundCallback,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, invoiceActionId);

      const invoice = await services.getSingleInvoice(invoiceActionId);

      // convert billecta ids to pigello
      const convertedInvoice = await services.convertBillectaInvoiceToPigello({
        invoice,
        creditorId,
      });

      // special case for manual reminder invoices
      if (
        convertedInvoice.ReminderInvoiceActionPublicId &&
        convertedInvoice?.State?.Stage === "Manual"
      ) {
        convertedInvoice.State.Stage = "ReminderInvoiceSent";
      }

      // set current invoice for overview
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: convertedInvoice,
        },
      });

      dispatch(setInvoiceFormInstance({ invoice: convertedInvoice }));

      removeFromProgress(dispatch, constants, invoiceActionId);
    } catch (e) {
      const notFound =
        e?.response?.data?.Message === "Fakturan kunde inte hittas"; // bad error message, string comparison may be better

      notFoundCallback && notFound && notFoundCallback();
    }
  };
};

export const getReminderInvoice = ({ reminderInvoiceId, invoiceId }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, `reminder_${reminderInvoiceId}`);

    const reminderInvoice = await services.getReminderInvoice(invoiceId);

    // set current invoice for overview
    dispatch({
      type: constants.SET_CURRENT_REMINDER_INVOICE,
      payload: {
        reminderInvoice: reminderInvoice,
      },
    });

    removeFromProgress(dispatch, constants, `reminder_${reminderInvoiceId}`);
  };
};

export const downloadBillectaFile = ({ filePublicId, fileName }) => {
  return async (dispatch) => {
    try {
      const preview = await services.downloadBillectaFile(filePublicId);

      // trigger pdf download
      const a = document.createElement("a");
      a.href = "data:application/pdf;base64, " + preview;
      a.download = `${fileName || filePublicId}.pdf`;
      a.click();

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Filen hämtades",
        })
      );
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta fil",
        })
      );
    }
  };
};

export const setInvoiceFormInstance = ({ invoice }) => {
  return async (dispatch) => {
    // set form instance to cleaned, PUT-friendly version of invocie
    const cleanedInvoice = cleanPutInvoice(invoice);
    dispatch({
      type: getFormInstanceStateType(constants.STORE_NAME),
      payload: { result: cleanedInvoice, clean: true },
    });
  };
};

export const getHandleContractInvoice = ({ contractInvoiceActionId }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, contractInvoiceActionId);

    const invoice = await services.getContractInvoice(contractInvoiceActionId);

    // set current invoice for overview
    dispatch({
      type: constants.SET_CURRENT_CONTRACT_INVOICE,
      payload: {
        invoice,
      },
    });

    removeFromProgress(dispatch, constants, contractInvoiceActionId);
  };
};

export const createMultipleInvoices = ({
  invoices,
  creditorId,
  allDoneCallback,
}) => {
  return async (dispatch) => {
    const requests = [];

    const preProcess = (data) => {
      const formattedPostObj = cloneDeep(data);

      delete formattedPostObj._tenant;
      delete formattedPostObj._contractId;
      delete formattedPostObj._type;
      delete formattedPostObj._contract;
      delete formattedPostObj._internalId;
      delete formattedPostObj._periodEnd;
      delete formattedPostObj._periodStart;

      if (formattedPostObj?.Records?.length) {
        formattedPostObj.Records.forEach((r, i) => {
          // set correct order
          r.SequenceNo = i;

          // convert value to oren
          if (r.RecordType !== "Message") {
            r.UnitPrice.Value = r.UnitPrice.Value * 100;
          }
        });
      }

      // convert value to oren
      if (formattedPostObj?.InvoiceFee?.Value) {
        formattedPostObj.InvoiceFee.Value =
          formattedPostObj.InvoiceFee.Value * 100;
      }

      return formattedPostObj;
    };

    for (let i = 0; i < invoices.length; i++) {
      const current = invoices[i];

      const type = current._type;
      const id = current._contractId;

      let url;

      switch (type) {
        case CONTRACT_TYPES.LEASE:
          url = `/accounting/contract/${creditorId}/${id}/invoice/`;
          break;

        case CONTRACT_TYPES.OTHER:
          url = `/accounting/other_contract/${creditorId}/${id}/invoice/`;
          break;

        case CONTRACT_TYPES.PARKING:
          url = `/accounting/parking_contract/${creditorId}/${id}/invoice/`;
          break;

        default:
          break;
      }

      const postData = preProcess(current);

      requests.push(axiosInstance.post(url, postData));
    }

    const responses = await Promise.all(requests.map((r) => r.catch((e) => e)));
    const validResponses = responses.filter((resp) => !(resp instanceof Error));
    const errors = responses.filter((resp) => resp instanceof Error);

    dispatch(
      handleRemoveSuccessfulInvoices({ responses, errors, key: "invoices" })
    );

    allDoneCallback && allDoneCallback(errors);
  };
};

export const handleRemoveSuccessfulInvoices = ({ responses, errors, key }) => {
  return async (dispatch) => {
    const state = store.getState();

    const formInstance = state[constants.STORE_NAME].formInstance;
    const invoicesClone = cloneDeep(formInstance[key || "invoices"]);

    let restInvoices = [];

    errors.forEach((e) => {
      const idx = responses.findIndex((r) => r === e);

      const errorInvoice = invoicesClone[idx];

      restInvoices.push(errorInvoice);
    });

    dispatch({
      type: constants.REMOVE_MANY_INVOICE,
      payload: {
        updatedInvoices: restInvoices,
      },
    });
  };
};

const asyncManualInvoicePreProcess = async ({
  formattedPostObj,
  company,
  isUpdate,
}) => {
  const convertedPostObj = await services.convertPigelloInvoiceToBillecta({
    invoice: formattedPostObj,
    companyId: company?.id,
    isUpdate,
  });

  if (convertedPostObj) {
    return convertedPostObj;
  }

  throw Error("Något gick fel");
};

export const createManualInvoice = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  company,
}) => {
  const url = `/accounting/gateways/invoice/`;

  const preProcess = (data) => {
    const formattedPostObj = cloneDeep(data);

    if (formattedPostObj?.Records?.length) {
      formattedPostObj.Records.forEach((r, i) => {
        r.SequenceNo = i;

        if (r.RecordType !== "Message") {
          r.UnitPrice.Value = r.UnitPrice.Value * 100;
        }
      });
    }

    if (formattedPostObj?.InvoiceFee?.Value) {
      formattedPostObj.InvoiceFee.Value =
        formattedPostObj.InvoiceFee.Value * 100;
    }

    if (!formattedPostObj?.DebtCollectionDetails?.SendToDebtCollection) {
      formattedPostObj.DebtCollectionDetails = undefined;
    }

    if (!formattedPostObj.InterestStartInDaysAfterDueDate) {
      formattedPostObj.InterestPercentage = undefined;
      formattedPostObj.InterestStartInDaysAfterDueDate = undefined;
    }

    if (formattedPostObj?.Attachments?.length) {
      formattedPostObj.Attachments = formattedPostObj.Attachments.map((a) => ({
        File: {
          content_type: a._tempData.data.split(",")[0],
          data: a._tempData.data.split(",")[1],
          file_name: a._tempData.file_name,
        },
      }));
    }

    return formattedPostObj;
  };

  return post({
    url: url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess,
    asyncPreProcess: async (data) =>
      asyncManualInvoicePreProcess({ formattedPostObj: data, company }),
  });
};

export const updateInvoice = ({
  successCallback,
  errorCallback,
  id,
  company,
}) => {
  const url = `/accounting/gateways/invoice/${id}/`;

  const preProcess = (data) => {
    const formattedPostObj = cloneDeep(data);

    if (formattedPostObj?.Records?.length) {
      formattedPostObj.Records.forEach((r, i) => {
        r.SequenceNo = i;

        if (r.RecordType !== "Message") {
          r.UnitPrice.Value = r.UnitPrice.Value * 100;
        }
      });
    }

    if (formattedPostObj?.InvoiceFee?.Value) {
      formattedPostObj.InvoiceFee.Value =
        formattedPostObj.InvoiceFee.Value * 100;
    }

    if (formattedPostObj?.Attachments?.length) {
      formattedPostObj.Attachments = formattedPostObj.Attachments.map((a) => {
        if (a?.File?.FilePublicId) return a;

        return {
          File: {
            content_type: a._tempData.data.split(",")[0],
            data: a._tempData.data.split(",")[1],
            file_name: a._tempData.file_name,
          },
        };
      });
    }

    return formattedPostObj;
  };

  return put({
    url,
    constants,
    successCallback,
    errorCallback,
    preProcess,
    asyncPreProcess: async (data) =>
      asyncManualInvoicePreProcess({
        formattedPostObj: data,
        company,
        isUpdate: true,
      }),
  });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const getContractInvoiceIdPreview = ({
  contractInvoiceId,
  successCallback,
}) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, `preview_${contractInvoiceId}`);
    const preview = await services.generateContractInvoiceIdPreview(
      contractInvoiceId
    );

    dispatch({
      type: constants.SET_CURRENT_INVOICE_PREVIEW_PDF,
      payload: {
        id: contractInvoiceId,
        pdf: `data:application/pdf;base64, ${preview}`,
      },
    });

    successCallback(preview);

    removeFromProgress(dispatch, constants, `preview_${contractInvoiceId}`);
  };
};

export const getInvoicePreview = ({ postObj, company }) => {
  return async (dispatch) => {
    const formattedPostObj = cloneDeep(postObj);
    if (formattedPostObj?.Records?.length) {
      formattedPostObj.Records.forEach((r, i) => {
        r.SequenceNo = i;

        if (r.RecordType !== "Message") {
          r.UnitPrice.Value = r.UnitPrice.Value * 100;
        }
      });
    }

    if (formattedPostObj?.InvoiceFee?.Value) {
      formattedPostObj.InvoiceFee.Value =
        formattedPostObj.InvoiceFee.Value * 100;
    }

    // convert pigello debtor/product/costcenter/project to billecta
    const convertedPostObj = await services.convertPigelloInvoiceToBillecta({
      invoice: formattedPostObj,
      companyId: company?.id,
    });

    const preview = await services.generateInvoicePreview({
      postObj: convertedPostObj,
    });

    // trigger pdf download
    const a = document.createElement("a");
    a.href = "data:application/pdf;base64, " + preview;
    a.download = "preview.pdf";
    a.click();
  };
};

export const getInvoiceIdPreview = ({ invoiceId, download = false }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, `preview_${invoiceId}`);

    const preview = await services.generateInvoiceIdPreview(invoiceId);

    if (download) {
      const a = document.createElement("a");
      a.href = "data:application/pdf;base64, " + preview;
      a.download = "preview.pdf";
      a.click();
    }

    dispatch({
      type: constants.SET_CURRENT_INVOICE_PREVIEW_PDF,
      payload: {
        id: invoiceId,
        pdf: `data:application/pdf;base64, ${preview}`,
      },
    });

    removeFromProgress(dispatch, constants, `preview_${invoiceId}`);
  };
};

export const mergeInvoices = ({
  invoices,
  invoiceDate,
  dueDate,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const merges = {};
      let calls = [];
      let couldNotMergeCount = 0;
      let couldMergeCount = 0;

      invoices.forEach((invoice) => {
        const debtorId = invoice.DebtorPublicId;

        if (merges[debtorId]) {
          merges[debtorId].push(invoice.ActionPublicId);
        } else {
          merges[debtorId] = [invoice.ActionPublicId];
        }
      });

      Object.keys(merges || {}).forEach((debtorId) => {
        const current = merges[debtorId];

        if (current.length === 1) {
          couldNotMergeCount += 1;
          delete merges[debtorId];
        } else {
          couldMergeCount += current.length;
          calls.push(
            services.mergeInvoices({
              invoiceIds: current,
              invoiceDate,
              dueDate,
            })
          );
        }
      });

      const results = await Promise.all(calls.map((p) => p.catch((e) => e)));
      const validResults = results.filter(
        (result) => !(result instanceof Error)
      );
      const errorResults = results.filter((result) => result instanceof Error);

      successCallback &&
        successCallback({
          couldNotMergeCount,
          couldMergeCount,
          errorResults,
          validResults,
        });
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

/**
 *
 * idArray is an array of invoiceActionIds to be attested
 * creditorId necessary to fetch all invoices after update
 * fetchSingleInvoiceId is passed if updated from handle invoice component, to update data. Should also clear current invoice if passed
 */
export const attestInvoices = ({
  idArray,
  creditorId,
  fetchSingleInvoiceId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.SET_ATTEST_INVOICES_LOADING,
        payload: {
          loading: true,
        },
      });

      if (fetchSingleInvoiceId) {
        dispatch({
          type: constants.SET_CURRENT_INVOICE,
          payload: {
            invoice: null,
          },
        });
      }

      for (let i = 0; i < idArray.length; i++) {
        const current = idArray[i];

        try {
          await services.attestInvoice(current);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Fakturan attesterades",
            })
          );
        } catch (e) {
          const err = e.response?.data?.Message;
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "En av fakturorna kunde inte attesteras",
              description: err,
            })
          );
        }
      }

      dispatch(getAllInvoices(creditorId));

      if (fetchSingleInvoiceId) {
        dispatch(
          getHandleInvoice({
            invoiceActionId: fetchSingleInvoiceId,
            creditorId,
          })
        );

        successCallback && successCallback();
      }

      dispatch({
        type: constants.SET_ATTEST_INVOICES_LOADING,
        payload: {
          loading: false,
        },
      });
    } catch (e) {
      errorCallback && errorCallback(e.response?.data?.Message);
      dispatch(getAllInvoices(creditorId));

      dispatch({
        type: constants.SET_ATTEST_INVOICES_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };
};

export const deleteInvoice = ({
  invoiceId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const deleteCall = async () => await services.deleteInvoice(invoiceId);

      dispatch({
        type: constants.SET_DELETE_INVOICES_LOADING,
        payload: {
          loading: true,
        },
      });

      const resp = await deleteCall();

      // clear current invoice
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      // reload all invoices
      dispatch(getAllInvoices(creditorId));

      dispatch({
        type: constants.SET_DELETE_INVOICES_LOADING,
        payload: {
          loading: false,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      dispatch({
        type: constants.SET_DELETE_INVOICES_LOADING,
        payload: {
          loading: false,
        },
      });
      console.log(e);
      errorCallback && errorCallback();
    }
  };
};

export const sendInvoice = ({
  invoiceActionId,
  creditorId,
  method,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const response = await services.sendInvoice(invoiceActionId, method);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const disputeInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const response = await services.disputeInvoice(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const undisputeInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const response = await services.undisputeInvoice(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const cancelRemindersForInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const response = await services.cancelReminders(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const pauseInvoice = ({ id, creditorId, successCallback }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_INVOICE,
      payload: {
        invoice: null,
      },
    });

    const responses = await services.pauseInvoice(id);

    // get all invoices again after update
    dispatch(getHandleInvoice({ invoiceActionId: id, creditorId }));
    dispatch(getAllInvoices(creditorId));

    successCallback && successCallback();
  };
};

export const resumeInvoice = ({ id, creditorId, successCallback }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_INVOICE,
      payload: {
        invoice: null,
      },
    });

    const responses = await services.resumeInvoice(id);

    // get all invoices again after update
    dispatch(getHandleInvoice({ invoiceActionId: id, creditorId }));
    dispatch(getAllInvoices(creditorId));

    successCallback && successCallback();
  };
};

export const pauseContractInvoice = ({ id, creditorId, successCallback }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_CONTRACT_INVOICE,
      payload: {
        invoice: null,
      },
    });

    await services.pauseContractInvoice(id);

    dispatch(
      getHandleContractInvoice({ contractInvoiceActionId: id, creditorId })
    );

    successCallback && successCallback();
  };
};

export const resumeContractInvoice = ({ id, creditorId, successCallback }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_CONTRACT_INVOICE,
      payload: {
        invoice: null,
      },
    });

    await services.resumeContractInvoice(id);

    // get all invoices again after update
    dispatch(
      getHandleContractInvoice({ contractInvoiceActionId: id, creditorId })
    );

    successCallback && successCallback();
  };
};

export const generateNextContractInvoice = ({
  id,
  creditorId,
  successCallback,
}) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_CONTRACT_INVOICE,
      payload: {
        invoice: null,
      },
    });

    await services.generateNextContractInvoice(id);

    dispatch(
      getHandleContractInvoice({ contractInvoiceActionId: id, creditorId })
    );

    // clear related invoices for lease to show new
    dispatch({
      type: constants.CLEAR_CONNECTED_INVOICES,
      payload: {
        key: `${id}_related`,
      },
    });

    successCallback && successCallback();
  };
};

export const moveNextContractInvoiceBySteps = ({
  id,
  steps,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.SET_CURRENT_CONTRACT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      await services.moveNextContractInvoiceBySteps(id, steps);

      dispatch(
        getHandleContractInvoice({ contractInvoiceActionId: id, creditorId })
      );

      dispatch({
        type: constants.CLEAR_CONNECTED_INVOICES,
        payload: {
          key: `${id}_related`,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const initiateCreditInvoice = ({ successCallback, errorCallback }) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      // step 1 - create a credit invoice with the inverted total sum of the debit invoice
      const creditInvoice = invoiceToCreditInvoice(
        cloneDeep(state.billectaInvoicing.formInstance)
      );

      dispatch(
        setActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: creditInvoice,
        })
      );

      successCallback();
    } catch (e) {
      errorCallback(e);
    }
  };
};

export const matchCreditToDebit = ({
  creditorId,
  debitInvoiceActionId,
  creditInvoiceActionId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.creditInvoice({
        debitInvoiceActionId,
        creditInvoiceActionId,
      });

      dispatch(getAllInvoices(creditorId));

      successCallback && successCallback();
    } catch (e) {
      const message = e.response?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const creditInvoice = ({
  debitInvoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
  company,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      // step 1 - get the credit invoice from store
      const creditInvoice = cloneDeep(state.billectaInvoicing.formInstance);

      // step 2 - fix values for billecta
      if (creditInvoice?.Records?.length) {
        creditInvoice.Records.forEach((r) => {
          if (r.RecordType !== "Message") {
            r.UnitPrice.Value = r.UnitPrice.Value * 100;
          }
        });
      }
      if (creditInvoice?.InvoiceFee?.Value) {
        creditInvoice.InvoiceFee.Value = creditInvoice.InvoiceFee.Value * 100;
      }

      // step 3 - convert to billecta structure
      const convertedCreditInvoice =
        await services.convertPigelloInvoiceToBillecta({
          invoice: creditInvoice,
          companyId: company?.id,
          isUpdate: true, // true to not touch debtor
        });

      const { PublicId: creditInvoiceActionId } =
        await services.createCreditInvoice(convertedCreditInvoice);

      // step 4 - attest credit invoice
      await services.attestInvoice(creditInvoiceActionId);

      // // step 5 - credit invoice
      await services.creditInvoice({
        debitInvoiceActionId,
        creditInvoiceActionId,
      });

      // step 3 - update data
      dispatch(
        getHandleInvoice({ invoiceActionId: debitInvoiceActionId, creditorId })
      );
      dispatch(getAllInvoices(creditorId));
      successCallback && successCallback();
    } catch (e) {
      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const sendManualReminderInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
  reminderInvoiceDeliveryMethod,
  reminderFee,
  reminderPaymentTermsInDays,
}) => {
  return async (dispatch) => {
    try {
      // clear current invoice data
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      const body = {
        DeliveryMethod: reminderInvoiceDeliveryMethod,
        ReminderFee: { Value: (reminderFee || 0) * 100, CurrencyCode: "SEK" },
        PaymentTermsInDays: reminderPaymentTermsInDays
          ? parseInt(reminderPaymentTermsInDays)
          : null,
        InvoiceActionPublicId: invoiceActionId,
      };

      await services.sendManualReminderInvoice({ body });

      // update data
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      dispatch(getAllInvoices(creditorId));

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.data?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const registerInvoicePayment = ({
  invoiceActionId,
  creditorId,
  paymentData,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const postObj = {
        ActionPublicId: invoiceActionId,
        Amount: {
          CurrencyCode: paymentData.currency,
          // ören to sek
          Value: paymentData.value * 100,
        },
        // WriteOff: null,
        // Comment: "Paid 1 SEK",
        Date: paymentData.paidDate,
        // WriteOffVat: 0,
        PaymentMeanCode: paymentData.paymentMeansCode,
        OvershootingAmountHandling: paymentData.overpaymentHandle,
        PaymentReferenceText: paymentData.reference,
      };

      // cleat currenct invoice
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      await services.registerInvoicePayment({ postObj, invoiceActionId });

      successCallback && successCallback();

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      dispatch(getAllInvoices(creditorId));
    } catch (e) {
      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const matchPaymentsToInvoice = ({
  paymentIds,
  invoiceId,
  paymentDate,
  successCallback,
  errorCallback,
  alternativeErrorCallback,
  creditorId,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.matchPaymentsToInvoice({
        paymentIds,
        invoiceId,
        creditorId,
        paymentDate,
      });

      if (res.every((r) => r.Successfull)) {
        successCallback();
      } else {
        alternativeErrorCallback &&
          alternativeErrorCallback(
            "En eller flera betalningar kunde ej matchas. Detta kan bero på t.ex. att negativa belopp ej kan matchas mot fakturor."
          );
      }

      // get all invoices & payments again after update
      dispatch(getAllInvoices(creditorId));
      dispatch(getAllPayments(creditorId));
    } catch (e) {
      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const deletePayment = ({
  paymentId,
  bookKeepingAccount,
  transactionDate,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.deletePayment({
        creditorId,
        paymentId,
        bookKeepingAccount,
        transactionDate,
      });

      dispatch(getAllInvoices(creditorId));
      dispatch(getAllPayments(creditorId));

      successCallback && successCallback();
    } catch (e) {
      dispatch(getAllInvoices(creditorId));
      dispatch(getAllPayments(creditorId));

      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const getSingularInvoicesByDebtorConfig = ({
  identifier,
  debtorInvoiceConfig,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, identifier);

      const calls = debtorInvoiceConfig?.billecta_ids?.map((bid) => {
        const searchParams = {
          DebtorPublicIds: [bid.object_id],
          CreditorPublicId: bid.creditor_id,
        };

        return searchServices.search({ searchParams });
      });

      const results = await Promise.all(calls);

      const invoices = results.map((r) => r.InvoiceActions).flat();

      //remove invoices connected to a contract invoice
      const filteredSingleInvoices = invoices?.filter(
        (i) => !i.ContractInvoiceActionPublicId
      );

      dispatch({
        type: constants.SET_DEBTOR_INVOICE_SEARCH_RESULT,
        payload: {
          identifier,
          invoices: filteredSingleInvoices,
        },
      });

      removeFromProgress(dispatch, constants, identifier);
      successCallback && successCallback();
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback();
    }
  };
};

export const activateAutogiroForInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const response = await services.activateAutogiroForInvoice(
        invoiceActionId
      );

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const inactivateAutogiroForInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const response = await services.inactivateAutogiroForInvoice(
        invoiceActionId
      );

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};
