import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import Customers from "./overview/Customers";

import usePermissionRedirect from "../../hooks/usePermissionRedirect";

export default function MainCustomerOverview() {
  const { path, url } = useRouteMatch();

  usePermissionRedirect(["view_can_management"]);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: Customers,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Förvaltningsuppdrag`}
          {...{ subPages: subPageLinks }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
