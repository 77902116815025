import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import moment from "moment";

import {
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import { useDispatch } from "react-redux";
import {
  attestInvoices,
  billectaStateMap,
  creditInvoice,
  deleteInvoice,
  getHandleInvoice,
  initiateCreditInvoice,
  pauseInvoice,
  resumeInvoice,
  sendManualReminderInvoice,
  updateInvoice,
  useInvoiceForm,
  constants,
  sendInvoice,
  disputeInvoice,
  undisputeInvoice,
  creditorInvoiceDetailUrl,
} from "../../store/billectaInvoicing";
import { useInvoice } from "../../store/billectaInvoicing/hooks/retrieve";
import { useFilteredCompanies } from "../../store/companies";
import { buildQueryString, setActiveFormInstance } from "../../store/base";
import InvoicePreview from "./invoiceDetail/InvoicePreview";
import InvoiceOverview from "./invoiceDetail/Overview";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import { toMoneyString } from "../../components/utils/stringUtils";
import InvoiceEvents from "./invoiceDetail/Events";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import { creditorDetailUrl } from "../../store/billectaInvoicing/utils";
import StandardModal from "../../components/Modals/StandardModal";
import HandleInvoiceForm from "../../components/Billecta/Invoices/HandleInvoice/HandleInvoiceForm";
import CreditInvoiceForm from "../../components/Billecta/Invoices/HandleInvoice/CreditInvoiceForm";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { cleanPutInvoice } from "../../store/billecta/utils";
import { clearIntegrationData } from "../../store/billectaIntegrations";
import { InfoBox } from "../../components/Displays";
import NonConnectedSelect from "../../components/Forms/Base/Old/NonConnected/NonConnectedSelect";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import NonConnectedNumberInput from "../../components/Forms/Base/Old/NonConnected/NonConnectedNumberInput";
import InvoiceNotFound from "../../components/Billecta/Invoices/NotFound";

function InvoiceDetail() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();

  const { creditorId, invoiceId } = useParams();
  useInvoiceForm("PUT");
  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [confirmationFunction, setConfirmationFunction] = React.useState(null);
  const [editInvoiceOpen, setEditInvoiceOpen] = React.useState(false);
  const [editModalLoading, setEditModalLoading] = React.useState(false);
  const [editHasValidationError, setEditHasValidationError] =
    React.useState(false);
  const [editHasRequiredMissing, setEditHasRequiredMissing] =
    React.useState(false);
  const [creditInvoiceOpen, setCreditInvoiceOpen] = React.useState(false);
  const [creditModalLoading, setCreditModalLoading] = React.useState(false);
  const [creditHasValidationError, setCreditHasValidationError] =
    React.useState(false);
  const [creditHasRequiredMissing, setCreditHasRequiredMissing] =
    React.useState(false);

  const [sendInvoiceOpen, setSendInvoiceOpen] = React.useState(false);
  const [sendInvoiceMethod, setSendInvoiceMethod] = React.useState(null);
  const [reminderInvoiceSettingsOpen, setReminderInvoiceSettingsOpen] =
    React.useState(false);

  const [reminderInvoiceDeliveryMethod, setReminderInvoiceDeliveryMethod] =
    React.useState(null);
  const [reminderFee, setReminderFee] = React.useState(0);
  const [reminderPaymentTermsInDays, setReminderPaymentTermsInDays] =
    React.useState(null);
  const [sendReminderLoading, setSendReminderInvoiceLoading] =
    React.useState(false);

  const [invoice, invoiceLoading, invoiceNotFound] = useInvoice({
    creditorId,
    invoiceId,
  });
  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  React.useEffect(() => {
    if (!sendInvoiceMethod) {
      setSendInvoiceMethod(invoice?.DeliveryMethod);
    }
  }, [invoice]);

  const hasAttested = !!invoice?.State?.AttestedDate;
  const canDelete = !hasAttested;
  const isPaused = invoice?.State.IsPaused;
  const canAttest = moment(invoice?.InvoiceDate).isSameOrBefore(moment());
  const isDisputed = !!invoice?.State?.DisputedDate;
  const isCreditInvoice = invoice?.ActionType === "CreditInvoiceAction";

  React.useEffect(() => {
    if (invoice) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: cleanPutInvoice(invoice),
        })
      );
    }
  }, [invoice]);

  React.useEffect(() => {
    return () => {
      dispatch(clearIntegrationData());
    };
  }, []);

  const successCallbackBase = ({ title, body }) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title,
        description: body,
      })
    );
  };
  const errorCallbackBase = ({ title, body }) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title,
        description: body,
      })
    );
  };

  const handleEditValidationError = React.useCallback((validationError) => {
    if (Object.keys(validationError).length) {
      setEditHasValidationError(true);
    } else {
      setEditHasValidationError(false);
    }
  }, []);

  const handleEditRequiredMissing = React.useCallback((requiredMissing) => {
    if (requiredMissing.length) {
      setEditHasRequiredMissing(true);
    } else {
      setEditHasRequiredMissing(false);
    }
  }, []);

  const handleCreditValidationError = React.useCallback((validationError) => {
    if (Object.keys(validationError).length) {
      setCreditHasValidationError(true);
    } else {
      setCreditHasValidationError(false);
    }
  }, []);

  const handleCreditRequiredMissing = React.useCallback((requiredMissing) => {
    if (requiredMissing.length) {
      setCreditHasRequiredMissing(true);
    } else {
      setCreditHasRequiredMissing(false);
    }
  }, []);

  const handleEditSaved = () => {
    setEditModalLoading(true);

    dispatch(
      updateInvoice({
        id: invoiceId,
        company,
        successCallback: () => {
          setEditModalLoading(false);
          setEditInvoiceOpen(false);
          dispatch(
            getHandleInvoice({ creditorId, invoiceActionId: invoiceId })
          );
        },
        errorCallback: (_, returnedData) =>
          errorCallbackBase({})({
            title: "Uppdateringen misslyckades",
            body: returnedData?.Message || "Kontrollera och försök igen",
          }),
      })
    );
  };

  const handleCreditSaved = () => {
    setCreditModalLoading(true);
    dispatch(
      creditInvoice({
        debitInvoiceActionId: invoiceId,
        creditorId,
        company,
        successCallback: () => {
          setCreditModalLoading(false);
          setCreditInvoiceOpen(false);
          successCallbackBase({ title: "Fakturan krediterades" });
          dispatch(
            getHandleInvoice({ creditorId, invoiceActionId: invoiceId })
          );
        },
        errorCallback: (message) => {
          setCreditModalLoading(false);
          errorCallbackBase({})({
            title: "Fakturan kunde inte krediteras",
            body: message ? message : "",
          });
        },
      })
    );
  };

  const handleConfirmAttestInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill attestera fakturan?");
    setConfirmationFunction(() => handleAttestInvoice);
  };
  const handleAttestInvoice = () => {
    setLoading(true);
    dispatch(
      attestInvoices({
        idArray: [invoiceId],
        creditorId,
        fetchSingleInvoiceId: invoiceId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan attesterades" });
          setLoading(false);
        },
        errorCallback: (message) => {
          errorCallbackBase({})({
            title: "Fakturan kunde inte attesteras",
            body: message || null,
          });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmDeleteInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill radera fakturan?");
    setConfirmationFunction(() => handleDeleteInvoice);
  };

  const handleDeleteInvoice = () => {
    setLoading(true);
    dispatch(
      deleteInvoice({
        invoiceId,
        creditorId,
        successCallback: () => {
          successCallbackBase({ title: "Raderingen lyckades" });
          push(creditorDetailUrl({ creditorId }));
        },
        errorCallback: (message) => {
          errorCallbackBase({})({
            title: "Något gick fel",
            body: message || "Fakturan kunde inte raderas",
          });
        },
      })
    );
  };

  const handleConfirmPauseInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill pausa fakturan?");
    setConfirmationFunction(() => handlePauseInvoice);
  };

  const handlePauseInvoice = () => {
    setLoading(true);
    dispatch(
      pauseInvoice({
        id: invoiceId,
        creditorId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan pausades" });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmResumeInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill aktivera fakturan?");
    setConfirmationFunction(() => handleResumeInvoice);
  };

  const handleResumeInvoice = () => {
    setLoading(true);
    dispatch(
      resumeInvoice({
        id: invoiceId,
        creditorId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan aktiverades" });
          setLoading(false);
        },
      })
    );
  };

  const handleSendManualReminderInvoice = () => {
    setLoading(true);

    setSendReminderInvoiceLoading(true);
    dispatch(
      sendManualReminderInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        reminderInvoiceDeliveryMethod,
        reminderFee,
        reminderPaymentTermsInDays,
        successCallback: () => {
          successCallbackBase({ title: "Påminnelse skickades" });
          setLoading(false);
          setReminderInvoiceSettingsOpen(false);
          setSendReminderInvoiceLoading(false);
          setReminderFee(0);
          setReminderPaymentTermsInDays(null);
          setReminderInvoiceDeliveryMethod(null);
        },
        errorCallback: (message) => {
          setSendReminderInvoiceLoading(false);
          errorCallbackBase({
            title: "Kunde ej skicka påminnelse",
            body: message || "Kontrollera inställningarna och försök igen",
          });
        },
      })
    );
  };

  const handleSendInvoice = () => {
    setLoading(true);

    dispatch(
      sendInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        method: sendInvoiceMethod,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan skickades" });
          setLoading(false);
          setSendInvoiceOpen(false);
        },
        errorCallback: (e) => {
          errorCallbackBase({
            title: "Fakturan skickades inte",
            body: e || "Något gick fel. Försök igen.",
          });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmDisputeInvoice = () => {
    setConfirmationTitle("Är du säker på att du vill markera som bestriden?");
    setConfirmationFunction(() => handleDisputeInvoice);
  };

  const handleDisputeInvoice = () => {
    setLoading(true);

    dispatch(
      disputeInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        successCallback: () => {
          successCallbackBase({ title: "Fakturan markerades som bestriden" });
          setLoading(false);
        },
        errorCallback: (e) => {
          errorCallbackBase({
            title: "Fakturan kunde ej bestridas",
            body: e || "Något gick fel. Försök igen.",
          });
          setLoading(false);
        },
      })
    );
  };

  const handleConfirmUndisputeInvoice = () => {
    setConfirmationTitle(
      "Är du säker på att du vill markera som EJ bestriden?"
    );
    setConfirmationFunction(() => handleUndisputeInvoice);
  };

  const handleUndisputeInvoice = () => {
    setLoading(true);

    dispatch(
      undisputeInvoice({
        creditorId,
        invoiceActionId: invoiceId,
        successCallback: () => {
          successCallbackBase({
            title: "Fakturan markerades som EJ bestriden",
          });
          setLoading(false);
        },
        errorCallback: (e) => {
          errorCallbackBase({
            title: "Bestridningen kunde ej tas bort",
            body: e || "Något gick fel. Försök igen.",
          });
          setLoading(false);
        },
      })
    );
  };

  const handleInitiateCreditInvoice = () => {
    setLoading(true);

    dispatch(
      initiateCreditInvoice({
        debitInvoiceActionId: invoiceId,
        creditorId,
        successCallback: () => {
          setLoading(false);
          setCreditInvoiceOpen(true);
        },
        errorCallback: (message) => {
          setLoading(false);
          errorCallbackBase({
            title: "Fakturan kan inte krediteras",
            body: message ? message : "",
          });
        },
      })
    );
  };

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: InvoiceOverview,
    },
    {
      path: "/preview",
      label: "Granska faktura",
      component: InvoicePreview,
    },
    {
      path: "/events",
      label: `Händelser ${
        invoice?.Events?.length ? `(${invoice?.Events?.length})` : ""
      }`,
      component: InvoiceEvents,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const renderActions = () => {
    if (invoice && !hasAttested && canAttest) {
      return (
        <PrimaryButton
          pulse
          title="Attestera"
          clicked={handleConfirmAttestInvoice}
        />
      );
    }
    return <></>;
  };

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel
          state={
            isPaused
              ? "Pausad"
              : isCreditInvoice
              ? "Kreditfaktura"
              : billectaStateMap[invoice?.State?.Stage]
          }
        >
          {isPaused
            ? "Pausad"
            : isCreditInvoice
            ? "Kreditfaktura"
            : billectaStateMap[invoice?.State?.Stage]}
        </StatusLabel>

        {isPaused && isCreditInvoice && (
          <StatusLabel style={{ marginLeft: 12 }} state={"Kreditfaktura"}>
            Kreditfaktura
          </StatusLabel>
        )}

        {isDisputed && (
          <StatusLabel style={{ marginLeft: 12 }} state={6}>
            Bestriden
          </StatusLabel>
        )}

        {invoice && (
          <StatusLabel style={{ marginLeft: 12 }} state={1}>
            Fakturerat:{" "}
            {toMoneyString(invoice?.InvoicedAmount?.ValueForView, true)}
          </StatusLabel>
        )}

        {invoice && (
          <StatusLabel style={{ marginLeft: 12 }} state={4}>
            Kvarstående:{" "}
            {toMoneyString(invoice?.CurrentAmount?.ValueForView, true)}
          </StatusLabel>
        )}
      </>
    );
  };

  const handleActions = [];

  if (!hasAttested && canAttest) {
    handleActions.push({
      name: "Attestera",
      onClick: handleConfirmAttestInvoice,
    });
  }

  if (!hasAttested) {
    handleActions.push({
      name: "Redigera",
      onClick: () => setEditInvoiceOpen(true),
    });
  }

  if (
    invoice?.CanCreateCreditInvoice &&
    hasAttested &&
    !invoice?.State.ClosedDate
  ) {
    handleActions.push({
      name: "Skapa kreditfaktura",
      onClick: handleInitiateCreditInvoice,
    });
  }

  if (invoice?.CanPause && hasAttested) {
    handleActions.push({
      name: "Pausa",
      onClick: handleConfirmPauseInvoice,
    });
  }

  if (invoice?.CanResume) {
    handleActions.push({
      name: "Aktivera",
      onClick: handleConfirmResumeInvoice,
    });
  }

  if (invoice?.CanSendManualReminderInvoice) {
    handleActions.push({
      name: "Skicka påminnelse",
      onClick: () => setReminderInvoiceSettingsOpen(true),
    });
  }

  if (invoice?.CanResendManualInvoice || invoice?.CanSendManualInvoice) {
    handleActions.push({
      name: "Skicka faktura",
      onClick: () => setSendInvoiceOpen(true),
    });
  }

  if (invoice?.CanDispute) {
    handleActions.push({
      name: "Markera som bestriden",
      onClick: handleConfirmDisputeInvoice,
    });
  }

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: handleConfirmDeleteInvoice,
    });
  }

  if (invoiceNotFound) {
    return (
      <InvoiceNotFound
        goBack={() => push(creditorInvoiceDetailUrl({ creditorId }))}
      />
    );
  }
  return (
    <>
      <StandardModal
        title="Skicka faktura"
        isOpen={sendInvoiceOpen}
        closeFunction={() => setSendInvoiceOpen(false)}
        withActionBar
        saveFunction={handleSendInvoice}
        actionBarAcceptTitle="Skicka faktura"
        small
        extraStyle={{ minHeight: 500, maxHeight: "40vh" }}
      >
        <NonConnectedSelect
          extraStyle={{ paddingBottom: 200 }}
          id="_send_invoice_method"
          value={sendInvoiceMethod}
          choices={[
            { v: "Email", d: "Email (0 kr/st)" },
            { v: "Kivra", d: "Kivra (4 kr/st)" },
            {
              v: "EInvoice",
              d: "E-faktura (2.50 kr/st exkl. bankkostnader)",
            },
            { v: "Mail", d: "Post (6.50 kr/st)" },
            { v: "SMS", d: "SMS (1.50 kr/st)" },
          ]}
          onUpdate={(val) => setSendInvoiceMethod(val)}
          label="Leveransmetod"
          getOptionLabel={(o) => o.d}
          getOptionValue={(o) => o.v}
        />
      </StandardModal>

      <StandardModal
        isOpen={reminderInvoiceSettingsOpen}
        closeFunction={() => setReminderInvoiceSettingsOpen(false)}
        title="Inställningar för påminnelse"
        withActionBar
        saveFunction={handleSendManualReminderInvoice}
        canAccept={!!reminderInvoiceDeliveryMethod}
        actionBarAcceptTitle="Skicka påminnelse"
      >
        {sendReminderLoading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Inställningar för påminnelse</OverviewTitle>
            <OverviewSubtitle>
              Ange inställningar och villkor för påminnelsen
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <NonConnectedSelect
          extraStyle={{ paddingBottom: 200 }}
          id="_send_reminder_method"
          value={reminderInvoiceDeliveryMethod}
          choices={[
            { v: 2, d: "Skicka ej" },
            { v: 0, d: "Email (0 kr/st)" },
            { v: 5, d: "Kivra (4 kr/st)" },
            {
              v: 4,
              d: "E-faktura (2.50 kr/st exkl. bankkostnader)",
            },
            { v: 1, d: "Post (6.50 kr/st)" },
            { v: 3, d: "SMS (1.50 kr/st)" },
          ]}
          onUpdate={(val) => setReminderInvoiceDeliveryMethod(val)}
          label="Leveransmetod"
          getOptionLabel={(o) => o.d}
          getOptionValue={(o) => o.v}
        />

        <NonConnectedNumberInput
          label="Påminnelseavgift (SEK)"
          id="_send_reminder_fee"
          value={reminderFee}
          onUpdate={(val) => setReminderFee(val)}
        />

        <NonConnectedNumberInput
          label="Betalvillkor i antal dagar"
          helpText="Lämna tom för att använda standardinställning"
          id="_send_reminder_terms"
          value={reminderPaymentTermsInDays}
          onUpdate={(val) => setReminderPaymentTermsInDays(val)}
        />
      </StandardModal>

      <ConfirmationModal
        isOpen={!!confirmationFunction}
        closeFunction={() => setConfirmationFunction(null)}
        title={confirmationTitle}
        acceptCallback={confirmationFunction}
      />

      <StandardModal
        title="Redigera faktura"
        isOpen={editInvoiceOpen}
        closeFunction={() => setEditInvoiceOpen(false)}
        saveFunction={handleEditSaved}
        large
        actionBarAcceptTitle={"Uppdatera faktura"}
        withActionBar={true}
        canAccept={!editHasRequiredMissing && !editHasValidationError}
      >
        {editModalLoading && <OverlaySpinner />}
        {editInvoiceOpen && (
          <HandleInvoiceForm
            onValidationError={handleEditValidationError}
            onRequiredMissing={handleEditRequiredMissing}
            invoice={invoice}
            allDisabled={!!invoice?.State?.AttestedDate}
          />
        )}
      </StandardModal>

      <StandardModal
        title="Skapa kreditfaktura"
        isOpen={creditInvoiceOpen}
        closeFunction={() => setCreditInvoiceOpen(false)}
        saveFunction={handleCreditSaved}
        large
        actionBarAcceptTitle={"Skapa kreditfaktura"}
        withActionBar={true}
        canAccept={!creditHasValidationError && !creditHasRequiredMissing}
      >
        {creditModalLoading && <OverlaySpinner />}
        {creditInvoiceOpen && (
          <CreditInvoiceForm
            onValidationError={handleCreditValidationError}
            onRequiredMissing={handleCreditRequiredMissing}
          />
        )}
      </StandardModal>

      <DetailLayoutWrapper>
        {loading && <FullPageSpinner />}
        <DetailPageHeaderMenu
          title={`Faktura ${
            invoice?.InvoiceNumber
              ? `nr ${invoice?.InvoiceNumber}`
              : invoiceLoading
              ? "(Laddar...)"
              : "(Ej attesterad)"
          }`}
          {...{
            subPages: subPageLinks,
            breadCrumbs: [
              {
                label: "Fakturering",
                url: "/rental-notifications",
              },
              {
                url: `/rental-notifications/company/${creditorId}/invoices`,
                label: company?.str_representation,
              },
              {
                label: `Faktura ${
                  invoice?.InvoiceNumber
                    ? `nr ${invoice?.InvoiceNumber}`
                    : invoiceLoading
                    ? "(Laddar...)"
                    : "(Ej attesterad)"
                }`,
              },
            ],
            renderActions,
            handleActions,
            renderInfoPills,
          }}
        />

        {isDisputed && (
          <>
            <div style={{ padding: "6px 24px 0 24px" }}>
              <InfoBox
                boxTheme="warning"
                title="Faktura bestriden"
                text={`Denna faktura markerades som bestriden ${moment(
                  invoice?.State.DisputedDate
                ).format("YYYY-MM-DD HH:mm")}.`}
                renderButton={{
                  title: "Klicka här för att ta bort bestridningen",
                  clicked: handleConfirmUndisputeInvoice,
                  iconType: "close",
                  iconPlacement: "right",
                }}
              />
            </div>
          </>
        )}

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default InvoiceDetail;
