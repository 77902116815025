import * as React from "react";
import * as SC from "./styles";

export default ({ clicked, title, extraStyle, withComma }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };
  return (
    <SC.TableLinkButton style={{ ...extraStyle }} onClick={handleClick}>
      {title}
      {withComma && ", "}
    </SC.TableLinkButton>
  );
};
