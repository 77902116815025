import * as React from "react";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import {
  useCompanyPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/companies";
import { useFrequentPermissions } from "../../../store/base";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  handleRowClicked,
  withPersistantSortBy,
  withPersistantGlobalFilter,
}) => {
  const storeName = constants.STORE_NAME;

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const filterInstructions = {
    name: { operator: "icontains" },
    orgnr: { operator: "icontains" },
  };

  const columns = React.useMemo(
    () => columnDefs(hasBillectaViewPermission),
    [persistantFilterMethod, persistantQueryString, hasBillectaViewPermission]
  );
  const fetchAllTreshold = 25;

  return (
    <>
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useCompanyPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        onRowClicked={handleRowClicked}
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Bolag"}
        detailUrlMethod={(original) => detailUrl({ id: original?.id })}
        exportSheetController={exportSheetController}
        {...{
          hideFilters,
          hideSearch,
          hideExport,
          withPersistantSortBy,
          withPersistantGlobalFilter,
        }}
      />
    </>
  );
};
