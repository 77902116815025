import * as React from "react";

import FullTable from "../Lists/UserGroups/FullTable";

export default ({
  persistantQueryString,
  filterMethod,
  overrideRowClick,
  checkRowHighlighted,
}) => {
  return (
    <>
      <FullTable
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={filterMethod}
        hideTitle
        hideFilters
        hideColumns
        hideExport
        {...{ overrideRowClick, checkRowHighlighted }}
      />
    </>
  );
};
