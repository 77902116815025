import * as React from "react";
import { useSelector } from "react-redux";
import { constants } from "../../../store/billectaReports";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { FieldBox } from "../Search/styles";
import GroupedAccountSalesTable from "./GroupedAccountSalesTable";
import GroupedProjectSalesReportTable from "./GroupedProjectSalesReportTable";

export default ({ fromDate, toDate, setFromDate, setToDate }) => {
  const productSalesReport = useSelector(
    (state) => state[constants.STORE_NAME].productSalesReport
  );

  return (
    <>
      <FieldBox>
        <OverviewTitle small style={{ marginBottom: "8px" }}>
          Datumintervall för rapport
        </OverviewTitle>
        <NonConnectedDatePicker
          value={fromDate}
          clearable={false}
          id="grouped-project-sales-report-from-date"
          label="Från"
          onChange={(val) => setFromDate(val)}
          noMargin
        />
        <NonConnectedDatePicker
          value={toDate}
          clearable={false}
          id="grouped-project-sales-report-to-date"
          label="Till"
          onChange={(val) => setToDate(val)}
          noMargin
        />
      </FieldBox>

      {productSalesReport && (
        <GroupedProjectSalesReportTable
          reportRows={productSalesReport}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
    </>
  );
};
