import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { addToProcess, buildQueryString } from "../../base";
import * as services from "./services";
import { constants } from "..";
import { cloneDeep } from "lodash";
import { search } from "../../billectaSearch/store/services";
import { getContractInvoice } from "../../billectaInvoicing/store/services";
import { removeFromProgress } from "../../base/store/actions";
import { downloadAccountingReport } from "../../billectaAccountingReports/store/actions";

export const getBillingReport = ({
  fromDate,
  toDate,
  selectedProjects,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      if (!fromDate || !toDate) throw Error();
      const query = buildQueryString({
        from: fromDate,
        to: toDate,
        projectNumbers: selectedProjects?.length ? selectedProjects : undefined,
      });

      const resp = await services.getBillingReport({ creditorId, query });

      const data = resp.data;

      dispatch({
        type: constants.SET_BILLING_REPORT,
        payload: {
          data,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const getDashboardBillingReport = ({
  fromDate,
  toDate,
  creditorIds,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, "billing_report_dashboard");

      if (!fromDate || !toDate) throw Error();
      const query = buildQueryString({
        from: fromDate,
        to: toDate,
      });

      const promises = creditorIds.map((c) =>
        services.getBillingReport({ creditorId: c, query })
      );

      const resp = await Promise.all(promises);

      const data = resp.map((r) => r.data).flat();

      data.sort((a, b) => {
        const aDate = moment(a.InvoiceDate);
        const bDate = moment(b.InvoiceDate);

        return aDate.isAfter(bDate) ? 1 : bDate.isAfter(aDate) ? -1 : 0;
      });

      dispatch({
        type: constants.SET_DASHBOARD_BILLING_REPORT,
        payload: {
          data,
          creditorIds,
        },
      });

      removeFromProgress(dispatch, constants, "billing_report_dashboard");
    } catch (e) {}
  };
};

export const getDashboardDueDateReport = ({
  fromDate,
  toDate,
  creditorIds,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, "duedate_report_dashboard");

      if (!fromDate || !toDate) throw Error();

      const promises = creditorIds.map((c) => {
        const searchParams = {
          CreditorPublicId: c,
          DueDateFrom: fromDate,
          DueDateTo: toDate,
        };
        return search({ searchParams });
      });

      const resp = await Promise.all(promises);

      const data = resp.map((r) => r.InvoiceActions).flat();

      dispatch({
        type: constants.SET_DASHBOARD_DUEDATE_REPORT,
        payload: {
          data,
          creditorIds,
        },
      });

      removeFromProgress(dispatch, constants, "duedate_report_dashboard");
    } catch (e) {}
  };
};

export const getProductSalesReport = ({
  fromDate,
  toDate,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      if (!fromDate || !toDate) throw Error();

      const query = buildQueryString({
        from: fromDate,
        to: toDate,
      });

      const resp = await services.getProductSalesReport({
        creditorId,
        query,
        isExport: true,
      });

      const data = resp.data;

      dispatch({
        type: constants.SET_PRODUCT_SALES,
        payload: {
          data,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const getPeriodicProductSalesReport = ({
  fromDate,
  toDate,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      if (!fromDate || !toDate) throw Error();

      const query = buildQueryString({
        from: fromDate,
        to: toDate,
      });

      const resp = await services.getProductSalesReport({
        creditorId,
        query,
        isExport: false,
      });

      const data = resp.data;

      dispatch({
        type: constants.SET_PERIODIC_PRODUCT_SALES,
        payload: {
          data,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

const exportPaymentReportExcel = (data) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const wb = {
    Sheets: {
      Fakturabetalningar: XLSX.utils.json_to_sheet(data.ValuesOnActions),
      "Ohanterade betalningar": XLSX.utils.json_to_sheet(
        data.UnhandledPayments
      ),
    },
    SheetNames: ["Fakturabetalningar", "Ohanterade betalningar"],
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, "inbetalningsrapport" + fileExtension);
};

/**
 * 
IncomingPaymentsReportExportTypeView:
BgMax = 0,
TotalIN = 1,

IncomingPaymentReportPaymentTypeView:
Payments = 0,
Creditations = 1,
Overpayments = 2,
UnmatchedPayments = 3,
WriteOffs = 4,
Centroundings = 5,
ReminderPayments =6
 */

export const getAdvancedPaymentsReport = ({
  Creditors,
  From,
  To,
  Format,
  DateSelectionType,
  ResultSelectionType,
  Types,
  PaymentMeanCodes,
  quickview,
  exportExcel,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const params = JSON.stringify({
        Creditors,
        From: moment(From).format("YYYY-MM-DD"),
        To: moment(To).format("YYYY-MM-DD"),
        Types, // categories
        PaymentMeanCodes,
        Format, // export type, if is excel or quickview, this dosn't matter but need to be set to 1
        ProjectNumbers: [],
        DateSelectionType,
        ResultSelectionType,
      });

      const resp = await services.getPaymentsReport({
        params,
        exportFile: !quickview && !exportExcel,
      });

      const data = resp.data;

      if (quickview) {
        dispatch({
          type: constants.SET_ADVANCED_INCOMING_PAYMENTS,
          payload: {
            data,
          },
        });
      } else if (exportExcel) {
        exportPaymentReportExcel(data);
      } else {
        dispatch(
          downloadAccountingReport({
            fileId: data.FilePublicId,
            fileName: data.FileName,
          })
        );
      }

      successCallback && successCallback(data);
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const getPaymentsReport = ({
  fromDate,
  toDate,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const params = JSON.stringify({
        Creditors: [creditorId],
        From: fromDate || "2020-01-01",
        To: toDate || "2021-05-01",
        Format: 1,
        Types: [0, 1, 2, 3, 4, 5, 6],
        PaymentMeanCodes: [],
        ProjectNumbers: [],
      });

      const resp = await services.getPaymentsReport({
        creditorId,
        params,
      });

      const data = resp.data;

      dispatch({
        type: constants.SET_INCOMING_PAYMENTS,
        payload: {
          data,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const getDashboardPaymentsReport = ({
  creditorIds,
  fromDate,
  toDate,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, "payment_report_dashboard");
      const params = JSON.stringify({
        Creditors: creditorIds,
        From: fromDate,
        To: toDate,
        Format: 1,
        Types: [0, 1, 2, 3, 4, 5, 6],
        PaymentMeanCodes: [],
        ProjectNumbers: [],
      });

      const resp = await services.getPaymentsReport({
        params,
      });

      const data = resp.data;

      dispatch({
        type: constants.SET_INCOMING_DASHBOARD_PAYMENTS,
        payload: {
          data,
          creditorIds,
        },
      });

      removeFromProgress(dispatch, constants, "payment_report_dashboard");
    } catch (e) {}
  };
};

export const getVATReport = ({
  fromDate,
  toDate,
  prevPeriodFromDate,
  prevPeriodToDate,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      if (!fromDate || !toDate) throw Error();

      const searchParamBase = {
        CreditorPublicId: creditorId,
      };

      const calls = [
        search({
          searchParams: {
            ...searchParamBase,
            PeriodDateFrom: prevPeriodFromDate,
            PeriodDateTo: prevPeriodToDate,
          },
        }),
        search({
          searchParams: {
            ...searchParamBase,
            PeriodDateFrom: fromDate,
            PeriodDateTo: toDate,
          },
        }),
      ];

      const [
        { InvoiceActions: prevPeriodRes },
        { InvoiceActions: searchedPeriodRes },
      ] = await Promise.all(calls);

      const invoicesWithOutstandingVATPrevPeriod = prevPeriodRes.filter(
        (i) => i.TotalVATAmount.ValueForView > 0 && i.AttestedDate != null
      );

      const attestedInvoicesSearchedPeriod = searchedPeriodRes.filter(
        (i) => i.AttestedDate != null
      );

      const totalVatAmountForPrevPeriod =
        invoicesWithOutstandingVATPrevPeriod.reduce((acc, cur) => {
          return acc + cur.TotalVATAmount.ValueForView;
        }, 0);

      const totalRemainingAmountForPrevPeriod =
        invoicesWithOutstandingVATPrevPeriod.reduce((acc, cur) => {
          return acc + cur.CurrentAmount.ValueForView;
        }, 0);

      const totalInvoicedAmountForPrevPeriod =
        invoicesWithOutstandingVATPrevPeriod.reduce((acc, cur) => {
          return acc + cur.InvoicedAmount.ValueForView;
        }, 0);

      const totalVatAmountForSearchedPeriod =
        attestedInvoicesSearchedPeriod.reduce((acc, cur) => {
          return acc + cur.TotalVATAmount.ValueForView;
        }, 0);

      const totalRemainingAmountForSearchedPeriod =
        attestedInvoicesSearchedPeriod.reduce((acc, cur) => {
          return acc + cur.CurrentAmount.ValueForView;
        }, 0);

      const totalInvoicedAmountForSearchedPeriod =
        attestedInvoicesSearchedPeriod.reduce((acc, cur) => {
          return acc + cur.InvoicedAmount.ValueForView;
        }, 0);

      dispatch({
        type: constants.SET_VAT_REPORT,
        payload: {
          searchedPeriodRes: attestedInvoicesSearchedPeriod,
          invoicesWithOutstandingVATPrevPeriod,
          fromDate,
          toDate,
          prevPeriodFromDate,
          prevPeriodToDate,
          totalVatAmountForPrevPeriod,
          totalRemainingAmountForPrevPeriod,
          totalInvoicedAmountForPrevPeriod,
          totalVatAmountForSearchedPeriod,
          totalRemainingAmountForSearchedPeriod,
          totalInvoicedAmountForSearchedPeriod,
        },
      });

      // /// OLD
      // const query = buildQueryString({
      //   from: fromDate,
      //   to: toDate,
      // });

      // const prevPeriodQuery = buildQueryString({
      //   from: prevPeriodFromDate,
      //   to: prevPeriodToDate,
      // });

      // const promises = [
      //   services.getBillingReport({ creditorId, query }),
      //   services.getBillingReport({ creditorId, query: prevPeriodQuery }),
      // ];

      // const [thisPeriodResp, prevPeriodResp] = await Promise.all(promises);

      // const data = thisPeriodResp.data;
      // const prevData = prevPeriodResp.data;

      // // Total VAT
      // const totalVAT = data.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmount.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total 25% VAT
      // const total25VAT = data.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmountThirdVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total 12% VAT
      // const total12VAT = data.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmountSecondVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total 6% VAT
      // const total6VAT = data.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmountFirstVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total VAT eligible
      // const totalVATEligible = data.reduce((acc, cur) => {
      //   const d =
      //     cur.InvoicedAmount.ValueForView -
      //     cur.InvoicedNetAmountZeroVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total non VAT eligible
      // const totalNonVATEligible = data.reduce((acc, cur) => {
      //   const d = cur.InvoicedNetAmountZeroVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total ex VAT
      // const totalVATEligibleNet = totalVATEligible - totalVAT;

      // // Total VAT
      // const prevTotalVAT = prevData.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmount.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total 25% VAT
      // const prevTotal25VAT = prevData.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmountThirdVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total 12% VAT
      // const prevTotal12VAT = prevData.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmountSecondVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total 6% VAT
      // const prevTotal6VAT = prevData.reduce((acc, cur) => {
      //   const d = cur.InvoicedTaxAmountFirstVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total VAT eligible
      // const prevTotalVATEligible = prevData.reduce((acc, cur) => {
      //   const d =
      //     cur.InvoicedAmount.ValueForView -
      //     cur.InvoicedNetAmountZeroVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total non VAT eligible
      // const prevTotalNonVATEligible = prevData.reduce((acc, cur) => {
      //   const d = cur.InvoicedNetAmountZeroVat.ValueForView;
      //   return (acc += d);
      // }, 0);

      // // Total ex VAT
      // const prevTotalVATEligibleNet = prevTotalVATEligible - prevTotalVAT;

      // const [restingVAT, restingVATData] = calculateRestingVAT({
      //   prevPeriodData: prevData,
      //   thisPeriodToDate: toDate,
      // });

      // dispatch({
      //   type: constants.SET_VAT_REPORT,
      //   payload: {
      //     data,
      //     prevData,
      //     fromDate,
      //     toDate,
      //     prevPeriodFromDate,
      //     prevPeriodToDate,
      //     totalVAT,
      //     total25VAT,
      //     total12VAT,
      //     total6VAT,
      //     totalVATEligible,
      //     totalNonVATEligible,
      //     totalVATEligibleNet,
      //     prevTotalVAT,
      //     prevTotal25VAT,
      //     prevTotal12VAT,
      //     prevTotal6VAT,
      //     prevTotalVATEligible,
      //     prevTotalNonVATEligible,
      //     prevTotalVATEligibleNet,
      //     restingVAT,
      //     restingVATData,
      //   },
      // });

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const getFutureInvoicesReport = ({
  fromDate,
  toDate,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const searchParams = {
        CreditorPublicId: creditorId,
        PeriodDateFrom: fromDate,
        PeriodDateTo: toDate,
        Types: ["ContractInvoiceAction"],
      };

      // get invoices with period within to/from date
      const result = await search({ searchParams });

      // these invoices contain too little information to perform what we want
      const contractInvoices = result?.ContractInvoiceActions;

      // so we fetch them individually
      const ids = contractInvoices?.map((ci) => ci.ActionPublicId);
      const promises = ids.map((id) => getContractInvoice(id));
      const detailedContractInvoices = await Promise.all(promises);

      const calculatedInvoices = [];

      if (detailedContractInvoices?.length) {
        detailedContractInvoices.forEach((contractInvoice) => {
          const possibleInvoicesWithinPeriod = getPossibleInvoicesWithinPeriod({
            contractInvoice,
            fromDate,
            toDate,
          });

          calculatedInvoices.push(...possibleInvoicesWithinPeriod);
        });
      }

      dispatch({
        type: constants.SET_FUTURE_INVOICES,
        payload: {
          futureInvoices: calculatedInvoices,
        },
      });

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

const calculateRestingVAT = ({ prevPeriodData, thisPeriodToDate }) => {
  // exclude paid invoices
  let dataClone = cloneDeep(prevPeriodData);
  const periodEndDate = moment(thisPeriodToDate);

  dataClone = dataClone.filter((invoice) => {
    return invoice.CurrentAmount.ValueForView > 0;
  });

  // exclude invoices where duedate is after this period date
  dataClone = dataClone.filter((invoice) => {
    return !moment(invoice.DueDate).isAfter(periodEndDate);
  });

  const totalRestingVAT = dataClone.reduce((acc, cur) => {
    const d = cur.InvoicedTaxAmount.ValueForView;
    return (acc += d);
  }, 0);

  return [totalRestingVAT, dataClone];
};

const getPossibleInvoicesWithinPeriod = ({
  contractInvoice,
  fromDate,
  toDate,
}) => {
  const possibleInvoices = [];

  const momentFromDate = moment(fromDate);
  const momentToDate = moment(toDate);

  // if no next run date, not interesting
  if (!contractInvoice.State.NextRunDate) {
    return possibleInvoices;
  }

  let nextInvoiceDate = moment(contractInvoice.State.NextRunDate);
  let nextDueDate;

  // next invoice is after to date, not interesting
  if (nextInvoiceDate.isAfter(momentToDate)) {
    return possibleInvoices;
  }

  // while we have not reached end, keep adding invoices
  let hasReachedEnd = false;
  const addMonths = getAddMonths(contractInvoice);

  do {
    nextDueDate = nextInvoiceDate.clone();
    if (contractInvoice.PayLastDayOfMonth) {
      nextDueDate.set("date", 1);
      nextDueDate.add(1, "month");
      nextDueDate.subtract(1, "day");
    } else {
      nextDueDate.add(contractInvoice.PaymentTermsInDays, "days");
    }

    let { sumExclVAT, sumIncVAT } = getAmountForPeriod({
      contractInvoice,
      nextInvoiceDate,
      nextDueDate,
    });

    if (nextInvoiceDate.isAfter(momentFromDate)) {
      possibleInvoices.push({
        invoiceDate: nextInvoiceDate.format("YYYY-MM-DD"),
        dueDate: nextDueDate.format("YYYY-MM-DD"),
        // used to match with contract
        contractInvoiceId: contractInvoice.ActionPublicId,
        contractNumber: contractInvoice.ContractNumber,
        yourReference: contractInvoice.YourReference,
        sumExclVAT,
        sumIncVAT,
      });
    }

    nextInvoiceDate = nextInvoiceDate.add(addMonths, "months");

    if (nextInvoiceDate.isAfter(moment(toDate))) {
      hasReachedEnd = true;
    }
  } while (!hasReachedEnd && possibleInvoices.length < 5000);

  return possibleInvoices;
};

// always adds months
const getAddMonths = (contractInvoice) => {
  let rec;
  switch (contractInvoice.RecurrenceDetails.RecurrenceInterval) {
    case "Monthly":
      rec = contractInvoice.RecurrenceDetails.MonthlyRecurrence;
      return rec.RecurMonthInterval;

    case "Quarterly":
      rec = contractInvoice.RecurrenceDetails.QuarterlyRecurrence;
      // 3 months per quarter
      return rec.RecurQuarterInterval * 3;

    case "Yearly":
      rec = contractInvoice.RecurrenceDetails.YearlyRecurrence;
      // 12 months per year
      return rec.YearlyRecurrence * 12;

    default:
      throw Error("Something went wrong with the contract invoice increment");
  }
};

const getAmountForPeriod = ({
  contractInvoice,
  nextInvoiceDate,
  nextDueDate,
}) => {
  let records = contractInvoice.Records;

  records = records.filter((r) => {
    // if not periodised, include
    if (!r.InvoicedFrom) return true;

    if (moment(r.InvoicedFrom).isAfter(nextDueDate)) {
      return false;
    }
    if (moment(r.InvoicedTo).isBefore(nextInvoiceDate)) {
      return false;
    }

    return true;
  });

  const sumIncVAT = records.reduce((acc, cur) => {
    return acc + cur.TotalIncVAT.ValueForView;
  }, 0);
  const sumExclVAT = records.reduce((acc, cur) => {
    return acc + cur.TotalExclVAT.ValueForView;
  }, 0);

  return {
    sumIncVAT,
    sumExclVAT,
  };
};
