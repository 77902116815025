import * as React from "react";
// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs, { getDetailUrl, getErrorSource } from "./listDefs";

// store, state
import { buildRangeFilter } from "../Base/utils";

import {
  useInvoicingErrorPagination,
  performFilter,
  constants,
} from "../../../store/invoicingErrors";
import { useHistory } from "react-router";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useDispatch } from "react-redux";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  onRowClicked,
  checkRowHighlighted,
  forceInitialPageSize,
  hidePagination,
  externalFilters,
  withPersistantSortBy,
  withPersistantGlobalFilter,
  persistantCategories,
  clearCategories,
}) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const filterInstructions = {};

  const columns = React.useMemo(
    () => columnDefs(),
    [persistantFilterMethod, persistantQueryString]
  );
  const fetchAllTreshold = 25;

  const localStorageHiddenColumnId = "error_invoicing_full_table_hidden";

  // not filterable on backend, but we want to enable filtering on it
  const forceFrontendColumns = [];

  const handleRowClicked = (row) => {
    const objectInitiatorObject = row.original.initiator_object;
    const objectIntitatorContentType = row.original.initiator_content_type;

    // equals "Angår objekt" in listdefs
    const objectDetailUrlMethod = getDetailUrl(objectIntitatorContentType);

    const error = row.original.error;

    const errorSource = getErrorSource(error);

    const sourceInitiatorObject = errorSource?.object_id
      ? { id: errorSource.object_id, str_representation: "Gå till källa" }
      : null;
    const sourceIntitatorContentType = errorSource?.content_type;

    // equals "Källa till fel" in listdefs
    const errorSourceDetailUrlMethod = getDetailUrl(sourceIntitatorContentType);

    if (errorSourceDetailUrlMethod && sourceInitiatorObject) {
      push(errorSourceDetailUrlMethod({ id: sourceInitiatorObject.id }));
    } else if (objectDetailUrlMethod) {
      push(objectDetailUrlMethod({ objectInitiatorObject }));
    } else {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Källa till fel kunde inte hittas",
          description: "Kontakta Pigello för att få hjälp med ärendet",
        })
      );
    }
  };

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantQueryString={persistantQueryString}
      persistantFilterMethod={persistantFilterMethod}
      paginationMethod={useInvoicingErrorPagination}
      filterInstructions={filterInstructions}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      localStorageHiddenColumnId={localStorageHiddenColumnId}
      detailUrlMethod={(obj) => {}}
      onRowClicked={handleRowClicked}
      toggleFormCallback={toggleFormCallback}
      title={hideTitle ? undefined : "Faktureringsfel"}
      forceFrontendColumns={forceFrontendColumns}
      forceInitialPageSize={forceInitialPageSize}
      hidePagination={hidePagination}
      exportSheetController={exportSheetController}
      {...{
        hideSearch,
        hideFilters,
        hideExport,
        hideColumns,
        onRowClicked,
        checkRowHighlighted,
        externalFilters,
        withPersistantSortBy,
        withPersistantGlobalFilter,
        persistantCategories,
        clearCategories,
      }}
    />
  );
};
