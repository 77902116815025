import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { useCanUseScrive } from "../../../store/editabledocs/hooks/retrieve";
import { usePermissionCheck } from "../../../store/base";

export default function ScriveIntegration() {
  const current = INTEGRATIONS.find((i) => i.title === "Scrive");

  const [canUseScrive] = useCanUseScrive();
  const customerHasScrive = usePermissionCheck("allow_scrive");

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök Scrives hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  Scrive-integrationen aktiveras globalt och kan begränsas till
                  vissa användare i systemet. Aktiveringen görs av Pigello på
                  begäran.
                  <br />
                  <br />
                  Så fungerar integrationen:
                  <br />
                  <br />
                  <strong>Steg 1:</strong>
                  <br />
                  Välj digital signering som signeringsmetod på ett avtal eller
                  dokument.
                  <br />
                  <br />
                  <strong>Steg 2:</strong>
                  <br />
                  Förbered det digitala dokumentet genom att placera ut fält och
                  välja signerande parter.
                  <br />
                  <br />
                  <strong>Steg 3:</strong>
                  <br />
                  Starta enkelt den digitala signeringen via Pigello och få
                  löpande uppdateringar om händelser på dokumentet.
                  <br />
                  <br />
                  <strong>Steg 4:</strong>
                  <br />
                  När alla parter har signerat dokumentet hämtas automatiskt
                  signeringsbeviset in till Pigello och sparas på avtalet.
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              {customerHasScrive !== true ? (
                <>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Aktivera integration</OverviewTitle>
                      <OverviewSubtitle>
                        Scrive-integrationen aktiveras av Pigello på begäran.
                        Kontakta oss för att veta mer.
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>
                  <TextButton
                    title="Kontakta oss"
                    iconType="arrow"
                    iconPlacement="right"
                    clicked={() => window.Intercom("show")}
                  />
                </>
              ) : canUseScrive === false ? (
                <>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Integration aktiv</OverviewTitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>

                  <InnerBox>
                    Användarbehörighet saknas för att använda
                    Scrive-integrationen
                  </InnerBox>
                </>
              ) : (
                <>
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Integration aktiv</OverviewTitle>
                      <OverviewSubtitle>
                        Scrive-integrationen är aktiv. Följ instruktionerna för
                        integrationen för att använda Scrive.
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>
                </>
              )}
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
