import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";

import BottomBar from "../../../components/StepDisplay/BottomBar";
import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { BareLayoutWrapper, InnerBox } from "../../../components/sharedStyles";
import {
  useUserGroup,
  update,
  constants as userGroupConstants,
} from "../../../store/userGroups";
import { useUser, inviteUsers } from "../../../store/users";
import FullSummary from "./Summary/FullSummary";
import { updateActiveFormInstance } from "../../../store/base";
import { cloneDeep } from "lodash";
import StandardModal from "../../../components/Modals/StandardModal";
import { InfoBox } from "../../../components/Displays";

export default () => {
  const [loading, setLoading] = React.useState(false);
  const { groupId, userId } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const storeName = userGroupConstants.STORE_NAME;

  const [user] = useUser(userId);
  const [group] = useUserGroup(groupId);

  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);

  const onSuccessInvite = () => {
    setLoading(false);
    push(`/account/groups-and-users/user/${user.id}`);
  };

  const sendInvite = () => {
    dispatch(
      inviteUsers({
        userIds: [user?.id],
        successCallback: onSuccessInvite,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onSubmit = (shouldInvite) => {
    setLoading(true);
    if (shouldInvite) {
      dispatch(
        update({
          id: groupId,
          successCallback: () => {
            sendInvite();
          },
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    } else {
      dispatch(
        update({
          id: groupId,
          successCallback: onSuccessInvite,
          errorCallback: () => {
            setLoading(false);
          },
        })
      );
    }
  };

  React.useEffect(() => {
    //add user to group
    const groupClone = cloneDeep(group);
    const isInGroup = groupClone?.users?.find((u) => u.id === user?.id);
    if (groupClone?.users && user?.id && !isInGroup) {
      groupClone.users.push({ id: user.id });
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: groupClone,
      })
    );
  }, [group, user]);

  return (
    <BareLayoutWrapper>
      <StandardModal
        isOpen={cancelModalOpen}
        closeFunction={() => setCancelModalOpen(false)}
        saveFunction={() => push(`/account/groups-and-users/user/${user?.id}`)}
        withActionBar
        title="Avbryt skapandet av användare"
        actionBarCancelTitle="Stäng"
        actionBarAcceptTitle="Avbryt ändå"
      >
        <OverviewTitle>Är du säker på att du vill avbryta?</OverviewTitle>
        <InfoBox
          text={`Var uppmärksam att användaren ${user?.str_representation} redan är skapad, men inte
          inbjuden. Avbryter du i detta steg så kommer användarkontot finnas under "Användare och Grupper", men inte vara tilldelad den
          grupp du valt. Har du däremot skapat en ny grupp kommer även den vara skapad, men utan några användare i sig.`}
        />
        <div style={{ marginTop: "24px" }}>
          Klicka på <strong>"Avbryt ändå"</strong> för att avbryta och gå direkt
          till den nyskapade användarens detaljsida.
        </div>
      </StandardModal>
      <StepDisplay
        steps={[
          "1. Skapa användare",
          "2. Ställ in behörigheter",
          "3. Bjud in användare",
        ]}
        activeIndex={2}
      />
      <InnerBox
        style={{
          maxWidth: 1000,
          padding: 48,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          marginBottom: "80px",
        }}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Kontrollera och bjud in</OverviewTitle>
            <OverviewSubtitle>
              Dubbelkolla att den ifyllda informationen stämmer och bjud sedan
              in användaren genom att klicka på "Bjud in användaren".
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <FullSummary user={user} group={group} />
      </InnerBox>
      <BottomBar
        primaryActionTitle="Slutför och bjud in användaren"
        disabled={false}
        primaryAction={() => onSubmit(true)}
        noInviteActionTitle="Slutför utan att bjuda in"
        noInviteAction={() => onSubmit()}
        cancelActionTitle="Avbryt"
        cancelAction={() => setCancelModalOpen(true)}
      />
      {loading && <OverlaySpinner />}
    </BareLayoutWrapper>
  );
};
