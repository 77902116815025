import * as React from "react";
import * as SC from "../styles";

import {
  NumberInput,
  PositiveNumber,
  Select,
  TableSelect,
  TextInput,
} from "../../../Forms/Base/Fields";

import { TextButton } from "../../../Forms/Base/Buttons";

import {
  constants as productConstants,
  performFilter as productPerformFilter,
  useInvoicingProductPagination,
  constructStrRep as productConstructStrRep,
} from "../../../../store/invoicingProducts";
import {
  constants as costCenterConstants,
  performFilter as costCenterPerformFilter,
  useInvoicingCostCenterPagination,
  constructStrRep as costCenterConstructStrRep,
} from "../../../../store/invoicingCostCenters";
import {
  constants as projectConstants,
  performFilter as projectPerformFilter,
  useInvoicingProjectPagination,
  constructStrRep as projectConstructStrRep,
} from "../../../../store/invoicingProjects";

import productListDefs from "../../../Lists/InvoicingProducts/listDefs";
import costCenterListDefs from "../../../Lists/InvoicingCostCenters/listDefs";
import projectListDefs from "../../../Lists/InvoicingProjects/listDefs";

export default ({
  instance,
  record,
  index,
  onRemoveRecordClicked,
  disabled,
  method,
  constants,
  creditorId,
  forceGetRecordRowKeys,
  addDetailRow,
}) => {
  const [detailsOpen, setDetailsOpen] = React.useState(false);

  const getRecordRowKeys = ({ index, propertyName }) => {
    if (forceGetRecordRowKeys)
      return forceGetRecordRowKeys({ index, propertyName });

    return [`Records[${index}].${propertyName}`, `Records.${propertyName}`];
  };

  const [descriptionFieldKey, descriptionInstructionKey] = getRecordRowKeys({
    index,
    propertyName: "ArticleDescription",
  });

  const [productFieldKey, productInstructionKey] = getRecordRowKeys({
    index,
    propertyName: "ProductPublicId",
  });

  const [costCenterFieldKey, costCenterInstructionsKey] = getRecordRowKeys({
    index,
    propertyName: "CostCenter",
  });

  const [projectFieldKey, projectInstructionsKey] = getRecordRowKeys({
    index,
    propertyName: "Project",
  });

  const [quantityFieldKey, quantityInstructionKey] = getRecordRowKeys({
    index,
    propertyName: "Quantity",
  });
  const [unitFieldKey, unitInstructionKey] = getRecordRowKeys({
    index,
    propertyName: "Units",
  });
  const [unitPriceFieldKey, unitPriceInstructionKey] = getRecordRowKeys({
    index,
    propertyName: "UnitPrice.Value",
  });
  const [VATFieldKey, VATInstructionKey] = getRecordRowKeys({
    index,
    propertyName: "VAT",
  });

  const rowTotal =
    instance?.Records[index]?.Quantity *
      instance?.Records[index]?.UnitPrice?.Value || 0;

  const isMessageRow = record.RecordType === "Message";

  return (
    <SC.InvoicedRecordRowWrapper key={record.ProductPublicId + index}>
      <SC.InvoicedRecordRow>
        <SC.InvoicedBoxRecordRowContent
          percentageWidth={isMessageRow ? "95" : "25"}
        >
          <TextInput
            noTitle
            noMargin
            rowSize
            storeName={constants.STORE_NAME}
            method={method}
            fieldKey={descriptionFieldKey}
            instructionsKey={descriptionInstructionKey}
            disabled={disabled}
            placeholder={isMessageRow ? "Meddelanderad" : "Beskrivning"}
          />
        </SC.InvoicedBoxRecordRowContent>
        {!isMessageRow && (
          <>
            <SC.InvoicedBoxRecordRowContent percentageWidth="25">
              <TableSelect
                storeName={constants.STORE_NAME}
                tableStoreName={productConstants.STORE_NAME}
                fieldKey={productFieldKey}
                performFilter={productPerformFilter}
                paginationMethod={useInvoicingProductPagination}
                instructionsKey={productInstructionKey}
                listDefs={productListDefs}
                method={method}
                fieldTitle="Produkt"
                title={"Välj produkt..."}
                constructStrRep={productConstructStrRep}
                modalInModal
                noMargin
                noTitle
              />
            </SC.InvoicedBoxRecordRowContent>

            <SC.InvoicedBoxRecordRowContent percentageWidth="10">
              <PositiveNumber
                noTitle
                noMargin
                rowSize
                storeName={constants.STORE_NAME}
                method={method}
                fieldKey={quantityFieldKey}
                instructionsKey={quantityInstructionKey}
                disabled={disabled}
              />
            </SC.InvoicedBoxRecordRowContent>

            <SC.InvoicedBoxRecordRowContent percentageWidth="5">
              <TextInput
                noTitle
                noMargin
                rowSize
                storeName={constants.STORE_NAME}
                method={method}
                fieldKey={unitFieldKey}
                instructionsKey={unitInstructionKey}
                disabled={disabled}
              />
            </SC.InvoicedBoxRecordRowContent>

            <SC.InvoicedBoxRecordRowContent percentageWidth="10">
              <NumberInput
                noTitle
                noMargin
                rowSize
                storeName={constants.STORE_NAME}
                method={method}
                fieldKey={unitPriceFieldKey}
                instructionsKey={unitPriceInstructionKey}
                disabled={disabled}
              />
            </SC.InvoicedBoxRecordRowContent>

            <SC.InvoicedBoxRecordRowContent percentageWidth="10">
              <Select
                noTitle
                noMargin
                rowSize
                storeName={constants.STORE_NAME}
                method={method}
                fieldKey={VATFieldKey}
                instructionsKey={VATInstructionKey}
                disabled={disabled}
              />
            </SC.InvoicedBoxRecordRowContent>

            <SC.InvoicedBoxRecordRowContent flexEnd percentageWidth="10">
              {rowTotal.toLocaleString("sv")}
            </SC.InvoicedBoxRecordRowContent>
          </>
        )}

        <SC.InvoicedBoxRecordRowContent percentageWidth="5">
          {!disabled && (
            <SC.DeleteButton onClick={() => onRemoveRecordClicked(index)} />
          )}
        </SC.InvoicedBoxRecordRowContent>
      </SC.InvoicedRecordRow>

      {!isMessageRow && (
        <>
          {detailsOpen ? (
            <>
              <SC.InvoicedRecordRow>
                <SC.InvoicedBoxRecordRowContent percentageWidth="25">
                  <TableSelect
                    storeName={constants.STORE_NAME}
                    tableStoreName={costCenterConstants.STORE_NAME}
                    fieldKey={costCenterFieldKey}
                    performFilter={costCenterPerformFilter}
                    paginationMethod={useInvoicingCostCenterPagination}
                    instructionsKey={costCenterInstructionsKey}
                    listDefs={costCenterListDefs}
                    method={method}
                    fieldTitle="Kostnadsställe"
                    title={"Välj kostnadsställe..."}
                    constructStrRep={costCenterConstructStrRep}
                    modalInModal
                    noMargin
                    noTitle
                  />
                </SC.InvoicedBoxRecordRowContent>

                <SC.InvoicedBoxRecordRowContent percentageWidth="25">
                  <TableSelect
                    storeName={constants.STORE_NAME}
                    tableStoreName={projectConstants.STORE_NAME}
                    fieldKey={projectFieldKey}
                    performFilter={projectPerformFilter}
                    paginationMethod={useInvoicingProjectPagination}
                    instructionsKey={projectInstructionsKey}
                    listDefs={projectListDefs}
                    method={method}
                    fieldTitle="Projekt"
                    title={"Välj projekt..."}
                    constructStrRep={projectConstructStrRep}
                    modalInModal
                    noMargin
                    noTitle
                  />
                </SC.InvoicedBoxRecordRowContent>
              </SC.InvoicedRecordRow>

              <TextButton
                extraStyle={{ color: "#444A55" }}
                title="Stäng detaljer"
                iconType="close"
                clicked={() => setDetailsOpen(false)}
              />
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextButton
                extraStyle={{ color: "#444A55", marginRight: 12 }}
                title="Detaljer"
                iconType="edit"
                iconPlacement="right"
                clicked={() => setDetailsOpen(true)}
              />

              <TextButton
                extraStyle={{
                  color: "#444A55",
                  marginLeft: 12,
                  marginRight: "auto",
                }}
                title="Lägg till meddelanderad"
                iconType="add"
                iconPlacement="right"
                clicked={() => addDetailRow(index)}
              />
            </div>
          )}
        </>
      )}
    </SC.InvoicedRecordRowWrapper>
  );
};
