import * as React from "react";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({}) => {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Granska och slutför</OverviewTitle>
          <OverviewSubtitle>
            Kontrollera att uppgifterna stämmer. Tryck på "Lägg till
            förvaltningsuppdrag" för att slutföra
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />
    </>
  );
};
