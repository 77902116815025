import { useDispatch, useSelector } from "react-redux";
import { performMultipleInvoiceSearches } from "..";
import { useInProgress } from "../../base";

export const useInvoicesFromMultipleSearchResults = ({
  searchId,
  searchInstances,
  typeConstants,
  errorCallback,
}) => {
  const name = `search_${searchId}`;
  const dispatch = useDispatch();
  const storeName = typeConstants?.STORE_NAME;

  const currentResult = useSelector((state) => state[storeName][name]);

  const inProgress = useInProgress({ storeName, name });

  if (!searchId || !searchInstances || !typeConstants) {
    return [undefined, false];
  }

  if (inProgress) return [undefined, true];

  // check if current invoice is the one we want, if not, we fetch it
  if (!currentResult) {
    dispatch(
      performMultipleInvoiceSearches({
        name,
        searchInstances,
        typeConstants,
        errorCallback,
      })
    );
    return [undefined, true];
  }

  return [currentResult, false];
};
