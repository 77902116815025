import styled, { css } from "styled-components";
import { animated } from "react-spring";

import closeBoxIcon from "../../assets/svg/close-box-red.svg";

export const BackDrop = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0 70px 0;
  z-index: 1001;
`;

export const FormModalWrapper = styled.div`
  min-width: 800px;
  max-width: 1000px;
  min-height: 100%;
  width: 60%;
  padding: 10px 20px 20px 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  overflow-y: auto;
`;

export const FormModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  font-weight: 600;
  color: ${(p) => p.theme.colors.black};
  font-size: 1.4rem;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  height: 1.4rem;
  width: 1.4rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${closeBoxIcon});
`;

export const StandardBackDrop = styled(animated.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(p) => p.theme.zIndex.modalBackdrop};
`;

export const Modal = styled(animated.div)`
  height: 70vh;
  min-height: 600px;
  width: 90%;
  max-width: 1000px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  z-index: ${(p) => p.theme.zIndex.modal};

  ${(p) =>
    p.high &&
    css`
      height: 90vh;
    `}
`;

export const ModalActionBar = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  border-top: 1px solid ${(p) => p.theme.colors.fadedBorder};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ModalContent = styled.div`
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  padding: 20px 20px 240px 20px;
  overflow-y: auto;

  ${(p) =>
    p.noBottomPadding &&
    css`
      padding-bottom: 20px;
    `}
`;

export const LabelValueContainer = styled.div`
  margin-bottom: 24px;
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const InfoBoxContainer = styled.div`
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  margin: 12px 0;
  padding: 8px 12px;

  border-color: ${(p) => p.theme.colors.blue};
  background-color: ${(p) => p.theme.colors.blueLight};
  color: ${(p) => p.theme.colors.blue};

  display: flex;
  border-radius: 5px;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  ${(p) =>
    p.boxTheme === "yellow" &&
    css`
      border-color: ${(p) => p.theme.colors.yellow};
      background-color: ${(p) => p.theme.colors.yellowLight};
      color: ${(p) => p.theme.colors.yellow};
    `}

  ${(p) =>
    p.boxTheme === "warning" &&
    css`
      border-color: ${(p) => p.theme.colors.red};
      background-color: ${(p) => p.theme.colors.redLight};
      color: ${(p) => p.theme.colors.red};
    `}

  ${(p) =>
    p.boxTheme === "ok" &&
    css`
      border-color: ${(p) => p.theme.colors.green};
      background-color: ${(p) => p.theme.colors.greenLight};
      color: ${(p) => p.theme.colors.green};
    `}
`;
export const InfoBoxTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.data};
  font-size: ${(p) => p.theme.fontSizes.data};
`;
export const InfoBoxText = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const TagLabel = styled.div`
  padding: 2px 6px;
  margin-right: 2px;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 3px;
  display: inline-flex;
  background-color: ${(p) => p.tag.color || "eafaf8"};
`;

export const EmptyTagLabel = styled.div`
  font-size: 0.8rem;
  font-weight: 600;

  border: thin solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 3px;
  padding: 2px 6px;
  display: inline-flex;
`;

export const DateCell = styled.div`
  background-color: ${(p) => p.theme.colors.yellowLight};
  display: inline-block;
  color: ${(p) => p.theme.primaryText};
  border-radius: 3px;
  padding: 2px 6px;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
`;

export const ATag = styled.a`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerLarge};
  color: ${(p) => p.theme.colors.blue};
`;

export const ToggleBoxWrapper = styled.div`
  display: flex;
  border: 1px solid ${(p) => p.theme.colors.fadedBorder};
  border-radius: 5px;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  overflow: hidden;
`;

export const ToggleBoxItem = styled.div`
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.graphite};
  transition: all 0.1s ease-in-out;

  &:not(:last-child) {
    border-right: thin solid ${(p) => p.theme.colors.gray2};
  }

  ${(p) =>
    p.selected &&
    css`
      background-color: ${(p) => p.theme.colors.primaryBlue};
      color: white;
    `}

  ${(p) =>
    !p.selected &&
    css`
      &:hover {
        background-color: ${(p) => p.theme.colors.blueLight};
      }
    `}
`;
