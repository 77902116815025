import styled from "styled-components";
import problemIcon from "../../../../../assets/svg/problem.svg";

export const Wrapper = styled.div`
  padding: 12px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #12263f;
  margin-bottom: 12px;
`;

export const Item = styled.div`
  margin-bottom: 4px;

  font-size: 0.9rem;
  font-weight: 500;
  padding-left: 28px;
  position: relative;
  color: #12263f;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 2px;
    height: 20px;
    width: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${problemIcon});
  }
`;
