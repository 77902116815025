import * as React from "react";

import SelectFilter from "../../Base/CompleteList/Filters/SelectFilter"

export default ({column}) => {
    const choices = [
        {value:"document", display:"Dokument"},
    ]

    return <SelectFilter choices={choices} column={column}/>
}