import * as React from "react";
import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

import { LinkedObject, TimeCell } from "../../Displays";
import SimpleDateFilter from "../Base/CompleteList/Filters/DateFilter";
import { isArray, isObject, isString, keys, values } from "lodash";

export const DETAIL_URL_MAPPING = {
  "standard.company": ({ id }) =>
    `/error-redirect?contentType=invoicingcompany&id=${id}`,
  "accounting.customerinvoicing": ({ id }) =>
    `/error-redirect?contentType=invoicinglease&id=${id}`,
  "accounting.project": ({ id }) =>
    `/error-redirect?contentType=invoicingproject&id=${id}`,
  "accounting.product": ({ id }) =>
    `/error-redirect?contentType=invoicingproduct&id=${id}`,
  "accounting.costcenter": ({ id }) =>
    `/error-redirect?contentType=invoicingcostcenter&id=${id}`,
};

const isBottom = (error) => {
  return (
    isObject(error) &&
    error["error"] &&
    (error["instance"] || error["instances"])
  );
};

const unpackNestedErrors = (error) => {
  if (isBottom(error)) {
    if (error["instance"]) {
      return [error["instance"], ...unpackNestedErrors(error["error"])];
    }
    return [...error["instances"], ...unpackNestedErrors(error["error"])];
  }

  let result = [];
  if (isObject(error)) {
    Object.values(error).forEach((err) => {
      result = [...result, ...unpackNestedErrors(err)];
    });
  } else if (isArray(error)) {
    error.forEach((err) => {
      result = [...result, ...unpackNestedErrors(err)];
    });
  }

  return result;
};

export const unpackErrorStr = (error) => {
  if (isString(error)) {
    return [error];
  }

  let result = [];
  if (isArray(error)) {
    error.forEach((err) => {
      result = [...result, ...unpackErrorStr(err)];
    });
  } else if (isObject(error)) {
    keys(error).forEach((key) => {
      if (key === "instance" || key === "instances") return;
      const val = error[key];
      result = [...result, ...unpackErrorStr(val)];
    });
  }

  return result;
};

export const getErrorSource = (error) => {
  return unpackNestedErrors(error)?.[0];
};

export const getDetailUrl = (contentType) => {
  if (contentType) return DETAIL_URL_MAPPING[contentType];

  return false;
};

export default () => {
  const cols = [
    {
      Header: "Felmeddelande",
      accessor: "error",
      Cell: ({ value }) => {
        const error = unpackErrorStr(value);

        return <div>{error.join(", ")}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Angår objekt",
      id: "related_object",
      Cell: ({ row }) => {
        const initiatorObject = row.original.initiator_object;
        const intitatorContentType = row.original.initiator_content_type;

        const detailUrlMethod = getDetailUrl(intitatorContentType);

        if (detailUrlMethod) {
          return (
            <LinkedObject obj={initiatorObject} urlMethod={detailUrlMethod} />
          );
        } else {
          return <div>-</div>;
        }
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Källa till fel",
      id: "error_source",
      Cell: ({ row }) => {
        const error = row.original.error;

        const errorSource = getErrorSource(error);

        const initiatorObject = errorSource?.object_id
          ? { id: errorSource.object_id, str_representation: "Gå till källa" }
          : null;
        const intitatorContentType = errorSource?.content_type;

        const detailUrlMethod = getDetailUrl(intitatorContentType);

        if (detailUrlMethod && initiatorObject) {
          return (
            <LinkedObject obj={initiatorObject} urlMethod={detailUrlMethod} />
          );
        } else {
          return <div>-</div>;
        }
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Skapades",
      accessor: "created_at",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Uppdaterades",
      accessor: "updated_at",
      Cell: ({ value }) => <TimeCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
  ];

  return cols;
};
