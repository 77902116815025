import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useFrequentPermissions, usePermissionCheck } from "../../store/base";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  ESignOrManual,
  ExistingOrNewContract,
} from "../../components/Forms/Pickers";
import { useCanUseScrive } from "../../store/editabledocs/hooks/retrieve";
import {
  createUrl,
  handleManyInvoicingUrl,
} from "../../store/managementContracts";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/Forms/Base/Buttons";
import ManagementContracts from "./overview/ManagementContracts";
import { useManagementContractPaginationCount } from "../../store/managementContracts/hooks/retrieve";

import HandleMassInvoincingLeaseContract from "./overview/HandleMassInvoicing";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

export default function ManagementContractOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["view_can_contract"]);

  const canAdd = usePermissionCheck("add_can_contract");
  const [canUseScrive, isLoadingCanUseScrive] = useCanUseScrive();
  const { hasBillectaFullPermission } = useFrequentPermissions();

  const [selectedNewOrExisting, setSelectedNewOrExisting] = React.useState("");

  const [chooseNewExistingActive, setChooseNewExistingActive] =
    React.useState(false);
  React.useState(false);
  const [chooseDigitalManualOpen, setChooseDigitalManualOpen] =
    React.useState(false);

  const [activeCount] = useManagementContractPaginationCount({});

  const initiateCreation = (digitalOrManual, newOrExisting) => {
    push(
      createUrl({
        existing:
          selectedNewOrExisting === "existing" || newOrExisting === "existing",
        digitalSign: digitalOrManual === "digital",
      })
    );
  };

  const handleExistingNewSelected = (newOrExisting) => {
    setSelectedNewOrExisting(newOrExisting);

    if (canUseScrive && newOrExisting === "new") {
      setChooseNewExistingActive(false);
      setChooseDigitalManualOpen(true);
    } else {
      initiateCreation("manual", newOrExisting);
    }
  };

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Avtal",
      component: ManagementContracts,
    },

    {
      path: "/handle-invoicing-statuses",
      hiddenLabel: true,
      component: HandleMassInvoincingLeaseContract,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((s) => !s.hiddenLabel).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0}>
          Antal avtal: {activeCount != null ? activeCount : "Laddar..."}
        </StatusLabel>
      </>
    );
  };

  const renderActions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {hasBillectaFullPermission && (
        <SecondaryButton
          title="Masshantera aviseringsstatus"
          clicked={() => push(handleManyInvoicingUrl())}
        />
      )}
      {canAdd && (
        <PrimaryButton
          extraStyle={{ marginLeft: 12 }}
          title="Lägg till avtal"
          clicked={() => setChooseNewExistingActive(true)}
        />
      )}
    </div>
  );

  return (
    <>
      <ExistingOrNewContract
        isOpen={chooseNewExistingActive}
        onClose={() => setChooseNewExistingActive(false)}
        onSelect={handleExistingNewSelected}
      />

      <ESignOrManual
        isOpen={chooseDigitalManualOpen}
        onClose={() => setChooseDigitalManualOpen(false)}
        onSelect={initiateCreation}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Förvaltningsavtal`}
          {...{ subPages: subPageLinks, renderInfoPills, renderActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
