import React from "react";
import FullTable from "../../components/Lists/NotificationsCenter/FullTable";
import { buildQueryString } from "../../store/base";

export default function Notifications() {
  const persistantQueryString = buildQueryString({
    order_by: "-created_at",
  });

  return (
    <FullTable
      hideTitle
      persistantQueryString={persistantQueryString}
      hideColumns
      exportSheetController={() => {}}
    />
  );
}
