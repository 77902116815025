import * as React from "react";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import {
  useInternalCustomerPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/internalCustomers";
import { useDispatch } from "react-redux";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  hideSearch,
  hideFilters,
  hideExport,
  hideColumns,
  exportSheetController,
  isMembers,
  withPersistantSortBy,
  withPersistantGlobalFilter,
  onRowClicked,
}) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const columns = React.useMemo(
    () => columnDefs(),
    [persistantFilterMethod, persistantQueryString]
  );
  const fetchAllTreshold = 25;

  // not filterable on backend
  const forceFrontendColumns = ["state"];

  const localStorageHiddenColumnId = "internal_customer_full_table_hidden";

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantQueryString={persistantQueryString}
      persistantFilterMethod={persistantFilterMethod}
      paginationMethod={useInternalCustomerPagination}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      detailUrlMethod={(obj) =>
        detailUrl({
          id: obj.id,
          isMember: isMembers,
        })
      }
      toggleFormCallback={toggleFormCallback}
      title={hideTitle ? undefined : "Interna kunder"}
      forceFrontendColumns={forceFrontendColumns}
      exportSheetController={exportSheetController}
      {...{
        localStorageHiddenColumnId,
        withPersistantSortBy,
        withPersistantGlobalFilter,
        hideSearch,
        hideFilters,
        hideExport,
        onRowClicked,
        hideColumns,
      }}
    />
  );
};
