import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import Insights from "../../components/Forms/Base/Buttons/Insights";

import { DetailLayoutWrapper } from "../../components/sharedStyles";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import InvoicingObjects from "./overview/InvoicingObjects";
import InvoicingOverview from "./overview/Overview";
import PaymentReport from "./overview/PaymentReport";
import InvoicingSettings from "./overview/Settings";
import Notifications from "./overview/Notifications";
import { useImportantMessages } from "../../store/billectaEvents/hooks/retrieve";

function BillectaOverview() {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  usePermissionRedirect(["allow_billecta", "view_can_billing"]);

  const [importantMessages] = useImportantMessages();

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: InvoicingOverview,
    },
    {
      path: "/notifications",
      label: `Viktiga meddelanden (${importantMessages?.length || 0})`,
      component: Notifications,
    },
    {
      path: "/invoicing-objects",
      label: "Produkter & Bokföringsdimensioner",
      component: InvoicingObjects,
    },
    {
      path: "/settings",
      label: "Aviseringsinställningar",
      component: InvoicingSettings,
    },
    {
      path: "/payment-report",
      label: "Inbetalningsrapport",
      component: PaymentReport,
    },
  ];

  const subPageLinks = SUB_PAGES.filter((sp) => !sp.hidden).map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const renderActions = () => {
    return (
      <>
        <Insights title="Insights" clicked={() => push(`/insights/economy`)} />
      </>
    );
  };

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title={"Fakturering"}
        {...{
          subPages: subPageLinks,
          renderActions,
        }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}

export default BillectaOverview;
