import { EuiFlexItem } from "@elastic/eui";
import * as React from "react";
import Pdf from "../../Pdf/Pdf";
import * as SC from "./styles";

const imageFormats = ["jpg", "jpeg", "png", "gif"];

export default function BasicDocDocumentDisplay({ basicdoc }) {
  const docUrl = basicdoc?.doc?.get;
  const s3ParamsSplit = docUrl?.split("?");
  const fileWithoutS3Params = s3ParamsSplit?.[0];
  const fileTypeSplit = fileWithoutS3Params?.split(".");
  const fileType = fileTypeSplit?.[fileTypeSplit?.length - 1];
  const isPDF = fileType?.toLowerCase() === "pdf";
  const isImage = imageFormats.includes(fileType);

  if (!docUrl) return <div>Dokument saknas</div>;

  return (
    <>
      {isPDF && <Pdf url={basicdoc?.doc?.get} />}
      {isImage && <SC.BasicDocImage img={docUrl} />}
    </>
  );
}
