import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";
import ContractInvoiceTableFilters from "../ExternalFilters/ContractInvoiceTableFilters";
import { DELIVERY_METHOD_MAPPER_EXCL_PRICES } from "../../../store/billectaInvoicing/utils";
import { DateCell } from "../../Displays";

const PERSISTANT_FILTER_KEY = `persistant_category_contractinvoicestable`;

export default ({ contractInvoices, handleRowClicked }) => {
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [filterCounts, setFilterCounts] = React.useState(null);
  const [hasPersistantFilter, setHasPersistantFilter] = React.useState(
    !!localStorage.getItem(PERSISTANT_FILTER_KEY)
  );

  React.useEffect(() => {
    let newFilterCounts = {};

    contractInvoices.forEach((i) => {
      if (i.IsPaused) {
        newFilterCounts["Paused"]
          ? (newFilterCounts["Paused"] += 1)
          : (newFilterCounts["Paused"] = 1);
      } else {
        newFilterCounts["Active"]
          ? (newFilterCounts["Active"] += 1)
          : (newFilterCounts["Active"] = 1);
      }

      setFilterCounts(newFilterCounts);
    });
  }, [contractInvoices]);

  const updateFilter = React.useCallback(
    (filter) => {
      if (selectedFilter === filter) {
        setSelectedFilter(null);
      } else {
        setSelectedFilter(filter);
      }

      localStorage.setItem(PERSISTANT_FILTER_KEY, filter);
    },
    [selectedFilter]
  );

  React.useEffect(() => {
    if (hasPersistantFilter) {
      const item = localStorage.getItem(PERSISTANT_FILTER_KEY);

      const filter = item ? item : null;

      updateFilter(filter);
    }
  }, [hasPersistantFilter]);

  const data = React.useMemo(() => {
    let displayedContractInvoices = [];

    displayedContractInvoices = contractInvoices.map((i) => ({
      ...i,
      total: `${(i.Amount.Value / 100)?.toLocaleString("sv")}`,
    }));

    // filter external filters
    if (selectedFilter) {
      displayedContractInvoices = displayedContractInvoices.filter((i) => {
        if (selectedFilter === "Paused") return i.IsPaused;
        if (selectedFilter === "Active") return !i.IsPaused;
      });
    }

    return displayedContractInvoices;
  }, [contractInvoices, selectedFilter]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "IsPaused",
        Cell: (props) => {
          return (
            <StatusLabel state={props.value ? 3 : 0}>
              {props.value ? "Pausad" : "Aktiv"}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Kontraktsnr.",
        accessor: "ContractNumber",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Kund",
        accessor: "DebtorName",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Värde (SEK)",
        accessor: "total",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Leveransmetod",
        accessor: "DeliveryMethod",
        Cell: (props) => {
          return (
            <Field>{DELIVERY_METHOD_MAPPER_EXCL_PRICES[props.value]}</Field>
          );
        },
      },
      {
        Header: "Nästa avisering",
        accessor: "NextRunDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Skapad",
        accessor: "Created",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Startdatum",
        accessor: "Start",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Slutdatum",
        accessor: "End",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
    ],
    []
  );

  return (
    <BasicTable
      externalFilters={() => (
        <ContractInvoiceTableFilters
          updateFilter={updateFilter}
          selectedFilter={selectedFilter}
          filterCounts={filterCounts}
        />
      )}
      clearExternalFilters={() => {
        updateFilter(null);
        setHasPersistantFilter(false);
      }}
      hasPersistantExternalFilter={hasPersistantFilter}
      data={data}
      columns={columns}
      onRowClicked={handleRowClicked}
      tableId="contractInvoicesTable"
      withPersistantSortBy
      withPersistantGlobalFilter
    />
  );
};
