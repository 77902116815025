import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
export const SidebarWrapper = styled.div`
  width: 25%;
  max-width: 250px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  color: ${(p) => p.theme.colors.gray1};
  background-color: ${(p) => p.theme.colors.gray5};
  border-right: 2px solid ${(p) => p.theme.colors.graphite};
`;

export const SidebarTitle = styled.div`
  padding: 0px 20px 0 40px;
  font-size: ${(p) => p.theme.fontSizes.headerMedium};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
`;

export const SidebarItems = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
`;

export const LogoutItem = styled.div`
  cursor: pointer;
  color: ${(p) => p.theme.colors.gray1};
  margin: 20vh 0 4px 0;
`;

export const SidebarItem = styled(Link)`
  color: ${(p) => p.theme.colors.gray1};
  margin: 4px 0;
  &:hover {
    color: ${(p) => p.theme.colors.green};
  }

  ${(p) =>
    p.active &&
    css`
      color: ${(p) => p.theme.colors.greenDark};

      &:hover {
        color: ${(p) => p.theme.colors.greenDark};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed !important;
      color: ${p.theme.colors.gray1};

      &:hover {
        color: ${p.theme.colors.gray1};
      }
    `}
`;
