import * as React from "react";
import DetailPageHeaderMenu from "../../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";

import INTEGRATIONS from "../integrationsList";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import { buildQueryString } from "../../../store/base";

import CompanyTable from "../../../components/Lists/Companies/FullTable";

export default function PEAccountingIntegration() {
  const current = INTEGRATIONS.find((i) => i.title === "PE Accounting");

  const withInvoicingQuery = buildQueryString({
    billecta_id__isnull: false,
  });
  const withInvoicingFilter = (obj) => !!obj.billecta_id;

  const goToWebsite = () => {
    if (!current.visitUrl) return;
    window.open(current.visitUrl, "_blank").focus();
  };

  const renderLogo = () => {
    return (
      <div
        style={{
          height: 50,
          width: 70,
          marginRight: 8,
          boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
          borderRadius: 8,
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${current.image})`,
        }}
      ></div>
    );
  };

  const renderInfoPills = () => (
    <OverviewSubtitle>{current.description}</OverviewSubtitle>
  );

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Integration ${current.title}`}
          {...{
            renderLogo,
            renderInfoPills,
            breadCrumbs: [
              { url: "/integrations", label: "Integrationer" },
              { label: current?.title },
            ],
          }}
        />
        <DetailInnerWrapper>
          <DetailPageBoxFlexWrapper>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "60%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Integrationsinformation</OverviewTitle>
                  <OverviewSubtitle>
                    <TextButton
                      title="Besök PE Accountings hemsida"
                      iconType="launch"
                      iconPlacement="right"
                      clicked={goToWebsite}
                    />
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>
                      Hur fungerar integrationen?
                    </OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>
                <BodyText>
                  PE Accounting-integrationen bokför automatiskt händelser i
                  Pigellos faktureringsmodul mot PE Accounting.
                  <br />
                  <br />
                  Integrationen aktiveras under bolagsinställningarna på
                  respektive bolag
                </BodyText>
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox
              style={{ flex: 1, maxWidth: "39%", alignSelf: "flex-start" }}
            >
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Hantera integration</OverviewTitle>
                  <OverviewSubtitle>
                    Välj vilket bolag integrationen ska hanteras för
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <CompanyTable
                {...{
                  persistantFilterMethod: withInvoicingFilter,
                  persistantQueryString: withInvoicingQuery,
                  hideTitle: true,
                  hideExport: true,
                  hideFilters: true,
                  hideSelect: true,
                  hideSearch: true,
                  hideColumns: true,
                }}
              />
            </DetailPageBox>
          </DetailPageBoxFlexWrapper>
        </DetailInnerWrapper>
      </DetailLayoutWrapper>
    </>
  );
}
