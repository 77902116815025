export const ACTION_TYPES = {
  view: "Visa",
  add: "Lägga till",
  change: "Redigera",
  delete: "Ta bort",
};

export const getModalInformation = (title) => {
  switch (title) {
    case "Företag":
      return {
        title: "Företag",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa företag"],
          },
          {
            kind: "add",
            actions: ["Skapa nya företag"],
          },
          {
            kind: "change",
            actions: ["Redigera företag"],
          },
          {
            kind: "delete",
            actions: ["Ta bort företag"],
          },
        ],
      };

    case "Förvaltning":
      return {
        title: "Förvaltning",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa Förvaltning"],
          },
          {
            kind: "add",
            actions: ["Lägg till nya Förvaltning"],
          },
          {
            kind: "change",
            actions: [
              "Hantera Förvaltning",
              "Hantera förvaltningsinställningar",
            ],
          },
          {
            kind: "delete",
            actions: ["Ta bort Förvaltning"],
          },
        ],
      };

    case "Dokument":
      return {
        title: "Dokument",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa dokument"],
          },
          {
            kind: "add",
            actions: ["Lägg till dokument"],
          },
          {
            kind: "change",
            actions: ["Redigera dokument"],
          },
          {
            kind: "delete",
            actions: ["Ta bort dokument"],
          },
        ],
      };

    case "Mallar":
      return {
        title: "Mallar & Bilagor",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: [
              "Visa dokument",
              "Visa bilagor",
              "Visa avtalsmallar",
              "Visa signeringsparter",
            ],
          },
          {
            kind: "add",
            actions: [
              "Lägg till dokument",
              "Lägg till bilagor",
              "Lägg till avtalsmallar",
              "Lägg till signeringsparter",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera dokument",
              "Redigera bilagor",
              "Redigera avtalsmallar",
              "Hantera signeringsparter",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort dokument",
              "Ta bort bilagor",
              "Ta bort avtalsmallar",
              "Ta bort signeringsparter",
            ],
          },
        ],
      };

    case "Användarhantering":
      return {
        title: "Användarhantering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa användare"],
          },
          {
            kind: "add",
            actions: ["Lägg till användare"],
          },
          {
            kind: "change",
            actions: ["Redigera användare"],
          },
          {
            kind: "delete",
            actions: ["Ta bort användare"],
          },
        ],
      };

    case "Behörighetshantering":
      return {
        title: "Behörighetshantering",
        isTable: true,
        info: [
          {
            kind: "view",
            actions: ["Visa användargrupper", "Visa behörigheter"],
          },
          {
            kind: "add",
            actions: ["Lägg till användargrupper", "Lägg till behörigheter"],
          },
          {
            kind: "change",
            actions: ["Redigera användargrupper", "Redigera behörigheter"],
          },
          {
            kind: "delete",
            actions: ["Ta bort användargrupper", "Ta bort behörigheter"],
          },
        ],
      };

    case "Fakturering/Avisering":
      return {
        title: "Fakturering/Avisering",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Billecta integration",
        companyPermType: "allow_billecta",
        info: [
          {
            kind: "view",
            actions: [
              "Visa enskilda fakturor",
              "Visa 3-fakturering",
              "Visa massfakturering",
              "Visa avtalsavisering",
              "Visa inbetalningar",
              "Visa omatchade inbetalningar",
              "Visa påminnelser",
              "Visa kreditfakturor",
              "Visa kravhantering",
              "Visa faktureringsinställningar",
              "Visa bokföringsinställningar",
              "Visa produkter",
              "Visa kostnadsställen",
              "Visa projekt",
              "Skapa SIE4 exporter",
              "Exportera kundreskontra",
              "Exportera rapporter",
              "Visa bokföringsintegrationer",
              "Visa bankgirointegration",
            ],
          },
          {
            kind: "add",
            actions: [
              "Skapa enskilda fakturor",
              "Skapa 3-fakturering",
              "Skapa massfakturor",
              "Sätt upp avtalsavisering",
              "Lägg till inbetalningar manuellt",
              "Matcha omatchade inbetalnngar",
              "Skicka påminnelser",
              "Skapa kreditfatkuror",
              "Aktivera kravhantering",
              "Sätt upp faktureringsinställningar",
              "Sätt upp bokföringsinställningar",
              "Skapa produkter",
              "Skapa kostnadsställen",
              "Skapa projekt",
              "Skapa SIE4 exporter",
              "Exportera kundreskontra",
              "Exportera rapporter",
              "Hantera bolagsinställningar för fakturering",
              "Hantera bankgirointegration",
            ],
          },
          {
            kind: "change",
            actions: [
              "Redigera enskilda fakturor",
              "Redigera avtalsavisering",
              "Redigera ej attesterade kreditfakturor",
              "Redigera kravhantering",
              "Redigera faktureringsinställnigar",
              "Redigera bokföringsinställningar",
              "Redigera produkter",
              "Redigera kostnadsställen",
              "Redigera projekt",
              "Skapa SIE4 exporter",
              "Exportera kundreskontra",
              "Exportera rapporter",
              "Hantera bolagsinställningar för fakturering",
              "Hantera bankgirointegration",
            ],
          },
          {
            kind: "delete",
            actions: [
              "Ta bort ej attesterade fakturor",
              "Ta bort omatchade inbetalningar",
              "Ta bort ej attesterade kreditfakturor",
              "Ta bort kravhantering",
              "Ta bort produkter",
              "Ta bort kostnadsställen",
              "Ta bort projekt",
              "Inaktivera bankgirointegration",
            ],
          },
        ],
      };

    case "E-signering":
      return {
        title: "E-singering",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "E-signering med Scrive",
        companyPermType: "allow_scrive",
        info: [
          {
            kind: "view",
            actions: ["Visa en e-signeringsprocess"],
          },
          {
            kind: "add",
            actions: ["Starta e-signeringsprocess"],
          },
          {
            kind: "change",
            actions: ["Redigera påbörjad e-signeringsprocess"],
          },
          {
            kind: "delete",
            actions: ["Avsluta e-signeringsprocess"],
          },
        ],
      };

    case "Fastdok":
      return {
        title: "Fastighetsägarna dokument",
        isTable: true,
        companyPerm: true,
        companyPermTitle: "Fastighetsägarna dokument",
        companyPermType: "allow_fastdoc",
        info: [
          {
            kind: "view",
            actions: ["Visa fastighetsägarna dokument"],
          },
          {
            kind: "add",
            actions: ["Använda fastighetsägarna dokument"],
          },
          {
            kind: "change",
            actions: ["Redigera fastighetsägarna dokument"],
          },
          {
            kind: "delete",
            actions: ["Ta bort fastighetsägarna dokument"],
          },
        ],
      };
  }
};
