import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  fastDocAtlasTemplateName,
  fastDocTemplateName,
  privateFastDocTemplateName,
} from "../../components/EditableDocBase/DocumentSelector/Templates/Container";
import EditableDocCreateContainer from "../../components/Forms/EditableDoc/TemplateForm/Container";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import { create, destroyPostForm, detailUrl } from "../../store/editabledocs";

export default function CreateTemplate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const { push } = useHistory();

  const successCallback = (_, returned) => {
    setLoading(false);

    dispatch(destroyPostForm(true));
    push(detailUrl({ id: returned.id }));
  };

  const errorCallback = () => {
    setLoading(false);
  };

  const preProcess = (data) => {
    const clone = cloneDeep(data);

    clone.is_template = true;

    const chosenTemplate = clone._chosenTemplate;
    const isFastDocTemplate = [
      fastDocAtlasTemplateName,
      fastDocTemplateName,
      privateFastDocTemplateName,
    ].includes(chosenTemplate?.name);
    const fastdocId = chosenTemplate?.id;

    if (!isFastDocTemplate || !fastdocId) {
      return clone;
    }
    clone.fastdoc_id = fastdocId;
    delete clone.docData_;

    return clone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      create({
        preProcess,
        successCallback,
        errorCallback,
      })
    );
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      <EditableDocCreateContainer
        {...{
          method: "POST",
          onSubmit,
        }}
      />
    </>
  );
}
