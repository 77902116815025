import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  destroyForm,
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../store/base";
import { constants } from "../../../store/billectaIntegrations";
import { useFortnoxForm } from "../../../store/billectaIntegrations/hooks/form";
import { updateFortnoxSettings } from "../../../store/billectaIntegrations/store/actions";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import { DatePicker, TextInput } from "../../Forms/Base/Fields";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";

export default function FortnoxIntegrationForm({
  open,
  closeFunction,
  creditorId,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const fortnoxSettings = useSelector(
    (state) => state.billectaIntegrations.fortnoxSettings
  );

  const instance = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );

  useFortnoxForm("PUT", open);

  React.useEffect(() => {
    if (open) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: fortnoxSettings,
        })
      );
    } else {
      dispatch(
        destroyForm({
          constants,
          method: "PUT",
          success: false,
        })
      );

      dispatch(
        setActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {},
        })
      );
    }
  }, [open]);

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      updateFortnoxSettings({
        creditorId,
        successCallback: () => {
          closeFunction();
          setLoading(false);
          dispatch(
            setActiveFormInstance({
              storeName: constants.STORE_NAME,
              data: {},
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Kontrollera formuläret och försök igen",
            })
          );
        },
      })
    );
  };

  const canAccept = instance?.APICode && instance?.VoucherSeries;

  return (
    <StandardModal
      isOpen={open}
      closeFunction={closeFunction}
      withActionBar
      actionBarAcceptTitle="Uppdatera integration"
      title="Integration - Fortnox"
      saveFunction={handleSubmit}
      canAccept={canAccept}
    >
      {loading && <OverlaySpinner />}
      <FormAreaTitle>Fortnox</FormAreaTitle>
      <FormAreaDescription>
        Integrationen Fortnox synkar bokföringsuppgifter baserat på avier
        skickat genom Pigello mot Fortnox
      </FormAreaDescription>

      <DatePicker
        title="Startdatum för verifikationsserie"
        storeName={constants.STORE_NAME}
        fieldKey={"StartDate"}
        method={"PUT"}
      />

      <TextInput
        storeName={constants.STORE_NAME}
        fieldKey={"VoucherSeries"}
        method={"PUT"}
      />
    </StandardModal>
  );
}
