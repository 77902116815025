import { push } from "connected-react-router";
import * as React from "react";
import { useHistory } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../../components/Forms/Base/Buttons";
import RenderGroup from "../../../../components/Forms/UserGroup/Permissions/RenderGroup";

export default ({ group, userId }) => {
  const { push } = useHistory();
  return (
    <div>
      <OverviewTitleWrapper>
        <OverviewTitle small>
          Översikt behörigheter - {group?.name}
        </OverviewTitle>
        <PrimaryButton
          title="Ändra grupp"
          clicked={() =>
            push(
              `/account/groups-and-users/user-setup/set-user-permissions/${userId}`
            )
          }
        />
      </OverviewTitleWrapper>
      <RenderGroup group={group} disabled />
    </div>
  );
};
