import * as React from "react";
import { useHistory } from "react-router-dom";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import { performFilter, constants, detailUrl } from "../../../store/users";
import { useUserPagination } from "../../../store/users";
import { useFilteredUserGroups } from "../../../store/userGroups";
import { useSelector } from "react-redux";

export default ({
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  persistantQueryString,
  persistantFilterMethod,
  groups,
}) => {
  const { push } = useHistory();
  const currentUserId = useSelector((state) => state.app.user?.id);
  const storeName = constants.STORE_NAME;

  const columns = React.useMemo(
    () => columnDefs(groups),
    [persistantQueryString, persistantFilterMethod]
  );
  const fetchAllTreshold = 25;

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantFilterMethod={persistantFilterMethod}
      persistantQueryString={persistantQueryString}
      paginationMethod={useUserPagination}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      onRowClicked={(row) => {
        const id = row.original?.id;
        if (id == currentUserId) {
          push("/account/user");
          return;
        }
        push(`/account/groups-and-users/user/${id}`);
      }} // prevent default behaviour
      detailUrlMethod={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      title={hideTitle ? undefined : "Användare"}
      exportSheetController={exportSheetController}
      {...{ hideFilters, hideExport, hideSearch, hideColumns }}
    />
  );
};
