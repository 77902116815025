import * as React from "react";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";
import { buildRangeFilter } from "../Base/utils";

// store, state
import {
  useEditabledocPagination,
  performFilter,
  constants,
  editUrl,
  useEditabledoc,
  detailUrl,
} from "../../../store/editabledocs";
import DeleteModal from "../../Forms/Delete/DeleteModal";
import { clearFetched } from "../../../store/base";
import { useDispatch } from "react-redux";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  withPersistantSortBy,
  withPersistantGlobalFilter,
}) => {
  const dispatch = useDispatch();
  const [removeId, setRemoveId] = React.useState(null);
  const storeName = constants.STORE_NAME;

  const filterInstructions = {
    inhouse_time: { handler: (filterObject) => buildRangeFilter(filterObject) },
  };

  const [removeObject] = useEditabledoc(removeId);

  const exportParser = {
    category: (data) => data.category_display,
  };

  const onRemove = (id) => {
    setRemoveId(id);
  };

  const handleDeleted = () => {
    dispatch(clearFetched(constants, true));

    setRemoveId(null);
  };

  const columns = React.useMemo(
    () => columnDefs(onRemove),
    [persistantFilterMethod, persistantQueryString, removeId]
  );
  const fetchAllTreshold = 25;

  return (
    <>
      <DeleteModal
        instance={removeObject}
        constants={constants}
        isOpen={!!removeObject}
        closeFunction={() => setRemoveId(null)}
        deletedCallback={handleDeleted}
      />

      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useEditabledocPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        detailUrlMethod={(obj) =>
          detailUrl({
            id: obj.id,
          })
        }
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Avtalsmallar"}
        exportParser={exportParser}
        exportSheetController={exportSheetController}
        {...{
          hideFilters,
          hideExport,
          hideSearch,
          withPersistantSortBy,
          withPersistantGlobalFilter,
        }}
      />
    </>
  );
};
