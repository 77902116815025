export default [
  {
    title: "Förvaltningsbolag",
    key: "COMPANY",
    visited: true,
    hasError: false,
    fieldKeys: ["company"],
  },
  {
    title: "Uppdatera",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
