import * as React from "react";
// style, design
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { Select, TextInput } from "../../../Base/Fields";

// store, states
import { constants } from "../../../../../store/invoicingCompany";
import { useFormInstanceField } from "../../../../../store/base";
import { InfoBox } from "../../../../Displays";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const primaryPaymentMethod = useFormInstanceField({
    storeName,
    fieldKey: "primary_payment_method",
  });
  return (
    <>
      <FormAreaTitle>Betalmetoder</FormAreaTitle>

      {primaryPaymentMethod && primaryPaymentMethod !== "bankgiro" && (
        <InfoBox
          boxTheme={"yellow"}
          title={"OBS"}
          text="Vänligen notera att ingen automatisk avstämning på inbetalningar kan ske om du väljer annat än Bankgiro som betalsätt."
        />
      )}

      <Select
        title="Primär betalmetod"
        description="Primär betalmetod på avier"
        {...{
          storeName,
          method,
          fieldKey: `primary_payment_method`,
        }}
      />

      <TextInput
        title="Bankgiro"
        {...{
          storeName,
          method,
          fieldKey: `bankgiro`,
        }}
      />

      <TextInput
        title="Plusgiro"
        {...{
          storeName,
          method,
          fieldKey: `plusgiro`,
        }}
      />

      <DoubleFieldWrapper>
        <TextInput
          title="Clearingnummer"
          extraStyles={{ minWidth: "150px", marginRight: "20px" }}
          {...{
            storeName,
            method,
            fieldKey: `clearing_number`,
          }}
        />

        <TextInput
          title="Bankkonto"
          extraStyles={{ minWidth: "290px" }}
          {...{
            storeName,
            method,
            fieldKey: `bank_account`,
          }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "150px", marginRight: "20px" }}
          title="BIC/Swift"
          description="BIC/Swift är det internationella ID:t för banken"
          {...{
            storeName,
            method,
            fieldKey: `bic`,
          }}
        />

        <TextInput
          extraStyles={{ minWidth: "290px" }}
          title="IBAN"
          description="IBAN är det internationella formatet för bankkontot"
          {...{
            storeName,
            method,
            fieldKey: `iban`,
          }}
        />
      </DoubleFieldWrapper>
    </>
  );
};
