import * as React from "react";

import { useDispatch } from "react-redux";
import { destroyForm, updateActiveFormInstance } from "../../../store/base";
import {
  useFortnoxCredentialForm,
  constants,
  create,
  update,
} from "../../../store/fortnoxCredential";
import { InfoBox } from "../../Displays";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import { TextInput } from "../../Forms/Base/Fields";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";

export default function FortnoxCredentialForm({
  fortnoxCredId,
  fortnoxCredential,
  companyId,
  open,
  closeFunction,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const method = fortnoxCredId ? "PATCH" : "POST";
  const [loading, setLoading] = React.useState(false);
  useFortnoxCredentialForm(method, fortnoxCredId);

  React.useEffect(() => {
    if (open) {
      if (method === "POST") {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: { company: { id: companyId } },
          })
        );
      } else {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: fortnoxCredential,
          })
        );
      }
    } else {
      dispatch(
        destroyForm({
          constants,
          method,
          success: false,
        })
      );
    }
  }, [open]);

  const handleSubmit = () => {
    setLoading(true);

    if (method === "POST") {
      dispatch(
        create({
          successCallback: () => {
            closeFunction();
            setLoading(false);
          },
        })
      );
    } else {
      dispatch(
        update({
          id: fortnoxCredId,
          successCallback: () => {
            closeFunction();
            setLoading(false);
          },
        })
      );
    }
  };

  return (
    <StandardModal
      isOpen={open}
      closeFunction={closeFunction}
      withActionBar
      actionBarAcceptTitle={
        method === "POST" ? "Aktivera integration" : "Uppdatera nyckel"
      }
      title="Integration - Fortnox leverantörsfaktura"
      saveFunction={handleSubmit}
    >
      {loading && <OverlaySpinner />}

      <FormAreaTitle>Fortnox leverantörsfaktura</FormAreaTitle>
      <FormAreaDescription>
        Integrationen Fortnox leverantörsfaktura möjligör fakturering vid
        felanmälningar.
      </FormAreaDescription>

      {fortnoxCredId && (
        <InfoBox
          title="OBS"
          text="Vid uppdatering av nyckel byts befintliga ut, av säkerhetsskäl visas inte befintlig nyckel."
        />
      )}
      <TextInput
        storeName={constants.STORE_NAME}
        fieldKey={"auth_code"}
        title="Autentiseringsnyckel"
        method={method}
      />
    </StandardModal>
  );
}
