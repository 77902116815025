import * as React from "react";

import { ToolTipCell, EmailTag } from "../../Displays";
import AnonymousCell from "../../Displays/AnonymousCell";
import { TextButton } from "../../Forms/Base/Buttons";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";
import { StatusLabel } from "../Base/CompleteList/styles";

export default (hasBillectaViewPermission, handleLogin) => {
  const cols = [
    {
      Header: "",
      accessor: "image.get",
      Cell: ({ value }) => (
        <div
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "5px",
            backgroundImage: `url(${value})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Uppdragsgivare",
      accessor: "str_representation",
      Cell: ({ value, row }) =>
        !!row?.original?.user?.anonymization ? (
          <AnonymousCell />
        ) : (
          <ToolTipCell text={value} />
        ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Org-nr",
      accessor: "legal_id",
      Cell: ({ value, row }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kontaktperson",
      accessor: (row) => `${row.first_name} ${row.last_name}`,
      Cell: ({ row }) => (
        <ToolTipCell
          text={`${row.original.first_name} ${row.original.last_name}`}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => <EmailTag mail={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  if (handleLogin) {
    cols.push({
      Header: "Logga in",
      id: "login",
      Cell: ({ row }) => {
        const connected = !row.original.is_not_connected;

        if (!connected) return "Saknar behörighet/koppling";

        return (
          <TextButton
            title="Logga in"
            iconType="arrow"
            iconPlacement="right"
            clicked={() => handleLogin(row.original.id)}
          />
        );
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    });
  }

  if (hasBillectaViewPermission) {
    cols.splice(5, 0, {
      Header: "Fakturering",
      accessor: (row, _) => !!row.debtor_invoice_config?.id,
      Cell: ({ value }) => (
        <StatusLabel state={value ? 0 : 3}>
          {value ? "Konfigurerad" : "Ej konfigurerad"}
        </StatusLabel>
      ),
      Filter: SimpleTextFilter,
      filter: "textExact",
    });
  }

  return cols;
};
