import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { constants } from "../../../store/editabledocs";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "../../Forms/Base/Buttons";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import { exportEditableDoc } from "../export";
import * as SC from "./styles";
import ToolTip from "../../ToolTip/ToolTip";
import PayTemplate from "../PayTemplate/PayTemplate";
import FieldDetailCard from "../Fields/HandleField/FieldDetailCard";
import { cloneDeep } from "lodash";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import validateSave from "../validateSave";
import StandardModal from "../../Modals/StandardModal";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";

function EditableDocBottomBar({
  method,
  onSubmit,
  isTemplate,
  onClearDocumentCallback,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const pageKey = `_page`;
  const numberOfPagesKey = `_pageNo`;
  const [payTemplateOpen, setPayTemplateOpen] = React.useState(false);

  const [confirmRemoveOpen, setConfirmRemoveOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState(null);
  const editableDoc = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const editableDocPageNumber = useFormInstanceField({
    storeName,
    fieldKey: pageKey,
  });
  const editableDocPageAmount = useFormInstanceField({
    storeName,
    fieldKey: numberOfPagesKey,
  });

  const handleFieldData = useFormInstanceField({
    storeName,
    fieldKey: "_handleFieldAttrs",
  });

  const chosenTemplate = useFormInstanceField({
    storeName,
    fieldKey: "_chosenTemplate",
  });

  const unpaidTemplate = !chosenTemplate ? false : !chosenTemplate.pay;

  const onDownload = async () => {
    await exportEditableDoc({ editableDoc, forShow: true, download: true });
  };

  const onClearHandleField = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _handleFieldAttrs: null,
        },
      })
    );
  };

  const onRemoveDoc = () => {
    // clean field placements
    const parties = cloneDeep(editableDoc?.parties);
    if (parties) {
      parties.forEach((p) => {
        if (p.fields) {
          p.fields.forEach((f) => {
            f.placements = [];
          });
        }
      });
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          parties,
          doc: null,
          docData_: null,
          fastdoc_id: null,
          _chosenTemplate: null,
        },
      })
    );

    onClearDocumentCallback && onClearDocumentCallback();
  };

  const buildPageOptions = () => {
    let pageOptions = [];

    for (let i = 0; i < editableDocPageAmount; i++) {
      pageOptions.push({
        label: `Sida ${i + 1}`,
        value: i + 1,
      });
    }

    return pageOptions;
  };

  const handlePageUpdated = (page) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _page: page,
        },
      })
    );
  };

  const attemptSubmit = () => {
    const editableDocCopy = cloneDeep(editableDoc);
    const [canSave, errors] = validateSave({
      instance: editableDocCopy,
      isTemplate,
    });

    if (canSave) {
      onSubmit();
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <>
      <StandardModal
        isOpen={validationErrors?.length > 0}
        closeFunction={() => setValidationErrors(null)}
        withActionBar
        actionBarCancelTitle="Dölj"
        title={`Ågärda felen för att spara ${
          isTemplate ? "mallen" : "signeringsdokumentet"
        }`}
      >
        {validationErrors?.length > 0 && (
          <>
            {" "}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Dessa fel måste åtgärdas:</OverviewTitle>
                <OverviewSubtitle>
                  Tryck på "Spara" när felen är åtgärdade för att försöka igen.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            <ul
              style={{
                marginTop: 24,
                listStyleType: "square",
                listStylePosition: "outside",
                paddingLeft: 24,
              }}
            >
              {validationErrors?.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
        )}
      </StandardModal>

      <ConfirmationModal
        isOpen={confirmRemoveOpen}
        title={"Är du säker på att du vill byta dokument?"}
        renderContent={() => (
          <div>Detta innebär att alla fältplaceringar kommer rensas.</div>
        )}
        closeFunction={() => setConfirmRemoveOpen(false)}
        acceptCallback={onRemoveDoc}
        acceptTitle="Byt dokument"
      />

      {unpaidTemplate && !isTemplate && (
        <PayTemplate
          isOpen={payTemplateOpen}
          closeFunction={() => setPayTemplateOpen(false)}
          chosenTemplate={chosenTemplate}
          storeName={storeName}
          fastdocId={editableDoc?.fastdoc_id}
        />
      )}

      {!!handleFieldData && (
        <FieldDetailCard
          {...{
            storeName,
            method,
            ...handleFieldData,
            closeFunction: onClearHandleField,
            pageAmount: editableDocPageAmount,
            onPageSelected: handlePageUpdated,
          }}
        />
      )}

      {!handleFieldData && (
        <SC.BottomBarContainer>
          <SC.InnerContainer>
            <SC.ButtonsWrapper>
              <SC.SelectWrapper>
                <NonConnectedSelect
                  menuPlacement="top"
                  noMargin
                  noTitle
                  noHelp
                  onUpdate={handlePageUpdated}
                  choices={buildPageOptions()}
                  value={editableDocPageNumber}
                  id={"editabledocpageselect"}
                  placeholder="Välj sida"
                  getOptionValue={(o) => o.value}
                  getOptionLabel={(o) => o.label}
                  allowNull={false}
                />
              </SC.SelectWrapper>

              {unpaidTemplate && !isTemplate && (
                <PrimaryButton
                  extraStyle={{ marginLeft: 12, position: "relative" }}
                  title="Betala mall"
                  clicked={() => setPayTemplateOpen(true)}
                >
                  <ToolTip
                    title={"Betala för mall från FastDok"}
                    renderContent={() => (
                      <>
                        Vid utnyttjande av mall från FastDok <br />
                        så måste mallen betalas för. <br />
                        Tryck här för att betala för
                        <br /> mallen och ta bort vattenstämpeln!
                      </>
                    )}
                    canClose
                    placement="top"
                    persistantKey={"pay_for_template_tip"}
                  />
                </PrimaryButton>
              )}
              <TextButton
                extraStyle={{ marginLeft: 12 }}
                title="Byt dokument"
                clicked={() => setConfirmRemoveOpen(true)}
                iconType="close"
                iconPlacement="right"
                red
              />
            </SC.ButtonsWrapper>

            <SC.ButtonsWrapper>
              <SecondaryButton title="Förhandsgranska" clicked={onDownload} />
              <PrimaryButton
                extraStyle={{ marginLeft: 12 }}
                title={
                  isTemplate && method === "POST"
                    ? "Spara mall"
                    : isTemplate && method === "PATCH"
                    ? "Uppdatera mall"
                    : "Spara dokument"
                }
                clicked={attemptSubmit}
              />
            </SC.ButtonsWrapper>
          </SC.InnerContainer>
        </SC.BottomBarContainer>
      )}
    </>
  );
}

export default EditableDocBottomBar;
