import * as React from "react";
import * as SC from "./styles";
import { useSpring, config } from "@react-spring/core";
import useScreenSize from "../../../../hooks/useScreenSize";

export default ({ children, isMain }) => {
  const { isMobile } = useScreenSize();

  const { main } = useSpring({
    config: { duration: 300, ...config.gentle },
    from: { main: 0 },
    main: isMain ? 1 : 0,
  });

  return (
    <SC.FlowFormChapterDescription
      style={{
        opacity: isMobile ? 1 : main.to([0, 1], [0, 1]),
      }}
    >
      {children}
    </SC.FlowFormChapterDescription>
  );
};
