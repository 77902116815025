import * as React from "react";
import { useParams } from "react-router-dom";

// store, state
import { buildQueryString, useFormInstanceField } from "../../../../store/base";
import { fetchMethodFromKind } from "../../../../store/utils";
import { NonNestedSelect, Select } from "../Fields";

export default React.memo(
  ({
    storeName,
    method,
    ctFieldKey,
    idFieldKey,
    realEstateIds,
    selectDescription,
  }) => {
    const selectedCt = useFormInstanceField({
      storeName,
      fieldKey: ctFieldKey,
    });
    const retrievedFetchMethod = fetchMethodFromKind(selectedCt, true);

    const querystring = buildQueryString({ realestate_ids: realEstateIds });
    const fetchMethod = retrievedFetchMethod
      ? () => retrievedFetchMethod(querystring)
      : undefined;

    return (
      <>
        <Select
          title="Koppla till objekt av typ"
          storeName={storeName}
          fieldKey={ctFieldKey}
          method={method}
          description={selectDescription}
        />

        {!!fetchMethod ? (
          <NonNestedSelect
            title="Koppla till objekt"
            storeName={storeName}
            method={method}
            fieldKey={idFieldKey}
            fetchMethod={fetchMethod}
            setIdOnly={true}
          />
        ) : null}
      </>
    );
  }
);
