import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { cloneDeep } from "lodash";

import {
  constants,
  addCurrentObject,
  setRows,
  getCurrentRows,
} from "../../../store/whitelistPerms";
import DetailPermsTable from "../../DetailPermsTable/DetailPermsTable";
import WhiteListTable from "../../Details/Account/UserGroups/WhiteListTable";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { PrimaryButton } from "../Base/Buttons";
import WhiteListAlternatives from "./WLPComponents/WhiteListAlternatives";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import * as SharedStyles from "../../sharedStyles";
import { updateActiveFormInstance } from "../../../store/base";
import { InfoBox } from "../../Displays";

export default ({ group, isLoading, disabled, hideTitle, fromForm }) => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const storeName = constants.STORE_NAME;
  const current = useSelector((state) => state.whitelistPerms?.current);
  const [detailPermOpen, setDetailPermOpen] = React.useState(false);
  const [contentType, setContentType] = React.useState("");
  const [selectorOpen, setSelectorOpen] = React.useState(false);
  const [activeActions, setActiveActions] = React.useState(
    GROUP_PERMS_DETAIL_MAP
  );

  const openModalAndSetContentType = (ct) => {
    const storeClone = cloneDeep(current);
    setContentType(ct);
    const activeContentType = storeClone?.[ct];
    if (activeContentType) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            activeContentType,
          },
        })
      );
    } else {
      dispatch(
        addCurrentObject({
          groupId,
          contentType: ct,
        })
      );
    }
    setDetailPermOpen(true);
  };

  const handleDetailPermToggled = (obj, action) => {
    const rowClone = cloneDeep(current?.[contentType]);

    const match = rowClone.find((r) => r.object_id === obj.object_id);
    switch (action) {
      case "VIEW":
        match.operations.view = !match.operations.view;
        break;
      case "CHANGE":
        match.operations.change = !match.operations.change;
        break;
      case "DELETE":
        match.operations.delete = !match.operations.delete;
        break;

      default:
        return;
    }

    dispatch(setRows({ newRows: rowClone, contentType }));
  };

  const updateActiveActionsFromGroup = () => {
    let matches = [];
    group.permissions.forEach((p) => {
      const [action, can, perm, rest] = p.split("_");

      if (
        WHITELIST_CONTENT_TYPES.includes(`${perm}${rest ? `_${rest}` : ""}`) &&
        (p.includes("change") || p.includes("delete") || p.includes("view"))
      ) {
        if (perm === "baseobject") {
          BASEOBJECT_MAP.forEach((b) => matches.push(`${action}_can_${b}`));
        } else if (perm === "reporterrand") {
          matches.push(`${action}_can_reporterrandsetting`);
          matches.push(p);
        } else if (perm === "inspectionerrand") {
          matches.push(`${action}_can_inspectionerrandsetting`);
          matches.push(p);
        } else if (perm === "roundingerrand") {
          matches.push(`${action}_can_roundingerrandsetting`);
          matches.push(p);
        } else if (perm === "goverrand") {
          matches.push(`${action}_can_goverrandsetting`);
          matches.push(p);
        } else {
          matches.push(p);
        }
      }
    });

    const actionsClone = cloneDeep(activeActions);

    matches.forEach((m) => {
      let [action, can, contentType] = m.split("_");
      actionsClone[contentType][action] = action === "view" ? false : true;
    });

    setActiveActions(actionsClone);
  };

  const detailPermsHeaders = ["Objektsnamn", "Visa", "Ändra", "Ta bort"];
  let errorRowIndexes = [];

  const detailPermsRows = current?.[contentType];

  React.useEffect(() => {
    if (group) {
      updateActiveActionsFromGroup();
    }
  }, [group]);

  React.useEffect(() => {
    if (!fromForm && contentType) {
      dispatch(getCurrentRows({ groupId, contentType }));
    }
  }, [contentType]);

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitle>
          Detaljbehörigheter för{" "}
          {group ? group.name : " gruppen som precis blev skapad"}
        </OverviewTitle>
      </OverviewTitleWrapper>
      {group?.permissions?.length ? (
        <>
          <WhiteListAlternatives
            group={group}
            openDetailModal={openModalAndSetContentType}
            readableTitle={getReadableString}
            current={current}
            whiteListContentTypes={WHITELIST_CONTENT_TYPES}
            baseobjectMap={BASEOBJECT_MAP}
            // disabled={disabled}
          />

          <StandardModal
            isOpen={detailPermOpen}
            closeFunction={() => setDetailPermOpen(false)}
            withActionBar
            actionBarCancelTitle="Stäng"
            modalInModal
          >
            <SharedStyles.DetailPageBox>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "12px",
                }}
              >
                {!hideTitle && (
                  <OverviewTitle>
                    {getReadableString(contentType)}
                  </OverviewTitle>
                )}
                {!disabled && (
                  <PrimaryButton
                    title="Hantera objekt"
                    clicked={() => setSelectorOpen(true)}
                    disabled={disabled}
                  />
                )}

                {isLoading && <OverlaySpinner />}
              </div>
              <InfoBox
                title="OBS"
                text={`Om gruppen saknar behörighet att t.ex ändra, så kommer "Ändra" vara inaktiverad. Se därför till att slå på motsvarande behörighet om ett specifikt objekt ska tillåtas.`}
              />

              <WhiteListTable
                contentType={contentType}
                groupId={groupId}
                isOpen={selectorOpen}
                closeFunction={setSelectorOpen}
              />

              <DetailPermsTable
                extraContainerStyles={{ marginTop: 20 }}
                headers={detailPermsHeaders}
                rows={detailPermsRows}
                loading={false}
                errorRowIndexes={errorRowIndexes}
                handleDetailPermToggled={handleDetailPermToggled}
                activeActions={activeActions}
                contentType={contentType}
                disabled={disabled}
              />
            </SharedStyles.DetailPageBox>
          </StandardModal>
        </>
      ) : (
        <div>
          Inga valda behörigheter hittades, med andra ord är det inte möjligt
          att slå på specifika objektsbehörigheter för den här gruppen. Klicka
          på "Spara och gå vidare" för att gå till sista steget.
        </div>
      )}
    </>
  );
};

const getReadableString = (contentType) => {
  switch (contentType) {
    case "editabledoc":
      return "Avtalsmallar";
    case "basicdoc":
      return "Dokument";
    case "company":
      return "Företag";
    case "customercontract":
      return "Förvaltningsavtal";
  }
};

const GROUP_PERMS_DETAIL_MAP = {
  editabledoc: {
    view: false,
    change: false,
    delete: false,
  },

  basicdoc: {
    view: false,
    change: false,
    delete: false,
  },
  company: {
    view: false,
    change: false,
    delete: false,
  },
};

const WHITELIST_CONTENT_TYPES = [
  "baseobject",
  "editabledoc",
  "basicdoc",
  "company",
];

const BASEOBJECT_MAP = ["basicdoc"];
