import * as React from "react";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => {
  const cols = [
    {
      Header: "Titel",
      accessor: "str_representation",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
