import * as React from "react";

// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./listDefs";

// store, state
import {
  useBasicDocPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/basicDocs";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  withPersistantSortBy,
  withPersistantGlobalFilter,
}) => {
  const storeName = constants.STORE_NAME;

  const filterInstructions = {
    title: { operator: "icontains" },
    comment: { operator: "icontains" },
  };

  const exportParser = {
    category: (data) => data.category_display,
  };
  const exportExclude = ["content_type"];

  const columns = React.useMemo(
    () => columnDefs(),
    [persistantFilterMethod, persistantQueryString]
  );
  const fetchAllTreshold = 25;

  // not filterable on backend, but we want to enable filtering on it
  const forceFrontendColumns = [
    "state",
    "leasecontracts",
    "othercontracts",
    "floors",
  ];

  return (
    <BaseTable
      storeName={storeName}
      columns={columns}
      persistantQueryString={persistantQueryString}
      persistantFilterMethod={persistantFilterMethod}
      paginationMethod={useBasicDocPagination}
      filterInstructions={filterInstructions}
      fetchAllTreshold={fetchAllTreshold}
      filterAction={performFilter}
      detailUrlMethod={(obj) => detailUrl({ id: obj.id })}
      toggleFormCallback={toggleFormCallback}
      title={hideTitle ? undefined : "Dokument"}
      forceFrontendColumns={forceFrontendColumns}
      exportParser={exportParser}
      exportExclude={exportExclude}
      exportSheetController={exportSheetController}
      {...{
        hideFilters,
        hideSearch,
        hideExport,
        withPersistantSortBy,
        withPersistantGlobalFilter,
      }}
    />
  );
};
