import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import { constants } from "../../../store/editabledocs";
import { DoubleFieldWrapper } from "../../Forms/Base/Chapters/styles";
import {
  Checkbox,
  NumberInput,
  Select,
  TextField,
  TextInput,
} from "../../Forms/Base/Fields";

export default ({ method, isTemplate }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const invitationMessage = useFormInstanceField({
    storeName,
    fieldKey: "invitation_message",
  });
  const confirmationMessage = useFormInstanceField({
    storeName,
    fieldKey: "confirmation_message",
  });

  // set SMS messages to match email messages
  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          sms_invitation_message: invitationMessage,
          sms_confirmation_message: confirmationMessage,
        },
      })
    );
  }, [invitationMessage, confirmationMessage]);

  return (
    <>
      {isTemplate ? (
        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: 310, marginRight: 12 }}
            title="Namn på mall"
            storeName={storeName}
            fieldKey="title"
            method={method}
          />

          <Select
            extraStyles={{ minWidth: 310 }}
            title="Kategori"
            storeName={storeName}
            fieldKey="category"
            method={method}
          />
        </DoubleFieldWrapper>
      ) : (
        <TextInput
          title="Namn på dokument"
          description="Detta namn kommer vara synligt för alla parter som får dokumentet"
          storeName={storeName}
          fieldKey="title"
          method={method}
        />
      )}

      <DoubleFieldWrapper>
        <NumberInput
          title="Antal dagar att signera"
          description="Ange hur många dagar parterna har på sig att signera avtalet innan signeringen misslyckas"
          storeName={storeName}
          fieldKey={"days_to_sign"}
          placeholder="90 dagar är standard om inget anges"
          method={method}
          extraStyles={{ minWidth: 310, marginRight: 12 }}
        />
        <NumberInput
          title="Påminnelse skickas efter X dagar"
          storeName={storeName}
          fieldKey={"days_to_remind"}
          description="Om inte parten har signerat kommer en påminnelse automatiskt att skickas ut efter X antal dagar"
          placeholder="Ingen påminnelse skickas om inget anges"
          method={method}
          extraStyles={{ minWidth: 310 }}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextField
          title="Inbjudningsmeddelande"
          description="Detta meddelande kommer att skickas i E-postmeddelandet när parten bjuds in till att signera om E-post är valt som leveranssätt för parten"
          storeName={storeName}
          fieldKey={"invitation_message"}
          method={method}
          extraStyles={{ minWidth: 310, maxWidth: 310, marginRight: 12 }}
        />
        <TextField
          title="Bekräftelsemeddelande"
          description="Detta meddelande kommer att skickas i E-postmeddelandet när parten informeras om att signeringen är avslutad om E-post är valt som leveranssätt för parten"
          storeName={storeName}
          fieldKey={"confirmation_message"}
          method={method}
          extraStyles={{ minWidth: 310, maxWidth: 310 }}
        />
      </DoubleFieldWrapper>

      {isTemplate && (
        <Checkbox
          title="Detta är en publik mall"
          storeName={storeName}
          fieldKey={"is_public"}
          method={method}
        />
      )}
    </>
  );
};
