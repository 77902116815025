export const detailUrl = ({ id }) => {
  return `/account/groups-and-users/user/${id}`;
};

export const overviewUrl = () => {
  return `/account/groups-and-users`;
};

export const constructStrRep = (obj) => {
  return (
    obj?.str_representation ||
    obj?.corporate_name ||
    obj?.last_name ||
    obj?.first_name ||
    obj?.legal_id ||
    obj?.email ||
    "Användare"
  );
};
