export default [
  {
    title: "Betalningsvillkor",
    key: "DEBT_SETTING",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "contract_pay_last_day_month",
      "contract_payment_term_days",
    ],
  },
  {
    title: "Påminnelser",
    key: "REMINDERS",
    hasError: false,
    fieldKeys: [
      "use_reminders",
      "send_reminder_after_days",
      "reminder_term_days",
      "reminder_fee",
      "reminder_grace_days",
      "reminder_grace_amount",
    ],
  },
  {
    title: "Kravhantering",
    key: "DEBT_COLLECTION",
    hasError: false,
    fieldKeys: [
      "use_debt_collection",
      "send_to_debt_after_days",
      "debt_start_mode",
      "debt_term_days",
      "number_of_reminders",
    ],
  },
  {
    title: "Dröjsmålsränta",
    key: "INTEREST",
    hasError: false,
    fieldKeys: [
      "interest_type",
      "interest_percentage",
      "reason_higher_interest",
      "interest_start_due_date",
    ],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["id"],
  },
];
