import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { RadioGroup, TextField, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingSettings";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <FormAreaTitle>Aviseringsinställning</FormAreaTitle>

      <TextInput
        title="Titel på inställning"
        {...{ storeName, method, fieldKey: "title" }}
      />

      <TextField
        title="Meddelande på fakturor"
        {...{ storeName, method, fieldKey: "message" }}
      />

      <RadioGroup
        title="Kräv godkännande av kostnader för avisering"
        description="Om denna inställning är aktiv krävs det att en användare som har tillgång till aviseringsmodulen (Pigello Hyra) godkänner ändringar eller tillägg av kostnader och rabatter. Exempel: En användare som har rätt att redigera avtalet lägger till en rabatt på 200 SEK. Denna rabatt kommer ej att tas hänsyn till i aviseringen innan en användare som har tillgång till Pigello Hyra har godkänt rabatten."
        {...{ storeName, method, fieldKey: "require_cost_accept" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      <RadioGroup
        title="Sammanför automatiskt fakturor till samma kund"
        description="Om detta är aktivt kommer fakturor som genereras med denna inställning automatiskt slås ihop med andra icke-attesterade fakturor till samma kund."
        {...{ storeName, method, fieldKey: "auto_merge" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={true}
      />

      <RadioGroup
        title="Autoattestera fakturor"
        description="Om detta är aktivt kommer alla fakturor som genereras att automatiskt attesteras och bokföras så fort de skapas."
        {...{ storeName, method, fieldKey: "auto_attest" }}
        forceHorizontalToolTipPlacement="right"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />
    </>
  );
};
