import * as React from "react";

import { OverviewSubtitle } from "../../components/Details/OverviewInfo/styles";
import IntegrationsGrid from "../../components/Integrations/IntegrationsGrid";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

export default function IntegrationsOverview() {
  const renderInfoPills = () => (
    <OverviewSubtitle style={{ color: "white" }}>
      Här listas alla integrationer som kan kopplas mot Pigello.
    </OverviewSubtitle>
  );
  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu title="Integrationer" {...{ renderInfoPills }} />

      <IntegrationsGrid />
    </DetailLayoutWrapper>
  );
}
