import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  SecondaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import LeaseSelectTable from "../../../components/Lists/ManagementContracts/SelectTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { massUpdateInvoicingStatus } from "../../../store/invoicingCustomer";
import { MASS_INVOICING_UPDATE_ACTIONS } from "../../../store/invoicingCustomer/store/actions";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { overviewUrl } from "../../../store/managementContracts";
import { useHistory } from "react-router";

const UPDATE_TYPES = {
  ACTIVATE: 0,
  INACTIVATE: 1,
};

export default function HandleMassInvoicingCustomerContract() {
  const dispatch = useDispatch();
  const [updateType, setUpdateType] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [confirmationTitle, setConfirmationTitle] = React.useState("");
  const [confirmationFunction, setConfirmationFunction] = React.useState(null);
  const { push } = useHistory();

  const activePersistantQueryString = buildQueryString({
    customer_invoicing_active: true,
  });

  const inactivePersistantQueryString = buildQueryString({
    customer_invoicing_active: false,
  });

  const activeFilter = (obj) => {
    return obj.customer_invoicing_active;
  };
  const inactiveFilter = (obj) => {
    return obj.customer_invoicing_active === false;
  };

  const activateInvoicings = (selected) => {
    setLoading(true);

    const ids = selected
      .map((s) => s.original.customer_invoicing?.id)
      ?.filter((id) => !!id);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.ACTIVATE,
        ids,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för förvaltningsavtalen aktiverades",
              description: `Eventuella fel syns under "Fakturering" och de berörda förvaltningsavtan`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  const inActivateInvoicings = (selected) => {
    setLoading(true);

    const ids = selected
      .map((s) => s.original.customer_invoicing?.id)
      ?.filter((id) => !!id);

    dispatch(
      massUpdateInvoicingStatus({
        action: MASS_INVOICING_UPDATE_ACTIONS.PAUSE,
        ids,
        successCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Avisering för förvaltningsavtalen pausades",
              description: `Eventuella fel syns under "Fakturering" och de berörda förvaltningsavtalen`,
            })
          );
          push(overviewUrl());
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Uppdateringen kunde ej genomföras",
              description: `Kontakta Pigello om problemet kvarstår`,
            })
          );
        },
      })
    );
  };

  return (
    <DetailInnerWrapper>
      <ConfirmationModal
        isOpen={!!confirmationFunction}
        closeFunction={() => setConfirmationFunction(null)}
        title={confirmationTitle}
        acceptCallback={confirmationFunction}
      />

      {updateType === null && (
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>
              Masshantering av aviseringsstatus för förvaltningsavtal
            </OverviewTitle>
          </OverviewTitleWrapper>

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Vilken typ av uppdatering ska genomföras?
              </OverviewTitle>
            </OverviewTitleWrapper>

            <BodyText style={{ maxWidth: 800 }}>
              Välj vilken typ av uppdatering som ska utföras. Vid uppdatering av
              status för flera förvaltningsavtal samtidigt så kommer eventuella
              fel hamna under "Felmeddelanden" under sidan "Fakturering" samt
              inne på det specifika avtalets aviseringsflik.{" "}
            </BodyText>

            <TextButton
              extraStyle={{ marginTop: 24 }}
              title="Aktivera avisering för förvaltningsavtal med pausad avisering"
              iconType="done"
              iconPlacement="right"
              clicked={() => setUpdateType(UPDATE_TYPES.ACTIVATE)}
            />

            <TextButton
              extraStyle={{ marginTop: 24 }}
              title="Pausa avisering för förvaltningsavtal med aktiv avisering"
              iconType="pause"
              iconPlacement="right"
              clicked={() => setUpdateType(UPDATE_TYPES.PAUSE)}
            />
          </InnerBox>
        </DetailPageBox>
      )}

      {updateType === UPDATE_TYPES.ACTIVATE && (
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            title="Gå tillbaka till val av uppdatering"
            iconType="arrow-back"
            clicked={() => setUpdateType(null)}
            extraStyle={{ marginBottom: 24 }}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper style={{ marginRight: 48 }}>
              <OverviewTitle small>
                Aktivera avisering för förvaltningsavtal med pausad avisering
              </OverviewTitle>
              <OverviewSubtitle>
                Välj de förvaltningsavtal du vill aktivera den pausade
                aviseringen för. Tryck på <strong>"Aktivera avisering"</strong>{" "}
                under listan för att spara. Tryck på{" "}
                <strong>"Aktivera alla"</strong> för att aktivera avisering för
                alla förvaltningsavtal med pausad avisering.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <LeaseSelectTable
            persistantFilterMethod={inactiveFilter}
            persistantQueryString={inactivePersistantQueryString}
            hideColumns
            hideTitle
            hideExport
            onSubmit={activateInvoicings}
            submitLabel={"Aktivera pausad avisering"}
          />
        </DetailPageBox>
      )}

      {updateType === UPDATE_TYPES.PAUSE && (
        <DetailPageBox>
          {loading && <OverlaySpinner />}
          <TextButton
            title="Gå tillbaka till val av uppdatering"
            iconType="arrow-back"
            clicked={() => setUpdateType(null)}
            extraStyle={{ marginBottom: 24 }}
          />

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper style={{ marginRight: 48 }}>
              <OverviewTitle small>
                Pausa avisering för förvaltningsavtal med aktiv avisering
              </OverviewTitle>
              <OverviewSubtitle>
                Välj de förvaltningsavtal du vill pausa den aktiva aviseringen
                för. Tryck på <strong>"Pausa avisering"</strong> under listan
                för att spara. Tryck på <strong>"Pausa alla"</strong> för att
                pausa avisering för alla förvaltningsavtal med aktiv avisering.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <LeaseSelectTable
            persistantFilterMethod={activeFilter}
            persistantQueryString={activePersistantQueryString}
            hideColumns
            hideTitle
            hideExport
            onSubmit={inActivateInvoicings}
            submitLabel={"Pausa aktiv avisering"}
          />
        </DetailPageBox>
      )}
    </DetailInnerWrapper>
  );
}
