import * as React from "react";
import { constants } from "../../../store/editabledocs";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import useKeyPress from "../../../hooks/useKeyPress";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import DocumentSelector from "../DocumentSelector/DocumentSelector";
import * as SC from "./styles";
import FieldSet from "../Fields/FieldSet";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function EditableDocPDF({ method, contract, isCancelledDoc }) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const pageKey = `_page`;
  const numberOfPagesKey = `_pageNo`;
  const [pageRenderReady, setPageRenderReady] = React.useState(false);
  const [docRenderReady, setDocRenderReady] = React.useState(false);
  const [pageWidth, setPageWidth] = React.useState(0);

  const pageDownPress = useKeyPress("ArrowLeft");
  const pageUpPress = useKeyPress("ArrowRight");

  const outerWrapperRef = React.useRef();

  const editableDoc = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const isSigned = editableDoc?.status && editableDoc?.status !== "preparation";

  const editableDocPageNumber = useFormInstanceField({
    storeName,
    fieldKey: pageKey,
  });
  const editableDocPageAmount = useFormInstanceField({
    storeName,
    fieldKey: pageKey,
  });
  const url = useFormInstanceField({
    storeName,
    fieldKey: "doc.get",
  });

  const b64Prefix = "data:application/pdf;base64,";
  let b64 = editableDoc?.docData_;
  if (b64 && !b64?.includes(b64Prefix)) {
    b64 = b64Prefix + b64;
  }
  const data = b64 || url;
  const hasData = Boolean(data);

  const setPageNumber = (pageNumber) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [pageKey]: pageNumber,
        },
      })
    );
  };

  React.useEffect(() => {
    if (!editableDocPageNumber) {
      setPageNumber(1);
    }
  }, []);

  React.useLayoutEffect(() => {
    if (outerWrapperRef?.current?.offsetWidth) {
      setPageWidth(outerWrapperRef.current.offsetWidth);
    }
  }, [outerWrapperRef?.current, hasData]);

  React.useEffect(() => {
    setPageRenderReady(false);
    setDocRenderReady(false);
  }, [data]);

  const onLoadSuccess = ({ numPages }) => {
    setDocRenderReady(true);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [numberOfPagesKey]: numPages,
        },
      })
    );
  };

  React.useEffect(() => {
    if (!data) return;
    if (pageDownPress && editableDocPageNumber > 1) {
      setPageNumber(editableDocPageNumber - 1);
    }

    if (pageUpPress && editableDocPageNumber < editableDocPageAmount) {
      setPageNumber(editableDocPageNumber + 1);
    }
  }, [pageDownPress, pageUpPress]);

  if (!hasData) {
    return (
      <>
        <SC.DocumentSelectorWrapper>
          <DocumentSelector
            {...{
              storeName,
              contract,
              isCancelledDoc,
              method,
              editableDocFieldKey: "",
              editableDoc,
            }}
          />
        </SC.DocumentSelectorWrapper>
      </>
    );
  }

  return (
    <div ref={outerWrapperRef}>
      <Document
        width={pageWidth}
        file={data}
        onLoadProgress={() => setDocRenderReady(false)}
        onLoadSuccess={onLoadSuccess}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {editableDocPageAmount != null &&
          pageRenderReady &&
          docRenderReady ? (
            <FieldSet
              disabled={isSigned}
              storeName={storeName}
              currentPage={editableDocPageNumber}
              method={method}
            />
          ) : null}
          <Page
            onLoadProgress={() => setPageRenderReady(false)}
            onLoadSuccess={() => setPageRenderReady(true)}
            width={pageWidth}
            pageNumber={editableDocPageNumber}
          />
        </div>
      </Document>
    </div>
  );
}

export default EditableDocPDF;
