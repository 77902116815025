import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../../components/Displays";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import InvoicingDebtSettingModalForm from "../../../components/Forms/InvoicingDebtSetting/ChapterForm/ModalForm";
import StandardModal from "../../../components/Modals/StandardModal";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import DebtSettingSummary from "../../../components/Summaries/DebtSettingSummary";
import { useInvoicingDebtSetting } from "../../../store/invoicingDebtSettings";

import InvoiceSettingsTable from "../../../components/Lists/InvoicingSettings/FullTable";
import { buildQueryString } from "../../../store/base";

function InvoicingDebtSettingOverview() {
  const { settingId } = useParams();
  const [debtSetting] = useInvoicingDebtSetting(settingId);
  const [editOpen, setEditOpen] = React.useState(false);
  const [warnEditOpen, setWarnEditOpen] = React.useState(false);

  const persistantQueryString = buildQueryString({
    debt_setting: settingId,
  });
  const persistantFilterMethod = (obj) => obj.debt_setting?.id == settingId;

  return (
    <>
      <StandardModal
        isOpen={warnEditOpen}
        title="Information om redigering"
        withActionBar
        saveFunction={() => {
          setEditOpen(true);
          setWarnEditOpen(false);
        }}
        closeFunction={() => setWarnEditOpen(false)}
        actionBarAcceptIsWarning
        actionBarAcceptTitle="Redigera ändå"
      >
        <InfoBox
          title="OBS"
          text={
            "Ändringar av denna inställning kommer att påverka aviseringsinställningarna som använder sig av den. Detta innebär att avtal och objekt som använder sig av de påverkade aviseringsinställningarna också påverkas. Kontrollera att detta är önskvärt."
          }
          boxTheme="yellow"
        />

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Används av</OverviewTitle>
          </OverviewTitleWrapper>

          <InvoiceSettingsTable
            hideTitle
            hideColumns
            {...{ persistantFilterMethod, persistantQueryString }}
          />
        </DetailPageBox>
      </StandardModal>

      <InvoicingDebtSettingModalForm
        method="PATCH"
        id={settingId}
        instance={debtSetting}
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>

            <PrimaryButton
              title="Redigera"
              clicked={() => setWarnEditOpen(true)}
            />
          </OverviewTitleWrapper>

          <DebtSettingSummary {...{ setting: debtSetting }} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export default InvoicingDebtSettingOverview;
