import { useDispatch, useSelector } from "react-redux";

import constants from "../store/constants";

import { CLEANED_AUTOGIRO, CLEANED_FORTNOX, CLEANED_PE } from "./instructions";

export function usePEForm(method, open) {
  const dispatch = useDispatch();

  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!open) return;

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: CLEANED_PE,
        name: "integration_pe",
        method: method,
      },
    });
  }

  return hasForm;
}

export function useAutogiroForm(method, open) {
  const dispatch = useDispatch();

  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!open) return;

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: CLEANED_AUTOGIRO,
        name: "integration_autogiro",
        method: method,
      },
    });
  }

  return hasForm;
}

export function useFortnoxForm(method, open) {
  const dispatch = useDispatch();

  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!open) return;

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: CLEANED_FORTNOX,
        name: "integration_fortnox",
        method: method,
      },
    });
  }

  return hasForm;
}
