import * as React from "react";
// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TableSelect } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingCompany";

import {
  useInvoicingCustomDimensionPagination,
  performFilter as dimensionPerformFilter,
  constructStrRep as dimensionConstructStrRep,
  constants as dimensionConstants,
} from "../../../../../store/invoicingCustomDimensions";
import dimensionListDefs from "../../../../Lists/InvoicingCustomDimensions/listDefs";

import {
  useInvoicingCustomDimensionEntriesPagination,
  performFilter as dimensionEntryPerformFilter,
  constructStrRep as dimensionEntryConstructStrRep,
  constants as dimensionEntryConstants,
} from "../../../../../store/invoicingCustomDimensionEntries";
import dimensionEntryListDefs from "../../../../Lists/InvoicingCustomDimensionEntries/listDefs";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InfoBox } from "../../../../Displays";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const selectedDimension = useFormInstanceField({
    storeName,
    fieldKey: "_interal_dimension.id",
  });
  const selectedDimensionEntry = useFormInstanceField({
    storeName,
    fieldKey: "additional_dimension.dimension_entry.id",
  });

  const selectedOverrideDimension = useFormInstanceField({
    storeName,
    fieldKey: "_interal_payment_dimension_override.id",
  });
  const selectedOverrideDimensionEntry = useFormInstanceField({
    storeName,
    fieldKey: "payment_dimension_override.dimension_entry.id",
  });

  const persistantQueryString = buildQueryString({
    dimension: selectedDimension,
  });
  const persistantOverrideQueryString = buildQueryString({
    dimension: selectedOverrideDimension,
  });

  const persistantFilterMethod = (obj) =>
    obj.dimension?.id == selectedDimension;

  const persistantOverrideFilterMethod = (obj) =>
    obj.dimension?.id == selectedOverrideDimension;

  const clearDimension = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          additional_dimension: null,
        },
      })
    );
  };

  const clearOverrideDimension = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          payment_dimension_override: null,
        },
      })
    );
  };

  return (
    <>
      <FormAreaTitle>Detaljerade bokföringsinställningar</FormAreaTitle>

      <InnerBox style={{ marginBottom: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Anpassad bokföringsdimension för bolag
          </OverviewTitle>
        </OverviewTitleWrapper>
        {!selectedDimensionEntry && (
          <TableSelect
            storeName={storeName}
            tableStoreName={dimensionConstants.STORE_NAME}
            method={method}
            performFilter={dimensionPerformFilter}
            fieldKey="_interal_dimension"
            paginationMethod={useInvoicingCustomDimensionPagination}
            listDefs={dimensionListDefs}
            method={method}
            fieldTitle="Anpassad dimension"
            title={"Välj anpassad dimension..."}
            constructStrRep={dimensionConstructStrRep}
            modalInModal
          />
        )}

        {(selectedDimension || selectedDimensionEntry) && (
          <TableSelect
            storeName={storeName}
            tableStoreName={dimensionEntryConstants.STORE_NAME}
            method={method}
            performFilter={dimensionEntryPerformFilter}
            fieldKey="additional_dimension.dimension_entry"
            paginationMethod={useInvoicingCustomDimensionEntriesPagination}
            listDefs={dimensionEntryListDefs}
            method={method}
            fieldTitle="Instans av dimension"
            title={"Välj instans av dimension..."}
            constructStrRep={dimensionEntryConstructStrRep}
            persistantFilterMethod={persistantFilterMethod}
            persistantQueryString={persistantQueryString}
            modalInModal
          />
        )}

        {selectedDimensionEntry && (
          <TextButton
            title="Ta bort den valda dimensionen"
            iconType="sync"
            iconPlacement="right"
            red
            clicked={clearDimension}
          />
        )}
      </InnerBox>

      <hr />
      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Anpassad bokföringsdimension för överskrivning av dimension vid
            inbetalningar
          </OverviewTitle>
        </OverviewTitleWrapper>

        <InfoBox
          title="Överskrivning av dimension vid inbetalningar"
          text='Den valda dimensionen kommer att skriva över den anpassade dimensionen för t.ex. kunder om transaktionen sker på ett av de konton som är listade som inbetalningskonton under "Bokföringsinställningar" -> "Betalsätt". Detta sker dock enbart om SIE-ID:t för dimensionerna överrensstämmer.'
        />

        {!selectedOverrideDimensionEntry && (
          <TableSelect
            storeName={storeName}
            tableStoreName={dimensionConstants.STORE_NAME}
            method={method}
            performFilter={dimensionPerformFilter}
            fieldKey="_interal_payment_dimension_override"
            paginationMethod={useInvoicingCustomDimensionPagination}
            listDefs={dimensionListDefs}
            method={method}
            fieldTitle="Anpassad dimension för överskrivning"
            title={"Välj anpassad dimension..."}
            constructStrRep={dimensionConstructStrRep}
            modalInModal
          />
        )}

        {(selectedOverrideDimension || selectedOverrideDimensionEntry) && (
          <TableSelect
            storeName={storeName}
            tableStoreName={dimensionEntryConstants.STORE_NAME}
            method={method}
            performFilter={dimensionEntryPerformFilter}
            fieldKey="payment_dimension_override.dimension_entry"
            paginationMethod={useInvoicingCustomDimensionEntriesPagination}
            listDefs={dimensionEntryListDefs}
            method={method}
            fieldTitle="Instans av dimension"
            title={"Välj instans av dimension..."}
            constructStrRep={dimensionEntryConstructStrRep}
            persistantFilterMethod={persistantOverrideFilterMethod}
            persistantQueryString={persistantOverrideQueryString}
            modalInModal
          />
        )}

        {selectedOverrideDimensionEntry && (
          <TextButton
            title="Ta bort den valda dimensionen"
            iconType="sync"
            iconPlacement="right"
            red
            clicked={clearOverrideDimension}
          />
        )}
      </InnerBox>
    </>
  );
};
