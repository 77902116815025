import * as React from "react";

// style, design
import { ToolTipCell, DateCell } from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

import CategoryFilter from "./Filters/CategoryFilter";
import SimpleDateFilter from "../Base/CompleteList/Filters/DateFilter";
import { RemoveButton } from "../../Billecta/Invoices/InvoiceComponents/Multiple/styles";

export default (onRemove) => [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Kategori",
    accessor: "category",
    Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
    Filter: CategoryFilter,
    filter: "text",
  },
  {
    Header: "Uppdaterings datum",
    accessor: "inhouse_time",
    Cell: ({ value }) => <DateCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Ta bort",
    id: "remove",
    Cell: ({ row }) => (
      <RemoveButton
        onClick={(e) => {
          e.stopPropagation();
          onRemove(row.original.id);
        }}
      />
    ),
    Filter: SimpleTextFilter,
    filter: "text",
  },
];

//Exporting columDefs as funciton to play nicely with detailPerms logic (components/Details/Account/UserGroups/WhiteListTable.js)

export const editableDocsColumnDefs = () => {
  return [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kategori",
      accessor: "category",
      Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
      Filter: CategoryFilter,
      filter: "text",
    },
    {
      Header: "Uppdaterings datum",
      accessor: "inhouse_time",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
  ];
};
