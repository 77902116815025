import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logout } from "../../../store/app/actions";
import { useNotificationsPaginationCount } from "../../../store/notificationsDisplay";

import { TextButton } from "../../Forms/Base/Buttons";
import Logo from "../Sidebar/Logo";
import * as SC from "./styles";

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const user = useSelector((state) => state.app.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  const [notificationCount, countLoading] =
    useNotificationsPaginationCount(false);

  return (
    <SC.SidebarWrapper>
      <Logo />

      <TextButton
        title="Tillbaka"
        iconType="arrow-back"
        clicked={() => push("/")}
        extraStyle={{
          marginBottom: "8px",
          margin: "20px 20px 0 20px",
        }}
      />
      <SC.SidebarTitle>Inställningar</SC.SidebarTitle>

      <SC.SidebarItems>
        <SC.SidebarItem
          active={window.location.pathname.includes("/account/user")}
          to="/account/user"
        >
          Min profil
        </SC.SidebarItem>
        <SC.SidebarItem
          active={window.location.pathname.includes("/account/notifications")}
          to="/account/notifications"
        >
          Notifikationer {countLoading ? null : `(${notificationCount})`}
        </SC.SidebarItem>
        <SC.SidebarItem
          active={window.location.pathname.includes(
            "/account/groups-and-users"
          )}
          to="/account/groups-and-users"
        >
          Användare & Grupper
        </SC.SidebarItem>

        <SC.SidebarItem
          active={window.location.pathname.includes("/account/terms-gdpr")}
          to="/account/terms-gdpr"
        >
          Villkor & GDPR
        </SC.SidebarItem>

        <SC.LogoutItem onClick={handleLogout}>Logga ut</SC.LogoutItem>
      </SC.SidebarItems>
    </SC.SidebarWrapper>
  );
};
