import * as React from "react";
import PremisesTable from "../PremisesTable/PremisesTable";

const MAPPER = {
  customerinvoicing: "Förvaltningsavtal",
};

const ATTR_MAPPER = {
  auto_attest: "Automatisk attestering",
  auto_merge: "Automatisk sammanslagning",
  interval_setting: "Aviseringsintervall",
  message: "Meddelande på fakturor",
};

export default function UpdateInvoiceSettingSummary({ confirmData }) {
  const [combinedInvoicings, setCombinedInvoicings] = React.useState([]);

  React.useEffect(() => {
    const res = [];
    // lease contracts
    Object.keys(confirmData.customerInvoicing || {}).forEach((k) => {
      res.push({
        content_type: "customerinvoicing",
        id: k,
        str_representation: confirmData.customerInvoicing[k].str_representation,
        attrs: confirmData.customerInvoicing[k].invoicingsetting,
      });
    });

    setCombinedInvoicings(res);
  }, [confirmData]);

  const rows = React.useMemo(() => {
    return combinedInvoicings.map((ci) => [
      `${MAPPER[ci.content_type]} ${ci.str_representation}`,
      ci.attrs.map((a) => ATTR_MAPPER[a]).join(", "),
    ]);
  }, [combinedInvoicings]);

  return (
    <>
      <PremisesTable
        title="Objekt med attribut som ej påverkas"
        headers={["Objekt", "Attribut som ej påverkas av uppdateringen"]}
        rows={rows}
      />
    </>
  );
}
