import * as React from "react";
import { Route } from "react-router-dom";
import FormTopArea from "./TopArea/FormTopArea";

export default ({ component: RouteComponent, path, exact }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormTopArea />
          <RouteComponent {...matchProps} />
        </div>
      )}
    />
  );
};
