import styled, { css } from "styled-components";
import checkIcon from "../../../../assets/svg/check_white.svg";
import crossIcon from "../../../../assets/svg/cross_white.svg";
import { pulseBlue } from "../../../sharedStyles";

export const OnPageFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  min-height: 400px;
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.colors.gray};
  border-radius: 5px;
  padding: 24px;
`;

export const FullScreenFormWrapper = styled.div`
  ${"" /* menu bar height is 49px for now */}
  height: ${(p) => (p.isBareMode ? "100vh" : "calc(100vh - 73px)")};
  width: 100vw;
`;

export const ChaptersWrapper = styled.div`
  ${"" /* formheader height for now */}
  height: 100%;
  overflow-y: auto;
  padding-bottom: 150px;
  min-width: 250px;
  max-width: 250px;
  background-color: ${(p) =>
    p.isFullScreen ? p.theme.colors.gray4 : p.theme.colors.gray1};
  border-right: ${(p) => p.theme.borders.standard};
`;

export const MobileChaptersWrapper = styled.div`
  height: 50px;
  display: flex;
  overflow-x: auto;
  background-color: #fff;
  border-bottom: ${(p) => p.theme.borders.standard};
`;

export const MobilePadding = styled.div`
  @media (${(p) => p.theme.mediaQueries.mobile}) {
    padding: 12px;
    padding-bottom: 84px;
  } ;
`;

export const FormArea = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  position: relative;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    padding: 0;
  }

  ${(p) =>
    p.noPadding &&
    css`
      padding: 0;
    `}
`;

export const FormAreaTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(p) => p.theme.colors.textPrimary};
  margin-bottom: 12px;
`;

export const FormAreaDescription = styled.div`
  font-size: 1rem;
  color: ${(p) => p.theme.colors.textPrimary};
  margin-bottom: 40px;
`;

export const FormContent = styled.div`
  display: flex;
  height: 100%;
`;

export const PdfWrapper = styled.div`
  min-width: 50%;
  height: 100%;
  background-color: #fff;
  border-left: ${(p) => p.theme.borders.standard};
`;

export const StepsButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  padding-top: 24px;

  @media (${(p) => p.theme.mediaQueries.desktop}) {
    & > button {
      margin-right: 12px;
    }
  }

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    flex-direction: column-reverse;

    & > button {
      margin-bottom: 12px;
    }
  }
`;

export const DoubleFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    flex-direction: row;
    padding: 6px;
    align-items: center;
  }
`;

export const ChapterItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    padding: 4px 6px;
    border-radius: 5px;
    background-color: #f1f1f1;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 12px;
    }

    ${(p) =>
      p.isActive &&
      css`
        background-color: ${(p) => p.theme.colors.blueDark};

        & > ${ChapterTitle} {
          color: white;
          font-weight: 600;
        }
      `}

    ${(p) =>
      p.hasError &&
      css`
        background-color: ${(p) => p.theme.colors.red};

        & > ${ChapterTitle} {
          color: white;
          font-weight: 600;
        }
      `}
  }
`;

export const ChapterTitle = styled.div`
  color: ${(p) => (p.isFullScreen ? "#fff" : p.theme.colors.primaryText)};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    font-size: 0.9rem;
  }
`;

export const StatusDot = styled.div`
  min-height: 18px;
  min-width: 18px;

  border: 2px solid ${(p) => p.theme.colors.blue};
  border-radius: 50%;
  margin-right: 12px;
  transition: all 0.2s ease-in-out;

  ${(p) =>
    p.active &&
    css`
      background-color: ${p.theme.colors.blue};
      border-color: ${p.theme.colors.blue};
      animation: ${pulseBlue} 2s infinite;
    `}

  ${(p) =>
    p.done &&
    css`
      background-color: ${p.theme.colors.green};
      border-color: ${p.theme.colors.green};

      background-size: 12px;
      background-position: 1px -1px;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
    `}

    ${(p) =>
    p.hasError &&
    css`
      background-color: ${p.theme.colors.red};
      background-size: 10px;
      background-position: center;
      background-image: url(${crossIcon});
      background-repeat: no-repeat;
      border: 2px solid ${(p) => p.theme.colors.red};
    `}
`;

export const StatusBar = styled.div`
  width: 3px;
  border-radius: 6px;
  height: 14px;
  margin: 4px 7px;
  background-color: ${(p) =>
    p.isFullScreen ? p.theme.colors.blueLight : p.theme.colors.gray2};
`;

export const FormTitleWrapper = styled.div`
  margin-bottom: 24px;
`;

export const FormTitle = styled.div`
  color: #fff;
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
`;
