import * as React from "react";
import { DateCell } from "../../../Displays";
import RelationalTableField from "../../../Forms/Base/FieldComponents/RelationalTableField";
import {
  DatePicker,
  RadioGroup,
  RentField,
  Select,
  TextInput,
} from "../../../Forms/Base/Fields";
import StandardModal from "../../../Modals/StandardModal";
import { toMoneyString } from "../../../utils/stringUtils";
import DetailInfo from "../DetailInfo/DetailInfo";

import ProductNestedChildrenMethod from "../../../Forms/InvoicingProduct/NestedChildren";
import CostCenterNestedChildrenMethod from "../../../Forms/InvoicingCostCenter/NestedChildren";
import ProjectNestedChildrenMethod from "../../../Forms/InvoicingProject/NestedChildren";
import invoicingProductListDefs from "../../../Lists/InvoicingProducts/listDefs";
import invoicingCostCentersListDefs from "../../../Lists/InvoicingCostCenters/listDefs";
import invoicingProjectsListDefs from "../../../Lists/InvoicingProjects/listDefs";

import {
  constants as invoicingProductConstants,
  performFilter as invoicingProductPerformFilter,
  constructStrRep as invoicingProductConstructStrRep,
  useInvoicingProductPagination,
} from "../../../../store/invoicingProducts";
import {
  constants as invoicingCostCenterConstants,
  performFilter as invoicingCostCenterPerformFilter,
  constructStrRep as invoicingCostCenterConstructStrRep,
  useInvoicingCostCenterPagination,
} from "../../../../store/invoicingCostCenters";
import {
  constants as invoicingProjectConstants,
  performFilter as invoicingProjectPerformFilter,
  constructStrRep as invoicingProjectConstructStrRep,
  useInvoicingProjectPagination,
} from "../../../../store/invoicingProjects";
import { useFrequentPermissions } from "../../../../store/base";
import { StatusLabel } from "../../../Lists/Base/CompleteList/styles";
import { TextButton } from "../../../Forms/Base/Buttons";

export default function HandleContractCost({
  costs,
  handleCostIndex,
  closeFunction,
  method,
  contract,
  storeName,
  onUpdate,
  onRemove,
}) {
  const currentCost = costs?.[handleCostIndex];
  const isDiscount = currentCost?.value < 0;

  const [editActive, setEditActive] = React.useState(false);

  const totalArea = contract?.total_area || null;

  // Set new costs to edit
  React.useEffect(() => {
    if (
      handleCostIndex != null &&
      currentCost &&
      (currentCost?.value == null || !currentCost?.title)
    ) {
      setEditActive(true);
    }
  }, [handleCostIndex, currentCost]);

  const { hasBillectaFullPermission, hasBillectaViewPermission } =
    useFrequentPermissions();

  const infoObj = {
    Detaljer: [
      {
        title: "Godkänd för avisering",
        hidden: !hasBillectaViewPermission,
        value: (
          <StatusLabel state={currentCost?.accepted ? 1 : 6}>
            {currentCost?.accepted ? "Godkänd" : "Ej godkänd"}
          </StatusLabel>
        ),
      },
      {
        title: "Typ",
        value: (
          <StatusLabel state={isDiscount ? 3 : 1}>
            {isDiscount ? "Rabatt" : "Kostnad"}
          </StatusLabel>
        ),
      },
      {
        title: "Beskrivning",
        value: currentCost?.title,
      },
      {
        title: "Produkt",
        value:
          currentCost?.product?.str_representation ||
          currentCost?.product?.title ||
          "-",
      },
      {
        title: "Projekt",
        value:
          currentCost?.project?.str_representation ||
          currentCost?.project?.title ||
          "-",
      },

      {
        title: "Kostnadsställe",
        value:
          currentCost?.cost_center?.str_representation ||
          currentCost?.cost_center?.title ||
          "-",
      },

      {
        title: "SEK/månad",
        value: toMoneyString(currentCost?.value),
      },
      {
        title: "Börjar aviseras för",
        value: currentCost?.start_date ? (
          <DateCell date={currentCost?.start_date} />
        ) : (
          "Med avtalet"
        ),
      },
      {
        title: "Slutar aviseras för",
        value: currentCost?.end_date ? (
          <DateCell date={currentCost?.end_date} />
        ) : (
          "Med avtalet"
        ),
      },
    ],
  };

  infoObj["Detaljer"].push({
    title: "Indexuppräkning",
    value: (
      <StatusLabel state={currentCost?.indexate ? 1 : 3}>
        {currentCost?.indexate ? "Aktiv" : "Inaktiv"}
      </StatusLabel>
    ),
  });

  return (
    <StandardModal
      isOpen={handleCostIndex !== null}
      closeFunction={editActive ? () => setEditActive(false) : closeFunction}
      title={`${editActive ? "Redigera" : "Hantera"} ${
        isDiscount ? "Rabatt" : "Kostnad"
      }`}
      withActionBar
      actionBarAcceptTitle={editActive ? "Klar" : "Redigera"}
      actionBarCancelTitle={editActive ? "Tillbaka" : "Stäng"}
      saveFunction={editActive ? onUpdate : () => setEditActive(true)}
    >
      {!editActive ? (
        <>
          <DetailInfo infoObj={infoObj} />

          <TextButton
            title="Radera rad"
            clicked={onRemove}
            red
            iconType="close"
            iconPlacement="right"
            extraStyle={{ marginTop: 24 }}
          />
        </>
      ) : (
        <>
          {hasBillectaFullPermission && (
            <RadioGroup
              title="Godkänd för avisering"
              description="Kostnader som inte är godkända för avisering kommer ej att aviseras för"
              storeName={storeName}
              method={method}
              options={[
                { label: "Godkänd", value: true },
                { label: "Ej godkänd", value: false },
              ]}
              fieldKey={`cost_set[${handleCostIndex}].accepted`}
              instructionsKey="cost_set.accepted"
            />
          )}

          <TextInput
            title="Beskrivning"
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].title`}
            instructionsKey="cost_set.title"
          />

          <RelationalTableField
            storeName={storeName}
            tableStoreName={invoicingProductConstants.STORE_NAME}
            paginationMethod={useInvoicingProductPagination}
            performFilter={invoicingProductPerformFilter}
            listDefs={invoicingProductListDefs}
            title="Välj produkt..."
            fieldTitle="Produkt"
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].product`}
            instructionsKey={"cost_set.product"}
            constructStrRep={invoicingProductConstructStrRep}
            modalTitle="Hantera produkt"
            modalInModal
            children={ProductNestedChildrenMethod({
              storeName,
              method,
              parentPath: `cost_set[${handleCostIndex}].product`,
              parentInstructionsPath: `cost_set.product`,
            })}
          />

          <RentField
            {...{ method, storeName }}
            fieldKey={`cost_set[${handleCostIndex}].value`}
            instructionsKey="cost_set.value"
            title="Debiteras (SEK)"
            subtitle="Rabatter anges negativt"
            description="Värde på debiteringen i SEK. Rabatter anges negativt, t.ex. -1000"
            area={totalArea}
          />

          <DatePicker
            title="Startdatum"
            description="Kostnaden ska faktureras från detta datum. Lämnas tomt om kostnaden löper med avtalet."
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].start_date`}
            instructionsKey="cost_set.start_date"
          />

          <DatePicker
            title="Slutdatum"
            description="Kostnaden ska faktureras till detta datum. Lämnas tomt om kostnaden löper med avtalet."
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].end_date`}
            instructionsKey="cost_set.end_date"
          />

          <Select
            title="Momssats"
            description="Momssats som ska appliceras på kostnaden. Lämnas tomt om grundmomssatsen ska appliceras på kostnaden."
            storeName={storeName}
            method={method}
            fieldKey={`cost_set[${handleCostIndex}].vat`}
            instructionsKey="cost_set.vat"
          />

          {hasBillectaFullPermission && (
            <RelationalTableField
              storeName={storeName}
              tableStoreName={invoicingProjectConstants.STORE_NAME}
              paginationMethod={useInvoicingProjectPagination}
              performFilter={invoicingProjectPerformFilter}
              listDefs={invoicingProjectsListDefs}
              title="Välj projekt..."
              fieldTitle="Projekt"
              method={method}
              fieldKey={`cost_set[${handleCostIndex}].project`}
              instructionsKey={"cost_set.project"}
              constructStrRep={invoicingProjectConstructStrRep}
              modalTitle="Hantera projekt"
              modalInModal
              children={ProjectNestedChildrenMethod({
                storeName,
                method,
                parentPath: `cost_set[${handleCostIndex}].project`,
                parentInstructionsPath: `cost_set.project`,
              })}
            />
          )}

          {hasBillectaFullPermission && (
            <RelationalTableField
              storeName={storeName}
              tableStoreName={invoicingCostCenterConstants.STORE_NAME}
              paginationMethod={useInvoicingCostCenterPagination}
              performFilter={invoicingCostCenterPerformFilter}
              listDefs={invoicingCostCentersListDefs}
              title="Välj kostnadsställe..."
              fieldTitle="Kostnadsställe"
              method={method}
              modalInModal
              fieldKey={`cost_set[${handleCostIndex}].cost_center`}
              instructionsKey={"cost_set.cost_center"}
              constructStrRep={invoicingCostCenterConstructStrRep}
              children={CostCenterNestedChildrenMethod({
                storeName,
                method,
                parentPath: `cost_set[${handleCostIndex}].cost_center`,
                parentInstructionsPath: `cost_set.cost_center`,
              })}
              modalTitle="Hantera kostnadsställe"
            />
          )}

          <RadioGroup
            title="Indexuppräkna denna debiteringsrad"
            defaultValue={false}
            description="Räkna upp värdet på denna debiteringsrad utifrån inställningarna på avtalet"
            options={[
              {
                label: "Ja, indexuppräkna denna debiteringsrad",
                value: true,
              },
              {
                label: "Nej, indexuppräkna EJ denna debiteringsrad",
                value: false,
              },
            ]}
            {...{
              storeName,
              method,
              fieldKey: `cost_set[${handleCostIndex}].indexate`,
              instructionsKey: "cost_set.indexate",
            }}
          />
        </>
      )}
    </StandardModal>
  );
}
