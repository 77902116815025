import styled, { css } from "styled-components";

import closeIcon from "../../../assets/svg/minus-square-black.svg";
import openIcon from "../../../assets/svg/add-square-black.svg";
import deleteIcon from "../../../assets/svg/delete.svg";
import downloadIcon from "../../../assets/svg/download.svg";
import cloudOffIcon from "../../../assets/svg/material/cloud_off_black.svg";

export const InfoBox = styled.div`
  background-color: ${(p) => p.theme.colors.grayLight};
  border: 1px solid ${(p) => p.theme.colors.grayDark};
  border-radius: 5px;

  margin-bottom: 12px;
  padding: 8px;
  font-size: 0.8rem;
  display: inline-block;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 24px;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const FormAreaDescription = styled.div`
  font-size: 1rem;
  color: ${(p) => p.theme.colors.textPrimary};
  margin-bottom: 40px;
`;

export const InvoicedBoxFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InvoicedBox = styled.div`
  border: thin solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  margin: 24px 0;

  ${(p) =>
    p.flexed &&
    css`
      flex: 1;

      &:first-child {
        margin-right: 24px;
      }
    `}
`;

export const InvoicedBoxTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #eceff1;
  padding: 12px;

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}

  @media(${(p) => p.theme.mediaQueries.mobile}) {
    flex-direction: column;
  }
`;

export const InvoicedBoxTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvoicedBoxTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #12263f;
  margin-bottom: 6px;
`;

export const InvoicedBoxSubtitle = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
`;

export const InvoicedBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
`;

export const InvoicedRecordRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 12px;

  &:nth-child(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const InvoicedRecordRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
`;

export const InvoicedBoxRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #fff;

  &:nth-child(even) {
    background-color: rgba(241, 242, 246, 0.35);
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ${(p) =>
    p.invalid &&
    css`
      -webkit-box-shadow: inset 0px 0px 5px 0px rgba(207, 0, 28, 1);
      -moz-box-shadow: inset 0px 0px 5px 0px rgba(207, 0, 28, 1);
      box-shadow: inset 0px 0px 5px 0px rgba(207, 0, 28, 1);
    `}
`;

export const InvoicedBoxRowTitle = styled.div`
  font-weight: 500;
  color: #12263f;
  flex: 1;
`;

export const InvoicedBoxRowValue = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const InvalidRowWarning = styled.div`
  margin-top: 4px;
  color: red;
  font-weight: 500;
`;

export const ToggleOpenButton = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(p) => (p.open ? closeIcon : openIcon)});
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${deleteIcon});
`;

export const InovicedBoxRecordsTitlesRow = styled.div`
  display: flex;
  background-color: #eceff1;
  border-bottom: thin solid ${(p) => p.theme.colors.grayDark};
  padding: 12px 12px 0 12px;
`;

export const InvoicedBoxRecordRowTitle = styled.div`
  font-weight: 600;
  font-size: 0.9em;
  padding-bottom: 4px;
  color: #12263f;
  width: ${(p) => p.percentageWidth || 10}%;

  &:not(:first-child) {
    padding-left: 6px;
  }
  &:not(:last-child) {
    border-right: thin solid ${(p) => p.theme.colors.grayDark};
  }

  ${(p) =>
    p.flexEnd &&
    css`
      text-align: end;
      padding-right: 6px;
    `}
`;

export const InvoicedBoxRecordRowContent = styled.div`
  color: #12263f;
  width: ${(p) => p.percentageWidth || 10}%;

  &:not(:first-child) {
    padding-left: 6px;
  }
  &:not(:last-child) {
    padding-right: 6px;
    border-right: thin solid ${(p) => p.theme.colors.grayDark};
  }

  ${(p) =>
    p.flexEnd &&
    css`
      text-align: end;
    `}
`;

export const InvoicedBoxRecordRowBottomRow = styled.div`
  display: flex;
  padding: 12px;
  font-size: 0.8rem;
`;

export const DownloadButton = styled.div`
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${downloadIcon});
`;

export const HandleSelectedContainer = styled.div`
  position: relative;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.activeGray};
  border-bottom: thin solid ${(p) => p.theme.colors.fadedBorder};
`;

export const InvoiceStatusLabel = styled.div`
  display: inline-block;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 1rem;
  background-color: #fff;
  color: #ddd;
  margin-bottom: 16px;
  border: thin solid #ddd;
  border-radius: 5px;

  ${(p) =>
    p.status === "PAUSED" &&
    css`
      background-color: rgba(251, 241, 138, 0.1);
      color: rgb(227, 209, 25);
      border-color: rgb(227, 209, 25);
    `}
`;

export const NotFoundIcon = styled.div`
  height: 44px;
  width: 44px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${cloudOffIcon});
  margin-bottom: 24px;
`;
