export const CLEANED_GENERATE_FORM = {
  From: {
    _label: "Från",
    _internalId: "From",
  },
  To: {
    _label: "Till",
    _internalId: "To",
  },
  remove_already_exported: {
    _label: "Exkludera redan exporterad data",
    _internalId: "remove_already_exported",
  },
  fallback_voucher_index: {
    _label: "Autogenerera verifikationsnummer om det saknas",
    _internalId: "fallback_voucher_index",
  },
  Format: {
    _label: "Format",
    _internalId: "Format",
    _choices: [{ v: "SIE4", d: "SIE4" }],
  },
  DateSelectionType: {
    _label: "Perioden baseras på",
    _choices: [
      { v: "EventDate", d: "Händelsedatum" },
      { v: "TransactionDate", d: "Transaktionsdatum" },
    ],
  },
  BookKeepingTypesFilter: {
    _label: "Inkluderade händelser",
    _internalId: "BookKeepingTypesFilter",
    _choices: [
      { v: "ProductSales", d: "Försäljning" },
      {
        v: "ProductSalesWithReverseVAT",
        d: "Försäljning med omvänd moms",
      },
      { v: "RotRutDiscount", d: "ROT/RUT-skattereduktion" },
      { v: "PaymentToBankAccount", d: "Inbetalning" },
      {
        v: "OverPaymentToBankAccount",
        d: "Överbetalning",
      },
      { v: "CentRounding", d: "Öresavrundning" },
      { v: "Interest", d: "Ränta" },
      { v: "ProductSalesEU", d: "Försäljning EU, momsfri" },
      {
        v: "ProductSalesEUVAT",
        d: "Försäljning EU, momspliktig",
      },
      { v: "ProductSalesNonEU", d: "Försäljning icke-EU" },
      {
        v: "SupplierPaymentFromBankAccount",
        d: "Utbetalning (leverantörsfaktura)",
      },
      {
        v: "SupplierPurchaseDebt",
        d: "Kostnad (leverantörsfaktura)",
      },
      {
        v: "SupplierPurchaseEU",
        d: "Kostnad EU, momsfri (leverantörsfaktura)",
      },
      {
        v: "SupplierPurchaseEUVAT",
        d: "Kostnad EU momspliktig (leverantörsfaktura)",
      },
      {
        v: "SupplierPurchaseNonEU",
        d: "Kostnad icke-EU (leverantörsfaktura)",
      },
      { v: "CurrencyDifference", d: "Valutakursdifferenser" },
      {
        v: "FinanceCostNoRecourse",
        d: "Finansiella kostnader (utan regress)",
      },
      { v: "SelfInvoiceDebt", d: "Kostnad (självfaktura)	" },
      {
        v: "SelfInvoiceDebtVAT",
        d: "Kostnadsmoms (självfaktura)",
      },
      {
        v: "SelfInvoicePaymentFromBankAccount",
        d: "Utbetalning (självfaktura)",
      },
      {
        v: "SelfInvoiceCreditation",
        d: "Kreditering (självfaktura)",
      },
      {
        v: "InvoiceSalesDebtRemoved",
        d: "Avskrivning fakturaförsäljningsskuld",
      },
      { v: "WriteOff", d: "Bortskrivning" },
      {
        v: "ReminderCostPayment",
        d: "Inbetalning (påminnelseavgift)	",
      },
      { v: "Accrual", d: "Periodisering" },
      { v: "AdminsitrationCost", d: "Administrationsavgift" },
      {
        v: "InvoiceSalesDebtAdded",
        d: "Fakturaförsäljningsskuld",
      },
      { v: "RestingVAT", d: "Vilande moms" },
      { v: "FreightCost", d: "Fraktavgift" },
      {
        v: "OverPaymentDeleted",
        d: "Hantering/Borttagning av överbetalning",
      },
      {
        v: "UnmatchedPaymentToBankAccount",
        d: "Omatchad inkommande betalning",
      },
      {
        v: "UnmatchedPaymentDeleted",
        d: "Hanterad/Borttagen omatchad betalning",
      },
      {
        v: "FinanceCostWithRecourse",
        d: "Finansiella kostnader (med regress)",
      },
      { v: "ClientFundDebt", d: "Klientmedelskontoskuld" },
      {
        v: "NonPerformingLoanPurchase",
        d: "Portföljköp",
      },
      {
        v: "PurchasedNonPerformingLoanPayment",
        d: "Betalning på portföljsköp",
      },
    ],
  },
};
