import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import PopupHandle from "../../../components/Details/OverviewInfo/PopupHandle/PopupHandle";
import CalendarEventForm from "../../../components/Forms/CalendarEvent/ChapterForm/ModalForm";
import TodoItemForm from "../../../components/Forms/TodoItem/ChapterForm/ModalForm";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import StandardModal from "../../../components/Modals/StandardModal";
import { buildQueryString, setActiveFormInstance } from "../../../store/base";
import {
  constants as calendarEventConstants,
  useFilteredCalendarEvents,
} from "../../../store/calendarEvents";

import {
  BetaBox,
  BreadCrumb,
  BreadCrumbWrapper,
  Title,
} from "../PageTitle/styles";
import * as SC from "./styles";
import * as TodoStyles from "../../../components/Details/Dashboard/MyWorkday/styles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import TodoItem from "../../../components/Details/Dashboard/MyWorkday/TodoItem";
import CalendarDisplayItem from "../../../components/Details/Dashboard/MyWorkday/CalendarDisplayItem";

function DetailPageHeaderMenu({
  title,
  breadCrumbs,
  isBeta,
  subPages,
  handleActions,
  renderInfoPills,
  renderActions,
  renderLogo,

  eventContentType,
  eventObjectId,
  eventIdQueryStr,
}) {
  const dispatch = useDispatch();
  const [showBetaInfo, setShowBetaInfo] = React.useState(false);
  const [addTodoOpen, setAddTodoOpen] = React.useState(false);
  const [addCalendarEventOpen, setAddCalendarEventOpen] = React.useState(false);
  const [viewTodosOpen, setViewTodosOpen] = React.useState(false);
  const [viewCalendarEventsOpen, setViewCalendarEventsOpen] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);
  const popupRef = React.useRef();

  const showEventActions = eventContentType && eventObjectId;

  const handlePopupClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const calendarEventsQ = buildQueryString({
    calendar__isnull: false,
    mine: true,
    start__gte: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
    [eventIdQueryStr]: eventObjectId,
  });

  const todoEventsQ = buildQueryString({
    calendar__isnull: true,
    done: false,
    mine: true,
    [eventIdQueryStr]: eventObjectId,
  });

  const [todos, todosLoading] = useFilteredCalendarEvents(todoEventsQ);
  const [calendarEvents, calendarEventsLoading] =
    useFilteredCalendarEvents(calendarEventsQ);

  React.useEffect(() => {
    if (eventContentType && eventObjectId) {
      dispatch(
        setActiveFormInstance({
          storeName: calendarEventConstants.STORE_NAME,
          data: {
            content_type: eventContentType,
            object_id: eventObjectId,
          },
        })
      );
    }
  }, [eventContentType, eventObjectId]);

  return (
    <>
      {showEventActions && (
        <>
          <TodoItemForm
            method="POST"
            onCheckout={() => setAddTodoOpen(false)}
            isOpen={addTodoOpen}
          />

          <CalendarEventForm
            method="POST"
            onCheckout={() => setAddCalendarEventOpen(false)}
            isOpen={addCalendarEventOpen}
          />
        </>
      )}

      {todos?.length > 0 && (
        <StandardModal
          title="Uppgifter kopplade till detta objekt"
          withActionBar
          actionBarCancelTitle="Stäng"
          isOpen={viewTodosOpen}
          closeFunction={() => setViewTodosOpen(false)}
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Att göra</OverviewTitle>
              <OverviewSubtitle>
                Uppgifter kopplade till detta objekt
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <TodoStyles.TodoItemContainer>
            {todos?.map((todo) => {
              return (
                <TodoItem
                  key={todo?.id}
                  {...{
                    event: todo,
                    modalInModal: true,
                    hideObjectLink: true,
                  }}
                />
              );
            })}
          </TodoStyles.TodoItemContainer>
        </StandardModal>
      )}

      <StandardModal
        title="Händelser kopplade till detta objekt"
        withActionBar
        actionBarCancelTitle="Stäng"
        isOpen={viewCalendarEventsOpen}
        closeFunction={() => setViewCalendarEventsOpen(false)}
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Händelser</OverviewTitle>
            <OverviewSubtitle>
              Händelser kopplade till detta objekt
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <TodoStyles.TodoItemContainer>
          {calendarEvents?.map((event) => {
            return (
              <CalendarDisplayItem
                key={event?.id}
                {...{
                  event,
                  modalInModal: true,
                  hideObjectLink: true,
                }}
              />
            );
          })}
        </TodoStyles.TodoItemContainer>
      </StandardModal>

      <SC.Container>
        <BreadCrumbWrapper>
          {breadCrumbs?.map((b) => (
            <BreadCrumb key={b.url} to={b.url}>
              {b.label}
            </BreadCrumb>
          ))}

          {showEventActions && (
            <SC.AddEventWrapper>
              {todos?.length > 0 && (
                <>
                  <SC.AddEventLink onClick={() => setViewTodosOpen(true)}>
                    {todos?.length > 1
                      ? `${todos.length} uppgifter`
                      : `${todos.length} uppgift`}
                  </SC.AddEventLink>
                  | 
                </>
              )}
              {calendarEvents?.length > 0 && (
                <>
                  <SC.AddEventLink
                    onClick={() => setViewCalendarEventsOpen(true)}
                  >
                    {calendarEvents?.length > 1
                      ? `${calendarEvents.length} planerade händelser`
                      : `${calendarEvents.length} planerad händelse`}
                  </SC.AddEventLink>
                  | 
                </>
              )}{" "}
              Lägg till:{" "}
              <SC.AddEventLink onClick={() => setAddTodoOpen(true)}>
                Att göra
              </SC.AddEventLink>{" "}
              /
              <SC.AddEventLink onClick={() => setAddCalendarEventOpen(true)}>
                Kalenderevent
              </SC.AddEventLink>
            </SC.AddEventWrapper>
          )}
        </BreadCrumbWrapper>

        <SC.TitleWrapper style={renderLogo ? { marginBottom: 8 } : {}}>
          <Title>
            {renderLogo && renderLogo()}
            {title}
            {isBeta && (
              <StatusLabel
                onMouseOver={() => setShowBetaInfo(true)}
                onMouseLeave={() => setShowBetaInfo(false)}
                style={{
                  marginLeft: 8,
                  position: "relative",
                  cursor: "pointer",
                }}
                state={"BETA"}
              >
                BETA
                {showBetaInfo && (
                  <BetaBox>
                    Denna del av systemet är ny och under utveckling. Pigello
                    utför noggranna tester innan funktionen släpps officiellt
                    och därför kan viss funktionalitet saknas eller inte fungera
                    som det ska. Vänligen informera oss om det är något som inte
                    verkar fungera som det ska så tittar vi på det direkt.
                  </BetaBox>
                )}
              </StatusLabel>
            )}
          </Title>

          {renderActions && renderActions()}
        </SC.TitleWrapper>

        {renderInfoPills && (
          <SC.InfoPillsWrapper>{renderInfoPills()}</SC.InfoPillsWrapper>
        )}

        <SC.PageLinksWrapper>
          {subPages?.map((sp) => (
            <SC.PageLink hasError={sp.hasError} to={sp.url}>
              {sp.label}
            </SC.PageLink>
          ))}

          {handleActions?.length > 0 && (
            <>
              <SC.HandleActionSeparator />
              <SC.HandleAction ref={popupRef} onClick={handlePopupClick}>
                Hantera{" "}
                <svg
                  width="12"
                  height="12"
                  x="0"
                  y="0"
                  viewBox="0 0 444.819 444.819"
                  style={{
                    enableBackground: "new 0 0 512 512",
                    marginLeft: "4px",
                    marginTop: 2,
                  }}
                >
                  <path
                    d="M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561   L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416   C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848   c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693   C444.819,130.287,441.295,121.629,434.252,114.203z"
                    fill="#2ec177"
                    data-original="#000000"
                  />
                </svg>
                <PopupHandle
                  {...{
                    actions: handleActions,
                    open,
                    closeFunction: () => setOpen(false),
                  }}
                />
              </SC.HandleAction>
            </>
          )}
        </SC.PageLinksWrapper>
      </SC.Container>
    </>
  );
}

export default DetailPageHeaderMenu;
