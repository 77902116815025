import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import AsyncSelect from "../Fields/AsyncSelect";
import NewSingleItem from "../Decorations/NewSingleItem";
import StandardModal from "../../../Modals/StandardModal";

// store, state
import {
  updateActiveFormInstance,
  updateFormErrors,
  useFormError,
  useFormField,
} from "../../../../store/base";
import TableSelect from "../Fields/TableSelect";

export default React.memo(
  ({
    storeName,
    tableStoreName,
    listDefs,
    paginationMethod,
    persistantFilterMethod,
    persistantQueryString,
    filterInstructions,
    extraColumnsDefsArgs,
    performFilter,
    fieldTitle,
    forceFrontendColumns,
    method,
    fieldKey,
    children,
    constructStrRep,
    instructionsKey,
    modalTitle,
    tryNestedUpdate,
    modalInModal,
    title,
    description,
    noMargin,
    selectedCallback,

    //for passing down small to standardmodal
    createModalSmall,
  }) => {
    const dispatch = useDispatch();

    const [handleIsActive, setHandleActive] = React.useState(false);
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });

    const errors = useFormError({
      storeName,
      fieldKey: instructionsKey,
    });

    const allowNull = instructions?._allowNull;
    const defValue = allowNull !== undefined && allowNull ? null : undefined;

    const onCreateClicked = () => {
      // clear all data
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: defValue } })
      );

      // clear all errors
      if (errors?.length) {
        dispatch(
          updateFormErrors({ storeName, data: { [fieldKey]: undefined } })
        );
      }

      // open up handleModal
      setHandleActive(true);
    };

    const onRemoveCreated = () => {
      // clear all data
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: defValue } })
      );

      // clear all errors
      dispatch(
        updateFormErrors({ storeName, data: { [fieldKey]: undefined } })
      );
    };

    const onEditClicked = () => {
      // open up handleMOdal
      setHandleActive(true);
    };

    return (
      <>
        <TableSelect
          storeName={storeName}
          method={method}
          fieldKey={fieldKey}
          onCreateClicked={onCreateClicked}
          instructionsKey={instructionsKey}
          title={title}
          tableStoreName={tableStoreName}
          listDefs={listDefs}
          paginationMethod={paginationMethod}
          persistantFilterMethod={persistantFilterMethod}
          persistantQueryString={persistantQueryString}
          filterInstructions={filterInstructions}
          extraColumnsDefsArgs={extraColumnsDefsArgs}
          performFilter={performFilter}
          fieldTitle={fieldTitle}
          forceFrontendColumns={forceFrontendColumns}
          setWholeObject
          modalInModal={modalInModal}
          constructStrRep={constructStrRep}
          description={description}
          noMargin={noMargin}
          selectedCallback={selectedCallback}
        />

        <NewSingleItem
          storeName={storeName}
          fieldKey={fieldKey}
          onRemove={onRemoveCreated}
          onEdit={onEditClicked}
          constructStrRep={constructStrRep}
          instructionsKey={instructionsKey}
          method={method}
          tryNestedUpdate={tryNestedUpdate}
        />
        <StandardModal
          modalInModal={modalInModal}
          isOpen={handleIsActive}
          withActionBar
          title={modalTitle ? modalTitle : false}
          closeFunction={() => setHandleActive(false)}
          saveFunction={() => setHandleActive(false)}
          small={createModalSmall}
        >
          {children}
        </StandardModal>
      </>
    );
  }
);
