import * as React from "react";
import { DetailInnerWrapper } from "../sharedStyles";
import * as SC from "./styles";

import { TextButton } from "../Forms/Base/Buttons";
import INTEGRATIONS from "../../views/Integrations/integrationsList";
import { useHistory } from "react-router-dom";

export default function IntegrationsGrid() {
  const { push } = useHistory();

  return (
    <DetailInnerWrapper>
      <SC.Grid>
        {INTEGRATIONS.map((integration) => (
          <SC.GridItem key={integration.title}>
            <SC.IntegrationHeader>
              <SC.IntegrationLogo image={integration.image} />

              <SC.IntegrationTitleWrapper>
                <SC.IntegrationTitle>{integration.title}</SC.IntegrationTitle>
                <SC.IntegrationSubtitle>
                  {integration.subtitle}
                </SC.IntegrationSubtitle>
              </SC.IntegrationTitleWrapper>
            </SC.IntegrationHeader>
            <SC.IntegrationDescription>
              {integration.description}
            </SC.IntegrationDescription>

            <SC.IntegrationFooter>
              <TextButton
                disabled={integration.alwaysOn}
                iconType={integration.alwaysOn ? "done" : "arrow"}
                iconPlacement="right"
                title={
                  integration.alwaysOn ? "Alltid aktiv" : "Hantera integration"
                }
                clicked={() => push(integration.path)}
              />
            </SC.IntegrationFooter>
          </SC.GridItem>
        ))}
      </SC.Grid>
    </DetailInnerWrapper>
  );
}
