import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "../../../../store/billectaInvoicing";
import { updateActiveFormInstance } from "../../../../store/base";

import * as SC from "../styles";
import {
  Select,
  DatePicker,
  PositiveNumber,
  TextInput,
  Checkbox,
  TextField,
} from "../../../Forms/Base/Fields";
import { InvoiceChapter } from "../InvoiceComponents";
import checkValidations from "../InvoiceComponents/checkValidations";

import DebtCollectionChapter from "../InvoiceComponents/DebtCollectionChapter";
import TenantChapter from "../InvoiceComponents/TenantChapter";
import InvoiceRecords from "../InvoiceComponents/InvoiceRecords";
import checkRequired from "../InvoiceComponents/checkRequired";
import RequiredMissing from "../InvoiceComponents/RequiredMissing/RequiredMissing";
import {
  getAutogiroSettings,
  getEInvoiceSettings,
  getKivraSettings,
} from "../../../../store/billectaIntegrations";
import { useParams } from "react-router";
import TableFileM2m from "../../../Forms/Base/Fields/TableFileM2m";
import { InnerBox } from "../../../sharedStyles";

export default function CreateInvoiceForm({
  selectedTenant,
  selectedDebtorConfig,
  company,
  onValidationError,
  onRequiredMissing,
  period,
}) {
  const dispatch = useDispatch();

  const invoiceInstance = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );
  const { creditorId } = useParams();

  const kivraSettings = useSelector(
    (state) => state.billectaIntegrations.kivraSettings
  );
  const eInvoiceSettings = useSelector(
    (state) => state.billectaIntegrations.eInvoiceSettings
  );

  const autogiroSettings = useSelector(
    (state) => state.billectaIntegrations.autogiroSettings
  );

  const tenantHasEInvoiceActivated = !!selectedDebtorConfig?.e_invoice_bank;

  const tenantHasAutogiro = selectedDebtorConfig?.use_autogiro;
  const creditorHasAutogiro = !!autogiroSettings;

  const [requiredMissing, setRequiredMissing] = React.useState([]);
  const [validationErrors, setValidationErrors] = React.useState({});

  React.useEffect(() => {
    if (!kivraSettings) {
      dispatch(getKivraSettings(creditorId));
    }
    if (!eInvoiceSettings) {
      dispatch(getEInvoiceSettings(creditorId));
    }

    if (!autogiroSettings) {
      dispatch(getAutogiroSettings(creditorId));
    }
  }, []);

  //If tenant has e-invoce set up, that is the only allowed delivery method
  // So, we force default it. The field is also disabled.
  // Invoice fees are not allowed for Einvoice, so its reset to 0
  React.useEffect(() => {
    if (tenantHasEInvoiceActivated) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {
            DeliveryMethod: "EInvoice",
            InvoiceFee: {
              CurrencyCode: "SEK",
              Value: 0,
            },
          },
        })
      );
    }
  }, [tenantHasEInvoiceActivated, dispatch]);

  const setValidationErrorsCallback = React.useCallback(
    (errors) => {
      setValidationErrors(errors);
      onValidationError(errors);
    },
    [validationErrors]
  );

  const setRequiredCallback = React.useCallback(
    (required) => {
      setRequiredMissing(required);
      onRequiredMissing(required);
    },
    [requiredMissing]
  );

  React.useEffect(() => {
    if (invoiceInstance) {
      checkValidations({
        setErrors: setValidationErrorsCallback,
        formInstance: invoiceInstance,
        debtorInstance: selectedDebtorConfig,
        tenant: selectedTenant,
        kivraEnabled: kivraSettings?.IsEnabled,
        eInvoiceEnabled: !!eInvoiceSettings?.FUI,
        type: "INVOICE",
      });

      checkRequired({
        formInstance: invoiceInstance,
        type: "INVOICE",
        setRequired: setRequiredCallback,
      });
    }
  }, [invoiceInstance]);

  const automatedDebtCollectionAdded = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          InterestPercentage: 8,
          InterestStartInDaysAfterDueDate: 8,
          InterestType: "AboveEffectiveReference",
          DebtCollectionDetails: {
            DaysDelayAfterDueDate: 4,
            NumberOfReminders: 1,
            PaymentTermsInDays: 10,
            SendToDebtCollection: true,
            StartDebtCollectionActionLevel: "Reminders",
          },
        },
      })
    );
  };

  const automatedDebtCollectionRemoved = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          InterestPercentage: undefined,
          InterestStartInDaysAfterDueDate: undefined,
          InterestType: undefined,
          DebtCollectionDetails: undefined,
        },
      })
    );
  };

  const invoicingChapter = () => (
    <InvoiceChapter
      forceOpen
      title="Faktura"
      description="Inställningar för fakturan"
    >
      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Vår referens:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <TextInput
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"POST"}
            fieldKey={`OurReference`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Fakturadatum::</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <DatePicker
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"POST"}
            fieldKey={`InvoiceDate`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors["DueDate"] ||
            !!validationErrors["DueDate_before_today"],
        }}
      >
        <SC.InvoicedBoxRowTitle>Betalas senast:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <DatePicker
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"POST"}
            fieldKey={`DueDate`}
          />
          {!!validationErrors["DueDate"] && (
            <SC.InvalidRowWarning>
              Förfallodatumet är innan fakturadatumet. Fakturan kommer ej kunna
              attesteras.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DueDate_before_today"] && (
            <SC.InvalidRowWarning>
              Förfallodatumet måste ligga i framtiden.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>
          Skicka automatiska påminnelser:
        </SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <Checkbox
            title="Skicka automatiska påminnelser"
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"POST"}
            fieldKey={`ReminderInvoiceDetails.SendReminderInvoice`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      {invoiceInstance?.ReminderInvoiceDetails?.SendReminderInvoice && (
        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>
            Skicka automatisk påminnelse x dagar efter förfallodatum:
          </SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            <PositiveNumber
              noMargin
              noTitle
              storeName={constants.STORE_NAME}
              method={"POST"}
              fieldKey={`ReminderInvoiceDetails.DaysDelayAfterDueDate`}
            />
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>
      )}

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Använd autogiro:</SC.InvoicedBoxRowTitle>
        <SC.InvoicedBoxRowValue>
          <Checkbox
            disabled={!tenantHasAutogiro || !creditorHasAutogiro}
            noMargin
            title={
              !creditorHasAutogiro
                ? "Autogiro ej aktiverat för bolaget"
                : !tenantHasAutogiro
                ? "Autogiro ej aktiverat för kund"
                : "Använd autogiro"
            }
            noTitle
            storeName={constants.STORE_NAME}
            method={"POST"}
            fieldKey={`Autogiro.AutogiroWithdrawalEnabled`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors["DeliveryMethod_Email"] ||
            !!validationErrors["DeliveryMethod_Mail"] ||
            !!validationErrors["DeliveryMethod_Kivra_Disabled"] ||
            !!validationErrors["DeliveryMethod_SMS"] ||
            !!validationErrors["DeliveryMethod_EInvoice_Disabled"],
        }}
      >
        <SC.InvoicedBoxRowTitle>Leveransmetod:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <Select
            disabled={tenantHasEInvoiceActivated}
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"POST"}
            fieldKey={`DeliveryMethod`}
          />
          {tenantHasEInvoiceActivated && (
            <SC.InvalidRowWarning style={{ color: "black" }}>
              Eftersom kunden har E-faktura uppsatt så är leveransmetoden låst
              till E-faktura. För att kunna använda en annan leveransmetod krävs
              att E-faktura tas bort ifrån kunden.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Email"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar e-postadress för leverans. Lägg till
              en e-postadress på kunden eller välj en annan leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Mail"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar fysisk adress för leverans. Lägg
              till en address på kunden eller välj en annan leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Kivra_Disabled"] && (
            <SC.InvalidRowWarning>
              Kivra-integrationen är inte konfigurerad för detta bolag. Aktivera
              integrationen för att kunna nyttja Kivra som leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_SMS"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar telefonnummer för leverans. Lägg
              till ett telefonnummer på kunden eller välj en annan
              leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_EInvoice_Disabled"] && (
            <SC.InvalidRowWarning>
              E-fakturering har inte aktiverats. Kontakta Pigello för att
              aktivera detta.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Meddelande på fakturan:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <TextField
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"POST"}
            fieldKey={`Message`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>
    </InvoiceChapter>
  );

  const AttachmentChapter = () => (
    <InvoiceChapter title="Bilagor">
      <InnerBox style={{ display: "flex", justifyContent: "center" }}>
        <TableFileM2m
          title="Bilagor"
          extraStyles={{ width: "100%", maxWidth: "none" }}
          description="Bilagor på fakturan"
          storeName={constants.STORE_NAME}
          method={"POST"}
          fieldKey={`Attachments`}
          fileKey="File"
        />
      </InnerBox>
    </InvoiceChapter>
  );

  return (
    <>
      <TenantChapter
        tenant={selectedTenant}
        debtorConfig={selectedDebtorConfig}
        constants={constants}
        method="POST"
      />

      {invoicingChapter()}

      <InvoiceRecords
        tenantHasEInvoiceActivated={tenantHasEInvoiceActivated}
        instance={invoiceInstance}
        constants={constants}
        creditorId={creditorId}
        method={"POST"}
        periodStart={period?.start_date}
        periodEnd={period?.end_date}
      />

      <DebtCollectionChapter
        constants={constants}
        validationErrors={validationErrors}
        instance={invoiceInstance}
        automatedDebtCollectionAdded={automatedDebtCollectionAdded}
        automatedDebtCollectionRemoved={automatedDebtCollectionRemoved}
        company={company}
      />

      {AttachmentChapter()}

      {requiredMissing.length > 0 && (
        <RequiredMissing
          requiredMissing={requiredMissing}
          title="Dessa uppgifter saknas för att kunna skapa eller förhandsgranska fakturan:"
        />
      )}
    </>
  );
}
