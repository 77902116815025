import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import {
  BareLayoutTitleWrapper,
  BareLayoutWrapper,
  DetailPageBox,
} from "../../components/sharedStyles";
import PageTitle from "../Layouts/PageTitle/PageTitle";
import AddGroupFormContainer from "../../components/Account/AddGroupFormContainer";
import { TextButton } from "../../components/Forms/Base/Buttons";
import { useHistory } from "react-router";
import CreateGroupForm from "../../components/Forms/UserGroup/CreateGroupForm";

export default () => {
  const { goBack } = useHistory();
  const breadCrumbs = [
    {
      label: "Inställningar",
      url: "/account/user",
    },
    {
      label: "Användare & Grupper",
      url: "/account/groups-and-users?tab=GROUPS",
    },
    {
      label: "Lägg till grupp",
    },
  ];

  return (
    <BareLayoutWrapper>
      <BareLayoutTitleWrapper>
        <PageTitle dark title="Lägg till grupp" breadCrumbs={breadCrumbs} />
      </BareLayoutTitleWrapper>
      <DetailPageBox>
        <TextButton
          title="Tillbaka"
          iconType="arrow-back"
          extraStyle={{ marginBottom: "8px" }}
          clicked={goBack}
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Lägg till ny grupp</OverviewTitle>
            <OverviewSubtitle>
              Namnge den nya gruppen och bocka i vilka behörigheter som ska vara
              påslagna. Den här gruppen kommer att synas under listan "Skapade
              grupper" i gruppöversikten. När du skapar en ny användare kommer
              du kunna välja den här gruppen.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        <CreateGroupForm addGroupForm />
      </DetailPageBox>
    </BareLayoutWrapper>
  );
};
