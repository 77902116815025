import * as React from "react";
import * as SC from "./styles";
import moment from "moment";
import { useSelector } from "react-redux";
import { constants } from "../../../store/billectaReports";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { InnerBox } from "../../sharedStyles";
import { FieldBox } from "../Search/styles";
import { ExportExcelButton } from "../../Forms/Base/Buttons";
import { performExcelExport } from "../../Lists/Base/utils";
import InvoiceTable from "../Search/InvoiceTable";

export default ({ fromDate, toDate, setFromDate, setToDate, goToInvoice }) => {
  const storeName = constants.STORE_NAME;
  const VATReport = useSelector((state) => state[storeName].searchedPeriodRes);
  const VATPrevReport = useSelector(
    (state) => state[storeName].invoicesWithOutstandingVATPrevPeriod
  );

  const fromDateState = useSelector((state) => state[storeName].fromDate);
  const toDateState = useSelector((state) => state[storeName].toDate);
  const prevPeriodFromDateState = useSelector(
    (state) => state[storeName].prevPeriodFromDate
  );
  const prevPeriodToDateState = useSelector(
    (state) => state[storeName].prevPeriodToDate
  );

  const totalVatAmountForPrevPeriod = useSelector(
    (state) => state[storeName].totalVatAmountForPrevPeriod
  );
  const totalRemainingAmountForPrevPeriod = useSelector(
    (state) => state[storeName].totalRemainingAmountForPrevPeriod
  );
  const totalInvoicedAmountForPrevPeriod = useSelector(
    (state) => state[storeName].totalInvoicedAmountForPrevPeriod
  );

  const totalVatAmountForSearchedPeriod = useSelector(
    (state) => state[storeName].totalVatAmountForSearchedPeriod
  );
  const totalRemainingAmountForSearchedPeriod = useSelector(
    (state) => state[storeName].totalRemainingAmountForSearchedPeriod
  );
  const totalInvoicedAmountForSearchedPeriod = useSelector(
    (state) => state[storeName].totalInvoicedAmountForSearchedPeriod
  );

  const parseForExport = () => {
    if (!VATReport) return;
    let parsedData = [];

    // first row is headers
    const headers = [
      "Aviserat totalt",
      "Kvarstående aviserat belopp",
      `Utgående moms för period ((${fromDateState} - ${toDateState}))`,
      `Vilande moms från föregående period (${prevPeriodFromDateState} - ${prevPeriodToDateState})`,
      "Utgående moms total",
    ];
    parsedData.push(headers);

    let row = [
      `${totalInvoicedAmountForSearchedPeriod.toLocaleString("sv")}`,
      `${totalRemainingAmountForSearchedPeriod.toLocaleString("sv")}`,
      `${totalVatAmountForSearchedPeriod.toLocaleString("sv")}`,
      `${totalVatAmountForPrevPeriod.toLocaleString("sv")}`,
      `${(
        totalVatAmountForPrevPeriod + totalVatAmountForSearchedPeriod
      ).toLocaleString("sv")}`,
    ];

    parsedData.push(row);

    let basisData = [];
    const basisHeaders = [
      "Fakturanummer",
      "Mottagare",
      "Fakturadatum",
      "Förfallodatum",
      "Fakturerat belopp SEK inkl. moms",
      "Kvarstående belopp SEK inkl. moms",
      "Total kvarstående moms SEK",
    ];
    basisData.push(basisHeaders);

    VATReport.forEach((d) => {
      const r = [
        d.InvoiceNumber,
        d.DebtorName,
        moment(d.InvoiceDate).format("YYYY-MM-DD"),
        moment(d.DueDate).format("YYYY-MM-DD"),
        d.InvoicedAmount.ValueForView.toLocaleString("sv"),
        d.CurrentAmount.ValueForView.toLocaleString("sv"),
        d.TotalVATAmount.ValueForView.toLocaleString("sv"),
      ];

      basisData.push(r);
    });

    let prevBasisData = [];
    prevBasisData.push(basisHeaders);

    if (VATPrevReport) {
      VATPrevReport.forEach((d) => {
        const r = [
          d.InvoiceNumber,
          d.DebtorName,
          moment(d.InvoiceDate).format("YYYY-MM-DD"),
          moment(d.DueDate).format("YYYY-MM-DD"),
          d.InvoicedAmount.ValueForView.toLocaleString("sv"),
          d.CurrentAmount.ValueForView.toLocaleString("sv"),
          d.TotalVATAmount.ValueForView.toLocaleString("sv"),
        ];

        prevBasisData.push(r);
      });
    }

    performExcelExport({
      data: {
        Momsrapport: parsedData,
        Underlag: basisData,
        "Underlag vilande": prevBasisData,
      },
      fileName: `moms_${fromDateState}-${toDateState}`,
    });
  };

  return (
    <>
      <FieldBox>
        <OverviewTitle small style={{ marginBottom: "8px" }}>
          Datumintervall för rapport
        </OverviewTitle>
        <NonConnectedDatePicker
          value={fromDate}
          clearable={false}
          id="vat-report-from-date"
          label="Från"
          onChange={(val) => setFromDate(val)}
          noMargin
        />
        <NonConnectedDatePicker
          value={toDate}
          clearable={false}
          id="vat-report-to-date"
          label="Till"
          onChange={(val) => setToDate(val)}
          noMargin
        />
      </FieldBox>

      {!!VATReport && (
        <>
          <InnerBox>
            <SC.VATReportWrapper>
              <SC.VATReportHeader>
                <OverviewTitle small>
                  Moms för period {`${fromDateState} - ${toDateState}`}
                </OverviewTitle>

                <ExportExcelButton onClick={parseForExport} title="Exportera" />
              </SC.VATReportHeader>
              <SC.VATReportRow>
                <SC.VATReportTitle>Aviserat totalt (SEK):</SC.VATReportTitle>
                <SC.VATReportValue>
                  {totalInvoicedAmountForSearchedPeriod.toLocaleString("sv")}
                </SC.VATReportValue>
              </SC.VATReportRow>

              <SC.VATReportRow>
                <SC.VATReportTitle>
                  Kvarstående aviserat belopp (SEK):
                </SC.VATReportTitle>
                <SC.VATReportValue>
                  {totalRemainingAmountForSearchedPeriod.toLocaleString("sv")}
                </SC.VATReportValue>
              </SC.VATReportRow>

              <SC.VATReportRow>
                <SC.VATReportTitle>
                  Utgående moms för period (SEK):{" "}
                </SC.VATReportTitle>
                <SC.VATReportValue>
                  {totalVatAmountForSearchedPeriod.toLocaleString("sv")}
                </SC.VATReportValue>
              </SC.VATReportRow>

              <SC.VATReportRow>
                <SC.VATReportTitle>
                  Aviserat totalt föregående period (SEK):
                </SC.VATReportTitle>
                <SC.VATReportValue>
                  {totalInvoicedAmountForPrevPeriod.toLocaleString("sv")}
                </SC.VATReportValue>
              </SC.VATReportRow>

              <SC.VATReportRow>
                <SC.VATReportTitle>
                  Kvarstående aviserat belopp föregående period (SEK):
                </SC.VATReportTitle>
                <SC.VATReportValue>
                  {totalRemainingAmountForPrevPeriod.toLocaleString("sv")}
                </SC.VATReportValue>
              </SC.VATReportRow>

              <SC.VATReportRow>
                <SC.VATReportTitle>
                  Vilande moms från föregående period (SEK):
                  <div>
                    ({`${prevPeriodFromDateState} - ${prevPeriodToDateState}`})
                  </div>
                </SC.VATReportTitle>
                <SC.VATReportValue>
                  {totalVatAmountForPrevPeriod.toLocaleString("sv")}
                </SC.VATReportValue>
              </SC.VATReportRow>

              <SC.VATReportRow>
                <SC.VATReportTitle bold>
                  Utgående moms total (SEK):
                </SC.VATReportTitle>
                <SC.VATReportValue bold>
                  {(
                    totalVatAmountForSearchedPeriod +
                    totalVatAmountForPrevPeriod
                  ).toLocaleString("sv")}
                </SC.VATReportValue>
              </SC.VATReportRow>
            </SC.VATReportWrapper>

            {!!VATPrevReport?.length && (
              <>
                <OverviewTitle small style={{ marginTop: "48px" }}>
                  Vilande moms beräknad på dessa fakturor:
                </OverviewTitle>
                <InvoiceTable
                  invoices={VATPrevReport}
                  handleRowClicked={goToInvoice}
                />
              </>
            )}
          </InnerBox>
        </>
      )}
    </>
  );
};
