export const constructStrRep = (obj) => {
  return obj.str_representation || "Förvaltningsuppdrag";
};
export const detailUrl = ({ id, showInvoicingSettingPrompt }) => {
  return `/customers/detail/${id}${
    showInvoicingSettingPrompt ? "?showInvoicingSettingPrompt=true" : ""
  }`;
};
export const overviewUrl = (conf) => {
  return `/customers`;
};
