import styled from "styled-components";
import { animated } from "react-spring";

export const FullPageOverlay = styled(animated.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CoverOverlay = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinningCircleContainer = styled.svg`
  height: 90px;
  width: 90px;
  animation: rotate 2s linear infinite;
`;

export const SpinningCircle = styled.circle`
  animation: dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150; // ie11 fallback
  stroke-dashoffset: -35; // ie11 fallback
  stroke-linecap: round;
  stroke: ${(p) => p.theme.colors.blueDark};
`;

export const BoxedOverlay = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  margin: -14px 0 0 -14px;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  position: relative;
  margin: 12px 0;
  box-shadow: ${(p) => p.theme.boxShadows.discrete};
  border: 1px solid ${(p) => p.theme.colors.gray};
  background-color: white;
  border-radius: 8px;
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;
