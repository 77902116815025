import * as React from "react";
// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs, {
  getCostBetweenMonthDates,
  PRODUCT_CATEGORY_MAPPING,
  getMonthsForCost,
  getIndexBetweenMonths,
  getSpecifiedPeriodDates,
} from "./listDefs";

// store, state
import { buildRangeFilter } from "../Base/utils";
import DetailInfo from "../../Details/OverviewInfo/DetailInfo/DetailInfo";

import {
  useManagementContractPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/managementContracts";
import { buildQueryString, useFrequentPermissions } from "../../../store/base";

import moment from "moment";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import DescriptionToolTip from "../../Forms/Base/Layout/DescriptionToolTip";
import { toMoneyString } from "../../utils/stringUtils";
import ToggleSqm from "../../Displays/ToggleSqm";
import ToggleYearlyMonthly from "../../Displays/ToggleYearlyMonthly";
import { InnerBox } from "../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextButton } from "../../Forms/Base/Buttons";

export const getDateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  useSpecifiedPeriod,
  setUseSpecifiedPeriod,
  useSqm,
  setUseSqm,
  showMonthlyCosts,
  setShowMonthlyCosts,
}) => {
  let monthChoices = [];
  for (let index = 0; index < 12; index++) {
    monthChoices.push({ id: index, str_representation: `${index + 1}` });
  }

  return (
    <InnerBox>
      <OverviewTitleWrapper style={{ marginBottom: 0 }}>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Inställningar för kostnadsrader</OverviewTitle>
          <OverviewSubtitle>
            {useSpecifiedPeriod ? (
              <TextButton
                title="Använd gällande avtalsår"
                iconType="eventrepeat"
                iconPlacement="right"
                clicked={() => setUseSpecifiedPeriod(false)}
              />
            ) : (
              <TextButton
                title="Specificera period för kostnader"
                iconType="event"
                iconPlacement="right"
                clicked={() => setUseSpecifiedPeriod(true)}
              />
            )}
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>

        <div style={{ display: "flex", alignItems: "center" }}>
          {setUseSqm && (
            <ToggleSqm isSqm={useSqm} setIsSqm={(val) => setUseSqm(val)} />
          )}

          <ToggleYearlyMonthly
            isYearly={!showMonthlyCosts}
            setIsYearly={(val) => setShowMonthlyCosts(!val)}
            extraStyles={{ marginLeft: 24 }}
          />
        </div>
      </OverviewTitleWrapper>

      {useSpecifiedPeriod && (
        <div style={{ display: "flex", alignItems: "center" }}>
          Baserar kostnadsrader på{" "}
          <NonConnectedDatePicker
            extraStyle={{ margin: "0 6px" }}
            inputExtraStyle={{ maxWidth: 140 }}
            format="YYYY-MM"
            noMargin
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
          />{" "}
          till{" "}
          <NonConnectedDatePicker
            extraStyle={{ margin: "0 6px" }}
            inputExtraStyle={{ maxWidth: 140 }}
            format="YYYY-MM"
            noMargin
            value={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
          />
          <DescriptionToolTip
            description={
              "Kostnader och indexuppräkning på objekten i listan räknas ut för det månadsintervallet som är valt. Beräkningen görs från den 1:a i startmånaden till den sista i slutmånaden."
            }
          />
        </div>
      )}
    </InnerBox>
  );
};

export const renderSubRow = ({
  row,
  tableWidth,
  startDate,
  endDate,
  onlyCosts = false,
}) => {
  const contract = row.original;
  const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
    start: startDate,
    end: endDate,
    contract,
  });
  const contents = [];

  const costs = getCostBetweenMonthDates(
    contract.costs,
    specifiedStartDate,
    specifiedEndDate
  );

  costs.sort((a, b) => {
    const bRate =
      b.end_date && b.start_date ? 3 : b.end_date ? 2 : b.start_date ? 1 : 0;
    const aRate =
      a.end_date && a.start_date ? 3 : a.end_date ? 2 : a.start_date ? 1 : 0;

    if (bRate === aRate) {
      return (a.product_category ?? -1) - (b.product_category ?? -1);
    }
    return aRate - bRate;
  });

  let costInfo = [];

  const indexBetweenMonths = getIndexBetweenMonths(
    !!row.original.indexation,
    row.original.indexations,
    specifiedStartDate,
    specifiedEndDate
  );

  let totalCost =
    typeof indexBetweenMonths === "number" ? indexBetweenMonths : 0;

  costs.forEach((c) => {
    const productKey = PRODUCT_CATEGORY_MAPPING[c.product_category] ?? "Övriga";

    const start = moment(c.start_date);
    const end = moment(c.end_date);

    let dtString = "";
    if (start._isValid && end._isValid) {
      dtString = ` (${start.format("YYYY-MM-DD")} - ${end.format(
        "YYYY-MM-DD"
      )})`;
    } else if (start._isValid) {
      dtString = ` (f.o.m ${start.format("YYYY-MM-DD")})`;
    } else if (end._isValid) {
      dtString = ` (t.o.m ${end.format("YYYY-MM-DD")})`;
    }

    const months = getMonthsForCost(
      c,
      specifiedStartDate,
      specifiedEndDate,
      true
    );
    const totVal = c.value * c.unit_amount * months;
    costInfo.push({
      title: `${c.title} (${productKey})`,
      value: `${toMoneyString(totVal)}${dtString}`,
    });

    totalCost += totVal;
  });

  if (typeof indexBetweenMonths !== "string") {
    costInfo.push({
      title: "Indexuppräkning",
      value: toMoneyString(indexBetweenMonths),
    });
  }

  if (costInfo.length) {
    costInfo.push({
      title: <b>Totalt</b>,
      value: (
        <b>
          {toMoneyString(totalCost)} ({specifiedStartDate.format("YYYY-MM-DD")}{" "}
          - {specifiedEndDate.format("YYYY-MM-DD")})
        </b>
      ),
    });
  } else {
    costInfo.push({
      title: "Kostnader Saknas",
      value: "",
    });
  }

  contents.push({ Kostnader: costInfo });
  if (onlyCosts) {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <div
          style={{
            width: tableWidth || 800,
            position: "sticky",
            top: 0,
            left: 0,
          }}
        >
          {contents.map((c, i) => (
            <DetailInfo
              extraChapterStyles={{
                marginRight: 0,
                borderRadius: 0,
                marginBottom: 0,
                border: "none",
              }}
              key={c}
              infoObj={c}
              darkThemed
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        style={{
          width: tableWidth || 800,
          position: "sticky",
          top: 0,
          left: 0,
        }}
      >
        {contents.map((c, i) => (
          <DetailInfo
            extraChapterStyles={{
              marginRight: 0,
              borderRadius: 0,
              marginBottom: 0,
              border: "none",
            }}
            key={c}
            infoObj={c}
            darkThemed
          />
        ))}
      </div>
    </div>
  );
};

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  exportSheetController,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  onRowClicked,
  checkRowHighlighted,
  forceInitialPageSize,
  hidePagination,
  externalFilters,
  withPersistantSortBy,
  withPersistantGlobalFilter,
  persistantCategories,
  clearCategories,
  withCosts,
  withFullScreen,
  persistantColumnsOverride,
}) => {
  const storeName = constants.STORE_NAME;

  const sDate = moment({ year: moment().year(), month: 0, day: 1 });
  const eDate = moment({ year: moment().year(), month: 11, day: 31 });
  const [startDate, setStartDate] = React.useState(sDate.format("YYYY-MM"));
  const [endDate, setEndDate] = React.useState(eDate.format("YYYY-MM"));

  const [useSpecifiedPeriod, setUseSpecifiedPeriod] = React.useState(false);
  const [useSqm, setUseSqm] = React.useState(false);
  const [showMonthlyCosts, setShowMonthlyCosts] = React.useState(false);

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const filterInstructions = {
    id_number: { operator: "icontains" },
    state: {
      handler: (filterObject) => {
        // for 0 and 1 we want to use an operator to check for any of the two
        // operator must be attached to current_state variable
        // filter on variable state is only supported on backend as a convenience
        if ([0, 1].includes(filterObject?.value)) {
          return buildQueryString({ state__in: [0, 1] });
        }
        return buildQueryString({ state: filterObject.value });
      },
    },
    start_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
    end_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  };

  const columns = React.useMemo(
    () =>
      columnDefs(
        startDate,
        endDate,
        withCosts,
        hasBillectaViewPermission,
        useSqm,
        showMonthlyCosts,
        useSpecifiedPeriod
      ),
    [
      persistantFilterMethod,
      persistantQueryString,
      startDate,
      endDate,
      withCosts,
      hasBillectaViewPermission,
      useSqm,
      showMonthlyCosts,
      useSpecifiedPeriod,
    ]
  );
  const fetchAllTreshold = 25;

  const localStorageHiddenColumnId =
    persistantColumnsOverride || "leasecontracts_full_table_hidden";

  // not filterable on backend, but we want to enable filtering on it
  const forceFrontendColumns = [];

  return (
    <>
      {withCosts &&
        getDateRangePicker({
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          useSpecifiedPeriod,
          setUseSpecifiedPeriod,
          useSqm,
          setUseSqm,
          showMonthlyCosts,
          setShowMonthlyCosts,
        })}
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useManagementContractPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        localStorageHiddenColumnId={localStorageHiddenColumnId}
        detailUrlMethod={(obj) => detailUrl({ id: obj.id })}
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Förvaltningsavtal"}
        forceFrontendColumns={forceFrontendColumns}
        forceInitialPageSize={forceInitialPageSize}
        hidePagination={hidePagination}
        exportSheetController={exportSheetController}
        renderRowSubComponent={
          withCosts
            ? ({ row, tableWidth }) =>
                renderSubRow({ row, tableWidth, startDate, endDate })
            : null
        }
        {...{
          hideSearch,
          hideFilters,
          hideExport,
          hideColumns,
          onRowClicked,
          checkRowHighlighted,
          externalFilters,
          withPersistantSortBy,
          withPersistantGlobalFilter,
          persistantCategories,
          clearCategories,
          withFullScreen,
        }}
      />
    </>
  );
};
