import * as React from "react";
import { DetailInnerWrapper } from "../../../components/sharedStyles";
import * as SC from "./styles";

import keyIcon from "../../../assets/svg/material/management_key_black.svg";
import moduleIcon from "../../../assets/svg/material/worker_black.svg";
import articleIcon from "../../../assets/svg/material/article_black.svg";
import addBusinessIcon from "../../../assets/svg/material/add_business_black.svg";
import supportIcon from "../../../assets/svg/material/support_agent_black.svg";
import documentIcon from "../../../assets/svg/material/document_black.svg";
import invoicingIcon from "../../../assets/svg/material/account_balance_black.svg";
import { useHistory } from "react-router-dom";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import StandardModal from "../../../components/Modals/StandardModal";
import NonConnectedInputField from "../../../components/Forms/Base/Old/NonConnected/NonConnectedInputField";
import { setImpersonateToken, setImpersonateUserId } from "../../../utils";
import { getImpersonateUrl } from "../../../store/customers/store/actions";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { axiosInstance } from "../../../store/base/store/axios";

export default function HandleCustomers() {
  const dispatch = useDispatch();
  const [customerLoginOpen, setCustomerLoginOpen] = React.useState(false);
  const [username, setUsername] = React.useState("");

  const { push } = useHistory();

  const handleImpersonate = async () => {
    try {
      const { data } = await axiosInstance.post(
        "/accounts/auth/impersonation_auth/",
        {
          username: username,
        }
      );

      setImpersonateUserId(data.user_id);
      setImpersonateToken(data.token);

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Inloggning lyckades",
          description: "Sessionen öppnas i en ny flik",
        })
      );

      window.open(getImpersonateUrl(), "_blank").focus();

      setUsername("");
      setCustomerLoginOpen(false);
    } catch (e) {
      console.log(e);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Inloggning misslyckades",
          description: `Status: ${e?.response?.status}`,
        })
      );
    }
  };

  return (
    <>
      <StandardModal
        title="Logga in som kund"
        isOpen={customerLoginOpen}
        closeFunction={() => setCustomerLoginOpen(false)}
        withActionBar
        small
        canAccept={!!username}
        saveFunction={handleImpersonate}
        actionBarAcceptTitle="Logga in"
      >
        <NonConnectedInputField
          title="Användarnamn"
          value={username}
          onChange={({ target: { value } }) => setUsername(value)}
        />
      </StandardModal>

      <DetailInnerWrapper>
        <SC.Grid>
          <SC.GridItem>
            <SC.GridItemHeader>
              <SC.GridItemLogo image={addBusinessIcon} />
              <SC.GridItemTitleWrapper>
                <SC.GridItemTitle>Skapa ny kund</SC.GridItemTitle>
              </SC.GridItemTitleWrapper>
            </SC.GridItemHeader>

            <SC.GridItemDescription>
              Lägg till en ny kund i Pigello
            </SC.GridItemDescription>

            <SC.GridItemFooter>
              <TextButton
                iconType={"arrow"}
                iconPlacement="right"
                title={"Skapa kund"}
                clicked={() => push("/admin/handle/create-customer")}
              />
            </SC.GridItemFooter>
          </SC.GridItem>

          <SC.GridItem>
            <SC.GridItemHeader>
              <SC.GridItemLogo image={moduleIcon} />
              <SC.GridItemTitleWrapper>
                <SC.GridItemTitle>Hantera kunder</SC.GridItemTitle>
              </SC.GridItemTitleWrapper>
            </SC.GridItemHeader>

            <SC.GridItemDescription>
              Uppdatera kontaktinformation samt aktivera och inaktivera moduler
              för kunder
            </SC.GridItemDescription>

            <SC.GridItemFooter>
              <TextButton
                iconType={"arrow"}
                iconPlacement="right"
                title={"Hantera kunder"}
                clicked={() => push("/admin/handle/customer-modules")}
              />
            </SC.GridItemFooter>
          </SC.GridItem>

          <SC.GridItem>
            <SC.GridItemHeader>
              <SC.GridItemLogo image={supportIcon} />
              <SC.GridItemTitleWrapper>
                <SC.GridItemTitle>Logga in som kund</SC.GridItemTitle>
              </SC.GridItemTitleWrapper>
            </SC.GridItemHeader>

            <SC.GridItemDescription>
              Logga in som en kund för att underlätta support och felsökning
            </SC.GridItemDescription>

            <SC.GridItemFooter>
              <TextButton
                iconType={"arrow"}
                iconPlacement="right"
                title={"Logga in som en kund"}
                clicked={() => setCustomerLoginOpen(true)}
              />
            </SC.GridItemFooter>
          </SC.GridItem>

          <SC.GridItem>
            <SC.GridItemHeader>
              <SC.GridItemLogo image={keyIcon} />
              <SC.GridItemTitleWrapper>
                <SC.GridItemTitle>Förvaltningskonton</SC.GridItemTitle>
              </SC.GridItemTitleWrapper>
            </SC.GridItemHeader>

            <SC.GridItemDescription>
              Hantera kopplingen mellan förvaltningskonton och dess kunder
            </SC.GridItemDescription>

            <SC.GridItemFooter>
              <TextButton
                iconType={"arrow"}
                iconPlacement="right"
                title={"Hantera koppling av konton"}
                clicked={() => push("/admin/handle/management-accounts")}
              />
            </SC.GridItemFooter>
          </SC.GridItem>

          <SC.GridItem>
            <SC.GridItemHeader>
              <SC.GridItemLogo image={documentIcon} />
              <SC.GridItemTitleWrapper>
                <SC.GridItemTitle>Hantera Scrive-credentials</SC.GridItemTitle>
              </SC.GridItemTitleWrapper>
            </SC.GridItemHeader>

            <SC.GridItemDescription>
              Lägg in och hantera inloggningsuppfifter till Scrive för kunder
            </SC.GridItemDescription>

            <SC.GridItemFooter>
              <TextButton
                iconType={"arrow"}
                iconPlacement="right"
                title={"Hantera Scrive-credentials"}
                clicked={() =>
                  push("/admin/handle/scrive-credential-management")
                }
              />
            </SC.GridItemFooter>
          </SC.GridItem>

          <SC.GridItem>
            <SC.GridItemHeader>
              <SC.GridItemLogo image={invoicingIcon} />
              <SC.GridItemTitleWrapper>
                <SC.GridItemTitle>Tvinga aviseringsjobb</SC.GridItemTitle>
              </SC.GridItemTitleWrapper>
            </SC.GridItemHeader>

            <SC.GridItemDescription>
              Tvinga jobbet som kör avi-genereringen för en kund
            </SC.GridItemDescription>

            <SC.GridItemFooter>
              <TextButton
                iconType={"arrow"}
                iconPlacement="right"
                title={"Tvinga aviseringsjobb"}
                clicked={() => push("/admin/handle/force-invoice-generator")}
              />
            </SC.GridItemFooter>
          </SC.GridItem>

          <SC.GridItem disabled>
            <SC.GridItemHeader>
              <SC.GridItemLogo image={articleIcon} />
              <SC.GridItemTitleWrapper>
                <SC.GridItemTitle>Nyheter</SC.GridItemTitle>
              </SC.GridItemTitleWrapper>
            </SC.GridItemHeader>

            <SC.GridItemDescription>
              Lägg till och administrera Pigellos nyhetsflöde
            </SC.GridItemDescription>

            <SC.GridItemFooter>
              <TextButton
                iconType={"arrow"}
                iconPlacement="right"
                title={"Hantera Nyheter"}
                clicked={() => push("/admin/connect-management-accounts")}
              />
            </SC.GridItemFooter>
          </SC.GridItem>
        </SC.Grid>
      </DetailInnerWrapper>
    </>
  );
}
