import * as React from "react";
import * as SC from "./styles";

import StandardModal from "../../Modals/StandardModal";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import {
  deleteObject,
  getAskDeleteData,
} from "../../../store/base/store/actions";
import { InfoBox } from "../../Displays";

export default ({
  isOpen,
  instance,
  constants,
  closeFunction,
  errorCallback,
  deletedCallback,
  renderBanner,
}) => {
  const dispatch = useDispatch();
  const deleteData = useSelector((state) =>
    get(state, `${constants.STORE_NAME}.deleteData`)
  );

  React.useEffect(() => {
    if (isOpen && instance?.id) {
      dispatch(getAskDeleteData({ instance, constants }));
    }
  }, [isOpen, instance]);

  const successCallback = () => {
    closeFunction();
    if (deletedCallback) {
      deletedCallback();
    }
  };

  const onDelete = () => {
    dispatch(
      deleteObject({
        instance,
        constants,
        successCallback,
        errorCallback: () => {
          errorCallback && errorCallback();
          closeFunction();
        },
      })
    );
  };

  const renderDeleteData = () => {
    return Object.keys(deleteData).map((key) => {
      const type = deleteData[key];
      const readableType = type[0]?.type_verbose;

      return (
        <SC.DeleteDataType key={key}>
          <SC.DeleteDataTypeTitle>{readableType}</SC.DeleteDataTypeTitle>
          {type.map((item) => (
            <SC.DeleteDataItem key={item.id}>
              {item.str_representation}
            </SC.DeleteDataItem>
          ))}
        </SC.DeleteDataType>
      );
    });
  };

  return (
    <StandardModal
      isOpen={isOpen}
      title={`Radera ${instance?.str_representation}`}
      closeFunction={closeFunction}
      saveFunction={onDelete}
      withActionBar={true}
      actionBarAcceptTitle={"Radera"}
      actionBarAcceptIsWarning={true}
    >
      <>
        {renderBanner && (
          <InfoBox
            title={renderBanner?.title}
            text={renderBanner?.text}
            boxTheme={renderBanner?.boxTheme}
            renderButton={renderBanner?.button}
          />
        )}
        <SC.DeleteTitle>
          Du är påväg att radera {instance?.str_representation}
        </SC.DeleteTitle>
        <SC.DeleteSubtitle>
          Om du raderar {instance?.str_representation} raderas:
        </SC.DeleteSubtitle>

        <SC.DeleteDataWrapper>
          {deleteData && renderDeleteData()}
        </SC.DeleteDataWrapper>
      </>
    </StandardModal>
  );
};
