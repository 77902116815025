import {
  useCompanies,
  useFilteredCompanies,
  detailUrl as useCompanyDetailRedirect,
  useCompany,
  constants as companyConstants,
  performFilter as filterCompanies,
} from "./companies";

import {
  useFilteredManagementContracts,
  useManagementContracts,
  detailUrl as useManagementDetailRedirect,
  useManagementContract,
  constants as managementConstants,
  performFilter as filterManagements,
} from "./managementContracts";

import {
  useFilteredCustomers,
  useCustomers,
  detailUrl as useCustomerDetailRedirect,
  useCustomer,
  constants as customerConstants,
  performFilter as filterCustomers,
} from "./customers";

import {
  useFilteredUsers,
  useUsers,
  detailUrl as useUserDetailRedirect,
  useUser,
  constants as userConstants,
  performFilter as filterUsers,
} from "./users";

import {
  useBasicDocs,
  useFilteredBasicDocs,
  detailUrl as useBasicDetailRedirect,
  useBasicDoc,
  constants as basicConstants,
  performFilter as filterBasics,
} from "./basicDocs";

import {
  useAddresses,
  useFilteredAddresses,
  constants as addressConstants,
} from "./addresses";

import {
  constants as invoicingErrorConstants,
  performFilter as invoicingErrorPerformFilter,
} from "./invoicingErrors";

import {
  constants as invoicingSingleMappingConstants,
  performFilter as invoicingSingleMappingPerformFilter,
} from "./invoicingSingleMapping";

import {
  constants as invoicingProjectConstants,
  performFilter as invoicingProjectPerformFilter,
} from "./invoicingProjects";

import {
  constants as invoicingCostCenterConstants,
  performFilter as invoicingCostCenterPerformFilter,
} from "./invoicingCostCenters";

import {
  constants as invoicingProductsConstants,
  performFilter as invoicingProductsPerformFilter,
} from "./invoicingProducts";

import {
  constants as invoicingDebtSettingConstants,
  performFilter as invoicingDebtSettingPerformFilter,
} from "./invoicingDebtSettings";

import {
  constants as invoicingCompanyConstants,
  performFilter as invoicingCompanyPerformFilter,
} from "./invoicingCompany";

import {
  constants as invoicingSettingConstants,
  performFilter as invoicingSettingPerformFilter,
} from "./invoicingSettings";

import {
  constants as invoicingLeaseConstants,
  performFilter as invoicingLeasePerformFilter,
} from "./invoicingCustomer";

import {
  constants as invoicingDebtorConstants,
  performFilter as invoicingDebtorPerformFilter,
} from "./invoicingDebtor";

import {
  constants as fortnoxCredentialConstants,
  performFilter as fortnoxCredentialPerformFilter,
} from "./fortnoxCredential";

import {
  constants as fortnoxConfigConstants,
  performFilter as fortnoxConfigPerformFilter,
} from "./fortnoxConfig";

import {
  constants as fortnoxAccountConstants,
  performFilter as fortnoxAccountPerformFilter,
} from "./fortnoxAccount";

import {
  constants as fortnoxSupplierConstants,
  performFilter as fortnoxSupplierPerformFilter,
} from "./fortnoxSupplierInvoice";

import {
  constants as fortnoxTransactionConstants,
  performFilter as fortnoxTransactionPerformFilter,
} from "./fortnoxTransaction";

import { constants as calendarConstants } from "./calendar";
import { constants as calendarEventConstants } from "./calendarEvents";

import { constants as billectaAccountingReportConstants } from "./billectaAccountingReports";

import { constants as billectaAccountsRecievableConstants } from "./billectaAccountsReceivable";

import { constants as billectaBookKeepingConstants } from "./billectaBookKeeping";

import {
  constants as billectaEventConstants,
  performFilter as filterBillectaEvents,
} from "./billectaEvents";

import { constants as billectaIntegrationConstants } from "./billectaIntegrations";

import { constants as billectaInvoicingConstants } from "./billectaInvoicing";

import { constants as billectaKycConstants } from "./billectaKyc";

import { constants as billectaPaymentMeanConstants } from "./billectaPaymentMeans";

import { constants as overviewConstants } from "./overview";
import { store } from "./store";
import { buildQueryString, getBackgroundAddStateType } from "./base";
import { getCancelToken } from "./base/store/axios";
import { clearFetched } from "./base/store/actions";
import { debounce } from "lodash";

export const fetchMethodFromKind = (kind, filter) => {
  switch (kind) {
    case "standard.company":
      return filter ? useFilteredCompanies : useCompanies;
    case "management.customercontract":
      return filter ? useFilteredManagementContracts : useManagementContracts;
    case "accounts.user":
      return filter ? useFilteredUsers : useUsers;
    case "management.customer":
      return filter ? useFilteredCustomers : useCustomers;
    case "standard.basicdoc":
      return filter ? useFilteredBasicDocs : useBasicDocs;
    case "general.address":
      return filter ? useFilteredAddresses : useAddresses;
    default:
      return undefined;
  }
};

export const fetchSingleMethodFromKind = (kind) => {
  switch (kind) {
    case "standard.company":
      return useCompany;
    case "management.customercontract":
      return useManagementContract;
    case "accounts.user":
      return useUser;
    case "management.customer":
      return useCustomer;
    case "standard.basicdoc":
      return useBasicDoc;
    default:
      return undefined;
  }
};

export const redirectMethodFromKind = (kind) => {
  switch (kind) {
    case "standard.company":
      return useCompanyDetailRedirect;
    case "management.customercontract":
      return useManagementDetailRedirect;
    case "accounts.user":
      return useUserDetailRedirect;
    case "management.customer":
      return useCustomerDetailRedirect;
    case "standard.basicdoc":
      return useBasicDetailRedirect;
    default:
      return undefined;
  }
};

export const resetAllStates = () => {
  return async (dispatch) => {
    dispatch({
      type: companyConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: addressConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: customerConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: userConstants.RESET_STATE,
      payload: {},
    });

    dispatch({
      type: basicConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: overviewConstants.RESET_STATE,
      payload: {},
    });

    dispatch({
      type: managementConstants.RESET_STATE,
      payload: {},
    });

    dispatch({
      type: billectaAccountingReportConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaAccountsRecievableConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaBookKeepingConstants.RESET_STATE,
      payload: {},
    });

    dispatch({
      type: billectaEventConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaIntegrationConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaInvoicingConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaKycConstants.RESET_STATE,
      payload: {},
    });
    dispatch({
      type: billectaPaymentMeanConstants.RESET_STATE,
      payload: {},
    });
  };
};

export const getWantedBackgroundTasks = () => {
  // currently all the background tasks we want is to fetch all data
  // for leasecontracts, apartments, industrialpremises

  // if you add other actions than filter actions, make sure
  // that they accept the taskToken parameter

  let result = [];
  const querystring = "";
  const state = store.getState();

  const allWanted = [
    [managementConstants.STORE_NAME, filterManagements],
    [customerConstants.STORE_NAME, filterCustomers],
    [basicConstants.STORE_NAME, filterBasics],
  ];

  allWanted.forEach((w) => {
    const [storeName, filterAction] = w;

    // we already got it, or we've already activated it
    if (
      state[storeName].filtered[querystring] ||
      state[storeName].inProgress.includes(querystring)
    ) {
      return;
    }

    // assumes an array of holding values [filterAction, name, "state type to add background task"]
    result.push([
      (dispatch) =>
        dispatch(filterAction(querystring, undefined, getCancelToken())),
      querystring,
      getBackgroundAddStateType(storeName),
    ]);
  });

  return result;
};

const getConstants = (key) => {
  switch (key) {
    case "events.calendar":
      return calendarConstants;
    case "events.todo":
      return calendarEventConstants;
    case "standard.company":
      return companyConstants;
    case "management.customercontract":
      return managementConstants;

    case "management.customer":
      return customerConstants;
    case "accounts.user":
      return userConstants;

    case "standard.basicdoc":
      return basicConstants;

    case "accounting.reportevent":
      return billectaEventConstants;

    case "accounting.billectaouterror": {
      return invoicingErrorConstants;
    }
    case "accounting.billectasingleinvoicemapping": {
      return invoicingSingleMappingConstants;
    }
    case "accounting.project": {
      return invoicingProjectConstants;
    }
    case "accounting.costcenter": {
      return invoicingCostCenterConstants;
    }
    case "accounting.product": {
      return invoicingProductsConstants;
    }
    case "accounting.debthandlingsetting": {
      return invoicingDebtSettingConstants;
    }
    case "accounting.companyinvoiceconfig": {
      return invoicingCompanyConstants;
    }

    case "accounting.invoicingsetting": {
      return invoicingSettingConstants;
    }
    case "accounting.customerinvoicing": {
      return invoicingLeaseConstants;
    }

    case "accounting.debtorinvoiceconfig": {
      return invoicingDebtorConstants;
    }
    case "accounting.fortnoxcredential": {
      return fortnoxCredentialConstants;
    }
    case "accounting.fortnoxconfig": {
      return fortnoxConfigConstants;
    }
    case "accounting.fortnoxaccount": {
      return fortnoxAccountConstants;
    }
    case "accounting.fortnoxtransaction": {
      return fortnoxTransactionConstants;
    }
    case "accounting.fortnoxsuppliererrandinvoice": {
      return fortnoxSupplierConstants;
    }

    default:
      return undefined;
  }
};

// just for info
const APP_LABELS = [
  "accounts",
  "general",
  "events",
  "standard",
  "apihandler",
  "permissions",
  "accounting",
  "sales",
  "market",
  "errands",
  "brf",
  "imd",
];

// just for info
const MODEL_NAMES = [
  //accounts
  "user",
  "tenant",
  "tenantportalsetting",
  "usergroup",
  "notificationsetting",
  "anonymization",

  //general
  "mutation",
  "adress",
  "contractcost",
  "note",
  "tag",

  //events
  "todo",
  "notification",

  //standard
  "company",
  "realestate",
  "building",
  "editabledoc",
  "blueprint",
  "room",
  "basicdoc",
  "apartment",
  "industrialpremises",
  "rentincrease",
  "commonarea",
  "blockrental",
  "leasecontract",
  "othercontract",
  "parkingspecification",
  "parkingzone",
  "parkinglot",
  "parkingspot",
  "parkingcontract",
  "servicepartner",
  "servicecontract",

  // permissions
  "permdetail",
  "permrule",

  // accounting
  "billectaouterror",
  "billectasingleinvoicemapping",
  "project",
  "costcenter",
  "product",
  "cost",
  "debthandlingsetting",
  "companyinvoiceconfig",
  "imdinvoiceconfig",
  "invoicingsetting",
  "leaseinvoicing",
  "otherinvoicing",
  "parkinginvoicing",
  "brfinvoicing",
  "serviceinvoicing",
  "leaseimdinvoicing",
  "parkingimdinvoicing",
  "brfimdinvoicing",
  "debtorinvoiceconfig",
  "reportevent",
  "kycreport",
  "fortnoxcredential",
  "fortnoxconfig",
  "fortnoxaccount",
  "fortnoxtransaction",
  "fortnoxsuppliererrandinvoice",

  //errands
  "topclassification",
  "middleclassification",
  "componentproduct",
  "bottomclassification",
  "componentrating",
  "component",
  "roundingcomponent",
  "roundingarea",
  "costarticle",
  "role",
  "roleuser",
  "roleorder",
  "userorder",
  "servicepartnerorder",
  "reporterrandsetting",
  "inspectionerrandsetting",
  "roundingerrandsetting",
  "inspectionerrand",
  "reporterrand",
  "roundingfault",
  "roundingerrand",

  //market
  "homeqcredential",
  "dynamicpipeattribute",
  "pipeattributechoices",
  "pipe",
  "stage",
  "milestone",
  "lead",
  "pipeattributevalue",
  "commodities",
  "requirement",
  "ad",

  //brf
  "brfowner",
  "brfpledge",
  "brfpremis",
  "brfcontract",
  "brfcompany",
  "plannedfeeraise",
  "brfgroup",

  // imd
  "measuretype",
  "pricevalue",
  "sensor",
  "sensormeasurevalue",
];

/**
 * object with debounce function dynamically created when needed
 * key            :  value
 * socketStoreName: debounced function
 */
const debouncedDefs = {};

const updateLocalStore = ({ socketStoreName, dispatch }) => {
  // console.log("[UPDATE LOCAL STORAGE] - [socketStoreName]: ", socketStoreName);
  const constants = getConstants(socketStoreName);
  dispatch(clearFetched(constants, true));
};

export const handleSocketUpdates = (event, dispatch) => {
  // data is an array of strings, "${app_label}_${model_name}_${id}" for a specific object or "${app_label}_${model_name}" for clearing lists
  const data = JSON.parse(event?.data);
  const state = store.getState();

  let handled = [];

  data.forEach((cacheStr) => {
    const [appLabel, modelName, id] = cacheStr.split("_");
    const socketStoreName = `${appLabel}.${modelName}`;
    if (handled.includes(socketStoreName)) {
      return;
    }

    const constants = getConstants(socketStoreName);

    // not found or we don't care about it;
    if (!constants) return;

    // const queryString = buildQueryString({
    //   id__in: id || [],
    // });

    if (id) {
      // update specific object
      // skip this - a re-fetch of a lot of instances that we probably currently don't care about
      // since they're probably far away from beeing rendered may happen otherwice
      // better to just clear the fetched data so that when we do use a given instance
      // it's refreshed at that point in time
      return;

      // const isInProgress = state[storeName]?.inProgress?.includes(queryString);
      // if (isInProgress) {
      //   return;
      // }
    } else {
      /**
       * If we already have the debounced function setup, call it
       * else
       * create it and then call it
       */

      if (debouncedDefs[socketStoreName]) {
        const debouncedFunc = debouncedDefs[socketStoreName];
        // console.log(
        //   "[EXISTING DEBOUNCE CALL] - [socketStoreName]: ",
        //   socketStoreName
        // );
        debouncedFunc({ socketStoreName, dispatch });
      } else {
        debouncedDefs[socketStoreName] = debounce(updateLocalStore, 1000);
        const debouncedFunc = debouncedDefs[socketStoreName];
        // console.log(
        //   "[NEW DEBOUNCE CALL] - [socketStoreName]: ",
        //   socketStoreName
        // );
        debouncedFunc({ socketStoreName, dispatch });
      }

      handled.push(socketStoreName);
      return;
    }
  });
};
