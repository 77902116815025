import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import appReducer from "./app/reducer";

import {
  reducer as internalCustomerReducer,
  constants as internalCustomerConstants,
} from "./internalCustomers";

import {
  reducer as globalSearchReducer,
  constants as globalSearchConstants,
} from "./globalSearch";

import { constants as notesConstants, reducer as notesReducer } from "./notes";
import { constants as costsConstants, reducer as costsReducer } from "./costs";

import companyReducer from "./companies/store/reducer";
import companyConstants from "./companies/store/constants";

import {
  constants as singleInvoiceMappingConstants,
  reducer as singleInvoiceMappingReducer,
} from "./invoicingSingleMapping";

import {
  constants as invoicingDebtorConstants,
  reducer as invoicingDebtorReducer,
} from "./invoicingDebtor";

import {
  constants as invoicingCompanyConstants,
  reducer as invoicingCompanyReducer,
} from "./invoicingCompany";

import {
  constants as invoicingSettingConstants,
  reducer as invoicingSettingReducer,
} from "./invoicingSettings";

import {
  constants as invoicingErrorConstants,
  reducer as invoicingErrorReducer,
} from "./invoicingErrors";

import {
  constants as invoicingDebtSettingConstants,
  reducer as invoicingDebtSettingReducer,
} from "./invoicingDebtSettings";

import {
  constants as invoicingLeaseConstants,
  reducer as invoicingLeaseReducer,
} from "./invoicingCustomer";

import {
  constants as invoicingProductConstants,
  reducer as invoicingProductReducer,
} from "./invoicingProducts";

import {
  constants as invoicingCostCenterConstants,
  reducer as invoicingCostCenterReducer,
} from "./invoicingCostCenters";

import {
  constants as invoicingProjectConstants,
  reducer as invoicingProjectReducer,
} from "./invoicingProjects";

import {
  constants as invoicingCustomDimensionConstants,
  reducer as invoicingCustomDimensionReducer,
} from "./invoicingCustomDimensions";

import {
  constants as invoicingCustomDimensionEntryConstants,
  reducer as invoicingCustomDimensionEntryReducer,
} from "./invoicingCustomDimensionEntries";

import leaseContractReducer from "./managementContracts/store/reducer";
import leaseContractConstants from "./managementContracts/store/constants";

import userReducer from "./users/store/reducer";
import userConstants from "./users/store/constants";

import userGroupReducer from "./userGroups/store/reducer";
import userGroupConstants from "./userGroups/store/constants";

import tenantReducer from "./customers/store/reducer";
import tenantConstants from "./customers/store/constants";

import overviewReducer from "./overview/store/reducer";
import overviewConstants from "./overview/store/constants";

import basicDocReducer from "./basicDocs/store/reducer";
import basicDocConstants from "./basicDocs/store/constants";

import addressReducer from "./addresses/store/reducer";
import addressConstants from "./addresses/store/constants";

import notificationReducer from "./notifications/store/reducer";
import notificationConstants from "./notifications/store/constants";

import invoiceReducer from "./billectaInvoicing/store/reducer";
import invoiceConstants from "./billectaInvoicing/store/constants";

import invoiceReportsReducer from "./billectaReports/store/reducer";
import invoiceReportsConstants from "./billectaReports/store/constants";

import invoiceSearchReducer from "./billectaSearch/store/reducer";
import invoiceSearchConstants from "./billectaSearch/store/constants";

import editableDocReducer from "./editabledocs/store/reducer";
import editableDocConstants from "./editabledocs/store/constants";

import notificationsDisplayReducer from "./notificationsDisplay/store/reducer";
import notificationsDisplayConstants from "./notificationsDisplay/store/constants";

import bookKeepingReducer from "./billectaBookKeeping/store/reducer";
import bookKeepingConstants from "./billectaBookKeeping/store/constants";

import paymentMeansReducer from "./billectaPaymentMeans/store/reducer";
import paymentMeansConstants from "./billectaPaymentMeans/store/constants";

import accountingReportsReducer from "./billectaAccountingReports/store/reducer";
import accountingReportsConstants from "./billectaAccountingReports/store/constants";

import billectaIntegrationsReducer from "./billectaIntegrations/store/reducer";
import billectaIntegrationsConstants from "./billectaIntegrations/store/constants";

import billectaEventsReducer from "./billectaEvents/store/reducer";
import billectaEventsConstants from "./billectaEvents/store/constants";

import {
  constants as billectaAccountsBalanceConstants,
  reducer as billectaAccountsBalanceReducer,
} from "./billectaAccountBalance";

import billectaKycReducer from "./billectaKyc/store/reducer";
import billectaKycConstants from "./billectaKyc/store/constants";

import billectaAccountsReceivableReducer from "./billectaAccountsReceivable/store/reducer";
import billectaAccountsReceivableConstants from "./billectaAccountsReceivable/store/constants";

import { reducer as toastReducer, constants as toastConstants } from "./toasts";

import {
  constants as whitelistPermsConstants,
  reducer as whitelistPermsReducer,
} from "./whitelistPerms";

import fortnoxAccountReducer from "./fortnoxAccount/store/reducer";
import fortnoxAccountConstants from "./fortnoxAccount/store/constants";

import fortnoxConfigReducer from "./fortnoxConfig/store/reducer";
import fortnoxConfigConstants from "./fortnoxConfig/store/constants";

import fortnoxSupplierInvoiceReducer from "./fortnoxSupplierInvoice/store/reducer";
import fortnoxSupplierInvoiceConstants from "./fortnoxSupplierInvoice/store/constants";

import fortnoxTransactionReducer from "./fortnoxTransaction/store/reducer";
import fortnoxTransactionConstants from "./fortnoxTransaction/store/constants";

import fortnoxCredentialReducer from "./fortnoxCredential/store/reducer";
import fortnoxCredentialConstants from "./fortnoxCredential/store/constants";

import calendarReducer from "./calendar/store/reducer";
import calendarConstants from "./calendar/store/constants";

import calendarEventReducer from "./calendarEvents/store/reducer";
import calendarEventConstants from "./calendarEvents/store/constants";

import outlookEventReducer from "./outlookCalendarEvents/store/reducer";
import outlookEventConstants from "./outlookCalendarEvents/store/constants";

import outlookCalendarReducer from "./outlookCalendar/store/reducer";
import outlookCalendaronstants from "./outlookCalendar/store/constants";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    [internalCustomerConstants.STORE_NAME]: internalCustomerReducer,
    [overviewConstants.STORE_NAME]: overviewReducer,
    [outlookCalendaronstants.STORE_NAME]: outlookCalendarReducer,
    [outlookEventConstants.STORE_NAME]: outlookEventReducer,
    [calendarConstants.STORE_NAME]: calendarReducer,
    [calendarEventConstants.STORE_NAME]: calendarEventReducer,
    [editableDocConstants.STORE_NAME]: editableDocReducer,
    [fortnoxAccountConstants.STORE_NAME]: fortnoxAccountReducer,
    [fortnoxCredentialConstants.STORE_NAME]: fortnoxCredentialReducer,
    [fortnoxConfigConstants.STORE_NAME]: fortnoxConfigReducer,
    [fortnoxSupplierInvoiceConstants.STORE_NAME]: fortnoxSupplierInvoiceReducer,
    [fortnoxTransactionConstants.STORE_NAME]: fortnoxTransactionReducer,
    [globalSearchConstants.STORE_NAME]: globalSearchReducer,
    [notesConstants.STORE_NAME]: notesReducer,
    [costsConstants.STORE_NAME]: costsReducer,
    [companyConstants.STORE_NAME]: companyReducer,
    [userConstants.STORE_NAME]: userReducer,
    [userGroupConstants.STORE_NAME]: userGroupReducer,
    [whitelistPermsConstants.STORE_NAME]: whitelistPermsReducer,
    [tenantConstants.STORE_NAME]: tenantReducer,
    [leaseContractConstants.STORE_NAME]: leaseContractReducer,
    [invoicingErrorConstants.STORE_NAME]: invoicingErrorReducer,
    [singleInvoiceMappingConstants.STORE_NAME]: singleInvoiceMappingReducer,
    [invoicingDebtorConstants.STORE_NAME]: invoicingDebtorReducer,
    [invoicingCompanyConstants.STORE_NAME]: invoicingCompanyReducer,
    [invoicingSettingConstants.STORE_NAME]: invoicingSettingReducer,
    [invoicingDebtSettingConstants.STORE_NAME]: invoicingDebtSettingReducer,
    [invoicingLeaseConstants.STORE_NAME]: invoicingLeaseReducer,
    [invoicingProductConstants.STORE_NAME]: invoicingProductReducer,
    [invoicingCostCenterConstants.STORE_NAME]: invoicingCostCenterReducer,
    [invoicingProjectConstants.STORE_NAME]: invoicingProjectReducer,
    [invoicingCustomDimensionConstants.STORE_NAME]:
      invoicingCustomDimensionReducer,
    [invoicingCustomDimensionEntryConstants.STORE_NAME]:
      invoicingCustomDimensionEntryReducer,
    [basicDocConstants.STORE_NAME]: basicDocReducer,
    [addressConstants.STORE_NAME]: addressReducer,
    [notificationConstants.STORE_NAME]: notificationReducer,
    [invoiceConstants.STORE_NAME]: invoiceReducer,
    [invoiceReportsConstants.STORE_NAME]: invoiceReportsReducer,
    [invoiceSearchConstants.STORE_NAME]: invoiceSearchReducer,
    [notificationsDisplayConstants.STORE_NAME]: notificationsDisplayReducer,
    [bookKeepingConstants.STORE_NAME]: bookKeepingReducer,
    [paymentMeansConstants.STORE_NAME]: paymentMeansReducer,
    [accountingReportsConstants.STORE_NAME]: accountingReportsReducer,
    [billectaIntegrationsConstants.STORE_NAME]: billectaIntegrationsReducer,
    [billectaEventsConstants.STORE_NAME]: billectaEventsReducer,
    [billectaAccountsBalanceConstants.STORE_NAME]:
      billectaAccountsBalanceReducer,
    [billectaKycConstants.STORE_NAME]: billectaKycReducer,
    [billectaAccountsReceivableConstants.STORE_NAME]:
      billectaAccountsReceivableReducer,
    [toastConstants.STORE_NAME]: toastReducer,
  });

export default createRootReducer;
