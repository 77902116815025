import * as React from "react";

import { TextInput } from "../../../Forms/Base/Fields";
import * as SC from "../styles";

export default function TenantChapter({
  tenant,
  debtorConfig,
  constants,
  method,
  fieldKey,
  instructionsKey,
  isBrf,
}) {
  return (
    <SC.InvoicedBox flexed>
      <SC.InvoicedBoxTitleWrapper>
        <SC.InvoicedBoxTitle>Kund</SC.InvoicedBoxTitle>
      </SC.InvoicedBoxTitleWrapper>

      <SC.InvoicedBoxContent>
        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>Namn:</SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            {tenant.str_representation}
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>

        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>Adress:</SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            {debtorConfig?.invoice_address?.base || "Adress saknas"}
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>

        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>E-post:</SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            {debtorConfig?.email_invoice || "E-post saknas"}
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>

        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>Telefon:</SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            {debtorConfig?.invoice_phone || "Telefon saknas"}
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>

        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>Kundens referens:</SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            <TextInput
              rowSize
              noMargin
              noTitle
              storeName={constants.STORE_NAME}
              method={method}
              fieldKey={fieldKey || `YourReference`}
              {...{ instructionsKey }}
            />
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>

        {!isBrf && (
          <SC.InvoicedBoxRow>
            <SC.InvoicedBoxRowTitle>Hyresgästnummer:</SC.InvoicedBoxRowTitle>

            <SC.InvoicedBoxRowValue>
              {tenant.customer_id || "Ej inställt"}
            </SC.InvoicedBoxRowValue>
          </SC.InvoicedBoxRow>
        )}
      </SC.InvoicedBoxContent>
    </SC.InvoicedBox>
  );
}
