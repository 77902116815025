import * as React from "react";
import { SecondaryButton } from "../../../Forms/Base/Buttons/styles";

import * as SC from "../styles";

export default function InvoiceChapter({
  title,
  description,
  children,
  openCallback,
  closeCallback,
  isAddRemove,
  forceOpen,
  hideToggle,
}) {
  const [open, setOpen] = React.useState(forceOpen ? true : false);

  // prevent callbacks from running without user input
  const [initiated, setInitiated] = React.useState(false);

  React.useEffect(() => {
    if (forceOpen) {
      setOpen(true);
    }
  }, [forceOpen]);

  React.useEffect(() => {
    if (!initiated) {
      setInitiated(true);
      return;
    }
    if (open && openCallback) {
      openCallback();
    } else if (closeCallback) {
      closeCallback();
    }
  }, [open]);

  return (
    <SC.InvoicedBox>
      <SC.InvoicedBoxTitleWrapper
        onClick={hideToggle ? null : () => setOpen(!open)}
      >
        <SC.InvoicedBoxTitleTextWrapper>
          <SC.InvoicedBoxTitle>{title}</SC.InvoicedBoxTitle>
          {description && (
            <SC.InvoicedBoxSubtitle>{description}</SC.InvoicedBoxSubtitle>
          )}
        </SC.InvoicedBoxTitleTextWrapper>
        {isAddRemove ? (
          <SecondaryButton>{open ? "Radera" : "Lägg till"}</SecondaryButton>
        ) : (
          !hideToggle && <SC.ToggleOpenButton open={open} />
        )}
      </SC.InvoicedBoxTitleWrapper>

      <SC.InvoicedBoxContent>{open ? children : null}</SC.InvoicedBoxContent>
    </SC.InvoicedBox>
  );
}
