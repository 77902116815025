import styled from "styled-components";

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
`;

export const Activity = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 4px;
  border: ${(p) => p.theme.borders.standard};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.gray1};
  }
`;

export const ActivityTimestamp = styled.div`
  color: ${(p) => p.theme.colors.secondaryText};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: 12px;
`;

export const ActivityTitle = styled.div`
  color: ${(p) => p.theme.colors.primaryText};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: 12px;
`;
