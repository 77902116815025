import * as React from "react";
import {
  DatePicker,
  NumberInput,
  RadioGroup,
  Select,
  TextField,
  TextInput,
} from "../../../Base/Fields";

import { constants } from "../../../../../store/invoicingCustomer";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import InvoicingSettingDefault from "../../../../Summaries/InvoicingSettingDefault";
import { getIntervalStr } from "../../../../Displays/InvoicingHelpers";
import { useDispatch } from "react-redux";
import { TextButton } from "../../../Base/Buttons";
import { cloneDeep } from "lodash";
import { StatusLabel } from "../../../../Lists/Base/CompleteList/styles";
import { useCompanyInvoicing } from "../../../../../store/invoicingCompany";
import { useParams } from "react-router";
import { useManagementContract } from "../../../../../store/managementContracts";
import { useCustomer } from "../../../../../store/customers";
import { useDebtorInvoicing } from "../../../../../store/invoicingDebtor";
import { BodyText } from "../../../../sharedStyles";
import { InfoBox } from "../../../../Displays";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { contractId } = useParams();
  const [managementContract] = useManagementContract(contractId);
  const [customer] = useCustomer(managementContract?.management_customer?.id);
  const [debtorInvoicingConfig] = useDebtorInvoicing(
    customer?.debtor_invoice_config?.id
  );
  const [invoiceMonthDisabled, setInvoiceMonthDisabled] = React.useState(false);

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const intervalMode = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.mode",
  });
  const invoicePeriod = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.invoice_period",
  });

  const intervalMonth = useFormInstanceField({
    storeName,
    fieldKey: "interval_setting.on_month",
  });

  const yourReference = useFormInstanceField({
    storeName,
    fieldKey: "your_reference",
  });

  const ourReference = useFormInstanceField({
    storeName,
    fieldKey: "our_reference",
  });

  const invoiceMessage = useFormInstanceField({
    storeName,
    fieldKey: "message",
  });

  const defaultSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const requireCostAccept = useFormInstanceField({
    storeName,
    fieldKey: "require_cost_accept",
  });

  const adminFee = useFormInstanceField({
    storeName,
    fieldKey: "admin_fee",
  });

  const autoMerge = useFormInstanceField({
    storeName,
    fieldKey: "auto_merge",
  });

  const autoAttest = useFormInstanceField({
    storeName,
    fieldKey: "auto_attest",
  });

  const billingCompany = useFormInstanceField({
    storeName,
    fieldKey: "billing_company",
  });

  const deliveryMethod = useFormInstanceField({
    storeName,
    fieldKey: "delivery_method",
  });

  const [companyInvoicingConfig] = useCompanyInvoicing(
    billingCompany?.invoicing_details?.id
  );

  React.useEffect(() => {
    // cant be previous on quarterly and yearly mode
    if ((intervalMode === 1 || intervalMode === 2) && invoicePeriod === -1) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ["interval_setting.invoice_period"]: 0,
          },
        })
      );

      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Faktureringsperioden sattes till innevarande",
          description:
            "Faktureringsperioden kan ej vara föregående vid årsvis eller kvartalsvis avisering",
        })
      );
    }

    // if invoice period is next, intervalmode is quarter, intervalmonth must be 3
    if (invoicePeriod === 1 && intervalMode === 1) {
      setInvoiceMonthDisabled(true);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ["interval_setting.on_month"]: 3,
          },
        })
      );

      if (intervalMonth !== 3) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Månad i kvartal för aviseringsintervallet sattes till 3",
            description:
              "Vid nästkommande faktureringsperiod och kvartalsvis avisering måste månaden var den sista i kvartalet",
          })
        );
      }

      // if invoice period is next, intervalmode is year, intervalmonth must be 12
    } else if (invoicePeriod === 1 && intervalMode === 2) {
      setInvoiceMonthDisabled(true);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ["interval_setting.on_month"]: 12,
          },
        })
      );

      if (intervalMonth !== 12) {
        dispatch(
          addToast({
            type: TOAST_TYPES.INFO,
            title: "Månad i år för aviseringsintervallet sattes till 12",
            description:
              "Vid nästkommande faktureringsperiod och årsvis avisering måste månaden var den sista i året",
          })
        );
      }
    } else {
      setInvoiceMonthDisabled(false);
    }
  }, [invoicePeriod, intervalMode, intervalMonth]);

  const renderInvoiceInfoChapter = () => {
    return (
      <>
        <hr />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Fakturainformation</OverviewTitle>
            <OverviewSubtitle>
              Inställningar som berör vilken information som ska finnas med på
              fakturor.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {ourReference != null ||
        companyInvoicingConfig?.our_reference == null ? (
          <>
            {companyInvoicingConfig?.our_reference != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  delete instanceClone.our_reference;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <TextInput
              title="Vår referens"
              description={`"Vår referens" på fakturor som genereras utifrån detta avtal.`}
              {...{ storeName, method, fieldKey: "our_reference" }}
            />
          </>
        ) : (
          <InvoicingSettingDefault
            extraStyle={{ marginBottom: 36 }}
            title="Vår referens"
            sourceTitle="Från valt bolag"
            content={[
              {
                title: "Vår referens",
                content: companyInvoicingConfig?.our_reference,
              },
            ]}
            renderContent
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    our_reference: "",
                  },
                })
              );
            }}
          />
        )}

        {yourReference != null ||
        debtorInvoicingConfig?.your_reference == null ? (
          <>
            {debtorInvoicingConfig?.your_reference != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  delete instanceClone.your_reference;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <TextInput
              title="Er referens"
              description={`"Er referens" på fakturor som genereras utifrån detta avtal.`}
              {...{ storeName, method, fieldKey: "your_reference" }}
            />
          </>
        ) : (
          <InvoicingSettingDefault
            extraStyle={{ marginBottom: 36 }}
            title="Er referens"
            sourceTitle="Från kundens inställningar"
            content={[
              {
                title: "Er referens",
                content: debtorInvoicingConfig?.your_reference,
              },
            ]}
            renderContent
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    your_reference: "",
                  },
                })
              );
            }}
          />
        )}

        <TextInput
          title="Referensmärkning på fakturor"
          description={`"Extern referens" på fakturor som genereras utifrån detta avtal.`}
          {...{ storeName, method, fieldKey: "external_reference" }}
        />

        {invoiceMessage != null || defaultSetting?.message == null ? (
          <>
            {defaultSetting?.message != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);

                  delete instanceClone.message;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <TextField
              title="Meddelande på fakturor"
              {...{ storeName, method, fieldKey: "message" }}
            />
          </>
        ) : (
          <InvoicingSettingDefault
            extraStyle={{ marginBottom: 36 }}
            title="Fakturameddelande"
            content={[
              {
                title: "Meddelande på fakturor",
                content: defaultSetting?.message,
              },
            ]}
            renderContent
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    message: "",
                  },
                })
              );
            }}
          />
        )}
        {debtorInvoicingConfig?.default_invoice_message && (
          <>
            <BodyText>OBS: Meddelandet som är inställt på kunden: </BodyText>
            <BodyText style={{ fontStyle: "italic", margin: "8px 0" }}>
              "{debtorInvoicingConfig.default_invoice_message}"
            </BodyText>
            <BodyText>
              kommer att läggas till efter detta meddelande på avierna.
            </BodyText>
          </>
        )}
      </>
    );
  };

  const renderIntervalChapter = () => {
    return (
      <>
        <hr />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Faktureringsintervall</OverviewTitle>
            <OverviewSubtitle>
              Inställningar som berör när och hur fakturor genereras
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {intervalMode != null || defaultSetting?.interval_setting == null ? (
          <>
            {defaultSetting?.interval_setting != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  delete instanceClone.interval_setting;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            {renderIntervalSetting()}
          </>
        ) : (
          <InvoicingSettingDefault
            title="Faktureringsintervall"
            content={[
              {
                title: "Intervall",
                content: getIntervalStr(defaultSetting?.interval_setting),
              },
              {
                title: "Faktureringsperiod",
                content:
                  defaultSetting?.interval_setting?.invoice_period_display,
              },
            ]}
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    "interval_setting.mode": 0,
                  },
                })
              );
            }}
          />
        )}
      </>
    );
  };

  const renderIntervalSetting = () => {
    return (
      <>
        <Select
          title="Typ av faktureringsintervall"
          description={`Typ av intervall för när fakturor ska genereras och skickas. Vid månadsvis fakturering väljs t.ex. "Per månad".`}
          {...{ storeName, method, fieldKey: "interval_setting.mode" }}
        />

        {/* Per month */}
        {intervalMode === 0 && (
          <>
            <NumberInput
              title="Månadsintervall"
              description={`Ange i vilket månadsintervall fakturor ska genereras. Ska en faktura genereras varje månad anges 1, varannan månad 2 osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.interval",
              }}
            />

            <NumberInput
              title="Dag i månad som faktura ska genereras"
              description={`Ange vilken dag i varje månad som en faktura ska genereras upp och skickas.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.day_in_month",
              }}
            />
          </>
        )}

        {/* Quarterly */}
        {intervalMode === 1 && (
          <>
            <NumberInput
              title="Kvartalsintervall"
              description={`Ange i vilket kvartalsintervall fakturor ska genereras. Ska en faktura genereras varje kvartal anges "1", varannat kvartal "2" osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.interval",
              }}
            />

            {invoiceMonthDisabled && (
              <div style={{ maxWidth: 600 }}>
                <InfoBox
                  boxTheme="info"
                  text="Vid nästkommande period och kvartalsintervall måste månaden vara 3"
                />
              </div>
            )}
            <NumberInput
              disabled={invoiceMonthDisabled}
              title="Månad i kvartal som faktura ska genereras"
              description={`Ange vilken månaden i kvartalet som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas 1:a månaden kvartalet så anges "1".`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.on_month",
              }}
            />

            <NumberInput
              title="Dag i månad som faktura ska genereras"
              description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.day_in_month",
              }}
            />
          </>
        )}

        {/* yearly */}
        {intervalMode === 2 && (
          <>
            <NumberInput
              title="Årsintervall"
              description={`Ange i vilket årsintervall fakturor ska genereras. Ska en faktura genereras varje år anges "1", varannat år "2" osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.interval",
              }}
            />

            {invoiceMonthDisabled && (
              <div style={{ maxWidth: 600 }}>
                <InfoBox
                  boxTheme="info"
                  text="Vid nästkommande period och årsintervall måste månaden vara 12"
                />
              </div>
            )}
            <NumberInput
              disabled={invoiceMonthDisabled}
              title="Månad som faktura ska genereras"
              description={`Ange vilken månaden på året som en faktura ska genereras upp och skickas. T.ex. om fakturan ska skickas i Januari så anges "1", Juli så anges "7" osv.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.on_month",
              }}
            />

            <NumberInput
              title="Dag i månad som faktura ska genereras"
              description={`Ange vilken dag i månaden för generering som en faktura ska genereras upp och skickas.`}
              {...{
                storeName,
                method,
                fieldKey: "interval_setting.day_in_month",
              }}
            />
          </>
        )}

        {(intervalMode === 1 || intervalMode === 2) && (
          <div style={{ maxWidth: 600 }}>
            <InfoBox
              boxTheme="info"
              text="Faktureringsperioden kan ej vara föregående vid kvartalsvis eller årsvis avisering"
            />
          </div>
        )}
        <Select
          title="Faktureringsperiod"
          filterChoices={(c) => {
            if ([1, 2].includes(intervalMode)) {
              return c.v === -1 ? false : true;
            }
            return true;
          }}
          {...{
            storeName,
            method,
            fieldKey: "interval_setting.invoice_period",
          }}
        />
      </>
    );
  };

  const renderHandleChapter = () => {
    return (
      <>
        <hr />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Hantering av fakturor</OverviewTitle>
            <OverviewSubtitle>
              Inställningar som berör hur fakturor ska hanteras
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {requireCostAccept != null ||
        defaultSetting?.require_cost_accept == null ? (
          <>
            {defaultSetting?.require_cost_accept != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  delete instanceClone.require_cost_accept;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <RadioGroup
              title="Kräv godkännande av kostnader för avisering"
              description="Om denna inställning är aktiv krävs det att en användare som har tillgång till aviseringsmodulen (Pigello Hyra) godkänner ändringar eller tillägg av kostnader och rabatter. Exempel: En användare som har rätt att redigera avtalet lägger till en rabatt på 200 SEK. Denna rabatt kommer ej att tas hänsyn till i aviseringen innan en användare som har tillgång till Pigello Hyra har godkänt rabatten."
              {...{ storeName, method, fieldKey: "require_cost_accept" }}
              forceHorizontalToolTipPlacement="left"
              options={[
                { label: "Ja", value: true },
                { label: "Nej", value: false },
              ]}
              defaultValue={false}
            />
          </>
        ) : (
          <InvoicingSettingDefault
            extraStyle={{ marginBottom: 36 }}
            title="Kostnadsgodkännande"
            content={[
              {
                title: "Kräv godkännande av kostnader",
                content: defaultSetting?.require_cost_accept ? (
                  <StatusLabel state={0}>Ja</StatusLabel>
                ) : (
                  <StatusLabel state={6}>Nej</StatusLabel>
                ),
              },
            ]}
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    require_cost_accept: false,
                  },
                })
              );
            }}
          />
        )}

        {adminFee != null || companyInvoicingConfig?.admin_fee == null ? (
          <>
            {defaultSetting?.admin_fee != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                sourceTitle="Från valt bolag"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  delete instanceClone.admin_fee;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <NumberInput
              title="Administrationsavgift"
              description="Ange kostnaden för administrationsavgift av faktura. "
              {...{ storeName, method, fieldKey: "admin_fee" }}
            />
          </>
        ) : (
          <InvoicingSettingDefault
            extraStyle={{ marginBottom: 36 }}
            title="Administrationsavgift"
            content={[
              {
                title: "Administrationsavgift",
                content: companyInvoicingConfig?.adminfee || 0,
              },
            ]}
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    admin_fee: 0,
                  },
                })
              );
            }}
          />
        )}

        {autoMerge != null || defaultSetting?.auto_merge == null ? (
          <>
            {defaultSetting?.auto_merge != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  delete instanceClone.auto_merge;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <RadioGroup
              title="Slå ihop fakturor som genererats för detta avtal"
              description="Om denna inställning är aktiv kommer fakturor som genereras för detta avtal automatiskt slås ihop med andra icke-attesterade fakturor till samma kund."
              {...{ storeName, method, fieldKey: "auto_merge" }}
              forceHorizontalToolTipPlacement="left"
              options={[
                { label: "Ja", value: true },
                { label: "Nej", value: false },
              ]}
              defaultValue={true}
            />
          </>
        ) : (
          <InvoicingSettingDefault
            extraStyle={{ marginBottom: 36 }}
            title="Slå ihop fakturor"
            content={[
              {
                title: "Slå ihop fakturor som genererats för detta avtal",
                content: defaultSetting?.auto_merge ? (
                  <StatusLabel state={0}>Ja</StatusLabel>
                ) : (
                  <StatusLabel state={6}>Nej</StatusLabel>
                ),
              },
            ]}
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    auto_merge: false,
                  },
                })
              );
            }}
          />
        )}

        {autoAttest != null || defaultSetting?.auto_attest == null ? (
          <>
            {defaultSetting?.auto_attest != null && (
              <TextButton
                extraStyle={{ marginBottom: 24 }}
                title="Använd vald inställning"
                iconType="edit"
                clicked={() => {
                  const instanceClone = cloneDeep(instance);
                  delete instanceClone.auto_attest;
                  dispatch(
                    setActiveFormInstance({
                      storeName,
                      data: instanceClone,
                    })
                  );
                }}
              />
            )}

            <RadioGroup
              title="Autoattestera fakturor som genereras för detta avtal"
              description="Om denna inställning är aktiv kommer alla fakturor som genereras automatiskt att attesteras och bokföras så fort de skapas."
              {...{ storeName, method, fieldKey: "auto_attest" }}
              forceHorizontalToolTipPlacement="left"
              options={[
                { label: "Ja", value: true },
                { label: "Nej", value: false },
              ]}
              defaultValue={false}
            />
          </>
        ) : (
          <InvoicingSettingDefault
            extraStyle={{ marginBottom: 36 }}
            title="Automatisk attestering"
            content={[
              {
                title: "Autoattestera fakturor som genereras för detta avtal",
                content: defaultSetting?.auto_attest ? (
                  <StatusLabel state={0}>Ja</StatusLabel>
                ) : (
                  <StatusLabel state={6}>Nej</StatusLabel>
                ),
              },
            ]}
            onOverride={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    auto_attest: false,
                  },
                })
              );
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>Avtalsspecifikt</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för fakturering som är specifika för detta avtal
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {deliveryMethod != null ||
      (debtorInvoicingConfig?.delivery_method == null &&
        companyInvoicingConfig?.delivery_method == null) ? (
        <>
          {(debtorInvoicingConfig?.delivery_method != null ||
            companyInvoicingConfig?.delivery_method != null) && (
            <TextButton
              extraStyle={{ marginBottom: 24 }}
              title="Använd vald inställning"
              iconType="edit"
              clicked={() => {
                const instanceClone = cloneDeep(instance);
                delete instanceClone.delivery_method;
                dispatch(
                  setActiveFormInstance({
                    storeName,
                    data: instanceClone,
                  })
                );
              }}
            />
          )}

          <Select
            title="Leveransmetod"
            {...{ storeName, method, fieldKey: "delivery_method" }}
          />
        </>
      ) : (
        <InvoicingSettingDefault
          extraStyle={{ marginBottom: 36 }}
          title="Leveransmetod"
          sourceTitle={
            debtorInvoicingConfig?.delivery_method != null
              ? "Från kundens inställningar"
              : "Från valt bolag"
          }
          content={[
            {
              title: "Leveransmetod",
              content:
                debtorInvoicingConfig?.delivery_method_display ||
                companyInvoicingConfig?.delivery_method_display,
            },
          ]}
          onOverride={() => {
            dispatch(
              updateActiveFormInstance({
                storeName,
                data: {
                  delivery_method: "Email",
                },
              })
            );
          }}
        />
      )}

      <DatePicker
        title="Startdatum för avisering"
        description='Om inget startdatum väljs kommer startdatumet på avtalet att användas. Vid "kommande" period bör en aviseringsperiod innan startdatumet på avtalet väljas, vid "innevarande" bör de två datumen matcha varandra och på "föregående" bör startdatumet vara en period efter startdatumet på avtalet.'
        {...{ storeName, method, fieldKey: "start_date" }}
      />

      <RadioGroup
        title="Använd automeddelande på fakturor"
        description="Om denna inställning är aktiv kommer meddelandet på fakturor som genereras för detta avtal att få ett auto-genererat meddelande som beskriver vilket/vilka hyresobjekt fakturan avser."
        {...{ storeName, method, fieldKey: "include_auto_message" }}
        forceHorizontalToolTipPlacement="left"
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        defaultValue={false}
      />

      {renderInvoiceInfoChapter()}

      {renderIntervalChapter()}

      {renderHandleChapter()}
    </>
  );
};
