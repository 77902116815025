import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { destroyForm, updateActiveFormInstance } from "../../../store/base";
import {
  constants,
  updateAutogiroSettings,
} from "../../../store/billectaIntegrations";
import { useAutogiroForm } from "../../../store/billectaIntegrations/hooks/form";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import { Checkbox, TextInput } from "../../Forms/Base/Fields";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";

export default function AutogiroIntegrationForm({
  open,
  closeFunction,
  creditorId,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const autogiroSettings = useSelector(
    (state) => state.billectaIntegrations.autogiroSettings
  );

  useAutogiroForm("PUT", open);

  React.useEffect(() => {
    if (open) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: autogiroSettings,
        })
      );
    } else {
      dispatch(
        destroyForm({
          constants,
          method: "PUT",
          success: false,
        })
      );
    }
  }, [open]);

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      updateAutogiroSettings({
        creditorId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: `Autogirointegrationen uppdaterades`,
            })
          );

          closeFunction();
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);

          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: `Autogirointegrationen kunde inte uppdateras`,
              description: "Kontrollera datan",
            })
          );
        },
      })
    );
  };

  return (
    <StandardModal
      isOpen={open}
      closeFunction={closeFunction}
      withActionBar
      actionBarAcceptTitle={
        autogiroSettings ? "Uppdatera" : "Aktivera integration"
      }
      title="Integration - Autogiro"
      saveFunction={handleSubmit}
    >
      {loading && <OverlaySpinner />}

      <FormAreaTitle>Autogiro</FormAreaTitle>
      <FormAreaDescription>
        Integrationen Autogiro tillåter automatiska betalningar via Autogirot
      </FormAreaDescription>

      <TextInput
        storeName={constants.STORE_NAME}
        fieldKey={"AutogiroCustomerNumber"}
        method={"PUT"}
        title="Autogiro-kundnummer hos Bankgirocentralen"
      />

      <Checkbox
        storeName={constants.STORE_NAME}
        fieldKey={"CancelAllFutureWithdrawalsOnFailedWithdrawal"}
        method={"PUT"}
        title="Avbryt samtliga framtida autogirouttag vid ett misslyckat uttag"
      />

      <Checkbox
        storeName={constants.STORE_NAME}
        fieldKey={"DisableDeliveryOnNewAutogiroApproval"}
        method={"PUT"}
        title="Ändra leveranssätt på avtalsfakturor till 'Skicka ej' vid nytt autogiromedgivande"
      />

      <Checkbox
        storeName={constants.STORE_NAME}
        fieldKey={"EnableDeliveryOnDeleteAutogiroApproval"}
        method={"PUT"}
        title="Ändra leveranssätt på avtalsfakturor till standardleveranssätt vid borttaget autogiromedgivande (gäller när leveranssätt är 'Skicka ej')"
      />

      <Checkbox
        storeName={constants.STORE_NAME}
        fieldKey={"SendInvoiceOnFailedWithdrawal"}
        method={"PUT"}
        title="Skicka fakturan enligt standard leveranssätt vid misslyckad dragning"
      />
    </StandardModal>
  );
}
