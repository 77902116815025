import * as React from "react";
import NonConnectedTextInput from "../../../Forms/Base/Old/NonConnected/NonConnectedTextInput";
import NonConnectedDatePicker from "../../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { PrimaryButton } from "../../../Forms/Base/Buttons";
import StandardModal from "../../../Modals/StandardModal";
import { InnerBox } from "../../../sharedStyles";
import { InfoBox } from "../../../Displays";
import { toMoneyString } from "../../../utils/stringUtils";
import NonConnectedSelect from "../../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import { axiosInstance } from "../../../../store/base/store/axios";
import { getPaymentMeansInstance } from "../../../../store/billectaPaymentMeans/store/services";

const OVERPAYMENT_OPTIONS = [
  {
    d: "Ignorera överskjutande belopp",
    v: 0,
  },
  {
    d: "Som en valutakursdifferens",
    v: 1,
  },
  {
    d: "Som en överbetalning",
    v: 2,
  },
];

export default function RegisterInvoiceForm({
  registerPayment,
  isCreditInvoice,
  defaultValue,
  creditorId,
}) {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [currency, setCurrency] = React.useState("SEK");
  const [paidDate, setPaidDate] = React.useState(null);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [overpaymentHandle, setOverpaymentHandle] = React.useState(2);
  const [reference, setReference] = React.useState("");
  const [paymentMeansInstance, setPaymentMeansInstance] = React.useState(null);
  const [paymentMeansCode, setPaymentMeansCode] = React.useState("BG");

  const getPaymentMeansOptions = async () => {
    const res = await getPaymentMeansInstance(creditorId);
    setPaymentMeansInstance(res);
  };

  React.useEffect(() => {
    getPaymentMeansOptions();
  }, []);

  React.useEffect(() => {
    if (!!value && !!currency && !!paidDate) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [value, currency, paidDate]);

  React.useEffect(() => {
    if (!value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <StandardModal
        isOpen={open}
        closeFunction={() => setOpen(false)}
        title="Registrera manuell inbetalning"
        withActionBar
        saveFunction={() => {
          setOpen(false);
          registerPayment({
            value,
            currency,
            paidDate,
            overpaymentHandle,
            reference,
            paymentMeansCode,
          });
        }}
        canAccept={canSubmit}
        actionBarAcceptTitle="Registrera inbetalning"
      >
        {isCreditInvoice && (
          <InfoBox
            boxTheme="yellow"
            title="OBS: Kreditfaktura"
            text="För att registrera en betalning mot en kreditfaktura anges värdet negativt"
          />
        )}
        <InnerBox>
          <div style={{ padding: "12px" }}>
            {value != defaultValue && value < defaultValue && (
              <InfoBox
                boxTheme="yellow"
                text={`Det inskrivna beloppet matchar ej det kvarstående beloppet på fakturan. Det kvarstående beloppet på fakturan efter uppdateringen blir ${(
                  defaultValue - value
                ).toLocaleString("sv")} SEK`}
              />
            )}

            <NonConnectedTextInput
              value={reference}
              onChange={(v) => setReference(v)}
              label="Referens"
            />

            <NonConnectedDatePicker
              extraStyle={{ flex: 1 }}
              value={paidDate}
              onChange={(d) => setPaidDate(d)}
              label="Inbetalningsdatum"
            />

            <NonConnectedSelect
              extraStyles={{ marginTop: 24 }}
              choices={paymentMeansInstance || []}
              allowNull={false}
              getOptionLabel={(o) => o.Description}
              getOptionValue={(o) => o.PaymentCode}
              label="Betalsätt"
              id="payment_code"
              value={paymentMeansCode}
              onUpdate={(v) => setPaymentMeansCode(v)}
            />

            <div
              style={{
                flex: 1,
                marginRight: 12,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <NonConnectedTextInput
                noMargin
                value={value}
                onChange={(v) => setValue(v)}
                label="Belopp (SEK)"
                type="number"
              />
              <div style={{ fontSize: 14, marginTop: 4 }}>
                <strong>Återstående:</strong>
                {toMoneyString(defaultValue - (value || 0), true)}
              </div>
            </div>

            {value > defaultValue && (
              <NonConnectedSelect
                extraStyles={{ marginTop: 24 }}
                choices={OVERPAYMENT_OPTIONS}
                allowNull={false}
                getOptionLabel={(o) => o.d}
                getOptionValue={(o) => o.v}
                label="Hantering av överskjutande belopp"
                id="overpayment_id"
                value={overpaymentHandle}
                onUpdate={(v) => setOverpaymentHandle(v)}
              />
            )}
          </div>
        </InnerBox>
      </StandardModal>

      <PrimaryButton
        title="Registrera manuell inbetalning"
        clicked={() => setOpen(true)}
      />
    </>
  );
}
