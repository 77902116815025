import * as React from "react";
import { useHistory } from "react-router";
import * as SC from "./styles";

export default ({ noBorder, borderRight }) => {
  const { push } = useHistory();
  const goHome = () => {
    push("/");
  };

  return (
    <SC.LogoWrapper onClick={goHome} {...{ noBorder, borderRight }}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 279.0844"
      >
        <g id="Logo">
          <circle
            cx="139.5422"
            cy="139.5422"
            r="139.5422"
            style={{ fill: "#fff" }} //#202225
          />
          <g id="P">
            <g id="LINE">
              <polygon
                style={{ fill: "#202225" }}
                points="215.49 111.23 122.752 164.773 122.752 184.16 215.49 130.618 215.49 111.23"
              />
            </g>
            <g id="LINE-2" data-name="LINE">
              <path
                style={{ fill: "#202225" }}
                d="M156.332,198.9274,198.7,174.4661a33.58,33.58,0,0,0,16.79-29.0808l-92.7378,53.5421A33.5794,33.5794,0,0,0,156.332,198.9274Z"
              />
            </g>
            <g id="LINE-3" data-name="LINE">
              <path
                style={{ fill: "#202225" }}
                d="M215.49,96.4627A33.58,33.58,0,0,0,198.7,67.3818L156.332,42.9205a33.58,33.58,0,0,0-33.58,0Z"
              />
            </g>
            <g id="LINE-4" data-name="LINE">
              <polygon
                style={{ fill: "#202225" }}
                points="109.963 50.304 93.173 59.998 185.911 113.54 202.701 103.847 109.963 50.304"
              />
            </g>
            <g id="LINE-5" data-name="LINE">
              <polygon
                style={{ fill: "#202225" }}
                points="109.963 252.545 93.173 242.852 93.173 74.766 109.963 84.459 109.963 252.545"
              />
            </g>
            <g id="LINE-6" data-name="LINE">
              <path
                style={{ fill: "#ff202225f" }}
                d="M80.3842,67.3818a33.58,33.58,0,0,0-16.79,29.0809V206.387a33.58,33.58,0,0,0,16.79,29.0809Z"
              />
            </g>
          </g>
        </g>
        <g id="Text">
          <path
            d="M426.1319,69.3931a48.2671,48.2671,0,0,1,20.0609,18.526q7.1222,11.9518,7.1254,27.734,0,23.4627-15.5661,36.9426-15.57,13.4832-41.6561,13.4832H376.8023v50.2068H341.5044V62.8158H396.096Q413.1965,62.8158,426.1319,69.3931Zm-14.47,61.936q6.3569-4.7118,6.3582-15.676,0-20.389-24.1167-20.3895H376.8023V136.043h17.1012Q405.3,136.043,411.662,131.3291Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M506.8128,93.9483H472.1722V61.281h34.6406Zm0,122.3374H472.1722V102.28h34.6406Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M646.9076,102.28V216.2857q0,16.2227-9.3178,26.3091a52.5249,52.5249,0,0,1-23.13,14.47A92.253,92.253,0,0,1,586.396,261.45q-19.2949,0-30.0359-5.7V227.4669q9.8659,5.2616,30.0359,5.262,9.425,0,15.2378-2.7406,5.8065-2.7437,8.2216-6.4673a13.3571,13.3571,0,0,0,2.4116-7.2353V203.57h-1.5345q-3.0734,5.0459-10.9621,9.9754-7.893,4.9331-20.39,4.933a56.0333,56.0333,0,0,1-26.1993-6.6872,54.4328,54.4328,0,0,1-21.157-20.06q-8.3351-13.37-8.3315-32.4478,0-19.074,8.3315-32.4479a54.4879,54.4879,0,0,1,21.157-20.0608,56.08,56.08,0,0,1,26.1993-6.6869q12.4973,0,20.39,4.933t10.9621,9.9755h1.5345V102.28Zm-42.3141,78.05q8.33-7.6713,8.3315-21.0471,0-13.37-8.3315-21.0472a28.1652,28.1652,0,0,0-19.7319-7.6735,27.6524,27.6524,0,0,0-19.7318,7.6735q-8.1135,7.6767-8.1117,21.0472,0,13.3755,8.1117,21.0471a27.6372,27.6372,0,0,0,19.7318,7.6735A28.1494,28.1494,0,0,0,604.5935,180.33Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M748.0861,106.2258q12.8248,6.14,21.3761,19.4032,8.55,13.2675,8.55,32.9959a91.7715,91.7715,0,0,1-.8764,12.9351H698.4275a23.8687,23.8687,0,0,0,9.8659,13.2645q7.014,4.4961,17.32,4.4944a51.2982,51.2982,0,0,0,12.4966-1.2058,55.3448,55.3448,0,0,0,8.3315-2.85q3.7254-1.6443,5.481-2.302l19.0739,18.4165a59.6921,59.6921,0,0,1-19.4029,12.6061q-11.0734,4.4919-27.2961,4.4948a65.3411,65.3411,0,0,1-28.6108-6.6872,56.9826,56.9826,0,0,1-22.9112-20.06q-8.9925-13.37-8.9889-32.0093,0-18.8535,8.88-32.4478a57.1447,57.1447,0,0,1,22.4723-20.39,60.3432,60.3432,0,0,1,27.1863-6.7964A59.2265,59.2265,0,0,1,748.0861,106.2258Zm-39.9026,27.9534a26.062,26.062,0,0,0-9.5369,12.8259h47.3563a20.4138,20.4138,0,0,0-8.77-12.8259,26.4987,26.4987,0,0,0-14.908-4.4944A24.27,24.27,0,0,0,708.1835,134.1792Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M829.5342,216.2857H794.8935V61.281h34.6407Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M886.5366,216.2857H851.8959V61.281h34.6407Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M991.5525,106.8834a58.71,58.71,0,0,1,23.24,20.39Q1024,140.8695,1024,159.7211q0,18.8583-9.2079,32.1189a58.8632,58.8632,0,0,1-23.13,19.9509,61.7683,61.7683,0,0,1-55.9069,0,58.8324,58.8324,0,0,1-23.13-19.9509q-9.2085-13.2622-9.2086-32.1189,0-18.8535,9.2086-32.4478a58.6822,58.6822,0,0,1,23.24-20.39,60.4345,60.4345,0,0,1,55.6878,0ZM950.6636,133.96a25.9127,25.9127,0,0,0-10.085,9.9755,30.79,30.79,0,0,0-3.8368,15.7855,28.7088,28.7088,0,0,0,3.8368,15.1276,26.3043,26.3043,0,0,0,10.085,9.7563,26.9127,26.9127,0,0,0,13.0447,3.3982,26.31,26.31,0,0,0,12.9354-3.3982,26.5908,26.5908,0,0,0,9.9759-9.7563,28.75,28.75,0,0,0,3.8367-15.1276,30.834,30.834,0,0,0-3.8367-15.7855,26.3069,26.3069,0,0,0-22.9113-13.3737A26.948,26.948,0,0,0,950.6636,133.96Z"
            style={{ fill: "#fff" }}
          />
        </g>
      </svg>
    </SC.LogoWrapper>
  );
};
