import { axiosInstance } from "../../base/store/axios";
import { constants } from "../";

async function getBillingReport({ query, creditorId }) {
  const resp = await axiosInstance.get(
    `${constants.BILLING_REPORT_URL}${creditorId}/?${query}`
  );

  return resp;
}

async function getProductSalesReport({ query, creditorId, isExport }) {
  const resp = await axiosInstance.get(
    `${constants.PRODUCT_SALES_URL}${creditorId}/?${query}&export=${isExport}`
  );

  return resp;
}

async function getPaymentsReport({ params, exportFile }) {
  const resp = await axiosInstance.post(
    `${
      exportFile
        ? constants.INCOMING_PAYMENTS_EXPORT_URL
        : constants.INCOMING_PAYMENTS_URL
    }`,
    params
  );

  return resp;
}

export { getBillingReport, getProductSalesReport, getPaymentsReport };
