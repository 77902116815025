import * as FileSaver from "file-saver";
import moment from "moment";
import * as XLSX from "xlsx";

import { appendQueryString, buildQueryString } from "../../../store/base";

export const prepareFilters = (filters, instructions) => {
  if (!filters || !filters.length) {
    return "";
  }

  let result = "";

  filters.forEach((f) => {
    const instruction = instructions[f.id];

    if (!instruction) {
      result = appendQueryString(
        result,
        buildQueryString({ [f.id.replace(".", "__")]: f.value })
      );
      return;
    }

    let querystring = "";
    if (instruction.handler) {
      querystring = instruction.handler(f);
    } else {
      const operator = instruction.operator;
      querystring = buildQueryString({
        [`${f.id.replace(".", "__")}__${operator}`]: f.value,
      });
    }

    result = appendQueryString(result, querystring);
  });

  return result;
};

export const prepareOrdering = (ordering) => {
  if (!ordering || !ordering.length) {
    return "";
  }

  const prepared = ordering.map((o) => {
    const key = o.id.replace(".", "__");
    return o.desc ? `-${key}` : key;
  });

  return buildQueryString({ order_by: prepared });
};

export const buildRangeFilter = (filterObject) => {
  const gte = (filterObject?.value || [])[0];
  const lte = (filterObject?.value || [])[1];
  // don't filter
  if (!gte && !lte) {
    return "";
  }

  let filter = {};
  if (gte) {
    filter[`${filterObject.id}__gte`] = gte;
  }
  if (lte) {
    filter[`${filterObject.id}__lte`] = lte;
  }
  return buildQueryString(filter);
};

export const buildManyIdFilter = (filterObject) => {
  if (!filterObject?.value?.length) {
    return "";
  }
  return buildQueryString({
    [`${filterObject.id}__id__in`]: filterObject.value.map((f) => f.id),
  });
};

const cleanKey = (key) => {
  //\ , / , * , ? , : , [ , ].
  return key
    .replaceAll("/", "_")
    .replaceAll("*", ".")
    .replaceAll("?", ".")
    .replaceAll(":", ".")
    .replaceAll("[", "(")
    .replaceAll("]", ")");
};

export const performDataExport = ({ data, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const wb = {
    Sheets: {
      export: XLSX.utils.json_to_sheet(data),
    },
    SheetNames: ["export"],
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName + fileExtension);
};

export const performExcelExport = ({ data, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let sheets = {};
  let sheetNames = [];
  Object.keys(data).forEach((k) => {
    const key = cleanKey(k);

    sheetNames.push(key);
    sheets[key] = XLSX.utils.json_to_sheet(data[k]);
  });

  const wb = { Sheets: sheets, SheetNames: sheetNames };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName + fileExtension);
};

// helper to make columns look better
function fitToColumn(arrayOfArray) {
  // get maximum character of each column
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
    ),
  }));
}

// helper function to generate 2d array from table
function tableToArray(tbl, opt_cellValueGetter) {
  opt_cellValueGetter =
    opt_cellValueGetter ||
    function (td) {
      return td.textContent || td.innerText;
    };
  var twoD = [];
  for (
    var rowCount = tbl.rows.length, rowIndex = 0;
    rowIndex < rowCount;
    rowIndex++
  ) {
    twoD.push([]);
  }
  for (var rowIndex = 0, tr; rowIndex < rowCount; rowIndex++) {
    var tr = tbl.rows[rowIndex];
    for (
      var colIndex = 0, colCount = tr.cells.length, offset = 0;
      colIndex < colCount;
      colIndex++
    ) {
      var td = tr.cells[colIndex],
        text = opt_cellValueGetter(td, colIndex, rowIndex, tbl);
      while (twoD[rowIndex].hasOwnProperty(colIndex + offset)) {
        offset++;
      }
      for (
        var i = 0, colSpan = parseInt(td.colSpan, 10) || 1;
        i < colSpan;
        i++
      ) {
        for (
          var j = 0, rowSpan = parseInt(td.rowSpan, 10) || 1;
          j < rowSpan;
          j++
        ) {
          twoD[rowIndex + j][colIndex + offset + i] = text;
        }
      }
    }
  }
  return twoD;
}

function isNumeric(n) {
  // const isNum = !isNaN(+n);
  const isNum = /^-?\d+$/.test(n);
  return isNum;
}

export const performTableToBookExcelExport = ({
  element,
  fileName,
  userStr,
}) => {
  const workbook = XLSX.utils.book_new();

  const exportInfo = [
    [""],
    [""],
    [""],
    [""],
    [""],
    [""],
    ["Export från Pigello Fastighetssystem"],
    [""],
    [""],
    ["Export skapad", moment().format("YYYY-MM-DD HH:mm")],
    ["Exporterad av", userStr || "Anonym"],
  ];

  const exportData = tableToArray(element);

  const combinedExport = [...exportData, ...exportInfo];

  // fix values not allowed in excel
  const formattedCombinedExport = combinedExport.map((row) => {
    const formattedRow = row.map((cell) => {
      if (cell == null) {
        return "";
      }

      if (cell == "-" || cell == " - " || cell == " - ") {
        return "";
      }

      let formattedNumCell = cell.replace(/\s+/g, "");
      formattedNumCell = formattedNumCell.replace(/−/g, "-");

      if (isNumeric(formattedNumCell)) {
        return parseInt(formattedNumCell);
      }

      return cell;
    });

    return formattedRow;
  });

  const ws1 = XLSX.utils.aoa_to_sheet(formattedCombinedExport);
  ws1["!cols"] = fitToColumn(formattedCombinedExport);
  XLSX.utils.book_append_sheet(workbook, ws1, "export");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  workbook.Props = {
    Title: "Pigello Export",
    Author: userStr || "Pigello",
    CreatedDate: moment(),
  };

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName + fileExtension);
};
