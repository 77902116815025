import * as React from "react";

export default () => {
  return (
    <div>
      Lägg till kostnader och rabatter som ska aviseras för på avtalet.
      <br />
      <br />
      Exempel på kostnader kan vara:
      <br /> - Bashyra
      <br /> - Bredband/telefoni
      <br /> - Fastighetsskatt
      <br />
      <br />
      Vissa kostnader har hämtats automatiskt från hyresobjekten som är valda på
      avtalet.
      <br />
      <br />
      <strong>
        OBS:Minst en rad med produkten Bashyra måste finnas med på
        förvaltningsavtalet.
      </strong>
    </div>
  );
};
