import baseReducer from "../../billecta/reducer";
import constants from "./constants";

export default (state, action) => {
  const { type, payload } = action;

  let newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.DESTROY_FORM: {
      return { ...newState, forms: {} };
    }

    case constants.SET_BILLING_REPORT: {
      return { ...newState, billingReport: payload.data };
    }

    case constants.SET_DASHBOARD_BILLING_REPORT: {
      return {
        ...newState,
        dashboardBillingReport: payload.data,
        dashboardBillingReportCreditors: payload.creditorIds,
      };
    }

    case constants.SET_DASHBOARD_DUEDATE_REPORT: {
      return {
        ...newState,
        dashboardDueDateReport: payload.data,
        dashboardDueDateReportCreditors: payload.creditorIds,
      };
    }

    case constants.SET_PRODUCT_SALES: {
      return { ...newState, productSalesReport: payload.data };
    }
    case constants.SET_PERIODIC_PRODUCT_SALES: {
      return { ...newState, periodicProductSalesReport: payload.data };
    }

    case constants.SET_INCOMING_PAYMENTS: {
      return { ...newState, paymentsReport: payload.data };
    }

    case constants.SET_ADVANCED_INCOMING_PAYMENTS: {
      return { ...newState, advancedPaymentsReport: payload.data };
    }

    case constants.SET_INCOMING_DASHBOARD_PAYMENTS: {
      return {
        ...newState,
        dashboardPaymentsReport: payload.data,
        dashboardPaymentsReportCreditors: payload.creditorIds,
      };
    }

    case constants.SET_FUTURE_INVOICES: {
      return { ...newState, futureInvoices: payload.futureInvoices };
    }

    case constants.SET_VAT_REPORT: {
      return {
        ...newState,
        searchedPeriodRes: payload.searchedPeriodRes,
        fromDate: payload.fromDate,
        toDate: payload.toDate,
        prevPeriodFromDate: payload.prevPeriodFromDate,
        prevPeriodToDate: payload.prevPeriodToDate,
        totalVatAmountForPrevPeriod: payload.totalVatAmountForPrevPeriod,
        totalRemainingAmountForPrevPeriod:
          payload.totalRemainingAmountForPrevPeriod,
        totalInvoicedAmountForPrevPeriod:
          payload.totalInvoicedAmountForPrevPeriod,
        totalVatAmountForSearchedPeriod:
          payload.totalVatAmountForSearchedPeriod,
        totalRemainingAmountForSearchedPeriod:
          payload.totalRemainingAmountForSearchedPeriod,
        totalInvoicedAmountForSearchedPeriod:
          payload.totalInvoicedAmountForSearchedPeriod,
        invoicesWithOutstandingVATPrevPeriod:
          payload.invoicesWithOutstandingVATPrevPeriod,
        // VATReport: payload.data,
        // VATPrevReport: payload.prevData,
        // fromDate: payload.fromDate,
        // toDate: payload.toDate,
        // prevPeriodFromDate: payload.prevPeriodFromDate,
        // prevPeriodToDate: payload.prevPeriodToDate,
        // totalVAT: payload.totalVAT,
        // totalVATEligible: payload.totalVATEligible,
        // totalNonVATEligible: payload.totalNonVATEligible,
        // totalVATEligibleNet: payload.totalVATEligibleNet,
        // total25VAT: payload.total25VAT,
        // total12VAT: payload.total12VAT,
        // total6VAT: payload.total6VAT,
        // prevTotalVAT: payload.prevTotalVAT,
        // prevTotal25VAT: payload.prevTotal25VAT,
        // prevTotal12VAT: payload.prevTotal12VAT,
        // prevTotal6VAT: payload.prevTotal6VAT,
        // prevTotalVATEligible: payload.prevTotalVATEligible,
        // prevTotalNonVATEligible: payload.prevTotalNonVATEligible,
        // prevTotalVATEligibleNet: payload.prevTotalVATEligibleNet,
        // restingVAT: payload.restingVAT,
        // restingVATData: payload.restingVATData,
      };
    }

    default:
      return newState;
  }
};
