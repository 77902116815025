import ContractDetails from "./ContractDetails";
import Confirm from "./Confirm";
import Indexation from "./Indexation";
import SignStatus from "./SignStatus";
import Parties from "./Parties";
import Attachments from "./Attachments";
import ExistingDocument from "./ExistingDocument";

// Map of chapterdef KEY: Component
export default {
  PARTIES: Parties,
  EXISTINGDOC: ExistingDocument,
  ATTACHMENTS: Attachments,
  CONTRACT: ContractDetails,
  INDEXATION: Indexation,
  SIGN_STATUS: SignStatus,
  CONFIRM: Confirm,
};
