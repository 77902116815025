import { axiosInstance } from "../../../utils";
import constants from "./constants";

async function massUpdateInvoicingStatus({ patchObj }) {
  const res = await axiosInstance.patch(
    constants.MASS_INVOICE_STATUS_UPDATE_URL,
    patchObj
  );

  return res;
}

export { massUpdateInvoicingStatus };
