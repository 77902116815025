import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import HandleCustomers from "./overview/HandleCustomers";

export default function MainAdminOverview() {
  const { path, url } = useRouteMatch();

  const SUB_PAGES = [
    {
      path: "/actions",
      label: "Hantera kunder",
      component: HandleCustomers,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu title={`Admin`} {...{ subPages: subPageLinks }} />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
