import styled from "styled-components";

export const IntegrationsTitle = styled.div`
  margin-top: 24px;
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 12px;
  color: ${(p) => p.theme.colors.primary};
`;

export const IntegrationsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  cursor: pointer;
`;

export const Integration = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
`;

export const IntegrationImage = styled.div`
  width: 140px;
  min-width: 140px;
  height: 140px;
  min-height: 140px;
  margin-bottom: 6px;
  box-shadow: ${(p) => p.theme.boxShadows.discrete};

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.src});
`;

export const IntegrationTextTitle = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.primary};
`;

export const IntegrationText = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  color: ${(p) => p.theme.colors.primary};
`;
