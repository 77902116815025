import * as React from "react";

// style, design
import {
  DoubleFieldWrapper,
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { TextInput, Select } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/users";

import NonConnectedCheckbox from "../../../Base/Old/NonConnected/NonConnectedCheckbox";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { InfoBox } from "../../../../Displays";

export default ({ method }) => {
  const dispatch = useDispatch();

  const storeName = constants.STORE_NAME;
  const [isCompany, setIsCompany] = React.useState(false);

  const is_company = useFormInstanceField({
    storeName,
    fieldKey: "is_company",
  });

  const userType = useFormInstanceField({
    storeName,
    fieldKey: "user_type",
  });

  const handleCompanyAccount = (checked) => {
    if (!checked) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            corporate_name: null,
          },
        })
      );
    }
    setIsCompany(checked);
  };

  React.useEffect(() => {
    if (is_company) {
      setIsCompany(is_company);
    }
  }, [is_company]);

  return (
    <>
      <FormAreaTitle>Hyresobjekt</FormAreaTitle>
      <FormAreaDescription>Ange information om användaren</FormAreaDescription>

      <div>
        <NonConnectedCheckbox
          id={1}
          value={isCompany}
          onChange={handleCompanyAccount}
          title="Bocka i om det är ett företagskonto"
        />
      </div>
      {isCompany && (
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          method={method}
          fieldKey={"corporate_name"}
          title="Företagsnamn"
        />
      )}
      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          fieldKey={"first_name"}
          method={method}
          title="Förnamn"
        />
        <TextInput
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          fieldKey={"last_name"}
          method={method}
          title="Efternamn"
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          fieldKey={"email"}
          method={method}
          title="Epost"
        />
        <TextInput
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          fieldKey={"phone"}
          method={method}
          title="Telefon"
        />
      </DoubleFieldWrapper>

      <TextInput
        storeName={storeName}
        fieldKey={"legal_id"}
        method={method}
        title={isCompany ? "Org nummmer" : "Personnummer"}
      />

      <Select
        storeName={storeName}
        fieldKey="user_type"
        method={method}
        title="Användartyp"
      />
      {userType === 0 && (
        <InfoBox
          title="Admin"
          text="En administratör har tillgång till alla delar av systemet som den
            blir tilldelad på oavsett fastigheter. Du kan dock begränsa vilka
            rättigheter en admin ska ha genom att tilldela admin-användaren en
            behörighetsgrupp. "
        />
      )}
      {userType === 1 && (
        <InfoBox
          title="Standard"
          text="En standardanvändare har enbart rättighet till de delar av 
          systemet som ligger under respektive fastighet som användaren blir tilldelad på,
           samt de rättigheter som standardanvändaren får av behörighetsgruppen som
           tilldelas på användaren."
        />
      )}
      {userType === 2 && (
        <InfoBox
          title="Arbetare"
          text="En utförare är en användare som jobbar med teknisk förvaltning 
          och har enbart tillgång till de objekt och ärenden som utföraren har blivit tilldelad 
          på. Du kan utöver detta även begränsa vilka rättigheter en utförare ska ha genom att
           tilldela utförare-användaren en behörighetsgrupp."
        />
      )}
      {userType === 4 && (
        <InfoBox
          title="Gäst"
          text="En gästanvändare har enbart tillgång till de specifika objekt som en gästanvändare 
            blir tilldelad till, samt de rättigheter som gästanvändaren får av behörighetsgruppen 
            som tilldelas på användaren."
        />
      )}
    </>
  );
};
