import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "../styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

/**
 * Todo, style/design related:
 * must be able to disable radiobutton, if !selectValue
 */

export default React.memo(
  ({ storeName, fieldKey, method, instructionsKey, placementKey }) => {
    const dispatch = useDispatch();

    const actualFieldKey = `${fieldKey}.value`;
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || actualFieldKey,
    });

    const value = useFormInstanceField({ storeName, fieldKey: actualFieldKey });
    const error = useFormError({ storeName, fieldKey: actualFieldKey });

    const selectValue = useFormInstanceField({
      storeName,
      fieldKey: `${placementKey}.value`,
    });

    const checked = selectValue && selectValue === value;

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const onChange = () => {
      if (!selectValue || checked) {
        return;
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [actualFieldKey]: selectValue },
        })
      );
    };

    return (
      <>
        <SC.PDFRadio
          small
          id={placementKey}
          checked={checked}
          onChange={() => onChange()}
        />
        <label htmlFor={placementKey}></label>
      </>
    );
  }
);
