import * as React from "react";

import { ToolTipCell } from "../../Displays";
import AnonymousCell from "../../Displays/AnonymousCell";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => {
  const cols = [
    {
      Header: "",
      accessor: "image.get",
      Cell: ({ value }) => (
        <div
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "5px",
            backgroundImage: `url(${value})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
      ),
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Uppdragsgivare",
      accessor: "str_representation",
      Cell: ({ value, row }) =>
        !!row?.original?.user?.anonymization ? (
          <AnonymousCell />
        ) : (
          <ToolTipCell text={value} />
        ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Org-nr",
      accessor: "legal_id",
      Cell: ({ value, row }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kontaktperson",
      accessor: (row) => `${row.first_name} ${row.last_name}`,
      Cell: ({ row }) => (
        <ToolTipCell
          text={`${row.original.first_name} ${row.original.last_name}`}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
