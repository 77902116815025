import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { DateCell } from "../../Displays";

export default ({ reportRows, fromDate, toDate, handleRowClicked }) => {
  const data = React.useMemo(() => {
    return reportRows;
  }, [reportRows]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Fakturanr.",
        accessor: "InvoiceNumber",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
        Footer: () => {
          return <Field style={{ fontWeight: 600 }}>Total sida:</Field>;
        },
        TotalFooter: () => {
          return <Field style={{ fontWeight: 600 }}>Total:</Field>;
        },
      },
      {
        Header: "Fakturerad part",
        accessor: "DebtorName",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Fakturadatum",
        accessor: "InvoiceDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "DueDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Stängd datum",
        accessor: "ClosedDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Transaktionsdatum",
        accessor: "TransactionDate",
        Cell: (props) => {
          return <DateCell date={props.value} />;
        },
      },
      {
        Header: "Kvarvarande belopp (SEK ink. Moms)",
        accessor: "CurrentAmount.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.CurrentAmount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.CurrentAmount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Fakturerat belopp (SEK ink. Moms)",
        accessor: "InvoicedAmount.ValueForView",
        Cell: (props) => {
          return <Field>{props.value?.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedAmount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedAmount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Moms total (SEK)",
        accessor: "InvoicedTaxAmount.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedTaxAmount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedTaxAmount.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Netto belopp Moms 25% (SEK)",
        accessor: "InvoicedNetAmountThirdVat.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedNetAmountThirdVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedNetAmountThirdVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Moms 25% (SEK)",
        accessor: "InvoicedTaxAmountThirdVat.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedTaxAmountThirdVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedTaxAmountThirdVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Netto belopp Moms 12% (SEK)",
        accessor: "InvoicedNetAmountSecondVat.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedNetAmountSecondVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedNetAmountSecondVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Moms 12% (SEK)",
        accessor: "InvoicedTaxAmountSecondVat.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedTaxAmountSecondVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedTaxAmountSecondVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Netto belopp Moms 6% (SEK)",
        accessor: "InvoicedNetAmountFirstVat.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedNetAmountFirstVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedNetAmountFirstVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Moms 6% (SEK)",
        accessor: "InvoicedTaxAmountFirstVat.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedTaxAmountFirstVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedTaxAmountFirstVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Netto belopp Moms 0% (SEK)",
        accessor: "InvoicedNetAmountZeroVat.ValueForView",
        Cell: (props) => {
          return <Field>{props.value.toLocaleString("sv")}</Field>;
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedNetAmountZeroVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedNetAmountZeroVat.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        withExport
        exportName={`faktureringsrapport_${fromDate}-${toDate}`}
        onRowClicked={handleRowClicked}
        withSummaryFooter
        withTotalSummaryFooter
        tableId="billingreportstable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
