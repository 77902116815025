import * as React from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";

export default React.memo(
  ({
    storeName,
    method,
    fieldKey,
    fetchMethod,
    fetchQueryString,
    instructionsKey,
    formatDisplayValue,
    title,
    description,

    // allows for react select to anchor the select list to the body.
    // Excellent for overflow problems but MUST NOT be used in modals
    bodyPortaled,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const [choices, isLoading] = fetchMethod(fetchQueryString);

    const onChange = (data) => {
      let formattedData = [];
      if (data !== null) {
        formattedData = data.map((d) => {
          if (typeof d === "number") {
            return d;
          }
          return d.id;
        });
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const getOptionLabel = (option) => {
      let obj = undefined;
      if (typeof option === "number") {
        obj = choices.find((c) => c.id === option);
      } else {
        obj = option;
      }

      if (formatDisplayValue) {
        return formatDisplayValue(obj) || "Värde";
      }

      return obj?.str_representation || "Värde";
    };

    const filteredChoices = choices.filter((c) => {
      if (c.name !== "Hyresgäst") {
        return !(value || []).includes(c.id);
      }
    });

    return (
      <SC.InputSpacing>
        <SC.InputFieldTitle>
          {title}
          {instructions._required ? "*" : ""}
        </SC.InputFieldTitle>
        {description && (
          <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
        )}
        {choices ? (
          <SC.SelectWrapper>
            <Select
              id={instructions._internalId}
              placeholder="Välj..."
              onChange={onChange}
              defaultValue={[]}
              value={value}
              options={filteredChoices}
              getOptionValue={(option) => option}
              getOptionLabel={getOptionLabel}
              isClearable={!instructions._required}
              isMulti={true}
              {...{
                menuPortalTarget: bodyPortaled
                  ? document.querySelector("body")
                  : null,
              }}
            />
          </SC.SelectWrapper>
        ) : (
          <SC.SelectLoader>Laddar alternativ...</SC.SelectLoader>
        )}
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
