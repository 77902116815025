import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BillectaNotifications from "../../../components/Billecta/Notifications/BillectaNotifications";
import BillectaImportantMessages from "../../../components/Billecta/Notifications/ImportantMessages";
import { NotificationDot } from "../../../components/Dashboard/styles";
import { ItemTitle } from "../../../components/Details/OverviewInfo/DetailInfo/styles";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { buildQueryString } from "../../../store/base";
import {
  REPORT_EVENTS_CATEGORIES,
  useImportantMessages,
  useBillectaEventsPaginationCount,
} from "../../../store/billectaEvents/hooks/retrieve";
import {
  creditorInvoiceDetailUrl,
  creditorPaymentsDetailUrl,
} from "../../../store/billectaInvoicing";
import { useFilteredCompanies } from "../../../store/companies";

const notificationsCategories = [
  REPORT_EVENTS_CATEGORIES.INVOICE_UNDELIVERED,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_APPROVAL_FAIL,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_CHANGED,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL,
  REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL_RETRY,
];

export default function Notifications() {
  const { creditorId } = useParams();
  const { push } = useHistory();
  const [messages] = useImportantMessages(creditorId);
  const invoices = useSelector((state) => state.billectaInvoicing.invoices);
  const payments = useSelector((state) => state.billectaInvoicing.payments);

  const paymentCounts = payments?.reduce(
    (acc, cur) => {
      if (
        cur.State === "Unhandled" &&
        cur.UnhandledPaymentType === "OverPayment"
      )
        return { ...acc, OverPayment: acc.OverPayment + 1 };
      if (cur.State === "Unhandled")
        return { ...acc, Unmatched: acc.Unmatched + 1 };

      return acc;
    },
    { OverPayment: 0, Unmatched: 0 }
  );
  const overPaymentSum = payments?.reduce((acc, cur) => {
    if (cur.State === "Unhandled" && cur.UnhandledPaymentType === "OverPayment")
      return acc + cur.Amount.ValueForView;

    return acc;
  }, 0);

  const unmatchedSum = payments?.reduce((acc, cur) => {
    if (cur.State === "Unhandled" && cur.UnhandledPaymentType !== "OverPayment")
      return acc + cur.Amount.ValueForView;

    return acc;
  }, 0);

  const counts = invoices?.reduce(
    (acc, cur) => {
      if (cur.Stage === "Created") return { ...acc, Created: acc.Created + 1 };
      if (cur.Stage === "ReminderInvoiceSent")
        return { ...acc, ReminderInvoiceSent: acc.ReminderInvoiceSent + 1 };
      if (cur.Stage === "SentToDebtCollection")
        return { ...acc, SentToDebtCollection: acc.SentToDebtCollection + 1 };
      if (cur.Stage === "Manual") return { ...acc, Manual: acc.Manual + 1 };
      return acc;
    },
    {
      Created: 0,
      ReminderInvoiceSent: 0,
      Manual: 0,
      SentToDebtCollection: 0,
    }
  );

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const [billectaEventsCount] = useBillectaEventsPaginationCount({
    relatedCreditorIds: company?.billecta_id ? [company?.billecta_id] : [],
    read: false,
    categories: notificationsCategories,
  });

  const attentionTheme = {
    backgroundColor: "#ffecf2",
    color: "#fa3d56",
    borderColor: "#fa3d56",
    justifyContent: "center",
    flex: 1,
  };

  const warningTheme = {
    backgroundColor: "#f2ebff",
    color: "#9258f0",
    borderColor: "#9258f0",
    justifyContent: "center",
    flex: 1,
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        {isLoadingCompany && <OverlaySpinner />}

        <div style={{ display: "flex" }}>
          <InnerBox style={{ flex: 1, marginRight: 12, marginBottom: 12 }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Fakturor</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
              <TextButton
                title="Gå till fakturor"
                iconType="arrow"
                iconPlacement="right"
                extraStyle={{ marginLeft: "auto", marginRight: 0 }}
                clicked={() => push(creditorInvoiceDetailUrl({ creditorId }))}
              />
            </OverviewTitleWrapper>

            <div style={{ display: "flex" }}>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                  marginBottom: 6,
                }}
              >
                <ItemTitle>Ej attesterade</ItemTitle>
                <div style={{ fontSize: 18 }}>{counts?.Created || 0}</div>
              </InnerBox>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <ItemTitle>Påminnelse</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {counts?.ReminderInvoiceSent || 0}
                </div>
              </InnerBox>
            </div>
            <div style={{ display: "flex" }}>
              <InnerBox
                style={
                  counts?.Manual > 0
                    ? { ...attentionTheme, marginRight: 6 }
                    : {
                        justifyContent: "center",
                        flex: 1,
                        marginRight: 6,
                      }
                }
              >
                <ItemTitle>Förfallna (Ej hanterade)</ItemTitle>
                <div style={{ fontSize: 18 }}>{counts?.Manual || 0}</div>
              </InnerBox>
              <InnerBox
                style={
                  counts?.SentToDebtCollection > 0
                    ? attentionTheme
                    : {
                        justifyContent: "center",
                        flex: 1,
                      }
                }
              >
                <ItemTitle>Inkasso</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {counts?.SentToDebtCollection || 0}
                </div>
              </InnerBox>
            </div>
          </InnerBox>

          <InnerBox style={{ flex: 1, marginBottom: 12 }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Betalningar</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
              <TextButton
                title="Gå till betalningar"
                iconType="arrow"
                iconPlacement="right"
                extraStyle={{ marginLeft: "auto", marginRight: 0 }}
                clicked={() => push(creditorPaymentsDetailUrl({ creditorId }))}
              />
            </OverviewTitleWrapper>

            <div style={{ display: "flex" }}>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginBottom: 6,
                  marginRight: 6,
                }}
              >
                <ItemTitle>Överbetalningar</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {paymentCounts?.OverPayment || 0}
                </div>
              </InnerBox>
              <InnerBox
                style={
                  paymentCounts?.Unmatched > 0
                    ? warningTheme
                    : {
                        justifyContent: "center",
                        flex: 1,
                      }
                }
              >
                <ItemTitle>Ej matchade</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {paymentCounts?.Unmatched || 0}
                </div>
              </InnerBox>
            </div>

            <div style={{ display: "flex" }}>
              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                  marginRight: 6,
                }}
              >
                <ItemTitle>Belopp överbetalningar</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {toMoneyString(overPaymentSum, true)}
                </div>
              </InnerBox>

              <InnerBox
                style={{
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <ItemTitle>Belopp ej matchade</ItemTitle>
                <div style={{ fontSize: 18 }}>
                  {toMoneyString(unmatchedSum, true)}
                </div>
              </InnerBox>
            </div>
          </InnerBox>
        </div>

        <div style={{ display: "flex", maxWidth: "100%" }}>
          <InnerBox style={{ flex: 3, maxWidth: "70%" }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle
                  small
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  Notiser{" "}
                  <NotificationDot>{billectaEventsCount}</NotificationDot>
                </OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <BillectaNotifications />
          </InnerBox>
          {messages?.length > 0 && (
            <InnerBox style={{ flex: 2, marginLeft: 12 }}>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle
                    small
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    Viktiga meddelanden{" "}
                    <NotificationDot>{messages?.length}</NotificationDot>
                  </OverviewTitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              <BillectaImportantMessages />
            </InnerBox>
          )}
        </div>
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
