import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";
import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  detailUrl,
  useCompanyForm,
} from "../../../../store/companies";

export default ({ method, id, onCheckout, isOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const { push } = useHistory();
  const formLoaded = Boolean(useCompanyForm(method, id));

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    if (method === "POST") {
      push(detailUrl({ id: returned.id, showInvoicingSettingPrompt: true }));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs}
      onRenderChapter={(key) => renderChapter(key, method)}
      submitCallback={onSubmit}
      title={method === "POST" ? "Skapa bolag" : "Uppdatera bolag"}
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
