import * as React from "react";

import * as SC from "../../Fields/styles";

export default React.memo(
  ({
    value,
    onChange,
    onBlur,
    onFocus,
    required,
    maxLength,
    error,
    label,
    helpText,
    id,
    disabled,
    renderDescription,
    renderSuggestions = false,
    suggestions = [],
    handleSuggestionClicked,
    extraStyles,
    extraTitleStyles = {},
    type,
    noMargin,
    rowSize,
    placeholder,
    onKeyPress,
  }) => {
    const handleChange = (value) => {
      onChange(value);
    };

    return (
      <SC.InputSpacing {...{ noMargin }} style={{ ...extraStyles }}>
        <SC.InputFieldTitle style={{ ...extraTitleStyles }}>
          {label}
          {required ? "*" : ""}
        </SC.InputFieldTitle>
        {helpText && (
          <SC.InputFieldDescription>{helpText}</SC.InputFieldDescription>
        )}
        {renderDescription ? renderDescription : null}
        <SC.InputFieldWrapper error={!!error}>
          <SC.InputField
            value={value || ""}
            onChange={({ target: { value } }) => handleChange(value)}
            onBlur={onBlur}
            onFocus={onFocus}
            maxLength={maxLength}
            disabled={disabled}
            onKeyPress={onKeyPress}
            id={id}
            required={required}
            type={type || "text"}
            {...{ rowSize, placeholder }}
          />
        </SC.InputFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
