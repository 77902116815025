import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { DateCell } from "../../Displays";

export default ({ invoices, date, handleRowClicked }) => {
  const data = React.useMemo(() => {
    return invoices;
  }, [invoices]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Fakturanummer",
        accessor: "InvoiceNumber",
        Cell: ({ value }) => <Field>{value}</Field>,
        Footer: () => {
          return <Field style={{ fontWeight: 600 }}>Total sida:</Field>;
        },
        TotalFooter: () => {
          return <Field style={{ fontWeight: 600 }}>Total:</Field>;
        },
      },
      {
        Header: "Kund",
        accessor: "tenant.str_representation",
        Cell: ({ value, row }) => {
          return <Field>{value || row.original?.Debtor?.ContactName}</Field>;
        },
      },
      {
        Header: "Fakturadatum",
        accessor: "InvoiceDate",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
      {
        Header: "Förfallodatum",
        accessor: "DueDate",
        Cell: ({ value }) => {
          return <DateCell date={value} />;
        },
      },
      {
        Header: "Fakturerat belopp (SEK ink. Moms)",
        accessor: "InvoicedAmountIncludingTax.ValueForView",
        Cell: ({ value }) => {
          return (
            <Field>
              {value
                ? `${(Math.round(value * 100) / 100).toLocaleString("sv")}`
                : 0}
            </Field>
          );
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.InvoicedAmountIncludingTax.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.InvoicedAmountIncludingTax.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Betalat belopp (SEK ink. Moms)",
        accessor: "PaidAmount",
        Cell: ({ value }) => {
          return (
            <Field>
              {value
                ? `${(Math.round(value * 100) / 100).toLocaleString("sv")}`
                : 0}
            </Field>
          );
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.PaidAmount;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.PaidAmount;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
      {
        Header: "Kvarstående belopp (SEK ink. Moms)",
        accessor: "RemainingAmountIncludingTax.ValueForView",
        Cell: ({ value }) => {
          return (
            <Field>
              {value
                ? `${(Math.round(value * 100) / 100).toLocaleString("sv")}`
                : 0}
            </Field>
          );
        },
        Footer: (props) => {
          const summarized = props.page.reduce((acc, cur) => {
            const val = cur.original.RemainingAmountIncludingTax.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
        TotalFooter: (props) => {
          const summarized = props.data.reduce((acc, cur) => {
            const val = cur.RemainingAmountIncludingTax.ValueForView;
            return (acc += val);
          }, 0);

          return <Field>{summarized.toLocaleString("sv")}</Field>;
        },
      },
    ],
    []
  );

  return (
    <>
      <BasicTable
        data={data}
        columns={columns}
        onRowClicked={handleRowClicked}
        withExport
        exportName={`kundreskontra_${date}`}
        withSummaryFooter
        withTotalSummaryFooter
        tableId="accountsreceivabletable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
    </>
  );
};
