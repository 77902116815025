import * as React from "react";
import { EuiToolTip } from "@elastic/eui";

export default ({ text }) => {
  const content = () => {
    if (!text && text !== 0) {
      return "-";
    }

    return text;
  };

  return (
    <EuiToolTip content={content()} anchorClassName={"eui-textTruncate"}>
      <>{content()}</>
    </EuiToolTip>
  );
};
