import { axiosInstance } from "../../../utils";
import constants from "./constants";

async function fetchAskUpdateSharedSettingCustomerInvoicing(data) {
  const res = await axiosInstance.patch(
    constants.ASK_UPDATE_SHARED_SETTING_URL_CUSTOMER_INVOICING,
    data
  );
  return res;
}

export { fetchAskUpdateSharedSettingCustomerInvoicing };
