import * as React from "react";
// style, design
import {
  DoubleFieldWrapper,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { AddressField, Select, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingDebtor";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Faktureringsinformation</OverviewTitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <AddressField
        title="Fakturaadress"
        {...{
          storeName,
          method,
          fieldKey: `invoice_address`,
        }}
      />

      <TextInput
        title="Fakturaadress c/o"
        {...{
          storeName,
          method,
          fieldKey: `care_of`,
        }}
      />
    </>
  );
};
