import {
  performLogutRedirect,
  setCustomerData,
  setImpersonateCustomerData,
  setImpersonateIntercomHashKey,
  setImpersonateToken,
  setImpersonateUserCreatedDate,
  setImpersonateUserId,
  setIntercomHashKey,
  setToken,
  setUserCreatedDate,
  setUserId,
} from "../../../utils";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import { addToast, TOAST_TYPES } from "../../toasts";
import constants from "./constants";
import * as services from "./services";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    preProcess,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    preProcess,
  });
};

export const getImpersonateUrl = () => {
  if (window.location.hostname.includes("demo")) {
    return "https://demo.app.pigello.se";
  } else if (window.location.hostname.includes("localhost")) {
    return "http://localhost:3000";
  } else {
    return "https://app.pigello.se";
  }
};

export const authenticateAsCustomer = (customerId) => {
  return async (dispatch) => {
    try {
      const loginCount =
        JSON.parse(localStorage.getItem(`management_login_count`)) || {};

      if (loginCount[customerId]) {
        loginCount[customerId] = parseInt(loginCount[customerId]) + 1;
      } else {
        loginCount[customerId] = 1;
      }

      const { data } = await services.authenticateAsCustomer(customerId);
      localStorage.setItem(
        "management_login_count",
        JSON.stringify(loginCount)
      );

      setImpersonateToken(data.token);
      setImpersonateUserId(data.user_id);
      setImpersonateIntercomHashKey(data.intercom?.user_hash_key);
      setImpersonateUserCreatedDate(data.intercom?.user_created_date);
      setImpersonateCustomerData(data.intercom?.customer);

      window.open(getImpersonateUrl(), "_blank").focus();
    } catch (e) {
      console.log(e);
      const status = e.response?.status;

      if (status === 401) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Inloggning misslyckades",
            description: "Sessionen har löpt ut. Du loggas nu ut",
          })
        );

        performLogutRedirect();
      } else {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Inloggning misslyckades",
            description: "Koppling hittades ej",
          })
        );
      }
    }
  };
};
