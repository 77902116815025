import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import EditableDocContractContainer from "../../components/Forms/EditableDoc/ContractForm/Container";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import { setActiveFormInstance, useFormInstanceField } from "../../store/base";
import { useCompany } from "../../store/companies";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  update,
  constants as editableDocConstants,
  matchContractAttributesToPartyFields,
  useEditabledoc,
} from "../../store/editabledocs";
import { setInitialParties } from "../../store/editabledocs/utils";
import { useCompanyInvoicing } from "../../store/invoicingCompany";
import { useCustomerInvoicing } from "../../store/invoicingCustomer";
import {
  useManagementContract,
  detailUrl as managementContractDetailUrl,
} from "../../store/managementContracts";

import { useCustomer } from "../../store/customers";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import { useUser } from "../../store/users";

const getInvoicingObjHook = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.CUSTOMER_CONTRACT)
    return useCustomerInvoicing;

  return null;
};

export const getDetailUrl = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.CUSTOMER_CONTRACT)
    return managementContractDetailUrl;

  return null;
};

const getContractHook = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.CUSTOMER_CONTRACT)
    return useManagementContract;

  return null;
};

const getAttrName = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.CUSTOMER_CONTRACT)
    return "customer_invoicing";

  return null;
};

export default () => {
  const dispatch = useDispatch();
  const { contractType, id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [hasSetInitialDoc, setHasSetInitialDoc] = React.useState(false);
  const [hasSetInitialPartyData, setHasSetInitialPartyData] =
    React.useState(false);

  const { push } = useHistory();

  const contractTypeHook = getContractHook(contractType);
  const attrName = getAttrName(contractType);
  const invoicingTypeHook = getInvoicingObjHook(contractType);
  const detailUrl = getDetailUrl(contractType);

  const [contract, contractLoading] = contractTypeHook(id);
  const [editableDoc] = useEditabledoc(contract?.editabledoc?.id);

  const [customer, customersLoading] = useCustomer(contract?.opponent?.id);
  const [company, companyLoading] = useCompany(contract?.company?.id);
  const [billingSettingCompany, billingSettingCompanyLoading] =
    useCompanyInvoicing(company?.invoicing_details?.id);
  const [companyUser, companyUserLoading] = useUser(company?.owner?.id);
  const [invoicingObj, invoicingObjLoading] = invoicingTypeHook(
    contract?.[attrName]?.id
  );

  const editableDocInstance = useFormInstanceField({
    storeName: editableDocConstants.STORE_NAME,
    fieldKey: "",
  });

  // for templates, match field
  const matchAttributesToPartyFields = () => {
    const editableDocClone = cloneDeep(editableDocInstance);

    const matched = matchContractAttributesToPartyFields({
      editableDoc: editableDocClone,
      contract,
      customer,
      company,
      companyUser,
      billingSettingCompany,
    });

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Data från avtalet matchades automatiskt mot mallen",
        description: `De fält som ingen match hittades för ligger kvar.`,
      })
    );

    dispatch(
      setActiveFormInstance({
        storeName: editableDocConstants.STORE_NAME,
        data: { ...matched, _page: 1 },
      })
    );
  };

  // for custom documents, no matching of fields, only default party data from contract
  const setInitialPartyData = () => {
    if (customer || company || companyUser) {
      const editableDocClone = setInitialParties({
        editableDoc: cloneDeep(editableDocInstance),
        customer,
        companyUser,
        company,
      });

      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Parter lades automatiskt till från avtalet",
          description: `Du hittar de tillagda parterna under "Hantera parter"`,
        })
      );

      dispatch(
        setActiveFormInstance({
          storeName: editableDocConstants.STORE_NAME,
          data: { ...editableDocClone, _page: 1 },
        })
      );
    }
  };

  const onDocumentUpdated = () => {
    if (!editableDocInstance || hasSetInitialPartyData) return;

    if (editableDocInstance?._chosenTemplate != null) {
      if (editableDocInstance.parties?.length) {
        setHasSetInitialPartyData(true);

        matchAttributesToPartyFields();
      } else {
      }
    } else if (
      editableDocInstance?.doc &&
      !editableDocInstance?.parties?.length
    ) {
      setHasSetInitialPartyData(true);
      setInitialPartyData();
    } else {
    }
  };

  React.useEffect(() => {
    if (editableDoc && !hasSetInitialDoc) {
      dispatch(
        setActiveFormInstance({
          storeName: editableDocConstants.STORE_NAME,
          data: editableDoc,
        })
      );

      setHasSetInitialDoc(true);
    }
  }, [editableDoc]);

  React.useEffect(() => {
    if (editableDocInstance) {
      onDocumentUpdated();
    }
  }, [editableDocInstance]);

  const successCallback = (_, returned) => {
    setLoading(false);
    push(detailUrl({ id }));
  };

  const errorCallback = () => {
    setLoading(false);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    // go through fields, no null values allowed

    if (dataClone?.parties?.length > 0) {
      dataClone.parties.forEach((party) => {
        if (party.fields?.length > 0) {
          party.fields.forEach((field) => {
            if (!field.value) {
              field.value = "";
            }
          });
        }
      });
    }
    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        preProcess,
        id: contract?.editabledoc?.id,
        successCallback,
        errorCallback,
      })
    );
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      {hasSetInitialDoc && (
        <EditableDocContractContainer
          {...{
            contract,
            onSubmit,
            detailUrl,
            method: "PATCH",
            onClearDocumentCallback: () => setHasSetInitialPartyData(false),
          }}
        />
      )}
    </>
  );
};
