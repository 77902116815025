export default [
  {
    title: "Faktureringsinfo",
    key: "INFO",
    visited: true,
    hasError: false,
    fieldKeys: ["invoice_address", "care_of", "vat"],
  },
  {
    title: "Leverans av faktura",
    key: "DELIVERY",
    visited: false,
    hasError: false,
    fieldKeys: [
      "delivery_method",
      "your_reference",
      "default_invoice_message",
      "email_invoice",
      "email_invoice_cc",
      "email_invoice_bcc",
      "invoice_phone",
    ],
  },
  {
    title: "Betalmetoder",
    key: "PAYMENT",
    visited: false,
    hasError: false,
    fieldKeys: [
      "e_invoice_bank",
      "autogiro_clearing",
      "autogiro_number",
      "use_autogiro",
      "autogiro_payer_number",
      "external_id",
      "autogiro_payment_service",
      "gln",
      "intermediator",
    ],
  },
  {
    title: "Bokföring",
    key: "ACCOUNTING",
    visited: false,
    hasError: false,
    fieldKeys: ["additional_dimension"],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["customer"],
  },
];
