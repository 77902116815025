import * as React from "react";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import StandardModal from "../../Modals/StandardModal";

export default function VismaIntegrationForm({
  open,
  closeFunction,
  creditorId,
}) {
  return (
    <StandardModal
      isOpen={open}
      closeFunction={closeFunction}
      withActionBar
      actionBarAcceptTitle="Aktivera integration"
      title="Integration - Visma"
    >
      <FormAreaTitle>Visma</FormAreaTitle>
      <FormAreaDescription>
        Integrationen Visma synkar bokföringsuppgifter baserat på avier skickat
        genom Pigello mot Visma
      </FormAreaDescription>
    </StandardModal>
  );
}
