import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { ItemTitle } from "../../../components/Details/OverviewInfo/DetailInfo/styles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import DescriptionToolTip from "../../../components/Forms/Base/Layout/DescriptionToolTip";
import NonConnectedDatePicker from "../../../components/Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedNumberInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedNumberInput";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import StandardModal from "../../../components/Modals/StandardModal";
import {
  BodyText,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { buildQueryString } from "../../../store/base";
import {
  useAccountBalanceForDebtor,
  addManualTransactionForDebtor,
  constants,
} from "../../../store/billectaAccountBalance";
import { useFilteredCompanies } from "../../../store/companies";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

const BALANCE_TYPE_MAP = {
  Invoice: "Faktura",
  ManualBalance: "Manuell justering",
  OverPayment: "Överbetalning",
};

export default function DebtorAccountBalance({ billectaObj, customer }) {
  const dispatch = useDispatch();
  const [showTransactions, setShowTransactions] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [paymentDate, setPaymentDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [amount, setAmount] = React.useState(0);
  const [reference, setReference] = React.useState("");

  const q = buildQueryString({
    billecta_id: billectaObj.creditor_id,
  });
  const [companies] = useFilteredCompanies(q);
  const company = companies?.[0];

  const [accountBalance, accountBalanceLoading] = useAccountBalanceForDebtor(
    billectaObj.object_id
  );

  const sum = accountBalance?.reduce((acc, cur) => {
    return acc + cur.Amount.ValueForView;
  }, 0);

  const handleAddTransaction = () => {
    setLoading(true);
    const postObj = {
      Amount: { Value: amount * 100, CurrencyCode: "SEK" }, // sent in oren, convert to SEK
      PaymentDate: paymentDate,
      Reference: reference,
      DebtorPublicId: billectaObj.object_id,
      CreditorPublicId: billectaObj.creditor_id,
    };

    dispatch(
      addManualTransactionForDebtor({
        postObj,
        debtorId: billectaObj.object_id,
        successCallback: () => {
          setLoading(false);
          setAddModalOpen(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Saldot uppdaterades",
            })
          );
        },
        errorCallback: (e) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Saldot kunde ej uppdateras",
              description: e || "Kontrolllera datan och försök igen",
            })
          );
        },
      })
    );
  };

  // clear account balance
  React.useEffect(() => {
    return () => {
      dispatch({
        type: constants.SET_ACCOUNT_BALANCE,
        payload: {
          debtorId: billectaObj.object_id,
          accountBalance: undefined,
        },
      });
    };
  }, []);

  return (
    <>
      <StandardModal
        isOpen={showTransactions}
        closeFunction={() => setShowTransactions(false)}
        title="Saldohistorik"
        withActionBar
        actionBarCancelTitle="Stäng"
      >
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Kundsaldo för {customer?.str_representation}
            </OverviewTitle>
            <OverviewSubtitle>
              Transaktioner i {company?.str_representation || "Laddar..."}
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>
        {accountBalance?.map((ab) => (
          <InnerBox>
            <ItemTitle>
              {moment(ab.TransactionDate).format("YYYY-MM-DD HH:mm")} -{" "}
              {BALANCE_TYPE_MAP[ab.BalanceType] || ab.BalanceType}
            </ItemTitle>
            <BodyText>
              <div
                style={{
                  fontWeight: 600,
                  color:
                    ab.Amount.ValueForView > 0
                      ? "#2ec177"
                      : ab.Amount.ValueForView === 0
                      ? "#202225"
                      : "#ea5050",
                }}
              >
                {toMoneyString(ab.Amount.ValueForView, true)}
              </div>{" "}
              {ab.Description}
            </BodyText>
          </InnerBox>
        ))}
      </StandardModal>

      <StandardModal
        isOpen={addModalOpen}
        closeFunction={() => setAddModalOpen(false)}
        title="Lägg till transaktion"
        withActionBar
        saveFunction={handleAddTransaction}
        actionBarAcceptTitle="Lägg till transaktion"
        canAccept={amount != null && paymentDate != null}
      >
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Lägg till manuell transaktion på kundsaldo för{" "}
              {customer?.str_representation}
            </OverviewTitle>
            <OverviewSubtitle>
              Transaktionen kommer att uppdatera kundsaldot. Negativa belopp
              anges med - (minustecken) innan beloppet. En omatchad betalning
              kommer att skapas vid manuell justering, denna hittas under
              "Betalningar" på bolagets faktureringssida. Den kan antingen
              matchas mot en faktura (manuellt eller automatiskt beroende på
              inställningarna för bolaget) eller tas bort.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <NonConnectedDatePicker
          label="Transaktionsdatum"
          value={paymentDate}
          onChange={(val) => setPaymentDate(val)}
          id="payment_date"
          maxDate={moment().toDate()}
        />

        <NonConnectedNumberInput
          label="Belopp"
          value={amount}
          onUpdate={(val) => setAmount(val)}
          id="payment_amount"
        />

        <NonConnectedTextInput
          label="Referens"
          value={reference}
          onChange={(val) => setReference(val)}
          id="payment_reference"
        />
      </StandardModal>

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
              small
            >
              Kundsaldo{" "}
              <DescriptionToolTip description="Kundsaldot kommer att matchas mot framtida fakturor mot kunden om detta är aktiverat på bolaget" />
            </OverviewTitle>
            <OverviewSubtitle>
              {company?.str_representation || "Laddar..."}
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <InnerBox style={{ fontWeight: 600, fontSize: 20, marginBottom: 12 }}>
          {sum != null ? toMoneyString(sum, true) : "Laddar..."}
        </InnerBox>

        <TextButton
          title="Uppdatera kundsaldo"
          extraStyle={{ marginBottom: 6 }}
          iconType="edit"
          iconPlacement="right"
          clicked={() => setAddModalOpen(true)}
        />
        <TextButton
          title="Visa saldohistorik"
          iconType="info"
          iconPlacement="right"
          clicked={() => setShowTransactions(true)}
        />
      </DetailPageBox>
    </>
  );
}
