import * as React from "react";

// style, design
import * as SharedStyles from "../../../components/sharedStyles";

// store, state

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { detailUrl, useCompany } from "../../../store/companies";

import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { updateFortnoxSettings } from "../../../store/billectaIntegrations/store/actions";
import useQuery from "../../../components/utils/useQuery";

export default function CompanyAwaitActivateFortnox() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const query = useQuery();

  const code = query.get("code");
  const state = query.get("state");
  const [loading, setLoading] = React.useState(true);

  const { replace } = useHistory();

  const internalState = localStorage.getItem("fnx_state");
  const voucherSeries = localStorage.getItem("fnx_voucher_series");
  const startDate = localStorage.getItem("fnx_start_date");
  const isUpdate = localStorage.getItem("fnx_is_update") == "true";

  const [company] = useCompany(companyId);
  const creditorId = company?.billecta_id;

  const handleSaveCode = () => {
    setLoading(true);
    dispatch(
      updateFortnoxSettings({
        creditorId,
        forceData: {
          APICode: code,
          VoucherSeries: voucherSeries,
          StartDate:
            startDate != null && startDate != "undefined"
              ? startDate
              : undefined,
        },
        successCallback: () => {
          localStorage.removeItem("fnx_state");
          localStorage.removeItem("fnx_voucher_series");
          localStorage.removeItem("fnx_start_date");
          localStorage.removeItem("fnx_is_update");
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: `Fortnox-integration ${
                isUpdate ? "uppdaterad" : "aktiverad"
              }`,
              description:
                "Gå till Integrationer -> Fortnox -> Hantera för att uppdatera verifikationsserie",
            })
          );
          replace(detailUrl({ id: companyId }));
        },
        errorCallback: (e) => {
          setLoading(false);
          localStorage.removeItem("fnx_state");
          localStorage.removeItem("fnx_voucher_series");
          localStorage.removeItem("fnx_start_date");
          localStorage.removeItem("fnx_is_update");
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                e?.response?.data?.Message ||
                "Kontakta Pigello om problemet kvarstår",
            })
          );
          replace(detailUrl({ id: companyId }));
        },
      })
    );
  };

  React.useEffect(() => {
    if (!creditorId) {
      return;
    }

    if (internalState != state || !code) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Koden från Fortnox matchade ej Pigellos kod",
          description: "Försök igen, kontakta Pigello om problemet kvarstår",
        })
      );

      return;
    }

    handleSaveCode();
  }, [creditorId]);

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitle>
            {isUpdate ? "Uppdaterar " : "Aktiverar"} Fortnox-integration...
          </OverviewTitle>
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox>
          <SharedStyles.BodyText>
            Inväntar {isUpdate ? "uppdatering " : "aktivering"} av
            Fortnox-integration...
          </SharedStyles.BodyText>
        </SharedStyles.InnerBox>
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
