import * as React from "react";
import * as SC from "./styles";

export default ({ chapters, activeChapter, chapterClicked }) => {
  return (
    <SC.Wrapper>
      {chapters.map((chapter, index) => {
        const isActive = activeChapter.key === chapter.key;

        const hasError = chapter.hasError;
        return (
          <React.Fragment key={chapter.key}>
            <SC.ChapterItem
              {...{ isActive, hasError }}
              onClick={() => chapterClicked(chapter.key)}
            >
              <SC.ChapterTitle>{chapter.title}</SC.ChapterTitle>
            </SC.ChapterItem>
          </React.Fragment>
        );
      })}
    </SC.Wrapper>
  );
};
