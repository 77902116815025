import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import PageSlider from "../../Layouts/PageSlider/PageSlider";

import ProductTable from "../../../components/Lists/InvoicingProducts/FullTable";
import ProjectTable from "../../../components/Lists/InvoicingProjects/FullTable";
import CostCenterTable from "../../../components/Lists/InvoicingCostCenters/FullTable";
import CustomDimensionTable from "../../../components/Lists/InvoicingCustomDimensions/FullTable";
import CostCenterForm from "../../../components/Forms/InvoicingCostCenter/ChapterForm/ModalForm";
import ProjectForm from "../../../components/Forms/InvoicingProject/ChapterForm/ModalForm";
import ProductForm from "../../../components/Forms/InvoicingProduct/ChapterForm/ModalForm";
import CustomDimensionForm from "../../../components/Forms/InvoicingCustomDimension/ChapterForm/ModalForm";
import { TextButton } from "../../../components/Forms/Base/Buttons";

const TABS = {
  PRODUCTS: "Produkter",
  COST_CENTERS: "Kostnadsställen",
  PROJECTS: "Projekt",
  CUSTOM: "Anpassade",
};

function InvoicingObjects() {
  const [selectedTab, setSelectedTab] = React.useState("PRODUCTS");

  const [costCenterFormOpen, setCostCenterFormOpen] = React.useState(false);
  const [projectFormOpen, setProjectFormOpen] = React.useState(false);
  const [productFormOpen, setProductFormOpen] = React.useState(false);
  const [customDimensionFormOpen, setCustomDimensionFormOpen] =
    React.useState(false);

  const CREATE_LINKS = {
    PRODUCTS: (
      <TextButton
        title="Lägg till ny produkt"
        clicked={() => setProductFormOpen(true)}
        iconType="add"
        iconPlacement="right"
      />
    ),
    COST_CENTERS: (
      <TextButton
        title="Lägg till nytt kostnadsställe"
        clicked={() => setCostCenterFormOpen(true)}
        iconType="add"
        iconPlacement="right"
      />
    ),
    PROJECTS: (
      <TextButton
        title="Lägg till nytt projekt"
        clicked={() => setProjectFormOpen(true)}
        iconType="add"
        iconPlacement="right"
      />
    ),
    CUSTOM: (
      <TextButton
        title="Lägg till ny anpassad dimension"
        clicked={() => setCustomDimensionFormOpen(true)}
        iconType="add"
        iconPlacement="right"
      />
    ),
  };

  return (
    <>
      <CostCenterForm
        method="POST"
        isOpen={costCenterFormOpen}
        onCheckout={() => setCostCenterFormOpen(false)}
      />

      <ProjectForm
        method="POST"
        isOpen={projectFormOpen}
        onCheckout={() => setProjectFormOpen(false)}
      />

      <ProductForm
        method="POST"
        isOpen={productFormOpen}
        onCheckout={() => setProductFormOpen(false)}
      />

      <CustomDimensionForm
        method="POST"
        isOpen={customDimensionFormOpen}
        onCheckout={() => setCustomDimensionFormOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>{TABS[selectedTab]}</OverviewTitle>
              {CREATE_LINKS[selectedTab] && (
                <OverviewSubtitle>{CREATE_LINKS[selectedTab]}</OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>
            <PageSlider
              {...{
                TABS,
                selectedTab,
                onTabSelected: (tab) => setSelectedTab(tab),
              }}
            />
          </OverviewTitleWrapper>

          {selectedTab === "PRODUCTS" && <ProductTable hideTitle hideColumns />}
          {selectedTab === "COST_CENTERS" && (
            <CostCenterTable hideTitle hideColumns />
          )}
          {selectedTab === "PROJECTS" && <ProjectTable hideTitle hideColumns />}

          {selectedTab === "CUSTOM" && (
            <CustomDimensionTable hideTitle hideColumns />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

export default InvoicingObjects;
