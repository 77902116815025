import * as React from "react";

// style, design
import * as SC from "../Chapters/styles";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import Chapters from "../Chapters/Chapters";
import { useSelector } from "react-redux";
import useScreenSize from "../../../../hooks/useScreenSize";
import MobileChapters from "../Chapters/MobileChapters";

export default ({
  title,
  chapters,
  activeChapter,
  chapterClicked,
  children,
  loading,
  onDone,
  pdf,
  askSaveWork,
  noPadding,
}) => {
  const isBareMode = useSelector((state) => state.app.isBareMode);
  const { isMobile } = useScreenSize();
  const mobileChapterWrapperRef = React.useRef();

  const formAreaRef = React.useRef();

  React.useEffect(() => {
    if (!isMobile || !mobileChapterWrapperRef) return;

    const index = chapters.findIndex((c) => c.key === activeChapter.key);
    const current = mobileChapterWrapperRef.current;

    if (current) {
      current.scrollTo(index * 70, 0);
    }
  }, [activeChapter]);

  React.useEffect(() => {
    if (formAreaRef?.current) {
      formAreaRef.current.scrollTo(0, 0);
    }
  }, [activeChapter]);

  return (
    <>
      {loading && <FullPageSpinner />}
      <SC.FullScreenFormWrapper {...{ isBareMode }}>
        <SC.FormContent>
          {isMobile ? null : (
            <SC.ChaptersWrapper {...{ isFullScreen: true }}>
              <Chapters
                chapters={chapters}
                activeChapter={activeChapter}
                chapterClicked={chapterClicked}
                {...{ title, onDone, askSaveWork }}
              />
            </SC.ChaptersWrapper>
          )}

          <SC.FormArea ref={formAreaRef} {...{ noPadding }}>
            {isMobile && (
              <SC.MobileChaptersWrapper ref={mobileChapterWrapperRef}>
                <MobileChapters
                  {...{ chapters, activeChapter, chapterClicked }}
                />
              </SC.MobileChaptersWrapper>
            )}
            <SC.MobilePadding>{children}</SC.MobilePadding>
          </SC.FormArea>
          {!!pdf ? <SC.PdfWrapper>{pdf}</SC.PdfWrapper> : null}
        </SC.FormContent>
      </SC.FullScreenFormWrapper>
    </>
  );
};
