import * as React from "react";
import { useTransition } from "react-spring";

// style, design
import * as SC from "./styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

import Chapters from "../Chapters/Chapters";
import { ChaptersWrapper } from "../Chapters/styles";
import { LinkButton, PrimaryButton, SecondaryButton } from "../Buttons";

export default ({
  isOpen,
  title,
  chapters,
  chapterClicked,
  activeChapter,
  children,
  loading,
  onDone,
  cancelCallback,
  cancelTitle,
  submitCallback,
  submitTitle,
  secondarySubmit = false,
  secondarySubmitTitle,
  modalInModal = false,
}) => {
  const areaRef = React.useRef();

  const modalTransition = useTransition(!!isOpen, {
    config: { duration: 150 },
    from: { opacity: 0, transform: `translateY(100%)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0 },
  });

  React.useEffect(() => {
    if (areaRef?.current) {
      areaRef.current.scrollTo(0, 0);
    }
  }, [activeChapter]);

  return modalTransition(
    (styles, open) =>
      open && (
        <SC.BackDrop
          {...{ nestedModal: modalInModal }}
          style={{ opacity: styles.opacity }}
        >
          <SC.Modal
            {...{ nestedModal: modalInModal }}
            onClick={(e) => e.stopPropagation()}
            style={{ ...styles, opacity: 1 }}
          >
            {loading && <OverlaySpinner />}
            <SC.ModalHeader>
              {title}
              <SC.CloseButton onClick={onDone} />
            </SC.ModalHeader>
            {chapters && (
              <SC.ModalLayout>
                <ChaptersWrapper>
                  <Chapters
                    chapters={chapters}
                    chapterClicked={chapterClicked}
                    activeChapter={activeChapter}
                  />
                </ChaptersWrapper>
                <SC.ModalContent ref={areaRef}>
                  {!loading && children}
                </SC.ModalContent>
              </SC.ModalLayout>
            )}
            <SC.ModalActionBar>
              {secondarySubmit && (
                <SecondaryButton
                  title={secondarySubmitTitle}
                  extraStyle={{ marginRight: "auto" }}
                  clicked={() => secondarySubmit()}
                />
              )}
              <LinkButton
                clicked={cancelCallback}
                title={cancelTitle || "Avbryt"}
              />
              <PrimaryButton
                clicked={submitCallback}
                title={submitTitle || "Spara"}
              />
            </SC.ModalActionBar>
          </SC.Modal>
        </SC.BackDrop>
      )
  );
};
