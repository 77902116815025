import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "./styles";

// store, design
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    extraStyles,
    type,
    instructionsKey,
    noTitle,
    noMargin,
    rowSize,
    disabled,
    children,
    title,
    description,
    subtitle,
    alwaysShowDescription,
    changeCallback,
    placeholder,
    manualInstructions,
    subtitleHelp,
    overrideRequired = false,
  }) => {
    const dispatch = useDispatch();

    let instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    instructions = instructions ?? manualInstructions;

    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );
      if (changeCallback) {
        changeCallback(data);
      }
    };

    React.useEffect(() => {
      if (instructions?._allowNull && !value) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              [fieldKey]: null,
            },
          })
        );
      }
    }, [instructions, value]);

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {!noTitle && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <SC.InputFieldTitle>
                {title || instructions._label}
                {overrideRequired ||
                (instructions._required && !instructions._allowNull)
                  ? "*"
                  : ""}
              </SC.InputFieldTitle>

              {!alwaysShowDescription &&
                (instructions._helpText || description) && (
                  <DescriptionToolTip
                    description={description || instructions._helpText}
                  />
                )}
            </div>

            {alwaysShowDescription && (
              <SC.InputFieldDescription>
                {description || instructions._helpText}
              </SC.InputFieldDescription>
            )}
          </>
        )}
        <SC.InputFieldWrapper error={!!error}>
          <SC.InputField
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            maxLength={instructions._maxLength}
            id={instructions._internalId}
            required={instructions._required}
            disabled={disabled}
            type={type || "text"}
            {...{ rowSize, placeholder }}
          />
        </SC.InputFieldWrapper>
        {subtitleHelp && (
          <div style={{ fontSize: 12, marginBottom: -14 }}>{subtitleHelp}</div>
        )}

        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        {children && children}
      </SC.InputSpacing>
    );
  }
);
