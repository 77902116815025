const defs = [
  {
    title: "Kostnader & Rabatter",
    key: "COSTS",
    visited: true,
    hasError: false,
    fieldKeys: ["cost_set", "vat"],
  },
  {
    title: "Aviseringsinställningar",
    key: "INVOICING",
    visited: false,
    hasError: false,
    fieldKeys: [
      "setting",
      "our_reference",
      "your_reference",
      "external_reference",
      "message",
      "interval_setting",
      "require_cost_accept",
      "admin_fee",
      "auto_merge",
      "auto_attest",
      "delivery_method",
      "start_date",
      "include_auto_message",
    ],
  },
  {
    title: "Spara och gå vidare",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [""],
  },
];

export default ({ hasBillectaFullPermission, skipInvoicing }) => {
  if (!hasBillectaFullPermission || skipInvoicing) {
    return defs.filter((d) => d.key !== "INVOICING");
  }
  return defs;
};
