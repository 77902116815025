import * as React from "react";
import { useDispatch } from "react-redux";
import { updateActiveFormInstance } from "../../../store/base";
import { getTemplate, useFastdocTemplates } from "../../../store/editabledocs";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";

import * as SC from "../Fields/styles";

const dataPath = "docData_";

export default function PayTemplate({
  chosenTemplate,
  fastdocId,
  storeName,
  isOpen,
  closeFunction,
}) {
  const dispatch = useDispatch();
  const [templates, isLoadingTemplates] = useFastdocTemplates();
  const [loading, setLoading] = React.useState(false);

  const template = templates.find(
    (t) => t.Id === chosenTemplate?.id || fastdocId === t.Id
  );
  const templatePrice = template?.Properties.find(
    (p) => p.TypeNamespace === "BlankettPris"
  )?.Value;

  // TODO - get fastdoc balance
  const balance = 0;

  const onPay = async () => {
    setLoading(true);

    try {
      const { data, unauthed } = await getTemplate(
        fastdocId || chosenTemplate?.id,
        true
      );

      if (unauthed) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde ej autentisera mot FastDok",
            description: "Försök igen",
          })
        );

        setLoading(false);
        closeFunction();
      } else if (data) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              [dataPath]: data,
              doc: "contract.pdf",
            },
          })
        );
        const setData = {
          _chosenTemplate: {
            name: chosenTemplate.name,
            id: chosenTemplate.id,
            pay: true,
          },
        };
        dispatch(updateActiveFormInstance({ storeName, data: setData }));
        setLoading(false);

        closeFunction();
      } else {
        setLoading(false);

        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Kunde ej hämta mall",
            description: "Försök igen",
          })
        );

        closeFunction();
      }
    } catch (e) {
      setLoading(false);
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta mall",
          description: "Försök igen",
        })
      );
      closeFunction();
    }
  };

  return (
    <>
      <StandardModal
        withActionBar
        title="Betala vald mall"
        actionBarAcceptTitle="Bekräfta köp"
        isOpen={isOpen}
        saveFunction={onPay}
        closeFunction={closeFunction}
      >
        {loading && <OverlaySpinner />}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <SC.FastDocLogo />

          <p
            style={{
              marginBottom: "12px",
            }}
          >
            <strong>
              Vald mall:
              <br />
            </strong>{" "}
            {template?.Name}
          </p>

          {template?.Description && (
            <p
              style={{
                marginBottom: "12px",

                maxWidth: "600px",
              }}
            >
              <strong>
                Beskriving:
                <br />
              </strong>{" "}
              {template.Description}
            </p>
          )}

          <p style={{ marginBottom: "12px" }}>
            <strong>
              Kostnad:
              <br />
            </strong>{" "}
            {templatePrice} SEK
          </p>

          <p style={{ marginBottom: "12px" }}>
            <strong>
              Saldo Fastdoc:
              <br />
            </strong>{" "}
            {balance} SEK
          </p>
        </div>
      </StandardModal>
    </>
  );
}
