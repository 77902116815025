import Cookies from "js-cookie";
import { currentEnv, ENVIRONMENTS } from "..";

export const setToken = (token) => {
  Cookies.set(
    "management_auth",
    token,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};
export const setImpersonateToken = (token) => {
  Cookies.set(
    "auth",
    token,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setUserId = (userId) => {
  Cookies.set(
    "management_user_id",
    userId,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};
export const setImpersonateUserId = (userId) => {
  Cookies.set(
    "user_id",
    userId,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setIntercomHashKey = (hashKey) => {
  Cookies.set(
    "management_intercom_hash_key",
    hashKey,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};
export const setImpersonateIntercomHashKey = (hashKey) => {
  Cookies.set(
    "intercom_hash_key",
    hashKey,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setUserCreatedDate = (createdDate) => {
  Cookies.set(
    "management_intercom_user_created_at",
    createdDate,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};
export const setImpersonateUserCreatedDate = (createdDate) => {
  Cookies.set(
    "intercom_user_created_at",
    createdDate,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setCustomerData = (customer) => {
  Cookies.set(
    "management_intercom_customer",
    JSON.stringify(customer),
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};
export const setImpersonateCustomerData = (customer) => {
  Cookies.set(
    "intercom_customer",
    JSON.stringify(customer),
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const getToken = () => {
  const token = Cookies.get("management_auth");

  if (token) {
    return { token };
  }

  return { token: null };
};

export const getId = () => {
  const userIdString = Cookies.get("management_user_id");

  if (userIdString) {
    return parseInt(userIdString);
  }

  return null;
};

export const getIntercomHashKey = () => {
  const hashKey = Cookies.get("management_intercom_hash_key");

  if (hashKey) {
    return hashKey;
  }

  return null;
};

export const getUserCreatedDate = () => {
  const userCreatedDate = Cookies.get("management_intercom_user_created_at");

  if (userCreatedDate) {
    return userCreatedDate;
  }

  return null;
};

export const getCustomer = () => {
  const customer = Cookies.get("management_intercom_customer");

  if (customer) {
    return JSON.parse(customer);
  }

  return null;
};

export const clearUserData = () => {
  Cookies.remove(
    "management_auth",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "management_user_id",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "management_intercom_user_created_at",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "management_intercom_customer",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "management_intercom_hash_key",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const formattedToken = () => {
  const { token } = getToken();

  if (token == null) {
    return null;
  }

  return `Token ${token}`;
};
