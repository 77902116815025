import * as React from "react";

import {
  BodyText,
  DetailInnerWrapper,
  DetailLayoutWrapper,
  DetailPageBox,
  InnerBox,
} from "../../components/sharedStyles";

import ErrorBoundary from "../../components/ErrorBoundary";

import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";

import TodosTable from "../../components/Lists/Todos/FullTable";
import { buildQueryString } from "../../store/base";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { update } from "../../store/calendarEvents";
import { useHistory } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [confirmResetOpen, setConfirmResetOpen] = React.useState(false);
  const userId = useSelector((state) => state.app.user?.id);
  const persistantFilterMethod = (obj) => {
    return (
      obj.done &&
      !obj.calendar &&
      (obj.owner?.id == userId ||
        obj.participants?.map((p) => p.user?.id)?.includes(userId))
    );
  };
  const persistantQueryString = buildQueryString({
    calendar__isnull: true,
    done: true,
    mine: true,
  });

  const handleRowClicked = (row) => {
    const todo = row.original;

    setConfirmResetOpen(todo);
  };

  const resetTodo = (id) => {
    dispatch(
      update({
        id,
        forceData: { done: false },
        successCallback: () => {
          setConfirmResetOpen(false);
          push("/");
        },
        errorCallback: () => {
          setConfirmResetOpen(false);
        },
      })
    );
  };

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmResetOpen}
        title={`Vill du markera denna uppgift som "Ej avklarad"?`}
        renderContent={() => (
          <InnerBox>
            <BodyText style={{ fontSize: 500, fontSize: 16 }}>
              {confirmResetOpen?.title}
            </BodyText>
            <BodyText>{confirmResetOpen?.description}</BodyText>
          </InnerBox>
        )}
        closeFunction={() => setConfirmResetOpen(false)}
        acceptCallback={() => resetTodo(confirmResetOpen?.id)}
      />

      <ErrorBoundary>
        <DetailLayoutWrapper>
          <DetailPageHeaderMenu
            isBeta
            title={`Att göra`}
            breadCrumbs={[
              {
                label: "Min arbetsdag",
                url: "/",
              },
              {
                label: "Avklarade uppgifter",
              },
            ]}
          />

          <DetailInnerWrapper>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Avklarade uppgifter</OverviewTitle>
                  <OverviewSubtitle>
                    Tryck på en uppgift för att markera den som ej avklarad
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <TodosTable
                {...{ persistantFilterMethod, persistantQueryString }}
                hideTitle
                hideExport
                hideColumns
                hideFilters
                handleRowClicked={handleRowClicked}
              />
            </DetailPageBox>
          </DetailInnerWrapper>
        </DetailLayoutWrapper>
      </ErrorBoundary>
    </>
  );
};
