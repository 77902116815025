export const CLEANED_PE = {
  CompanyId: {
    _internalId: "CompanyId",
    _label: "Bolagsid",
  },

  AccessToken: {
    _internalId: "AccessToken",
    _label: "Åtkomsttoken",
  },

  StartDate: {
    _internalId: "StartDate",
    _label: "Startdatum",
  },

  PaymentVoucherSeries: {
    _internalId: "PaymentVoucherSeries",
    _label: "Verifikationsserie",
    _helpText:
      "Glöm inte bort att du även behöver sätta verifikationsserien bland bokföringsinställningarna för att synken ska fungera.",
  },

  ProjectDimensionId: {
    _internalId: "ProjectDimensionId",
    _label: "Projektdimension",
  },
};

export const CLEANED_FORTNOX = {
  APICode: {
    _internalId: "APICode",
    _label: "API-nyckel",
    _required: true,
  },
  AccessToken: {
    _internalId: "AccessToken",
    _label: "Åtkomsttoken",
  },
  StartDate: {
    _internalId: "StartDate",
    _label: "Startdatum",
  },
  VoucherSeries: {
    _internalId: "VoucherSeries",
    _helpText:
      "Glöm inte bort att du även behöver sätta verifikationsserien bland bokföringsinställningarna för att synken ska fungera.",
    _label: "Verifikationsserie",
    _required: true,
  },
};

export const CLEANED_AUTOGIRO = {
  AutogiroCustomerNumber: {
    _internalId: "AutogiroCustomerNumber",
    _label: "Kundnummer hos Autogiro",
    _helpText: "Kundnumret måste vara 6 tecken långt",
  },
  CancelAllFutureWithdrawalsOnFailedWithdrawal: {
    _internalId: "CancelAllFutureWithdrawalsOnFailedWithdrawal",
    _label: "Ställ in framtida dragningar vid misslyckad dragning",
  },
  DisableDeliveryOnNewAutogiroApproval: {
    _internalId: "DisableDeliveryOnNewAutogiroApproval",
    _label: "Disable delivery on new autogiro approval ",
  },
  EnableDeliveryOnDeleteAutogiroApproval: {
    _internalId: "EnableDeliveryOnDeleteAutogiroApproval",
    _label: "Enable delivery on delete autogiro approval",
  },
  // AutoCreateDebtorOnUnknownSsnOnNewAutogiroApproval: {
  //   _internalId: "AutoCreateDebtorOnUnknownSsnOnNewAutogiroApproval",
  // },
  SendInvoiceOnFailedWithdrawal: {
    _internalId: "SendInvoiceOnFailedWithdrawal",
    _label: "Skicka faktura vid misslyckad dragning",
  },
  // SendConfirmationEmailOnApprovalChanges: { // Dont unlock this, will cause Billecta to auto send email with their logo to our customers
  //   _internalId: "SendConfirmationEmailOnApprovalChanges",
  // },
};
