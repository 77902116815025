import * as React from "react";

// style, design
import { FormAreaTitle } from "../../../Base/Chapters/styles";
import {
  TextInput,
  AddressField,
  ImageUploader,
  TableSelect,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/companies";

import {
  constants as invoicingSettingConstants,
  useInvoicingSettingPagination,
  performFilter,
  constructStrRep,
  useInvoicingSetting,
} from "../../../../../store/invoicingSettings";
import listDefs from "../../../../Lists/InvoicingSettings/listDefs";

import {
  constants as userConstants,
  useUserPagination,
  performFilter as userPerformFilter,
  constructStrRep as userConstructStrRep,
} from "../../../../../store/users";
import userListDefs from "../../../../Lists/Users/listDefs";

import { useFormInstanceField } from "../../../../../store/base";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const selectedInvoicingConfig = useFormInstanceField({
    storeName,
    fieldKey: "invoicing_config",
  });

  const [existingInvoicingConfig] = useInvoicingSetting(
    selectedInvoicingConfig?.id
  );

  return (
    <>
      <FormAreaTitle>Bolagsuppgifter</FormAreaTitle>

      <TextInput
        title="Bolagsnamn"
        storeName={storeName}
        fieldKey="name"
        method={method}
      />
      <TextInput
        title="Orgnr"
        storeName={storeName}
        fieldKey="orgnr"
        method={method}
      />

      <AddressField
        title="Bolagets adress"
        storeName={storeName}
        fieldKey="address"
        method={method}
      />

      <TableSelect
        storeName={storeName}
        tableStoreName={invoicingSettingConstants.STORE_NAME}
        method={method}
        fieldKey={"invoicing_config"}
        listDefs={listDefs}
        paginationMethod={useInvoicingSettingPagination}
        performFilter={performFilter}
        title="Välj aviseringsinställning..."
        fieldTitle="Föreslagen aviseringsinställning"
        description={
          "Välj vilken basinställning för avisering som ska föreslås för avtal som skapas under bolaget"
        }
        constructStrRep={constructStrRep}
        patchDisplay={existingInvoicingConfig?.str_representation}
        modalInModal
      />

      <ImageUploader
        title="Bolagets logga"
        storeName={storeName}
        fieldKey="image"
        method={method}
      />

      <TableSelect
        storeName={storeName}
        tableStoreName={userConstants.STORE_NAME}
        method={method}
        fieldKey={"owner"}
        listDefs={userListDefs}
        paginationMethod={useUserPagination}
        performFilter={userPerformFilter}
        title="Välj ansvarig användare..."
        fieldTitle="Ansvarig användare"
        constructStrRep={userConstructStrRep}
        modalInModal
      />
    </>
  );
};
