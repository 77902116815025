import styled, { css } from "styled-components";
import { animated } from "react-spring";
import arrowDownBlack from "../../../../assets/svg/arrow_down_black.svg";
import arrowDownBlue from "../../../../assets/svg/arrow_down_blue.svg";
import arrowDownRed from "../../../../assets/svg/arrow_down_red.svg";
import closeCircleBlack from "../../../../assets/svg/close-circle.svg";
import documentWhite from "../../../../assets/svg/material/document_white.svg";

export const FlowFormWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px 300px 80px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1200px;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    padding: 20px;
  }
`;

export const DisplayDocumentWrapper = styled.div`
  position: fixed;
  top: 75px;
  left: 0;
  height: 86vh;
  width: 40vw;
  background-color: white;
  padding: 12px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  transform: ${(p) => (p.open ? "translateX(0)" : "translateX(-100%)")};
  transition: all 0.2s ease-in-out;
  z-index: 999;

  @media (${(p) => p.theme.mediaQueries.desktopSmall}) {
    width: 90vw;
  }

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    top: 5px;
    height: calc(100vh - 10px);
    width: 100vw;
  }
`;

export const InnerDocumentWrapper = styled.div`
  overflow-y: auto;
  height: calc(100% - 40px);
  width: 100%;
`;

export const DisplayDocumentTopWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
`;

export const CloseDisplayDocumentWrapper = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${closeCircleBlack});
`;

export const OpenDisplayDocumentWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  height: 60px;
  width: 40px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  background-image: url(${documentWhite});
  top: calc(50% - 30px);
  right: -40px;
  z-index: 2;
`;

export const FlowFormTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  color: ${(p) => p.theme.colors.graphite};
  position: sticky;
  margin-bottom: 60px;
  max-width: 30%;
  top: 20px;
  align-self: flex-start;
  line-height: 100%;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    position: relative;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const FlowFormChapterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 150px;
  position: relative;
  scroll-margin: 50px;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const ErrorIndicatorContainer = styled.div`
  cursor: pointer;
  position: fixed;
  left: calc(50% - 135px);
  bottom: 100px;
  width: 270px;
  height: 60px;
  padding: 0 12px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 60px;
  box-shadow: ${(p) => p.theme.boxShadows.standardRed};
  border: ${(p) => p.theme.borders.standard};
  z-index: 99;

  &:hover {
    background-color: ${(p) => p.theme.colors.gray1};
  }
`;

export const ErrorIndicatorIcon = styled.div`
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standardRed};
  height: 40px;
  width: 40px;
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  background-image: url(${arrowDownRed});
  border-radius: 50%;
  background-color: white;
  margin-right: 24px;

  ${(p) =>
    !p.isAbove &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ErrorIndicatorText = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.primaryText};
`;

export const FlowFormChapterNavigator = styled(animated.div)`
  position: absolute;
  left: -80px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    display: none;
  }
`;

export const NavigatePrev = styled.div`
  cursor: pointer;
  height: 30px;
  width: 50px;
  background-position: center;
  transform: rotate(180deg);
  background-image: url(${arrowDownBlack});
  background-size: contain;
  background-repeat: no-repeat;

  :hover {
    background-image: url(${arrowDownBlue});
  }
`;

export const NavigateNext = styled.div`
  cursor: pointer;
  height: 30px;
  width: 50px;
  background-position: center;

  background-image: url(${arrowDownBlack});
  background-size: contain;
  background-repeat: no-repeat;

  :hover {
    background-image: url(${arrowDownBlue});
  }
`;

export const FlowFormChapterTitleWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 60px;
  align-self: flex-start;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    position: relative;
    width: 100%;
    top: 0;
    margin-bottom: 12px;
  }
`;

export const FlowFormChapterTitle = styled(animated.div)`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.primaryText};
  margin-bottom: 8px;
`;

export const FlowFormChapterDescription = styled(animated.div)`
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.secondaryText};
`;

export const FlowFormChapter = styled.div`
  background-color: white;
  width: 68%;
  margin-left: 2%;
  max-width: 700px;
  min-height: 200px;
  padding: 24px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) =>
    p.hasError ? p.theme.boxShadows.standardRed : p.theme.boxShadows.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-family: "Inter", sans-serif;
  position: relative;

  @media (${(p) => p.theme.mediaQueries.mobile}) {
    position: relative;
    width: 100%;
    margin-left: 0;
  }
`;
