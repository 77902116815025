import { constants } from "..";
import { addToProcess } from "../../base";
import { removeFromProgress } from "../../base/store/actions";
import * as services from "./services";

export const performSearch = ({
  searchParams,
  successCallback,
  errorCallback,

  // custom response handling - both needed
  resultDispatchType,
  searchId,
}) => {
  return async (dispatch) => {
    try {
      const resp = await services.search({ searchParams });

      if (resultDispatchType && searchId) {
        dispatch({
          type: resultDispatchType,
          payload: {
            result: resp,
            searchId,
          },
        });
      } else {
        dispatch({
          type: constants.SET_SEARCH_RESULT,
          payload: {
            result: resp,
          },
        });
      }

      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

export const performMultiCreditorReturnSearch = ({
  searchInstances,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const calls = searchInstances.map((s) =>
        services.search({ searchParams: s })
      );
      const resp = await Promise.all(calls);

      successCallback(resp);
    } catch (e) {
      errorCallback(e);
    }
  };
};

export const performMultipleInvoiceSearches = ({
  searchInstances = [], // array of searchparams
  successCallback,
  errorCallback,
  typeConstants,
  name,
}) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, typeConstants, name);
      const searches = searchInstances.map((s) =>
        services.search({ searchParams: s })
      );
      const responses = await Promise.all(searches);

      const invoices = responses.reduce((acc, cur) => {
        const currentInvoices = cur.InvoiceActions;

        return [...acc, ...currentInvoices];
      }, []);

      if (typeConstants && name) {
        dispatch({
          type: typeConstants.SET_BILLECTA_SEARCH_RESULT,
          payload: {
            result: invoices,
            name,
          },
        });
      } else {
        throw Error("constants or searchId missing");
      }

      successCallback && successCallback();

      removeFromProgress(dispatch, typeConstants, name);
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback();
    }
  };
};

export const clearSearch = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_SEARCH_RESULT,
      payload: {
        result: null,
      },
    });
  };
};
