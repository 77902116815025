export default [
  {
    title: "Avtal",
    key: "CONTRACT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "id_number",
      "status",
      "start_date",
      "end_date",
      "renew_interval",
      "warning_interval",
      "notify_interval",
      "invoice_month_interval",
      "renew_indefinetely",
    ],
  },
  {
    title: "Indexuppräkning",
    key: "INDEXATION",
    visited: true,
    hasError: false,
    fieldKeys: ["indexation"],
  },
  {
    title: "Uppdatera",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
