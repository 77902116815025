import * as React from "react";
// style, design
import BaseTable from "../Base/CompleteList/Table";
import columnDefs from "./selectListDefs";

// store, state
import { buildRangeFilter } from "../Base/utils";

import {
  useManagementContractPagination,
  performFilter,
  constants,
  detailUrl,
} from "../../../store/managementContracts";
import { buildQueryString } from "../../../store/base";
import { PrimaryButton } from "../../Forms/Base/Buttons";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  onRowClicked,
  checkRowHighlighted,
  forceInitialPageSize,
  hidePagination,
  externalFilters,
  withPersistantSortBy,
  withPersistantGlobalFilter,
  persistantCategories,
  clearCategories,
  onSubmit,
  submitLabel,
}) => {
  const storeName = constants.STORE_NAME;
  const [selected, setSelected] = React.useState([]);

  const handleSelectedUpdated = (sel) => {
    setSelected(sel);
  };

  const filterInstructions = {
    id_number: { operator: "icontains" },
    state: {
      handler: (filterObject) => {
        // for 0 and 1 we want to use an operator to check for any of the two
        // operator must be attached to current_state variable
        // filter on variable state is only supported on backend as a convenience
        if ([0, 1].includes(filterObject?.value)) {
          return buildQueryString({ state__in: [0, 1] });
        }
        return buildQueryString({ state: filterObject.value });
      },
    },
    start_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
    end_date: { handler: (filterObject) => buildRangeFilter(filterObject) },
  };

  const columns = React.useMemo(
    () => columnDefs(),
    [persistantFilterMethod, persistantQueryString]
  );
  const fetchAllTreshold = 25;

  const localStorageHiddenColumnId = "leasecontracts_full_table_hidden";

  // not filterable on backend, but we want to enable filtering on it
  const forceFrontendColumns = [];

  return (
    <>
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useManagementContractPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        localStorageHiddenColumnId={localStorageHiddenColumnId}
        onRowClicked={() => {}}
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Förvaltningsavtal"}
        forceFrontendColumns={forceFrontendColumns}
        forceInitialPageSize={forceInitialPageSize}
        hidePagination={hidePagination}
        withSelectableRows
        handleSelectedUpdated={handleSelectedUpdated}
        {...{
          hideSearch,
          hideFilters,
          hideExport,
          hideColumns,
          onRowClicked,
          checkRowHighlighted,
          externalFilters,
          withPersistantSortBy,
          withPersistantGlobalFilter,
          persistantCategories,
          clearCategories,
        }}
      />

      {selected?.length > 0 && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 42 }}
        >
          <PrimaryButton
            extraStyle={{ minWidth: "300px" }}
            clicked={() => onSubmit(selected)}
            title={submitLabel}
          />
        </div>
      )}
    </>
  );
};
