import * as React from "react";

import BasicTable from "../Table/BasicTable";
import { Field } from "../Table";
import { StatusLabel } from "../../Lists/Base/CompleteList/styles";

export default ({
  tenants,
  realEstates,
  selectTenant,
  hideSearch,
  disableRowClick,
}) => {
  const [selectedRow, setSelectedRow] = React.useState(null);

  const data = React.useMemo(() => {
    return tenants.map((c) => ({
      ...c,
      tableState: {
        state: c.state,
        display: c.state_display,
      },
    }));
  }, [tenants]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "tableState", // accessor is the "key" in the data,

        Cell: (props) => {
          return (
            <StatusLabel
              state={props.value.state}
              onClick={props.column.onClick}
              contentType="tenant"
            >
              {props.value.display}
            </StatusLabel>
          );
        },
      },
      {
        Header: "Namn",
        accessor: "str_representation",
        Cell: (props) => {
          return <Field>{props.value}</Field>;
        },
      },
      {
        Header: "Tillhör fastigheter",
        accessor: "realestates",
        Cell: ({ row }) => {
          const userRealEstates = row.original?.user?.realestates?.map(
            (r) => r.id
          );
          const realEstatesForTenant = realEstates?.filter((r) =>
            userRealEstates?.includes(r.id)
          );

          return (
            <Field>
              {realEstatesForTenant
                ?.map((r) => r.str_representation)
                ?.join(", ") || "-"}
            </Field>
          );
        },
      },
    ],
    []
  );

  const handleRowClicked = (row) => {
    setSelectedRow(row.id);
    selectTenant(row.original);
  };

  return (
    <BasicTable
      data={data}
      columns={columns}
      onRowClicked={disableRowClick ? "" : handleRowClicked}
      selectedRow={selectedRow}
      hideSearch={hideSearch}
      tableId="tenantstable"
      withPersistantSortBy
      withPersistantGlobalFilter
    />
  );
};
