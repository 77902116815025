import styled from "styled-components";

export const DocWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 100px auto;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  background-color: ${(p) => p.theme.colors.gray2};
`;
