import * as React from "react";
import { FormArea, FormAreaTitle } from "../../Forms/Base/Chapters/styles";
import DocumentUploader from "../../Forms/Base/Fields/DocumentUploader";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { TextButton } from "../../Forms/Base/Buttons";

import TemplateContainer from "./Templates/Container";
import { FormAreaDescription } from "../../Billecta/Invoices/styles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { InfoBox } from "../../Displays";
import { useHistory, useParams } from "react-router";
import { getDetailUrl } from "../../../views/EditableDoc/HandleContract";

const STEPS = {
  SELECT_TYPE: "SELECT_TYPE",
  DOCUMENT: "DOCUMENT",
  TEMPLATES: "TEMPLATES",
  UNAUTHED_FASTDOK: "UNAUTHED_FASTDOK",
};

export default ({
  storeName,
  method,
  contract,
  editableDocFieldKey,
  isCancelledDoc,
}) => {
  const dataPath = `docData_`;
  const [step, setStep] = React.useState(STEPS.SELECT_TYPE);
  const { push } = useHistory();
  const { contractType } = useParams();

  const contractDetailUrl = getDetailUrl(contractType);

  const [loadingExistingTemplate, setLoadingExistingTemplate] =
    React.useState(false);

  if (loadingExistingTemplate)
    return (
      <FormArea>
        <OverlaySpinner />
        <FormAreaTitle>Hämtar mall...</FormAreaTitle>
      </FormArea>
    );

  return (
    <>
      <FormArea>
        {step === STEPS.SELECT_TYPE && (
          <>
            {contract && (
              <>
                {isCancelledDoc ? (
                  <InfoBox
                    boxTheme="info"
                    title={`Dokument för digital uppsägning av avtal ${contract.id_number}`}
                    text={`Här sätter du upp det digitala dokumentet för uppsägning av avtalet. Tryck på "Gå vidare till avtal" för att hoppa över detta steg och göra det senare istället.`}
                    renderButton={{
                      title: "Gå vidare till avtal",
                      iconType: "arrow",
                      iconPlacement: "right",
                      clicked: () =>
                        push(contractDetailUrl({ id: contract.id })),
                    }}
                  />
                ) : (
                  <InfoBox
                    boxTheme="info"
                    title={`Dokument för digital signering av avtal ${contract.id_number}`}
                    text={`Här sätter du upp det digitala dokumentet för signering av avtalet. Tryck på "Gå vidare till avtal" för att hoppa över detta steg och göra det senare istället.`}
                    renderButton={{
                      title: "Gå vidare till avtal",
                      iconType: "arrow",
                      iconPlacement: "right",
                      clicked: () =>
                        push(contractDetailUrl({ id: contract.id })),
                    }}
                  />
                )}
              </>
            )}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Välj typ av dokument</OverviewTitle>
                <OverviewSubtitle>
                  Välj om en mall ska användas eller om du vill ladda upp ett
                  eget dokument
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextButton
                iconType="arrow"
                title="Basera på mall"
                clicked={() => setStep(STEPS.TEMPLATES)}
                extraStyle={{ marginBottom: "24px" }}
              />
              <TextButton
                iconType="arrow"
                title="Ladda upp dokument"
                clicked={() => setStep(STEPS.DOCUMENT)}
              />
            </div>
          </>
        )}

        {step === STEPS.DOCUMENT && (
          <>
            <TextButton
              iconType="arrow-back"
              title="Tillbaka"
              clicked={() => setStep(STEPS.SELECT_TYPE)}
              extraStyle={{ marginBottom: "24px" }}
            />
            <FormAreaTitle>Välj dokument</FormAreaTitle>
            <FormAreaDescription>Ladda upp ett dokument</FormAreaDescription>
          </>
        )}

        {step === STEPS.TEMPLATES && (
          <>
            <TextButton
              iconType="arrow-back"
              title="Tillbaka"
              clicked={() => setStep(STEPS.SELECT_TYPE)}
              extraStyle={{ marginBottom: "24px" }}
            />
            <FormAreaTitle>Välj mall</FormAreaTitle>
            <FormAreaDescription>
              Välj vilken mall som ska användas som bas.
            </FormAreaDescription>
          </>
        )}

        {step === STEPS.DOCUMENT && (
          <DocumentUploader
            storeName={storeName}
            fieldKey={`${editableDocFieldKey}${
              editableDocFieldKey ? "." : ""
            }doc`}
            method={method}
          />
        )}

        {step === STEPS.TEMPLATES && (
          <TemplateContainer
            storeName={storeName}
            dataPath={dataPath}
            editableDocPath={editableDocFieldKey}
          />
        )}
      </FormArea>
    </>
  );
};
