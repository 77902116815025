import * as React from "react";

import { Confirm, Contract, Indexation } from "./Chapters";

export default ({ key, method }) => {
  switch (key) {
    case "CONTRACT":
      return <Contract method={method} />;
    case "INDEXATION":
      return <Indexation method={method} />;
    default:
      return <Confirm />;
  }
};
