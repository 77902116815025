import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import FormLayout from "./components/Layout/FormLayout";
import SettingsLayout from "./components/Layout/SettingsLayout";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

// views

import ManagementCreateContract from "./views/ManagementContract/Create/CreateContract";
import ManagementCreateCosts from "./views/ManagementContract/Create/CreateCosts";

import Login from "./views/Auth/Login";

import SignUp from "./views/EditableDoc/SignUp";

import EditableDocHandleContract from "./views/EditableDoc/HandleContract";

import BillectaKyc from "./views/Billecta/detail/Kyc";
import Profile from "./views/Account/Profile";
import EditMyUser from "./views/Account/EditMyUser";
import ResetMyPassword from "./views/Account/ResetMyPassword";
import TermsGdpr from "./views/Account/TermsGdpr";
import Notifications from "./views/Account/Notifications";
import NotificationSettings from "./views/Account/NotificationSettings";
import UsersAndGroups from "./views/Account/UsersAndGroups";
import AddGroup from "./views/Account/AddGroup";
import User from "./views/Account/User";
import EditUser from "./views/Account/EditUser";
import Group from "./views/Account/Group";
import GroupContentTypeDetail from "./views/Account/GroupContentTypeDetail";
import EditGroup from "./views/Account/EditGroup";
import UserSetupCreateUser from "./views/Account/UserSetup/CreateUser";
import UserSetupInviteUser from "./views/Account/UserSetup/InviteUser";
import UserSetupSetUserPermissions from "./views/Account/UserSetup/SetUserPermissions";
import UserSetupPickExistingGroup from "./views/Account/UserSetup/PickExistingGroup";
import UserSetupCreateNewGroup from "./views/Account/UserSetup/CreateNewGroup";
import UserSetupCreateNewWhiteListSettings from "./views/Account/UserSetup/CreateNewWhiteListSettings";

import BaseLayout from "./components/Layout/BaseLayout";

import MyWorkday from "./views/Dashboard/MyWorkday";
import CompanyDetail from "./views/Companies/MainDetail";

import BillectaOverview from "./views/Billecta/MainOverview";
import BillectaDetail from "./views/Billecta/MainDetail";
import InvoicingProduct from "./views/InvoicingSettings/InvoicingProduct";
import InvoicingProject from "./views/InvoicingSettings/InvoicingProject";
import InvoicingCostCenter from "./views/InvoicingSettings/InvoicingCostCenter";
import InvoicingSettingDetail from "./views/InvoicingSettings/Setting";
import DebtInvoicingSettingDetail from "./views/InvoicingSettings/DebtSetting";
import ErrorRedirect from "./views/InvoicingErrors/ErrorRedirect";

import InvoiceDetail from "./views/Billecta/InvoiceDetail";
import MainCompanyOverview from "./views/Companies/MainOverview";
import MainDocumentsOverview from "./views/BasicDoc/MainOverview";
import MainDocumentDetail from "./views/BasicDoc/MainDetail";

import PermissionDeniedPage from "./views/Auth/PermissionDenied";
import TemplateOverview from "./views/EditableDoc/MainOverview";
import CreateTemplate from "./views/EditableDoc/CreateTemplate";

import Calendar from "./views/Calendar/Calendar";
import OutlookActivation from "./views/Calendar/OutlookActivation";
import EditTemplate from "./views/EditableDoc/EditTemplate";
import EditableDocMainDetail from "./views/EditableDoc/MainDetail";
import CancelledDocHandleContract from "./views/EditableDoc/CancelContract";
import InvoicingCustomDimension from "./views/InvoicingSettings/InvoicingCustomDimension";
import InvoicingCustomDimensionEntry from "./views/InvoicingSettings/InvoicingCustomDimensionEntry";
import Todos from "./views/Dashboard/Todos";
import IntegrationsOverview from "./views/Integrations/MainOverview";
import OutlookIntegration from "./views/Integrations/Integrations/Outlook";
import Unit4ErpIntegration from "./views/Integrations/Integrations/Unit4Erp";
import AutogiroIntegration from "./views/Integrations/Integrations/Autogiro";
import KivraIntegration from "./views/Integrations/Integrations/Kivra";
import FortnoxAccountingIntegration from "./views/Integrations/Integrations/FortnoxAccounting";
import PEAccountingIntegration from "./views/Integrations/Integrations/PEAccounting";
import VismaEEconomyIntegration from "./views/Integrations/Integrations/VismaEEconomy";
import ScriveIntegration from "./views/Integrations/Integrations/Scrive";
import CancelManagementContractForm from "./views/ManagementContract/Cancel";
import ManagementContractDetail from "./views/ManagementContract/MainDetail";
import ManagementContractOverview from "./views/ManagementContract/MainOverview";
import MainCustomerOverview from "./views/Customers/MainOverview";
import CustomerDetail from "./views/Customers/MainDetail";
import MainAdminOverview from "./views/Admin/MainOverview";
import ConnectManagementAccounts from "./views/Admin/handle/ConnectMangementAccounts";
import CreateCustomer from "./views/Admin/handle/CreateCustomer";
import HandleCustomerModules from "./views/Admin/handle/HandleModules";
import ScriveCredentials from "./views/Admin/handle/ScriveCredentials";
import ForceInvoicing from "./views/Admin/handle/ForceInvoicing";

export default () => {
  return (
    <Switch>
      {/* Dashboard */}
      <BaseLayout exact path="/" component={MyWorkday} />
      <BaseLayout path="/todos" component={Todos} />
      <BaseLayout exact path="/calendar" component={Calendar} />
      <BaseLayout exact path="/outlookActivate" component={OutlookActivation} />

      {/** Creation and Editing */}
      <FormLayout
        exact
        path="/management-contracts/create/costs/:contractId"
        component={ManagementCreateCosts}
      />
      <FormLayout
        exact
        path="/management-contracts/create/:contractType"
        component={ManagementCreateContract}
      />

      <FormLayout
        exact
        path="/editable-doc/create"
        component={CreateTemplate}
      />
      <FormLayout
        exact
        path="/editable-doc/edit/:editableDocId"
        component={EditTemplate}
      />
      <FormLayout
        exact
        path="/editable-doc/handle/:contractType/:id"
        component={EditableDocHandleContract}
      />
      <FormLayout
        exact
        path="/cancelled-doc/handle/:contractType/:id"
        component={CancelledDocHandleContract}
      />

      <FormLayout
        exact
        path="/management-contracts/cancel/:serviceContractId"
        component={CancelManagementContractForm}
      />

      <FormLayout
        exact
        path="/account/groups-and-users/user-setup/create-user"
        component={UserSetupCreateUser}
      />
      <FormLayout
        exact
        path="/account/groups-and-users/user-setup/set-user-permissions/existing-group/:userId"
        component={UserSetupPickExistingGroup}
      />
      <FormLayout
        exact
        path="/account/groups-and-users/user-setup/set-user-permissions/create-group/:userId"
        component={UserSetupCreateNewGroup}
      />
      <FormLayout
        exact
        path="/account/groups-and-users/user-setup/set-user-permissions/create-white-list-setting/:groupId/:userId"
        component={UserSetupCreateNewWhiteListSettings}
      />
      <FormLayout
        exact
        path="/account/groups-and-users/user-setup/set-user-permissions/:userId"
        component={UserSetupSetUserPermissions}
      />
      <FormLayout
        exact
        path="/account/groups-and-users/user-setup/invite-user/:groupId/:userId"
        component={UserSetupInviteUser}
      />

      <FormLayout
        exact
        path="/admin/handle/management-accounts"
        component={ConnectManagementAccounts}
      />
      <FormLayout
        exact
        path="/admin/handle/scrive-credential-management"
        component={ScriveCredentials}
      />
      <FormLayout
        exact
        path="/admin/handle/create-customer"
        component={CreateCustomer}
      />
      <FormLayout
        exact
        path="/admin/handle/customer-modules"
        component={HandleCustomerModules}
      />
      <FormLayout
        exact
        path="/admin/handle/force-invoice-generator"
        component={ForceInvoicing}
      />
      {/*creation and editing end */}
      {/*details */}

      <BaseLayout
        path="/documents/detail/:basicDocId"
        component={MainDocumentDetail}
      />
      <BaseLayout
        path="/templates/detail/:editableDocId"
        component={EditableDocMainDetail}
      />
      <BaseLayout
        path="/management-contracts/detail/:managementContractId"
        component={ManagementContractDetail}
      />

      <BaseLayout
        path="/customers/detail/:customerId"
        component={CustomerDetail}
      />

      <BaseLayout
        path="/companies/detail/:companyId"
        component={CompanyDetail}
      />
      {/*details end */}

      {/* Integration pages */}
      <BaseLayout exact path="/integrations" component={IntegrationsOverview} />
      <BaseLayout
        exact
        path="/integrations/outlook"
        component={OutlookIntegration}
      />
      <BaseLayout
        exact
        path="/integrations/unit-4-erp"
        component={Unit4ErpIntegration}
      />
      <BaseLayout
        exact
        path="/integrations/autogiro"
        component={AutogiroIntegration}
      />
      <BaseLayout
        exact
        path="/integrations/kivra"
        component={KivraIntegration}
      />
      <BaseLayout
        exact
        path="/integrations/fortnox-accounting"
        component={FortnoxAccountingIntegration}
      />
      <BaseLayout
        exact
        path="/integrations/pe-accounting"
        component={PEAccountingIntegration}
      />
      <BaseLayout
        exact
        path="/integrations/visma-accounting"
        component={VismaEEconomyIntegration}
      />
      <BaseLayout
        exact
        path="/integrations/scrive"
        component={ScriveIntegration}
      />

      {/* Integration pages end */}
      {/* overview */}
      <BaseLayout
        path="/management-contracts"
        component={ManagementContractOverview}
      />
      <BaseLayout path="/companies" component={MainCompanyOverview} />
      <BaseLayout path="/customers" component={MainCustomerOverview} />
      <BaseLayout path="/documents" component={MainDocumentsOverview} />
      <BaseLayout path="/templates" component={TemplateOverview} />
      <BaseLayout path="/admin" component={MainAdminOverview} />

      {/* overview end */}
      {/* Billecta */}
      <BaseLayout path="/error-redirect" component={ErrorRedirect} />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/products/:productId"
        component={InvoicingProduct}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/projects/:projectId"
        component={InvoicingProject}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/cost-centers/:costCenterId"
        component={InvoicingCostCenter}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/custom-dimensions/:customDimensionId"
        component={InvoicingCustomDimension}
      />
      <BaseLayout
        exact
        path="/rental-notifications/invoicing-objects/custom-dimensions-entries/:customDimensionEntryId"
        component={InvoicingCustomDimensionEntry}
      />
      <BaseLayout
        path="/rental-notifications/settings/invoicing-setting/:settingId"
        component={InvoicingSettingDetail}
      />
      <BaseLayout
        path="/rental-notifications/settings/debt-setting/:settingId"
        component={DebtInvoicingSettingDetail}
      />

      <BaseLayout
        path="/rental-notifications/company/:creditorId/invoice/:invoiceId"
        component={InvoiceDetail}
      />
      <BaseLayout
        exact
        path="/rental-notifications/company/:creditorId/kyc"
        component={BillectaKyc}
      />
      <BaseLayout
        path="/rental-notifications/company/:creditorId"
        component={BillectaDetail}
      />
      <BaseLayout path="/rental-notifications" component={BillectaOverview} />
      {/* Scrive */}
      <PrivateRoute exact path="/e-signing/sign-up" component={SignUp} />
      {/* Account & settings */}
      <SettingsLayout exact path="/account/user" component={Profile} />
      <SettingsLayout
        exact
        path="/account/user/edit/:userId"
        component={EditMyUser}
      />
      <SettingsLayout
        exact
        path="/account/user/reset-password"
        component={ResetMyPassword}
      />
      <SettingsLayout
        exact
        path="/account/groups-and-users"
        component={UsersAndGroups}
      />
      <SettingsLayout
        exact
        path="/account/groups-and-users/user/:userId"
        component={User}
      />
      <SettingsLayout
        exact
        path="/account/groups-and-users/user/edit/:userId"
        component={EditUser}
      />

      <SettingsLayout
        exact
        path="/account/groups-and-users/group/:groupId"
        component={Group}
      />
      <SettingsLayout
        exact
        path="/account/groups-and-users/group/:groupId/:contentType"
        component={GroupContentTypeDetail}
      />
      <SettingsLayout
        exact
        path="/account/groups-and-users/group/edit/:groupId"
        component={EditGroup}
      />
      <SettingsLayout
        exact
        path="/account/groups-and-users/add-group"
        component={AddGroup}
      />
      <SettingsLayout exact path="/account/terms-gdpr" component={TermsGdpr} />
      <SettingsLayout
        exact
        path="/account/notifications"
        component={Notifications}
      />
      <SettingsLayout
        exact
        path="/account/notifications/settings"
        component={NotificationSettings}
      />

      <BaseLayout
        exact
        path="/permission-denied"
        component={PermissionDeniedPage}
      />
      {/* Login & signup */}
      <Route exact path="/login" component={Login} />

      {/* Redirections */}
      <Redirect from="*" to="/" />
    </Switch>
  );
};
