import * as React from "react";
import { Route } from "react-router-dom";
import useActiveFilters from "../../hooks/useActiveFilters";

// style, design
import MainSidebar from "./Sidebar/MainSidebar";
import TopArea from "./TopArea/TopArea";

export default ({ component: RouteComponent, path, exact }) => {
  const { filterActive } = useActiveFilters();

  const [sideBarOpen, setSideBarOpen] = React.useState(false);

  React.useEffect(() => {
    const sideBarOpenCache = localStorage.getItem("sidebar__open");

    if (sideBarOpenCache == "false") {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  }, []);

  const toggleSideBar = (open) => {
    localStorage.setItem("sidebar__open", open);
    setSideBarOpen(open);
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => (
        <div style={{ display: "flex" }}>
          <MainSidebar {...{ sideBarOpen, setSideBarOpen: toggleSideBar }} />

          <div
            style={{
              maxWidth: `calc(100vw - ${sideBarOpen ? "250" : "75"}px)`,
              width: `calc(100vw - ${sideBarOpen ? "250" : "75"}px)`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TopArea sideBarOpen={sideBarOpen} />

            <div style={{ display: "flex" }}>
              <RouteComponent {...matchProps} />
            </div>
          </div>
        </div>
      )}
    />
  );
};
