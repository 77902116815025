import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { pulseRed } from "../../../components/sharedStyles";

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.gray5};
  padding: 12px 36px 0 36px;
  width: 100%;
  border-bottom: thin solid ${(p) => p.theme.colors.gray5};
`;

export const TitleWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageLinksWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const HandleActionSeparator = styled.div`
  height: ${(p) => p.theme.fontSizes.headerSmall};
  width: 2px;
  border-radius: 3px;
  background-color: ${(p) => p.theme.colors.gray1};
  margin: 2px 12px;
`;

export const HandleAction = styled.div`
  cursor: pointer;
  position: relative;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.green};
  margin-bottom: -1px;
`;

export const PageLink = styled(NavLink)`
  padding: 8px 24px;
  border-top-left-radius: 8px;
  position: relative;
  border-top-right-radius: 8px;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.gray1};
  border-top: thin solid rgba(0, 0, 0, 0);
  border-left: thin solid rgba(0, 0, 0, 0);
  border-right: thin solid rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
  }

  margin-bottom: -1px;
  &.active {
    color: ${(p) => p.theme.colors.greenDark};
    background-color: ${(p) => p.theme.colors.backgroundGray};
    font-weight: ${(p) => p.theme.fontWeights.headerSmall};
    border-top: thin solid ${(p) => p.theme.colors.gray1};
    border-left: thin solid ${(p) => p.theme.colors.gray1};
    border-right: thin solid ${(p) => p.theme.colors.gray1};
  }

  ${(p) =>
    p.hasError &&
    css`
      &:after {
        position: absolute;
        top: calc(50% - 5px);
        right: 8px;
        height: 10px;
        width: 10px;
        animation: ${pulseRed} 1s linear infinite;
        border: ${(p) => p.theme.borders.standardRed};
        content: "";
        border-radius: 50%;
        background-color: ${(p) => p.theme.colors.red};
      }
    `}
`;

export const InfoPillsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0;
`;

export const AddEventWrapper = styled.div`
  display: flex;
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.mini};
  color: ${(p) => p.theme.colors.gray2};
  margin-left: auto;
  align-items: center;
`;

export const AddEventLink = styled.div`
  color: ${(p) => p.theme.colors.green};
  margin: 0 6px;
  text-decoration: underline;
  cursor: pointer;
`;
