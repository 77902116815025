import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

export async function authenticateAsCustomer(customerId) {
  const res = await axiosInstance.post(
    `${constants.AUTHENTICATE_AS_CUSTOMER_URL}${customerId}/`
  );

  return res;
}
