import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { deletePayment } from "../../../store/billectaInvoicing/store/actions";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import NonConnectedCheckbox from "../../Forms/Base/Old/NonConnected/NonConnectedCheckbox";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import NonConnectedTextInput from "../../Forms/Base/Old/NonConnected/NonConnectedTextInput";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";
import { BodyText } from "../../sharedStyles";

const OPTIONS = {
  NON_ACTION: 1,
  MANUAL: 2,
};

const todaysDate = moment().toDate();

export default ({ isOpen, closeFunction, paymentId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [selectedKind, setSelectedKind] = React.useState(OPTIONS.NON_ACTION);
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [account, setAccount] = React.useState("");
  const { creditorId } = useParams();

  const successCallback = (bookKeeping) => {
    setLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Betalningen togs bort",
        description: bookKeeping
          ? "Borttagandet bokfördes"
          : "Borttagandet bokförs ej",
      })
    );

    closeFunction();
  };

  const errorCallback = (message) => {
    setLoading(false);

    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Betalningen kunde ej tas bort",
        description: message ? message : "",
      })
    );
  };

  const handleRemovePayment = () => {
    setLoading(true);
    if (selectedKind === OPTIONS.NON_ACTION) {
      dispatch(
        deletePayment({
          creditorId,
          paymentId,
          successCallback: () => successCallback(false),
          errorCallback,
        })
      );
    } else {
      dispatch(
        deletePayment({
          creditorId,
          paymentId,
          transactionDate: date,
          bookKeepingAccount: account,
          successCallback: () => successCallback(true),
          errorCallback,
        })
      );
    }
  };

  const canAccept =
    selectedKind === OPTIONS.NON_ACTION ? true : !!date && !!account;

  return (
    <StandardModal
      {...{ isOpen, closeFunction }}
      withActionBar
      title="Ta bort betalning"
      saveFunction={handleRemovePayment}
      canAccept={canAccept}
      actionBarAcceptTitle="Ta bort betalning"
    >
      {loading && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitle>Hur vill du bokföra borttagningen?</OverviewTitle>
      </OverviewTitleWrapper>

      <BodyText>
        Betalningen har bokförts på 'Förskott från kund' i
        bokföringsinställningarna. Hur vill du att bokföringen ska ske vid
        borttagning?
      </BodyText>

      <NonConnectedCheckbox
        id="non-action"
        radioStyle
        extraStyles={{ margin: "24px 0 12px 0" }}
        title="Bokför inte borttagningen"
        value={selectedKind === OPTIONS.NON_ACTION}
        onChange={(checked) => {
          if (checked) {
            setSelectedKind(OPTIONS.NON_ACTION);
          }
          return;
        }}
        noMargin
      />

      <NonConnectedCheckbox
        extraStyles={{ marginBottom: 24 }}
        id="manual"
        radioStyle
        title="Bokför på följande konto: "
        value={selectedKind === OPTIONS.MANUAL}
        onChange={(checked) => {
          if (checked) {
            setSelectedKind(OPTIONS.MANUAL);
          }
          return;
        }}
        noMargin
      />

      {selectedKind === OPTIONS.MANUAL && (
        <NonConnectedTextInput
          value={account}
          onChange={(val) => setAccount(val)}
          label="Konto"
          id="account"
        />
      )}

      {selectedKind !== OPTIONS.NON_ACTION && (
        <NonConnectedDatePicker
          id="date-date"
          value={date}
          label="Transaktionsdatum"
          onChange={(newDate) => setDate(newDate)}
          maxDate={todaysDate}
        />
      )}

      {selectedKind === OPTIONS.NON_ACTION && (
        <BodyText style={{ marginTop: 8 }}>
          <strong>OBS:</strong> Betalningen har bokförts. Tar du bort
          betalningen kommer den ligga kvar i bokföringen och du behöver
          manuellt skapa en bokföringspost för att boka bort den.
        </BodyText>
      )}
    </StandardModal>
  );
};
