import styled from "styled-components";

export const BottomBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 70px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  z-index: 999;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
`;

export const SelectWrapper = styled.div`
  width: 140px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
