import * as React from "react";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { buildQueryString } from "../../../store/base";
import DocsTable from "../../../components/Lists/BasicDocs/FullTable";

export default function BasicDocs() {
  const persistantQueryString = buildQueryString({});
  const persistantFilterMethod = (obj) => {
    return obj;
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Översikt</OverviewTitle>
          </OverviewTitleWrapper>

          <DocsTable
            persistantQueryString={persistantQueryString}
            persistantFilterMethod={persistantFilterMethod}
            hideTitle
            withPersistantSortBy
            withPersistantGlobalFilter
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
