import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

// style, design
import * as SC from "../Base/Chapters/styles";
import { DoubleFieldWrapper } from "../Base/Chapters/styles";
import { TextInput, Checkbox } from "../Base/Fields";
import { PrimaryButton } from "../Base/Buttons";

// store, state
import {
  constants,
  destroyPostForm,
  create,
  update,
} from "../../../store/billectaKyc";
import { constants as companyConstants } from "../../../store/companies";
import { useKycForm } from "../../../store/billectaKyc/hooks/form";
import ManyRelationalOneWay from "../Base/FieldComponents/ManyRelationalOneWay";
import UsPersonNestedChildren from "./UsPersonNestedChildren";
import MajorityHoldersNestedChildren from "./MajorityHoldersNestedChildren";
import BeneficialOwnersNestedChildren from "./BeneficialOwnersNestedChildren";
import { useHistory } from "react-router-dom";
import { clearFetched } from "../../../store/base";
import OverlaySpinner from "../../Loaders/OverlaySpinner";

export default ({ company, existingKyc }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  // Always POST for KYC
  const method = "POST";

  const formLoaded = Boolean(useKycForm());

  const managesCurrencyExchanges = useSelector(
    (state) => !!state[storeName].formInstance?.managing_currency_exchanges
  );
  const managesCash = useSelector(
    (state) => !!state[storeName].formInstance?.manages_cash
  );
  const hasUsOwners = useSelector(
    (state) => !!state[storeName].formInstance?.has_us_owner
  );
  const hasMajorityOwners = useSelector(
    (state) => !!state[storeName].formInstance?.has_owners_with_majority
  );
  const hasBeneficialOwners = useSelector(
    (state) => !state[storeName].formInstance?.has_no_benifitial_owners
  );

  const getUsPersonChildren = (index) => {
    const parentPath = `us_persons[${index}]`;
    const parentInstructionsPath = "us_persons";

    return (
      <UsPersonNestedChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  const getMajorityHoldersChildren = (index) => {
    const parentPath = `majority_holders[${index}]`;
    const parentInstructionsPath = "majority_holders";

    return (
      <MajorityHoldersNestedChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  const getBeneficialHoldersChildren = (index) => {
    const parentPath = `benifitial_owners[${index}]`;
    const parentInstructionsPath = "benifitial_owners";

    return (
      <BeneficialOwnersNestedChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  const checkout = (success) => {
    setLoading(false);
    if (success) {
      dispatch(clearFetched(companyConstants, true));
    }

    push(`/rental-notifications/company/${company.billecta_id}`);
    dispatch(destroyPostForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    if (existingKyc) {
      dispatch(
        update({
          id: existingKyc.id,
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    } else {
      dispatch(
        create({
          successCallback: () => checkout(true),
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  return (
    <>
      {formLoaded ? (
        <SC.FormArea>
          <TextInput
            title="Namn"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="name"
          />

          <DoubleFieldWrapper>
            <TextInput
              title="Namn på signerande part"
              extraStyles={{ flex: "1", marginRight: "20px" }}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="signing_name"
            />

            <TextInput
              title="Personnummer på signerande part"
              extraStyles={{ flex: 1 }}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="signing_legal_id"
            />
          </DoubleFieldWrapper>
          <DoubleFieldWrapper>
            <TextInput
              title="E-post"
              extraStyles={{ flex: "1", marginRight: "20px" }}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="email"
            />

            <TextInput
              title="Telefon"
              extraStyles={{ flex: 1 }}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="phone"
            />
          </DoubleFieldWrapper>
          <DoubleFieldWrapper>
            <TextInput
              title="Medborgare i land"
              extraStyles={{ flex: "1", marginRight: "20px" }}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="citizen_ship_country"
            />

            <TextInput
              title="Skatteresidens"
              extraStyles={{ flex: 1 }}
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="tax_country"
            />
          </DoubleFieldWrapper>

          <Checkbox
            title="Politiskt utsatt person"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="has_pep_business_relation"
          />

          <Checkbox
            title="Bolaget har majoritetsägare"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="has_owners_with_majority"
          />

          {hasMajorityOwners && (
            <ManyRelationalOneWay
              storeName={constants.STORE_NAME}
              method={method}
              fieldKey="majority_holders"
              getChildren={getMajorityHoldersChildren}
              modalTitle="Lägg till majoritetsägare"
              constructStrRep={(obj) => {
                return obj.name;
              }}
            />
          )}

          <Checkbox
            title="Inga huvudmän"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="has_no_benifitial_owners"
          />

          {hasBeneficialOwners && (
            <ManyRelationalOneWay
              storeName={constants.STORE_NAME}
              method={method}
              fieldKey="benifitial_owners"
              getChildren={getBeneficialHoldersChildren}
              modalTitle="Lägg till ägare"
              constructStrRep={(obj) => {
                return obj.name;
              }}
            />
          )}

          <Checkbox
            title="Någon av ägarna är politiskt utsatt"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_anyone_pep"
          />

          <Checkbox
            title="Bolaget är ett finansbolag"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_financial_institute"
          />

          <Checkbox
            title="Bolaget är ett aktivt icke-finansiellt bolag"
            description={`Ett företag definieras som aktiv icke‐finansiell enhet bl.a. om något avföljande stämmer: Minst 50 procent av företagets inkomster före skatt kom från försäljning av varor och/eller tjänster under föregående räkenskapsår Företaget är, eller ägs av stat, kommun eller landsting. Företagets eller företagsgruppens aktier handlas på en etablerad värdepappersmarknad
          `}
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_active_non_financial_institute"
          />

          <Checkbox
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="has_us_owner"
            title="Bolaget har amerikanska ägare"
          />

          {hasUsOwners && (
            <ManyRelationalOneWay
              storeName={constants.STORE_NAME}
              method={method}
              fieldKey="us_persons"
              getChildren={getUsPersonChildren}
              modalTitle="Lägg till amerikansk medborgare"
              constructStrRep={(obj) => {
                return obj.name;
              }}
            />
          )}

          <Checkbox
            title="Bolaget är skatteskyldigt i U.S.A."
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="taxable_in_us"
          />

          <Checkbox
            title="Bolaget hanterar kontanter"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="manages_cash"
          />

          {managesCash && (
            <TextInput
              title="Anledning till kontanthantering"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="managing_cash_purpose"
            />
          )}

          <Checkbox
            title="Bolaget hanterar valutaväxling"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="managing_currency_exchanges"
          />

          {managesCurrencyExchanges && (
            <TextInput
              title="Anledning till hantering av valutaväxling"
              method={method}
              storeName={constants.STORE_NAME}
              fieldKey="managing_currency_exchange_purpose"
            />
          )}
          <Checkbox
            title="Bolaget handlar med fastigheter"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_real_estate_sales"
          />

          <Checkbox
            title="Bolaget bygger fastigheter"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_real_estate_construction"
          />

          <Checkbox
            title="Bolaget bedriver restaurang"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_restaurant"
          />

          <Checkbox
            title="Bolaget bedriver kafé"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_cafe"
          />

          <Checkbox
            title="Bolaget bedriver mäkleri"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_broker"
          />

          <Checkbox
            title="Bolaget bedriver nattklubb"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_night_club"
          />

          <Checkbox
            title="Bolaget bedriver kioskverksamhet"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_kiosk"
          />

          <Checkbox
            title="Bolaget bedriver kasino/hasard-spel online"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_online_gambling"
          />

          <Checkbox
            title="Bolaget bedriver städverksamhet"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_cleaning"
          />

          <Checkbox
            title="Bolaget bedriver vadslagningsverksamhet"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_betting"
          />

          <Checkbox
            title="Bolaget bedriver taxiverksamhet"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_taxi"
          />

          <Checkbox
            title="Bolaget bedriver frisörverksamhet"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="is_barber"
          />

          <TextInput
            title="Bolagets övriga affärsverksamheter"
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="other_service"
          />

          <Checkbox
            title="Jag/vi intygar att informationen angiven är korrekt"
            description={`
            Jag/vi försäkrar på heder och samvete att här lämnade uppgifter är riktiga 
och jag/vi förbinder oss att utan dröjsmål anmäla förändringar i detta 
avseende till Billecta AB. Undertecknade godkänner även att uppgifter om 
företaget/enheten och verkliga huvudmän rapporteras till relevanta 
skattemyndigheter för det fall rättsliga rapporteringsplikt föreligger.`}
            method={method}
            storeName={constants.STORE_NAME}
            fieldKey="accept_value"
            alwaysShowHelp
          />

          <PrimaryButton clicked={onSubmit} title="Registera blankett" />
        </SC.FormArea>
      ) : (
        <OverlaySpinner />
      )}
    </>
  );
};
