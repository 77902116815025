import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEqual } from "lodash";

import * as SC from "./styles";

import {
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../store/base";
import {
  FIELD_TYPES,
  getFieldType,
  toExternalSize,
  toInternalFontSize,
  toInternalSize,
} from "../utils";
import CheckboxField from "./FieldTypes/CheckboxField";
import RadioField from "./FieldTypes/RadioField";
import PdfInputField from "./FieldTypes/PdfInputField";
import IntervalInputField from "./FieldTypes/IntervalInputField";
import SignField from "./FieldTypes/SignField/SignField";

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}
export default React.memo(
  ({
    storeName,
    method,
    position,
    partyKey,
    fieldKey,
    positionKey,
    disabled,
    canClick,
  }) => {
    const dispatch = useDispatch();

    const [showActions, setShowActions] = React.useState(false);

    const hideActionsTimeOutRef = React.useRef();

    const kind = useFormInstanceField({
      storeName,
      fieldKey: `${fieldKey}.kind`,
    });
    const fsrel = useFormInstanceField({
      storeName,
      fieldKey: `${positionKey}.fsrel`,
    });

    const name = useFormInstanceField({
      storeName,
      fieldKey: `${fieldKey}.name`,
    });

    const fieldType = getFieldType(kind);

    const [displayName, setDisplayName] = React.useState(false);

    const valueKey =
      fieldType === FIELD_TYPES.CHECKBOX ? "is_checked" : "value";
    const value = useFormInstanceField({
      storeName,
      fieldKey: `${fieldKey}.${valueKey}`,
    });

    const fullParty = useFormInstanceField({ storeName, fieldKey: partyKey });
    const partyColor = fullParty?._color;

    const fieldError = useFormError({ storeName, fieldKey: fieldKey });
    const hasErr =
      fieldError &&
      (Array.isArray(fieldError)
        ? fieldError.length
        : fieldError.constructor === Object
        ? Object.keys(fieldError).length
        : true);

    const fieldInstructionKey = fieldKey.replaceAll(/\[[^\]]*\]/g, "");

    // set data for handle field component in redux
    const setHandleFieldAttributes = () => {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            _handleFieldAttrs: {
              partyKey: partyKey,
              fieldKey: fieldKey,
              fieldInstructionKey: fieldInstructionKey,
              positionKey: positionKey,
              position,
            },
          },
        })
      );
    };

    const updateSize = (size) => {
      // important, to make sure that it doesn't from automatic re-rendering
      const { w, h } = toExternalSize(size);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [`${positionKey}.wrel`]: w, [`${positionKey}.hrel`]: h },
        })
      );
    };

    React.useEffect(() => {
      const { f: fontSize } = toInternalFontSize({ f: fsrel });

      const canvasFontSize = fontSize === 20 ? 23 : fontSize === 18 ? 21 : 19;

      let size = {
        width: value ? getTextWidth(value, `${canvasFontSize}px Arial`) : 100,
        height: 38,
      };

      if (kind === FIELD_TYPES.CHECKBOX || kind === FIELD_TYPES.RADIO) {
        size = {
          width: 20,
          height: 20,
        };
      }

      if (kind.includes("interval")) {
        size = {
          width: 150,
          height: 38,
        };
      }

      if (kind === FIELD_TYPES.SIGNATURE) {
        size = {
          width: 200,
          height: 50,
        };
      }

      updateSize(size);
    }, [value, kind]);

    const getInputField = () => {
      const size = toInternalSize({ w: position.wrel, h: position.hrel });

      const inputStyle = {
        width: "100%",
        height: "100%",
        backgroundColor: partyColor,
      };

      const fontSize = toInternalFontSize({ f: position.fsrel })?.f;

      let moveIsTopMounted = false;
      let moveIsLeftMounted = false;
      if (position?.yrel > 0.5) {
        moveIsTopMounted = true;
      }
      if (position?.xrel > 0.5) {
        moveIsLeftMounted = true;
      }
      // most simple: text input
      // not passsing partyKey => no reverse autofill of field value
      // => no changing of field value
      return (
        <div
          onMouseOver={() => {
            clearTimeout(hideActionsTimeOutRef.current);
            setShowActions(true);
          }}
          onMouseLeave={() => {
            hideActionsTimeOutRef.current = setTimeout(() => {
              setShowActions(false);
            }, 2000);
          }}
        >
          {displayName && <SC.DisplayName>{name}</SC.DisplayName>}

          {showActions && (
            <>
              <SC.Move
                {...{ moveIsTopMounted, moveIsLeftMounted }}
                className={disabled ? "no-move" : "handle"}
              />
              <SC.Edit
                {...{ moveIsTopMounted, moveIsLeftMounted }}
                className={"no-move"}
                onClick={() =>
                  disabled || !canClick ? undefined : setHandleFieldAttributes()
                }
              />
            </>
          )}

          {!canClick && <SC.CrossHair />}
          <SC.PDFInputFieldWrapperWithCrossHair
            lockedMinSize={
              fieldType === FIELD_TYPES.CHECKBOX ||
              fieldType === FIELD_TYPES.RADIO
            }
            noMaxHeight={fieldType === FIELD_TYPES.SIGNATURE}
            onMouseOver={() => setDisplayName(true)}
            onMouseLeave={() => setDisplayName(false)}
            tabIndex="0"
            className="no-move"
            {...{
              hasErr,
              width: `${size.w}px`,
              height: `${size.h}px`,
            }}
          >
            {fieldType === FIELD_TYPES.CHECKBOX ? (
              <>
                <CheckboxField
                  className={"no-move"}
                  storeName={storeName}
                  fieldKey={fieldKey}
                  method={method}
                  instructionsKey={`${fieldInstructionKey}.is_checked`}
                  extraStyles={inputStyle}
                  style={{ backgroundColor: partyColor }}
                  partyKey={partyKey}
                />
              </>
            ) : fieldType === FIELD_TYPES.RADIO ? (
              <>
                <RadioField
                  className={"no-move"}
                  storeName={storeName}
                  fieldKey={fieldKey}
                  method={method}
                  instructionsKey={`${fieldInstructionKey}.value`}
                  extraStyles={inputStyle}
                  style={{ backgroundColor: partyColor }}
                  placementKey={positionKey}
                />
              </>
            ) : fieldType === FIELD_TYPES.INTERVAL ? (
              <>
                <IntervalInputField
                  className={"no-move"}
                  storeName={storeName}
                  fieldKey={fieldKey}
                  method={method}
                  instructionsKey={`${fieldInstructionKey}.value`}
                  extraStyles={inputStyle}
                  style={{ backgroundColor: partyColor }}
                  placementKey={positionKey}
                  borderColor={partyColor}
                />
              </>
            ) : fieldType === FIELD_TYPES.SIGNATURE ? (
              <>
                <SignField
                  className="no-move"
                  party={fullParty}
                  color={partyColor}
                  fontSize={fontSize}
                />
              </>
            ) : (
              <>
                <PdfInputField
                  className={"no-move"}
                  name={`${partyKey}-${fieldKey}`}
                  storeName={storeName}
                  fieldKey={fieldKey}
                  autocomplete={false}
                  method={method}
                  instructionsKey={`${fieldInstructionKey}.value`}
                  noTitle
                  noMargin
                  borderColor={partyColor}
                  partyKey={partyKey}
                  fontSize={fontSize ? `${fontSize}px` : undefined}
                />
              </>
            )}
          </SC.PDFInputFieldWrapperWithCrossHair>
        </div>
      );
    };

    return <>{getInputField()}</>;
  }
);
