import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import CustomerInvoicingTable from "../../../components/Lists/InvoicingCustomer/FullTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import PageSlider from "../../Layouts/PageSlider/PageSlider";
import { buildQueryString } from "../../../store/base";
import { useHistory, useParams } from "react-router";
import { useFilteredCompanies } from "../../../store/companies";
import ContractInvoicesTable from "../../../components/Billecta/Invoices/ContractInvoicesTable";
import { useActiveContractInvoices } from "../../../store/billectaInvoicing";
import ContainerSpinner from "../../../components/Loaders/ContainerSpinner";
import { useFilteredCustomerInvoicings } from "../../../store/invoicingCustomer";

import { detailUrl as customerDetailUrl } from "../../../store/managementContracts";

const TABS = {
  OVERVIEW: "Översikt",
  CUSTOMER: "Förvaltningsavtal",
};

function InvoicingContracts() {
  const { creditorId } = useParams();
  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });

  const { push } = useHistory();
  const [companies, isLoadingCompany] = useFilteredCompanies(companyQuery);
  const [pushToContractInvoiceId, setPushToContractInvoiceId] =
    React.useState(null);
  const company = companies?.[0];
  const [selectedTab, setSelectedTab] = React.useState("OVERVIEW");

  const matchingPushQuery = buildQueryString({
    billecta_object_ids: pushToContractInvoiceId || [],
  });

  const [matchingPushToCustomerInvoicings] =
    useFilteredCustomerInvoicings(matchingPushQuery);
  const matchingPushToCustomerInvoice = matchingPushToCustomerInvoicings?.[0];
  const customerContractId =
    matchingPushToCustomerInvoice?.customer_contract?.id;

  const [contractInvoices, contractInvoicesLoading] = useActiveContractInvoices(
    { creditorId }
  );

  if (customerContractId && pushToContractInvoiceId) {
    push(customerDetailUrl({ id: customerContractId }));
    setPushToContractInvoiceId(null);
  }

  const persistantQuery = buildQueryString({
    billing_company: company?.id,
  });

  const persistantFilter = (obj) => obj.billing_company?.id === company?.id;

  const onContractInvoiceClicked = ({ original }) => {
    const id = original.ActionPublicId;

    setPushToContractInvoiceId(id);
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>{TABS[selectedTab]}</OverviewTitle>

          <PageSlider
            {...{
              TABS,
              selectedTab,
              onTabSelected: (tab) => setSelectedTab(tab),
            }}
          />
        </OverviewTitleWrapper>

        {selectedTab === "OVERVIEW" && (
          <>
            {contractInvoicesLoading ? (
              <ContainerSpinner />
            ) : (
              <ContractInvoicesTable
                contractInvoices={contractInvoices}
                handleRowClicked={onContractInvoiceClicked}
              />
            )}
          </>
        )}

        {selectedTab === "CUSTOMER" && (
          <CustomerInvoicingTable
            hideTitle
            hideColumns
            {...{
              persistantFilterMethod: persistantFilter,
              persistantQueryString: persistantQuery,
            }}
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default InvoicingContracts;
