import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { axiosInstance } from "../../../utils";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { PrimaryButton, TextButton } from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { BodyText, InnerBox } from "../../sharedStyles";
import * as SC from "./styles";
import QRCode from "qrcode.react";
import { logout } from "../../../store/app/actions";
import constants from "../../../store/app/constants";

const STEPS = {
  INFO: 1,
  ACTION: 2,
  DONE: 3,
};

export default function MfaSetting() {
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(STEPS.INFO);
  const [resetCode, setResetCode] = React.useState("");
  const [qrUrl, setQrUrl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const enforce = useSelector((state) => state.app.enforceMfaSetting);

  const requestActivation = async () => {
    setLoading(true);

    try {
      const { data } = await axiosInstance.post(
        "/accounts/auth/mfa_activation/"
      );

      const { url, reset_code } = data;

      setQrUrl(url);
      setResetCode(reset_code);
      setLoading(false);
      setStep(STEPS.ACTION);
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel",
          description: "Försök igen. Kontakta Pigello om problemet kvarstår",
        })
      );
    }
  };

  const copyKey = () => {
    navigator.clipboard.writeText(resetCode);

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Nyckel kopierad till urklipp",
      })
    );
  };

  return (
    <SC.MfaWrapper>
      {loading && <OverlaySpinner />}
      <InnerBox style={{ maxWidth: 800 }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Konfigurera 2-faktorsautentisering (MFA)
            </OverviewTitle>
            {enforce && (
              <OverviewSubtitle>
                Kontoägaren har ställt in att detta är ett krav. Sätt upp
                2-faktorsautentisering för att fortsätta.
              </OverviewSubtitle>
            )}
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {step === STEPS.INFO && (
          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Förberedelser</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <BodyText style={{ marginBottom: 12 }}>
              2-faktorsautentisering innebär att varje inloggning i Pigello
              bekräftas med en kod som genereras av en extern applikation.
              <br />
              Exempel på applikationer som kan användas är{" "}
              <strong>Google Authenticator</strong> och{" "}
              <strong>Microsoft Authenticator</strong>. Båda dessa kan laddas
              ned från App Store och Google Play kostnadsfritt.
            </BodyText>
            <ul>
              <li style={{ marginBottom: 8 }}>
                1. Ladda ned antingen <strong>Google Authenticator</strong>{" "}
                eller <strong>Microsoft Authenticator</strong> till din
                mobiltelefon.
              </li>

              <li style={{ marginBottom: 8 }}>
                2. <strong>OBS: Viktigt.</strong> Vid aktivering kommer du att
                få en <strong>återställningskod</strong> för att återställa din
                inloggning utifall du ej längre kommer åt din mobiltelefon för
                att generera koder. Denna måste{" "}
                <strong>skrivas ner och förvaras säkert</strong>.
              </li>
              <li>
                3. I nästa steg kommer du att behöva scanna en QR-kod för att
                koppla din app för 2-faktorsautentisering mot Pigello. Var redo
                med mobilen och appen.
              </li>
            </ul>

            <hr />

            <div
              style={{
                margin: "24px auto 0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PrimaryButton
                title="Aktivera 2-faktorsautentisering"
                clicked={requestActivation}
              />

              {!enforce && (
                <TextButton
                  extraStyle={{ marginTop: 12 }}
                  title="Avbryt aktivering"
                  clicked={() => {
                    dispatch({
                      type: constants.MFA_SETTING,
                      payload: {
                        show: false,
                      },
                    });
                  }}
                />
              )}
            </div>
          </InnerBox>
        )}

        {step === STEPS.ACTION && (
          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Aktivera app</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BodyText style={{ marginBottom: 12 }}>
                <strong>Microsoft Authenticator:</strong>
                <br />
                1. Tryck på "+" uppe till höger.
                <br />
                2. Välj "Annat"
                <br />
                3. Scanna QR-koden
              </BodyText>
              <BodyText>
                <strong>Google Authenticator:</strong>
                <br />
                1. Tryck på "+" nere till vänster.
                <br />
                2. Välj "QR-kod"
                <br />
                3. Scanna QR-koden
              </BodyText>
            </div>

            <hr />

            <div style={{ margin: "12px auto 12px auto" }}>
              {qrUrl && <QRCode value={qrUrl} />}
            </div>

            <div style={{ margin: "24px auto 0 auto" }}>
              <PrimaryButton
                title="Jag har aktiverat appen, gå vidare"
                clicked={() => setStep(STEPS.DONE)}
              />
            </div>
          </InnerBox>
        )}

        {step === STEPS.DONE && (
          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>
                  2-faktorsautentisering aktiverat
                </OverviewTitle>
                <OverviewSubtitle>
                  Spara återställningsnyckeln och logga in på nytt för att komma
                  igång med Pigello
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <BodyText style={{ marginBottom: 12 }}>
              <InnerBox>
                <strong>OBS: Spara denna återställningsnyckel:</strong>

                <br />

                <InnerBox>
                  <strong>{resetCode}</strong>
                </InnerBox>
                <br />

                <TextButton
                  title="Kopiera nyckel till urklipp"
                  iconType="copy"
                  iconPlacement="right"
                  clicked={copyKey}
                />
              </InnerBox>
            </BodyText>

            <hr />

            <div style={{ margin: "24px auto 0 auto" }}>
              <PrimaryButton
                title="Jag har sparat nyckeln, logga in på nytt"
                clicked={() => {
                  dispatch(logout());
                }}
              />
            </div>
          </InnerBox>
        )}
      </InnerBox>
    </SC.MfaWrapper>
  );
}
