import * as React from "react";
import { detailUrl as debtSettingDetailUrl } from "../../../store/invoicingDebtSettings";
import { LinkedObject } from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

export default () => {
  const cols = [
    {
      Header: "Titel",
      accessor: "str_representation",
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Inställning för betalningsvillkor",
      accessor: "debt_setting",
      Cell: ({ row }) => {
        const obj = row.original.debt_setting;

        return <LinkedObject obj={obj} urlMethod={debtSettingDetailUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];

  return cols;
};
