import * as React from "react";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import invoicingBaseChapters from "./InvoicingBaseChapters";
import {
  detailUrl,
  useManagementContract,
} from "../../../../store/managementContracts";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import StepDisplay from "../../../StepDisplay/StepDisplay";
import {
  useCustomerInvoicingForm,
  constants,
  create,
  destroyPostForm,
} from "../../../../store/invoicingCustomer";

import {
  constants as invoicingSettingConstants,
  create as createInvoicingSetting,
  useInvoicingSettingForm,
  useInvoicingSetting,
} from "../../../../store/invoicingSettings";

import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
  useFrequentPermissions,
} from "../../../../store/base";
import invoicingBaseChapterDefs from "./invoicingBaseChapterDefs";
import invoicingBaseDescriptions from "./InvoicingBaseDescriptions";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";

import { useCompany } from "../../../../store/companies";
import StandardModal from "../../../Modals/StandardModal";
import { BodyText } from "../../../sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import {
  handleContractEditableDocUrl,
  EDITABLE_DOC_CONTRACT_TYPES,
  useEditabledoc,
} from "../../../../store/editabledocs";
import CreateInvoicingSettingBase from "../../Costs/CreateInvoicingSettingBase/CreateInvoicingSettingBase";
import moment from "moment";

const STEPS = {
  INVOICING_BASE: "INVOICING_BASE",
  INVOICING_BASE_CREATE: "INVOICING_BASE_CREATE",
  COSTS: "COSTS",
};

export default ({ method = "POST" }) => {
  const [step, setStep] = React.useState(STEPS.INVOICING_BASE);
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const [alreadyHasInvoicing, setAlreadyHasInvoicing] = React.useState(false);
  const [skipInvoicing, setSkipInvoicing] = React.useState(false);
  const formLoaded = Boolean(useCustomerInvoicingForm(method));
  useInvoicingSettingForm(method);
  const { contractId } = useParams();

  const { hasBillectaFullPermission } = useFrequentPermissions();
  const userPermissionsLoaded = useSelector(
    (state) => !!state.app.user?.permissions
  );
  const selectedSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });

  const selectedBillingCompany = useFormInstanceField({
    storeName,
    fieldKey: "billing_company",
  });

  const billingStartDate = useFormInstanceField({
    storeName,
    fieldKey: "start_date",
  });

  const [managementContract] = useManagementContract(contractId);
  const [editableDoc] = useEditabledoc(managementContract?.editabledoc?.id);
  const [invoicingCompany] = useCompany(managementContract?.company?.id);

  // get company for autofill of invoicing company
  const [companyDefaultInvoicingSetting] = useInvoicingSetting(
    invoicingCompany?.invoicing_config?.id
  );

  React.useEffect(() => {
    if (!userPermissionsLoaded) return;

    if (!hasBillectaFullPermission) {
      setStep(STEPS.COSTS);
    }
  }, [userPermissionsLoaded]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(false));
    };
  }, []);

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    if (managementContract?.pre_existing) {
      replace(detailUrl({ id: managementContract?.id }));
    } else {
      replace(
        handleContractEditableDocUrl({
          contractType: EDITABLE_DOC_CONTRACT_TYPES.CUSTOMER_CONTRACT,
          id: managementContract.id,
        })
      );
    }
  };

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPostForm(success));
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          customer_contract: { id: contractId },
        },
      })
    );
  }, []);

  // auto set billing company
  React.useEffect(() => {
    if (
      !hasBillectaFullPermission ||
      selectedBillingCompany ||
      !invoicingCompany
    )
      return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          billing_company: invoicingCompany,
          _original_billing_company: invoicingCompany, // for table select component
        },
      })
    );
  }, [invoicingCompany, hasBillectaFullPermission]);

  React.useEffect(() => {
    if (!editableDoc) return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _editabledoctemp: editableDoc,
        },
      })
    );
  }, [editableDoc]);

  React.useEffect(() => {
    if (
      !hasBillectaFullPermission ||
      selectedSetting ||
      !companyDefaultInvoicingSetting
    )
      return;

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          setting: companyDefaultInvoicingSetting,
          _original_setting: companyDefaultInvoicingSetting, // for table select component
        },
      })
    );
  }, [companyDefaultInvoicingSetting, hasBillectaFullPermission]);

  // auto set billing start date from contract start date
  React.useEffect(() => {
    if (
      !hasBillectaFullPermission ||
      !managementContract ||
      billingStartDate ||
      !companyDefaultInvoicingSetting
    )
      return;

    if (!companyDefaultInvoicingSetting?.interval_setting) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            start_date: moment(managementContract.start_date)
              .subtract({ months: 1 })
              .format("YYYY-MM-DD"),
          },
        })
      );

      return;
    } else {
      const invoicePeriod =
        companyDefaultInvoicingSetting.interval_setting.invoice_period;
      const invoiceMode = companyDefaultInvoicingSetting.interval_setting.mode;
      const interval = companyDefaultInvoicingSetting.interval_setting.interval;

      const modeOffset =
        invoiceMode === 0
          ? { months: interval }
          : invoiceMode === 1
          ? { months: 3 * interval }
          : invoiceMode === 2
          ? { years: interval }
          : { months: 0 };

      // prev period, set start date one period before
      if (invoicePeriod === -1) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(managementContract.start_date)
                .add(modeOffset)
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      } else if (invoicePeriod === 0) {
        // same period, set start date to contract start date
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(managementContract.start_date)
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      } else if (invoicePeriod === 1) {
        // next period, set start date to one  period after
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(managementContract.start_date)
                .subtract(modeOffset)
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      } else {
        // set to one month before, most common scenario
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              start_date: moment(managementContract.start_date)
                .subtract({ months: 1 })
                .startOf("month")
                .format("YYYY-MM-DD"),
            },
          })
        );
      }
    }
  }, [
    managementContract,
    hasBillectaFullPermission,
    companyDefaultInvoicingSetting,
  ]);

  React.useEffect(() => {
    if (managementContract?.lease_invoicing) {
      setAlreadyHasInvoicing(true);
    }
  }, [managementContract]);

  React.useEffect(() => {
    return () => {
      destroyPostForm(false);
    };
  }, []);

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data }),
      })
    );
  };

  const onSelectedSetting = () => {
    setStep(STEPS.COSTS);
  };

  // go to create new setting with selected one as base
  const handleCopyAndCreateNew = () => {
    const cleanedExistingSetting = cloneDeep(selectedSetting);
    delete cleanedExistingSetting.id;
    delete cleanedExistingSetting.title;

    if (cleanedExistingSetting.interval_setting) {
      delete cleanedExistingSetting.interval_setting.id;
    }

    if (cleanedExistingSetting.debt_setting) {
      cleanedExistingSetting._original_debt_setting =
        cleanedExistingSetting.debt_setting; // for table select
    }

    dispatch(
      setActiveFormInstance({
        storeName: invoicingSettingConstants.STORE_NAME,
        data: cleanedExistingSetting,
      })
    );

    setStep(STEPS.INVOICING_BASE_CREATE);
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Aviseringsinställning kopierades",
        description:
          "Uppdatera inställningarna och spara för att skapa en ny inställning",
      })
    );
  };

  // create new setting and then set to active and proceed to next step
  const onCreateNewSetting = () => {
    setLoading(true);
    dispatch(
      createInvoicingSetting({
        preventDefaultToast: true,
        successCallback: (_, returnData) => {
          setLoading(false);

          // set new setting in store
          dispatch(
            updateActiveFormInstance({
              storeName,
              data: {
                setting: returnData,
              },
            })
          );

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Aviseringsinställning skapades",
              description: "Inställningen valdes för avtalet",
            })
          );

          // go to costs step
          setStep(STEPS.COSTS);
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description:
                "Inställningen kunde inte skapas. Kontrollera datan och försök igen.",
            })
          );
        },
      })
    );
  };

  const skipInvoicingSetting = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          setting: null,
          billing_company: null,
        },
      })
    );
    setSkipInvoicing(true);
    setStep(STEPS.COSTS);
  };

  const goBackToInvoicingSetup = () => {
    setSkipInvoicing(false);
    setStep(STEPS.INVOICING_BASE);

    if (invoicingCompany) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            billing_company: invoicingCompany,
            _original_billing_company: invoicingCompany, // for table select component
          },
        })
      );

      if (companyDefaultInvoicingSetting) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              setting: companyDefaultInvoicingSetting,
              _original_setting: companyDefaultInvoicingSetting, // for table select component
            },
          })
        );
      }
    }
  };

  const renderSteps = () => {
    const steps = [
      "1. Avtal",
      hasBillectaFullPermission ? "2. Kostnader & Avisering" : "2. Kostnader",
    ];

    if (!managementContract.pre_existing) {
      steps.push("3. Dokument för signering");
    }

    return <StepDisplay steps={steps} activeIndex={1} />;
  };

  const topButtonConfig = {
    title: `Hoppa över kostnader ${
      hasBillectaFullPermission ? " & avisering" : ""
    } och gå direkt till avtal`,
    iconType: "arrow",
    iconPlacement: "right",
    clicked: () => {
      replace(detailUrl({ id: managementContract.id }));
    },
  };

  return (
    <>
      <StandardModal
        title={`Konstnader ${
          hasBillectaFullPermission ? "& Avisering" : ""
        } redan konfigurerat`}
        isOpen={alreadyHasInvoicing}
        canClose={false}
        actionBarAcceptTitle="Gå till detaljsida"
        withActionBar
        saveFunction={() => {
          replace(detailUrl({ id: managementContract?.id }));
        }}
        canCancel={false}
      >
        <OverviewTitleWrapper>
          <OverviewTitle>
            Konstnader {hasBillectaFullPermission ? "& Avisering" : ""} redan
            konfigurerat för detta avtal
          </OverviewTitle>
        </OverviewTitleWrapper>

        <BodyText>
          Denna sida är enbart till för skapande av kostnader
          {hasBillectaFullPermission ? " och aviseringsinställningar" : ""}.
          Detta avtal har redan en inställning, gå till avtalets detaljsida för
          att redigera kostnader
          {hasBillectaFullPermission ? " och aviseringsinställningar" : ""}.
        </BodyText>
      </StandardModal>

      {(loading || !formLoaded) && <FullPageSpinner />}

      {/* First, if we have billecta permission, we select an invoicing settings or... */}
      {hasBillectaFullPermission &&
        managementContract &&
        step === STEPS.INVOICING_BASE && (
          <>
            {renderSteps()}
            <FlowFormBase
              {...{
                storeName,
                chapterDefs: invoicingBaseChapterDefs(),
                chapters: invoicingBaseChapters,
                descriptions: invoicingBaseDescriptions,
                method,
                onSubmit: onSelectedSetting,
                onGoToCreateNew: () => setStep(STEPS.INVOICING_BASE_CREATE),
                onCopyAndCreateNew: handleCopyAndCreateNew,
                contract: managementContract,
                topButtonConfig,
                skipInvoicingSetting,
                title: "Välj aviseringsinställning",
              }}
            />
          </>
        )}

      {/* Create a new one to be used. This renders and invoicing settings form */}
      {hasBillectaFullPermission &&
        managementContract &&
        step === STEPS.INVOICING_BASE_CREATE && (
          <>
            {renderSteps()}

            <CreateInvoicingSettingBase
              {...{
                storeName: invoicingSettingConstants.STORE_NAME,
                method,
                onSubmit: onCreateNewSetting,
                onGoBackToSelect: () => {
                  setStep(STEPS.INVOICING_BASE);
                  dispatch(
                    updateActiveFormInstance({
                      storeName,
                      data: {
                        setting: null, // TODO - set company default here
                      },
                    })
                  );
                },
                title: "Skapa aviseringsinställning",
              }}
            />
          </>
        )}

      {/* Then we can set up the costs and contract specific settings  */}
      {managementContract && step === STEPS.COSTS && (
        <>
          {renderSteps()}

          <FlowFormBase
            {...{
              storeName,
              chapterDefs: chapterDefs({
                hasBillectaFullPermission,
                skipInvoicing,
              }),
              chapters,
              descriptions,
              method,
              onSubmit,
              skipInvoicing,
              goBackToInvoicingSetup,
              topButtonConfig,
              contract: managementContract,
              displayDocumentFieldKey: "_editabledoctemp.doc.get",
              title: `Kostnader ${
                hasBillectaFullPermission ? "& avisering " : ""
              }för ${managementContract.id_number}`,
            }}
          />
        </>
      )}
    </>
  );
};

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  return copy;
};
