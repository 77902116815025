import * as React from "react";
import {
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { TextInput, TimePicker } from "../Base/Fields";
import * as SC from "./styles";

import { constants, useUserForm } from "../../../store/users/";
import { useDispatch } from "react-redux";

import { DoubleFieldWrapper } from "../Base/Chapters/styles";
import { InnerBox } from "../../sharedStyles";
import NonConnectedCheckbox from "../Base/Old/NonConnected/NonConnectedCheckbox";

export default () => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const method = "POST"; // temp
  useUserForm(method);

  const [userTypeInfo, setUserTypeInfo] = React.useState(null);
  const [isCompany, setIsCompany] = React.useState(true);

  const userType = useFormInstanceField({ storeName, fieldKey: "user_type" });
  const handleUserTypePicked = (type) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          user_type: type,
        },
      })
    );
  };

  const handleCompanyAccount = (checked) => {
    if (!checked) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            corporate_name: null,
          },
        })
      );
    }
    setIsCompany(checked);
  };

  React.useEffect(() => {
    return () => {
      dispatch(setActiveFormInstance({ storeName, data: {} }));
    };
  }, []);

  React.useEffect(() => {
    if (userType != null) {
      setUserTypeInfo(USERTYPE_INFO[userType]);
    }
  }, [userType]);

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitle>Skapa användare</OverviewTitle>
      </OverviewTitleWrapper>

      <InnerBox style={{ paddingTop: 24 }}>
        <OverviewTitleWrapper>
          <OverviewTitle small>Välj användartyp</OverviewTitle>
        </OverviewTitleWrapper>

        <SC.UserTypeWrapper>
          <SC.UserTypeChoice
            selected={userType === USER_TYPES.ADMIN}
            onClick={() => handleUserTypePicked(USER_TYPES.ADMIN)}
          >
            <SC.UserTypeIcon admin active={userType === USER_TYPES.ADMIN} />
            Admin
          </SC.UserTypeChoice>
          {/* <SC.UserTypeChoice
            selected={userType === USER_TYPES.STANDARD}
            onClick={() => handleUserTypePicked(USER_TYPES.STANDARD)}
          >
            <SC.UserTypeIcon
              standard
              active={userType === USER_TYPES.STANDARD}
            />
            Standard
          </SC.UserTypeChoice> */}
        </SC.UserTypeWrapper>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "30px",
            maxWidth: 800,
          }}
        >
          {(userType === USER_TYPES.ADMIN || userType) && (
            <>
              <OverviewTitle>{userTypeInfo?.header}</OverviewTitle>
              <p style={{ marginTop: "8px" }}>{userTypeInfo?.text}</p>
            </>
          )}
        </div>
      </InnerBox>

      {userType != null && (
        <InnerBox style={{ marginTop: 32, paddingTop: 24 }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Ange information om användaren</OverviewTitle>
          </OverviewTitleWrapper>
          <div>
            <NonConnectedCheckbox
              id={1}
              value={isCompany}
              onChange={handleCompanyAccount}
              title="Bocka i om det är ett företagskonto"
            />
          </div>
          {isCompany && (
            <DoubleFieldWrapper>
              <TextInput
                extraStyles={{ minWidth: "290px", marginRight: "20px" }}
                storeName={storeName}
                method={method}
                fieldKey={"corporate_name"}
                title="Företagsnamn"
              />
              <TextInput
                extraStyles={{ minWidth: "290px" }}
                storeName={storeName}
                fieldKey={"legal_id"}
                method={method}
                title="Org nummmer"
              />
            </DoubleFieldWrapper>
          )}
          <DoubleFieldWrapper>
            <TextInput
              extraStyles={{ minWidth: "290px", marginRight: "20px" }}
              storeName={storeName}
              fieldKey={"first_name"}
              method={method}
              title="Förnamn"
            />
            <TextInput
              extraStyles={{ minWidth: "290px" }}
              storeName={storeName}
              fieldKey={"last_name"}
              method={method}
              title="Efternamn"
            />
          </DoubleFieldWrapper>

          <DoubleFieldWrapper>
            <TextInput
              extraStyles={{ minWidth: "290px", marginRight: "20px" }}
              storeName={storeName}
              fieldKey={"email"}
              method={method}
              title="Epost"
            />
            <TextInput
              extraStyles={{ minWidth: "290px" }}
              storeName={storeName}
              fieldKey={"phone"}
              method={method}
              title="Telefon"
            />
          </DoubleFieldWrapper>

          {!isCompany && (
            <TextInput
              storeName={storeName}
              fieldKey={"legal_id"}
              method={method}
              title="Personnummer"
            />
          )}
          <TimePicker
            storeName={storeName}
            method={method}
            fieldKey="active_to"
            title="Aktiv till och med"
            description="Sätt ett datum när kontot ska inaktiveras vid försök till inloggning. T.ex om det ska vara ett konto till en sommarjobbare etc."
          />
        </InnerBox>
      )}
    </>
  );
};

const USERTYPE_INFO = [
  {
    type: 0,
    header: "Admin",
    text: "En administratör har tillgång till alla delar av systemet som den blir tilldelad på. Du kan dock begränsa vilka rättigheter en admin ska ha genom att tilldela admin-användaren en behörighetsgrupp.",
  },
  {
    type: 1,
    header: "Standard",
    text: "En standardanvändare har enbart rättighet till de delar av systemet som ligger under respektive kund som användaren blir tilldelad på, samt de rättigheter som standardanvändaren får av behörighetsgruppen som tilldelas på användaren.",
  },
];

const USER_TYPES = {
  ADMIN: 0,
  STANDARD: 1,
};
