import * as React from "react";
import { useSelector } from "react-redux";
import { constants } from "../../../store/billectaReports";
import PageSlider from "../../../views/Layouts/PageSlider/PageSlider";
import { OverviewTitle } from "../../Details/OverviewInfo/styles";
import NonConnectedDatePicker from "../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import { InnerBox } from "../../sharedStyles";
import { FieldBox } from "../Search/styles";
import PaymentsReportInvoiceTable from "./PaymentsReportInvoiceTable";
import PaymentsReportUnhandledPaymentTable from "./PaymentsReportUnhandledPaymentTable";

export default ({ fromDate, toDate, setFromDate, setToDate, goToInvoice }) => {
  const [selectedTab, setSelectedTab] = React.useState("INVOICE_PAYMENTS");

  const TABS = {
    INVOICE_PAYMENTS: "Fakturabetalningar",
    OVER_PAYMENTS: "Överbetalningar",
    UNHANDLED_PAYMENTS: "Omatchade betalningar",
  };
  const paymentsReport = useSelector(
    (state) => state[constants.STORE_NAME].paymentsReport
  );

  if (!paymentsReport?.ValuesOnActions) delete TABS.INVOICE_PAYMENTS;
  if (!paymentsReport?.UnhandledPayments) delete TABS.UNHANDLED_PAYMENTS;
  if (!paymentsReport?.OverPayments) delete TABS.OVER_PAYMENTS;

  if (paymentsReport?.ValuesOnActions?.length) {
    TABS.INVOICE_PAYMENTS = `${TABS.INVOICE_PAYMENTS} (${paymentsReport.ValuesOnActions.length})`;
  }
  if (paymentsReport?.UnhandledPayments?.length) {
    TABS.UNHANDLED_PAYMENTS = `${TABS.UNHANDLED_PAYMENTS} (${paymentsReport?.UnhandledPayments.length})`;
  }
  if (paymentsReport?.OverPayments?.length) {
    TABS.OVER_PAYMENTS = `${TABS.OVER_PAYMENTS} (${paymentsReport?.OverPayments.length})`;
  }

  return (
    <>
      <FieldBox>
        <OverviewTitle small style={{ marginBottom: "8px" }}>
          Datumintervall för rapport
        </OverviewTitle>
        <NonConnectedDatePicker
          value={fromDate}
          clearable={false}
          id="payment-report-from-date"
          label="Från"
          onChange={(val) => setFromDate(val)}
          noMargin
        />
        <NonConnectedDatePicker
          value={toDate}
          clearable={false}
          id="payment-report-to-date"
          label="Till"
          onChange={(val) => setToDate(val)}
          noMargin
        />
      </FieldBox>

      {Object.keys(TABS)?.length ? (
        <InnerBox>
          <div style={{ alignSelf: "flex-end" }}>
            <PageSlider
              {...{ TABS }}
              selectedTab={selectedTab}
              onTabSelected={setSelectedTab}
            />
          </div>
          {paymentsReport?.ValuesOnActions &&
            selectedTab === "INVOICE_PAYMENTS" && (
              <PaymentsReportInvoiceTable
                reportRows={paymentsReport.ValuesOnActions}
                fromDate={fromDate}
                toDate={toDate}
                handleRowClicked={goToInvoice}
              />
            )}

          {paymentsReport?.UnhandledPayments &&
            selectedTab === "UNHANDLED_PAYMENTS" && (
              <PaymentsReportUnhandledPaymentTable
                reportRows={paymentsReport.UnhandledPayments}
                fromDate={fromDate}
                toDate={toDate}
              />
            )}
        </InnerBox>
      ) : null}
    </>
  );
};
