export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_SINGLE_INVOICE_MAPPING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_SINGLE_INVOICE_MAPPING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_SINGLE_INVOICE_MAPPING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_SINGLE_INVOICE_MAPPING",
  DESTROY_FORM: "DESTROY_FORM_SINGLE_INVOICE_MAPPING",
  SET_FORM_ERROR: "SET_FORM_ERROR_SINGLE_INVOICE_MAPPING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_SINGLE_INVOICE_MAPPING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_SINGLE_INVOICE_MAPPING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_SINGLE_INVOICE_MAPPING",
  RESET_STATE: "RESET_STATE_SINGLE_INVOICE_MAPPING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_SINGLE_INVOICE_MAPPING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_SINGLE_INVOICE_MAPPING",
  CLEAR_FETCHED: "CLEAR_FETCHED_SINGLE_INVOICE_MAPPING",

  LIST_URL: "/accounting/basic/billectasingleinvoicemapping/list/",
  POST_URL: "/accounting/basic/billectasingleinvoicemapping/",
  PATCH_URL: "/accounting/basic/billectasingleinvoicemapping/",
  GET_URL: "/accounting/basic/billectasingleinvoicemapping/",

  STORE_NAME: "invoicingSingleMapping",
};
