import * as React from "react";

import {
  DatePicker,
  Checkbox,
  PositiveNumber,
  TextInput,
  RadioGroup,
  RentField,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/managementContracts";
import invoicingProductListDefs from "../../../../Lists/InvoicingProducts/listDefs";

import { TextButton } from "../../../Base/Buttons";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import ProductNestedChildrenMethod from "../../../InvoicingProduct/NestedChildren";

import {
  constants as invoicingProductConstants,
  performFilter as invoicingProductPerformFilter,
  constructStrRep as invoicingProductConstructStrRep,
  useInvoicingProductPagination,
} from "../../../../../store/invoicingProducts";

import { INDEX_TYPES } from "../../utils";
import RelationalTableField from "../../../Base/FieldComponents/RelationalTableField";
import { InnerBox } from "../../../../sharedStyles";

export default ({ method }) => {
  const dispatch = useDispatch();
  const [indexType, setIndexType] = React.useState(null);
  const storeName = constants.STORE_NAME;

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = React.useState(false);
  const [invoicingSettingsOpen, setInvoicingSettingsOpen] =
    React.useState(false);

  const useCostProductForIndexation = useFormInstanceField({
    storeName,
    fieldKey: "indexation.use_cost_product",
  });
  const productForIndexation = useFormInstanceField({
    storeName,
    fieldKey: "indexation.product",
  });

  React.useEffect(() => {
    if (useCostProductForIndexation && productForIndexation) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ["indexation.product"]: null,
          },
        })
      );
    }
  }, [useCostProductForIndexation]);

  React.useEffect(() => {
    setAdvancedSettingsOpen(false);
    setInvoicingSettingsOpen(false);
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          indexation: null,
        },
      })
    );
  }, [indexType]);

  return (
    <>
      {!indexType ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "40px",
          }}
        >
          <TextButton
            title="Automatisk indexuppräkning mot KPI"
            iconType={"arrow"}
            iconPlacement="right"
            extraStyle={{ marginBottom: "12px" }}
            clicked={() => setIndexType(INDEX_TYPES.DYNAMIC)}
          />
          <TextButton
            title="Fast indexuppräkning"
            iconType={"arrow"}
            iconPlacement="right"
            clicked={() => setIndexType(INDEX_TYPES.SET)}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "40px",
          }}
        >
          <TextButton
            title="Byt typ av indexuppräkning"
            iconType={"close"}
            iconPlacement="right"
            clicked={() => setIndexType(null)}
          />
        </div>
      )}

      {indexType === INDEX_TYPES.DYNAMIC && (
        <>
          <OverviewTitleWrapper>
            <OverviewTitle small>Dynamisk indexuppräkning</OverviewTitle>
          </OverviewTitleWrapper>

          <PositiveNumber
            storeName={storeName}
            fieldKey="indexation.index_amount"
            title="Andel av hyran som räknas upp (%)"
            method={method}
          />

          <div
            style={{
              margin: "12px 0 ",
              fontWeight: 600,
              fontSize: ".8rem",
              backgroundColor: "#e4f1ff",
              padding: 6,
              borderRadius: 8,
            }}
          >
            För förvaltningsavtal som börjar löpa någon gång under tiden 1/1 -
            30/6 anses bashyran anpassad till indextalet för oktober månad året
            innan. För förvaltningsavtal som börjar löpa någon gång under tiden
            1/7 - 31/12 anses bashyran istället anpassad till indextalet för
            oktober månad under samma år.
          </div>

          {!advancedSettingsOpen && (
            <TextButton
              title="Avancerade inställningar"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setAdvancedSettingsOpen(true)}
            />
          )}

          {!invoicingSettingsOpen && (
            <TextButton
              extraStyle={{ marginTop: 12 }}
              title="Aviseringsinställningar"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setInvoicingSettingsOpen(true)}
            />
          )}

          {advancedSettingsOpen && (
            <InnerBox style={{ margin: "24px 0 " }}>
              <OverviewTitleWrapper>
                <OverviewTitle small>Avancerade inställningar</OverviewTitle>
              </OverviewTitleWrapper>
              <Checkbox
                storeName={storeName}
                fieldKey="indexation.index_min_none"
                title="Använd minimal indexkvot om index inte kan räknas ut"
                method={method}
                forceHorizontalToolTipPlacement="left"
              />

              <PositiveNumber
                storeName={storeName}
                title="Minimal indexkvot"
                fieldKey="indexation.index_min_val"
                method={method}
              />
              <PositiveNumber
                storeName={storeName}
                title="Maximal indexkvot"
                fieldKey="indexation.index_max_val"
                method={method}
              />
              <RentField
                title="Basbelopp som index ska räknas upp på (OBS: Lämna tom om bashyran ska användas som basbelopp)"
                description="Om inget värde fylls i så används bashyran"
                storeName={storeName}
                fieldKey="indexation.index_rent"
                method={method}
                hideArea
              />

              <DatePicker
                storeName={storeName}
                title="Startdatum för uppräkning av index (OBS: Lämna tom om Pigello automatiskt ska räkna ut vilket datum som gäller)"
                description="Om fältet lämnas tomt används denna uträkning: För förvaltningsavtal som börjar löpa någon gång under tiden 1/1 - 30/6
            anses bashyran anpassad till indextalet för oktober månad året
            innan. För förvaltningsavtal som börjar löpa någon gång under tiden 1/7
            - 31/12 anses bashyran istället anpassad till indextalet för oktober
            månad under samma år."
                fieldKey="indexation.index_base_date"
                method={method}
              />
            </InnerBox>
          )}
        </>
      )}

      {indexType === INDEX_TYPES.SET && (
        <>
          <OverviewTitleWrapper>
            <OverviewTitle small>Fast indexuppräkning</OverviewTitle>
          </OverviewTitleWrapper>

          <PositiveNumber
            storeName={storeName}
            fieldKey="indexation.index_amount"
            title="Andel av hyran som räknas upp (%)"
            method={method}
          />

          <PositiveNumber
            storeName={storeName}
            fieldKey="indexation.index_quota"
            title="Fast indexuppräkning (%)"
            method={method}
          />

          {!advancedSettingsOpen && (
            <TextButton
              title="Avancerade inställningar"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setAdvancedSettingsOpen(true)}
            />
          )}

          {!invoicingSettingsOpen && (
            <TextButton
              extraStyle={{ marginTop: 12 }}
              title="Aviseringsinställningar"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setInvoicingSettingsOpen(true)}
            />
          )}

          {advancedSettingsOpen && (
            <InnerBox style={{ margin: "24px 0 " }}>
              <OverviewTitleWrapper>
                <OverviewTitle small>Avancerade inställningar</OverviewTitle>
              </OverviewTitleWrapper>
              <RentField
                title="Basbelopp som index ska räknas upp på (OBS: Lämna tom om bashyran ska användas som basbelopp)"
                description="Om inget värde fylls i så används bashyran"
                storeName={storeName}
                fieldKey="indexation.index_rent"
                method={method}
                hideArea
              />

              <DatePicker
                title="Startdatum för uppräkning av index (OBS: Lämna tom om Pigello automatiskt ska räkna ut vilket datum som gäller)"
                description="Om fältet lämnas tomt används denna uträkning: För förvaltningsavtal som börjar löpa någon gång under tiden 1/1 - 30/6
            anses bashyran anpassad till indextalet för oktober månad året
            innan. För förvaltningsavtal som börjar löpa någon gång under tiden 1/7
            - 31/12 anses bashyran istället anpassad till indextalet för oktober
            månad under samma år."
                storeName={storeName}
                fieldKey="indexation.index_base_date"
                method={method}
              />
            </InnerBox>
          )}
        </>
      )}

      {invoicingSettingsOpen && !!indexType && (
        <InnerBox style={{ marginBottom: 24, marginTop: 12 }}>
          <OverviewTitleWrapper>
            <OverviewTitle small>Avisering av indexuppräkning</OverviewTitle>
          </OverviewTitleWrapper>
          <TextInput
            title="Indexuppräkningens titel"
            placeholder="Indexuppräkning"
            description={`Titel på indexuppräkningen, t.ex. på avier. "Indexuppräkning" som standard.`}
            {...{
              method,
              storeName,
              fieldKey: "indexation.indexation_value_title",
            }}
          />

          <RadioGroup
            title="Inkludera kostnadens namn i titeln på på avier"
            defaultValue={false}
            options={[
              {
                label: 'Ja (t.ex. "Indexuppräkning - Bashyra")',
                value: true,
              },
              {
                label: 'Nej (t.ex. "Indexuppräkning")',
                value: false,
              },
            ]}
            {...{
              method,
              storeName,
              fieldKey: "indexation.include_cost_title",
            }}
          />

          <RadioGroup
            title="Använd underliggande debiteringsradens produkt"
            defaultValue={true}
            options={[
              {
                label:
                  "Ja, använd samma produkt som underliggande debiteringsrad",
                value: true,
              },
              {
                label: "Nej, specificera produkt för indexdebitering",
                value: false,
              },
            ]}
            {...{
              method,
              storeName,
              fieldKey: "indexation.use_cost_product",
            }}
          />

          {!useCostProductForIndexation && (
            <RelationalTableField
              storeName={storeName}
              tableStoreName={invoicingProductConstants.STORE_NAME}
              paginationMethod={useInvoicingProductPagination}
              performFilter={invoicingProductPerformFilter}
              listDefs={invoicingProductListDefs}
              title="Välj produkt för indexuppräkning..."
              fieldTitle="Produkt för indexuppräkning"
              method={method}
              fieldKey={`indexation.product`}
              instructionsKey={"indexation.product"}
              constructStrRep={invoicingProductConstructStrRep}
              modalTitle="Hantera produkt"
              children={ProductNestedChildrenMethod({
                storeName,
                method,
                parentPath: `indexation.product`,
                parentInstructionsPath: `indexation.product`,
              })}
            />
          )}
        </InnerBox>
      )}
    </>
  );
};
