import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "../styles";

// store, design
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { uid } from "uid";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    type,
    instructionsKey,
    fontSize,
    borderColor,
    displayOnlyMode,
  }) => {
    const dispatch = useDispatch();

    const actualFieldKey = `${fieldKey}.value`;
    const placeholderFieldKey = `${fieldKey}._placeholder`;
    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || actualFieldKey,
    });

    const value = useFormInstanceField({ storeName, fieldKey: actualFieldKey });
    const placeholder = useFormInstanceField({
      storeName,
      fieldKey: placeholderFieldKey,
    });
    const error = useFormError({ storeName, fieldKey: actualFieldKey });

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [actualFieldKey]: data },
        })
      );
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.PDFInputField
        {...{ displayOnlyMode }}
        value={value}
        name={uid(16)}
        autoComplete={uid(16)}
        onChange={({ target: { value } }) => onChange(value)}
        maxLength={instructions.max_length}
        id={instructions._internalId}
        required={instructions._required}
        type={type || "text"}
        style={fontSize ? { fontSize } : null}
        borderColor={borderColor}
        placeholder={placeholder}
      />
    );
  }
);
