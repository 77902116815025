import * as React from "react";
import { useParams } from "react-router";
import {
  useManagementContract,
  constants,
  update,
  useManagementContractForm,
} from "../../../store/managementContracts";
import { DetailInnerWrapper } from "../../../components/sharedStyles";
import DocumentViewer from "../../../components/Details/OverviewInfo/DocumentViewer/DocumentViewer";

export default function ManagementContractDocumentTabs() {
  const { managementContractId } = useParams();
  const [managementContract] = useManagementContract(managementContractId);

  useManagementContractForm("PATCH", managementContractId);
  return (
    <DetailInnerWrapper>
      <DocumentViewer
        contract={managementContract}
        contractConstants={constants}
        contractUpdate={update}
      />
    </DetailInnerWrapper>
  );
}
