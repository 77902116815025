import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import RegisterInvoiceForm from "../../../components/Billecta/Invoices/HandleInvoice/RegisterInvoiceForm";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewContent,
  OverviewItem,
  OverviewItemTitle,
  OverviewItemValue,
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, InfoBox, LinkedObject } from "../../../components/Displays";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import FullPageSpinner from "../../../components/Loaders/FullPageSpinner";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { buildQueryString } from "../../../store/base";
import {
  registerInvoicePayment,
  activateAutogiroForInvoice,
  inactivateAutogiroForInvoice,
  cancelRemindersForInvoice,
  invoiceDetailUrl,
} from "../../../store/billectaInvoicing";
import {
  useInvoice,
  useReminderInvoice,
} from "../../../store/billectaInvoicing/hooks/retrieve";
import { downloadBillectaFile } from "../../../store/billectaInvoicing/store/actions";
import { DELIVERY_METHOD_MAPPER_EXCL_PRICES } from "../../../store/billectaInvoicing/utils";
import { useFilteredDebtorInvoicings } from "../../../store/invoicingDebtor";
import {
  detailUrl as customerDetailUrl,
  useCustomer,
} from "../../../store/customers";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import useMatchingContract from "./useMatchingContract";
import { detailUrl as invoicingCustomerDetailUrl } from "../../../store/invoicingCustomer";
import { useFilteredSingleInvoiceMappings } from "../../../store/invoicingSingleMapping";
import { ItemTitle } from "../../../components/Details/OverviewInfo/DetailInfo/styles";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";

export default function InvoiceOverview() {
  const dispatch = useDispatch();
  const { invoiceId, creditorId } = useParams();
  const [invoice, invoiceLoading] = useInvoice({ creditorId, invoiceId });

  // get debit invoice which credit invoice credits
  const creditingInvoiceId = invoice?.CreditingInvoice?.SourcePublicId;
  const creditingInvoiceNumber = invoice?.CreditingInvoice?.SourceInvoiceId;

  const [loading, setLoading] = React.useState(false);
  const [autogiroLoading, setAutogiroLoading] = React.useState(false);
  const [confirmCancelReminderOpen, setConfirmCancelReminderOpen] =
    React.useState(false);

  const debtorQuery = buildQueryString({
    billecta_object_ids: invoice?.Debtor?.DebtorPublicId || [],
  });

  const [debtors] = useFilteredDebtorInvoicings(debtorQuery);
  const debtor = debtors?.[0];

  const [customer] = useCustomer(debtor?.management_customer?.id);

  const hasAttested = !!invoice?.State?.AttestedDate;
  const canAttest = moment(invoice?.InvoiceDate).isSameOrBefore(moment());
  const [periodStart, periodEnd] = calculatePeriodForInvoice(invoice?.Records);
  const isCreditInvoice = invoice?.ActionType === "CreditInvoiceAction";
  const canRegisterPayment = invoice?.CanRegisterPayment;
  const nextEvent = invoice?.State?.NextEvent;
  const nextEventDate = invoice?.State?.NextEventDate;
  const autoGiroActivated = !!invoice?.Autogiro;
  const canActivateAutogiro = !!invoice?.CanCreateAutogiroWithdrawal;
  const canInactivateAutogiro = autoGiroActivated;

  const [reminderInvoice] = useReminderInvoice({
    reminderInvoiceId: invoice?.ReminderInvoiceActionPublicId,
    invoiceId,
  });

  // find matching contract and type
  const [matchingContract, matchLoading] = useMatchingContract({ invoice });
  const invoiceMappingQ = buildQueryString({
    billecta_object_ids: invoice?.ActionPublicId || [],
  });
  const [singleInvoiceMappings] =
    useFilteredSingleInvoiceMappings(invoiceMappingQ);
  const singleInvoiceMapping = singleInvoiceMappings?.[0];

  const handleRegisterPayment = ({
    value,
    currency,
    paidDate,
    overpaymentHandle,
    reference,
    paymentMeansCode,
  }) => {
    setLoading(true);
    dispatch(
      registerInvoicePayment({
        invoiceActionId: invoiceId,
        creditorId,
        paymentData: {
          value,
          currency,
          paidDate,
          overpaymentHandle,
          reference,
          paymentMeansCode,
        },
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Betalningen lades till",
            })
          );
          setLoading(false);
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Betalningen kunde inte läggas till",
              description: message || "",
            })
          );
          setLoading(false);
        },
      })
    );
  };

  const downloadReminderInvoice = ({ filePublicId }) => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Hämtar påminnelsefaktura...",
      })
    );

    dispatch(downloadBillectaFile({ filePublicId }));
  };

  const cancelReminders = () => {
    dispatch(
      cancelRemindersForInvoice({
        invoiceActionId: invoice?.ActionPublicId,
        creditorId: invoice.CreditorPublicId,
        successCallback: () => {
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Påminnelser avbrutna",
            })
          );
        },
        errorCallback: (message) => {
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Kunde ej avbryta påminnelser",
              description:
                message ||
                "Försök igen, kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const reminderFee = reminderInvoice?.ReminderFee?.ValueForView || 0;
  const remainingReminderFee = reminderInvoice?.CurrentValue?.ValueForView || 0;

  const totalRemainingAmount =
    invoice?.CurrentAmount?.ValueForView + remainingReminderFee;

  return (
    <>
      <ConfirmationModal
        isOpen={confirmCancelReminderOpen}
        title="Vill du avbryta påminnelser för denna faktura?"
        closeFunction={() => setConfirmCancelReminderOpen(false)}
        acceptCallback={cancelReminders}
        acceptTitle="Fortsätt"
      />
      {loading && <FullPageSpinner />}
      <DetailInnerWrapper>
        {!canAttest && !hasAttested && (
          <InfoBox
            title="Fakturan kan inte attesteras än"
            text={`Attestering kan ske tidigast på fakturadatumet, ${moment(
              invoice?.InvoiceDate
            ).format("YYYY-MM-DD")}`}
          />
        )}
        {invoiceLoading && <FullPageSpinner />}
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ flex: 3, alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>
            </OverviewTitleWrapper>

            {nextEvent && (
              <InfoBox
                boxTheme="info"
                title={`Nästa händelse ${
                  nextEventDate
                    ? `(${moment(nextEventDate).format("YYYY-MM-DD")})`
                    : ""
                }`}
                text={nextEvent}
              />
            )}

            {invoice && (
              <DetailInfo
                infoObj={getInfoObj({
                  matchingContract,
                  singleInvoiceMapping,
                  invoice,
                  reminderInvoice,
                  customer,
                  periodStart,
                  periodEnd,
                  isCreditInvoice,
                  downloadReminderInvoice,
                  creditingInvoiceId,
                  creditingInvoiceNumber,
                  creditorId,
                  handleCancelReminders: () =>
                    setConfirmCancelReminderOpen(true),
                })}
              />
            )}

            {invoice && (
              <InnerBox style={{ marginRight: 36 }}>
                <OverviewTitleWrapper>
                  <OverviewTitle small>Meddelande på faktura</OverviewTitle>
                </OverviewTitleWrapper>
                {invoice.Message || "Saknar meddelande"}
              </InnerBox>
            )}
          </DetailPageBox>

          <div
            style={{
              flex: 1,
              minWidth: "300px",
              alignSelf: "flex-start",
              flexDirection: "column",
            }}
          >
            {hasAttested &&
              invoice?.State?.Stage !== "Completed" &&
              !invoice?.State?.ClosedDate &&
              (invoice?.ActionType === "InvoiceAction" ||
                invoice?.ActionType === "PaymentAdviceAction") && (
                <DetailPageBox>
                  {autogiroLoading && <OverlaySpinner />}
                  <OverviewTitleWrapper>
                    <OverviewTitleWithSubtitleWrapper>
                      <OverviewTitle small>Autogiro</OverviewTitle>
                      <OverviewSubtitle>
                        {!autoGiroActivated && !canActivateAutogiro ? (
                          <BodyText>
                            Autogiro kan ej aktiveras på denna faktura då
                            autogiro ej är aktiverat på kunden och/eller det
                            fakturerande bolaget.
                          </BodyText>
                        ) : (
                          <BodyText>
                            Autogirodragningen skickas till Bankgirocentralen
                            18:00 på fakturans förfallodatum. Om fakturan har
                            förfallit skickas den vid första möjliga tillfälle.
                          </BodyText>
                        )}
                      </OverviewSubtitle>
                    </OverviewTitleWithSubtitleWrapper>
                  </OverviewTitleWrapper>
                  <InnerBox>
                    <ItemTitle>
                      Autogiro är{" "}
                      <StatusLabel state={autoGiroActivated ? 0 : 6}>
                        {autoGiroActivated ? "Aktiverat" : "Inaktivt"}
                      </StatusLabel>
                    </ItemTitle>
                    {canInactivateAutogiro && (
                      <TextButton
                        extraStyle={{ marginTop: 6 }}
                        iconType="close"
                        iconPlacement="right"
                        title="Inaktivera för denna faktura"
                        clicked={() => {
                          setAutogiroLoading(true);
                          dispatch(
                            inactivateAutogiroForInvoice({
                              invoiceActionId: invoice?.ActionPublicId,
                              creditorId: invoice?.CreditorPublicId,
                              successCallback: () => {
                                setAutogiroLoading(false);
                                dispatch(
                                  addToast({
                                    type: TOAST_TYPES.SUCCESS,
                                    title: "Autogiro inaktiverades",
                                  })
                                );
                              },
                              errorCallback: (e) => {
                                setAutogiroLoading(false);
                                dispatch(
                                  addToast({
                                    type: TOAST_TYPES.ERROR,
                                    title: "Kunde ej inaktivera autogiro",
                                    description:
                                      e || "Kontrollera datan och försök igen",
                                  })
                                );
                              },
                            })
                          );
                        }}
                      />
                    )}
                    {canActivateAutogiro && !autoGiroActivated && (
                      <TextButton
                        extraStyle={{ marginTop: 6 }}
                        iconType="sync"
                        iconPlacement="right"
                        title="Aktivera för denna faktura"
                        clicked={() => {
                          setAutogiroLoading(true);
                          dispatch(
                            activateAutogiroForInvoice({
                              invoiceActionId: invoice?.ActionPublicId,
                              creditorId: invoice?.CreditorPublicId,
                              successCallback: () => {
                                setAutogiroLoading(false);
                                dispatch(
                                  addToast({
                                    type: TOAST_TYPES.SUCCESS,
                                    title: "Autogiro aktiverades",
                                  })
                                );
                              },
                              errorCallback: (e) => {
                                setAutogiroLoading(false);
                                dispatch(
                                  addToast({
                                    type: TOAST_TYPES.ERROR,
                                    title: "Kunde ej aktivera autogiro",
                                    description:
                                      e || "Kontrollera datan och försök igen",
                                  })
                                );
                              },
                            })
                          );
                        }}
                      />
                    )}
                  </InnerBox>
                </DetailPageBox>
              )}

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Betalinfo</OverviewTitle>
              </OverviewTitleWrapper>

              {invoice && (
                <OverviewContent>
                  <OverviewItem>
                    <OverviewItemTitle>Fakturerad summa</OverviewItemTitle>
                    <OverviewItemValue>
                      {invoice.InvoicedAmount.ValueForView.toLocaleString()} SEK
                    </OverviewItemValue>
                  </OverviewItem>

                  {reminderInvoice?.ReminderInvoices?.length > 0 && (
                    <OverviewItem>
                      <OverviewItemTitle>Påminnelseavgift</OverviewItemTitle>

                      <OverviewItemValue>
                        {toMoneyString(reminderFee, true)}
                      </OverviewItemValue>
                    </OverviewItem>
                  )}

                  <OverviewItem>
                    <OverviewItemTitle>Varav moms</OverviewItemTitle>
                    <OverviewItemValue>
                      {invoice.TotalVATAmount.ValueForView.toLocaleString()} SEK
                    </OverviewItemValue>
                  </OverviewItem>

                  <OverviewItem>
                    <OverviewItemTitle>Betalat</OverviewItemTitle>
                    <OverviewItemValue>
                      {invoice.PaidAmount.ValueForView.toLocaleString()} SEK
                    </OverviewItemValue>
                  </OverviewItem>

                  {invoice.CreditedAmount.ValueForView > 0 && (
                    <OverviewItem>
                      <OverviewItemTitle>Krediterat</OverviewItemTitle>
                      <OverviewItemValue>
                        {invoice.CreditedAmount.ValueForView.toLocaleString()}{" "}
                        SEK
                      </OverviewItemValue>
                    </OverviewItem>
                  )}

                  {invoice.InterestAmount.ValueForView > 0 && (
                    <OverviewItem>
                      <OverviewItemTitle>Räntebelopp</OverviewItemTitle>
                      <OverviewItemValue>
                        {invoice.InterestAmount.ValueForView.toLocaleString()}{" "}
                        SEK
                      </OverviewItemValue>
                    </OverviewItem>
                  )}

                  <OverviewItem>
                    <OverviewItemTitle>Kvarvarande</OverviewItemTitle>
                    <OverviewItemValue>
                      {toMoneyString(totalRemainingAmount, true)}
                    </OverviewItemValue>
                  </OverviewItem>
                </OverviewContent>
              )}

              {canRegisterPayment && invoice && (
                <RegisterInvoiceForm
                  isCreditInvoice={isCreditInvoice}
                  registerPayment={handleRegisterPayment}
                  defaultValue={invoice.CurrentAmount.ValueForView}
                  creditorId={creditorId}
                />
              )}
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInvoiceTypeStr({ matchingContract, singleInvoiceMapping }) {
  // from contract invoicing
  if (matchingContract) return "Från avtal";

  if (!singleInvoiceMapping) return "Manuell";

  return singleInvoiceMapping.category_display;
}

function getInfoObj({
  matchingContract,
  singleInvoiceMapping,
  invoice,
  reminderInvoice,
  customer,
  periodStart,
  periodEnd,
  isCreditInvoice,
  downloadReminderInvoice,
  creditingInvoiceId,
  creditingInvoiceNumber,
  handleCancelReminders,
  creditorId,
}) {
  const infoObj = {};

  if (reminderInvoice && reminderInvoice?.ReminderInvoices?.length) {
    reminderInvoice.ReminderInvoices.forEach((ri, idx) => {
      infoObj[`Påminnelse nummer ${idx + 1}`] = [
        {
          title: "Skickad",
          value: <DateCell date={ri?.InvoiceDate} />,
        },
        {
          title: "Betalas senast",
          value: <DateCell date={ri?.DueDate} />,
        },
        {
          title: "Påminnelseavgift",
          value: toMoneyString(
            reminderInvoice?.ReminderFee?.ValueForView,
            true
          ),
          hidden: idx > 0,
        },
        {
          title: "OCR",
          value: ri?.OCR,
        },
        {
          title: (
            <TextButton
              title="Hämta påminnelsefaktura"
              clicked={() =>
                downloadReminderInvoice({
                  filePublicId: ri.InvoiceFile?.FilePublicId,
                })
              }
              iconType="download"
              iconPlacement="right"
            />
          ),
          hidden: !ri.InvoiceFile?.FilePublicId,
        },
        {
          title: (
            <TextButton
              red
              title="Avbryt påminnelser (makulera avgift)"
              clicked={() => handleCancelReminders()}
              iconType="close"
              iconPlacement="right"
            />
          ),
        },
      ];
    });
  }

  infoObj["Fakturainformation"] = [
    {
      title: "Krediterar faktura",
      value: (
        <LinkedObject
          obj={{ str_representation: creditingInvoiceNumber }}
          urlMethod={() =>
            invoiceDetailUrl({ invoiceId: creditingInvoiceId, creditorId })
          }
        />
      ),
      hidden: !creditingInvoiceId,
    },
    {
      title: "Genererad av",
      value: matchingContract ? (
        <LinkedObject
          obj={{
            id: matchingContract?.customer_contract?.id,
            str_representation: matchingContract.str_representation,
          }}
          urlMethod={
            matchingContract?.customer_contract?.id
              ? invoicingCustomerDetailUrl
              : ""
          }
        />
      ) : (
        "Manuell"
      ),
    },
    {
      title: "Typ av faktura",
      value: getInvoiceTypeStr({
        matchingContract,
        singleInvoiceMapping,
      }),
    },
    {
      title: "Fakturerad part",
      value: <LinkedObject obj={customer} urlMethod={customerDetailUrl} />,
    },
    {
      title: "Kundens referens",
      value: invoice?.YourReference,
    },
    {
      title: "Bolagets referens",
      value: invoice?.OurReference,
    },
    {
      title: "OCR",
      value: invoice?.Invoices?.[0]?.OCR || "-",
    },
    {
      title: "Leveransmetod",
      value: DELIVERY_METHOD_MAPPER_EXCL_PRICES[invoice?.DeliveryMethod],
    },
    {
      title: "Dröjsmålsränta",
      value:
        invoice?.InterestType === "NoInterest"
          ? "0%"
          : `${invoice?.InterestPercentage}%`,
    },
    {
      title: "Använder kundsaldo",
      value: invoice?.State?.UseDebtorBalance ? "Ja" : "Nej",
      hidden: isCreditInvoice,
    },
  ];
  infoObj["Datum"] = [
    {
      title: "Period start",
      value: <DateCell date={periodStart} />,
    },
    {
      title: "Period slut",
      value: <DateCell date={periodEnd} />,
    },
    {
      title: "Fakturadatum",
      value: <DateCell date={invoice?.InvoiceDate} />,
    },
    {
      title: "Förfaller",
      value: <DateCell date={invoice?.DueDate} />,
      hidden: isCreditInvoice,
    },
    {
      title: "Skapades",
      value: <DateCell date={invoice?.Created} />,
    },
    {
      title: "Attesterad",
      value: invoice?.State?.AttestedDate ? (
        <DateCell date={invoice.State.AttestedDate} />
      ) : (
        "Ej attesterad"
      ),
    },
    {
      title: "Skickad",
      value: invoice?.State?.InvoiceSentDate ? (
        <DateCell date={invoice.State.InvoiceSentDate} />
      ) : (
        "Ej skickad"
      ),
    },
    {
      title: "Stängdes",
      value: <DateCell date={invoice?.State?.ClosedDate} />,
      hidden: !invoice?.State?.ClosedDate,
    },
    {
      title: "Bestriden",
      value: <DateCell date={invoice?.State?.DisputedDate} />,
      hidden: !invoice?.State?.DisputedDate,
    },
    {
      title: "Gick till inkasso",
      value: <DateCell date={invoice?.State?.SentToDebtCollectionDate} />,
      hidden: !invoice?.State?.SentToDebtCollectionDate,
    },
  ];

  return infoObj;
}

function calculatePeriodForInvoice(records) {
  if (!records) return [undefined, undefined];
  const periodEnds = records.map((r) => r.PeriodEnd).filter((p) => p);
  const periodStarts = records.map((r) => r.PeriodStart).filter((p) => p);

  const periodStart = periodStarts.reduce((acc, cur) => {
    const curPeriod = moment(cur);
    const accPeriod = acc ? moment(acc) : null;

    if (!accPeriod || curPeriod.isBefore(accPeriod))
      return curPeriod.format("YYYY-MM-DD");

    return accPeriod ? accPeriod.format("YYYY-MM-DD") : null;
  }, null);

  const periodEnd = periodEnds.reduce((acc, cur) => {
    const curPeriod = moment(cur);
    const accPeriod = acc ? moment(acc) : null;

    if (!accPeriod || curPeriod.isAfter(accPeriod))
      return curPeriod.format("YYYY-MM-DD");

    return accPeriod ? accPeriod.format("YYYY-MM-DD") : null;
  }, null);

  return [periodStart, periodEnd];
}
