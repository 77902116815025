import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";

import StepDisplay from "../../../components/StepDisplay/StepDisplay";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useUserForm,
  constants as userConstants,
  destroyPostForm,
} from "../../../store/users";
import { constants as internalCustomerConstants } from "../../../store/internalCustomers";
import { DoubleFieldWrapper } from "../../../components/Forms/Base/Chapters/styles";
import { TextInput } from "../../../components/Forms/Base/Fields";
import { clearFetched, useFormInstanceField } from "../../../store/base";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { cloneDeep } from "lodash";
import { axiosInstance } from "../../../store/base/store/axios";
import { addToast, TOAST_TYPES } from "../../../store/toasts";

const STEPS = {
  CREATE_CUSTOMER: 1,
  CONFIRM: 2,
};

export default function CreateCustomer() {
  const storeName = userConstants.STORE_NAME;
  const method = "POST";
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();
  const [step, setStep] = React.useState(STEPS.CREATE_CUSTOMER);
  const [createLoading, setCreateLoading] = React.useState(false);

  useUserForm(method);

  const steps = ["1. Ange information", "2. Granska och Bekräfta"];

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  React.useEffect(() => {
    return () => {
      dispatch(destroyPostForm(true));
    };
  }, []);

  const canSubmit =
    instance?.corporate_name &&
    instance?.email &&
    instance?.legal_id &&
    instance?.first_name &&
    instance?.last_name &&
    instance?.username &&
    instance?.password;

  const onSubmit = async () => {
    setCreateLoading(true);

    try {
      const instanceClone = cloneDeep(instance);

      const res = await axiosInstance.post(
        "/accounts/users/user/top_invite/",
        instanceClone,
        { timeout: 600000 }
      );

      setCreateLoading(false);

      dispatch(clearFetched(internalCustomerConstants, true));

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Kunden skapades",
          description: "Konfigurationen lyckades",
        })
      );

      push("/admin");
    } catch (e) {
      setCreateLoading(false);

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Något gick fel",
          description: JSON.stringify(e),
        })
      );
    }
  };

  const createStep = () => (
    <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Skapa kund</OverviewTitle>
          <OverviewSubtitle>
            Ange information om den nya kunden
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <InnerBox>
        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"corporate_name"}
            method={method}
            title="Bolagets namn"
          />

          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"legal_id"}
            method={method}
            title="Orgnummer"
          />
        </DoubleFieldWrapper>

        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"first_name"}
            method={method}
            title="Förnamn"
          />
          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"last_name"}
            method={method}
            title="Efternamn"
          />
        </DoubleFieldWrapper>

        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"email"}
            method={method}
            title="Epost"
          />
          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"phone"}
            method={method}
            title="Telefon"
          />
        </DoubleFieldWrapper>

        <DoubleFieldWrapper>
          <TextInput
            extraStyles={{ minWidth: "290px", marginRight: "20px" }}
            storeName={storeName}
            fieldKey={"username"}
            method={method}
            title="Användarnamn"
            manualInstructions={{
              _required: true,
              _readOnly: false,
              _allowNull: false,
              _internalId: "username",
              _nested: false,
              _minLength: 2,
              _maxLength: 64,
            }}
          />
          <TextInput
            extraStyles={{ minWidth: "290px" }}
            storeName={storeName}
            fieldKey={"password"}
            method={method}
            title="Lösenord"
            manualInstructions={{
              _required: true,
              _readOnly: false,
              _allowNull: false,
              _internalId: "password",
              _nested: false,
              _minLength: 2,
              _maxLength: 64,
            }}
          />
        </DoubleFieldWrapper>

        <div>
          <PrimaryButton
            clicked={() => {
              setStep(STEPS.CONFIRM);
            }}
            title={"Gå vidare"}
            disabled={!canSubmit}
          />
        </div>
      </InnerBox>
    </DetailPageBox>
  );

  const confirmStep = () => (
    <DetailPageBox style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      {createLoading && <OverlaySpinner />}
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Gå tillbaka"
        iconType="arrow-back"
        clicked={() => {
          setStep(STEPS.CREATE_CUSTOMER);
        }}
      />
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Bekräfta skapande av kund</OverviewTitle>
          <OverviewSubtitle>
            Kontrollera att datan är korrekt. Vid skapande kommer kunden att
            sättas upp av Pigellos system, vilket kan ta ett tag
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <DetailInfo
        infoObj={{
          Kundinformation: [
            {
              title: "Bolagets namn",
              value: instance?.corporate_name,
            },
            {
              title: "Orgnr",
              value: instance?.legal_id,
            },
            {
              title: "Kontaktperson",
              value: `${instance?.first_name} ${instance?.last_name}`,
            },
            {
              title: "E-post",
              value: instance?.email,
            },
            {
              title: "Telefon",
              value: instance?.phone,
            },
          ],
        }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryButton title="Skapa ny kund" clicked={onSubmit} />
      </div>
    </DetailPageBox>
  );

  return (
    <DetailInnerWrapper>
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Avbryt skapande av ny kund "
        iconType="arrow-back"
        clicked={() => goBack()}
      />
      <StepDisplay steps={steps} activeIndex={step - 1} />

      {step === STEPS.CREATE_CUSTOMER && <>{createStep()}</>}
      {step === STEPS.CONFIRM && <>{confirmStep()}</>}
    </DetailInnerWrapper>
  );
}
