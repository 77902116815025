import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { updateKivraSettings } from "../../../store/billectaIntegrations/store/actions";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../Forms/Base/Chapters/styles";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import StandardModal from "../../Modals/StandardModal";

export default function KivraIntegrationForm({
  open,
  closeFunction,
  creditorId,
}) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const isEnabled = useSelector(
    (state) => state.billectaIntegrations?.kivraSettings?.IsEnabled
  );

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      updateKivraSettings({
        creditorId,
        enable: !isEnabled,
        successCallback: () => {
          closeFunction();
          setLoading(false);
        },
      })
    );
  };

  return (
    <StandardModal
      isOpen={open}
      closeFunction={closeFunction}
      withActionBar
      actionBarAcceptTitle={
        !isEnabled ? "Aktivera integration" : "Inaktivera integration"
      }
      title="Integration - Kivra"
      saveFunction={handleSubmit}
      actionBarAcceptIsWarning={isEnabled}
    >
      {loading && <OverlaySpinner />}
      <FormAreaTitle>Kivra</FormAreaTitle>
      <FormAreaDescription>
        Integrationen Kivra tillåter bolaget att skicka avier via Kivras
        digitala brevlåda
      </FormAreaDescription>
    </StandardModal>
  );
}
