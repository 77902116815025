import { useDispatch, useSelector } from "react-redux";

import constants from "../store/constants";

import {
  CLEANED_INVOICE,
  MULTIPLE_CLEANED_INVOICE,
  TRIPLE_CLEANED_INVOICE,
} from "./instructions";

export function useMultipleInvoiceForm() {
  const method = "POST";
  const dispatch = useDispatch();

  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: MULTIPLE_CLEANED_INVOICE,
        name: "invoice",
        method: method,
      },
    });
  }

  return hasForm;
}

export function useTripleInvoiceForm() {
  const method = "POST";
  const dispatch = useDispatch();

  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: TRIPLE_CLEANED_INVOICE,
        name: "triple_invoice",
        method: method,
      },
    });
  }

  return hasForm;
}

export function useInvoiceForm(method = "PUT") {
  const dispatch = useDispatch();

  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: CLEANED_INVOICE,
        name: "invoice",
        method: method,
      },
    });
  }

  return hasForm;
}

export function useManualInvoiceForm(method, tenant) {
  const dispatch = useDispatch();
  const hasForm = useSelector((state) =>
    Boolean(state[constants.STORE_NAME].forms[method])
  );

  if (!tenant?.id) return undefined;

  if (!hasForm) {
    dispatch({
      type: constants.INSERT_INTO_FORMS,
      payload: {
        result: CLEANED_INVOICE,
        name: "manual_invoice",
        method: method,
      },
    });
  }

  return hasForm;
}
