import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import clearTableFilters from "./utils/clearTableFilters";

import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/store";

export const ENVIRONMENTS = {
  PROD: "management.pigello",
  STAGING: "staging.management.pigello.se",
  // ALPHA: "tfdemo.app.pigello",
  ALPHA: "demo.management.pigello.se",
  TEST: "test.management.pigello",
  DEV: "localhost",
};

export let currentEnv;
Object.keys(ENVIRONMENTS).forEach((key) => {
  const val = ENVIRONMENTS[key];
  if (window.location.host.includes(val)) {
    currentEnv = val;
  }
});

export const SOCKET_URL =
  currentEnv === ENVIRONMENTS.PROD
    ? "wss://9eabjypqx5.execute-api.eu-north-1.amazonaws.com/production"
    : currentEnv === ENVIRONMENTS.STAGING
    ? "wss://4abiipmiz2.execute-api.eu-north-1.amazonaws.com/staging"
    : "wss://nrvmskesh1.execute-api.eu-north-1.amazonaws.com/dev";

export const FEEDBACK_URL =
  currentEnv === ENVIRONMENTS.PROD
    ? "https://xnmbjk7llg.execute-api.eu-north-1.amazonaws.com/Development/feedback-beta"
    : "https://xnmbjk7llg.execute-api.eu-north-1.amazonaws.com/Development/feedback";

if (currentEnv === ENVIRONMENTS.PROD || currentEnv === ENVIRONMENTS.TEST) {
  //@ts-ignore
  window.__insp = window.__insp || [];
  //@ts-ignore

  __insp.push(["wid", 195571885]);
  var ldinsp = function () {
    //@ts-ignore

    if (typeof window.__inspld != "undefined") return;
    //@ts-ignore

    window.__inspld = 1;
    var insp = document.createElement("script");
    insp.type = "text/javascript";
    insp.async = true;
    insp.id = "inspsync";
    insp.src =
      ("https:" == document.location.protocol ? "https" : "http") +
      "://cdn.inspectlet.com/inspectlet.js?wid=195571885&r=" +
      Math.floor(new Date().getTime() / 3600000);
    var x = document.getElementsByTagName("script")[0];
    //@ts-ignore

    x.parentNode.insertBefore(insp, x);
  };
  setTimeout(ldinsp, 0);
}

clearTableFilters();

ReactDOM.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
  document.getElementById("root")
);
