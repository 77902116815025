import * as React from "react";
import { useEditabledocForm } from "../../../../store/editabledocs";
import EditableDocPDF from "../../../EditableDocBase/PdfRenderer/Pdf";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";
import {
  constants,
  destroyPatchForm,
  destroyPostForm,
} from "../../../../store/editabledocs";
import * as SC from "../styles";
import EditableDocBottomBar from "../../../EditableDocBase/BottomBar/BottomBar";
import { useFormInstanceField } from "../../../../store/base";
import EditableDocTopBar from "../../../EditableDocBase/TopBar/TopBar";

import useSetPartyColors from "../../../EditableDocBase/useSetPartyColors";
import { useDispatch } from "react-redux";

export default function EditableDocContainer({
  method,
  onSubmit,
  editableDocId,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const formLoaded = Boolean(useEditabledocForm(method, editableDocId));

  useSetPartyColors({ fieldKey: "parties", storeName });

  const editableDocFile = useFormInstanceField({
    storeName,
    fieldKey: "docData_",
  });
  const editableDocUrl = useFormInstanceField({
    storeName,
    fieldKey: "doc.get",
  });

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(true));
      dispatch(destroyPostForm(true));
    };
  }, []);

  const hasDocument = editableDocFile || editableDocUrl;

  if (!formLoaded) return <FullPageSpinner />;

  return (
    <>
      {hasDocument && <EditableDocTopBar method={method} isTemplate />}
      {hasDocument && (
        <EditableDocBottomBar {...{ method, onSubmit, isTemplate: true }} />
      )}

      <SC.DocWrapper>
        <EditableDocPDF method={method} />
      </SC.DocWrapper>
    </>
  );
}
