import { useFilteredCustomerInvoicings } from "../../../store/invoicingCustomer";

export default function useMatchingContract({ invoice }) {
  const contractInvoiceId = invoice?.ContractInvoiceActionPublicId;
  const invoiceId = invoice?.ActionPublicId;

  // complex q
  const query = `complex_filter=(billecta_object_ids=${
    contractInvoiceId || "nocontractinvoice"
  })|(billecta_single_object_ids=${invoiceId || "noinvoice"})`;

  const [matchingCustomerInvoicings, customerLoading] =
    useFilteredCustomerInvoicings(query);

  const matching = matchingCustomerInvoicings?.[0] || null; // add other contract types
  const loading = customerLoading || false; // add other loading flags

  return [matching, loading];
}
