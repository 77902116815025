import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "../../../../store/billectaInvoicing";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";

import * as SC from "../styles";
import {
  Select,
  DatePicker,
  PositiveNumber,
  TextInput,
  TextField,
  Checkbox,
} from "../../../Forms/Base/Fields";
import { InvoiceChapter } from "../InvoiceComponents";
import DebtCollectionChapter from "../InvoiceComponents/DebtCollectionChapter";
import checkValidations from "../InvoiceComponents/checkValidations";
import InvoiceRecords from "../InvoiceComponents/InvoiceRecords";
import {
  getAutogiroSettings,
  getEInvoiceSettings,
  getKivraSettings,
} from "../../../../store/billectaIntegrations";
import checkRequired from "../InvoiceComponents/checkRequired";
import RequiredMissing from "../InvoiceComponents/RequiredMissing/RequiredMissing";
import { useParams } from "react-router";
import { setInvoiceFormInstance } from "../../../../store/billectaInvoicing/store/actions";
import { useFilteredCompanies } from "../../../../store/companies";
import { useFilteredDebtorInvoicings } from "../../../../store/invoicingDebtor";
import { InnerBox } from "../../../sharedStyles";
import TableFileM2m from "../../../Forms/Base/Fields/TableFileM2m";
import { PrimaryButton } from "../../../Forms/Base/Buttons";
import NonConnectedDatePicker from "../../../Forms/Base/Old/NonConnected/NonConnectedDatePicker";
import moment from "moment";
import { cloneDeep } from "lodash";
import { useCustomer } from "../../../../store/customers";

export default function HandleInvoiceForm({
  allDisabled,
  onValidationError,
  onRequiredMissing,
  invoice,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = React.useState({});
  const [requiredMissing, setRequiredMissing] = React.useState([]);

  const invoiceData = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );
  const { creditorId } = useParams();

  const periodFrom = invoiceData?.Records?.reduce((acc, cur) => {
    if (!acc) return cur.PeriodStart;

    const curPeriodStart = moment(cur.PeriodStart);
    const accPeriodStart = moment(acc);

    if (curPeriodStart.isBefore(accPeriodStart))
      return curPeriodStart.format("YYYY-MM-DD");

    return acc;
  }, null);
  const periodTo = invoiceData?.Records?.reduce((acc, cur) => {
    if (!acc) return cur.PeriodEnd;

    const curPeriodEnd = moment(cur.PeriodEnd);
    const accPeriodEnd = moment(acc);

    if (curPeriodEnd.isBefore(accPeriodEnd))
      return curPeriodEnd.format("YYYY-MM-DD");

    return acc;
  }, null);

  const companyQuery = buildQueryString({
    billecta_id: creditorId,
  });
  const [companies] = useFilteredCompanies(companyQuery);
  const company = companies?.[0];

  const autogiroSettings = useSelector(
    (state) => state.billectaIntegrations.autogiroSettings
  );

  const kivraSettings = useSelector(
    (state) => state.billectaIntegrations.kivraSettings
  );
  const eInvoiceSettings = useSelector(
    (state) => state.billectaIntegrations.eInvoiceSettings
  );

  const debtorQuery = buildQueryString({
    billecta_object_ids: invoice?.Debtor?.DebtorPublicId || [],
  });

  const [debtors] = useFilteredDebtorInvoicings(debtorQuery);
  const selectedDebtorConfig = debtors?.[0];

  const [customer] = useCustomer(selectedDebtorConfig?.management_customer?.id);

  const customerHasAutogiro = selectedDebtorConfig?.use_autogiro;
  const creditorHasAutogiro = !!autogiroSettings;

  const customerHasEInvoiceActivated = !!selectedDebtorConfig?.e_invoice_bank;

  React.useEffect(() => {
    dispatch(setInvoiceFormInstance({ invoice }));
  }, []);

  React.useEffect(() => {
    if (!kivraSettings) {
      dispatch(getKivraSettings(creditorId));
    }
    if (!eInvoiceSettings) {
      dispatch(getEInvoiceSettings(creditorId));
    }
    if (!autogiroSettings) {
      dispatch(getAutogiroSettings(creditorId));
    }
  }, []);

  //If customer has e-invoce set up, that is the only allowed delivery method
  // So, we force default it. The field is also disabled.
  // Invoice fees are not allowed for Einvoice, so its reset to 0
  React.useEffect(() => {
    if (customerHasEInvoiceActivated && !allDisabled) {
      dispatch(
        updateActiveFormInstance({
          storeName: constants.STORE_NAME,
          data: {
            DeliveryMethod: "EInvoice",
            InvoiceFee: {
              CurrencyCode: "SEK",
              Value: 0,
            },
          },
        })
      );
    }
  }, [customerHasEInvoiceActivated, dispatch]);

  const setValidationErrorsCallback = React.useCallback(
    (errors) => {
      setValidationErrors(errors);
      onValidationError(errors);
    },
    [validationErrors]
  );

  const setRequiredCallback = React.useCallback(
    (required) => {
      setRequiredMissing(required);
      onRequiredMissing(required);
    },
    [requiredMissing]
  );

  const sendReminderInvoiceDetails = useFormInstanceField({
    storeName,
    fieldKey: "ReminderInvoiceDetails",
  });

  React.useEffect(() => {
    // clear DaysDealy if setting false for sending
    if (
      sendReminderInvoiceDetails &&
      !sendReminderInvoiceDetails.SendReminderInvoice
    ) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            ReminderInvoiceDetails: null,
          },
        })
      );
    }
  }, [sendReminderInvoiceDetails]);

  React.useEffect(() => {
    // check validation
    if (invoiceData) {
      checkValidations({
        formInstance: invoiceData,
        debtorInstance: selectedDebtorConfig,
        customer,
        kivraEnabled: kivraSettings?.IsEnabled,
        eInvoiceEnabled: !!eInvoiceSettings?.FUI,
        setErrors: setValidationErrorsCallback,
        type: "INVOICE",
      });

      checkRequired({
        formInstance: invoiceData,
        type: "INVOICE",
        setRequired: setRequiredCallback,
      });
    }
  }, [invoiceData]);

  const automatedDebtCollectionAdded = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          InterestPercentage: 8,
          InterestStartInDaysAfterDueDate: 10,
          InterestType: "AboveEffectiveReference",
          DebtCollectionDetails: {
            DaysDelayAfterDueDate: 4,
            NumberOfReminders: 1,
            PaymentTermsInDays: 8,
            SendToDebtCollection: true,
            StartDebtCollectionActionLevel: "Reminders",
          },
        },
      })
    );
  };

  const automatedDebtCollectionRemoved = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          InterestPercentage: undefined,
          InterestStartInDaysAfterDueDate: undefined,
          InterestType: undefined,
          DebtCollectionDetails: undefined,
        },
      })
    );
  };

  const handleRemoveAttachments = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: {
          Attachments: [],
        },
      })
    );
  };

  const setNewPeriodFrom = (date) => {
    const invoiceDataClone = cloneDeep(invoiceData);
    if (!invoiceDataClone?.Records?.length) return;

    invoiceDataClone.Records.forEach((r) => {
      r.PeriodStart = date;
    });

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: invoiceDataClone,
      })
    );
  };

  const setNewPeriodTo = (date) => {
    const invoiceDataClone = cloneDeep(invoiceData);

    if (!invoiceDataClone?.Records?.length) return;
    invoiceDataClone.Records.forEach((r) => {
      r.PeriodEnd = date;
    });

    dispatch(
      updateActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: invoiceDataClone,
      })
    );
  };

  const invoicingChapter = () => (
    <InvoiceChapter title="Faktura" description="Inställningar för fakturan">
      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Vår referens:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <TextInput
            disabled={allDisabled}
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`OurReference`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Kundens referens:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <TextInput
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`YourReference`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Fakturadatum:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <DatePicker
            disabled={allDisabled}
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`InvoiceDate`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid: !!validationErrors["DueDate"],
        }}
      >
        <SC.InvoicedBoxRowTitle>Betalas senast:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <DatePicker
            disabled={allDisabled}
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`DueDate`}
          />
          {!!validationErrors["DueDate"] && (
            <SC.InvalidRowWarning>
              Förfallodatumet är innan fakturadatumet. Fakturan kommer ej kunna
              attesteras.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Använd autogiro:</SC.InvoicedBoxRowTitle>
        <SC.InvoicedBoxRowValue>
          <Checkbox
            disabled={!customerHasAutogiro || !creditorHasAutogiro}
            noMargin
            title={
              !creditorHasAutogiro
                ? "Autogiro ej aktiverat för bolaget"
                : !customerHasAutogiro
                ? "Autogiro ej aktiverat för kund"
                : "Använd autogiro"
            }
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`Autogiro.AutogiroWithdrawalEnabled`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      <SC.InvoicedBoxRow
        {...{
          invalid:
            !!validationErrors["DeliveryMethod_Email"] ||
            !!validationErrors["DeliveryMethod_Mail"] ||
            !!validationErrors["DeliveryMethod_Kivra_Disabled"] ||
            !!validationErrors["DeliveryMethod_SMS"] ||
            !!validationErrors["DeliveryMethod_EInvoice_Disabled"],
        }}
      >
        <SC.InvoicedBoxRowTitle>Leveransmetod:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <Select
            disabled={allDisabled || customerHasEInvoiceActivated}
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`DeliveryMethod`}
          />
          {!allDisabled && customerHasEInvoiceActivated && (
            <SC.InvalidRowWarning style={{ color: "black" }}>
              Eftersom kunden har E-faktura uppsatt så är leveransmetoden låst
              till E-faktura. För att kunna använda en annan leveransmetod krävs
              att E-faktura tas bort ifrån kunden.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Email"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar e-postadress för leverans. Lägg till
              en e-postadress på kunden eller välj en annan leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Mail"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar fysisk adress för leverans. Lägg
              till en address på kunden eller välj en annan leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_Kivra_Disabled"] && (
            <SC.InvalidRowWarning>
              Kivra-integrationen är inte konfigurerad för detta bolag. Aktivera
              integrationen för att kunna nyttja Kivra som leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_SMS"] && (
            <SC.InvalidRowWarning>
              Den fakturerade parten saknar telefonnummer för leverans. Lägg
              till ett telefonnummer på kunden eller välj en annan
              leveransmetod.
            </SC.InvalidRowWarning>
          )}
          {!!validationErrors["DeliveryMethod_EInvoice_Disabled"] && (
            <SC.InvalidRowWarning>
              E-fakturering har inte aktiverats. Kontakta Pigello för att
              aktivera detta.
            </SC.InvalidRowWarning>
          )}
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>

      {invoiceData.DeliveryMethod === "Email" && (
        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>
            Skicka postalt om ej e-post är öppnad efter antal dagar:
          </SC.InvoicedBoxRowTitle>

          <SC.InvoicedBoxRowValue>
            <PositiveNumber
              disabled={allDisabled}
              rowSize
              noMargin
              noTitle
              storeName={constants.STORE_NAME}
              method={"PUT"}
              fieldKey={`SendByMailIfEmailNotViewedInDays`}
            />
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>
      )}

      <SC.InvoicedBoxRow>
        <SC.InvoicedBoxRowTitle>Meddelande på fakturan:</SC.InvoicedBoxRowTitle>

        <SC.InvoicedBoxRowValue>
          <TextField
            rowSize
            noMargin
            noTitle
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`Message`}
          />
        </SC.InvoicedBoxRowValue>
      </SC.InvoicedBoxRow>
    </InvoiceChapter>
  );

  const periodChapter = () => {
    return (
      <InvoiceChapter
        title="Period på faktura"
        description="Period som debiteringsraderna på fakturan avser"
      >
        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>Period från:</SC.InvoicedBoxRowTitle>
          <SC.InvoicedBoxRowValue>
            <NonConnectedDatePicker
              value={periodFrom}
              id="_period_from"
              rowSize
              noMargin
              noTitle
              onChange={(date) => setNewPeriodFrom(date)}
            />
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>
        <SC.InvoicedBoxRow>
          <SC.InvoicedBoxRowTitle>Period till:</SC.InvoicedBoxRowTitle>
          <SC.InvoicedBoxRowValue>
            <NonConnectedDatePicker
              value={periodTo}
              id="_period_to"
              rowSize
              noMargin
              noTitle
              onChange={(date) => setNewPeriodTo(date)}
            />
          </SC.InvoicedBoxRowValue>
        </SC.InvoicedBoxRow>
      </InvoiceChapter>
    );
  };

  const hasExistingAttachments = invoiceData?.Attachments?.some(
    (a) => a.File?.FilePublicId
  );

  const AttachmentChapter = () => (
    <InvoiceChapter title="Bilagor">
      <InnerBox style={{ display: "flex", justifyContent: "center" }}>
        {hasExistingAttachments ? (
          <div
            style={{
              padding: 48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Denna faktura har existerande bilagor. Tryck på "Ta bort existerande
            bilagor" för att ta bort existerande och kunna bifoga nya bilagor.
            <PrimaryButton
              extraStyle={{ marginLeft: 24 }}
              title="Ta bort existerande bilagor"
              clicked={handleRemoveAttachments}
            />
          </div>
        ) : (
          <TableFileM2m
            title="Bilagor"
            extraStyles={{ width: "100%", maxWidth: "none" }}
            description="Bilagor på fakturan"
            storeName={constants.STORE_NAME}
            method={"PUT"}
            fieldKey={`Attachments`}
            fileKey="File"
          />
        )}
      </InnerBox>
    </InvoiceChapter>
  );

  return (
    <>
      {invoicingChapter()}

      {periodChapter()}

      <InvoiceRecords
        customerHasEInvoiceActivated={
          !allDisabled && customerHasEInvoiceActivated
        }
        disabled={allDisabled}
        constants={constants}
        method="PUT"
        instance={invoiceData}
        matchRecords={false}
        creditorId={creditorId}
      />

      {(invoiceData?.DebtCollectionDetails?.SendToDebtCollection ||
        !allDisabled) && (
        <DebtCollectionChapter
          method="PUT"
          instance={invoiceData}
          constants={constants}
          validationErrors={validationErrors}
          automatedDebtCollectionAdded={automatedDebtCollectionAdded}
          automatedDebtCollectionRemoved={automatedDebtCollectionRemoved}
          company={company}
        />
      )}

      {AttachmentChapter()}

      {requiredMissing.length > 0 && (
        <RequiredMissing
          requiredMissing={requiredMissing}
          title="Dessa uppgifter saknas för att kunna skapa eller förhandsgranska fakturan:"
        />
      )}
    </>
  );
}
