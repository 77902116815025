import * as React from "react";
// style, design
import { Select, TextField, TextInput } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/invoicingDebtor";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Leverans av faktura</OverviewTitle>
          <OverviewSubtitle>
            Standardinställning för kund. Dessa inställningar kan skrivas över
            per avtal
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <Select
        title="Leveransmetod"
        {...{
          storeName,
          method,
          fieldKey: `delivery_method`,
        }}
      />

      <TextInput
        title="Kundens referens"
        {...{
          storeName,
          method,
          fieldKey: `your_reference`,
        }}
      />

      <TextField
        title="Standardmeddelande på fakturor"
        {...{
          storeName,
          method,
          fieldKey: `default_invoice_message`,
        }}
      />

      <TextInput
        title="Telefonnummer för fakturaleverans"
        {...{
          storeName,
          method,
          fieldKey: `invoice_phone`,
        }}
      />

      <TextInput
        title="E-postadress för fakturaleverans"
        {...{
          storeName,
          method,
          fieldKey: `email_invoice`,
        }}
      />

      <TextInput
        title="E-postadress för fakturaleverans CC"
        {...{
          storeName,
          method,
          fieldKey: `email_invoice_cc`,
        }}
      />

      <TextInput
        title="E-postadress för fakturaleverans BCC"
        {...{
          storeName,
          method,
          fieldKey: `email_invoice_bcc`,
        }}
      />
    </>
  );
};
