export const CLEANED_BOOK_KEEPING = {
  AccrualIsEnabled: {
    _internalId: "AccrualIsEnabled",
    _label: "Periodisering",
  },
  RestingVatIsEnabled: {
    _internalId: "RestingVatIsEnabled",
    _label: "Vilande moms",
  },
  VoucherSeries: {
    _internalId: "VoucherSeries",
  },
  BookKeepingMethod: {
    _internalId: "BookKeepingMethod",
    _choices: [
      { v: "InvoiceMethod", d: "Fakturametoden" },
      { v: "CashMethod", d: "Kontantmetoden" },
    ],
  },
  FiscalYear: {
    _internalId: "FiscalYear",
    _choices: [
      { v: "CalendarYear", d: "Kalenderår (1 jan - 31 dec" },
      {
        v: "FebToJan",
        d: "Brutet räkenskapsår (1 feb - 31 jan)",
      },
      {
        v: "MarToFeb",
        d: "Brutet räkenskapsår (1 mar  - 28 feb",
      },
      {
        v: "AprToMar",
        d: "Brutet räkenskapsår (1 apr - 31 mar",
      },
      {
        v: "MayToApr",
        d: "Brutet räkenskapsår (1 maj - 30 apr",
      },
      {
        v: "JunToMay",
        d: "Brutet räkenskapsår (1 jun - 31 maj",
      },
      {
        v: "JulToJun",
        d: "Brutet räkenskapsår (1 jul - 30 jun",
      },
      {
        v: "AugToJul",
        d: "Brutet räkenskapsår (1 aug - 31 jul",
      },
      {
        v: "SepToAug",
        d: "Brutet räkenskapsår (1 sep - 31 aug",
      },
      {
        v: "OctToSep",
        d: "Brutet räkenskapsår (1 oct - 30 sep",
      },
      {
        v: "NovToOct",
        d: "Brutet räkenskapsår (1 nov - 31 oct",
      },
      {
        v: "DecToNov",
        d: "Brutet räkenskapsår (1 dev  - 30 nov",
      },
    ],
  },
  Accounts: {
    _internalId: "Accounts",
    Account: {
      _internalId: "Accounts.Account",
    },
  },
};
