import * as React from "react";
import StandardModal from "../../../Modals/StandardModal";
import {
  SingleChapterRow,
  baseToggleInfo,
  InfoModalContent,
} from "./ExtraPermsComps";
import * as SC from "./styles";

export default ({ group, disabled }) => {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);

  const handleInfoModal = (permObject) => {
    setInfoModalOpen(permObject);
  };

  const mapCurrentPerms = () => {
    if (!group) return;

    return (
      <>
        <SingleChapterRow
          title="Företag"
          perms={baseToggleInfo.COMPANY_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Förvaltning"
          perms={baseToggleInfo.CUSTOMER_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Dokument"
          perms={baseToggleInfo.BASEOBJECT_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Mallar"
          perms={baseToggleInfo.CONTRACTS_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Användarhantering"
          perms={baseToggleInfo.USER_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Behörighetshantering"
          perms={baseToggleInfo.PERMISSION_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Fakturering/Avisering"
          perms={baseToggleInfo.BILLING_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="E-signering"
          perms={baseToggleInfo.E_SIGNING_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Fastdok"
          perms={baseToggleInfo.FASTDOC_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
      </>
    );
  };

  if (!group) return null;

  return (
    <>
      <StandardModal
        isOpen={infoModalOpen}
        closeFunction={() => setInfoModalOpen(false)}
        title={`Behörighetsinformation om ${infoModalOpen?.title}`}
        withActionBar
        actionBarCancelTitle="Stäng"
      >
        <InfoModalContent infoObj={infoModalOpen} />
      </StandardModal>

      <SC.PermPickerWrapper key={group?.id}>
        <SC.HeaderRow>
          <SC.HeaderTitle
            style={{ flex: 2, paddingLeft: "20px" }}
          ></SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Se</SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Skapa</SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Redigera</SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Ta bort</SC.HeaderTitle>
          <SC.TableFiller />
        </SC.HeaderRow>
        {mapCurrentPerms()}
      </SC.PermPickerWrapper>
    </>
  );
};
