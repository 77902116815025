import * as React from "react";
import { useHistory } from "react-router";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import BillectaTable from "../../../components/Lists/Companies/BillectaTable";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { buildQueryString } from "../../../store/base";
import { useFilteredBillectaEvents } from "../../../store/billectaEvents";
import { overviewUrl } from "../../../store/billectaInvoicing";
import InvoicingErrorsTable from "../../../components/Lists/InvoicingErrors/FullTable";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import { NotificationDot } from "../../../components/Dashboard/styles";
import { REPORT_EVENTS_CATEGORIES } from "../../../store/billectaEvents/hooks/retrieve";

function InvoicingOverview() {
  const { push } = useHistory();
  const [showNonInvoicing, setShowNonInvoicing] = React.useState(false);

  const billectaEventsQuery = buildQueryString({
    read: false,
    category__in: [
      REPORT_EVENTS_CATEGORIES.INVOICE_UNDELIVERED,
      REPORT_EVENTS_CATEGORIES.INVOICE_UNMATCHED_PAYMENT,
      REPORT_EVENTS_CATEGORIES.INVOICE_OVERPAYED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_APPROVAL_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_CHANGED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL_RETRY,
    ],
  });
  const [billectaEvents] = useFilteredBillectaEvents(billectaEventsQuery);

  const [billectaErrorsOpen, setBillectaErrorsOpen] = React.useState(false);

  React.useEffect(() => {
    const localShowBillectaErrors = localStorage.getItem(
      "show_invoicing_errors"
    );
    if (localShowBillectaErrors == "true" && !billectaErrorsOpen) {
      setBillectaErrorsOpen(true);
    }
  }, []);

  const [errorCount] = useInvoicingErrorPaginationCount();

  const goToCreditor = ({ original }) => {
    push(overviewUrl({ creditorId: original.billecta_id }));
  };

  const withInvoicingQuery = buildQueryString({
    billecta_id__isnull: false,
  });
  const withoutInvoicingQuery = buildQueryString({
    billecta_id__isnull: true,
  });

  const withInvoicingFilter = (obj) => !!obj.billecta_id;
  const withoutInvoicingFilter = (obj) => !obj.billecta_id;

  return (
    <DetailInnerWrapper>
      {errorCount > 0 && (
        <DetailPageBox error>
          <OverviewTitleWrapper
            style={billectaErrorsOpen ? {} : { marginBottom: 0 }}
          >
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
                small
              >
                Felmeddelanden <NotificationDot>{errorCount}</NotificationDot>
              </OverviewTitle>

              <OverviewSubtitle>
                Dessa fel kan innebära att bolag, kunder,
                aviseringsinställningar, kostnadsställen, produkter, kostnader,
                projekt och fakturor inte kan synkas som de ska mot
                aviseringssystemet.
                {!billectaErrorsOpen ? (
                  <>
                    <br />
                    <br />
                    <TextButton
                      title="Visa felmeddelanden"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => {
                        localStorage.setItem("show_invoicing_errors", true);
                        setBillectaErrorsOpen(true);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <TextButton
                      title="Dölj felmeddelanden"
                      iconType="close"
                      iconPlacement="right"
                      clicked={() => {
                        localStorage.setItem("show_invoicing_errors", false);
                        setBillectaErrorsOpen(false);
                      }}
                    />
                  </>
                )}
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {billectaErrorsOpen && (
            <InvoicingErrorsTable
              hideTitle
              hideColumns
              hideSearch
              hideExport
              hideFilters
            />
          )}
        </DetailPageBox>
      )}

      <DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Fakturerande bolag</OverviewTitle>
            <OverviewSubtitle>
              Gå in på ett bolag för att se fakturor och hantera
              bokföringsinställningar
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <BillectaTable
          {...{
            hideTitle: true,
            hideExport: true,
            hideFilters: true,
            hideColumns: true,
            handleRowClicked: goToCreditor,
            persistantFilterMethod: withInvoicingFilter,
            persistantQueryString: withInvoicingQuery,
            billectaEvents,
          }}
        />

        {showNonInvoicing && (
          <BillectaTable
            {...{
              hideTitle: true,
              hideExport: true,
              hideFilters: true,
              hideColumns: true,

              persistantFilterMethod: withoutInvoicingFilter,
              persistantQueryString: withoutInvoicingQuery,
            }}
          />
        )}
        {!showNonInvoicing && (
          <TextButton
            extraStyle={{ marginTop: 24 }}
            title="Visa bolag som ej är uppsatta för fakturering"
            clicked={() => setShowNonInvoicing(true)}
            iconType="arrow"
            iconPlacement="right"
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default InvoicingOverview;
