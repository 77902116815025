import * as React from "react";
import { useParams } from "react-router";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { EmailTag } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import { useCustomer } from "../../../store/customers";

import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import EditModal from "../../../components/Forms/Customer/ChapterForm/ModalForm";
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useFilteredNotes } from "../../../store/notes";

const getContactPerson = (customer) => {
  let currentContact;

  if (customer?.first_name) {
    currentContact = customer.first_name;
  }
  if (customer?.last_name) {
    if (currentContact) {
      currentContact = `${currentContact} ${customer.last_name}`;
    } else {
      currentContact = customer.last_name;
    }
  }
  if (!currentContact) {
    currentContact = "-";
  }

  return currentContact;
};

export default function CustomerOverview() {
  const [editOpen, setEditOpen] = React.useState(false);
  const { customerId } = useParams();

  const canEditCustomer = usePermissionCheck("change_can_management");

  const [customer] = useCustomer(customerId);

  const notesQ = buildQueryString({
    id__in: customer?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  return (
    <>
      <EditModal
        method="PATCH"
        id={customerId}
        isOpen={editOpen}
        instance={customer}
        onCheckout={() => setEditOpen(false)}
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "60%" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              {canEditCustomer && (
                <PrimaryButton
                  title="Redigera"
                  clicked={() => setEditOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getInfoObj({ customer })} />
          </DetailPageBox>

          <DetailPageBox style={{ width: "40%", alignSelf: "flex-start" }}>
            <Notes
              notes={notes}
              contentType="management.customer"
              objectId={customerId}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({ customer }) {
  const infoObj = {
    Förvaltningsuppdrag: [
      {
        title: "Bolagsnamn",
        value: customer?.corporate_name || "-",
      },
      {
        title: "Orgnr",
        value: customer?.legal_id || "-",
      },
      { title: "Kontaktperson", value: getContactPerson(customer) },

      { title: "E-post", value: <EmailTag mail={customer?.email} /> },
      {
        title: "Adress",
        value: customer?.address?.str_representation?.split(",")?.[0],
      },
    ],
  };

  return infoObj;
}
