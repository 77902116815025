import * as React from "react";

// style, design
import {
  ToolTipCell,
  LinkedObject,
  DateCell,
  DurationCell,
} from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "../Base/CompleteList/Filters/DateFilter";
import SimpleIntervalFilter from "../Base/CompleteList/Filters/IntervalFilter";
import StateFilter from "./Filters/StateFilter";

import { detailUrl as customerDetail } from "../../../store/customers";
import { detailUrl as companyDetail } from "../../../store/companies";
import { StatusLabel } from "../Base/CompleteList/styles";
import moment from "moment";
import InvoiceFilter from "./Filters/InvoiceFilter";

const _earliestDate = ({ dates }) => {
  let earliest = null;

  for (let index = 0; index < dates.length; index++) {
    let element = dates[index];
    if (element == null) {
      continue;
    }
    if (typeof element === "string") {
      element = moment(element);
    }

    if ((element?._isValid && earliest == null) || element < earliest) {
      earliest = element;
    }
  }

  return earliest;
};
const _latestDate = ({ dates }) => {
  let latest = null;

  for (let index = 0; index < dates.length; index++) {
    let element = dates[index];
    if (element == null) {
      continue;
    }
    if (typeof element === "string") {
      element = moment(element);
    }

    if ((element?._isValid && latest == null) || element > latest) {
      latest = element;
    }
  }

  return latest;
};

export const INDEX_STATES = {
  0: "Felaktig period",
  1: "Indexuppräkning ej aktiverat",
  2: "Index kan ej räknas ut för vald period",
};

export const PRODUCT_CATEGORIES = {
  CATEGORY_ERRAND_ARTICLE: 13,
  CATEGORY_MAINTENENCE: 21,
  CATEGORY_GARBAGE: 23,
  CATEGORY_SNOW: 24,
  CATEGORY_CLEANING: 25,
  CATEGORY_ADJUSTMENT: 27,
  CATEGORY_MANAGEMENT: 28,
  CATEGORY_CONSULTING: 29,
};

export const PRODUCT_CATEGORY_MAPPING = {
  13: "Ärende artikel",
  21: "Drift/Underhåll",
  23: "Avfallshantering",
  24: "Snöröjning",
  25: "Städning",
  27: "Ombyggnad/Hyresgästanpassning",
  28: "Förvaltning",
  29: "Konsulttjänster",
};

export const getContractFromAndTo = (contract) => {
  const now = moment();

  const periodStart = moment(
    contract.start_date || now.format("YYYY-MM-DD")
  ).set({ year: now.format("YYYY") });

  const periodEnd = moment(contract.start_date || now.format("YYYY-MM-DD"))
    .set({ year: now.format("YYYY") })
    .add({ year: 1 });

  const endDates = [
    periodEnd.format("YYYY-MM-DD"),
    contract.renewed_to,
    contract.closed_renew_date,
    contract.closed_date,
  ];
  if (!contract.renewed_to) {
    endDates.push(contract.end_date);
  }
  const contractTo = _earliestDate({
    dates: endDates,
  });

  const contractFrom = _latestDate({
    dates: [periodStart.format("YYYY-MM-DD"), contract.start_date],
  });

  return {
    contractFrom,
    contractTo,
  };
};

export const getSpecifiedPeriodDates = ({ start, end, contract }) => {
  const specifiedStartDate = _latestDate({
    dates: [start, contract.start_date],
  });

  const endDates = [
    end,
    contract.renewed_to,
    contract.closed_renew_date,
    contract.closed_date,
  ];
  if (!contract.renewed_to) {
    endDates.push(contract.end_date);
  }

  const specifiedEndDate = _earliestDate({
    dates: endDates,
  });

  return {
    specifiedStartDate,
    specifiedEndDate,
  };
};

export const getCostBetweenMonthDates = (costs, start, end) => {
  if (!costs?.length) {
    return [];
  }
  if (end.diff(start, "days") <= 0) {
    return [];
  }

  return costs.filter(
    (c) =>
      !(
        (c.end_date && moment(c.end_date) < start) ||
        (c.start_date && moment(c.start_date) > end)
      )
  );
};

export const getMonthsForCost = (cost, start, end, useSpecifiedPeriod) => {
  // lowest end
  let aStart;
  if (cost.start_date) {
    let cStart = moment(cost.start_date);
    if (cStart > start) {
      aStart = cStart;
    } else {
      aStart = start;
    }
  } else {
    aStart = start;
  }

  let aEnd;
  if (cost.end_date) {
    let cEnd = moment(cost.end_date);
    if (cEnd < end) {
      aEnd = cEnd;
    } else {
      aEnd = end;
    }
  } else {
    aEnd = end;
  }

  if (aEnd.isSame(aStart, "month")) return 1;

  return aEnd.diff(aStart, "months") + (useSpecifiedPeriod ? 1 : 0);
};

export const totalCostBetweenDates = ({ costs, start, end, contract }) => {
  const actualStart = _latestDate({ dates: [start, contract?.start_date] });

  const endDates = [
    end,
    contract?.closed_date,
    contract?.closed_renew_date,
    contract?.renewed_to,
  ];
  if (!contract?.renewed_to) {
    endDates.push(contract?.end_date);
  }
  const actualEnd = _earliestDate({
    dates: endDates,
  });

  const periodCosts = getCostBetweenMonthDates(costs, actualStart, actualEnd);

  const indexValue = getIndexBetweenMonths(
    !!contract?.indexation,
    contract?.indexations,
    actualStart,
    actualEnd
  );

  const calculatedCosts = periodCosts.reduce(
    (acc, curr) => {
      const months = getMonthsForCost(curr, actualStart, actualEnd);

      return acc + curr.value * curr.unit_amount * months;
    },
    typeof indexValue === "number" ? indexValue : 0
  );

  return calculatedCosts;
};

export const getIndexBetweenMonths = (hasIndex, indexes, start, end) => {
  // if any given month in a year between start and end does not have a
  // resulting index - we abort and return undefined - since it is undefined
  if (!hasIndex) return INDEX_STATES[1];

  if (end.diff(start, "days") <= 0) {
    return INDEX_STATES[0];
  }

  if (start.year() === end.year()) {
    const currentYear = moment({ year: start.year(), month: 0, day: 1 }).format(
      "YYYY-MM-DD"
    );

    const quota = (end.diff(start, "months") + 1) / 12;

    const indexMatch = indexes[currentYear];
    if (indexMatch == null) {
      return INDEX_STATES[2];
    }

    return indexMatch * quota;
  }

  let yearDiff = end.diff(start, "years") + 1;
  let hasAnyMatches = false;

  // e.g month 0 -> 12/12 = 1, e.g month 3 -> 9/12
  const startQuota = (12 - start.month()) / 12;
  const endQuota = end.month() / 12;

  let totalValue = 0;

  for (let year = 0; year <= yearDiff; year++) {
    const quota = year === 0 ? startQuota : year === yearDiff ? endQuota : 1;

    const indexMatch =
      indexes[
        moment({ year: year + start.year(), month: 0, day: 1 }).format(
          "YYYY-MM-DD"
        )
      ];

    if (indexMatch != null) {
      hasAnyMatches = true;

      totalValue += quota * indexMatch;
    }
  }

  if (!hasAnyMatches) {
    return INDEX_STATES[2];
  }
  return totalValue;
};

export const getCostColumns = (
  startDate,
  endDate,
  excludeColumns,
  useSqm,
  showMonthlyCosts,
  useSpecifiedPeriod
) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const _exclusions = (excludeColumns || [])?.map((c) => PRODUCT_CATEGORIES[c]);

  let result = [];

  Object.keys(PRODUCT_CATEGORY_MAPPING).forEach((c) => {
    const keyMapping = parseInt(c);

    if (_exclusions.includes(keyMapping)) {
      return;
    }

    const title = PRODUCT_CATEGORY_MAPPING[c];

    result.push({
      Header: `${title} ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
        useSqm ? "(per m²)" : ""
      }`,
      id: `product_title_${c}`,
      Cell: ({ row }) => {
        const { contractFrom, contractTo } = getContractFromAndTo(row.original);

        const { specifiedStartDate, specifiedEndDate } =
          getSpecifiedPeriodDates({
            start,
            end,
            contract: row.original,
          });

        const filteredCosts = getCostBetweenMonthDates(
          row.original.costs,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo
        );

        const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

        const total = filteredCosts.reduce((acc, curr) => {
          const months = getMonthsForCost(
            curr,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo,
            useSpecifiedPeriod
          );

          return (
            acc +
            (curr.product_category === keyMapping
              ? (curr.value *
                  curr.unit_amount *
                  (showMonthlyCosts ? 1 : months)) /
                (useSqm ? totalArea : 1)
              : 0)
          );
        }, 0);

        return (
          <ToolTipCell
            text={`${Math.round(total).toLocaleString("sv")} ${
              useSqm && totalArea === 1 ? "(Area saknas)" : ""
            }`}
          />
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    });
  });

  result.push({
    Header: `Hyresrabatter ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    id: `discounts`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(
          curr,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo,
          useSpecifiedPeriod
        );
        return (
          acc +
          (curr.value < 0
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  // index, brent, brent increase, proptax, parkeringar, deposit
  const nonPositiveInclusion = [
    PRODUCT_CATEGORIES.CATEGORY_INDEX,
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
    PRODUCT_CATEGORIES.CATEGORY_PROP_TAX,
    PRODUCT_CATEGORIES.CATEGORY_PARKING,
    PRODUCT_CATEGORIES.CATEGORY_DEPOSIT,
  ];

  result.push({
    Header: `Tillägg ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    id: `addons`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(
          curr,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo,
          useSpecifiedPeriod
        );

        return (
          acc +
          (curr.value >= 0 &&
          (curr.product_category === null ||
            !nonPositiveInclusion.includes(curr.product_category))
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  const baseRentinclusion = [
    PRODUCT_CATEGORIES.CATEGORY_BASE_RENT,
    PRODUCT_CATEGORIES.CATEGORY_RENT_INCREASE,
  ];
  result.push({
    Header: `Total bashyra ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    id: `baseRent`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total = filteredCosts.reduce((acc, curr) => {
        const months = getMonthsForCost(
          curr,
          useSpecifiedPeriod ? specifiedStartDate : contractFrom,
          useSpecifiedPeriod ? specifiedEndDate : contractTo,
          useSpecifiedPeriod
        );
        return (
          acc +
          (baseRentinclusion.includes(curr.product_category)
            ? (curr.value *
                curr.unit_amount *
                (showMonthlyCosts ? 1 : months)) /
              (useSqm ? totalArea : 1)
            : 0)
        );
      }, 0);

      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  result.push({
    Header: `Bashyra m. Index ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    id: `baseRentIndex`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      let indexValue = getIndexBetweenMonths(
        !!row.original.indexation,
        row.original.indexations,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );
      if (typeof indexValue === "string") {
        return <StatusLabel state={3}>{indexValue}</StatusLabel>;
      }

      const filteredCosts = getCostBetweenMonthDates(
        row.original.costs,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const monthsCount = useSpecifiedPeriod
        ? specifiedEndDate.diff(specifiedStartDate, "months") + 1
        : contractTo.diff(contractFrom, "months") + 1;

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      const total =
        filteredCosts.reduce((acc, curr) => {
          const months = getMonthsForCost(
            curr,
            useSpecifiedPeriod ? specifiedStartDate : contractFrom,
            useSpecifiedPeriod ? specifiedEndDate : contractTo,
            useSpecifiedPeriod
          );
          return (
            acc +
            (baseRentinclusion.includes(curr.product_category)
              ? (curr.value *
                  curr.unit_amount *
                  (showMonthlyCosts ? 1 : months)) /
                (useSqm ? totalArea : 1)
              : 0)
          );
        }, 0) +
        indexValue /
          (useSqm ? totalArea : 1) /
          (showMonthlyCosts ? monthsCount : 1);
      return <ToolTipCell text={Math.round(total).toLocaleString("sv")} />;
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  result.push({
    Header: `Indexuppräkning ${showMonthlyCosts ? "i SEK/mån" : "i SEK/år"} ${
      useSqm ? "(per m²)" : ""
    }`,
    id: `indexRent`,
    Cell: ({ row }) => {
      const { contractFrom, contractTo } = getContractFromAndTo(row.original);

      const { specifiedStartDate, specifiedEndDate } = getSpecifiedPeriodDates({
        start,
        end,
        contract: row.original,
      });

      const indexValue = getIndexBetweenMonths(
        !!row.original.indexation,
        row.original.indexations,
        useSpecifiedPeriod ? specifiedStartDate : contractFrom,
        useSpecifiedPeriod ? specifiedEndDate : contractTo
      );

      const months = useSpecifiedPeriod
        ? specifiedEndDate.diff(specifiedStartDate, "months") + 1
        : contractTo.diff(contractFrom, "months") + 1;

      const totalArea = row.original.total_area || 1; // 1 if no area to avoid Infinity

      if (typeof indexValue === "string") {
        return <StatusLabel state={3}>{indexValue}</StatusLabel>;
      }
      const calculatedIndexValue =
        indexValue / (useSqm ? totalArea : 1) / (showMonthlyCosts ? months : 1);
      return (
        <ToolTipCell
          text={Math.round(calculatedIndexValue).toLocaleString("sv")}
        />
      );
    },
    disableFilters: true,
    disableGlobalFilter: true,
    disableSortBy: true,
  });

  return result;
};

export default (
  startDate,
  endDate,
  withCosts,
  hasBillectaViewPermission,
  useSqm,
  showMonthlyCosts,
  useSpecifiedPeriod
) => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }) => {
        let label = row.original.state_display;
        const state = row.original.state;
        if ([6, 7].includes(state) && !row.original.closed_signed) {
          label += ` (Ej signerat)`;
        }
        const isDraft = row.original.draft;

        if (isDraft) {
          label = "Utkast";
        }

        return <StatusLabel state={state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Avtalsnummer",
      accessor: "id_number",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Kund",
      accessor: "opponent.str_representation",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.opponent} urlMethod={customerDetail} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Startdatum",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Slutdatum",
      accessor: (row, _) =>
        row.closed_date
          ? row.closed_date
          : row.renewed_to
          ? row.renewed_to
          : row.end_date,
      id: "end_date",
      Cell: ({ row }) => (
        <>
          {row.original.closed_date ? (
            <DateCell date={row.original.closed_date} />
          ) : row.original.renewed_to ? (
            <>
              <DateCell date={row.original.renewed_to} />
              <span> (Förlängt)</span>
            </>
          ) : (
            <DateCell date={row.original.end_date} />
          )}
        </>
      ),
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Uppsägningstid",
      accessor: "notify_interval",
      Cell: ({ row }) => (
        <DurationCell
          durationStamp={row.original.notify_interval}
          shadowDate={row.original.notify_time}
        />
      ),
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Förlängningstid",
      accessor: "renew_interval",
      Cell: ({ row }) => {
        const forever = row.original.renew_indefinetely;
        if (forever) {
          return "Tillsvidare";
        }
        return <DurationCell durationStamp={row.original.renew_interval} />;
      },
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Förvaltningsbolag",
      accessor: "company.str_representation",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.company} urlMethod={companyDetail} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  if (withCosts) {
    cols.splice(0, 0, {
      Header: "",
      id: "__expander",
      Cell: ({ row }) => {
        return (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <span>▼</span> : <span>{">"}</span>}
          </span>
        );
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    });
  }

  if (hasBillectaViewPermission) {
    cols.splice(3, 0, {
      Header: "Faktureringsstatus",
      accessor: "customer_invoicing_active",
      Cell: ({ value }) => (
        <StatusLabel
          state={
            value === true ? 1 : value === null ? 6 : value === false ? 3 : 10
          }
        >
          {value === true
            ? "Konfigurerad"
            : value === null
            ? "Ej konfigurerad"
            : value === false
            ? "Pausad"
            : "Okänd"}
        </StatusLabel>
      ),
      Filter: InvoiceFilter,
      filter: "textExact",
    });
  }

  if (withCosts)
    return cols.concat(
      getCostColumns(
        startDate,
        endDate,
        [
          "CATEGORY_MEMBER_FEE",
          "CATEGORY_PARKING",
          "CATEGORY_IMD",
          "CATEGORY_DEPOSIT",
        ],
        useSqm,
        showMonthlyCosts,
        useSpecifiedPeriod
      )
    );

  return cols;
};
