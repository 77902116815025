import { push } from "connected-react-router";

import {
  clearUserData,
  getIntercomHashKey,
  getId,
  getToken,
  performLogutRedirect,
  setCustomerData,
  setIntercomHashKey,
  setToken,
  setUserCreatedDate,
  setUserId,
  getUserCreatedDate,
  getCustomer,
} from "../../utils";
import { axiosInstance } from "../base/store/axios";
import { resetAllStates } from "../utils";
import { store } from "../store";

import constants from "./constants";
import * as services from "./services";
import { currentEnv, ENVIRONMENTS } from "../..";

export const localLogin = () => {
  return (dispatch) => {
    dispatch({
      type: constants.START_LOCAL_LOGIN,
    });

    const { token } = getToken();
    const localToken = token;

    const localUserId = getId();
    const hashKey = getIntercomHashKey();
    const userCreatedDate = getUserCreatedDate();
    const customer = getCustomer();

    if (localToken && localUserId) {
      dispatch({
        type: constants.LOCAL_LOGIN_SUCCESSFUL,
        payload: {
          token: localToken,
          userId: localUserId,
          intercom: {
            user_hash_key: hashKey,
            customer: customer,
            user_created_date: userCreatedDate,
          },
        },
      });
      dispatch(getUser(localUserId));
    } else {
      clearUserData();
      dispatch({
        type: constants.LOCAL_LOGIN_FAILED,
      });
      dispatch(resetAllStates());
    }
  };
};

export const login = (username, password, next, mfaCallback, mfaCode) => {
  return async (dispatch) => {
    dispatch({
      type: constants.START_LOGIN,
    });

    const body = { username, password, mfa_code: mfaCode };

    try {
      const { data, status } = await axiosInstance.post(
        "/accounts/auth/token_auth/",
        body
      );

      if (status === 206) {
        dispatch({
          type: constants.MFA_PENDING,
        });
        mfaCallback();
        return;
      }

      setToken(data.token);
      setUserId(data.user_id);
      setIntercomHashKey(data.intercom?.user_hash_key);
      setUserCreatedDate(data.intercom?.user_created_date);
      setCustomerData(data.intercom?.customer);

      const forceMfaSetting = data.enforce_mfa && !data.has_mfa;

      // is tenant
      if (data.user_type === 3) {
        window.location.replace("https://portal.pigello.se");
      }

      dispatch({
        type: constants.LOGIN_SUCCESSFUL,
        payload: {
          ...data,
        },
      });

      if (forceMfaSetting) {
        dispatch({
          type: constants.MFA_SETTING,
          payload: {
            show: true,
            enforce: true,
          },
        });
      }

      dispatch(getUser(data.user_id));

      dispatch(push(next || "/"));
    } catch (error) {
      dispatch({
        type: constants.LOGIN_FAILED,
        payload: {
          error: error?.response?.status,
        },
      });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    clearUserData();

    dispatch({
      type: constants.LOG_OUT,
    });

    // clear intercom data
    try {
      window.Intercom("shutdown");
    } catch (e) {
      console.error(e);
    }

    dispatch(resetAllStates());

    performLogutRedirect("logout");
  };
};

export const getUser = (userId) => {
  return async (dispatch) => {
    const user = await services.getUser(userId);

    if (user?.user_type === 3) {
      if (currentEnv === ENVIRONMENTS.PROD) {
        window.location.replace("https://portal.pigello.se");
      } else {
        window.location.replace("https://demo.portal.pigello.se");
      }
    }

    // FRESHDESK DATA
    if (window.fcWidget && user) {
      // To set unique user id in your system when it is available
      window.fcWidget.setExternalId(user.id);

      // To set user name
      window.fcWidget.user.setFirstName(user.str_representation);

      // To set user email
      window.fcWidget.user.setEmail(user.email);
      window.fcWidget.user.setPhone(user.phone);

      // To set user properties
      window.fcWidget.user.setProperties({
        userType: user.user_type_display,
        userName: user.username,
        phone: user.phone,
        firstName: user.first_name,
        lastName: user.last_name,
        isCompany: user.is_company,
        corporation: user.corporate_name,
        language: user.language_display,
        activeTo: user.active_to,
      });
    }

    dispatch({
      type: constants.GET_USER,
      payload: {
        user,
      },
    });
  };
};

export const impersonate = async (dispatch, username) => {
  const { data } = await axiosInstance.post(
    "/accounts/auth/impersonation_auth/",
    {
      username: username,
    }
  );

  dispatch(resetAllStates());

  setToken(data.token);
  setUserId(data.user_id);

  dispatch({
    type: constants.LOGIN_SUCCESSFUL,
    payload: {
      ...data,
    },
  });

  dispatch(getUser(data.user_id));

  dispatch(push("/"));
};

export const setBareMode = (bareMode) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_BARE_MODE,
      payload: {
        bareMode,
      },
    });
  };
};

const mobileMediaQuery = window.matchMedia("(max-width: 768px)");
const smallDesktopQuery = window.matchMedia("(max-width: 1200px)");
export const setScreenSize = () => {
  return async (dispatch) => {
    const isMobile = mobileMediaQuery.matches;
    const isSmallDesktop = smallDesktopQuery.matches;

    dispatch({
      type: constants.SET_SCREEN_SIZE,
      payload: {
        isMobile,
        isSmallDesktop,
      },
    });
  };
};

export const sendFeedback = ({ feedback, successCallback, errorCallback }) => {
  return async (dispatch) => {
    const state = store.getState();
    const data = {
      information: feedback.toString(),
      url: window.location.href,
      device: state.app.isMobile ? "mobile" : "desktop",
    };

    const success = await services.sendFeedback(data);

    if (success) {
      successCallback && successCallback();
    } else {
      errorCallback && errorCallback();
    }
  };
};
