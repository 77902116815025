import * as React from "react";
import { useCanUseScrive } from "../../../../store/editabledocs/hooks/retrieve";
import {
  constants,
  EDITABLE_DOC_CONTRACT_TYPES,
  handleContractEditableDocUrl,
  handleContractCancelledDocUrl,
  update,
  useEditabledoc,
  useEditabledocForm,
} from "../../../../store/editabledocs";
import PageSlider from "../../../../views/Layouts/PageSlider/PageSlider";
import { PrimaryButton, TextButton } from "../../../Forms/Base/Buttons";

import Pdf from "../../../Pdf/Pdf";
import {
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../sharedStyles";

import * as OverviewStyles from "../styles";
import { useDispatch } from "react-redux";
import {
  setActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import TableFileM2m from "../../../Forms/Base/Fields/TableFileM2m";
import { useHistory } from "react-router";
import { exportEditableDoc } from "../../../EditableDocBase/export";
import { InfoBox } from "../../../Displays";
import StandardModal from "../../../Modals/StandardModal";
import DocumentUploader from "../../../Forms/Base/Fields/DocumentUploader";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import { EditableDocOrNot } from "../../../Forms/Pickers";

const getEditableDocContractType = (contractConstants) => {
  if (contractConstants.STORE_NAME === "leaseContracts")
    return EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT;
};

export default ({ contract, contractConstants, contractUpdate }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [editabledoc] = useEditabledoc(contract?.editabledoc?.id);
  const [cancelleddoc] = useEditabledoc(contract?.cancelled_doc?.id);

  const [downloadTypePickerOpen, setDownloadTypePickerOpen] =
    React.useState(false);

  const editableDocContractType = getEditableDocContractType(contractConstants);

  useEditabledocForm(
    contract?.editabledoc ? "PATCH" : "POST",
    contract?.editabledoc?.id
  );

  const TABS = {
    DOC: "Avtal",
    CANCELATION_DOC: "Uppsägning",
  };
  if (!cancelleddoc) {
    delete TABS.CANCELATION_DOC;
  }
  const [selectedTab, setSelectedTab] = React.useState(
    !cancelleddoc ? "DOC" : "CANCELATION_DOC"
  );
  const [updateDocModalOpen, setUpdateDocModalOpen] = React.useState(false);
  const [updateCancelDocModalOpen, setUpdateCancelDocModalOpen] =
    React.useState(false);
  const [updateDocLoading, setUpdateDocLoading] = React.useState(false);
  const [docData, setDocData] = React.useState(null);
  const [cancelledDocData, setCancelledDocData] = React.useState(null);
  const [canUseScrive] = useCanUseScrive();
  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const scriveEvidenceUrl = editabledoc?.evidence?.get;
  const cancelDocScriveEvidenceUrl = cancelleddoc?.evidence?.get;

  React.useEffect(() => {
    if (editabledoc && selectedTab === "DOC") {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: editabledoc,
        })
      );
      dispatch(
        setActiveFormInstance({
          storeName: contractConstants.STORE_NAME,
          data: {
            editabledoc,
          },
        })
      );
    } else if (cancelleddoc && selectedTab === "CANCELATION_DOC") {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: cancelleddoc,
        })
      );

      dispatch(
        setActiveFormInstance({
          storeName: contractConstants.STORE_NAME,
          data: {
            cancelled_doc: cancelleddoc,
          },
        })
      );
    }
  }, [editabledoc, cancelleddoc, selectedTab]);

  const saveAttachmentUpdates = (newFiles) => {
    const cleanAttachmentsFormInstance = {
      id: instance.id,
      attachments: newFiles,
    };

    dispatch(
      update({
        id: selectedTab === "DOC" ? editabledoc.id : cancelleddoc.id,
        forceData: cleanAttachmentsFormInstance,
      })
    );
  };

  const printDocument = async ({ editableDoc, setStateMethod }) => {
    const data = await exportEditableDoc({ editableDoc, forShow: true });
    setStateMethod(data);
  };

  const handleDownload = async (editable) => {
    if (selectedTab === "DOC" && scriveEvidenceUrl && canUseScrive) {
      const a = document.createElement("a");
      a.href = scriveEvidenceUrl;
      a.download = `evidence_${contract?.str_representation}.pdf`;
      a.click();
    } else if (
      selectedTab === "CANCELATION_DOC" &&
      cancelDocScriveEvidenceUrl &&
      canUseScrive
    ) {
      const a = document.createElement("a");
      a.href = cancelDocScriveEvidenceUrl;
      a.download = `evidence_${contract?.str_representation}_cancel.pdf`;
      a.click();
    } else {
      await exportEditableDoc({
        editableDoc: selectedTab === "DOC" ? editabledoc : cancelleddoc,
        forShow: !editable,
        download: true,
      });
    }

    setDownloadTypePickerOpen(false);
  };

  const handleSelectDownloadType = (type) => {
    if (type === "view") {
      handleDownload(false);
    } else {
      handleDownload(true);
    }
  };

  React.useEffect(() => {
    if (selectedTab === "DOC") {
      if (scriveEvidenceUrl) return; // no need to print document if it has evidence

      if (docData || !editabledoc) {
        return;
      }
      printDocument({ editableDoc: editabledoc, setStateMethod: setDocData });
    } else if (selectedTab === "CANCELATION_DOC") {
      if (cancelledDocData || !cancelleddoc) {
        return;
      }
      printDocument({
        editableDoc: cancelleddoc,
        setStateMethod: setCancelledDocData,
      });
    }
  }, [editabledoc, cancelleddoc, docData, cancelledDocData, selectedTab]);

  const renderAttachments = () => {
    return (
      <TableFileM2m
        extraStyles={{ maxWidth: "100%" }}
        storeName={storeName}
        method={contract?.editabledoc ? "PATCH" : "POST"}
        fieldKey={"attachments"}
        title="Bilagor"
        onChangeCallback={saveAttachmentUpdates}
      />
    );
  };

  const showDownloadButton =
    (selectedTab === "DOC" &&
      ((canUseScrive && scriveEvidenceUrl) || docData)) ||
    (selectedTab === "CANCELATION_DOC" &&
      ((canUseScrive && cancelDocScriveEvidenceUrl) || cancelledDocData));

  const showHandleDocButton =
    selectedTab === "DOC" &&
    !contract?.pre_existing &&
    editabledoc?.status === "preparation";

  const showHandleCancellationDocButton =
    selectedTab === "CANCELATION_DOC" &&
    contract?.cancelled_doc?.id &&
    contract?.uses_cancellation_e_signing &&
    cancelleddoc?.status === "preparation";

  const showSigningWarning =
    !showHandleDocButton &&
    editabledoc &&
    editabledoc.has_scrive &&
    editabledoc.status !== "signed" &&
    editabledoc.status !== "sealed" &&
    selectedTab === "DOC";

  const showCancellationSigningWarning =
    !showHandleCancellationDocButton &&
    cancelleddoc &&
    cancelleddoc.has_scrive &&
    cancelleddoc.status !== "signed" &&
    cancelleddoc.status !== "sealed" &&
    selectedTab === "CANCELATION_DOC";

  // only allow quick swap of doc for preexisting documents
  const showChangeDocumentButton =
    selectedTab === "DOC" &&
    contract?.pre_existing &&
    contractConstants &&
    contractUpdate;

  const showChangeCancelDocumentButton =
    false &&
    selectedTab === "CANCELATION_DOC" &&
    !contract?.uses_cancellation_e_signing; // disabled, cant change doc when signed

  const hasEditableDocScriveEvidence =
    selectedTab === "DOC" && scriveEvidenceUrl && canUseScrive;

  const hasCancelledDocScriveEvidence =
    selectedTab === "CANCELATION_DOC" &&
    cancelDocScriveEvidenceUrl &&
    canUseScrive;

  return (
    <>
      <EditableDocOrNot
        isOpen={downloadTypePickerOpen}
        closeFunction={() => setDownloadTypePickerOpen(false)}
        onSelect={handleSelectDownloadType}
      />

      {showChangeDocumentButton && (
        <StandardModal
          isOpen={updateDocModalOpen}
          closeFunction={() => setUpdateDocModalOpen(false)}
          withActionBar
          title="Uppdatera dokument"
          saveFunction={() => {
            setUpdateDocLoading(true);
            dispatch(
              contractUpdate({
                id: contract?.id,
                successCallback: () => {
                  setUpdateDocLoading(false);
                  setUpdateDocModalOpen(false);
                },
                errorCallback: () => setUpdateDocLoading(false),
              })
            );
          }}
        >
          {updateDocLoading && <OverlaySpinner />}
          <DocumentUploader
            title="Dokument för avtal"
            description="Ladda upp det existerande avtalet om det finns ett sådant"
            storeName={contractConstants?.STORE_NAME}
            fieldKey={"editabledoc.doc"}
            method={"PATCH"}
          />
        </StandardModal>
      )}

      {showChangeCancelDocumentButton && (
        <StandardModal
          isOpen={updateCancelDocModalOpen}
          closeFunction={() => setUpdateCancelDocModalOpen(false)}
          withActionBar
          title="Uppdatera dokument för uppsägning"
          saveFunction={() => {
            setUpdateDocLoading(true);
            dispatch(
              contractUpdate({
                id: contract?.id,
                successCallback: () => {
                  setUpdateDocLoading(false);
                  setUpdateDocModalOpen(false);
                },
                errorCallback: () => setUpdateDocLoading(false),
              })
            );
          }}
        >
          {updateDocLoading && <OverlaySpinner />}
          <DocumentUploader
            title="Dokument för uppsägning"
            description="Ladda upp det existerande avtalet om det finns ett sådant"
            storeName={contractConstants?.STORE_NAME}
            fieldKey={"cancelled_doc.doc"}
            method={"PATCH"}
          />
        </StandardModal>
      )}

      <DetailPageBoxFlexWrapper>
        <DetailPageBox style={{ width: "70%", maxWidth: "70%" }}>
          {(showSigningWarning || showCancellationSigningWarning) && (
            <InfoBox
              boxTheme="info"
              title="Pågående signeringsprocess"
              text="Dokumentet kan ej redigeras under pågående signeringsprocess. För att göra ändringar, avbryt signeringen och starta sedan om den för att skicka ut dokumentet för signering igen."
            />
          )}
          <OverviewStyles.OverviewTitleWrapper>
            <OverviewStyles.OverviewTitleWithSubtitleWrapper>
              <OverviewStyles.OverviewTitle>
                Dokument
              </OverviewStyles.OverviewTitle>

              {showDownloadButton && (
                <OverviewStyles.OverviewSubtitle>
                  <TextButton
                    title={`Ladda ner${
                      selectedTab === "DOC" && scriveEvidenceUrl
                        ? " bevis"
                        : selectedTab === "CANCELATION_DOC" &&
                          cancelDocScriveEvidenceUrl
                        ? " bevis"
                        : ""
                    }`}
                    iconType="download"
                    clicked={() => {
                      if (
                        hasEditableDocScriveEvidence ||
                        hasCancelledDocScriveEvidence
                      ) {
                        handleDownload();
                      } else {
                        setDownloadTypePickerOpen(true);
                      }
                    }}
                  />
                </OverviewStyles.OverviewSubtitle>
              )}
            </OverviewStyles.OverviewTitleWithSubtitleWrapper>
            <OverviewStyles.ButtonWrapper>
              {showHandleDocButton && (
                <PrimaryButton
                  extraStyle={{ marginRight: cancelleddoc ? 12 : 0 }}
                  title="Hantera dokument"
                  clicked={() =>
                    push(
                      handleContractEditableDocUrl({
                        id: contract.id,
                        contractType: editableDocContractType,
                      })
                    )
                  }
                />
              )}

              {showHandleCancellationDocButton && (
                <PrimaryButton
                  extraStyle={{ marginRight: 12 }}
                  title="Hantera dokument"
                  clicked={() =>
                    push(
                      handleContractCancelledDocUrl({
                        id: contract.id,
                        contractType: editableDocContractType,
                      })
                    )
                  }
                />
              )}

              {showChangeDocumentButton && selectedTab === "DOC" && (
                <PrimaryButton
                  extraStyle={{ marginRight: cancelleddoc ? 12 : 0 }}
                  title="Uppdatera dokument"
                  clicked={() => setUpdateDocModalOpen(true)}
                />
              )}

              {showChangeCancelDocumentButton &&
                selectedTab === "CANCELATION_DOC" && (
                  <PrimaryButton
                    extraStyle={{ marginRight: cancelleddoc ? 12 : 0 }}
                    title="Uppdatera dokument"
                    clicked={() => setUpdateCancelDocModalOpen(true)}
                  />
                )}

              {cancelleddoc && (
                <PageSlider
                  TABS={TABS}
                  selectedTab={selectedTab}
                  onTabSelected={(tab) => setSelectedTab(tab)}
                />
              )}
            </OverviewStyles.ButtonWrapper>
          </OverviewStyles.OverviewTitleWrapper>

          <InnerBox>
            {selectedTab === "DOC" && !scriveEvidenceUrl && (
              <Pdf uint8={docData} />
            )}
            {selectedTab === "DOC" && scriveEvidenceUrl && (
              <Pdf url={scriveEvidenceUrl} />
            )}

            {selectedTab === "CANCELATION_DOC" &&
              !cancelDocScriveEvidenceUrl && <Pdf uint8={cancelledDocData} />}

            {selectedTab === "CANCELATION_DOC" &&
              cancelDocScriveEvidenceUrl && (
                <Pdf url={cancelDocScriveEvidenceUrl} />
              )}
          </InnerBox>
        </DetailPageBox>

        <DetailPageBox flexStart style={{ width: "30%", minWidth: "300px" }}>
          {renderAttachments()}
        </DetailPageBox>
      </DetailPageBoxFlexWrapper>
    </>
  );
};
