import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "./styles";

// store, state
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import DescriptionToolTip from "../Layout/DescriptionToolTip";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    validation,
    instructionsKey,
    extraStyles = {},
    noTitle,
    noMargin,
    rowSize,
    disabled,
    children,
    title,
    subtitle,
    description,
    changeCallback,
    placeholder,
    manualInstructions,
  }) => {
    const dispatch = useDispatch();

    let instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    instructions = instructions ?? manualInstructions;

    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const onChange = (data) => {
      let formattedData = parseFloat(data);

      if (data === undefined || data === null || isNaN(formattedData)) {
        formattedData = instructions._allowNull
          ? null
          : instructions._required
          ? 0
          : "";
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );

      if (changeCallback) {
        changeCallback(data);
      }
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    return (
      <SC.InputSpacing {...{ noMargin }} style={{ ...extraStyles }}>
        {!noTitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.InputFieldTitle>
              {title || instructions._label}
              {instructions._required ? "*" : ""}
            </SC.InputFieldTitle>
            {(instructions._helpText || description) && (
              <DescriptionToolTip
                description={description || instructions._helpText}
              />
            )}
          </div>
        )}
        {subtitle && (
          <SC.InputFieldDescription>{subtitle}</SC.InputFieldDescription>
        )}

        <SC.InputFieldWrapper error={!!error}>
          <SC.InputField
            value={value || null}
            onChange={({ target: { value } }) => onChange(value)}
            id={instructions._internalId}
            required={instructions._required}
            placeholder={placeholder || ""}
            type="number"
            disabled={disabled}
            {...{ rowSize }}
          />
        </SC.InputFieldWrapper>
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
        {children && children}
      </SC.InputSpacing>
    );
  }
);
