import styled, { css } from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
`;

export const GridItem = styled.div`
  position: relative;
  background-color: white;
  min-height: 160px;
  padding: 12px;
  padding-bottom: 46px;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;

      & > * > button {
        pointer-events: none;
      }
    `}
`;

export const GridItemHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const GridItemFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: ${(p) => p.theme.borders.standard};
`;

export const GridItemLogo = styled.div`
  height: 50px;
  width: 50px;
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border-radius: 50%;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.image});
  margin-right: 8px;
`;

export const GridItemTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const GridItemTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  line-height: 110%;
`;
export const GridItemSubtitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
`;

export const GridItemDescription = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  font-weight: ${(p) => p.theme.fontWeights.body};
  text-overflow: ellipsis;
`;
