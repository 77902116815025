import * as React from "react";
import * as SC from "./styles";

import Logo from "./Logo";
import accountBalanceIcon from "../../../assets/svg/material/account_balance_white.svg";

import documentIcon from "../../../assets/svg/material/document_white.svg";
import managementIcon from "../../../assets/svg/material/management_key_white.svg";
import workIcon from "../../../assets/svg/material/work_white.svg";

import dotEmptyIcon from "../../../assets/svg/material/dot_black_empty.svg";
import dotFilledIcon from "../../../assets/svg/material/dot_white_filled.svg";
import taskIcon from "../../../assets/svg/material/task_white.svg";

import devIcon from "../../../assets/svg/material/integration_instructions_white.svg";
import adminIcon from "../../../assets/svg/material/lock_white.svg";

import { useFrequentPermissions } from "../../../store/base";
import { useBillectaEventsPaginationCount } from "../../../store/billectaEvents";
import { REPORT_EVENTS_CATEGORIES } from "../../../store/billectaEvents/hooks/retrieve";
import { useFilteredCompanies } from "../../../store/companies";
import MiniLogo from "./MiniLogo";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const contractChapterItems = [
  "/management-contracts",
  "/documents",
  "/templates",
];

export default ({ sideBarOpen, setSideBarOpen }) => {
  const [open, setOpen] = React.useState("");
  const { location } = useHistory();

  const isStaff = useSelector((state) => state.app.user?.is_staff);
  const { pathname } = location;

  const [companies] = useFilteredCompanies();
  const billectaIds = companies
    ?.map((c) => c.billecta_id)
    ?.filter((id) => !!id);

  const { hasBillectaViewPermission } = useFrequentPermissions();

  const [billectaEventsCount] = useBillectaEventsPaginationCount({
    read: false,
    relatedCreditorIds: billectaIds?.length ? billectaIds : [],
    categories: [
      REPORT_EVENTS_CATEGORIES.INVOICE_UNDELIVERED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_APPROVAL_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_CHANGED,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL,
      REPORT_EVENTS_CATEGORIES.AUTOGIRO_PAYMENT_FAIL_RETRY,
    ],
  });

  React.useEffect(() => {
    if (sideBarOpen) return;

    setOpen(null);
  }, [pathname]);

  const getIsActive = (match, exact) => {
    if (exact) return window.location.pathname === match;
    return window.location.pathname?.split("/")?.includes(match);
  };

  const handleToggle = (key) => {
    if (open === key) {
      setOpen("");
    } else {
      setOpen(key);
    }
  };

  const getOuterIsActive = (type) => {
    if (sideBarOpen) return;

    switch (type) {
      case "CONTRACT":
        return contractChapterItems.some((i) =>
          window.location.pathname.includes(i)
        );

      default:
        return false;
    }
  };

  React.useEffect(() => {
    if (!sideBarOpen) {
      setOpen(null);
      return;
    }
    contractChapterItems.forEach((i) => {
      if (window.location.pathname.includes(i)) {
        setOpen("contracts");
      }
    });
  }, [sideBarOpen]);

  const renderContent = () => {
    return (
      <>
        <SC.Chapter
          closedMode={!sideBarOpen}
          style={{ marginTop: sideBarOpen ? "24px" : "12px" }}
        >
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/"
            active={getIsActive("/", true)}
            icon={taskIcon}
          >
            {sideBarOpen && "Min arbetsdag"}
          </SC.SidebarItem>
        </SC.Chapter>

        <SC.Chapter closedMode={!sideBarOpen}>
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/companies"
            active={getIsActive("companies")}
            icon={workIcon}
          >
            {sideBarOpen && "Förvaltningsbolag"}
          </SC.SidebarItem>

          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/customers"
            active={getIsActive("customers")}
            icon={managementIcon}
          >
            {sideBarOpen && "Förvaltningsuppdrag"}
          </SC.SidebarItem>

          <SC.SidebarToggleItem
            closedMode={!sideBarOpen}
            onClick={() => handleToggle("contracts")}
            isOpen={open === "contracts"}
            icon={documentIcon}
            active={getOuterIsActive("CONTRACT")}
          >
            {sideBarOpen && "Avtal & Dokument"}
          </SC.SidebarToggleItem>
          {open === "contracts" && (
            <>
              <SC.SidebarItem
                closedModeInner={!sideBarOpen}
                to="/management-contracts"
                active={getIsActive("management-contracts")}
                inner
                icon={
                  getIsActive("management-contracts")
                    ? dotFilledIcon
                    : dotEmptyIcon
                }
              >
                Förvaltningsavtal
              </SC.SidebarItem>

              <SC.SidebarItem
                to="/documents"
                active={getIsActive("documents")}
                inner
                closedModeInner={!sideBarOpen}
                icon={getIsActive("documents") ? dotFilledIcon : dotEmptyIcon}
              >
                Dokument
              </SC.SidebarItem>

              <SC.SidebarItem
                to="/templates"
                active={getIsActive("templates")}
                inner
                closedModeInner={!sideBarOpen}
                last
                icon={getIsActive("templates") ? dotFilledIcon : dotEmptyIcon}
              >
                Avtalsmallar
              </SC.SidebarItem>
            </>
          )}
        </SC.Chapter>

        <SC.Chapter closedMode={!sideBarOpen}>
          {hasBillectaViewPermission && (
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/rental-notifications"
              active={getIsActive("rental-notifications")}
              icon={accountBalanceIcon}
            >
              {!sideBarOpen && billectaEventsCount > 0 && (
                <SC.ActionDot>
                  {billectaEventsCount > 99 ? "99+" : billectaEventsCount}
                </SC.ActionDot>
              )}
              {sideBarOpen && (
                <>
                  Fakturering{" "}
                  {billectaEventsCount ? `(${billectaEventsCount})` : ""}
                </>
              )}
            </SC.SidebarItem>
          )}
        </SC.Chapter>

        <SC.Chapter closedMode={!sideBarOpen}>
          <SC.SidebarItem
            closedMode={!sideBarOpen}
            to="/integrations"
            active={getIsActive("integrations")}
            icon={devIcon}
          >
            {sideBarOpen && "Integrationer"}
          </SC.SidebarItem>
        </SC.Chapter>

        {isStaff && (
          <SC.Chapter closedMode={!sideBarOpen}>
            <SC.SidebarItem
              closedMode={!sideBarOpen}
              to="/admin"
              active={getIsActive("admin")}
              icon={adminIcon}
            >
              {sideBarOpen && "Admin"}
            </SC.SidebarItem>
          </SC.Chapter>
        )}
      </>
    );
  };

  const renderOpenClose = () => {
    if (!sideBarOpen) {
      return (
        <div style={{ width: 25, margin: "4px auto" }}>
          <SC.ExpandMenuIcon onClick={() => setSideBarOpen(true)} />
        </div>
      );
    } else {
      return (
        <div style={{ position: "absolute", right: 6, top: 76 }}>
          <SC.CloseMenuIcon onClick={() => setSideBarOpen(false)} />
        </div>
      );
    }
  };

  return (
    <SC.MainWrapper open={sideBarOpen}>
      {sideBarOpen ? <Logo /> : <MiniLogo />}

      {renderOpenClose()}

      {renderContent()}
    </SC.MainWrapper>
  );
};
