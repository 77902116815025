import * as React from "react";
import * as SC from "./styles";
import { useSpring, config } from "@react-spring/core";

export default ({ chapter, chapterIndex, chapterCount, isMain, hasError }) => {
  const { main } = useSpring({
    config: { duration: 300, ...config.gentle },
    from: { main: 0 },
    main: isMain ? 1 : 0,
  });

  return (
    <SC.FlowFormChapterTitle
      style={{
        color: hasError ? "#ea5050" : main.to([0, 1], ["#444A55", "#5165FB"]),
      }}
    >
      {chapter.title} ({`${chapterIndex + 1}/${chapterCount}`})
    </SC.FlowFormChapterTitle>
  );
};
