import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import CompanyModal from "../../../components/Forms/Company/ChapterForm/ModalForm";
import { useParams } from "react-router";
import { useCompany, constants } from "../../../store/companies";
import { detailUrl } from "../../../store/users";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  setActiveFormInstance,
  usePermissionCheck,
} from "../../../store/base";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { useFilteredNotes } from "../../../store/notes";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { LinkedObject } from "../../../components/Displays";

function CompanyOverview() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);
  const [company] = useCompany(companyId);
  const canEdit = usePermissionCheck("change_can_company");

  const notesQ = buildQueryString({
    id__in: company?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const onEdit = () => {
    dispatch(
      setActiveFormInstance({
        storeName: constants.STORE_NAME,
        data: company,
      })
    );

    setEditOpen(true);
  };

  return (
    <>
      <CompanyModal
        method="PATCH"
        isOpen={editOpen}
        onCheckout={() => setEditOpen(false)}
        id={companyId}
      />

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox style={{ width: "70%", alignSelf: "flex-start" }}>
            <OverviewTitleWrapper>
              <OverviewTitle>Översikt</OverviewTitle>

              {canEdit && <PrimaryButton title="Redigera" clicked={onEdit} />}
            </OverviewTitleWrapper>

            <DetailInfo infoObj={getInfoObj(company)} />
          </DetailPageBox>

          <DetailPageBox style={{ width: "30%", alignSelf: "flex-start" }}>
            <Notes
              notes={notes}
              contentType="standard.company"
              objectId={companyId}
            />
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj(company) {
  const infoObj = {
    Bolagsinfo: [
      {
        title: "Namn",
        value: company?.str_representation,
      },
      {
        title: "Orgnr",
        value: company?.orgnr || "-",
      },
      {
        title: "Bolagsadress",
        value: company?.address?.base?.split(",")?.[0] || "-",
      },
      {
        title: "Ansvarig användare",
        value: <LinkedObject obj={company?.owner} urlMethod={detailUrl} />,
      },
    ],
  };

  return infoObj;
}

export default CompanyOverview;
