import styled from "styled-components";

export const ReportHeader = styled.div`
  width: 100%;
  padding: 8px;
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

export const ReportHeaderTitle = styled.div`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary};
  font-size: 0.9rem;
  margin-bottom: 6px;
`;

export const ReportHeaderTitleInfo = styled.div`
  font-weight: 500;
  color: ${(p) => p.theme.colors.primary};
  font-size: 0.7rem;
`;

export const ReportInfo = styled.div`
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  &:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const ReportInfoTitle = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
`;

export const NotificationItem = styled.div`
  font-weight: 400;
  color: black;
  font-size: 0.8rem;
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
  padding: 6px;
  cursor: pointer;
  &:nth-last-child(2) {
    border: none;
  }
`;

export const DetailButton = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: ${(p) => p.theme.colors.primaryBlue};
  color: #fff;
  margin: 8px;
  border-radius: 30px;
`;
