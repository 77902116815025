import * as React from "react";

import { constants } from "../../../../../store/managementContracts";

import {
  constructStrRep as constructCompanyStrRep,
  useCompanyPagination,
  performFilter as performCompanyFilter,
  constants as companyConstants,
} from "../../../../../store/companies";
import companyListDefs from "../../../../Lists/Companies/listDefs";

// style, design

import { FormAreaTitle } from "../../../Base/Chapters/styles";
import { TableSelect } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Förvaltningsbolag på avtal</FormAreaTitle>

      <TableSelect
        storeName={storeName}
        method={method}
        fieldKey="company"
        constructStrRep={constructCompanyStrRep}
        tableStoreName={companyConstants.STORE_NAME}
        listDefs={companyListDefs}
        paginationMethod={useCompanyPagination}
        extraColumnsDefsArgs={[]}
        performFilter={performCompanyFilter}
        fieldTitle={"Förvaltningsbolag"}
        title="Välj förvaltningsbolag..."
        modalInModal
      />
    </>
  );
};
