import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import { useParams } from "react-router";
import { useCompany } from "../../../store/companies";
import { useInvoicingErrorPaginationCount } from "../../../store/invoicingErrors/hooks/retrieve";
import InvoicingErrorTable from "../../../components/Lists/InvoicingErrors/FullTable";
import { buildQueryString } from "../../../store/base";
import { NotificationDot } from "../../../components/Dashboard/styles";

export default function CompanyInvoicingErrors() {
  const { companyId } = useParams();

  const [company] = useCompany(companyId);

  const [errorCount] = useInvoicingErrorPaginationCount({
    filters: {
      billecta_creditor_id: company?.billecta_id,
    },
  });

  const errorQuery = buildQueryString({
    billecta_creditor_id: company?.billecta_id,
  });
  const errorFilter = (obj) => {
    return (
      obj.billecta_creditor_id === company?.billecta_id ||
      obj.error?.billecta_creditor_id === company?.billecta_id
    );
  };

  return (
    <>
      <DetailInnerWrapper>
        {errorCount > 0 && (
          <DetailPageBox error>
            <OverviewTitleWrapper>
              <OverviewTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
                small
              >
                Felmeddelanden under bolag
                <NotificationDot>{errorCount}</NotificationDot>
              </OverviewTitle>
            </OverviewTitleWrapper>

            <InvoicingErrorTable
              hideFilters
              hideExport
              hideSearch
              hideColumns
              hideTitle
              persistantFilterMethod={errorFilter}
              persistantQueryString={errorQuery}
            />
          </DetailPageBox>
        )}
      </DetailInnerWrapper>
    </>
  );
}
