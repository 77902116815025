import { PDFDocument, rgb } from "pdf-lib";
import fileDownload from "js-file-download";

import { FIELD_TYPES, getFieldType } from "./utils";

export const exportEditableDoc = async ({
  editableDoc,
  forShow,
  download = false,
}) => {
  try {
    // prepare some data
    const url = editableDoc?.doc?.get;

    const b64Prefix = "data:application/pdf;base64,";
    let b64 = editableDoc?.docData_;
    if (b64 && !b64.includes(b64Prefix)) {
      b64 = b64Prefix + b64;
    }

    const data = b64 || url;
    const hasData = Boolean(data);

    if (!hasData && forShow) {
      return null;
    }

    const loadForPrint = async () => {
      if (!hasData) {
        throw Error("Don't call this function without any pdf data dude");
      }

      if (url) {
        const arrBuff = await fetch(url).then((res) => res.arrayBuffer());
        return await PDFDocument.load(arrBuff);
      } else {
        const dataUri = "data:application/pdf;base64," + b64;
        return await PDFDocument.load(dataUri);
      }
    };

    const prepareFields = (pdf) => {
      // flattens out the party->fields->placements structure into fields with placements
      let fields = [];
      (editableDoc?.parties || []).forEach((p) => {
        (p?.fields || []).forEach((f) => {
          (f?.placements || []).forEach((plc) => {
            const pageNumber = plc?.page;
            const xrel = plc?.xrel;
            const yrel = plc?.yrel;
            const wrel = plc?.wrel;
            const hrel = plc?.hrel;
            const fsrel = plc?.fsrel;

            const plcValue = plc?.value;

            if (!pageNumber || !xrel || !yrel || !wrel || !hrel) {
              return;
            }

            if (!plcValue && getFieldType(f.kind) === FIELD_TYPES.RADIO) {
              return;
            }

            const page = pdf.getPage(pageNumber - 1);
            if (!page) {
              return;
            }

            const { width, height } = page.getSize();
            const w = wrel * width;
            const x = xrel * width;

            const h = hrel * height;
            // coordinate system for pdf is conjugate of normal
            const y = height - yrel * height - h;

            const fs = fsrel * width;

            const flattendField = {
              ...f,
              placements: undefined,
              page,
              x,
              y,
              w,
              h,
              fs,
              plcValue,
            };
            fields.push(flattendField);
          });
        });
      });

      return fields;
    };

    const decoratePdf = (pdf) => {
      pdf.setTitle(editableDoc?.title || "Avtal");
      pdf.setSubject(editableDoc?.title || "Avtal");
      pdf.setKeywords([
        "Pigello",
        "Pigello Solutions",
        "Pigello Property Solutions",
        "Pigello Fastighetssystem",
        "Avtal",
        "Contract",
      ]);
      pdf.setProducer("Pigello Property Systems");
      pdf.setCreator("Pigello Property Systems");
    };

    const printDocument = async () => {
      const pdf = await loadForPrint();

      const form = pdf.getForm();
      const fields = prepareFields(pdf);

      fields.forEach((field, index) => {
        const fieldType = getFieldType(field.kind);
        let component;
        const name = `${JSON.stringify({
          ...field,
          page: undefined,
        })}_${index}`;
        switch (fieldType) {
          case FIELD_TYPES.CHECKBOX:
            try {
              component = form.createCheckBox(name);
            } catch (error) {
              component = form.createCheckBox(`${name}_${name}`);
            }
            if (field.is_obligatory) {
              component.enableRequired();
            }

            if (forShow) {
              component.enableReadOnly();
            }

            // may need adustments if scrive adjustments are added
            component.addToPage(field.page, {
              x: field.x - 5,
              y: field.y,
              width: 10,
              height: 10,
              backgroundColor: rgb(1, 1, 1),
              borderWidth: 0,
            });
            if (field.is_checked) {
              component.check();
            }

            break;

          case FIELD_TYPES.RADIO:
            try {
              component = form.getRadioGroup(field.name);
            } catch (error) {
              component = form.createRadioGroup(field.name);
            }

            if (field.is_obligatory) {
              component.enableRequired();
            }

            if (forShow) {
              component.enableReadOnly();
            }

            component.addOptionToPage(field.plcValue, field.page, {
              x: field.x,
              y: field.y,
              width: field.w,
              height: field.h,
              backgroundColor: rgb(1, 1, 1),
              borderWidth: 0,
            });
            if (field.value === field.plcValue) {
              component.select(field.plcValue);
            }
            break;

          default:
            if (!forShow) {
              try {
                component = form.createTextField(name);
              } catch (error) {
                component = form.createTextField(`${name}_${name}`);
              }

              if (field.is_obligatory) {
                component.enableRequired();
              }
              if (field.value) {
                component.setText(field.value || "");
              }
              component.addToPage(field.page, {
                x: field.x,
                y: field.y,
                width: field.w,
                height: field.h,
                backgroundColor: rgb(1, 1, 1),
                borderWidth: 0,
              });
            } else {
              field.page.drawText(field.value || "", {
                x: field.x,
                y: field.y + (field.h - field.fs),
                size: field.fs,
                backgroundColor: rgb(1, 1, 1),
                borderWidth: 0,
              });
            }

            break;
        }
      });

      decoratePdf(pdf);

      const pdfBytes = await pdf.save();
      if (forShow && !download) {
        return pdfBytes;
      }

      fileDownload(pdfBytes, "download.pdf");
      return pdfBytes;
    };

    return await printDocument();
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
