import * as React from "react";
import { TextButton } from "../../Forms/Base/Buttons";
import NonConnectedSelect from "../../Forms/Base/Old/NonConnected/NonConnectedSelect";
import { lightOrDark } from "../utils";
import * as SC from "./styles";

const getFieldSuggestions = ({
  currentSelectedParty,
  baseFieldKey,
  baseFieldName,
  apartmentIndex,
  industrialPremisesIndex,
}) => {
  const fields = [];

  if (baseFieldKey === "contract") {
    fields.push({
      name: "Fullständigt namn hyresvärd",
      kind: "landlord_full_name",
      is_obligatory: false,
      value: `${
        currentSelectedParty?.fields?.find((f) => f.kind === "first_name")
          ?.value
      } ${
        currentSelectedParty?.fields?.find((f) => f.kind === "last_name")?.value
      }`,
    });

    fields.push({
      name: "Fastighetsbeteckning",
      value: "Fastighetsbeteckning",
      kind: "contract_real_estate_key",
      is_obligatory: false,
    });

    fields.push({
      name: "Avtals-ID",
      value: "Avtals-ID",
      kind: "contract_id",
      is_obligatory: false,
    });

    fields.push({
      name: "Startdatum",
      value: "Startdatum",
      kind: "contract_start_date",
      is_obligatory: false,
    });

    fields.push({
      name: "Slutdatum",
      value: "Slutdatum",
      kind: "contract_end_date",
      is_obligatory: false,
    });

    fields.push({
      name: "Uppsägningstid",
      value: "Uppsägningstid",
      kind: "contract_notify_interval",
      is_obligatory: false,
    });

    fields.push({
      name: "Total area",
      value: "Total area",
      kind: "total_area",
      is_obligatory: false,
    });

    fields.push({
      name: "Förlängningstid",
      value: "Förlängningstid",
      kind: "contract_renew_interval",
      is_obligatory: false,
    });

    fields.push({
      name: "Hyra per månad",
      value: "Hyra per månad",
      kind: "contract_total_rent_month",
      is_obligatory: false,
    });

    fields.push({
      name: "Hyra per år",
      value: "Hyra per år",
      kind: "contract_total_rent_year",
      is_obligatory: false,
    });
  }

  const indexKey =
    baseFieldKey === "apartment" ? apartmentIndex : industrialPremisesIndex;

  if (baseFieldKey === "apartment" || baseFieldKey === "industrialPremises") {
    fields.push({
      name: `Area ${baseFieldName} ${indexKey + 1}`,
      value: `Area ${baseFieldName} ${indexKey + 1}`,
      kind: `area_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Våning ${baseFieldName} ${indexKey + 1}`,
      value: `Våning ${baseFieldName} ${indexKey + 1}`,
      kind: `floor_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Kategori ${baseFieldName} ${indexKey + 1}`,
      value: `Kategori ${baseFieldName} ${indexKey + 1}`,
      kind: `category_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Objektsnummer ${baseFieldName} ${indexKey + 1}`,
      value: `Objektsnummer ${baseFieldName} ${indexKey + 1}`,
      kind: `object_id_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    if (baseFieldKey === "apartment") {
      fields.push({
        name: `Lägenhetsnummer ${baseFieldName} ${indexKey + 1}`,
        value: `Lägenhetsnummer ${baseFieldName} ${indexKey + 1}`,
        kind: `apartment_id_${baseFieldKey}_${indexKey}`,
        is_obligatory: false,
      });
    }

    fields.push({
      name: `Adress ${baseFieldName} ${indexKey + 1}`,
      value: `Adress ${baseFieldName} ${indexKey + 1}`,
      kind: `address_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Postnr. ${baseFieldName} ${indexKey + 1}`,
      value: `Postnr. ${baseFieldName} ${indexKey + 1}`,
      kind: `zip_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Postort ${baseFieldName} ${indexKey + 1}`,
      value: `Postort ${baseFieldName} ${indexKey + 1}`,
      kind: `city_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });

    fields.push({
      name: `Kommun ${baseFieldName} ${indexKey + 1}`,
      value: `Kommun ${baseFieldName} ${indexKey + 1}`,
      kind: `municipality_${baseFieldKey}_${indexKey}`,
      is_obligatory: false,
    });
  }

  if (baseFieldKey === "invoicing") {
    fields.push({
      name: `Bankgironummer`,
      value: `Bankgironummer`,
      kind: `bankgiro`,
      is_obligatory: false,
    });

    fields.push({
      name: `Autogironummer`,
      value: `Autogironummer`,
      kind: `autogiro`,
      is_obligatory: false,
    });
  }
  // only suggest those that have not yet been added
  return fields.filter((f) => {
    const fieldWithMatchingName = currentSelectedParty.fields?.find(
      (existingField) => existingField.name === f.name
    );

    return !fieldWithMatchingName;
  });
};

export default ({
  currentSelectedParty,
  isLandlord,
  selectedFields,
  currentSelectedPartyIndex,
  onAddNewField,
}) => {
  const [baseFieldKey, setBaseFieldKey] = React.useState(null);
  const [baseFieldName, setBaseFieldName] = React.useState(null);
  const [apartmentIndex, setApartmentIndex] = React.useState(0);
  const [industrialPremisesIndex, setIndustrialPremisesIndex] =
    React.useState(0);

  // if not landlord we can only add these fields
  if (!isLandlord) {
    return (
      <SC.Field
        {...{
          selected: !!selectedFields?.find(
            (f) => f.name === "Fullständigt namn"
          ),
        }}
        key={"full_name"}
        style={{
          backgroundColor: currentSelectedParty?._color,
          color: currentSelectedParty?._color
            ? lightOrDark(currentSelectedParty?._color) === "light"
              ? "black"
              : "white"
            : "white",
        }}
        onClick={() =>
          onAddNewField({
            partyIndex: currentSelectedPartyIndex,
            kind: "full_name",
            name: "Fullständigt namn",
            value: `${
              currentSelectedParty?.fields?.find((f) => f.kind === "first_name")
                .value
            } ${
              currentSelectedParty?.fields?.find((f) => f.kind === "last_name")
                .value
            }`,
            is_obligatory: false,
          })
        }
      >
        Fullständigt namn
      </SC.Field>
    );
  }

  if (!baseFieldKey) {
    return (
      <div>
        <div style={{ marginBottom: 12 }}>
          Välj kategori för föreslagna fält
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <SC.Field
            onClick={() => {
              setBaseFieldKey("contract");
              setBaseFieldName("avtal");
            }}
            clickableCategory
          >
            Avtal
          </SC.Field>
          <SC.Field
            onClick={() => {
              setBaseFieldKey("apartment");
              setBaseFieldName("lägenhet");
            }}
            clickableCategory
          >
            Lägenheter
          </SC.Field>
          <SC.Field
            onClick={() => {
              setBaseFieldKey("industrialPremises");
              setBaseFieldName("lokal");
            }}
            clickableCategory
          >
            Lokaler
          </SC.Field>
          <SC.Field
            onClick={() => {
              setBaseFieldKey("invoicing");
              setBaseFieldName("avisering");
            }}
            clickableCategory
          >
            Avisering
          </SC.Field>
        </div>
      </div>
    );
  }

  const fieldSuggestions = getFieldSuggestions({
    currentSelectedParty,
    baseFieldKey,
    baseFieldName,
    industrialPremisesIndex,
    apartmentIndex,
  });

  return (
    <div>
      <TextButton
        extraStyle={{ marginBottom: 8 }}
        title="Byt kategori"
        iconType="arrow-back"
        clicked={() => {
          setBaseFieldKey(null);
          setBaseFieldName(null);
        }}
      />

      {baseFieldKey === "apartment" && (
        <div style={{ maxWidth: 200, marginBottom: 12 }}>
          {" "}
          <NonConnectedSelect
            menuPlacement="top"
            label="Lägenhet i ordningen"
            choices={[
              { label: 1, value: 0 },
              { label: 2, value: 1 },
              { label: 3, value: 2 },
            ]}
            value={apartmentIndex}
            noMargin
            onUpdate={(val) => setApartmentIndex(val)}
            id="apartment_index_picker"
            getOptionLabel={(o) => o.label}
            getOptionValue={(o) => o.value}
            allowNull={false}
          />
        </div>
      )}

      {baseFieldKey === "industrialPremises" && (
        <div style={{ maxWidth: 200, marginBottom: 12 }}>
          {" "}
          <NonConnectedSelect
            menuPlacement="top"
            label="Lokal i ordningen"
            choices={[
              { label: 1, value: 0 },
              { label: 2, value: 1 },
              { label: 3, value: 2 },
            ]}
            value={industrialPremisesIndex}
            noMargin
            onUpdate={(val) => setIndustrialPremisesIndex(val)}
            id="indp_index_picker"
            getOptionLabel={(o) => o.label}
            getOptionValue={(o) => o.value}
            allowNull={false}
          />
        </div>
      )}

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {fieldSuggestions?.length > 0
          ? fieldSuggestions.map((f) => (
              <SC.Field
                {...{
                  selected: !!selectedFields?.find((sf) => sf.name === f.name),
                }}
                key={f.name}
                style={{
                  backgroundColor: currentSelectedParty?._color,
                  color: currentSelectedParty?._color
                    ? lightOrDark(currentSelectedParty?._color) === "light"
                      ? "black"
                      : "white"
                    : "white",
                }}
                onClick={() =>
                  onAddNewField({
                    partyIndex: currentSelectedPartyIndex,
                    kind: f.kind,
                    name: f.name,
                    value: f.value,
                    is_obligatory: false,
                  })
                }
              >
                {f.name}
              </SC.Field>
            ))
          : "Inga föreslagna fält kvar. Kontakta Pigello om du saknar ett visst fält som borde visas som förslag."}
      </div>
    </div>
  );
};
