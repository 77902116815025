export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_CUSTOMERINVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_CUSTOMERINVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_CUSTOMERINVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_CUSTOMERINVOICING",
  DESTROY_FORM: "DESTROY_FORM_CUSTOMERINVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_CUSTOMERINVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_CUSTOMERINVOICING",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_CUSTOMERINVOICING",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_CUSTOMERINVOICING",
  RESET_STATE: "RESET_STATE_CUSTOMERINVOICING",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_CUSTOMERINVOICING",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_CUSTOMERINVOICING",
  INSERT_CONTRACT_STATE_STAT: "INSERT_STATUS_STAT_CUSTOMERINVOICING",
  CLEAR_FETCHED: "CLEAR_FETCHED_CUSTOMERINVOICING",

  LIST_URL: "/accounting/contract/customerinvoicing/list/",
  POST_URL: "/accounting/contract/customerinvoicing/",
  PATCH_URL: "/accounting/contract/customerinvoicing/",
  GET_URL: "/accounting/contract/customerinvoicing/",
  MASS_INVOICE_STATUS_UPDATE_URL:
    "/accounting/contract/masspause_customerinvoicing/",

  STORE_NAME: "invoicingCustomer",
};
