export default [
  {
    title: "Företag",
    key: "COMPANY",
    visited: true,
    hasError: false,
    fieldKeys: [
      "name",
      "orgnr",
      "address",
      "description",
      "image",
      "image_temp_data",
      "invoicing_config",
      "owner",
    ],
  },
  {
    title: "Granska & bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
  },
];
