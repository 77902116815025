import * as React from "react";

// style, design
import {
  ToolTipCell,
  LinkedObject,
  DateCell,
  DurationCell,
} from "../../Displays";

import SimpleTextFilter from "../Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "../Base/CompleteList/Filters/DateFilter";
import SimpleIntervalFilter from "../Base/CompleteList/Filters/IntervalFilter";
import StateFilter from "./Filters/StateFilter";

import { detailUrl as customerDetailUrl } from "../../../store/customers";

import { StatusLabel } from "../Base/CompleteList/styles";
import SimpleBooleanFilter from "../Base/CompleteList/Filters/BooleanFilter";

export default () => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ row }) => {
        let label = row.original.state_display;
        const state = row.original.state;
        if ([6, 7].includes(state) && !row.original.closed_signed) {
          label += ` (Ej signerat)`;
        }
        const isDraft = row.original.draft;

        if (isDraft) {
          label = "Utkast";
        }

        return <StatusLabel state={state}>{label}</StatusLabel>;
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Avtalsnummer",
      accessor: "id_number",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Faktureringsstatus",
      accessor: "customer_invoicing_active",
      Cell: ({ value }) => (
        <StatusLabel
          state={
            value === true ? 1 : value === null ? 6 : value === false ? 3 : 10
          }
        >
          {value === true
            ? "Konfigurerad"
            : value === null
            ? "Ej konfigurerad"
            : value === false
            ? "Pausad"
            : "Okänd"}
        </StatusLabel>
      ),
      Filter: SimpleBooleanFilter,
      filter: "text",
    },
    {
      Header: "Kund",
      accessor: "customer.str_representation",

      Cell: ({ row }) => (
        <LinkedObject
          obj={row.original.customer}
          urlMethod={customerDetailUrl}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Startdatum",
      accessor: "start_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Slutdatum",
      accessor: (row, _) =>
        row.closed_date
          ? row.closed_date
          : row.renewed_to
          ? row.renewed_to
          : row.end_date,
      id: "end_date",
      Cell: ({ row }) => (
        <>
          {row.original.closed_date ? (
            <DateCell date={row.original.closed_date} />
          ) : row.original.renewed_to ? (
            <>
              <DateCell date={row.original.renewed_to} />
              <span> (Förlängt)</span>
            </>
          ) : (
            <DateCell date={row.original.end_date} />
          )}
        </>
      ),
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Uppsägningstid",
      accessor: "notify_interval",
      Cell: ({ row }) => (
        <DurationCell
          durationStamp={row.original.notify_interval}
          shadowDate={row.original.notify_time}
        />
      ),
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Förlängningstid",
      accessor: "renew_interval",
      Cell: ({ row }) => {
        const forever = row.original.renew_indefinetely;
        if (forever) {
          return "Tillsvidare";
        }
        return <DurationCell durationStamp={row.original.renew_interval} />;
      },
      Filter: SimpleIntervalFilter,
      filter: "text",
    },
    {
      Header: "Skapat av",
      accessor: "owner.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];

  return cols;
};
