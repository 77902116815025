import * as React from "react";
import { useDispatch } from "react-redux";
import {
  authenticateAsCustomer,
  useFilteredCustomers,
} from "../../../../store/customers";
import { TextButton } from "../../../Forms/Base/Buttons";
import PremisesTable from "../../../PremisesTable/PremisesTable";
import { DetailPageBox, InnerBox } from "../../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../OverviewInfo/styles";

export default function Favourites() {
  const dispatch = useDispatch();
  const [favourites, setFavourites] = React.useState(null);

  const [customers] = useFilteredCustomers("");

  React.useEffect(() => {
    const items =
      JSON.parse(localStorage.getItem("management_login_count")) || {};

    if (items?.length === 0 || favourites) return;

    const f = Object.keys(items).map((customerId) => {
      const customer = customers?.find((c) => c.id == customerId);
      return {
        id: customerId,
        is_not_connected: customer ? customer?.is_not_connected : true,
        name: customer?.str_representation || "Laddar...",
        count: parseInt(items[customerId]),
      };
    });

    f.sort((a, b) => b.count - a.count);

    const top5 = f.slice(0, 5);

    setFavourites(top5);
  }, [customers]);

  const clearList = () => {
    localStorage.removeItem("management_login_count");
    setFavourites([]);
  };

  return (
    <>
      <DetailPageBox style={{ borderRadius: 5, marginTop: 0 }}>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Mest besökta</OverviewTitle>
            <OverviewSubtitle>
              <TextButton
                title="Rensa lista"
                clicked={() => clearList()}
                iconType="close"
                iconPlacement="right"
              />
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {favourites?.length > 0 ? (
          <PremisesTable
            headers={["Förvaltningsuppdrag", "Logga in"]}
            rows={(favourites || []).map((f) => [
              f.name,
              f.is_not_connected ? (
                "Saknar behörighet/koppling"
              ) : (
                <TextButton
                  title="Logga in"
                  clicked={() => {
                    dispatch(authenticateAsCustomer(f.id));
                  }}
                  iconType="arrow"
                  iconPlacement="right"
                />
              ),
            ])}
          />
        ) : (
          <InnerBox>
            Data saknas. Listan uppdateras automatiskt vid inloggning hos
            uppdrag.
          </InnerBox>
        )}
      </DetailPageBox>
    </>
  );
}
